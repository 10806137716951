import User from "../../../api/users/domain/user";

interface UserSelectOptionsParams {
  users: User[];
  selectedUserId?: string;
  displayAvatar?: boolean;
  displayProfileBadge?: boolean;
  displayNif?: boolean;
}

export default function createUserSelectOptionsHTML({ users, selectedUserId, displayAvatar = true, displayProfileBadge = true, displayNif = true }: UserSelectOptionsParams): string {
  return users
    .map(({ userId, name, userProfile, avatarPath, identificationNumber }) => {
      const isSelected = selectedUserId === userId ? "selected" : "";
      const dataContent = `
        ${displayAvatar ? `<img src="${avatarPath}" class="img-fluid rounded mr-2" style="width:19px;" alt="${name}">` : ""}
        <span>${name}</span>
        ${displayNif ? `<span class="text-muted ml-2" style="font-size: 0.9em;">${identificationNumber ?? ''}</span>` : ""}
        ${displayProfileBadge ? `<span class="badge bg-${userProfile.backgroundColor} text-${userProfile.textColor} ml-2">${userProfile.label}</span>` : ""}
      `;
      return `<option value="${userId}" ${isSelected} data-content='${dataContent}'>${name}</option>`;
    })
    .join("");
}
