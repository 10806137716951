"use strict";

let cropper;

function showLoadingButton(buttonId, bgClass, onlySpiner = false) {
  const loadingHtml = onlySpiner ? '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="pointer-events: none;"></span>' : '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="pointer-events: none;"></span> Cargando...';
  $("#" + buttonId)
    .removeClass(bgClass)
    .addClass("btn-barymont-grey")
    .attr("disabled", true)
    .html(loadingHtml);
}

async function saveBase64Image() {
  const canvas = cropper.getCroppedCanvas({
    width: 135,
    height: 150,
  });

  try {
    const blob = await new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error("No se pudo generar el blob"));
        }
      });
    });

    const base64data = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });

    $("#signature-image-file-base64").val(JSON.stringify(base64data));
    $("#main_form_foto").attr("src", base64data);

    let response = await fetchSignatureImage("signature-image-file-base64");
    if (response.success) {
      const modalSignatureImageData = $("#modal-signature-image-data");
      modalSignatureImageData.modal("hide");
      $("#signature-url-image").val(response.image);
      createToast("info", "Imagen de la firma añadida", "La imagen se ha seleccionado correctamente.", 5000);
    } else {
      throw new Error("Ha ocurrido un error");
    }
  } catch (error) {
    createToast("error", "Ha ocurrido un error", "No se ha podido añadir la imagen, vuelva a intentarlo más tarde.", 5000);
  }
}

async function fetchSignatureImage(imageBase64Id) {
  let formData = new FormData();
  formData.append("imageBase64", document.getElementById(imageBase64Id).value);
  formData.append("name", document.getElementById("main_form_name").value);

  let requestOptions = {
    method: "POST",
    body: formData,
  };

  return await fetch("/herramientas/firma/ajax/saveSignatureImage", requestOptions).then((response) => response.json());
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname === "/contacto/firma-correo" || window.location.pathname === "/contacto/firma-correo-go" || window.location.pathname === "/contacto/firma-correo-advance") {
      const modalSignatureImageData = $("#modal-signature-image-data");
      const image = document.getElementById("crop-modal-signature-data-image");

      document.getElementById("main_form_foto").addEventListener("change", function (event) {
        const [file] = event.target.files;

        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            image.src = e.target.result;
            modalSignatureImageData.modal("show");
          };
          reader.readAsDataURL(file);
        }
      });

      document.getElementById("btn-update-modal-signature-data-image").addEventListener("click", () => {
        showLoadingButton(this.id, "btn-barymont-red");
        saveBase64Image();
      });

      modalSignatureImageData
        .on("shown.bs.modal", () => {
          cropper = new Cropper(image, {
            aspectRatio: 1,
            viewMode: 1,
            autoCropArea: 1,
            preview: ".preview-modal-signature-data-image",
          });
        })
        .on("hidden.bs.modal", () => {
          cropper.destroy();
          cropper = null;
        });
    }
  });
}
