"use strict";

var FILTER_STATE_LOCAL_STORAGE_KEY = "RANKING_MANAGEMENT_FILTER_STATE_";
var FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME = 1000 * 60 * 20; // 20 minutes in milliseconds

function formatScoreNumber(scoreAsString) {
  const num = parseFloat(scoreAsString);

  const roundedNum = Math.floor(num * 100) / 100;

  let formattedScore = roundedNum.toLocaleString("es-ES", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  return formattedScore;
}

function getStateOfCustomFilters() {
  return {
    checkRankingTypeFilter: $("#ranking-type-filter").val(),
    checkRankingCategoryFilter: $("#ranking-category-filter").val(),
    checkRankingStatusFilter: $("#ranking-status-filter").val(),
    checkRankingCalculationModeFilter: $("#ranking-calculation-mode-filter").val(),
    checkRankingViewModeFilter: $("#ranking-view-mode-filter").val(),
    checkRankingStartDateFilter: $("#ranking-start-date").val(),
    checkRankingFinishDateFilter: $("#ranking-finish-date").val(),
  };
}

function recreateStateOfCustomFilters(localStorageData) {
  if (localStorageData.customFilter === undefined) {
    return false;
  }

  $("#ranking-type-filter").val(localStorageData.customFilter.checkRankingTypeFilter).selectpicker("refresh");
  $("#ranking-category-filter").val(localStorageData.customFilter.checkRankingCategoryFilter).selectpicker("refresh");
  $("#ranking-status-filter").val(localStorageData.customFilter.checkRankingStatusFilter).selectpicker("refresh");
  $("#ranking-calculation-mode-filter").val(localStorageData.customFilter.checkRankingCalculationModeFilter).selectpicker("refresh");
  $("#ranking-view-mode-filter").val(localStorageData.customFilter.checkRankingViewModeFilter).selectpicker("refresh");
  $("#ranking-status-filter").val(localStorageData.customFilter.checkRankingStatusFilter).selectpicker("refresh");
  $("#ranking-start-date").val(localStorageData.customFilter.checkRankingStartDateFilter);
  $("#ranking-finish-date").val(localStorageData.customFilter.checkRankingFinishDateFilter);
}

function resetearFiltros() {
  $("#ranking-type-filter").val("").selectpicker("refresh");
  $("#ranking-category-filter").val("").selectpicker("refresh");
  $("#ranking-status-filter").val("").selectpicker("refresh");
  $("#ranking-calculation-mode-filter").val("").selectpicker("refresh");
  $("#ranking-view-mode-filter").val("").selectpicker("refresh");
  $("#ranking-start-date").val("");
  $("#ranking-finish-date").val("");
  $("#ranking-management-table").DataTable().search("").draw();

  $("#ranking-management-table").DataTable().ajax.reload();
}

function cloneElementToRemoveListeners(elementId) {
  const element = document.getElementById(elementId);
  const clone = element.cloneNode(true);
  element.parentNode.replaceChild(clone, element);
}

function showLoadingButton(buttonId, bgClass, onlySpiner = false) {
  const loadingHtml = onlySpiner ? '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="pointer-events: none;"></span>' : '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="pointer-events: none;"></span> Cargando...';
  $("#" + buttonId)
    .removeClass(bgClass)
    .addClass("btn-barymont-grey")
    .attr("disabled", true)
    .html(loadingHtml);
}

function updateButtonLabel(buttonId, newLabel, bgClass) {
  $("#" + buttonId)
    .removeClass("btn-barymont-grey")
    .addClass(bgClass)
    .attr("disabled", false)
    .html(newLabel);
}

function setSelectedValuesOnSelectPicker(selectId, valuesArray) {
  let select = document.getElementById(selectId);

  for (let option of select.options) {
    option.selected = false;
  }

  valuesArray.forEach((value) => {
    let option = document.querySelector(`#${selectId} option[value="${value}"]`);
    if (option) {
      option.selected = true;
    }
  });

  $(select).selectpicker("refresh");
}

/* START MODAL CREATE RANKING */
async function fetchRankingUserListWhoCanInscribeOnRanking() {
  let requestOptions = {
    method: "POST",
    redirect: "follow",
  };

  return await fetch("/rankings/ajax/getAllUsersWhoCanInscribeOnARanking", requestOptions).then((response) => response.json());
}

async function fetchRankingFactorProductList() {
  let requestOptions = {
    method: "POST",
    redirect: "follow",
  };

  return await fetch("/rankings/ajax/getAllCurrentRankingFactorProduct", requestOptions).then((response) => response.json());
}

async function fetchAllRankingDataById(rankingId) {
  let formData = new FormData();

  formData.append("rankingId", rankingId);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/rankings/ajax/getAllRankingDataByRankingId", requestOptions).then((response) => response.json());
}

async function fetchRankingParticipantBreakdownData(userId, rankingId) {
  let formData = new FormData();

  formData.append("rankingId", rankingId);
  formData.append("userId", userId);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/rankings/ajax/getRankingParticipantBreakdownData", requestOptions).then((response) => response.json());
}

async function openRankingModal(userListId, userListIdBadge, buttonActionId, buttonActionLabel, rankingId = null) {
  const modalMode = rankingId ? "edit" : "create";

  if (modalMode === "edit") {
    updateUserSelectedCounter(userListId, userListIdBadge);
    setRankingModalState(modalMode);
    cloneElementToRemoveListeners("modal-btn-summit-ranking-edit");
    showLoadingButton("edit-ranking-" + rankingId, "btn-barymont-grey", true);

    const rankingExists = await fetchAllRankingDataById(rankingId).then((rankingData) => {
      if (rankingData.status == 0) {
        return false;
      }

      resetRankingFieldsIntoEditModal();
      loadRankingDataIntoRankingModal(rankingData.data.rankingId, rankingData.data.name, rankingData.data.rankingType, rankingData.data.category, rankingData.data.rankingCalculationMode, rankingData.data.rankingViewMode, rankingData.data.startDate, rankingData.data.finishDate, rankingData.data.lastUpdateDate, rankingData.data.coverImage, rankingData.data.description);
      loadBadgeDataFromParticipantsIntoRankingModal(rankingData.data.rankingUsers, "modal-ranking-data-uuid-user-list");
      loadFactorDataInToRankingModal(rankingData.data.factors);

      document.getElementById("modal-btn-summit-ranking-edit")?.addEventListener("click", function () {
        showLoadingButton("modal-btn-summit-ranking-edit", "btn-barymont-red", false);

        if (checkIfRankingDataIsValid(getRankingData())) {
          updateRankingData(getRankingData()).then((response) => {
            updateButtonLabel("modal-btn-summit-ranking-edit", "Editar ranking", "btn-barymont-red");

            if (response.status) {
              $("#ranking-management-table").DataTable().ajax.reload();
              $("#modal-ranking").modal("hide");
              return;
            }
            createToast("danger", response.error.code, response.error.message, 5000);
          });
        } else {
          updateButtonLabel("modal-btn-summit-ranking-edit", "Editar ranking", "btn-barymont-red");
        }
      });

      return true;
    });

    if (!rankingExists) {
      createToast("danger", "Error", "No se ha podido cargar el ranking que quieres editar.", 5000);
      return;
    }

    updateButtonLabel(buttonActionId, buttonActionLabel, "btn-barymont-red");

    $("#modal-ranking").modal("show");
  }

  if (modalMode === "create") {
    updateUserSelectedCounter(userListId, userListIdBadge);
    setRankingModalState(modalMode);
    cloneElementToRemoveListeners("modal-btn-summit-ranking-create");

    resetRankingFieldsIntoEditModal();
    initializeTextareasWithTinyMCE();

    document.getElementById("modal-btn-summit-ranking-create")?.addEventListener("click", function () {
      showLoadingButton("modal-btn-summit-ranking-create", "btn-barymont-red", false);

      if (checkIfRankingDataIsValid(getRankingData())) {
        createRankingData(getRankingData()).then((response) => {
          updateButtonLabel("modal-btn-summit-ranking-create", "Crear ranking", "btn-barymont-red");

          if (response.status) {
            location.href = "/rankings/" + response.data.rankingId;
            return;
          } else {
            createToast("danger", response.error.code, response.error.message, 5000);
          }
        });
      } else {
        updateButtonLabel("modal-btn-summit-ranking-create", "Crear ranking", "btn-barymont-red");
      }
    });

    updateButtonLabel(buttonActionId, buttonActionLabel, "btn-barymont-red");

    $("#modal-ranking").modal("show");
  }
}

async function setRankingUserListDataOnRankingModal(rankingParticipantsData) {
  await fetchRankingUserListWhoCanInscribeOnRanking().then((rankingInscribeList) => {
    printRankingInscribeList(rankingInscribeList, "modal-participant-ranking-data-user-list", rankingParticipantsData);
  });
}

async function setRankingFactorProductListDataOnRankingModal() {
  await fetchRankingFactorProductList().then((rankingFactorProductList) => {
    printRankingFactorProductList(rankingFactorProductList, "modal-ranking-factor-data-product-list");
  });
}

async function updateRankingData(rankingData) {
  let formData = new FormData();

  formData.append("rankingId", rankingData.rankingId);
  formData.append("name", rankingData.name);
  formData.append("rankingType", rankingData.rankingType);
  formData.append("category", rankingData.category);
  formData.append("rankingCalculationMode", rankingData.rankingCalculationMode);
  formData.append("rankingViewMode", rankingData.rankingViewMode);
  formData.append("startDate", rankingData.startDate);
  formData.append("finishDate", rankingData.finishDate);
  formData.append("lastUpdateDate", rankingData.lastUpdateDate);
  formData.append("description", rankingData.description);
  formData.append("coverImage", rankingData.coverImage);
  formData.append("coverImageBase64", rankingData.coverImageBase64);
  formData.append("rankingUsersId", JSON.stringify(rankingData.rankingUserId));
  formData.append("factorsData", JSON.stringify(rankingData.rankingFactorsData));

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/rankings/ajax/updateRankingData", requestOptions).then((response) => response.json());
}

async function createRankingData(rankingData) {
  let formData = new FormData();

  formData.append("rankingId", rankingData.rankingId);
  formData.append("name", rankingData.name);
  formData.append("rankingType", rankingData.rankingType);
  formData.append("category", rankingData.category);
  formData.append("rankingCalculationMode", rankingData.rankingCalculationMode);
  formData.append("rankingViewMode", rankingData.rankingViewMode);
  formData.append("startDate", rankingData.startDate);
  formData.append("finishDate", rankingData.finishDate);
  formData.append("lastUpdateDate", rankingData.lastUpdateDate);
  formData.append("description", rankingData.description);
  formData.append("coverImage", rankingData.coverImage);
  formData.append("coverImageBase64", rankingData.coverImageBase64);
  formData.append("rankingUsersId", JSON.stringify(rankingData.rankingUserId));
  formData.append("factorsData", JSON.stringify(rankingData.rankingFactorsData));

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/rankings/ajax/createRankingData", requestOptions).then((response) => response.json());
}

function setRankingModalState(mode) {
  const modalSummitButton = document.getElementById("modal-btn-summit-ranking") || document.getElementById("modal-btn-summit-ranking-edit") || document.getElementById("modal-btn-summit-ranking-create");

  document.getElementById("modal-ranking-state").value = mode;

  if (mode === "create") {
    modalSummitButton.id = "modal-btn-summit-ranking-create";
    modalSummitButton.innerHTML = "Crear ranking";

    document.getElementById("modal-ranking-title").innerHTML = "Crear ranking";
    document.getElementById("modal-ranking-data-button-open-modal-confirm-remove-ranking").classList.add("d-none");
  }

  if (mode === "edit") {
    modalSummitButton.id = "modal-btn-summit-ranking-edit";
    modalSummitButton.innerHTML = "Editar ranking";

    document.getElementById("modal-ranking-title").innerHTML = "Editar ranking";
    document.getElementById("modal-ranking-data-button-open-modal-confirm-remove-ranking").classList.remove("d-none");
  }
}

function resetTableNewParticipantsListRankingManagementModal() {
  const containerWithNewData = document.getElementById("modal-participant-ranking-list-new-data-table-body");

  containerWithNewData.innerHTML = "";
  containerWithNewData.insertAdjacentHTML(
    "beforeend",
    `<tr id="participant-list-new-default-state-message">
      <td colspan="3" class="text-center w-100">No hay participantes añadidos</td>
    </tr>`
  );

  document.getElementById("modal-ranking-data-new-user-list-badge").textContent = 0;
  document.getElementById("modal-participant-ranking-list-data-export-button")?.classList.remove("d-none");
}

function resetTablesCurrentParticipantsListRankingManagementModal() {
  const containerWithCurrentData = document.getElementById("modal-participant-ranking-list-current-data-table-body");

  containerWithCurrentData.innerHTML = "";
  containerWithCurrentData.insertAdjacentHTML(
    "beforeend",
    `<tr id="participant-list-current-default-state-message">
      <td colspan="7" class="text-center w-100">No hay participantes añadidos</td>
    </tr>`
  );

  document.getElementById("modal-ranking-data-current-user-list-badge").textContent = 0;
}

function resetRankingFieldsIntoEditModal() {
  document.getElementById("modal-ranking-data-uuid").value = "";
  document.getElementById("modal-ranking-data-name").value = "";
  document.getElementById("modal-ranking-data-type").value = "";
  document.getElementById("modal-ranking-data-category").value = "";
  document.getElementById("modal-ranking-data-calculation-mode").value = "";
  document.getElementById("modal-ranking-data-view-mode").value = "";
  document.getElementById("modal-ranking-data-start-date").value = "";
  document.getElementById("modal-ranking-data-finish-date").value = "";
  document.getElementById("modal-ranking-data-last-update-date").value = "";
  document.getElementById("modal-ranking-data-image").src = "https://placehold.co/1920x1080?text=16:9";
  document.getElementById("modal-ranking-data-image-file").value = "";
  document.getElementById("modal-ranking-data-image-file-base64").value = "";
  document.getElementById("modal-ranking-data-description").value = "";
  document.getElementById("modal-ranking-data-uuid-user-list-badge").innerHTML = "0";
  document.getElementById("modal-ranking-data-uuid-user-list").value = "";

  $("#modal-ranking-data-type").selectpicker("refresh");
  $("#modal-ranking-data-category").selectpicker("refresh");
  $("#modal-ranking-data-calculation-mode").selectpicker("refresh");
  $("#modal-ranking-data-view-mode").selectpicker("refresh");

  if (tinymce === undefined) {
    initializeTextareasWithTinyMCE();
  } else {
    resetTextareasTinyMCE();
  }

  resetTableNewParticipantsListRankingManagementModal();
  resetTablesCurrentParticipantsListRankingManagementModal();

  printDefaultStateOnFactorList("modal-ranking-factor-data-table-body");
}

function loadRankingDataIntoRankingModal(rankingId, rankingName, rankingType, rankingCategory, rankingCalculationMode, rankingViewMode, rankingStartDate, rankingFinishDate, rankingLastUpdateDate, rankingCoverImage, rankingDescription) {
  document.getElementById("modal-ranking-data-uuid").value = rankingId;
  document.getElementById("modal-ranking-data-name").value = rankingName;
  document.getElementById("modal-ranking-data-type").value = rankingType;
  document.getElementById("modal-ranking-data-category").value = rankingCategory;
  document.getElementById("modal-ranking-data-calculation-mode").value = rankingCalculationMode;
  document.getElementById("modal-ranking-data-view-mode").value = rankingViewMode;
  document.getElementById("modal-ranking-data-start-date").value = rankingStartDate;
  document.getElementById("modal-ranking-data-finish-date").value = rankingFinishDate;
  document.getElementById("modal-ranking-data-last-update-date").value = rankingLastUpdateDate;
  document.getElementById("modal-ranking-data-image").src = rankingCoverImage;
  document.getElementById("modal-ranking-data-description").value = rankingDescription;

  $("#modal-ranking-data-type").selectpicker("refresh");
  $("#modal-ranking-data-category").selectpicker("refresh");
  $("#modal-ranking-data-calculation-mode").selectpicker("refresh");
  $("#modal-ranking-data-view-mode").selectpicker("refresh");

  initializeTextareasWithTinyMCE();
}

function loadBadgeDataFromParticipantsIntoRankingModal(rankingParticipants, userUuidListId) {
  const selectedUsers = rankingParticipants.map((participant) => participant.userId);
  document.getElementById(userUuidListId).value = JSON.stringify(selectedUsers);

  updateCounterByJsonLength(userUuidListId, userUuidListId + "-badge");
}

function loadFactorDataInToRankingModal(rankingFactors) {
  printDefaultStateOnFactorList("modal-ranking-factor-data-table-body");
  rankingFactors.forEach((factor) => {
    printFactorOnFactorList("modal-ranking-factor-data-table-body", factor.factorId, factor.description, factor.factorType, factor.factorScoringType, factor.factorDataStatusToInclude, factor.points, factor.minPoints, factor.maxPoints, factor.factorProductsIds, "");
  });

  initializeTextareasWithTinyMCE();
}

async function openFactorModal(factorId = null) {
  document.getElementById("form-modal-ranking-factor-data").classList.remove("was-validated");

  setRankingFactorProductListDataOnRankingModal().then(() => {
    if (factorId) {
      setFactorDataOnModalToEditFactorData(factorId);
      updateButtonLabel("edit-factor-" + factorId, `<i class="fas fa-pen fa-sm" style="pointer-events: none;"></i>`, "btn-barymont-red");
    } else {
      updateButtonLabel("modal-ranking-factor-data-button-add-factor", `<i class="fas fa-plus mr-2"></i>Añadir factor`, "btn-barymont-black");
    }
    $("#modal-ranking-factor-data").modal("show");
  });
}

async function checkIfRankingFactorIsValidOnBackend(factorId, rankingId, description, factorType, factorScoringType, factorData, points, minPoints, maxPoints, selectedProducts) {
  let formData = new FormData();

  formData.append("factorId", factorId);
  formData.append("rankingId", rankingId);
  formData.append("description", description);
  formData.append("factorType", factorType);
  formData.append("factorScoringType", factorScoringType);
  formData.append("factorData", factorData);
  formData.append("points", points);
  formData.append("minPoints", minPoints);
  formData.append("maxPoints", maxPoints);
  formData.append("products", selectedProducts);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/rankings/ajax/checkIfFactorIsWellFormated", requestOptions).then((response) => response.json());
}

function getRankingFactorData() {
  const factorId = document.getElementById("modal-ranking-factor-data-uuid").value;
  const rankingId = document.getElementById("modal-ranking-data-uuid").value;
  const description = document.getElementById("modal-ranking-factor-data-description").value;
  const factorType = document.getElementById("modal-ranking-factor-data-type").value;
  const factorScoringType = document.getElementById("modal-ranking-factor-data-scoring-type").value;
  const factorData = document.getElementById("modal-ranking-factor-data-status-to-include").value;
  const points = document.getElementById("modal-ranking-factor-data-points").value;
  const minPoints = document.getElementById("modal-ranking-factor-data-min-points").value;
  const maxPoints = document.getElementById("modal-ranking-factor-data-max-points").value;

  const selectElement = document.getElementById("modal-ranking-factor-data-product-list");
  const selectedProducts = Array.from(selectElement.selectedOptions).map((option) => option.value);

  const factorAction = document.getElementById("modal-ranking-factor-action-to-do").value;

  return { factorId, rankingId, description, factorType, factorScoringType, factorData, points, minPoints, maxPoints, selectedProducts, factorAction };
}

function printRankingInscribeList(rankingInscribeList, userListId, currentUsersOnRanking = []) {
  if (!rankingInscribeList.status) {
    $("#" + userListId).html(`
      <option value="0" disabled selected>No se han encontrado usuarios.</option>
    `);
    return;
  }

  $("#" + userListId).html("");

  rankingInscribeList.data.forEach((user) => {
    if (currentUsersOnRanking.includes(user.userId)) {
      return;
    }

    $("#" + userListId).append(`
      <option value="${user.userId}"
        data-content="<span class='text-uppercase'>${user.userName} - <span class='btn btn-sm py-0 bg-${user.userProfileLevelBgColor} text-${user.userProfileLevelTextColor}'>${user.userProfileLevelLabel.toUpperCase()}</span></span>"
        data-profile-level-text="${user.userProfileLevelLabel.toUpperCase().replace(/ /g, "-")}"
        data-participant-name="${user.userName}"
        data-participant-profile-level-html="<span class='btn btn-sm py-0 bg-${user.userProfileLevelBgColor} text-${user.userProfileLevelTextColor}'>${user.userProfileLevelLabel.toUpperCase()}</span>"
        >
        ${user.userName} - [${user.userProfileLevelLabel.toUpperCase().replace(/ /g, "-")}]
      </option>
    `);
  });

  $("#" + userListId).selectpicker("refresh");
}

function printRankingFactorProductList(rankingFactorProductList, productListId) {
  if (!rankingFactorProductList.status) {
    $("#" + productListId).html(`
      <option value="0" disabled selected>No se han encontrado productos.</option>
    `);
    return;
  }

  rankingFactorProductList.data.forEach((product) => {
    $("#" + productListId).append(`
      <option value="${product.ebrokerProductId}">
        ${product.ebrokerProductId} - ${product.name} - [${product.companyName}] - [${product.family}]
      </option>
    `);
  });
  $("#" + productListId).selectpicker("refresh");
}

function toggleScoringTypeFields() {
  const AllowedVScoringTypes = ["POINTS_X_UNIT", "POINTS_X_EURO"];

  const scoringType = document.getElementById("modal-ranking-factor-data-scoring-type").value;

  if (!AllowedVScoringTypes.includes(scoringType)) {
    console.error("El modo de vista no es correcto, solo se permiten los siguientes modos: " + AllowedVScoringTypes.join(", "));
  } else {
    if (scoringType == "POINTS_X_UNIT") {
      document.getElementById("modal-ranking-factor-data-min-points").disabled = true;
      document.getElementById("modal-ranking-factor-data-min-points").value = "";
      document.getElementById("modal-ranking-factor-data-max-points").disabled = true;
      document.getElementById("modal-ranking-factor-data-max-points").value = "";
    } else {
      document.getElementById("modal-ranking-factor-data-min-points").disabled = false;
      document.getElementById("modal-ranking-factor-data-max-points").disabled = false;
    }
  }
}

function updateCounterByJsonLength(InputIdWithJsonData, userListIdBadge) {
  const userCountBadge = document.getElementById(userListIdBadge);
  const arrayOfUsersSelected = JSON.parse(document.getElementById(InputIdWithJsonData).value);

  userCountBadge.textContent = arrayOfUsersSelected.length;
}

function incrementCounter(userListIdBadge) {
  const userCountBadge = document.getElementById(userListIdBadge);
  userCountBadge.textContent = parseInt(userCountBadge.textContent) + 1;
}

function updateUserSelectedCounter(userListId, userListIdBadge) {
  document.getElementById(userListId).addEventListener("change", function () {
    updateCounterByJsonLength(userListId, userListIdBadge);
  });
}

function removeRankingImage() {
  document.getElementById("modal-ranking-data-image").setAttribute("src", "https://placehold.co/1920x1080?text=16:9");
  document.getElementById("modal-ranking-data-image-file").value = "";
  document.getElementById("modal-ranking-data-image-file-base64").value = "_REMOVE_IMAGEN_";

  createToast("info", "Imagen eliminada", "Se ha eliminado la imagen del ranking.", 5000);
}

function createUuid(inputId) {
  document.getElementById(inputId).value = self.crypto.randomUUID();
}

function resetFactorModalFields() {
  document.getElementById("form-modal-ranking-factor-data").classList.remove("was-validated");

  document.getElementById("modal-ranking-factor-data-description").value = "";
  document.getElementById("modal-ranking-factor-data-type").value = "";
  document.getElementById("modal-ranking-factor-data-scoring-type").value = "";
  document.getElementById("modal-ranking-factor-data-status-to-include").value = "";
  document.getElementById("modal-ranking-factor-data-points").value = "";
  document.getElementById("modal-ranking-factor-data-min-points").value = "";
  document.getElementById("modal-ranking-factor-data-max-points").value = "";
  document.getElementById("modal-ranking-factor-data-product-list").value = "";
  document.getElementById("modal-ranking-factor-data-product-list").innerHTML = "";

  $("#modal-ranking-factor-data-scoring-type").selectpicker("refresh");
  $("#modal-ranking-factor-data-type").selectpicker("refresh");
  $("#modal-ranking-factor-data-status-to-include").selectpicker("refresh");
  $("#modal-ranking-factor-data-product-list").selectpicker("refresh");
}

function printFactorOnFactorList(containerId, factorId, description, factorType, factorScoringType, factorData, points, minPoints, maxPoints, selectedProducts, factorAction) {
  let container = document.getElementById(containerId);

  const defaultRow = container.querySelector("#defaultStateMessage");
  if (defaultRow) {
    container.removeChild(defaultRow);
  }

  const factorContent = `
    <input type="hidden" id="action-${factorId}" value="${factorAction}">
    <div style="display: none;" id="factor-description-${factorId}">
      ${description}
    </div>
    <td>
        <input type="hidden" id="factor-type-${factorId}" value="${factorType}">
        ${factorType}
    </td>
    <td>
        <input type="hidden" id="factor-scoring-type-${factorId}" value="${factorScoringType}">
        ${factorScoringType}
    </td>
    <td>
        <input type="hidden" id="factor-data-status-to-include-${factorId}" value="${factorData}">
        ${factorData}
    </td>
    <td>
        <input type="hidden" id="factor-points-${factorId}" value="${points}">
        <span class="badge badge-light mr-1" style="font-size:14px">${points}</span>
    </td>
    <td>
        <input type="hidden" id="factor-min-points-${factorId}" value="${minPoints}">
        <span class="badge badge-light mr-1" style="font-size:14px">${minPoints ? minPoints : "-"}</span>
    </td>
    <td>
        <input type="hidden" id="factor-max-points-${factorId}" value="${maxPoints}">
        <span class="badge badge-light mr-1" style="font-size:14px">${maxPoints ? maxPoints : "-"}</span>
    </td>
    <td>
        <input type="hidden" id="factor-products-${factorId}" value="${selectedProducts}">
        <span class="badge badge-light mr-1" style="font-size:14px">${selectedProducts.length}</span>
    </td>
    <td class="d-flex justify-content-around">
        <button id="edit-factor-${factorId}" type="button" class="btn btn-sm btn-barymont-red">
            <i class="fas fa-pen fa-sm" style="pointer-events: none;"></i>
        </button>
        <button id="delete-factor-${factorId}" type="button" class="btn btn-sm btn-barymont-black">
            <i class="fas fa-trash-alt fa-sm" style="pointer-events: none;"></i>
        </button>
    </td>`;

  let factorRow = document.getElementById("factor-" + factorId);

  if (factorRow) {
    factorRow.innerHTML = factorContent;
  } else {
    container.insertAdjacentHTML(
      "beforeend",
      `<tr id="factor-${factorId}" class="text-center">
      ${factorContent}
    </tr>`
    );
  }

  document.getElementById("edit-factor-" + factorId).addEventListener("click", function () {
    showLoadingButton("edit-factor-" + factorId, "btn-barymont-red", true);

    if (document.getElementById("action-" + factorId).value !== "TO_BE_CREATED") {
      setFactorModalAction("TO_BE_EDITED");
    } else {
      setFactorModalAction("TO_BE_CREATED");
    }

    resetFactorModalFields();
    resetTextareasTinyMCE();

    openFactorModal(factorId);
  });

  document.getElementById("delete-factor-" + factorId).addEventListener("click", function () {
    if (document.getElementById("action-" + factorId).value == "TO_BE_CREATED") {
      document.getElementById("factor-" + factorId).remove();
    } else {
      document.getElementById("action-" + factorId).value = "TO_BE_DELETED";
      document.getElementById("factor-" + factorId).classList.add("d-none");
    }

    const totalChildrens = [...document.getElementById(containerId).children] || [];
    let hiddenChildrens = 0;

    totalChildrens.forEach((row) => {
      if (row.classList.contains("d-none")) {
        hiddenChildrens++;
      }
    });

    if (totalChildrens.length === hiddenChildrens) {
      printDefaultStateOnFactorList(containerId);
      return;
    }
  });
}

function setFactorModalAction(action) {
  document.getElementById("modal-ranking-factor-action-to-do").value = action;

  if (action == "TO_BE_CREATED") {
    document.getElementById("form-modal-ranking-factor-title").innerHTML = "Crear factor";
    document.getElementById("btn-modal-ranking-factor-summit").innerHTML = "Crear factor";
    return;
  }

  if (action == "TO_BE_EDITED") {
    document.getElementById("form-modal-ranking-factor-title").innerHTML = "Editar factor";
    document.getElementById("btn-modal-ranking-factor-summit").innerHTML = "Editar factor";
    return;
  }
}

function printDefaultStateOnFactorList(containerId) {
  const container = document.getElementById(containerId);
  const existingMessage = container.querySelector("#defaultStateMessage");

  if (!existingMessage) {
    container.innerHTML = "";

    const defaultStateContent = `
      <tr id="defaultStateMessage">
          <td colspan="8" class="text-center w-100">No hay factores añadidos</td>
      </tr>`;

    container.insertAdjacentHTML("beforeend", defaultStateContent);
  }
}

function setFactorDataOnModalToEditFactorData(factorId) {
  const description = document.getElementById("factor-description-" + factorId).innerHTML;
  const factorType = document.getElementById("factor-type-" + factorId).value;
  const factorScoringType = document.getElementById("factor-scoring-type-" + factorId).value;
  const factorData = document.getElementById("factor-data-status-to-include-" + factorId).value;
  const points = document.getElementById("factor-points-" + factorId).value;
  const minPoints = document.getElementById("factor-min-points-" + factorId).value;
  const maxPoints = document.getElementById("factor-max-points-" + factorId).value;
  const selectedProducts = document.getElementById("factor-products-" + factorId).value;

  document.getElementById("modal-ranking-factor-data-uuid").value = factorId;
  document.getElementById("modal-ranking-factor-data-description").value = description;
  document.getElementById("modal-ranking-factor-data-type").value = factorType;
  document.getElementById("modal-ranking-factor-data-scoring-type").value = factorScoringType;
  document.getElementById("modal-ranking-factor-data-status-to-include").value = factorData;
  document.getElementById("modal-ranking-factor-data-points").value = points;
  document.getElementById("modal-ranking-factor-data-min-points").value = minPoints;
  document.getElementById("modal-ranking-factor-data-max-points").value = maxPoints;

  $("#modal-ranking-factor-data-scoring-type").selectpicker("refresh");
  $("#modal-ranking-factor-data-type").selectpicker("refresh");
  $("#modal-ranking-factor-data-status-to-include").selectpicker("refresh");

  initializeTextareasWithTinyMCE();
  toggleScoringTypeFields();

  if (selectedProducts !== "") {
    setSelectedValuesOnSelectPicker("modal-ranking-factor-data-product-list", selectedProducts.split(","));
  }
}

function checkIfRankingDataIsValid(rankingData) {
  if (!rankingData.name || !rankingData.rankingType || !rankingData.category || !rankingData.rankingCalculationMode || !rankingData.rankingViewMode || !rankingData.startDate || !rankingData.finishDate || !rankingData.lastUpdateDate) {
    createToast("warning", "Datos incompletos", "Debes rellenar todos los campos obligatorios.", 5000);
    return false;
  }

  if (rankingData.name.length < 10 || rankingData.name.length > 255) {
    createToast("warning", "Datos incorrectos", "El nombre del ranking debe tener entre 10 y 255 caracteres.", 5000);
    return false;
  }

  if (rankingData.description.length < 10) {
    createToast("warning", "Datos incorrectos", "La descripcion no puede tener menos de 10 caracteres.", 5000);
    return false;
  }

  if (rankingData.startDate >= rankingData.finishDate) {
    createToast("warning", "Datos incorrectos", "La fecha de inicio no puede ser mayor o igual que la fecha de fin.", 5000);
    return false;
  }

  if (rankingData.finishDate > rankingData.lastUpdateDate) {
    createToast("warning", "Datos incorrectos", "La fecha de fin no puede ser mayor que la fecha de actualizacion.", 5000);
    return false;
  }

  if (rankingData.coverImageBase64 === "_REMOVE_IMAGEN_") {
    createToast("warning", "Datos incompletos", "Debes seleccionar una imagen para el ranking.", 5000);
    return false;
  }

  return true;
}

function checkValidFactorData(description, factorType, factorScoringType, factorData, points, minPoints, maxPoints, selectedProducts, factorAction) {
  if (!factorType || !factorScoringType || !factorData || !points) {
    createToast("warning", "Datos incompletos", "Debes rellenar todos los campos obligatorios.", 5000);
    return false;
  }

  if (description.length < 10) {
    createToast("warning", "Datos incorrectos", "La descripcion no puede tener menos de 10 caracteres.", 5000);
    return false;
  }

  if (points <= 0) {
    createToast("warning", "Datos incorrectos", "El valor de puntos no puede ser menor o igual que 0.", 5000);
    return false;
  }

  if (factorScoringType == "POINTS_X_EURO" && minPoints > maxPoints) {
    createToast("warning", "Datos incorrectos", "El valor de puntos minimos no puede ser mayor que el valor de puntos maximos.", 5000);
    return false;
  }

  if (selectedProducts.length == 0) {
    createToast("warning", "Datos incompletos", "Debes seleccionar al menos un producto.", 5000);
    return false;
  }

  const AllowedFactorActionModes = ["TO_BE_CREATED", "TO_BE_EDITED", "TO_BE_DELETED"];

  if (!AllowedFactorActionModes.includes(factorAction)) {
    console.error("El modo de vista no es correcto, solo se permiten los siguientes modos: " + AllowedFactorActionModes.join(", "));
    return false;
  }

  return true;
}

function getFactorsDataFromTableHiddenInputs() {
  const factorsData = [];

  document
    .getElementById("modal-ranking-factor-data-table-body")
    .querySelectorAll("tr")
    .forEach((row) => {
      if (row.children.length === 1 && document.getElementById("modal-ranking-factor-data-table-body").querySelector("#defaultStateMessage")) {
        return;
      }

      const factorId = row.id.replace("factor-", "");

      const description = document.getElementById(`factor-description-${factorId}`).innerHTML;
      const type = document.getElementById(`factor-type-${factorId}`).value;
      const scoringType = document.getElementById(`factor-scoring-type-${factorId}`).value;
      const dataStatusToInclude = document.getElementById(`factor-data-status-to-include-${factorId}`).value;
      const points = parseFloat(document.getElementById(`factor-points-${factorId}`).value);
      const minPoints = document.getElementById(`factor-min-points-${factorId}`).value;
      const maxPoints = document.getElementById(`factor-max-points-${factorId}`).value;
      const factorProductsIds = document.getElementById(`factor-products-${factorId}`).value.split(",");
      const factorAction = document.getElementById(`action-${factorId}`).value;

      const factorData = {
        factorId: factorId,
        description: description,
        type: type,
        scoringType: scoringType,
        dataStatusToInclude: dataStatusToInclude,
        points: points,
        minPoints: minPoints == "null" || minPoints == "" ? null : parseFloat(minPoints),
        maxPoints: maxPoints == "null" || maxPoints == "" ? null : parseFloat(maxPoints),
        factorProductsIds: factorProductsIds,
        factorAction: factorAction,
      };

      factorsData.push(factorData);
    });

  return factorsData;
}

function getRankingData() {
  const rankingId = document.getElementById("modal-ranking-data-uuid").value;
  const name = document.getElementById("modal-ranking-data-name").value;
  const rankingType = document.getElementById("modal-ranking-data-type").value;
  const category = document.getElementById("modal-ranking-data-category").value;
  const rankingCalculationMode = document.getElementById("modal-ranking-data-calculation-mode").value;
  const rankingViewMode = document.getElementById("modal-ranking-data-view-mode").value;
  const startDate = document.getElementById("modal-ranking-data-start-date").value;
  const finishDate = document.getElementById("modal-ranking-data-finish-date").value;
  const lastUpdateDate = document.getElementById("modal-ranking-data-last-update-date").value;
  const coverImage = document.getElementById("modal-ranking-data-image").src;
  const coverImageBase64 = document.getElementById("modal-ranking-data-image-file-base64").value;
  const description = document.getElementById("modal-ranking-data-description").value;

  const rankingUserIdValue = document.getElementById("modal-ranking-data-uuid-user-list").value;
  const rankingUserId = rankingUserIdValue != "" ? JSON.parse(rankingUserIdValue) : [];

  const rankingFactorsData = getFactorsDataFromTableHiddenInputs();

  return { rankingId, name, rankingType, category, rankingCalculationMode, rankingViewMode, startDate, finishDate, lastUpdateDate, coverImage, coverImageBase64, description, rankingUserId, rankingFactorsData };
}

function selectAllUsersByProfileType(userListId, keyToSelectUsers) {
  const userList = $("#" + userListId);

  const valuesToSelect = userList
    .find("option")
    .filter(function () {
      return $(this)
        .text()
        .includes("[" + keyToSelectUsers + "]");
    })
    .map(function () {
      return [
        {
          value: $(this).val(),
          name: $(this).data("participantName"),
          profileLevel: $(this).data("participantProfileLevelHtml"),
          profileLevelText: $(this).data("profileLevelText"),
          dataContent: $(this).data("content"),
        },
      ];
    })
    .get();

  if (valuesToSelect.length > 0) {
    valuesToSelect.forEach((option) => {
      printNewParticipantSelectedOnRankingModal("modal-participant-ranking-list-new-data-table-body", "modal-ranking-data-uuid-user-list", option.value, option.name, option.profileLevelText, option.profileLevel, option.dataContent);
      userList.find("option[value='" + option.value + "']").remove();
    });
    $("#modal-participant-ranking-data-user-list").selectpicker("refresh");
    updateButonCountersByProfileLevel("modal-participant-ranking-data-user-list");
  }
}

function unselectAllUsersFromTableWithUserPrinted(userTableListId, badgeCounterId, shouldRemoveDataFromTable) {
  const userTableList = document.getElementById(userTableListId);

  userTableList.querySelectorAll("tr").forEach((tr) => {
    if (tr.id.includes("default-state-message")) {
      return;
    }

    removeParticipantIdFromHiddenInput("modal-ranking-data-uuid-user-list", tr.getAttribute("data-user-id"));

    const dataUserId = tr.getAttribute("data-user-id");
    const dataContent = tr.getAttribute("data-content");
    const dataParticipantName = tr.getAttribute("data-participant-name");
    const dataProfileLevelText = tr.getAttribute("data-profile-level-text");
    const dataParticipantProfileLevelHtml = tr.getAttribute("data-participant-profile-level-html");

    const selectWithAvailableParticipants = document.getElementById("modal-participant-ranking-data-user-list");

    if (!selectWithAvailableParticipants.querySelector(`option[value="${dataUserId}"]`)) {
      const userOption = document.createElement("option");
      userOption.value = dataUserId;
      userOption.text = `${dataParticipantName} - [${dataProfileLevelText.replace(/ /g, "-")}]`;
      userOption.setAttribute("data-content", dataContent);
      userOption.setAttribute("data-participant-name", dataParticipantName);
      userOption.setAttribute("data-profile-level-text", dataProfileLevelText.replace(/ /g, "-"));
      userOption.setAttribute("data-participant-profile-level-html", dataParticipantProfileLevelHtml);

      document.getElementById("modal-participant-ranking-data-user-list").appendChild(userOption);
    }

    if (shouldRemoveDataFromTable) {
      tr.remove();
    } else {
      tr.classList.add("d-none");
    }
  });

  document.getElementById(badgeCounterId).textContent = 0;

  $("#modal-participant-ranking-data-user-list").selectpicker("refresh");
  updateButonCountersByProfileLevel("modal-participant-ranking-data-user-list");
}

/* END MODAL CREATE RANKING */

/* START MODAL CLONE RANKING */

async function openCloneRankingModal(buttonActionId, buttonActionLabel, rankingId = null) {
  if (rankingId) {
    await fetchAllRankingDataById(rankingId).then((rankingData) => {
      if (rankingData.status == 0) {
        createToast("danger", rankingData.error.code, rankingData.error.message, 5000);
        return;
      }

      resetClonedRankingFieldsIntoCloneModal();
      createUuid("modal-clone-ranking-data-new-uuid");

      document.getElementById("modal-clone-ranking-data-old-uuid").value = rankingData.data.rankingId;
      document.getElementById("modal-clone-ranking-data-name").value = rankingData.data.name;
      document.getElementById("modal-clone-ranking-data-description").value = rankingData.data.description;

      initializeTextareasWithTinyMCE();
    });
  }
  updateButtonLabel(buttonActionId, buttonActionLabel, "btn-barymont-black");

  $("#modal-clone-ranking").modal("show");
}

async function cloneRankingData(rankingData) {
  let formData = new FormData();

  formData.append("oldRankingId", rankingData.oldRankingId);
  formData.append("newRankingId", rankingData.newRankingId);
  formData.append("name", rankingData.name);
  formData.append("startDate", rankingData.startDate);
  formData.append("finishDate", rankingData.finishDate);
  formData.append("lastUpdateDate", rankingData.lastUpdateDate);
  formData.append("description", rankingData.description);
  formData.append("coverImage", rankingData.coverImage);
  formData.append("coverImageBase64", rankingData.coverImageBase64);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/rankings/ajax/cloneRankingData", requestOptions).then((response) => response.json());
}

function resetClonedRankingFieldsIntoCloneModal() {
  document.getElementById("modal-clone-ranking-data-old-uuid").value = "";
  document.getElementById("modal-clone-ranking-data-new-uuid").value = "";
  document.getElementById("modal-clone-ranking-data-name").value = "";
  document.getElementById("modal-clone-ranking-data-start-date").value = "";
  document.getElementById("modal-clone-ranking-data-finish-date").value = "";
  document.getElementById("modal-clone-ranking-data-last-update-date").value = "";
  document.getElementById("modal-clone-ranking-data-image").src = "https://placehold.co/1920x1080?text=16:9";
  document.getElementById("modal-clone-ranking-data-image-file").value = "";
  document.getElementById("modal-clone-ranking-data-image-file-base64").value = "";

  resetTextareasTinyMCE();
}

function getCloneRankingData() {
  const oldRankingId = document.getElementById("modal-clone-ranking-data-old-uuid").value;
  const newRankingId = document.getElementById("modal-clone-ranking-data-new-uuid").value;
  const name = document.getElementById("modal-clone-ranking-data-name").value;
  const startDate = document.getElementById("modal-clone-ranking-data-start-date").value;
  const finishDate = document.getElementById("modal-clone-ranking-data-finish-date").value;
  const lastUpdateDate = document.getElementById("modal-clone-ranking-data-last-update-date").value;
  const coverImage = document.getElementById("modal-clone-ranking-data-image").src;
  const coverImageBase64 = document.getElementById("modal-clone-ranking-data-image-file-base64").value;
  const description = document.getElementById("modal-clone-ranking-data-description").value;

  return { oldRankingId, newRankingId, name, startDate, finishDate, lastUpdateDate, coverImage, coverImageBase64, description };
}

function checkIfCloneRankingDataIsValid(rankingData) {
  if (!rankingData.name || !rankingData.startDate || !rankingData.finishDate || !rankingData.lastUpdateDate) {
    createToast("warning", "Datos incompletos", "Debes rellenar todos los campos obligatorios.", 5000);
    return false;
  }

  if (rankingData.name.length < 10 || rankingData.name.length > 255) {
    createToast("warning", "Datos incorrectos", "El nombre del ranking debe tener entre 10 y 255 caracteres.", 5000);
    return false;
  }

  if (rankingData.description.length < 10) {
    createToast("warning", "Datos incorrectos", "La descripcion no puede tener menos de 10 caracteres.", 5000);
    return false;
  }

  if (rankingData.startDate >= rankingData.finishDate) {
    createToast("warning", "Datos incorrectos", "La fecha de inicio no puede ser mayor o igual que la fecha de fin.", 5000);
    return false;
  }

  if (rankingData.finishDate > rankingData.lastUpdateDate) {
    createToast("warning", "Datos incorrectos", "La fecha de fin no puede ser mayor que la fecha de actualizacion.", 5000);
    return false;
  }

  if (rankingData.coverImageBase64 === "_REMOVE_IMAGEN_") {
    createToast("warning", "Datos incompletos", "Debes seleccionar una imagen para el ranking.", 5000);
    return false;
  }

  return true;
}

/* END MODAL CLONE RANKING */

/* START MODAL PARTICIPANTS LIST RANKING */

async function fetchAllRankingParticipantsDataById(rankingId) {
  let formData = new FormData();

  formData.append("rankingId", rankingId);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/rankings/ajax/getRankingParticipantsData", requestOptions).then((response) => response.json());
}

async function setExtraPointsToUser(rankingId, userId, extraPoints, data) {
  let formData = new FormData();

  formData.append("rankingId", rankingId);
  formData.append("userId", userId);
  formData.append("extraPoints", extraPoints);
  formData.append("data", data);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/rankings/ajax/setExtraPointsToUser", requestOptions).then((response) => response.json());
}

async function openParticipantsListRankingManagementModal(buttonActionId, buttonActionLabel) {
  const rankingParticipantsData = document.getElementById("modal-ranking-data-uuid-user-list").value !== "" ? JSON.parse(document.getElementById("modal-ranking-data-uuid-user-list").value) : [];

  setRankingUserListDataOnRankingModal(rankingParticipantsData).then(() => {
    updateButtonLabel(buttonActionId, buttonActionLabel, "btn-barymont-black");
    updateButonCountersByProfileLevel("modal-participant-ranking-data-user-list");
    $("#modal-participant-ranking-list-data").modal("show");
    $('[data-toggle="tooltip"]').tooltip();
  });
}

function updateButonCountersByProfileLevel(userListId) {
  const userList = document.getElementById(userListId);
  if (!userList) {
    console.error("User list not found:", userListId);
    return;
  }

  let badgesValuesByProfileLevel = { ALL: 0 };

  document.querySelectorAll('[id^="user-profile-level-counter-"]').forEach((badge) => {
    if (badge && badge.id) {
      badgesValuesByProfileLevel[badge.id.replace("user-profile-level-counter-", "").toUpperCase()] = 0;
    }
  });

  userList.querySelectorAll("option").forEach((option) => {
    const profileLevel = option.getAttribute("data-profile-level-text");
    if (profileLevel) {
      badgesValuesByProfileLevel[profileLevel.toUpperCase().replace(/ /g, "-")] = (badgesValuesByProfileLevel[profileLevel.toUpperCase().replace(/ /g, "-")] || 0) + 1;
      badgesValuesByProfileLevel["ALL"]++;
    }
  });

  Object.entries(badgesValuesByProfileLevel).forEach(([key, value]) => {
    if (key === "NULL") {
      return;
    }
    const badgeElement = document.getElementById(`user-profile-level-counter-${key.toLowerCase().replace(/ /g, "-")}`);
    if (badgeElement) badgeElement.textContent = value;
  });
}

function updateUserListCounter(badgeTextId, increment) {
  const badgeText = document.getElementById(badgeTextId).textContent;

  if (increment) {
    document.getElementById(badgeTextId).textContent = parseInt(badgeText) + 1;
  } else {
    document.getElementById(badgeTextId).textContent = parseInt(badgeText) - 1;
  }
}

function printCurrentParticipantOnRankingModal(containerId, inputHiddenId, participantId, participantName, participantProfileLevel, participantProfileLevelHtmlData, participantDataContent, participantExtraPoints, participantBaseScore, participantFinalScore, participantPosition, participantAdditionalData, rankingId) {
  const currentParticipantsUuids = document.getElementById(inputHiddenId).value !== "" ? JSON.parse(document.getElementById(inputHiddenId).value) : [];

  const participantData = `
    <tr id="participant-${participantId}"
        data-position="${participantPosition === null ? "-" : participantPosition}"
        data-user-id="${participantId}"
        data-content="${participantDataContent}"
        data-participant-name="${participantName}"
        data-profile-level-text="${participantProfileLevel.replace(/ /g, "-")}"
        data-participant-profile-level-html="${participantProfileLevelHtmlData}"
        data-final-score="${participantFinalScore.toLocaleString() ?? 0}"
        class="${!currentParticipantsUuids.includes(participantId) ? "d-none" : ""}"
      >
        <td class="text-center">${participantPosition === null ? "-" : participantPosition}</td>
        <td class="text-center">${participantProfileLevelHtmlData}</td>
        <td>${participantName}</td>
        <td><input type="text" class="form-control font-weight-bolder text-right px-1" value="${participantBaseScore.toLocaleString() ?? 0}" disabled></td>
        <td><input type="text" class="form-control font-weight-bolder text-right px-1" value="${participantExtraPoints.toLocaleString() ?? 0}" disabled></td>
        <td><input type="text" class="form-control font-weight-bolder text-right px-1" value="${participantFinalScore.toLocaleString() ?? 0}" disabled></td>
        <td class="text-center">
            <button type="button"
                class='btn btn-sm btn-barymont-red'
                id="breackdown-data-points-participant-${participantId}"
            >
              <i class="fas fa-sitemap fa-sm"></i>
            </button>
            <button type="button"
                class='btn btn-sm btn-barymont-red'
                id="edit-participant-${participantId}"
                data-uuid="${participantId}"
            >
                <i class="fas fa-pen fa-sm"></i>
            </button>
            <button type="button"
                class='btn btn-sm btn-barymont-black'
                id="delete-participant-${participantId}"
                data-uuid="${participantId}"
            >
                <i class="fas fa-trash-alt fa-sm"></i>
            </button>
        </td>
    </tr>`;

  document.getElementById(containerId).insertAdjacentHTML("beforeend", participantData);

  checkIfDefaultMessageShouldBeShownOnParticipantsListRankingModal(containerId, "participant-list-current-default-state-message");

  document.getElementById("delete-participant-" + participantId).addEventListener("click", function () {
    document.getElementById("modal-participant-ranking-list-data-export-button")?.classList.add("d-none");
    document.getElementById("participant-" + participantId).classList.add("d-none");
    removeParticipantIdFromHiddenInput(inputHiddenId, participantId);

    const option = document.createElement("option");
    option.value = participantId;
    option.text = `${participantName} - [${participantProfileLevel.toUpperCase().replace(/ /g, "-")}]`;
    option.setAttribute("data-content", participantDataContent);
    option.setAttribute("data-participant-name", participantName);
    option.setAttribute("data-profile-level-text", participantProfileLevel.toUpperCase().replace(/ /g, "-"));
    option.setAttribute("data-participant-profile-level-html", participantProfileLevelHtmlData);

    document.getElementById("modal-participant-ranking-data-user-list").appendChild(option);

    const totalChildrens = [...document.getElementById(containerId).children] || [];
    let hiddenChildrens = 0;

    totalChildrens.forEach((row) => {
      if (row.id == "participant-list-current-default-state-message") {
        hiddenChildrens++;
      }
    });

    if (totalChildrens.length === hiddenChildrens) {
      document.getElementById("participant-list-current-default-state-message").classList.remove("d-none");
    }

    updateUserListCounter("modal-ranking-data-current-user-list-badge", false);
    updateButonCountersByProfileLevel("modal-participant-ranking-data-user-list");
    checkIfDefaultMessageShouldBeShownOnParticipantsListRankingModal("modal-participant-ranking-list-current-data-table-body", "participant-list-current-default-state-message");
    $("#modal-participant-ranking-data-user-list").selectpicker("refresh");
  });

  document.getElementById("edit-participant-" + participantId).addEventListener("click", function () {
    document.getElementById("modal-participant-ranking-edit-data-user-id").value = participantId;
    document.getElementById("modal-participant-ranking-edit-data-user-name").value = participantName;
    document.getElementById("modal-participant-ranking-edit-data-actual-score").value = participantBaseScore;
    document.getElementById("modal-participant-ranking-edit-data-extra-score").value = participantExtraPoints;
    document.getElementById("modal-participant-ranking-edit-data-final-calc-score").value = participantFinalScore;
    document.getElementById("modal-participant-ranking-additional-data").value = participantAdditionalData ?? "";
    tinymce.get("modal-participant-ranking-additional-data").setContent(participantAdditionalData ?? "");

    $("#modal-participant-ranking-edit-data").modal("show");
  });

  document.getElementById("breackdown-data-points-participant-" + participantId).addEventListener("click", function () {
    fetchRankingParticipantBreakdownData(participantId, rankingId).then((response) => {
      if (response.status) {
        printRankingParticipantBreakdownData(response.data, participantExtraPoints, participantFinalScore);

        document.getElementById("modal-participant-breakdown-export-data-button").addEventListener("click", function () {
          showLoadingButton("modal-participant-breakdown-export-data-button", "btn-barymont-red", true);

          fetchExportRankingCurrentParticipantBreakdownData(rankingId, participantId).then((response) => {
            if (response.status) {
              let exportData = response.data;
              let blob = new Blob([exportData], { type: "application/vnd.ms-excel;" });
              let url = URL.createObjectURL(blob);
              window.location.href = url;
            } else {
              createToast("error", "❌ Error", "Se ha producido un error al realizar el proceso de exportación...", 5000);
            }
            updateButtonLabel("modal-participant-breakdown-export-data-button", "<i class='fas fa-file-export mr-2'></i> Exportar desglose", "btn-barymont-red");
          });
        });

        $("#modal-participant-breakdown-ranking").modal("show");
      } else {
        createToast("warning", "Ha ocurrido un error", "No se ha podido obtener el desglose de puntos del participante, intentelo de nuevo", 5000);
      }
    });
  });
}

function printNewParticipantSelectedOnRankingModal(containerId, inputHiddenId, participantId, participantName, participantProfileLevel, participantProfileLevelHtmlData, participantDataContent) {
  document.getElementById("modal-participant-ranking-list-data-export-button")?.classList.add("d-none");

  addParticipantIdToHiddenInput(inputHiddenId, participantId);

  if (document.getElementById("participant-" + participantId)) {
    document.getElementById("participant-" + participantId).classList.remove("d-none");
    incrementCounter("modal-ranking-data-current-user-list-badge");
    checkIfDefaultMessageShouldBeShownOnParticipantsListRankingModal("modal-participant-ranking-list-current-data-table-body", "participant-list-current-default-state-message");
    return;
  } else {
    incrementCounter("modal-ranking-data-new-user-list-badge");
  }

  const participantData = `
    <tr id="new-participant-${participantId}"
        data-position="-"
        data-user-id="${participantId}"
        data-content="${participantDataContent}"
        data-participant-name="${participantName}"
        data-profile-level-text="${participantProfileLevel.toUpperCase().replace(/ /g, "-")}"
        data-participant-profile-level-html="${participantProfileLevelHtmlData}"
        data-final-score="0"
      >
        <td class="text-center">${participantProfileLevelHtmlData}</td>
        <td>${participantName}</td>
        <td class="text-center">
            <button type="button"
                class='btn btn-sm btn-barymont-black'
                id="delete-new-participant-${participantId}"
                data-uuid="${participantId}"
            >
                <i class="fas fa-trash-alt fa-sm"></i>
            </button>
        </td>
    </tr>`;
  document.getElementById(containerId).insertAdjacentHTML("beforeend", participantData);

  document.getElementById("delete-new-participant-" + participantId).addEventListener("click", function () {
    document.getElementById("new-participant-" + participantId).remove();
    removeParticipantIdFromHiddenInput(inputHiddenId, participantId);

    const option = document.createElement("option");
    option.value = participantId;
    option.text = `${participantName} - [${participantProfileLevel.toUpperCase().replace(/ /g, "-")}]`;
    option.setAttribute("data-content", participantDataContent);
    option.setAttribute("data-participant-name", participantName);
    option.setAttribute("data-profile-level-text", participantProfileLevel.toUpperCase().replace(/ /g, "-"));
    option.setAttribute("data-participant-profile-level-html", participantProfileLevelHtmlData);

    document.getElementById("modal-participant-ranking-data-user-list").appendChild(option);
    $("#modal-participant-ranking-data-user-list").selectpicker("refresh");

    const totalChildrens = [...document.getElementById(containerId).children] || [];
    let hiddenChildrens = 0;

    totalChildrens.forEach((row) => {
      if (row.id == "participant-list-new-default-state-message") {
        hiddenChildrens++;
      }
    });

    if (totalChildrens.length === hiddenChildrens) {
      document.getElementById("participant-list-new-default-state-message").classList.remove("d-none");
    }

    updateUserListCounter("modal-ranking-data-new-user-list-badge", false);
    updateButonCountersByProfileLevel("modal-participant-ranking-data-user-list");
    checkIfDefaultMessageShouldBeShownOnParticipantsListRankingModal("modal-participant-ranking-list-new-data-table-body", "participant-list-new-default-state-message");
  });

  document.getElementById("modal-participant-ranking-data-user-list").querySelector(`option[value="${participantId}"]`).remove();
}

function orderParticipantsWithNullScoreByNameOnRankingModal(containerId) {
  const container = document.getElementById(containerId);
  if (!container) return;

  Array.from(container.children)
    .filter((participant) => !participant.classList.contains("d-none") && participant.dataset.position === "-" && participant.dataset.finalScore === "0")
    .sort((a, b) => a.dataset.participantName.localeCompare(b.dataset.participantName))
    .forEach((participant) => container.appendChild(participant));
}

function checkIfDefaultMessageShouldBeShownOnParticipantsListRankingModal(containerId, defaultMessageId) {
  const totalChildrens = [...document.getElementById(containerId).children] || [];
  let hiddenChildrens = 0;

  totalChildrens.forEach((row) => {
    if (row.id == defaultMessageId || row.classList.contains("d-none")) {
      hiddenChildrens++;
    }
  });

  if (totalChildrens.length === hiddenChildrens) {
    document.getElementById(defaultMessageId).classList.remove("d-none");
  } else {
    document.getElementById(defaultMessageId).classList.add("d-none");
  }
}

function addParticipantIdToHiddenInput(inputHiddenId, participantId) {
  let participantsIds = [];

  if (document.getElementById(inputHiddenId).value !== "") {
    participantsIds = JSON.parse(document.getElementById(inputHiddenId).value);
  }

  participantsIds.push(participantId);

  document.getElementById(inputHiddenId).value = JSON.stringify(participantsIds);
  document.getElementById(inputHiddenId).dispatchEvent(new Event("change"));
}

function removeParticipantIdFromHiddenInput(inputHiddenId, participantId) {
  const participantsIds = JSON.parse(document.getElementById(inputHiddenId).value);

  const index = participantsIds.indexOf(participantId);

  if (index > -1) {
    participantsIds.splice(index, 1);
  }

  document.getElementById(inputHiddenId).value = JSON.stringify(participantsIds);
  document.getElementById(inputHiddenId).dispatchEvent(new Event("change"));
}

/* END MODAL PARTICIPANTS LIST RANKING */

/* START MODAL BREACKDOWN USER DATAPOINTS RANKING */

async function printRankingParticipantBreakdownData(breackdownData, participantExtraPoints, participantFinalScore) {
  document.getElementById("modal-participant-breakdown-total-points").value = formatScoreNumber(participantFinalScore);

  if (participantExtraPoints > 0) {
    document.getElementById("modal-participant-breakdown-extra-points").value = formatScoreNumber(participantExtraPoints);
    document.getElementById("modal-col-participant-breakdown-extra-points").classList.remove("d-none");
  } else {
    document.getElementById("modal-participant-breakdown-extra-points").value = 0;
    document.getElementById("modal-col-participant-breakdown-extra-points").classList.add("d-none");
  }

  document.getElementById("modal-participant-breakdown-table-body").innerHTML = "";

  if (breackdownData.factors.length === 0) {
    document.getElementById("modal-participant-breakdown-table-body").innerHTML = "<tr><td colspan='3' class='text-center w-100'>No hay datos</td></tr>";
    return;
  }

  breackdownData.factors.forEach((factorData) => {
    const breackdownDataContent = `
      <tr>
        <td>${factorData.description}</td>
        <td><input type="text" class="form-control text-right font-weight-bolder" value="${formatScoreNumber(factorData.score)}" disabled></td>
        <td class="text-center">
          <button type="button"
              class='btn btn-sm btn-barymont-red w-100'
              id="factor-breackdown-data-${factorData.factorId}"
          >
              <i class="fas fa-info px-3"></i>
          </button>
        </td>
      </tr>
    `;

    document.getElementById("modal-participant-breakdown-table-body").insertAdjacentHTML("beforeend", breackdownDataContent);

    document.getElementById(`factor-breackdown-data-${factorData.factorId}`).addEventListener("click", function () {
      printRankingParticipantFactorBreackdownData(factorData.scoreDetails, factorData.scoreIdentifierSubject, factorData.score, factorData.description, factorData.scoringTypeText);
      $("#modal-participant-breakdown-factor-ranking").modal("show");

      document.getElementById("modal-participant-breakdown-factor-product-list-button-container").innerHTML = `<button id="modal-participant-breakdown-factor-product-list-button-${factorData.factorId}" class="btn btn-barymont-grey w-100 mb-3">Ver los productos incluidos</button>`;

      document.getElementById(`modal-participant-breakdown-factor-product-list-button-${factorData.factorId}`).addEventListener("click", function () {
        const tableBreakdownProductListId = "modal-participant-breakdown-factor-table-product-list-body";

        document.getElementById(tableBreakdownProductListId).innerHTML = "";

        if (factorData.factorProductsDetails.length === 0) {
          document.getElementById(tableBreakdownProductListId).innerHTML = "<tr><td colspan='4' class='text-center w-100'>No hay productos incluidos</td></tr>";
        } else {
          factorData.factorProductsDetails.forEach((factorProductDetails) => {
            const factorProductDetailsContent = `
            <tr>
              <td>${factorProductDetails.name}</td>
              <td>${factorProductDetails.family}</td>
              <td>${factorProductDetails.companyName}</td>
              <td>${factorProductDetails.ebrokerProductId}</td>
            </tr>
          `;

            document.getElementById(tableBreakdownProductListId).insertAdjacentHTML("beforeend", factorProductDetailsContent);
          });
        }
        $("#modal-participant-breakdown-factor-product-list-ranking").modal("show");
      });
    });
  });
}

function printRankingParticipantFactorBreackdownData(factorBreackdownData, factorIdentifierSubject, factorScore, description, scoringTypeText) {
  document.getElementById("modal-participant-breakdown-factor-total-points").value = formatScoreNumber(factorScore);

  document.getElementById("modal-participant-breakdown-factor-table-body").innerHTML = "";
  document.getElementById("modal-participant-breakdown-factor-name").innerHTML = description;
  document.getElementById("modal-participant-breakdown-factor-obtainable-points-text").innerHTML = scoringTypeText;

  if (factorBreackdownData.length === 0) {
    document.getElementById("modal-participant-breakdown-factor-table-body").innerHTML = "<tr><td colspan='3' class='text-center w-100'>Todavía no se han conseguido puntos en este factor</td></tr>";
    return;
  }

  factorBreackdownData.forEach((factorUserScoreDetails) => {
    const factorBreackdownDataContent = `
      <tr>
        <td>${factorIdentifierSubject} - ${factorUserScoreDetails.id}</td>
        <td><input type="text" class="form-control text-right font-weight-bolder py-0" style="height: 20px;" value="${formatScoreNumber(factorUserScoreDetails.score)}" disabled></td>
        <td class="text-center">${factorUserScoreDetails.participationPercentajeAsString}</td>
      </tr>
    `;

    document.getElementById("modal-participant-breakdown-factor-table-body").insertAdjacentHTML("beforeend", factorBreackdownDataContent);
  });
}

/* END MODAL BREACKDOWN USER DATAPOINTS RANKING */

/* START MODAL EXPORT PARTICIPANTS LIST RANKING */

async function fetchExportRankingParticipantsList(rankingId) {
  let formData = new FormData();

  formData.append("rankingId", rankingId);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/rankings/ajax/exportRankingParticipantsList", requestOptions).then((response) => response.json());
}

async function fetchExportRankingCurrentParticipantBreakdownData(rankingId, userId) {
  let formData = new FormData();

  formData.append("rankingId", rankingId);
  formData.append("userId", userId);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/rankings/ajax/exportRankingCurrentParticipantBreakdownData", requestOptions).then((response) => response.json());
}

/* END MODAL EXPORT PARTICIPANTS LIST RANKING */

/* START MODAL DELETE RANKING */

async function deleteRanking(rankingId) {
  let formData = new FormData();

  formData.append("rankingId", rankingId);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/rankings/ajax/deleteRanking", requestOptions).then((response) => response.json());
}

/* END MODAL DELETE RANKING */

/* START MODAL RANKING ACHIEVEMENT AWARDED */

async function fetchAllRankingDataByIdAndRankingAchievementTypes(rankingId) {
  let formData = new FormData();

  formData.append("rankingId", rankingId);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/rankings/ajax/getAllRankingDataByIdAndRankingAchievementTypes", requestOptions).then((response) => response.json());
}

async function fetchGiveAchievementFromRanking(rankingId) {
  let formData = new FormData();

  formData.append("rankingId", rankingId);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/rankings/ajax/giveAchievementFromRanking", requestOptions).then((response) => response.json());
}

async function openAchievementRankingModal(buttonActionId, buttonActionLabel, rankingId) {
  if (rankingId) {
    await fetchAllRankingDataByIdAndRankingAchievementTypes(rankingId).then((rankingData) => {
      if (rankingData.status == 0) {
        createToast("danger", rankingData.error.code, rankingData.error.message, 5000);
        return;
      }

      const rankingAchievementModalBodyId = "modal-achievement-ranking-body";

      if (rankingData.data === null) {
        document.getElementById(rankingAchievementModalBodyId).innerHTML = `<div class="d-block text-center w-100">
              <div class='alert alert-info w-100 text-center h5 font-weight-bold' role='alert'>Los logros de este ranking ya han sido otorgados</div>
              <p class="text-center">si necesitas modificarlos, puedes hacerlo desde la herramienta de administración de logros.</p>
              <a href="/herramientas/logros-management" class="btn btn-barymont-red">Herramienta de administración de logros</a>
          </div>`;

        document.getElementById("modal-achievement-ranking-btn-summit").classList.add("d-none");
        updateButtonLabel(buttonActionId, buttonActionLabel, "btn-barymont-green");
        return;
      } else {
        document.getElementById("modal-achievement-ranking-btn-summit").classList.remove("d-none");
      }

      document.getElementById("modal-achievement-ranking-ranking-id").value = rankingId;

      document.getElementById(rankingAchievementModalBodyId).innerHTML = "";

      rankingData.data.forEach((achievementRanking) => {
        let colSize;
        switch (achievementRanking.achievementType.value) {
          case "PODIUM_TOP_3":
          case "RANKING_TOP_10":
            colSize = "col-lg-6";
            break;
          default:
            colSize = "col-lg-4";
            break;
        }

        let achievementRankingDataContent = `
          <div class="${colSize}">
              <div class="card mb-3">
                  <div class="card-header">
                      <h5 class="card-title mb-0 d-flex justify-content-center align-items-center">
                          <img src="${achievementRanking.achievementType.image}" alt="Podium Top 3" class="img-fluid" style="max-width: 50px;">
                          <span class="h4 ml-3">${achievementRanking.achievementType.label}</span>
                      </h5>
                  </div>
                  <div class="card-body px-2">
                      <h6 class="card-subtitle mb-2 text-muted text-center">${achievementRanking.achievementType.description}</h6>
                      <ul id="ranking-achievement-${achievementRanking.achievementType.value}-users-list" class="list-group list-group-flush">
                          ${achievementRanking.rankingUsers
                            .map(
                              (user) => `<li class="d-flex justify-content-between align-items-center mb-1">
                                            <img src="${user.avatarPath}" alt="${user.userName}" class="img-fluid mr-1" style="max-width: 30px;">
                                            <input type="text" class="form-control" value="${user.userName}" disabled readonly>
                                        </li>`
                            )
                            .join("")}
                      </ul>
                  </div>
              </div>
          </div>`;

        document.getElementById(rankingAchievementModalBodyId).insertAdjacentHTML("beforeend", achievementRankingDataContent);
      });
      updateButtonLabel(buttonActionId, buttonActionLabel, "btn-barymont-black");
    });
  }

  $("#modal-achievement-ranking").modal("show");
}

/* END MODAL RANKING ACHIEVEMENT AWARDED */

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname.includes("/herramientas/rankings-management")) {
      $("#ranking-management-table").DataTable({
        language: {
          sProcessing: "Procesando Datos...",
          sLengthMenu: "Mostrar _MENU_ Rankings",
          sZeroRecords: "No se encontraron rankings coincidentes con el criterio de búsqueda.",
          sEmptyTable: "Ningún ranking coincidente con el criterio de búsqueda.",
          sInfo: "Mostrando rankings del _START_ al _END_ de un total de _TOTAL_ rankings",
          sInfoEmpty: "Mostrando rankings del 0 al 0 de un total de 0 rankings",
          sInfoFiltered: "(Filtrados de un total de _MAX_ rankings)",
          sInfoPostFix: "",
          sSearch: "<div class='input-group-prepend'><div class='input-group-text'><i class='fas fa-search'></i></div></div>",
          searchPlaceholder: "Cualquier dato del ranking...",
          sUrl: "",
          sInfoThousands: ",",
          sLoadingRecords: "Cargando Datos...",
          oPaginate: {
            sFirst: "Primera",
            sLast: "Última",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
          oAria: {
            sSortAscending: ": Activar para ordenar la columna de manera ascendente",
            sSortDescending: ": Activar para ordenar la columna de manera descendente",
          },
          buttons: {
            copySuccess: {
              1: "Copiado 1 Rankings",
              _: "Copiados %d Rankings al Portapapeles",
            },
            copyTitle: "Rankings Copiados al Portapapeles",
          },
          select: {
            rows: "%d Rankings seleccionados",
          },
        },
        pagingType: "input",
        columnDefs: [
          {
            targets: [1, 6, 7, 8],
            orderable: true,
          },
          {
            targets: [0, 2, 3, 4, 5, 9, 10, 11, 12, 13],
            orderable: false,
          },
          {
            targets: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
            searchable: false,
          },
          {
            targets: [0, 1],
            searchable: true,
          },
          {
            targets: [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13],
            className: "dt-center",
          },
          {
            targets: [0, 9],
            visible: false,
          },
        ],
        order: [[6, "desc"]],
        processing: true,
        serverSide: true,
        scrollX: true,
        dom: '<"top"fl>Brt<"bottom"ip><"clear">',
        buttons: [
          {
            extend: "colvis",
            text: "Ver más columnas",
          },
          {
            extend: "collection",
            text: "Herramientas",
            buttons: [
              {
                extend: "copyHtml5",
                text: "Copiar",
              },
              {
                extend: "print",
                text: "Imprimir",
              },
            ],
            fade: true,
          },
          {
            text: '<i class="fas fa-eraser mr-2"></i> Limpiar filtros',
            className: "btn btn-barymont-black my-2 my-md-0",
            action: function () {
              resetearFiltros();
            },
            init: function (api, node) {
              $(node).removeClass("dt-button");
            },
          },
          {
            attr: {
              id: "create-ranking-button",
            },
            text: '<i class="fas fa-plus mr-2"></i> Crear ranking',
            className: "btn btn-barymont-red my-2 my-md-0",
            action: function () {},
            init: function (api, node) {
              $(node).removeClass("dt-button");
            },
          },
        ],
        pageLength: 10,
        lengthMenu: [
          [10, 25, 50],
          [10, 25, 50],
        ],
        select: false,
        keys: true,
        searchHighlight: true,
        ajax: {
          url: "/herramientas/rankings-management/listaprocessing",
          type: "post",
          data: function (d) {
            const checkRankingTypeFilter = $("#ranking-type-filter").val();
            const checkRankingCategoryFilter = $("#ranking-category-filter").val();
            const checkRankingStatusFilter = $("#ranking-status-filter").val();
            const checkRankingCalculationModeFilter = $("#ranking-calculation-mode-filter").val();
            const checkRankingViewModeFilter = $("#ranking-view-mode-filter").val();
            const checkRankingStartDateFilter = $("#ranking-start-date").val();
            const checkRankingFinishDateFilter = $("#ranking-finish-date").val();

            d.rankingType = checkRankingTypeFilter != "" ? checkRankingTypeFilter : "";
            d.rankingCategory = checkRankingCategoryFilter != "" ? checkRankingCategoryFilter : "";
            d.rankingStatus = checkRankingStatusFilter != "" ? checkRankingStatusFilter : "";
            d.rankingCalculationMode = checkRankingCalculationModeFilter != "" ? checkRankingCalculationModeFilter : "";
            d.rankingViewMode = checkRankingViewModeFilter != "" ? checkRankingViewModeFilter : "";
            d.rankingStartDate = checkRankingStartDateFilter != "" ? checkRankingStartDateFilter : "";
            d.rankingFinishDate = checkRankingFinishDateFilter != "" ? checkRankingFinishDateFilter : "";
          },
          error: function (e) {
            if (e.status === 401) {
              createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
              setTimeout(function () {
                location.reload();
              }, 5000);
            } else {
              createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
            }
          },
        },
        fnDrawCallback: function () {
          $('[data-toggle="tooltip"]').tooltip();
        },
        stateSave: true,
        stateSaveCallback: function (settings, data) {
          let storageKey = `${FILTER_STATE_LOCAL_STORAGE_KEY}${settings.sInstance}_${getCookieValue("user_id")}`;

          data.customFilter = getStateOfCustomFilters();

          localStorage.setItem(storageKey, JSON.stringify(data));
        },
        stateLoadCallback: function (settings) {
          let storageKey = `${FILTER_STATE_LOCAL_STORAGE_KEY}${settings.sInstance}_${getCookieValue("user_id")}`;

          if (localStorage.getItem(storageKey) === null) {
            return false;
          }

          let localStorageData = JSON.parse(localStorage.getItem(storageKey));

          if (localStorageData.time < new Date().getTime() - FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME) {
            return false;
          }

          recreateStateOfCustomFilters(localStorageData);

          return localStorageData;
        },
      });

      const params = new URLSearchParams(document.location.search);

      window.history.replaceState({}, document.title, window.location.pathname);

      const actionMode = params.get("mode");
      const rankingId = params.get("rankingId");

      if (actionMode == "edit") {
        createToast("info", "Cargando datos", "Se estan cargando los datos del ranking, espere por favor...", 2000);
      }

      if (actionMode == "create") {
        setRankingModalState("create");
        openRankingModal("modal-ranking-data-uuid-user-list", "modal-ranking-data-uuid-user-list-badge", "create-ranking", `<i class="fas fa-plus mr-2"></i> Crear ranking`);
        createUuid("modal-ranking-data-uuid");
      }

      if (actionMode == "edit" && rankingId) {
        setRankingModalState("edit");
        openRankingModal("modal-ranking-data-uuid-user-list", "modal-ranking-data-uuid-user-list-badge", `edit-ranking-${rankingId}`, `<i class="fas fa-edit" style="pointer-events: none;"></i>`, rankingId);
      }

      if (actionMode == "clone" && rankingId) {
        openCloneRankingModal("clone-ranking", `<i class="fas fa-copy fa-sm"style="pointer-events: none;"></i>`, rankingId);
      }

      document.getElementById("ranking-management-container").addEventListener("click", function (event) {
        const rankingId = event.target.getAttribute("data-uuid");

        if (event.target.matches(".edit-ranking-btn-to-open-edit-modal")) {
          openRankingModal("modal-ranking-data-uuid-user-list", "modal-ranking-data-uuid-user-list-badge", "edit-ranking-" + rankingId, `<i class="fas fa-edit" style="pointer-events: none;"></i>`, rankingId);
        }

        if (event.target.matches(".clone-ranking-btn-to-open-clone-modal")) {
          showLoadingButton(`clone-ranking-${rankingId}`, "btn-barymont-grey", true);
          openCloneRankingModal(`clone-ranking-${rankingId}`, `<i class="fas fa-clone"style="pointer-events: none;"></i>`, rankingId);
        }

        if (event.target.matches(".achievement-ranking-btn-to-open-achievement-modal")) {
          showLoadingButton(`achievement-ranking-${rankingId}`, "btn-barymont-grey", true);
          openAchievementRankingModal(`achievement-ranking-${rankingId}`, `<i class="fas fa-medal" style="pointer-events: none;"></i>`, rankingId);
        }
      });

      document.getElementById("modal-clone-ranking-btn-summit")?.addEventListener("click", function () {
        showLoadingButton("modal-clone-ranking-btn-summit", "btn-barymont-red", false);

        if (checkIfCloneRankingDataIsValid(getCloneRankingData())) {
          cloneRankingData(getCloneRankingData()).then((response) => {
            updateButtonLabel("modal-clone-ranking-btn-summit", "Clonar ranking", "btn-barymont-red");

            if (response.status) {
              window.open("/rankings/" + response.data.rankingId, "_blank");
              return;
            } else {
              createToast("danger", response.error.code, response.error.message, 5000);
            }
          });
        } else {
          updateButtonLabel("modal-clone-ranking-btn-summit", "Clonar ranking", "btn-barymont-red");
        }
      });

      document.getElementById("modal-participant-ranking-list-data-export-button")?.addEventListener("click", function () {
        showLoadingButton("modal-participant-ranking-list-data-export-button", "btn-barymont-red", true);

        fetchExportRankingParticipantsList(document.getElementById("modal-ranking-data-uuid").value).then((response) => {
          if (response.status) {
            let exportData = response.data.exportData;
            let blob = new Blob([exportData], { type: "application/vnd.ms-excel;" });
            let url = URL.createObjectURL(blob);
            window.location.href = url;
          } else {
            createToast("error", "❌ Error", "Se ha producido un error al realizar el proceso de exportación...", 5000);
          }
          updateButtonLabel("modal-participant-ranking-list-data-export-button", "Exportar listado de usuarios", "btn-barymont-red");
        });
      });

      document.getElementById("modal-ranking-data-button-open-modal-confirm-remove-ranking").addEventListener("click", function () {
        $("#modal-ranking-confirm-remover").modal("show");
      });

      document.getElementById("modal-ranking-confirm-remover-button").addEventListener("click", function () {
        const rankingId = document.getElementById("modal-ranking-data-uuid").value;

        if (rankingId) {
          deleteRanking(rankingId).then((response) => {
            if (response.status) {
              createToast("success", "Ranking eliminado", "El ranking se ha eliminado correctamente.", 2500);

              $("#ranking-management-table").DataTable().ajax.reload();
              $("#modal-ranking-confirm-remover").modal("hide");
              $("#modal-ranking").modal("hide");
            } else {
              createToast("danger", response.error.code, response.error.message, 5000);
            }
          });
        }
      });

      document.getElementById("modal-achievement-ranking-btn-summit").addEventListener("click", function () {
        showLoadingButton("modal-achievement-ranking-btn-summit", "btn-barymont-red", true);

        const rankingId = document.getElementById("modal-achievement-ranking-ranking-id").value;

        fetchGiveAchievementFromRanking(rankingId).then((response) => {
          if (response.status) {
            createToast("success", "Logro otorgado", "Los logros se han otorgado correctamente.", 2500);
            $("#ranking-management-table").DataTable().ajax.reload();
          } else {
            createToast("danger", response.error.code, response.error.message, 5000);
          }

          $("#modal-achievement-ranking").modal("hide");
          updateButtonLabel("modal-achievement-ranking-btn-summit", "Otorgar logros", "btn-barymont-red");
        });
      });

      document.getElementById("rankings-management-filters").addEventListener("change", function (event) {
        if (event.target.classList.contains("triggerDatatableUpdateRankingManagement")) {
          $("#ranking-management-table").DataTable().ajax.reload();
        }
      });

      const buttonsToSelectUsersFromUserListByProfileLevel = document.querySelectorAll('[id^="user-profile-level-"][id$="-button"]');

      buttonsToSelectUsersFromUserListByProfileLevel.forEach((button) => {
        const baseId = button.id.replace("-button", "");
        const inputValueId = `${baseId}-value`;

        const inputValue = document.getElementById(inputValueId).value.replace(/ /g, "-");

        button.addEventListener("click", function () {
          selectAllUsersByProfileType("modal-participant-ranking-data-user-list", inputValue);
          updateButonCountersByProfileLevel("modal-participant-ranking-data-user-list");
          checkIfDefaultMessageShouldBeShownOnParticipantsListRankingModal("modal-participant-ranking-list-new-data-table-body", "participant-list-new-default-state-message");
          orderParticipantsWithNullScoreByNameOnRankingModal("modal-participant-ranking-list-new-data-table-body");
        });
      });

      document.getElementById("user-profile-level-all")?.addEventListener("click", function () {
        const userList = $("#modal-participant-ranking-data-user-list");
        const valuesToSelect = userList
          .find("option")
          .filter(function () {
            if ($(this).val() !== 0) {
              return $(this).val();
            }
          })
          .map(function () {
            return [
              {
                value: $(this).val(),
                name: $(this).data("participantName"),
                profileLevel: $(this).data("participantProfileLevelHtml"),
                profileLevelText: $(this).data("profileLevelText"),
                dataContent: $(this).data("content"),
              },
            ];
          })
          .get();

        if (valuesToSelect.length > 0) {
          valuesToSelect.forEach((option) => {
            printNewParticipantSelectedOnRankingModal("modal-participant-ranking-list-new-data-table-body", "modal-ranking-data-uuid-user-list", option.value, option.name, option.profileLevelText, option.profileLevel, option.dataContent);
            userList.find("option[value='" + option.value + "']").remove();
          });
        }

        $("#modal-participant-ranking-data-user-list").selectpicker("refresh");
        updateButonCountersByProfileLevel("modal-participant-ranking-data-user-list");
        checkIfDefaultMessageShouldBeShownOnParticipantsListRankingModal("modal-participant-ranking-list-new-data-table-body", "participant-list-new-default-state-message");
        orderParticipantsWithNullScoreByNameOnRankingModal("modal-participant-ranking-list-new-data-table-body");
      });

      document.getElementById("btn-to-unselect-new-participants-on-ranking")?.addEventListener("click", function () {
        unselectAllUsersFromTableWithUserPrinted("modal-participant-ranking-list-new-data-table-body", "modal-ranking-data-new-user-list-badge", true);
        checkIfDefaultMessageShouldBeShownOnParticipantsListRankingModal("modal-participant-ranking-list-new-data-table-body", "participant-list-new-default-state-message");
      });

      document.getElementById("btn-to-unselect-current-participants-on-ranking")?.addEventListener("click", function () {
        unselectAllUsersFromTableWithUserPrinted("modal-participant-ranking-list-current-data-table-body", "modal-ranking-data-current-user-list-badge", false);
        checkIfDefaultMessageShouldBeShownOnParticipantsListRankingModal("modal-participant-ranking-list-current-data-table-body", "participant-list-current-default-state-message");
      });

      $(document).on("focusin", function (e) {
        if ($(e.target).closest(".tox-textfield").length) e.stopImmediatePropagation();
      });

      document.getElementById("remove-image-button")?.addEventListener("click", function () {
        removeRankingImage();
      });

      const $modal = $("#modal-ranking-image-data");
      const image = document.getElementById("crop-modal-ranking-data-image");
      let cropper;

      $("#modal-ranking-data-image-file").change((event) => {
        const [file] = event.target.files;

        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            image.src = e.target.result;
            $modal.modal("show");
          };
          reader.readAsDataURL(file);
        }
      });

      $modal
        .on("shown.bs.modal", () => {
          cropper = new Cropper(image, {
            aspectRatio: 16 / 9,
            viewMode: 1,
            autoCropArea: 1,
            preview: ".preview-modal-ranking-data-image",
          });
        })
        .on("hidden.bs.modal", () => {
          cropper.destroy();
          cropper = null;
        });

      $("#btn-update-modal-ranking-data-image").click(() => {
        const canvas = cropper.getCroppedCanvas({
          width: 516,
          height: 290,
        });

        try {
          canvas.toBlob((blob) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64data = reader.result;
              $("#modal-ranking-data-image-file-base64").val(JSON.stringify(base64data));
              $("#modal-ranking-data-image").attr("src", base64data);
              $modal.modal("hide");
              createToast("info", "Imagen del ranking actualizada", "La imagen se ha seleccionado correctamente, finaliza la creacion del ranking para que se suba la imagen.", 5000);
            };
            reader.readAsDataURL(blob);
          });
        } catch (error) {
          createToast("warning", "Ha ocurrido un error", "No se ha podido cambiar el imagen del ranking, intentelo de nuevo", 5000);
        }
      });

      if (document.getElementById("modal-clone-ranking")) {
        document.getElementById("modal-clone-ranking-remove-image-button")?.addEventListener("click", function () {
          document.getElementById("modal-clone-ranking-data-image").setAttribute("src", "https://placehold.co/1920x1080?text=16:9");
          document.getElementById("modal-clone-ranking-data-image-file").value = "";
          document.getElementById("modal-clone-ranking-data-image-file-base64").value = "_REMOVE_IMAGEN_";

          createToast("info", "Imagen eliminada", "Se ha eliminado la imagen del ranking.", 5000);
        });

        const cloneModal = $("#modal-clone-ranking-image-data");
        const cloneImage = document.getElementById("crop-modal-clone-ranking-data-image");
        let cropper;

        $("#modal-clone-ranking-data-image-file").change((event) => {
          const [file] = event.target.files;

          if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
              cloneImage.src = e.target.result;
              cloneModal.modal("show");
            };
            reader.readAsDataURL(file);
          }
        });

        cloneModal
          .on("shown.bs.modal", () => {
            cropper = new Cropper(cloneImage, {
              aspectRatio: 16 / 9,
              viewMode: 1,
              autoCropArea: 1,
              preview: ".preview-modal-clone-ranking-data-image",
            });
          })
          .on("hidden.bs.modal", () => {
            cropper.destroy();
            cropper = null;
          });

        $("#btn-update-modal-clone-ranking-data-image").click(() => {
          const canvas = cropper.getCroppedCanvas({
            width: 516,
            height: 290,
          });

          try {
            canvas.toBlob((blob) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                const base64data = reader.result;
                $("#modal-clone-ranking-data-image-file-base64").val(JSON.stringify(base64data));
                $("#modal-clone-ranking-data-image").attr("src", base64data);
                cloneModal.modal("hide");
                createToast("info", "Imagen del ranking actualizada", "La imagen se ha seleccionado correctamente, finaliza la creacion del ranking para que se suba la imagen.", 5000);
              };
              reader.readAsDataURL(blob);
            });
          } catch (error) {
            createToast("warning", "Ha ocurrido un error", "No se ha podido cambiar el imagen del ranking, intentelo de nuevo", 5000);
          }
        });
      }

      document.getElementById("create-ranking-button")?.addEventListener("click", function () {
        resetRankingFieldsIntoEditModal();
        showLoadingButton("create-ranking-button");

        openRankingModal("modal-ranking-data-uuid-user-list", "modal-ranking-data-uuid-user-list-badge", "create-ranking-button", `<i class="fas fa-plus mr-2"></i> Crear ranking`);
        createUuid("modal-ranking-data-uuid");
      });

      document.getElementById("modal-ranking-factor-data-button-add-factor")?.addEventListener("click", function () {
        showLoadingButton("modal-ranking-factor-data-button-add-factor", "btn-barymont-black", true);
        resetFactorModalFields();
        setFactorModalAction("TO_BE_CREATED");

        resetTextareasTinyMCE();
        initializeTextareasWithTinyMCE();

        openFactorModal();
        createUuid("modal-ranking-factor-data-uuid");
      });

      document.getElementById("btn-ranking-participant-data-button-manage-participant")?.addEventListener("click", function () {
        showLoadingButton("btn-ranking-participant-data-button-manage-participant", "btn-barymont-grey", true);

        const rankingId = document.getElementById("modal-ranking-data-uuid").value;
        const modalStatus = document.getElementById("modal-ranking-state").value;

        if (modalStatus == "create") {
          openParticipantsListRankingManagementModal("btn-ranking-participant-data-button-manage-participant", `<i class="fas fa-users fa-sm"style="pointer-events: none;"></i> Gestionar participantes`);
        }

        if (modalStatus == "edit") {
          fetchAllRankingParticipantsDataById(rankingId).then((response) => {
            resetTablesCurrentParticipantsListRankingManagementModal();

            response.data.rankingParticipantsData.forEach((participant) => {
              const participantDataContentHtml = `<span class='text-uppercase'>${participant.userName} - <span class='btn btn-sm py-0 bg-${participant.userProfileLevelBgColor} text-${participant.userProfileLevelTextColor}'>${participant.userProfileLevelLabel.toUpperCase()}</span></span>`;
              const participantUserProfileLevelHtml = `<span class='btn btn-sm py-0 bg-${participant.userProfileLevelBgColor} text-${participant.userProfileLevelTextColor}'>${participant.userProfileLevelLabel.toUpperCase()}</span>`;

              if (document.getElementById("modal-ranking-data-uuid-user-list").value.includes(participant.userId) && !document.getElementById("new-participant-" + participant.userId)) {
                printCurrentParticipantOnRankingModal("modal-participant-ranking-list-current-data-table-body", "modal-ranking-data-uuid-user-list", participant.userId, participant.userName, participant.userProfileLevelLabel, participantUserProfileLevelHtml, participantDataContentHtml, participant.extraPoints, participant.score, participant.finalScore, participant.position, participant.additionalData, rankingId);
                updateUserListCounter("modal-ranking-data-current-user-list-badge", true);
              }
            });
            $("#modal-participant-ranking-data-user-list").selectpicker("refresh");

            orderParticipantsWithNullScoreByNameOnRankingModal("modal-participant-ranking-list-current-data-table-body");
            openParticipantsListRankingManagementModal("btn-ranking-participant-data-button-manage-participant", `<i class="fas fa-users fa-sm"style="pointer-events: none;"></i> Gestionar participantes`, response.data.rankingLastUpdateDate, rankingId, response.data.rankingName, response.data.rankingParticipantsData);
          });
        }
      });

      document.getElementById("modal-participant-ranking-data-user-list")?.addEventListener("change", function () {
        const selectedOption = this.options[this.selectedIndex];

        printNewParticipantSelectedOnRankingModal("modal-participant-ranking-list-new-data-table-body", "modal-ranking-data-uuid-user-list", selectedOption.value, selectedOption.getAttribute("data-participant-name"), selectedOption.getAttribute("data-profile-level-text"), selectedOption.getAttribute("data-participant-profile-level-html"), selectedOption.getAttribute("data-content"));

        this.remove(this.selectedIndex);
        $("#modal-participant-ranking-data-user-list").selectpicker("refresh");

        checkIfDefaultMessageShouldBeShownOnParticipantsListRankingModal("modal-participant-ranking-list-new-data-table-body", "participant-list-new-default-state-message");
        orderParticipantsWithNullScoreByNameOnRankingModal("modal-participant-ranking-list-new-data-table-body");
        orderParticipantsWithNullScoreByNameOnRankingModal("modal-participant-ranking-list-current-data-table-body");
        updateButonCountersByProfileLevel("modal-participant-ranking-data-user-list");
      });

      document.getElementById("modal-participant-ranking-list-new-data-table-body")?.addEventListener("change", function () {
        const totalChildrens = [...document.getElementById(this.id).children] || [];
        let hiddenChildrens = 0;

        totalChildrens.forEach((row) => {
          if (row.id != "participant-list-new-default-state-message") {
            hiddenChildrens++;
          }
        });

        document.getElementById("modal-ranking-data-new-user-list-badge").textContent = hiddenChildrens;
      });

      document.getElementById("modal-ranking-data-uuid-user-list")?.addEventListener("change", function () {
        const userParticipantList = this.value !== "" ? JSON.parse(this.value) : [];

        document.getElementById("modal-ranking-data-uuid-user-list-badge").textContent = userParticipantList.length;
      });

      document.getElementById("btn-modal-ranking-factor-summit")?.addEventListener("click", function () {
        const { factorId, rankingId, description, factorType, factorScoringType, factorData, points, minPoints, maxPoints, selectedProducts, factorAction } = getRankingFactorData();

        if (!checkValidFactorData(description, factorType, factorScoringType, factorData, points, minPoints, maxPoints, selectedProducts, factorAction)) {
          return;
        }

        checkIfRankingFactorIsValidOnBackend(factorId, rankingId, description, factorType, factorScoringType, factorData, points, minPoints, maxPoints, selectedProducts).then((response) => {
          if (response.status) {
            if (factorAction == "TO_BE_CREATED") {
              createToast("success", "Factor creado", "El factor se ha creado correctamente.", 2500);
            } else {
              createToast("success", "Factor editado", "El factor se ha editado correctamente.", 2500);
            }

            printFactorOnFactorList("modal-ranking-factor-data-table-body", factorId, description, factorType, factorScoringType, factorData, points, minPoints, maxPoints, selectedProducts, factorAction);
            $("#modal-ranking-factor-data").modal("hide");
          } else {
            createToast("warning", "Ha ocurrido un error", "No se ha podido crear el factor, revise los datos e intentelo de nuevo", 5000);
          }
        });
      });

      document.getElementById("edit-ranking")?.addEventListener("click", function () {
        showLoadingButton("edit-ranking", "btn-barymont-grey", true);
        openRankingModal("modal-ranking-data-uuid-user-list", "modal-ranking-data-uuid-user-list-badge", "edit-ranking", `<i class="fas fa-pen fa-sm"style="pointer-events: none;"></i>`, document.getElementById("ranking-id").value);
      });

      document.getElementById("modal-ranking-factor-data-scoring-type")?.addEventListener("change", function () {
        toggleScoringTypeFields();
      });

      document.getElementById("clone-ranking")?.addEventListener("click", function () {
        showLoadingButton("clone-ranking", "btn-barymont-grey", true);
        openCloneRankingModal("clone-ranking", `<i class="fas fa-copy fa-sm"style="pointer-events: none;"></i>`, document.getElementById("ranking-id").value);
      });

      document.getElementById("modal-participant-ranking-edit-data-extra-score")?.addEventListener("keyup", function () {
        let extraScoreElement = document.getElementById("modal-participant-ranking-edit-data-extra-score");
        let extraPoints = parseFloat(extraScoreElement.value);

        if (extraPoints !== "" && isNaN(parseFloat(extraPoints))) {
          createToast("warning", "Datos incorrectos", "El valor de puntos no es un número.", 5000);
          return;
        }

        let actualScoreElement = document.getElementById("modal-participant-ranking-edit-data-actual-score");
        let actualScore = parseFloat(actualScoreElement.value);

        if (!isNaN(extraPoints) && !isNaN(actualScore)) {
          document.getElementById("modal-participant-ranking-edit-data-final-calc-score").value = actualScore + extraPoints;
        } else {
          createToast("warning", "Datos incorrectos", "El valor de puntos no es un número válido.", 5000);
        }
      });

      document.getElementById("modal-participant-ranking-edit-data-summit-button").addEventListener("click", function () {
        const rankingId = document.getElementById("modal-ranking-data-uuid").value;
        const userId = document.getElementById("modal-participant-ranking-edit-data-user-id").value;
        const extraPoints = document.getElementById("modal-participant-ranking-edit-data-extra-score").value;
        const data = document.getElementById("modal-participant-ranking-additional-data").value;

        if (!extraPoints) {
          createToast("warning", "Datos incompletos", "Debes rellenar todos los campos obligatorios.", 5000);
          return;
        }

        if (Number.isNaN(extraPoints)) {
          createToast("warning", "Datos incorrectos", "El valor de puntos no es un numero.", 5000);
          return;
        }

        setExtraPointsToUser(rankingId, userId, extraPoints, data).then((response) => {
          if (response.status) {
            document.getElementById("modal-participant-ranking-list-current-data-table-body").innerHTML = "<tr id='defaultStateMessage'><td colspan='7' class='text-center'><span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> Cargando...</td></tr>";

            fetchAllRankingParticipantsDataById(rankingId).then((response) => {
              if (response.status) {
                document.getElementById("modal-participant-ranking-list-current-data-table-body").innerHTML = "<tr id='participant-list-current-default-state-message'><td colspan='5' class='text-center w-100'>Todavia no hay participantes</td></tr>";

                response.data.rankingParticipantsData.forEach((participant) => {
                  const participantDataContentHtml = `<span class='text-uppercase'>${participant.userName} - <span class='btn btn-sm py-0 bg-${participant.userProfileLevelBgColor} text-${participant.userProfileLevelTextColor}'>${participant.userProfileLevelLabel.toUpperCase()}</span></span>`;
                  const participantUserProfileLevelHtml = `<span class='btn btn-sm py-0 bg-${participant.userProfileLevelBgColor} text-${participant.userProfileLevelTextColor}'>${participant.userProfileLevelLabel.toUpperCase()}</span>`;

                  printCurrentParticipantOnRankingModal("modal-participant-ranking-list-current-data-table-body", "modal-ranking-data-uuid-user-list", participant.userId, participant.userName, participant.userProfileLevelLabel, participantUserProfileLevelHtml, participantDataContentHtml, participant.extraPoints, participant.score, participant.finalScore, participant.position, participant.additionalData, rankingId);
                });
                $("#modal-participant-ranking-data-user-list").selectpicker("refresh");

                orderParticipantsWithNullScoreByNameOnRankingModal("modal-participant-ranking-list-current-data-table-body");
                createToast("success", "Puntos actualizados", "Los puntos del participante se han actualizado correctamente.", 2500);
              } else {
                createToast("danger", response.error.code, response.error.message, 5000);
                return;
              }
            });

            $("#modal-participant-ranking-edit-data").modal("hide");
          } else {
            createToast("warning", "Ha ocurrido un error", "No se ha podido actualizar los puntos del participante, intentelo de nuevo", 5000);
          }
        });
      });
    }
  });
}

function initializeTextareasWithTinyMCE() {
  tinymce.init({
    allow_unsafe_link_target: false,
    invalid_elements: "script,object,embed,link,style,form,input,iframe,img",
    selector: "textarea",
    height: 225,
    resize: false,
    menubar: false,
    plugins: "link",
    toolbar: "undo redo | formatselect | bold italic underline link",
    content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
    branding: false,
    setup: function (editor) {
      editor.on("change", function () {
        editor.save();
      });
    },
  });
}

function resetTextareasTinyMCE() {
  const textAreas = document.querySelectorAll("textarea");

  textAreas.forEach((textArea) => {
    textArea.value = "";
    tinymce.remove("textarea");
  });
}

export default {};
