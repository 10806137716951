"use strict";

import { OrgChart } from "d3-org-chart";
import { createModal, showLoadingButton, updateButtonLabel } from "../shared/shared";
import { createModalNewPromoter } from "../users/new-promoter-request";

export async function UIloadUserStructureModal(userStructureData, showPromoterButton, modalTextTitle, userId = null) {
  const modalId = Math.random().toString(36).substring(7);
  let showInactiveUsers = false;

  const modalSize = "xl";
  const modalTitle = `<i class='fa-solid fa-people-group text-white mr-2'></i>${modalTextTitle}`;
  const modalBodyContent = `<div class="chart-container bg-light" style="width: 100%; height: 80vh; position: relative;">

          <div id="accordion-options" class="accordion position-absolute" style="bottom: 20px; right: 20px; z-index: 10; max-width: 300px; width: 100%;">
            <div class="card shadow border-0">
              <div class="card-header p-0" id="heading-options" style="border-radius: 8px;">

                  <button class="btn btn-link w-100 text-left bg-barymont text-white font-weight-bold py-2 px-3"
                          data-toggle="collapse"
                          data-target="#collapse-options"
                          aria-expanded="true"
                          aria-controls="collapse-options"
                          style="text-decoration: none; font-size: 12px;">
                    <i class="fas fa-cogs text-white pr-2"></i> Controles
                    <i id="arrow-acordion-options" class="fas fa-chevron-down text-white float-right mt-1"></i>
                  </button>

              </div>
              <div id="collapse-options" class="collapse show" aria-labelledby="heading-options" data-parent="#accordion-options">
                <div class="card-body bg-light rounded-bottom" style="padding: 15px;">

                  <div class="row">
                    <div class="col-12 mb-2">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text bg-dark border-dark"><i class="fas fa-user text-white"></i></span>
                        </div>
                        <input type="text" class="form-control form-control-sm border-dark" placeholder="Buscar por nombre..." id="search-name-on-my-team" style="border: 1px solid #ddd;">
                      </div>
                    </div>

                    <div class="col-12">
                      <button id="btn-fit" class="btn btn-sm btn-outline-dark w-100 mb-2 shadow-sm">
                        <i class="fas fa-compress-arrows-alt pr-2" style="pointer-events: none;"></i> Ajustar
                      </button>
                    </div>

                    <div class="col-6">
                      <button id="btn-expand-all" class="btn btn-sm btn-outline-dark w-100 mb-2 shadow-sm">
                        <i class="fas fa-angle-double-down pr-2" style="pointer-events: none;"></i> Expandir
                      </button>
                    </div>

                    <div class="col-6">
                      <button id="btn-collapse-all" class="btn btn-sm btn-outline-dark w-100 mb-2 shadow-sm">
                        <i class="fas fa-angle-double-up pr-2" style="pointer-events: none;"></i> Colapsar
                      </button>
                    </div>

                    <div class="col-6">
                      <button id="btn-zoom-out" class="btn btn-sm btn-outline-dark w-100 mb-2 shadow-sm">
                        <i class="fas fa-minus pr-2" style="pointer-events: none;"></i> Alejar
                      </button>
                    </div>

                    <div class="col-6">
                      <button id="btn-zoom-in" class="btn btn-sm btn-outline-dark w-100 mb-2 shadow-sm">
                        <i class="fas fa-plus pr-2" style="pointer-events: none;"></i> Acercar
                      </button>
                    </div>

                    <div class="col-12">
                      <button id="btn-toggle-inactive" class="btn btn-sm btn-outline-dark w-100 shadow-sm">
                        <i class="fas fa-eye pr-2" style="pointer-events: none;"></i> Mostrar inactivos
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div id="loader-${modalId}" class="d-flex justify-content-center align-items-center" style="width: 100%; height: 100%; position: absolute; top: 0; left: 0; background-color: rgba(255, 255, 255, 0.8); z-index: 10;">
            <div class="spinner-border text-barymont-red" role="status" style="width: 3.5rem; height: 3.5rem;">
              <span class="sr-only">Cargando...</span>
            </div>
          </div>

        </div>`;

  const modalHeaderExtraElements = `<div>
          ${
            AppGbSession.checkUserHasPermission("General:NewPromoterRequestCreateAccessChecker") && showPromoterButton
              ? `<span class="btn btn-sm btn-barymont-grey relative px-3" id="new-promoter-button" style="font-size: 14px;">
            <i class="fa-solid fa-user-plus text-dark pr-2"></i>Nuevo Promotor
            </span>`
              : ""
          }
        </div>`;

  const modalElement = createModal(modalId, modalSize, modalTitle, modalBodyContent, null, "p-0", modalHeaderExtraElements, null);

  // Modify the icon on the accordion options
  const accordionIcon = document.getElementById("arrow-acordion-options");
  const collapseTarget = document.getElementById("heading-options");
  collapseTarget.addEventListener("click", () => {
    if (accordionIcon.classList.contains("fa-chevron-down")) {
      accordionIcon.classList.remove("fa-chevron-down");
      accordionIcon.classList.add("fa-chevron-up");
    } else {
      accordionIcon.classList.remove("fa-chevron-up");
      accordionIcon.classList.add("fa-chevron-down");
    }
  });

  // Agregar listener al botón "Nuevo Promotor" si el usuario tiene permisos
  if (AppGbSession.checkUserHasPermission("General:NewPromoterRequestCreateAccessChecker") && showPromoterButton) {
    const NewPromoterButtonId = "new-promoter-button";
    document.getElementById(NewPromoterButtonId)?.addEventListener("click", function () {
      showLoadingButton(NewPromoterButtonId, "btn-barymont-grey", true);
      createModalNewPromoter();
      updateButtonLabel(NewPromoterButtonId, "<i class='fas fa-user-plus text-dark pr-2'></i>Nuevo Promotor", "btn-barymont-grey");
    });
  }

  modalElement.modal("show");

  modalElement.on("shown.bs.modal", async () => {
    try {
      if (userStructureData === null) {
        throw new Error("No se han encontrado datos para mostrar en el gráfico.");
      }

      if (userId === null) {
        const scriptElement = document.querySelector("script[data-user-session-info]");
        userId = JSON.parse(scriptElement.textContent).userId;
      }
      if (Array.isArray(userStructureData) && userStructureData.length > 0) {
        const filterData = (includeInactive) => {
          const filteredData = [];

          const hasActiveDependents = (userId) => {
            const directDependents = userStructureData.filter((dependent) => dependent.responsibleUserId === userId);

            if (directDependents.some((dependent) => dependent.isActive)) {
              return true;
            }

            return directDependents.some((dependent) => hasActiveDependents(dependent.userId));
          };

          userStructureData.forEach((item) => {
            let parentId = null;

            if (item.userId !== item.responsibleUserId) {
              parentId = item.responsibleUserId;
            }

            if (includeInactive || item.isActive || hasActiveDependents(item.userId)) {
              filteredData.push({
                id: item.userId,
                parentId: parentId,
                name: item.name,
                email: item.email,
                phone: item.phone,
                identificationNumber: item.identificationNumber,
                profile: item.userProfile,
                roles: item.userRoles,
                imageUrl: item.avatarPath,
                isActive: item.isActive,
              });
            }
          });
          return filteredData;
        };

        let chartData = filterData(showInactiveUsers);

        const container = document.querySelector(".chart-container");

        const chart = new OrgChart()
          .container(container)
          .svgWidth(container.offsetWidth)
          .svgHeight(container.offsetHeight)
          .data(chartData)
          .nodeWidth(() => 350)
          .nodeHeight(() => 195)
          .childrenMargin(() => 125)
          .compactMarginBetween(() => 100)
          .compactMarginPair(() => 100)
          .neighbourMargin(() => 100)
          .siblingsMargin(() => 200)
          .linkUpdate(function (link) {
            const linkElement = this;

            linkElement.style.stroke = link.data._upToTheRootHighlighted ? "#424242" : "#E4E2E9";
            linkElement.style.strokeWidth = link.data._upToTheRootHighlighted ? "5px" : "1px";

            if (link.data._upToTheRootHighlighted && linkElement.parentNode) {
              linkElement.parentNode.appendChild(linkElement);
            }
          })
          .nodeUpdate(function (node) {
            const nodeElement = this.querySelector(".node-rect");

            if (nodeElement) {
              nodeElement.style.stroke = node.data._highlighted || node.data._upToTheRootHighlighted ? "#424242" : "none";
              nodeElement.style.strokeWidth = node.data._highlighted || node.data._upToTheRootHighlighted ? "10px" : "0px";
            }

            if (this.parentNode) {
              this.parentNode.appendChild(this);
            }
          })
          .buttonContent(
            ({ node }) => `
            <div class="d-flex justify-content-center align-items-center px-2 py-0 bg-white border rounded mt-3" style="height: 20px;">
              ${node.children ? '<i class="fas fa-angle-up"></i>' : '<i class="fas fa-angle-down"></i>'}
              <span class="ml-2">${node.data._directSubordinates}</span>
            </div>`
          )
          .nodeContent(function (d) {
            return `<div style="width:${d.width}px; height:${d.height}px; padding-left:1px; padding-right:1px;">

                      <div class="node-highlight-test shadow-sm ${d.data.id === userId ? "" : d.data.isActive ? "bg-white" : ""} rounded overflow-visible" style="width:${d.width - 2}px; height:${d.height}px; ${d.data.isActive ? "" : "background-color: #f0f0f0;"} ${d.data.id === userId ? "background-color: #dfebe2" : ""}">

                        <div class="p-2" title="${d.data.isActive ? "Activo" : "Inactivo"}">
                            <i class="fas fa-circle text-${d.data.isActive ? "success" : "danger"}" style="font-size: 14px;"></i>
                        </div>

                        <div style="margin-left:${d.width / 2 - 28}px; margin-top:-47px; width:56px; height:56px; background-color:#ffffff; border-radius:100px;">
                          <img src="${d.data.imageUrl}" style="width:56px; height:56px; border-radius:100px;" alt="${d.data.name}">
                        </div>

                        <div class="text-center mt-4 pt-2" style="font-size: 14px;">
                          <h5 class="font-weight-bold mb-1">${d.data.name}</h5>
                          <p class="badge bg-${d.data.profile.backgroundColor} text-${d.data.profile.textColor} px-3 py-1 mb-2">${d.data.profile.label}</p>
                          <div class="pt-1">
                            ${d.data.roles.map((role) => `<span class="badge px-2 py-1 mx-1" style="background-color: ${role.backgroundColorHex}; color: ${role.textColorHex};"><i class="${role.iconClasses} mr-2" style="color: ${role.textColorHex};"></i>${role.label}</span>`).join("")}
                          </div>
                          <div class="pt-2">
                            <span class="badge badge-light mx-1 p-2" title="${d.data.id}" onClick="sharedCopyValueToClipboard('${d.data.id}')"><i class="fa-solid fa-hashtag text-dark"></i></span>
                            <span class="badge badge-info mx-1 p-2" title="${d.data.email}" onClick="sharedCopyValueToClipboard('${d.data.email}')"><i class="fa-solid fa-envelope text-white"></i></span>
                            <span class="badge badge-info mx-1 p-2 ${d.data.phone ?? "d-none"}" title="${d.data.phone ?? null}" onClick="sharedCopyValueToClipboard('${d.data.phone ?? null}')"><i class="fa-solid fa-phone text-white"></i></span>
                            <span class="badge badge-info mx-1 p-2 ${d.data.identificationNumber ?? "d-none"}" title="${d.data.identificationNumber ?? null}" onClick="sharedCopyValueToClipboard('${d.data.identificationNumber ?? null}')"><i class="fa-solid fa-id-card text-white"></i></span>
                            <span class="badge badge-secondary mx-1 p-2 ${d.data._directSubordinates === 0 ? "d-none" : ""}" title="Base"><i class="fas fa-code-branch text-white pr-1"></i>${d.data._directSubordinates}</span>
                            <span class="badge badge-dark mx-1 p-2 ${d.data._totalSubordinates === 0 ? "d-none" : ""}" title="Jerarquía"><i class="fas fa-sitemap text-white pr-1"></i>${d.data._totalSubordinates}</span>
                          </div>
                        </div>

                        <div class="ml-2 pb-2">
                          <button data-user-id="${d.data.id}" class="btn btn-sm btn-light shadow-sm toggle-highlight-root" title="Resaltar">
                            <i class="fas fa-route text-dark" style="pointer-events: none;"></i>
                          </button>
                        </div>

                    </div>`;
          })
          .render()
          .clearHighlighting()
          .setUpToTheRootHighlighted(userId)
          .expandAll()
          .render()
          .fit();

        container.addEventListener("click", (event) => {
          const buttonElement = event.target.closest(".toggle-highlight-root");

          if (buttonElement) {
            const userId = buttonElement.getAttribute("data-user-id");

            if (userId) {
              chart.clearHighlighting();
              chart.collapseAll();
              chart.setUpToTheRootHighlighted(userId).render().fit();
            } else {
              console.error("No se encontró un data-user-id en el botón.");
            }
          }
        });

        document.getElementById("btn-fit").addEventListener("click", () => {
          chart ? chart.fit() : null;
        });

        document.getElementById("btn-expand-all").addEventListener("click", () => {
          chart ? chart.expandAll() : null;
        });

        document.getElementById("btn-collapse-all").addEventListener("click", () => {
          chart ? chart.collapseAll() : null;
        });

        document.getElementById("btn-zoom-out").addEventListener("click", () => {
          chart ? chart.zoomOut() : null;
        });

        document.getElementById("btn-zoom-in").addEventListener("click", () => {
          chart ? chart.zoomIn() : null;
        });

        document.getElementById("search-name-on-my-team").addEventListener("input", (event) => {
          const searchValue = event.target.value.trim().toLowerCase();

          chart.clearHighlighting();

          const chartData = chart.data();

          chartData.forEach((node) => (node._expanded = false));

          chartData.forEach((node) => {
            if (searchValue !== "" && node.name.toLowerCase().includes(searchValue)) {
              node._highlighted = true;
              node._expanded = true;
            } else {
              node._highlighted = false;
            }
          });

          chart.data(chartData).render().fit();
        });

        document.getElementById("btn-toggle-inactive").addEventListener("click", () => {
          showInactiveUsers = !showInactiveUsers;

          chart.data(filterData(showInactiveUsers)).render().expandAll().fit();

          document.getElementById("btn-toggle-inactive").innerHTML = `
            <i class="fas fa-eye text-dark pr-2"></i> ${showInactiveUsers ? "Ocultar inactivos" : "Mostrar inactivos"}`;
        });

        document.getElementById(`loader-${modalId}`).remove();
      } else {
        console.error("No se encontraron datos para mostrar en el gráfico.");
        document.querySelector(".chart-container").innerHTML = `
          <div class="alert alert-warning text-center" role="alert">
            <strong>¡Ups!</strong> No se han encontrado datos para mostrar en el gráfico.
          </div>`;
      }
    } catch (error) {
      console.error("Error al obtener los datos del equipo:", error);
      document.querySelector(".chart-container").innerHTML = `
        <div class="alert alert-danger text-center" role="alert">
          <strong>¡Ups!</strong> Ha ocurrido un error al cargar los datos del equipo.
        </div>`;
    }
  });
}

window.UIloadUserStructureModal = UIloadUserStructureModal;
