"use strict";

import settledSharedFunctions from "./settledShared.js";

const TOTAL_STEPS = 3;

var currentStep = 1;
var tagify;
var selectedNifForSettledArray = [];
var providersDataFromLastRequest = [];
var maxNifForSettled = 0;
var nifForSettledTable;

async function fetchServiceProvidersByCommissionsToSettledByDateRange(dateFrom, dateTo) {
  let formData = new FormData();

  formData.append("dateFrom", dateFrom);
  formData.append("dateTo", dateTo);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/liquidaciones/ajax/getServiceProvidersByCommissionsToSettledByDateRange", requestOptions).then((response) => response.json());
}

async function fetchPreSettledByDateRangeAndServiceProvidersSelecteds(dateFrom, dateTo, providersValues) {
  let formData = new FormData();

  formData.append("dateFrom", dateFrom);
  formData.append("dateTo", dateTo);
  formData.append("providersValues", JSON.stringify(providersValues));

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/liquidaciones/ajax/getNifForSettledByDateRangeAndServiceProviders", requestOptions).then((response) => response.json());
}

async function generateMassiveSettledByNif(selectedNifForSettledArray, dateFrom, dateTo, providersValues, description) {
  let formData = new FormData();

  formData.append("nifs", selectedNifForSettledArray);
  formData.append("dateFrom", dateFrom);
  formData.append("dateTo", dateTo);
  formData.append("providersValues", JSON.stringify(providersValues));
  formData.append("description", description);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/liquidaciones/ajax/generateMassiveSettleds", requestOptions).then((response) => response.json());
}

function printNifForSettledDatatableByJson(data, tableId) {
  if ($.fn.DataTable.isDataTable("#" + tableId)) {
    $("#" + tableId)
      .DataTable()
      .destroy();
    $("#" + tableId).empty();
  }

  maxNifForSettled = data.length;

  var transformedData = data.map(function (item) {
    return {
      nif: item.nif.nif,
      name: item.nif.full_name,
      commission_quantity: item.numberOfCommissions,
      tax_base: {
        render: item.grossAmountTotal + " €",
        sort: function () {
          return item.grossAmountTotal.replace(/\s/g, "").replace(/\./g, "");
        },
      },
      iva_percentage: {
        render: item.nif.tax_area / 100 + " %",
        sort: function () {
          return item.nif.tax_area;
        },
      },
      iva_total: {
        render: item.ivaAmountTotal + " €",
        sort: function () {
          return item.ivaAmountTotal.replace(/\s/g, "").replace(/\./g, "");
        },
      },
      irpf_percentage: {
        render: item.nif.tax_personal / 100 + " %",
        sort: function () {
          return item.nif.tax_personal;
        },
      },
      irpf_total: {
        render: item.irpfAmountTotal + " €",
        sort: function () {
          return item.irpfAmountTotal.replace(/\s/g, "").replace(/\./g, "");
        },
      },
      total_liquid: {
        render: item.netAmountTotal + " €",
        sort: function () {
          return item.netAmountTotal.replace(/\s/g, "").replace(/\./g, "");
        },
      },
      dateTo: item.dateTo,
      dateFrom: item.dateFrom,
      service_providers: JSON.stringify(item.serviceProviderEnumCollection),
    };
  });

  nifForSettledTable = $("#" + tableId).DataTable({
    language: {
      sProcessing: "Procesando datos...",
      sLengthMenu: "Mostrar _MENU_ Nifs para liquidar",
      sZeroRecords: "No se encontraron nifs para liquidar coincidentes con el criterio de búsqueda.",
      sEmptyTable: "Ningún nif para liquidar coincidente con el criterio de búsqueda.",
      sInfo: "Mostrando nifs para liquidar del _START_ al _END_ de un total de _TOTAL_ nifs para liquidar",
      sInfoEmpty: "Mostrando nifs para liquidar del 0 al 0 de un total de 0 nifs para liquidar",
      sInfoFiltered: "(Filtrados de un total de _MAX_ nifs para liquidar)",
      sSearch: "<div class='input-group-prepend'><div class='input-group-text'><i class='fas fa-search'></i></div></div>",
      sUrl: "",
      sInfoThousands: ",",
      sLoadingRecords: "Cargando Datos...",
      oPaginate: {
        sFirst: "Primera",
        sLast: "Última",
        sNext: "Siguiente",
        sPrevious: "Anterior",
      },
      oAria: {
        sSortAscending: ": Activar para ordenar la columna de manera ascendente",
        sSortDescending: ": Activar para ordenar la columna de manera descendente",
      },
      select: {
        rows: {
          _: "",
        },
      },
    },
    pagingType: "input",
    order: [[0, "desc"]],
    processing: true,
    dom: '<"top"fl>Brt<"bottom"ip><"clear">',
    buttons: [
      {
        text: "Seleccionar todos",
        className: "btn btn-barymont-red my-1 select-all-commissions",
        action: function () {
          selectAllNifForSettled();
        },
        init: function (api, node) {
          $(node).removeClass("dt-button");
        },
      },
      {
        text: "Limpiar seleccion",
        className: "btn btn-barymont-black my-2 my-md-0 deselect-all-commissions",
        action: function () {
          resetSelectedRows();
        },
        init: function (api, node) {
          $(node).removeClass("dt-button");
        },
      },
      {
        text: "Haz click en un fila para seleccionar un nif para liquidar",
        className: "btn btn-barymont-grey font-weight-bold total-selected not-clickable",
        init: function (api, node) {
          $(node).removeClass("dt-button");
        },
      },
    ],
    data: transformedData,
    columns: [
      {
        data: "nif",
        title: "NIF",
      },
      { data: "name", title: "Nombre", className: "text-left" },
      { data: "commission_quantity", title: "Nº Com.", className: "text-center" },
      {
        data: "tax_base",
        title: "B. Imponible",
        className: "text-right",
        render: function (data, type) {
          if (type === "sort") return data.sort;
          return data.render;
        },
      },
      {
        data: "iva_percentage",
        title: "% TAX Área",
        className: "text-right",
        render: function (data, type) {
          if (type === "sort") return data.sort;
          return data.render;
        },
      },
      {
        data: "iva_total",
        title: "I. TAX Área",
        className: "text-right",
        render: function (data, type) {
          if (type === "sort") return data.sort;
          return data.render;
        },
      },
      {
        data: "irpf_percentage",
        title: "% TAX Personal",
        className: "text-right",
        render: function (data, type) {
          if (type === "sort") return data.sort;
          return data.render;
        },
      },
      {
        data: "irpf_total",
        title: "I. TAX Personal",
        className: "text-right",
        render: function (data, type) {
          if (type === "sort") return data.sort;
          return data.render;
        },
      },
      {
        data: "total_liquid",
        title: "I. Líquido",
        className: "text-right",
        render: function (data, type) {
          if (type === "sort") return data.sort;
          return data.render;
        },
      },
    ],
    pageLength: 10,
    scrollX: true,
    lengthMenu: [
      [10, 25, 50],
      [10, 25, 50],
    ],
    select: {
      style: "multi",
      selector: "td",
      className: "clickable",
    },
    keys: true,
    searchHighlight: true,
    fnRowCallback: function (nRow) {
      updateNifForSettledDatatableActionButtons();
      return nRow;
    },
    fnDrawCallback: function () {
      updateNifForSettledDatatableActionButtons();
    },
  });

  function selectAllNifForSettled() {
    var allData = nifForSettledTable.rows().data().toArray();

    allData.forEach(function (data) {
      if ($.inArray(data.nif, selectedNifForSettledArray) === -1) {
        selectedNifForSettledArray.push(data.nif);
      }
    });

    nifForSettledTable.rows().nodes().to$().css("background-color", "var(--barymont-green)").select();
    updateNifForSettledDatatableActionButtons();
  }

  function resetSelectedRows() {
    selectedNifForSettledArray = [];
    nifForSettledTable.rows().deselect();
    nifForSettledTable.rows().nodes().to$().css("background-color", "white");
    updateNifForSettledDatatableActionButtons();
  }

  nifForSettledTable.on("select", function (e, dt, type, indexes) {
    const rowData = nifForSettledTable.rows(indexes).data().toArray();

    if ($.inArray(rowData[0].nif, selectedNifForSettledArray) === -1) {
      nifForSettledTable.rows(indexes).nodes().to$().css("background-color", "var(--barymont-green)");
      selectedNifForSettledArray.push(rowData[0].nif);
    }
    updateNifForSettledDatatableActionButtons();
  });

  nifForSettledTable.on("deselect", function (e, dt, type, indexes) {
    const rowData = nifForSettledTable.rows(indexes).data().toArray();
    nifForSettledTable.rows(indexes).nodes().to$().css("background-color", "white");
    selectedNifForSettledArray.splice($.inArray(rowData[0].nif, selectedNifForSettledArray), 1);

    if ($(".select-all-commissions").text() === "Deseleccionar todos") {
      $(".select-all-commissions").text("Seleccionar todos");
      $(".select-all-commissions").removeClass("btn-barymont-green");
      $(".select-all-commissions").addClass("btn-barymont-red");
    }
    updateNifForSettledDatatableActionButtons();
  });

  $(".select-all-commissions")
    .off("click")
    .on("click", function () {
      if ($(this).text() === "Seleccionar todos") {
        selectAllNifForSettled();
      } else {
        resetSelectedRows();
      }
    });

  resetSelectedRows();
}

function updateNifForSettledDatatableActionButtons() {
  $(".total-selected").html(selectedNifForSettledArray.length + " nif" + (selectedNifForSettledArray.length === 1 ? "" : "s") + " seleccionado" + (selectedNifForSettledArray.length === 1 ? "" : "s"));

  if (maxNifForSettled === selectedNifForSettledArray.length && maxNifForSettled !== 0) {
    $(".select-all-commissions").text("Deselecionar todos");
    $(".select-all-commissions").removeClass("btn-barymont-red");
    $(".select-all-commissions").addClass("btn-barymont-green");
  } else {
    $(".select-all-commissions").text("Seleccionar todos");
    $(".select-all-commissions").removeClass("btn-barymont-green");
    $(".select-all-commissions").addClass("btn-barymont-red");
  }
}

function printInstructionDataByStep(step) {
  switch (step) {
    case 1:
      document.getElementById("modal-massive-settled-instructions").innerHTML = "📅 Selecciona el rango de fechas de las comisiones que quieras liquidar";
      break;
    case 2:
      document.getElementById("modal-massive-settled-instructions").innerHTML = "🚚 Selecciona los tipos de servicios a liquidar";
      break;
    case 3:
      document.getElementById("modal-massive-settled-instructions").innerHTML = "👤 Selecciona los NIFs a los que se le generará una liquidación";
      break;
  }
}

function manageNavigateButtonsByStep(step) {
  if (step === 1) {
    document.getElementById("btn-massive-settled-prev").classList.add("d-none");
    document.getElementById("btn-massive-settled-prev").classList.remove("inline-block");
  } else {
    document.getElementById("btn-massive-settled-prev").classList.add("inline-block");
    document.getElementById("btn-massive-settled-prev").classList.remove("d-none");
  }

  if (step === TOTAL_STEPS) {
    document.getElementById("btn-massive-settled-next").classList.add("d-none");
    document.getElementById("btn-massive-settled-next").classList.remove("inline-block");
    document.getElementById("btn-massive-settled-generate").classList.add("inline-block");
    document.getElementById("btn-massive-settled-generate").classList.remove("d-none");
  } else {
    document.getElementById("btn-massive-settled-next").classList.add("inline-block");
    document.getElementById("btn-massive-settled-next").classList.remove("d-none");
    document.getElementById("btn-massive-settled-generate").classList.add("d-none");
    document.getElementById("btn-massive-settled-generate").classList.remove("inline-block");
  }
}

function showMasiveSettledStep(step, totalSteps, modalFormStepsBaseId) {
  for (let i = 1; i <= totalSteps; i++) {
    if (i === step) {
      document.getElementById(modalFormStepsBaseId + i).classList.remove("d-none");
    } else {
      document.getElementById(modalFormStepsBaseId + i).classList.add("d-none");
    }
  }
  printInstructionDataByStep(step);
  manageNavigateButtonsByStep(step);
}

function clearValuesMasiveSettlementFormByStep(step, step1DateFromId, step1DateToId, step3NifsId) {
  switch (step) {
    case 1:
      document.getElementById(step1DateFromId).value = "";
      document.getElementById(step1DateToId).value = "";
      break;
    case 2:
      if (tagify) {
        tagify.removeAllTags();
      }
      break;
    case 3:
      if ($.fn.DataTable.isDataTable("#" + step3NifsId)) {
        $("#" + step3NifsId)
          .DataTable()
          .destroy();
        $("#" + step3NifsId).empty();
      }
      break;
  }
}

function resetMasiveSettlementFormData(step1DateFromId, step1DateToId, step3NifsId) {
  currentStep = 1;
  selectedNifForSettledArray = [];
  providersDataFromLastRequest = [];
  maxNifForSettled = 0;
  clearValuesMasiveSettlementFormByStep(1, step1DateFromId, step1DateToId, step3NifsId);
  clearValuesMasiveSettlementFormByStep(2, step1DateFromId, step1DateToId, step3NifsId);
  clearValuesMasiveSettlementFormByStep(3, step1DateFromId, step1DateToId, step3NifsId);
  if ($.fn.DataTable.isDataTable("#" + step3NifsId)) {
    nifForSettledTable.rows().deselect();
    nifForSettledTable.rows().nodes().to$().css("background-color", "white");
  }
  updateNifForSettledDatatableActionButtons();
  manageNavigateButtonsByStep(currentStep);
}

function validateMasiveSettlementFormByStep(currentStep, step1DateFromId, step1DateToId) {
  switch (currentStep) {
    case 1:
      if (!document.getElementById(step1DateFromId).value || !document.getElementById(step1DateToId).value || document.getElementById(step1DateFromId).value > document.getElementById(step1DateToId).value) {
        createToast("warning", "Rango de fechas inválido", "Debes seleccionar un rango de fechas válido", 5000);
        return true;
      }
      break;
    case 2:
      if (tagify.value.length === 0) {
        createToast("warning", "Tipo de servicio inválido", "Debes seleccionar al menos un tipo de servicio", 5000);
        return true;
      }

      break;
    case 3:
      if (selectedNifForSettledArray.length === 0) {
        createToast("warning", "NIFs no seleccionados", "Debes seleccionar al menos un NIF para liquidar", 5000);
        return true;
      }
      break;
  }
  return false;
}

async function loadDataByStep(step, step1DateFromId, step1DateToId, step2ServiceTypesId, btnAddAllServicesId, btnRemoveAllServicesId, step3NifsId) {
  if (step === 1) {
    const dateFrom = document.getElementById(step1DateFromId).value;
    const dateTo = document.getElementById(step1DateToId).value;

    const commissionsResponse = await fetchServiceProvidersByCommissionsToSettledByDateRange(dateFrom, dateTo);

    if (commissionsResponse.status === 0) {
      createToast("error", "Error", "Ocurrió un error al cargar las comisiones", 5000);
      return false;
    }

    if (commissionsResponse.data.length === 0) {
      createToast("warning", "No hay comisiones", "No se encontraron comisiones sin liquidar para el rango de fechas seleccionado, por favor selecciona otro rango de fechas", 5000);
      return false;
    }

    if (commissionsResponse.status && commissionsResponse.data.length > 0) {
      if (tagify) {
        tagify.destroy();
      }

      providersDataFromLastRequest = commissionsResponse.data;

      printServiceProvidersTagifyByData(commissionsResponse.data, step2ServiceTypesId);
      document.getElementById(btnAddAllServicesId).addEventListener("click", () => {
        tagify.dropdown.selectAll();
        tagify.dropdown.fill();
      });

      document.getElementById(btnRemoveAllServicesId).addEventListener("click", () => {
        tagify.removeAllTags();
      });
    }
  }

  if (step === 2) {
    const dateFrom = document.getElementById(step1DateFromId).value;
    const dateTo = document.getElementById(step1DateToId).value;

    const selectedItemsFromTagify = tagify.value.map((item) => item.value);

    const nifsResponse = await fetchPreSettledByDateRangeAndServiceProvidersSelecteds(dateFrom, dateTo, selectedItemsFromTagify);

    if (nifsResponse.status === 0) {
      createToast("error", "Error", "Ocurrió un error al cargar los NIFs", 5000);
      return false;
    }

    if (nifsResponse.data.length === 0) {
      createToast("warning", "No hay NIFs", "No se encontraron NIFs para los tipos de servicios seleccionados, por favor selecciona otros tipos de servicios", 5000);
      return false;
    }

    if (nifsResponse.status && nifsResponse.data.length > 0) {
      printNifForSettledDatatableByJson(nifsResponse.data, step3NifsId);
    }
  }

  return true;
}

function printServiceProvidersTagifyByData(data, step2ServiceTypesId) {
  tagify = new Tagify(document.getElementById(step2ServiceTypesId), {
    maxTags: Infinity,
    tagTextProp: "name",
    editTags: false,
    dropdown: {
      position: "manual",
      classname: "tags-look",
      enabled: 0,
      closeOnSelect: false,
      maxItems: Infinity,
      mapValueTo: "name",
    },
    templates: {
      dropdownFooter() {
        return `<div class='tagify__dropdown__footer' style="padding: 5px 12px; cursor: pointer;">Se han encontrado ${data.length} tipos de servicios, con comisiones en las fechas seleccionadas</div>`;
      },
    },
    enforceWhitelist: true,
  });

  data?.map((tag) => {
    tagify.settings.whitelist.push({ value: tag.value, name: tag.label });
  });

  tagify.dropdown.show();
  tagify.DOM.scope.parentNode.appendChild(tagify.DOM.dropdown);
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname === "/herramientas/liquidaciones") {
      const modalMassiveSettledId = "modal-massive-settled";
      const modalFormStepsBaseId = "modal-massive-settled-form-step-";

      const modalMassiveSettledResultId = "modal-massive-settled-result";
      const modalMassiveSettledResultTitleId = "modal-massive-settled-result-title";

      // Buttons ids
      const btnCreateMassiveSettledId = "btn-create-massive-settled";
      const btnMassiveSettledNextStepId = "btn-massive-settled-next";
      const btnMassiveSettledPrevStepId = "btn-massive-settled-prev";

      //Fields by step
      const step1DateFromId = "masive-settled-date-from";
      const step1DateToId = "masive-settled-date-to";
      const step2ServiceTypesId = "masive-settled-service-types";
      const step3NifsId = "nif-for-setteld-table";
      const textAreaMassiveSettledGenerateId = "massive-settled-observation";

      // Buttons by step
      const btnAddAllServicesId = "btn-massive-settled-add-all-services";
      const btnRemoveAllServicesId = "btn-massive-settled-remove-all-services";
      const btnMassiveSettledObservationAccept = "btn-massive-settled-observation-accept";

      //modal-massive-settled-confirm items
      const modalMassiveSettledConfirmId = "modal-massive-settled-confirm";
      const modalMassiveSettledNumberOfNifsSelectedId = "modal-massive-settled-number-of-nifs-selected";
      const modalMassiveSettledDateFromSelectedId = "massive-settled-date-from-selected";
      const modalMassiveSettledDateToSelectedId = "massive-settled-date-to-selected";
      const modalMassiveSettledServiceProvidersSelectedId = "massive-settled-service-providers-selected";

      document.getElementById(btnMassiveSettledNextStepId).addEventListener("click", () => {
        settledSharedFunctions.showLoadingButton(btnMassiveSettledNextStepId, "btn-barymont-red", true);
        if (validateMasiveSettlementFormByStep(currentStep, step1DateFromId, step1DateToId)) {
          settledSharedFunctions.updateButtonLabel(btnMassiveSettledNextStepId, "Siguiente", "btn-barymont-red");
          return;
        }

        if (currentStep < TOTAL_STEPS) {
          let nextStep = currentStep + 1;
          showMasiveSettledStep(nextStep, TOTAL_STEPS, modalFormStepsBaseId);

          loadDataByStep(currentStep, step1DateFromId, step1DateToId, step2ServiceTypesId, btnAddAllServicesId, btnRemoveAllServicesId, step3NifsId).then((isDataLoaded) => {
            if (isDataLoaded === false) {
              showMasiveSettledStep(currentStep, TOTAL_STEPS, modalFormStepsBaseId);
              settledSharedFunctions.updateButtonLabel(btnMassiveSettledNextStepId, "Siguiente", "btn-barymont-red");
              return;
            }
            currentStep = nextStep;

            settledSharedFunctions.updateButtonLabel(btnMassiveSettledNextStepId, "Siguiente", "btn-barymont-red");
          });
        }
      });

      $("#" + modalMassiveSettledConfirmId).on("show.bs.modal", function (e) {
        if (selectedNifForSettledArray.length === 0) {
          e.preventDefault();
          createToast("warning", "NIFs no seleccionados", "Debes seleccionar al menos un NIF para liquidar", 5000);
          return;
        }

        document.getElementById(modalMassiveSettledNumberOfNifsSelectedId).innerHTML = selectedNifForSettledArray.length;
        document.getElementById(modalMassiveSettledDateFromSelectedId).value = document.getElementById(step1DateFromId).value;
        document.getElementById(modalMassiveSettledDateToSelectedId).value = document.getElementById(step1DateToId).value;

        const selectedItemsFromTagify = tagify.value.map((item) => providersDataFromLastRequest.find((element) => element.value === item.value).label);
        document.getElementById(modalMassiveSettledServiceProvidersSelectedId).innerHTML = selectedItemsFromTagify.map((item) => `<span class="badge badge-secondary" style="font-size: 11px">${item}</span>`).join(" ");
      });

      document.getElementById(btnMassiveSettledObservationAccept).addEventListener("click", () => {
        settledSharedFunctions.showLoadingButton(btnMassiveSettledObservationAccept, "btn-barymont-red", true);

        if (currentStep === TOTAL_STEPS) {
          const dateFrom = document.getElementById(step1DateFromId).value;
          const dateTo = document.getElementById(step1DateToId).value;
          const selectedItemsFromTagify = tagify.value.map((item) => item.value);
          const massiveSettledObservation = document.getElementById(textAreaMassiveSettledGenerateId).value;

          createToast("info", "Generando liquidaciones", "Se están generando las liquidaciones, por favor espera", 5000);

          generateMassiveSettledByNif(selectedNifForSettledArray, dateFrom, dateTo, selectedItemsFromTagify, massiveSettledObservation).then((response) => {
            settledSharedFunctions.updateButtonLabel(btnMassiveSettledObservationAccept, "Generar liquidaciones", "btn-barymont-red");
            resetMasiveSettlementFormData(step1DateFromId, step1DateToId, step3NifsId);
            $("#" + modalMassiveSettledConfirmId).modal("hide");
            $("#" + modalMassiveSettledId).modal("hide");

            if (response.status === 0) {
              createToast("error", "Error", "Ocurrió un error al generar las liquidaciones", 5000);
              return;
            }

            if (response.status === 1) {
              createToast("success", "Liquidaciones generadas", "Se han generado las liquidaciones correctamente", 5000);
              settledSharedFunctions.printSettledResultContent(modalMassiveSettledResultId, response.data, modalMassiveSettledResultTitleId, "SETTLEMENT");
            }

            window.dispatchEvent(new CustomEvent("massiveSettlementCreated"));
          });
        }
      });

      document.getElementById(btnMassiveSettledPrevStepId).addEventListener("click", () => {
        if (currentStep > 1) {
          currentStep--;
          clearValuesMasiveSettlementFormByStep(currentStep, step1DateFromId, step1DateToId, step3NifsId);
          showMasiveSettledStep(currentStep, TOTAL_STEPS, modalFormStepsBaseId);
        }
      });

      document.getElementById(btnCreateMassiveSettledId)?.addEventListener("click", () => {
        $("#" + modalMassiveSettledId).modal("show");
        resetMasiveSettlementFormData(step1DateFromId, step1DateToId, step3NifsId);
        showMasiveSettledStep(currentStep, TOTAL_STEPS, modalFormStepsBaseId);
      });
    }
  });
}

export default {};
