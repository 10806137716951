"use strict";

async function handleSelectUserRequest(event) {
  event.preventDefault();

  let userId = event.target.getAttribute("data-user-id");

  if (!userId) {
    return;
  }

  isLoadingSelectUserButtons(true);

  if (!userId) {
    document.querySelector(".alert.alert-info").classList.remove("d-none");
    document.querySelector(".alert.alert-info").innerHTML = "Ha ocurrido un error al seleccionar el usuario intentelo de nuevo";
    return;
  }

  let formdata = new FormData();
  formdata.append("userId", userId);

  let requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  let response;

  try {
    let result = await fetch("/login/ajax/selectUser", requestOptions).then((response) => response.text());
    response = JSON.parse(result);
  } catch (error) {
    console.log("error", error);
  }

  if (response && response.success) {
    window.location.href = response.fromUrl !== "" ? response.fromUrl : "/dashboard";
  } else {
    isLoadingSelectUserButtons(false);
    document.querySelector(".alert.alert-info").classList.remove("d-none");
    document.querySelector(".alert.alert-info").innerHTML = (response && response.message) || "Ha ocurrido un error al seleccionar el usuario intentelo de nuevo";
  }
}

function isLoadingSelectUserButtons(active) {
  if (active) {
    document.querySelectorAll("a[data-user-id]").forEach(function (button) {
      button.innerHTML = `<i class="fas fa-lg fa-spinner fa-spin mr-3"></i> Cargando`;
      button.classList.add("btn-barymont-grey");
      button.classList.remove("btn-barymont-red");
      button.dataset.waitingUserId = button.dataset.userId;
      button.dataset.userId = "";
    });
  } else {
    document.querySelectorAll("a[data-waiting-user-id]").forEach(function (button) {
      button.innerHTML = `<i class="fas fa-lg fa-door-open mr-3"></i> Entrar`;
      button.classList.add("btn-barymont-red");
      button.classList.remove("btn-barymont-grey");
      button.dataset.userId = button.dataset.waitingUserId;
      button.dataset.waitingUserId = "";
    });
  }
}

function searchInDiv(e) {
  const userCards = document.querySelectorAll("[data-search-terms]");
  userCards.forEach((item) => {
    if (!item.innerHTML.toLowerCase().includes(e.target.value)) {
      item.parentElement.style.display = "none";
    } else {
      item.parentElement.style.display = "block";
    }
  });

  if (Array.from(userCards).every((item) => item.parentElement.style.display === "none")) {
    document.querySelector(".alert.alert-info").classList.remove("d-none");
    document.querySelector(".alert.alert-info").innerHTML = `No se han encontrado usuarios con los términos de búsqueda`;
  } else {
    document.querySelector(".alert.alert-info").classList.add("d-none");
    document.querySelector(".alert.alert-info").innerHTML = "";
  }
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname === "/login/select-user") {
      /** SelectUser behavior */
      document.querySelectorAll("a[data-user-id]").forEach(function (button) {
        button.addEventListener("click", handleSelectUserRequest);
      });

      /** SearchBox behavior */
      document.querySelector("#searchbox").addEventListener("keyup", searchInDiv);
    }
  });
}
