"use strict";

import { createNewPromoterRequest } from "../../api/current-user.js";
import { showLoadingButton, updateButtonLabel, isValidEmail } from "../shared/shared.js";

export function createModalNewPromoter() {
  const randomModalId = `modal-${Math.random().toString(36).substring(2, 10)}`;
  const fragment = document.createDocumentFragment();

  const temporalDiv = document.createElement("div");
  temporalDiv.innerHTML = `
    <div class="modal fade" id="${randomModalId}" tabindex="-1" role="dialog" data-backdrop="static">
      <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">Solicitud de alta de nuevo Promotor</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-12">

                    <div class="form-group">

                      <div class="row">

                        <div class="col-12 mb-3">
                          <img src="https://content.barymont.com/files/6150db4c-db73-47f8-959c-8452d21d4c47.webp" class="img-fluid" alt="Logo Barymont">
                        </div>

                        <div class="col-12">
                          <span class="h4">Datos del nuevo promotor</span>
                        </div>

                        <div class="col-12">
                          <label for="new-promoter-contract-type" class="control-label font-weight-bold">Modelo de contrato:</label>
                          <select class="form-control" id="new-promoter-contract-type" name="new-promoter-contract-type" required>
                            <option value="Contrato Persona Física">Contrato Persona Física</option>
                            <option value="Contrato Persona Jurídica">Contrato Persona Jurídica</option>
                          </select>
                        </div>

                        <div id="variable-inputs" class="col-12"></div>

                        <div class="col-6">
                          <label for="new-promoter-email" class="control-label font-weight-bold">Email</label>
                          <input type="email" class="form-control" id="new-promoter-email" name="new-promoter-email" autocomplete="off" required>
                        </div>

                        <div class="col-6">
                          <label for="new-promoter-phone" class="control-label font-weight-bold">Teléfono</label>
                          <input type="text" class="form-control" id="new-promoter-phone" name="new-promoter-phone" autocomplete="off" required>
                        </div>

                        <div class="col-12 mt-4">
                          <span class="h4">Datos de acceso y corporativos</span>
                        </div>

                        <div class="col-12">
                          <label for="new-promoter-corporate-email-suggestion" class="control-label font-weight-bold">Email Corporativo Sugerido</label>
                          <input type="email" class="form-control" id="new-promoter-corporate-email-suggestion" name="new-promoter-corporate-email-suggestion" autocomplete="off" required>
                          <small class="form-text text-muted">Para nombres simples, se aplicará la norma corporativa: nombre.apellido@ag.barymont.com</small>
                        </div>

                        <div class="col-6">
                          <label for="new-promoter-responsible-name" class="control-label font-weight-bold">Nombre del responsable</label>
                          <input type="text" class="form-control" id="new-promoter-responsible-name" name="new-promoter-responsible-name" autocomplete="off" required>
                        </div>

                        <div class="col-6">
                          <label for="new-promoter-responsible-nif" class="control-label font-weight-bold">NIF del responsable</label>
                          <input type="text" class="form-control" id="new-promoter-responsible-nif" name="new-promoter-responsible-nif" autocomplete="off" required>
                        </div>

                        <div class="col-6">
                          <label for="new-promoter-referer-name" class="control-label font-weight-bold">Nombre del referenciador</label>
                          <input type="text" class="form-control" id="new-promoter-referer-name" name="new-promoter-referer-name" autocomplete="off" required>
                        </div>

                        <div class="col-6">
                          <label for="new-promoter-referer-nif" class="control-label font-weight-bold">NIF del referenciador</label>
                          <input type="text" class="form-control" id="new-promoter-referer-nif" name="new-promoter-referer-nif" autocomplete="off" required>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-barymont-red" id="new-promoter-request-button">Solicitar alta de promotor</button>
                  <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
              </div>
          </div>
      </div>
    </div>`;

  fragment.appendChild(temporalDiv.firstElementChild);

  document.body.appendChild(fragment);

  document.getElementById("new-promoter-corporate-email-suggestion").addEventListener("keyup", function () {
    const defaultDomain = "@ag.barymont.com";

    if (!this.timeoutId) {
      this.timeoutId = null;
    }

    clearTimeout(this.timeoutId);

    this.timeoutId = setTimeout(() => {
      if (this.value === "") {
        return;
      }

      if (this.value.includes("@")) {
        this.value = this.value.split("@")[0];
      }

      this.value = this.value + defaultDomain;
    }, 500);
  });

  const $modalElement = $(`#${randomModalId}`).modal("show");

  $modalElement.on("hidden.bs.modal", function () {
    $modalElement.remove();
  });

  document.getElementById("new-promoter-contract-type").addEventListener("change", function () {
    const promoterContractType = document.getElementById("new-promoter-contract-type").value;
    const promoterFilesContainer = document.getElementById("variable-inputs");

    promoterFilesContainer.innerHTML = "";

    if (promoterContractType === "Contrato Persona Física") {
      promoterFilesContainer.innerHTML = `
        <div class="container-fluid px-0">
          <div class="row">

            <div class="col-12">
              <label for="new-promoter-name" class="control-label font-weight-bold">Nombre</label>
              <input type="text" class="form-control" id="new-promoter-name" name="new-promoter-name" autocomplete="off" required>
            </div>

            <div class="col-6">
              <label for="new-promoter-first-name" class="control-label font-weight-bold">Primer Apellido</label>
              <input type="text" class="form-control" id="new-promoter-first-name" name="new-promoter-first-name" autocomplete="off" required>
            </div>

            <div class="col-6">
              <label for="new-promoter-second-name" class="control-label font-weight-bold">Segundo Apellido</label>
              <input type="text" class="form-control" id="new-promoter-second-name" name="new-promoter-second-name" autocomplete="off" required>
            </div>

            <div class="col-6">
              <label for="new-promoter-nif" class="control-label font-weight-bold">NIF</label>
              <input type="text" class="form-control" id="new-promoter-nif" name="new-promoter-nif" autocomplete="off" required>
            </div>

            <div class="col-6">
              <label for="new-promoter-nif-input" class="control-label font-weight-bold">Adjuntar NIF</label>
              <div class="custom-file">
                <input id="new-promoter-nif-input" type="file" class="custom-file-input" accept=".pdf,.jpg,.jpeg,.png" required>
                <label class="custom-file-label" for="new-promoter-nif-input" data-browse="Subir 📄">Elegir archivo NIF</label>
              </div>
            </div>

          </div>
        </div>
      `;
    }

    if (promoterContractType === "Contrato Persona Jurídica") {
      promoterFilesContainer.innerHTML = `
        <div class="container-fluid px-0">
          <div class="row">

            <div class="col-12">
              <label for="new-promoter-name" class="control-label font-weight-bold">Razón social</label>
              <input type="text" class="form-control" id="new-promoter-name" name="new-promoter-name" autocomplete="off" required>
            </div>

            <div class="col-6">
              <label for="new-promoter-nif" class="control-label font-weight-bold">CIF (Empresa)</label>
              <input type="text" class="form-control" id="new-promoter-nif" name="new-promoter-nif" autocomplete="off" required>
            </div>

            <div class="col-6">
              <label for="new-promoter-cif-input" class="control-label font-weight-bold">Adjuntar CIF (Empresa)</label>
              <div class="custom-file">
                <input id="new-promoter-cif-input" type="file" class="custom-file-input" accept=".pdf,.jpg,.jpeg,.png" required>
                <label class="custom-file-label" for="new-promoter-cif-input" data-browse="Subir 📄">Elegir archivo CIF</label>
              </div>
            </div>

            <div class="col-6">
              <label for="new-promoter-deed-input" class="control-label font-weight-bold">Adjuntar Escritura (Empresa)</label>
              <div class="custom-file">
                <input id="new-promoter-deed-input" type="file" class="custom-file-input" accept=".pdf,.jpg,.jpeg,.png" required>
                <label class="custom-file-label" for="new-promoter-deed-input" data-browse="Subir 📄">Elegir archivo de escritura</label>
              </div>
            </div>

            <div class="col-6">
              <label for="new-promoter-nif-input" class="control-label font-weight-bold">Adjuntar NIF (Personal)</label>
              <div class="custom-file">
                <input id="new-promoter-nif-input" type="file" class="custom-file-input" accept=".pdf,.jpg,.jpeg,.png" required>
                <label class="custom-file-label" for="new-promoter-nif-input" data-browse="Subir 📄">Elegir archivo NIF</label>
              </div>
            </div>

          </div>
        </div>`;

      document.getElementById("new-promoter-cif-input").addEventListener("change", function () {
        const fileName = this.files[0]?.name || "Elegir archivo";
        document.querySelector("#new-promoter-cif-input + .custom-file-label").textContent = fileName;
      });

      document.getElementById("new-promoter-deed-input").addEventListener("change", function () {
        const fileName = this.files[0]?.name || "Elegir archivo";
        document.querySelector("#new-promoter-deed-input + .custom-file-label").textContent = fileName;
      });
    }

    document.getElementById("new-promoter-nif-input").addEventListener("change", function () {
      const fileName = this.files[0]?.name || "Elegir archivo";
      document.querySelector("#new-promoter-nif-input + .custom-file-label").textContent = fileName;
    });

    document.getElementById("new-promoter-name").value = "";
  });

  document.getElementById("new-promoter-contract-type").dispatchEvent(new Event("change"));

  const sendButton = document.getElementById("new-promoter-request-button");

  sendButton.addEventListener("click", async function () {
    showLoadingButton("new-promoter-request-button", "btn-barymont-red");

    const promoterContractType = document.getElementById("new-promoter-contract-type").value;
    const promoterName = document.getElementById("new-promoter-name").value;
    const promoterFirstName = document.getElementById("new-promoter-first-name")?.value || "";
    const promoterSecondName = document.getElementById("new-promoter-second-name")?.value || "";
    const promoterNIF = document.getElementById("new-promoter-nif").value;
    const promoterEmail = document.getElementById("new-promoter-email").value;
    const promoterPhone = document.getElementById("new-promoter-phone").value;
    const promoterCorporateEmailSuggestion = document.getElementById("new-promoter-corporate-email-suggestion").value;
    const promoterRefererName = document.getElementById("new-promoter-referer-name").value;
    const promoterRefererNif = document.getElementById("new-promoter-referer-nif").value;
    const promoterResponsibleName = document.getElementById("new-promoter-responsible-name").value;
    const promoterResponsibleNif = document.getElementById("new-promoter-responsible-nif").value;
    const promoterNifFile = document.getElementById("new-promoter-nif-input").files[0];
    const promoterCifFile = document.getElementById("new-promoter-cif-input")?.files[0];
    const promoterDeedFile = document.getElementById("new-promoter-deed-input")?.files[0];

    if (promoterContractType === "Contrato Persona Física") {
      if (promoterName === "" || promoterFirstName === "" || promoterSecondName === "") {
        createToast("warning", "Faltan Datos", "Debes completar los campos de nombre y apellidos para poder continuar.", 5000);
        updateButtonLabel("new-promoter-request-button", "Solicitar alta de promotor", "btn-barymont-red");
        return;
      }

      if (promoterNifFile === undefined) {
        createToast("warning", "Faltan Datos", "Debes adjuntar el NIF para poder continuar.", 5000);
        updateButtonLabel("new-promoter-request-button", "Solicitar alta de promotor", "btn-barymont-red");
        return;
      }
    }

    if (promoterContractType === "Contrato Persona Jurídica") {
      if (promoterName === "") {
        createToast("warning", "Faltan Datos", "Debes completar el campo de razón social para poder continuar.", 5000);
        updateButtonLabel("new-promoter-request-button", "Solicitar alta de promotor", "btn-barymont-red");
        return;
      }

      if (promoterNifFile === undefined || promoterCifFile === undefined || promoterDeedFile === undefined) {
        createToast("warning", "Faltan Datos", "Debes adjuntar el CIF y la escritura para poder continuar.", 5000);
        updateButtonLabel("new-promoter-request-button", "Solicitar alta de promotor", "btn-barymont-red");
        return;
      }
    }

    if (promoterNIF === "" || promoterEmail === "" || promoterPhone === "" || promoterCorporateEmailSuggestion === "" || promoterRefererName === "" || promoterRefererNif === "" || promoterResponsibleName === "" || promoterResponsibleNif === "") {
      createToast("warning", "Faltan Datos", "Debes completar todos los campos para poder continuar.", 5000);
      updateButtonLabel("new-promoter-request-button", "Solicitar alta de promotor", "btn-barymont-red");
      return;
    }

    if (!isValidEmail(promoterEmail)) {
      createToast("warning", "Email Incorrecto", "El email del promotor no es válido.", 5000);
      updateButtonLabel("new-promoter-request-button", "Solicitar alta de promotor", "btn-barymont-red");
      return;
    }

    if (!isValidEmail(promoterCorporateEmailSuggestion)) {
      createToast("warning", "Email Incorrecto", "El email corporativo sugerido no es válido.", 5000);
      updateButtonLabel("new-promoter-request-button", "Solicitar alta de promotor", "btn-barymont-red");
      return;
    }

    const ValidatePromoterNIF = ValidateSpanishID(promoterNIF);
    if (ValidatePromoterNIF.valid === false) {
      createToast("warning", "NIF Incorrecto", "El NIF del promotor no es válido.", 5000);
      updateButtonLabel("new-promoter-request-button", "Solicitar alta de promotor", "btn-barymont-red");
      return;
    }

    const ValidateRefererNIF = ValidateSpanishID(promoterRefererNif);
    if (ValidateRefererNIF.valid === false) {
      createToast("warning", "NIF Incorrecto", "El NIF del referidor no es válido.", 5000);
      updateButtonLabel("new-promoter-request-button", "Solicitar alta de promotor", "btn-barymont-red");
      return;
    }

    const ValidateResponsibleNIF = ValidateSpanishID(promoterResponsibleNif);
    if (ValidateResponsibleNIF.valid === false) {
      createToast("warning", "NIF Incorrecto", "El NIF del responsable no es válido.", 5000);
      updateButtonLabel("new-promoter-request-button", "Solicitar alta de promotor", "btn-barymont-red");
      return;
    }

    try {
      await createNewPromoterRequest(promoterContractType, promoterName, promoterFirstName, promoterSecondName, promoterNIF, promoterEmail, promoterPhone, promoterCorporateEmailSuggestion, promoterRefererName, promoterRefererNif, promoterResponsibleName, promoterResponsibleNif, promoterNifFile, promoterCifFile, promoterDeedFile);

      $modalElement.modal("hide");

      createToast("success", "Solicitud Procesada", "La solicitud de alta de nuevo promotor ha sido procesada correctamente.", 3000);
    } catch (error) {
      createToast("error", "Error", error.message, 5000);
    } finally {
      updateButtonLabel("new-promoter-request-button", "Solicitar alta de promotor", "btn-barymont-red");
    }
  });
}
