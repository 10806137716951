interface ElementLoaderHandle {
  start: () => void;
  stop: () => void;
}

interface ElementLoaderParams {
  elementId: string;
  loadingText?: string;
  minLoadSeconds?: number;
  autoStopSeconds?: number;
}

export default function useElementLoader({ elementId, loadingText = "Cargando...", minLoadSeconds = 1, autoStopSeconds }: ElementLoaderParams): ElementLoaderHandle {
  const element = document.getElementById(elementId);

  if (!element) {
    console.error(`Error: Element with ID ${elementId} not found.`);
    return { start: () => {}, stop: () => {} };
  }

  const originalHTML = element.innerHTML;
  const isButton = element instanceof HTMLButtonElement;
  const originalStyles = !isButton
    ? {
        width: element.style.width,
        height: element.style.height,
        display: element.style.display,
        alignItems: element.style.alignItems,
        justifyContent: element.style.justifyContent,
      }
    : {};

  let loadingStartTime: number | null = null;
  let timeoutId: number | null = null;

  const restoreOriginalStyles = () => {
    element.innerHTML = originalHTML;
    if (!isButton) Object.assign(element.style, originalStyles);
  };

  const setLoadingState = (isLoading: boolean) => {
    if (isButton) {
      element.disabled = isLoading;
    } else {
      element.classList.toggle("disabled", isLoading);
      if (isLoading) {
        Object.assign(element.style, {
          width: `${element.offsetWidth}px`,
          height: `${element.offsetHeight}px`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        });
      }
    }

    element.innerHTML = isLoading ? `<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>${loadingText}` : originalHTML;

    if (!isLoading) restoreOriginalStyles();
  };

  const clearTimeoutIfExists = () => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };

  const stop = () => {
    const elapsedTime = loadingStartTime ? (Date.now() - loadingStartTime) / 1000 : 0;

    if (elapsedTime < minLoadSeconds) {
      clearTimeoutIfExists();
      timeoutId = window.setTimeout(stop, (minLoadSeconds - elapsedTime) * 1000);
      return;
    }

    clearTimeoutIfExists();
    setLoadingState(false);
  };

  const start = () => {
    clearTimeoutIfExists();
    loadingStartTime = Date.now();
    setLoadingState(true);

    if (autoStopSeconds) {
      timeoutId = window.setTimeout(stop, autoStopSeconds * 1000);
    }
  };

  return { start, stop };
}
