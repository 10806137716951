"use strict";

import { searchUsers } from "../../api/users.js";
import { showLoadingButton, updateButtonLabel } from "../shared/shared.js";
import { addCommonServiceModalsEventListeners } from "./services.js";

var FILTER_STATE_LOCAL_STORAGE_KEY = "SPAD_FILTER_STATE_";
var FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME = 1000 * 60 * 20;
var INPUT_DYNAMIC_FORM_NAME = "datos-especificos";

function getStateOfCustomFilters() {
  return {
    estadoServicio: $("#spad-filter-status").val(),
    tipoServicio: $("#spad-filter-type").val(),
    proveedorServicio: $("#spad-filter-provider").val(),
    updatedTo: $("#spad-filter-last-update-to").val(),
    updatedFrom: $("#spad-filter-last-update-from").val(),
    subordinateUserSelector: document.getElementById("subordinates-selector").value,
    subordinateFilterMode: document.getElementById("subordinates-filter-mode").value,
  };
}

function recreateStateOfCustomFilters(localStorageData) {
  if (localStorageData.customFilter === undefined) {
    return false;
  }

  $("#spad-filter-status").val(localStorageData.customFilter.estadoServicio);
  $("#spad-filter-type").val(localStorageData.customFilter.tipoServicio);
  $("#spad-filter-provider").val(localStorageData.customFilter.proveedorServicio);
  $("#spad-filter-last-update-to").val(localStorageData.customFilter.updatedTo);
  $("#spad-filter-last-update-from").val(localStorageData.customFilter.updatedFrom);

  $("#spad-filter-status").selectpicker("refresh");
  $("#spad-filter-type").selectpicker("refresh");
  $("#spad-filter-provider").selectpicker("refresh");

  document.getElementById("subordinates-selector").value = localStorageData.customFilter.subordinateUserSelector;
  $("#subordinates-selector").selectpicker("refresh");

  document.getElementById("subordinates-filter-mode").value = localStorageData.customFilter.subordinateFilterMode;
  $("#subordinates-filter-mode").selectpicker("refresh");
}

async function fetchSpadTypes() {
  let requestOptions = {
    method: "POST",
    redirect: "follow",
  };

  return await fetch("/herramientas/spad/ajax/getSpadTypesActive", requestOptions).then((response) => response.json());
}

async function printSpadTypes(typeFilterSelectId) {
  await fetchSpadTypes().then((response) => {
    if (!response.success) {
      createToast("error", "Error al cargar los tipos de servicio", "No se han podido cargar los tipos de servicio SPAD", 3000);
      return;
    }

    let selectFilterSpadType = document.getElementById(typeFilterSelectId);

    response.data.forEach((serviceType) => {
      let optionType = document.createElement("option");
      optionType.value = serviceType.serviceTypeId;
      optionType.text = serviceType.name;
      selectFilterSpadType.appendChild(optionType);
    });

    $(`#${typeFilterSelectId}`).selectpicker("refresh");
  });
}

async function fetchSpadProviders() {
  let requestOptions = {
    method: "POST",
    redirect: "follow",
  };

  return await fetch("/herramientas/spad/ajax/getSpadProviders", requestOptions).then((response) => response.json());
}

async function printSpadProviders(providerFilterSelectId) {
  await fetchSpadProviders().then((response) => {
    if (!response.success) {
      createToast("error", "Error al cargar los proveedores de servicio", "No se han podido cargar los proveedores de servicio SPAD", 3000);
      return;
    }

    let selectFilterSpadProvider = document.getElementById(providerFilterSelectId);

    response.data.sort((a, b) => {
      if (a.isActive && !b.isActive) {
        return -1;
      } else if (!a.isActive && b.isActive) {
        return 1;
      } else {
        return a.name.localeCompare(b.name);
      }
    });

    response.data.forEach((serviceProvider) => {
      let optionProvider = document.createElement("option");
      optionProvider.value = serviceProvider.serviceProviderId;
      optionProvider.text = serviceProvider.name;
      serviceProvider.isActive === false ? optionProvider.classList.add("text-danger") : "";
      selectFilterSpadProvider.appendChild(optionProvider);
    });

    $(`#${providerFilterSelectId}`).selectpicker("refresh");
  });
}

async function printApplicantAdvisorSelect(applicantAdvisorSelectId) {
  let selectFilterApplicantAdvisorSelect = document.getElementById(applicantAdvisorSelectId);

  if (!selectFilterApplicantAdvisorSelect) {
    return;
  }

  try {
    const activeUsers = await searchUsers([], [], true);

    activeUsers.forEach((user) => {
      let optionType = document.createElement("option");
      optionType.value = user.userId;
      optionType.dataset.content = `<span>${user.name}</span> <span class='badge bg-${user.userProfile.backgroundColor} text-${user.userProfile.textColor} ml-2'>${user.userProfile.label}</span>`;
      optionType.text = user.name;
      selectFilterApplicantAdvisorSelect.appendChild(optionType);
    });

    $(`#${applicantAdvisorSelectId}`).selectpicker("refresh");
  } catch (error) {
    createToast("error", "Error al cargar los promotores", "No se han podido cargar los promotores", 3000);
    return;
  }
}

async function loadFiltersData(typeFilterSelectId, providerFilterSelectId) {
  await printSpadTypes(typeFilterSelectId);
  await printSpadProviders(providerFilterSelectId);
}

async function fetchSpadServicesByLeadId(idLead) {
  const formData = new FormData();
  formData.append("idLead", idLead);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/spad/ajax/getSpadServicesByLeadId", requestOptions).then((response) => response.json());
}

function loadDatatable() {
  $("#spad-service-table").DataTable({
    language: {
      sProcessing: "Procesando Datos...",
      sLengthMenu: "Mostrar _MENU_ servicios",
      sZeroRecords: "No se encontraron servicios coincidentes con el criterio de búsqueda.",
      sEmptyTable: "Ningún servicio coincidente con el criterio de búsqueda.",
      sInfo: "Mostrando servicios del _START_ al _END_ de un total de _TOTAL_ servicios",
      sInfoEmpty: "Mostrando servicios del 0 al 0 de un total de 0 servicios",
      sInfoFiltered: "(Filtrados de un total de _MAX_ servicios)",
      sInfoPostFix: "",
      sSearch: "<div class='input-group-prepend'><div class='input-group-text'><i class='fas fa-search'></i></div></div>",
      searchPlaceholder: "Datos del Servicio",
      sUrl: "",
      sInfoThousands: ",",
      sLoadingRecords: "Cargando Datos...",
      oPaginate: {
        sFirst: "Primera",
        sLast: "Última",
        sNext: "Siguiente",
        sPrevious: "Anterior",
      },
      oAria: {
        sSortAscending: ": Activar para ordenar la columna de manera ascendente",
        sSortDescending: ": Activar para ordenar la columna de manera descendente",
      },
      buttons: {
        copySuccess: {
          1: "Copiado 1 servicio al Portapapeles",
          _: "Copiados %d servicios al Portapapeles",
        },
        copyTitle: "Servicios Copiados al Portapapeles",
      },
      select: {
        rows: "%d Servicios seleccionados",
      },
    },
    pagingType: "input",
    columnDefs: [
      {
        visible: false,
        targets: AppGbSession.checkUserHasPermission("SpadService:SpadServiceManageAccessChecker") ? [0] : [0, 5, 10, 11],
      },
      {
        searchable: true,
        targets: [0, 1, 2, 3, 6, 7, 8],
      },
      {
        className: "dt-center",
        targets: [1, 3, 4, 5, 7, 8, 10, 11, 12],
      },
      {
        orderable: true,
        targets: [1, 2, 4, 5, 6],
      },
      {
        orderable: false,
        targets: [3, 10, 11, 12],
      },
      {
        width: "10%",
        targets: [5, 6, 7, 8, 9],
      },
      {
        width: "6%",
        targets: [3],
      },
      {
        width: "2%",
        targets: [12],
      },
    ],
    order: [[8, "desc"]],
    processing: true,
    serverSide: true,
    scrollX: true,
    dom: '<"top"fl>Brt<"bottom"ip><"clear">',
    buttons: [
      {
        extend: "colvis",
        text: "Más columnas",
      },
      {
        extend: "collection",
        text: "Herramientas",
        buttons: [
          {
            extend: "copyHtml5",
            text: "Copiar",
          },
          {
            extend: "print",
            text: "Imprimir",
          },
          {
            extend: "excelHtml5",
            title: "Servicios Gestibarymont",
            text: "Excel",
          },
        ],
        fade: true,
      },
      {
        text: '<i class="fas fa-eraser mr-2"></i> Limpiar filtros',
        className: "btn btn-barymont-black my-2 my-md-0",
        action: function () {
          resetearFiltros();
        },
        init: function (api, node) {
          $(node).removeClass("dt-button");
        },
      },
      AppGbSession.checkUserHasPermission("SpadService:SpadServiceExportAccessChecker")
        ? {
            text: '<i class="fas fa-download mr-2"></i> Exportar',
            className: "btn btn-barymont-black my-2 my-md-0",
            action: function () {
              window.open("/administracion/exportaciones?exportType=spad", "_blank");
            },
            init: function (api, node) {
              $(node).removeClass("dt-button");
            },
          }
        : "",
    ],
    pageLength: 10,
    lengthMenu: [
      [10, 25, 50, 100],
      [10, 25, 50, 100],
    ],
    select: false,
    keys: true,
    searchHighlight: true,
    ajax: {
      url: "/herramientas/spad/listaprocessing",
      type: "post",
      data: function (data) {
        const checkServiceState = document.getElementById("spad-filter-status").value;
        data.serviceState = checkServiceState != "" ? checkServiceState : "";

        const checkServiceType = document.getElementById("spad-filter-type").value;
        data.serviceType = checkServiceType != "" ? checkServiceType : "";

        const checkServiceProvider = document.getElementById("spad-filter-provider").value;
        data.serviceProvider = checkServiceProvider != "" ? checkServiceProvider : "";

        data.dateLastUpdateTo = document.getElementById("spad-filter-last-update-to").value;
        data.dateLastUpdateFrom = document.getElementById("spad-filter-last-update-from").value;

        data.subordinateUserSelector = document.getElementById("subordinates-selector") !== null ? document.getElementById("subordinates-selector").value : "";
        data.subordinateFilterMode = document.getElementById("subordinates-filter-mode")?.value;
      },
      error: function (e) {
        if (e.status === 401) {
          createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
          setTimeout(function () {
            location.reload();
          }, 5000);
        } else {
          createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
        }
      },
    },
    fnDrawCallback: function () {
      $('[data-toggle="tooltip"]').tooltip();
    },
    stateSave: true,
    stateSaveCallback: function (settings, data) {
      let storageKey = FILTER_STATE_LOCAL_STORAGE_KEY + settings.sInstance;

      data.customFilter = getStateOfCustomFilters();

      localStorage.setItem(storageKey, JSON.stringify(data));
    },
    stateLoadCallback: function (settings) {
      let storageKey = FILTER_STATE_LOCAL_STORAGE_KEY + settings.sInstance;

      if (localStorage.getItem(storageKey) === null) {
        return false;
      }

      let localStorageData = JSON.parse(localStorage.getItem(storageKey));

      if (localStorageData.time < new Date().getTime() - FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME) {
        return false;
      }

      recreateStateOfCustomFilters(localStorageData);

      return localStorageData;
    },
  });
}

function resetearFiltros() {
  $("#spad-filter-status").val("");
  $("#spad-filter-type").val("");
  $("#spad-filter-provider").val("");
  $("#spad-filter-last-update-to").val("");
  $("#spad-filter-last-update-from").val("");
  $("#subordinates-selector").val("");
  $("#subordinates-filter-mode").val("is_promoter");

  $("#spad-filter-status").selectpicker("refresh");
  $("#spad-filter-type").selectpicker("refresh");
  $("#spad-filter-provider").selectpicker("refresh");
  $("#subordinates-selector").selectpicker("refresh");
  $("#subordinates-filter-mode").selectpicker("refresh");

  $("#spad-service-table").DataTable().search("").draw();
  $("#spad-service-table").DataTable().ajax.reload();
}

async function fetchHistoricalServiceData(serviceId) {
  let formData = new FormData();

  formData.append("serviceId", serviceId);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/spad/ajax/getHistoricalByServiceId", requestOptions).then((response) => response.json());
}

function openHistoricalModal(data, modalId, modalBodyId) {
  let modalBody = document.getElementById(modalBodyId);

  modalBody.innerHTML = "";

  modalBody.insertAdjacentHTML(
    "beforeend",
    `<div class="container-fluid">
        <div class="row">
            <div class="col-4 align-middle text-center text-barymont-black h6 font-weight-bold">◀ ANTERIOR</div>
            <div class="col-4 align-middle text-center text-barymont-black h6 font-weight-bold">⏱️ FECHA CAMBIO</div>
            <div class="col-4 align-middle text-center text-barymont-black h6 font-weight-bold">NUEVO ▶</div>
        </div>
    </div>
    <hr>`
  );

  if (data.length === 0) {
    modalBody.innerHTML += `<div class="text-center">No se encontraron cambios de estado para este servicio</div>`;
  } else {
    data.forEach((element) => {
      let elementMensajeString = "";

      if (element.message != "" && element.message != null) {
        elementMensajeString = '<div class="col-12 align-middle text-center pt-2 text-wrap text-break">💬 ' + element.message + "</div>";
      }

      if (element.previousState.value === element.newState.value) {
        modalBody.insertAdjacentHTML(
          "beforeend",
          `<div id='servicio-historico-estado-id-${element.serviceId}' class="row p-1">
              <div class="col-12 d-flex flex-column justify-content-center">
                  <span style="font-size:13px; white-space: normal;" class="badge bg-barymont-grey w-100 p-3">${element.createdAt}</span>
              </div>
              ${elementMensajeString}
          </div>`
        );
      } else {
        modalBody.insertAdjacentHTML(
          "beforeend",
          `<div id='servicio-historico-estado-id-${element.serviceId}' class="row p-1">
              <div class="col-4 d-flex flex-column justify-content-center">
                  <span style="font-size:13px; white-space: normal;" class="badge ${element.previousState.background} ${element.previousState.textColor} w-100 p-3 text-uppercase">${element.previousState.label}</span>
              </div>
              <div class="col-4 d-flex flex-column justify-content-center">
                  <span style="font-size:13px; white-space: normal;" class="badge bg-barymont-grey w-100 p-3">${element.createdAt}</span>
              </div>
              <div class="col-4 d-flex flex-column justify-content-center">
                  <span style="font-size:13px; white-space: normal;" class="badge ${element.newState.background} ${element.newState.textColor} w-100 p-3 text-uppercase">${element.newState.label}</span>
              </div>
              ${elementMensajeString}
            </div>`
        );
      }
    });
  }

  $("#" + modalId).modal("show");
  $('[data-toggle="tooltip"]').tooltip();
}

async function fetchServiceCurrentStateData(serviceId) {
  let formData = new FormData();

  formData.append("serviceId", serviceId);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/spad/ajax/getCurrentHistoricalStateByServiceId", requestOptions).then((response) => response.json());
}

async function fetchUpdateServiceState(serviceId, newServiceState, newServiceComment, newServiceProvider, sendMailToApplicantAdvisor) {
  let formData = new FormData();

  formData.append("serviceId", serviceId);
  formData.append("newServiceState", newServiceState);
  formData.append("newServiceComment", newServiceComment);
  formData.append("sendMailToApplicantAdvisor", sendMailToApplicantAdvisor);

  if (newServiceProvider != null) {
    formData.append("newServiceProvider", newServiceProvider);
  }

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/spad/ajax/updateServiceState", requestOptions).then((response) => response.json());
}

function openChangeStateModal(data, modalId, modalBodyId, modalSubmitButtonId, changeStateModalFormId) {
  let modalBody = document.getElementById(modalBodyId);
  document.getElementById(changeStateModalFormId).classList.remove("was-validated");

  modalBody.innerHTML = "";

  let selectOptions = "";

  data.statesTypeData.forEach((element) => {
    selectOptions += `<option class="${element.background} ${element.textColor} text-uppercase" value="${element.value}" >${element.label}</option>`;
  });

  let selectOptionsProviders = "";

  data.serviceProvidersByType.forEach((element) => {
    selectOptionsProviders += `<option value="${element.serviceProviderId}" class="text-uppercase">${element.name}</option>`;
  });

  if (selectOptionsProviders == "") {
    selectOptionsProviders = `<option value="" class="text-uppercase" selected disabled>No hay proveedores disponibles</option>`;
  }

  modalBody.insertAdjacentHTML(
    "beforeend",
    `<div class="container-fluid">
        <div class="row">
            <div class="col-6">
                <label for="estado-actual" class="control-label font-weight-bold">Estado Actual:</label>
                <input type="hidden" id="service-current-state" name="service-current-state" value="${data.serviceData.state.value}">
                <span class="badge ${data.serviceData.state.background} ${data.serviceData.state.textColor} w-100 py-3 text-uppercase" style="font-size:13px; white-space: normal;">${data.serviceData.state.label}</span>
            </div>
            <div class="col-6">
                <label for="service-new-state" class="control-label font-weight-bold">Nuevo Estado:</label>
                <select id="service-new-state" class="form-control input-lg text-uppercase selectpicker" name="service-new-state" placeholder="Seleccionar nuevo estado" title="Seleccionar nuevo estado" required>
                  ${selectOptions}
                </select>
                <div class="invalid-feedback">Es necesario seleccionar un Nuevo Estado.</div>
            </div>
            ${
              data.serviceData.state.value == "SOLICITADO" || data.serviceData.state.value == "CANCELADO"
                ? ` <div  class="col-12">
                        <label for="service-provider-id" class="control-label font-weight-bold">Seleccionar Proveedor:</label>
                        <i id="service-provider-popover" class="fas fa-info-circle d-none" style="font-size:16px" data-toggle="tooltip" title="No se puede asignar proveedor, si el nuevo estado es el mismo que el anterior" data-placement="top"></i>
                        <select id="service-provider-id" class="form-control input-lg selectpicker" name="service-provider-id" placeholder="Seleccionar Proveedor" title="Seleccionar Proveedor" required>
                          ${selectOptionsProviders}
                        </select>
                        <div class="invalid-feedback">Es necesario seleccionar un Proveedor.</div>
                    </div>`
                : ""
            }
            <div class="col-12">
                <label for="service-comment" class="control-label font-weight-bold">Comentario:</label>
                <input type="text" id="service-comment" class="form-control input-lg" name="service-comment">
            </div>
            <div class="col-12">
                <div class="form-check align-items-center d-flex">
                    <input type="checkbox" id="service-send-mail-to-applicant-advisor" name="service-send-mail-to-applicant-advisor" class="d-none" value="0">
                    <label id="service-send-mail-to-applicant-advisor-label" for="service-send-mail-to-applicant-advisor" class="font-weight-bold d-none pl-3">¿Desea mandar un correo al promotor con el comentario añadido?</label>
                </div>
            </div>

        </div>
    </div>`
  );

  $("#" + modalId).modal("show");
  $('[data-toggle="tooltip"]').tooltip();
  $(".selectpicker").selectpicker();

  const saveBtn = document.getElementById(modalSubmitButtonId);
  const saveBtnClone = saveBtn.cloneNode(true);
  saveBtn.parentNode.replaceChild(saveBtnClone, saveBtn);

  saveBtnClone.addEventListener("click", function () {
    showLoadingButton(modalSubmitButtonId, "btn-barymont-red");
    const serviceId = data.serviceData.serviceId;
    const newServiceState = document.getElementById("service-new-state").value;
    const newServiceComment = document.getElementById("service-comment").value;
    const newServiceProvider = document.getElementById("service-provider-id") ? document.getElementById("service-provider-id").value : null;
    const sendMailToApplicantAdvisor = document.getElementById("service-send-mail-to-applicant-advisor").checked ? 1 : 0;

    if (newServiceState == "") {
      createToast("error", "Error al cambiar el estado del servicio", "Es necesario seleccionar un nuevo estado para el servicio", 3000);
      updateButtonLabel(modalSubmitButtonId, "Cambiar estado", "btn-barymont-red");
      return;
    }

    if (newServiceState == "SOLICITADO" || newServiceState == "CANCELADO") {
      if (newServiceProvider == "" && document.getElementById("service-current-state").value !== newServiceState) {
        createToast("error", "Error al cambiar el estado del servicio", "Es necesario seleccionar un proveedor para el servicio", 3000);
        updateButtonLabel(modalSubmitButtonId, "Cambiar estado", "btn-barymont-red");
        return;
      }
    }

    if (document.getElementById("service-current-state").value === newServiceState && newServiceComment === "") {
      createToast("error", "Error al cambiar el estado del servicio", "Es necesario añadir un comentario al mantener el estado del servicio", 3000);
      updateButtonLabel(modalSubmitButtonId, "Cambiar estado", "btn-barymont-red");
      return;
    }

    fetchUpdateServiceState(serviceId, newServiceState, newServiceComment, newServiceProvider, sendMailToApplicantAdvisor).then((response) => {
      if (!response.success) {
        createToast("error", "Error al cambiar el estado del servicio", response.message, 3000);
        updateButtonLabel(modalSubmitButtonId, "Cambiar estado", "btn-barymont-red");
        return;
      }

      createToast("success", "Estado del servicio cambiado", response.data.message, 3000);
      $("#spad-service-table").DataTable().ajax.reload();
      $("#" + modalId).modal("hide");
      updateButtonLabel(modalSubmitButtonId, "Cambiar estado", "btn-barymont-red");
      document.dispatchEvent(new CustomEvent("serviceUpdated"));
    });
  });

  document.getElementById("service-new-state").addEventListener("change", function () {
    if (document.getElementById("service-current-state").value !== document.getElementById("service-new-state").value) {
      if (data.serviceData.state.value == "SOLICITADO" || data.serviceData.state.value == "CANCELADO") {
        $("#service-provider-id").prop("required", true);
        $("#service-provider-id").prop("disabled", false);
        document.getElementById("service-provider-popover").classList.add("d-none");
      }

      document.getElementById("service-send-mail-to-applicant-advisor").value = 0;
      document.getElementById("service-send-mail-to-applicant-advisor").checked = false;
      document.getElementById("service-send-mail-to-applicant-advisor").classList.add("d-none");
      document.getElementById("service-send-mail-to-applicant-advisor-label").classList.add("d-none");
      document.getElementById("service-comment").required = false;
    } else {
      if (data.serviceData.state.value == "SOLICITADO" || data.serviceData.state.value == "CANCELADO") {
        $("#service-provider-id").prop("required", false);
        $("#service-provider-id").val("");
        $("#service-provider-id").prop("disabled", true);
        $("#service-provider-id").prop("title", "Modificando al mismo estado no se puede asignar proveedor");

        document.getElementById("service-provider-popover").classList.remove("d-none");
        $('[data-toggle="tooltip"]').tooltip();
      }

      document.getElementById("service-send-mail-to-applicant-advisor").classList.remove("d-none");
      document.getElementById("service-send-mail-to-applicant-advisor-label").classList.remove("d-none");
      document.getElementById("service-comment").required = true;
    }
    $("#service-provider-id").selectpicker("refresh");

    document.getElementById("service-provider-id")?.dispatchEvent(new Event("change"));
  });
}

function printPendingOperationSpadServicesFromLead(data, containerId) {
  let container = document.getElementById(containerId);

  if (data.length === 0) {
    return;
  }

  let elementMensajeString = "";

  data.forEach((pendingOperation) => {
    elementMensajeString += `
      <tr>
          <td class="align-middle text-nowrap">${pendingOperation.createdAt}</td>
          <td colspan="2" class="align-middle text-nowrap"><span class="badge badge-info text-white w-100 p-2">PENDIENTE DE ACEPTACIÓN DOCUMENTO LEGAL [${pendingOperation.serviceType.legalDocumentTypeRefKey}]</span></td>
          <td class="align-middle text-nowrap"><span class="badge badge-light w-100 py-2" style="font-size:13px">${pendingOperation.serviceType.name}</span></td>
          <td class="align-middle text-nowrap">${pendingOperation.userRequestedName}</td>
          <td class="align-middle text-nowrap">
              <div class="p-1 w-100 d-flex justify-content-center align-items-center" data-toggle="tooltip" title="El cliente ha de aceptar el documento legal necesario en menos de 24 horas para poder terminar de cursar esta solicitud.">
                  <i class="far fa-question-circle text-barymont-red" style="font-size:15px"></i>
              </div>
          </td>
      </tr>
    `;
  });

  container.innerHTML = "";

  container.insertAdjacentHTML("beforeend", elementMensajeString);

  $('[data-toggle="tooltip"]').tooltip();
}

function printSpadServicesFromLead(data, containerId) {
  let container = document.getElementById(containerId);

  if (data.length === 0) {
    container.innerHTML = `<td colspan="6" class="align-middle">No se encontraron servicios SPAD para este lead</td>`;
    return;
  }

  let elementMensajeString = "";

  data.forEach((spadService) => {
    elementMensajeString += `
      <tr>
          <td class="align-middle text-nowrap">${spadService.createdAt}</td>
          <td class="align-middle text-nowrap">
              <span class="badge ${spadService.stateType.background} ${spadService.stateType.textColor} w-100 p-2 text-uppercase" style="font-size:13px; white-space: normal;">${spadService.stateType.label}</span>
          </td>
          <td class="align-middle text-nowrap">${spadService.modifiedAt}</td>
          <td class="align-middle text-nowrap"><span class="badge badge-light w-100 py-2" style="font-size:13px">${spadService.serviceServiceTypeName}</span></td>
          <td class="align-middle text-nowrap">${spadService.userName}</td>
          <td class="align-middle text-nowrap">
              <div class="class="datatable-btn-grid-container"">
                  <a id="historical-btn-${spadService.serviceId}" data-service-id="${spadService.serviceId}" class="btn btn-barymont-black w-50 historical-btn-to-open-modal" data-toggle="tooltip" title="Ver histórico de estados">
                      <i class="fas fa-history"></i>
                  </a>
                  <a href="/herramientas/servicio/${spadService.serviceId}" class="btn btn-barymont-red w-50" data-toggle="tooltip" title="Acceder al servicio">
                      <i class="fas fa-info" style="pointer-events: none;"></i>
                  </a>
              </div>
          </td>
      </tr>`;
  });

  container.innerHTML = "";

  container.insertAdjacentHTML("beforeend", elementMensajeString);

  $('[data-toggle="tooltip"]').tooltip();

  container.addEventListener("click", function (event) {
    const serviceId = event.target.getAttribute("data-service-id");

    if (event.target.matches(".historical-btn-to-open-modal")) {
      showLoadingButton(`historical-btn-${serviceId}`, "btn-barymont-black", true);

      fetchHistoricalServiceData(serviceId).then((response) => {
        if (!response.success) {
          createToast("error", "Error al cargar el historial del servicio", "No se ha podido cargar el historial del servicio SPAD", 3000);
          updateButtonLabel(`historical-btn-${serviceId}`, "<i class='fas fa-history' style='pointer-events: none;'></i>", "btn-barymont-black");
          return;
        }
        openHistoricalModal(response.data, "spad-service-historical-modal", "spad-service-historical-modal-body");
        updateButtonLabel(`historical-btn-${serviceId}`, "<i class='fas fa-history' style='pointer-events: none;'></i>", "btn-barymont-black");
      });
    }
  });
}

async function fetchFormDataByServiceTypeId(serviceTypeId) {
  let formData = new FormData();

  formData.append("serviceTypeId", serviceTypeId);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/spad/ajax/getFormDataByServiceTypeId", requestOptions).then((response) => response.json());
}

function printFormDataFromServiceType(data, containerId) {
  let container = document.getElementById(containerId);

  container.innerHTML = "";

  if (data.length === 0) {
    return;
  }

  let elementFormString = "";

  data.inputFields.forEach((element) => {
    const requiredFlag = element.isRequired ? "required" : "";
    const requiredMark = element.isRequired ? '<i class="fas fa-asterisk text-barymont-red ml-1"></i>' : "";
    const inputNameFormatted = `${INPUT_DYNAMIC_FORM_NAME}[${element.inputName}]`;

    const createTextInput = (element) => `
      <label><strong>${element.label}</strong> ${requiredMark}</label>
      <input name="${inputNameFormatted}" type="${element.inputType}" class="form-control" placeholder="${element.placeholder}" ${requiredFlag}>`;

    const createSelectInput = (element) => {
      const options = element.data.map((option) => `<option value="${option.value}">${option.value}</option>`).join("");
      return `
        <label><strong>${element.label}</strong> ${requiredMark}</label>
        <select name="${inputNameFormatted}" class="form-control" title="${element.placeholder}" ${requiredFlag}>
          <option value="" disabled selected>${element.placeholder}</option>
          ${options}
        </select>`;
    };

    switch (element.inputType) {
      case "text":
        elementFormString += createTextInput(element);
        break;
      case "select":
        elementFormString += createSelectInput(element);
        break;
      default:
        break;
    }
  });

  container.insertAdjacentHTML("beforeend", elementFormString);
}

function checkFromDataIsInvalid(selectApplicantAdvisorValue, selectFilterSpadTypeValue, fields) {
  let isInvalid = false;

  if (selectApplicantAdvisorValue == "" || selectFilterSpadTypeValue == "") {
    isInvalid = true;
  }
  for (const [, inputElement] of Object.entries(fields)) {
    if (inputElement.required && inputElement.value.trim() === "") {
      isInvalid = true;
    }
  }

  return isInvalid;
}

async function fetchCreateSpadService(leadId, selectApplicantAdvisorValue, selectFilterSpadTypeValue, onCreationRequestInfoToClientValue, formDynamicData) {
  let formData = new FormData();

  formData.append("leadId", leadId);
  formData.append("applicantAdvisorId", selectApplicantAdvisorValue);
  formData.append("serviceTypeId", selectFilterSpadTypeValue);
  formData.append("onCreationRequestInfoToClient", onCreationRequestInfoToClientValue);

  for (const [key, value] of Object.entries(formDynamicData)) {
    formData.append(key, value);
  }

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/spad/ajax/createSpadService", requestOptions).then((response) => response.json());
}

async function fetchLeadDataByLeadId(idLead) {
  const formData = new FormData();
  formData.append("idLead", idLead);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/spad/ajax/getLeadNameByLeadId", requestOptions).then((response) => response.json());
}

function printMessageStatus(data, containerId) {
  let container = document.getElementById(containerId);

  container.innerHTML = "";
  switch (data.action) {
    case "create":
      container.insertAdjacentHTML("beforeend", `<div class="alert alert-${data.style} fade show mt-2" role="alert">${data.message}</div>`);
      break;
    case "legaDocumentSent":
      container.insertAdjacentHTML(
        "beforeend",
        `<div class="alert alert-${data.style} fade show mt-2 text-center" role="alert">
          <span class="d-block font-weight-bold">${data.message}</span>
          <span class="badge badge-dark p-2 my-2 d-block" style="font-size:17px">${data.legalDocumentKey}</span>
          <span class="d-block font-weight-bold">${data.mailSended}</span>
          <span class="d-block">${data.tip}</span>
        </div>`
      );
      break;
    case "error":
      container.insertAdjacentHTML("beforeend", `<div class="alert alert-${data.style} fade show mt-2" role="alert">${data.message}</div>`);
      break;
  }
}

function printBreadcrumb(leadId, leadName, containerId) {
  let container = document.getElementById(containerId);

  container.innerHTML = "";
  container.insertAdjacentHTML("beforeend", `<a href="/herramientas/leads">Leads </a> / <a href="/herramientas/leads/${leadId}">${leadName}</a> / Servicios Profesionales`);
}

function loadAndOpenChangeStateModal() {
  fetchServiceCurrentStateData($("#service-id").val()).then((response) => {
    if (!response.success) {
      createToast("error", "Error al cargar el estado actual del servicio", "No se ha podido cargar el estado actual del servicio SPAD", 3000);
      return;
    }
    openChangeStateModal(response.data, "spad-service-change-state-modal", "spad-service-change-state-modal-body", "spad-service-change-state-modal-submit", "spad-service-change-state-modal-form");
  });
}

function loadAndOpenHistoricalModal() {
  //? esta funcion simula el event listener
  fetchHistoricalServiceData($("#service-id").val()).then((response) => {
    if (!response.success) {
      createToast("error", "Error al cargar el historial del servicio", "No se ha podido cargar el historial del servicio SPAD", 3000);
      return;
    }
    openHistoricalModal(response.data, "spad-service-historical-modal", "spad-service-historical-modal-body");
  });
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname === "/herramientas/spad") {
      const historicalModalId = "spad-service-historical-modal";
      const historicalModalBodyId = "spad-service-historical-modal-body";

      const changeStateModalId = "spad-service-change-state-modal";
      const changeStateModalBodyId = "spad-service-change-state-modal-body";
      const changeStateModalSubmitId = "spad-service-change-state-modal-submit";
      const changeStateModalFormId = "spad-service-change-state-modal-form";

      const selectFilterSpadTypeId = "spad-filter-type";
      const selectFilterSpadProviderId = "spad-filter-provider";

      loadFiltersData(selectFilterSpadTypeId, selectFilterSpadProviderId).then(() => {
        loadDatatable();
      });

      $(".updatetriggerservicio").change(function () {
        $("#spad-service-table").DataTable().ajax.reload();
      });

      document.getElementById("subordinates-selector").addEventListener("change", function () {
        $("#spad-service-table").DataTable().ajax.reload();
      });

      document.getElementById("subordinates-filter-mode").addEventListener("change", function () {
        $("#spad-service-table").DataTable().ajax.reload();
      });

      document.getElementById("spad-service-container").addEventListener("click", function (event) {
        const serviceId = event.target.getAttribute("data-service-id");

        if (event.target.matches(".historical-btn-to-open-modal")) {
          showLoadingButton(`historical-btn-${serviceId}`, "btn-barymont-black", true);

          fetchHistoricalServiceData(serviceId).then((response) => {
            if (!response.success) {
              createToast("error", "Error al cargar el historial del servicio", "No se ha podido cargar el historial del servicio SPAD", 3000);
              updateButtonLabel(`historical-btn-${serviceId}`, "<i class='fas fa-history' style='pointer-events: none;'></i>", "btn-barymont-black");
              return;
            }
            openHistoricalModal(response.data, historicalModalId, historicalModalBodyId);
            updateButtonLabel(`historical-btn-${serviceId}`, "<i class='fas fa-history' style='pointer-events: none;'></i>", "btn-barymont-black");
          });
        }

        if (event.target.matches(".add-new-state-btn")) {
          showLoadingButton(`add-new-state-btn-${serviceId}`, "btn-barymont-red", true);

          fetchServiceCurrentStateData(serviceId).then((response) => {
            if (!response.success) {
              createToast("error", "Error al cargar el historial del servicio", "No se ha podido cargar el historial del servicio SPAD", 3000);
              updateButtonLabel(`add-new-state-btn-${serviceId}`, "<i class='fas fa-plus' style='pointer-events: none;'></i>", "btn-barymont-red");
              return;
            }
            openChangeStateModal(response.data, changeStateModalId, changeStateModalBodyId, changeStateModalSubmitId, changeStateModalFormId);
            updateButtonLabel(`add-new-state-btn-${serviceId}`, "<i class='fas fa-plus' style='pointer-events: none;'></i>", "btn-barymont-red");
          });
        }
      });

      addCommonServiceModalsEventListeners();
    }

    if (window.location.pathname.includes("/herramientas/leads/spad/")) {
      const selectApplicantAdvisorId = "spad-service-advisor-select";
      const selectFilterSpadTypeId = "spad-service-type-select";
      const spadServiceTypeDynamicFormId = "spad-service-dynamic-form";
      const spadServiceTypeSubmitId = "spad-service-submit-form";
      const spadServiceDescriptionId = "spad-service-description";

      const spadServiceListBody = "spad-service-list-body";
      const spadServicePendingOperationListBody = "spad-service-from-pending-operation";

      const spadServiceMessageStatus = "spad-service-message-status";

      const spadServiceBreadcrums = "spad-service-breadcrums";

      const onCreationRequestInfoToClientAdminId = "request-info-to-client-admin";
      const onCreationRequestInfoToClientUserId = "request-info-to-client-user";
      const onCreationRequestInfoToClientStatus = "on-creation-request-info-to-client";

      const leadId = document.getElementById("lead-id").value;

      fetchLeadDataByLeadId(leadId).then((response) => {
        if (!response.success) {
          createToast("error", "Error al cargar los datos del lead", "No se han podido cargar los datos del lead", 3000);
          return;
        }
        printBreadcrumb(leadId, response.data.leadName, spadServiceBreadcrums);
      });

      printSpadTypes(selectFilterSpadTypeId);
      printApplicantAdvisorSelect(selectApplicantAdvisorId);

      fetchSpadServicesByLeadId(leadId).then((response) => {
        if (!response.success) {
          createToast("error", "Error al cargar los servicios SPAD", "No se han podido cargar los servicios SPAD", 3000);
          return;
        }
        printPendingOperationSpadServicesFromLead(response.data.pendingOperations, spadServicePendingOperationListBody);
        printSpadServicesFromLead(response.data.services, spadServiceListBody);
      });

      document.getElementById(selectFilterSpadTypeId).addEventListener("change", function () {
        const serviceTypeId = document.getElementById(selectFilterSpadTypeId).value;
        fetchFormDataByServiceTypeId(serviceTypeId).then((response) => {
          printFormDataFromServiceType(response.data, spadServiceTypeDynamicFormId);

          document.getElementById(spadServiceDescriptionId).innerHTML = `
          <label><strong>Descripción del servicio:</strong></label>
          <p class="ml-2">${response.data.description}</p>`;

          // In case of User is an Admin
          if (document.getElementById(onCreationRequestInfoToClientAdminId)) {
            document.getElementById(onCreationRequestInfoToClientAdminId).innerHTML = "";

            if (response.data.onCreationEmailClient) {
              document.getElementById(onCreationRequestInfoToClientAdminId).insertAdjacentHTML(
                "beforeend",
                `<label for="${onCreationRequestInfoToClientStatus}" class="font-weight-bold">¿Solicitar automáticamente información al cliente? <i class="fas fa-asterisk text-barymont-red"></i></label>
                  <select name="${onCreationRequestInfoToClientStatus}" id="${onCreationRequestInfoToClientStatus}" class="form-control" title="¿Solicitar automáticamente información al cliente?" required>
                      <option value="1" selected>Sí</option>
                      <option value="0">No</option>
                  </select>
                  <hr>`
              );
            } else {
              document.getElementById(onCreationRequestInfoToClientAdminId).innerHTML = `<input type="hidden" id="${onCreationRequestInfoToClientStatus}" value="0"/>`;
            }
          }

          // In case of User is an User
          if (document.getElementById(onCreationRequestInfoToClientUserId)) {
            document.getElementById(onCreationRequestInfoToClientUserId).innerHTML = `<input type="hidden" id="${onCreationRequestInfoToClientStatus}" value="${response.data.onCreationEmailClient ? 1 : 0}"/>`;
          }
        });
      });

      document.getElementById(spadServiceTypeSubmitId).addEventListener("click", function () {
        const containerWithDynamicForm = document.getElementById(spadServiceTypeDynamicFormId);
        const fields = Array.from(containerWithDynamicForm.querySelectorAll(`[name^=${INPUT_DYNAMIC_FORM_NAME}]`));
        const formDynamicData = Object.fromEntries(fields.map((element) => [element.name, element.value]));

        const selectApplicantAdvisorValue = document.getElementById(selectApplicantAdvisorId).value;
        const selectFilterSpadTypeValue = document.getElementById(selectFilterSpadTypeId).value;
        const onCreationRequestInfoToClientValue = document.getElementById(onCreationRequestInfoToClientStatus).value;

        showLoadingButton("spad-service-submit-form", "btn-barymont-red", true);

        if (checkFromDataIsInvalid(selectApplicantAdvisorValue, selectFilterSpadTypeValue, fields)) {
          createToast("error", "Error al crear el servicio SPAD", "Es necesario completar todos los campos del formulario", 3000);
          updateButtonLabel("spad-service-submit-form", "Solicitar Servicio", "btn-barymont-red");
          return;
        }

        fetchCreateSpadService(leadId, selectApplicantAdvisorValue, selectFilterSpadTypeValue, onCreationRequestInfoToClientValue, formDynamicData).then((response) => {
          printMessageStatus(response.data, spadServiceMessageStatus);

          if (!response.success) {
            createToast("error", "Error al crear el servicio SPAD", "No se ha podido crear el servicio SPAD", 3000);
            updateButtonLabel("spad-service-submit-form", "Solicitar Servicio", "btn-barymont-red");
            return;
          }

          createToast("success", "Servicio SPAD Creado", "Se ha creado el servicio SPAD correctamente", 3000);

          document.getElementById(selectFilterSpadTypeId).value = "";
          $("#" + selectFilterSpadTypeId).selectpicker("refresh");
          containerWithDynamicForm.innerHTML = "";

          fetchSpadServicesByLeadId(leadId).then((response) => {
            if (!response.success) {
              createToast("error", "Error al cargar los servicios SPAD", "No se han podido cargar los servicios SPAD", 3000);
              updateButtonLabel("spad-service-submit-form", "Solicitar Servicio", "btn-barymont-red");
              return;
            }

            printSpadServicesFromLead(response.data.services, spadServiceListBody);
            printPendingOperationSpadServicesFromLead(response.data.pendingOperations, spadServicePendingOperationListBody);
            updateButtonLabel("spad-service-submit-form", "Solicitar Servicio", "btn-barymont-red");
          });
        });
      });
    }
  });
}

export default {
  loadAndOpenHistoricalModal,
  loadAndOpenChangeStateModal,
};
