import ClientErrorResponse from "./clientErrorResponse";
import ServerErrorResponse from "./serverErrorResponse";

export default class ApiRequestError extends Error {
  public status: number;
  public errorData: ClientErrorResponse | ServerErrorResponse;
  public errorType: "clientError" | "serverError" | "unknownError";

  constructor(message: string, status: number, errorType: "clientError" | "serverError" | "unknownError", errorData: ClientErrorResponse | ServerErrorResponse | { message: string }) {
    super(message);
    this.status = status;
    this.errorData = errorData;
    this.errorType = errorType;
    Object.setPrototypeOf(this, ApiRequestError.prototype);
  }
}
