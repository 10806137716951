"use strict";

import { createModal } from "../shared/shared.js";
import { checkIfCurrentUserSessionIsServiceExpert } from "./service_experts.js";
import { checkPermissionByServiceType } from "./services.js";

function refreshServiceFileList(serviceId, filesListDivId, loadingFilesDivId) {
  const loadingFilesDiv = document.getElementById(loadingFilesDivId);
  const filesListDiv = document.getElementById(filesListDivId);

  filesListDiv.innerHTML = "";
  loadingFilesDiv.classList.remove("d-none");

  $.ajax({
    url: "/herramientas/services/ajax/getFilesByServiceId",
    type: "POST",
    dataType: "html",
    data: {
      serviceId: serviceId,
    },
    async: true,
  })
    .done(async function (data) {
      let response = JSON.parse(data);

      if (response["status"] === 1) {
        if (response["data"].length == 0) {
          loadingFilesDiv.classList.add("d-none");
          filesListDiv.innerHTML = `<div class="text-center">Este servicio no dispone de documentos adjuntos</div>`;
          return;
        }

        const deleteDocumentPermission = await checkPermissionByServiceType(response["serviceTypeId"], {
          financialEducationServiceAccessChecker: "FinancialEducationService:FinancialEducationServiceDocumentsManageAccessChecker",
          spadServiceAccessChecker: "SpadService:SpadServiceDocumentsManageAccessChecker",
          pymeServiceAccessChecker: "PymeService:PymeServiceDocumentsManageAccessChecker",
          cpServiceAccessChecker: "CpService:CpServiceDocumentsManageAccessChecker",
          financialPlanningServiceAccessChecker: "FinancialPlanningService:FinancialPlanningServiceManageAccessChecker",
        });

        response["data"].forEach((file) => {
          filesListDiv.innerHTML += `
            <div class="input-group mb-2">
                <input type="text" class="form-control font-weight-bolder" value="${file.friendly_name}" readonly="" data-toggle="tooltip" data-placement="right" title="Nombre del documento">
                <div class="input-group-append">
                    <span class="input-group-text text-center" data-toggle="tooltip" data-placement="left" data-html="true" data-original-title="Fecha de subida ${file.created_at}" title="Fecha de subida ${file.created_at}">
                    <i class="far fa-calendar-alt mr-2"></i> ${file.created_at}
                    </span>
                    <span class="input-group-text text-center" data-toggle="tooltip" data-placement="top" data-html="true" data-original-title="Subido por ${file.user}" title="Subido por ${file.user}">
                    <i class="fas fa-user px-2"></i>
                    </span>
                    <a role="button" target="_black" href="/herramientas/services/ajax/downloadServiceFile?idService=${file.service_id}&idServiceFile=${file.service_file_id}" class="btn btn-barymont-red mr-2" data-toggle="tooltip" data-placement="left" title="Descargar documento">
                    <i class="fas fa-solid fa-sm fa-download mr-2"></i> Descargar
                    </a>
                    ${
                      deleteDocumentPermission
                        ? `<a class="btn btn-barymont-black" href="#" data-delete-file-action="true" data-file-storage-name="${file.storage_name}" data-service-file-id="${file.service_file_id}" data-file-friendly-name="${file.friendly_name}" role="button" data-toggle="tooltip" data-placement="top" title="Eliminar documento" >
                    <i class="far fa-sm fa-trash-alt"></i>
                    </a>`
                        : ""
                    }
                </div>
            </div>
          `;
        });

        $('[data-toggle="tooltip"]').tooltip();

        document.querySelectorAll('[data-delete-file-action="true"]').forEach((element) => {
          element.addEventListener("click", function () {
            createConfirmDeleteServiceFileModal(element.getAttribute("data-file-storage-name"), element.getAttribute("data-service-file-id"), element.getAttribute("data-file-friendly-name"), serviceId, filesListDivId, loadingFilesDivId);
          });
        });
      } else {
        filesListDiv.innerHTML = `<div class="text-center text-danger">Ha ocurrido un error al cargar el listado de documentos.</div>`;
      }

      loadingFilesDiv.classList.add("d-none");
    })
    .fail(function (e) {
      if (e.status === 401) {
        createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
        setTimeout(function () {
          location.reload();
        }, 5000);
      } else {
        createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
      }
    });
}

function uploadFileService(serviceId, file, fileName, uploadFileLoadingDivRandomId, uploadFileFormRandomId, filesListDivId, loadingFilesDivId, modal) {
  const uploadFileLoadingDiv = document.getElementById(uploadFileLoadingDivRandomId);
  const uploadFileForm = document.getElementById(uploadFileFormRandomId);

  uploadFileLoadingDiv.classList.remove("d-none");
  uploadFileForm.classList.add("d-none");

  var formData = new FormData();

  formData.append("file", file);
  formData.append("serviceId", serviceId);
  formData.append("friendlyName", fileName);

  $.ajax({
    url: "/herramientas/services/ajax/uploadFileService",
    type: "POST",
    dataType: "html",
    data: formData,
    processData: false,
    contentType: false,
    async: true,
  })
    .done(function (data) {
      let response = JSON.parse(data);
      if (response["status"] === 1) {
        createToast("success", "Documento subido", "El documento se ha subido correctamente", 5000);
      } else {
        createToast("danger", "Error", response["error"]["message"], 5000);
      }
    })
    .fail(function (e) {
      if (e.status === 401) {
        createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
        setTimeout(function () {
          location.reload();
        }, 5000);
      } else {
        createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
      }
    })
    .always(function () {
      refreshServiceFileList(serviceId, filesListDivId, loadingFilesDivId);
      modal.modal("hide");
      $("[data-service-datatable]")?.DataTable().ajax.reload();
    });
}

function removeFileService(path, serviceFileId, serviceId, filesListDivId, loadingFilesDivId, modal) {
  modal.modal("hide");

  $.ajax({
    url: "/herramientas/services/ajax/removeFileService",
    type: "POST",
    dataType: "html",
    data: {
      serviceId: serviceId,
      path: path,
      serviceFileId: serviceFileId,
    },
    async: true,
  })
    .done(function (data) {
      let response = JSON.parse(data);

      if (response["status"] === 1) {
        createToast("success", "Documento eliminado", "El documento se ha eliminado correctamente", 5000);
      } else {
        createToast("danger", "Error", response["error"]["message"], 5000);
      }
    })
    .fail(function (e) {
      if (e.status === 401) {
        createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
        setTimeout(function () {
          location.reload();
        }, 5000);
      } else {
        createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
      }
    })
    .always(function () {
      refreshServiceFileList(serviceId, filesListDivId, loadingFilesDivId);
      $("[data-service-datatable]")?.DataTable().ajax.reload();
    });
}

function createConfirmDeleteServiceFileModal(path, serviceFileId, friendlyFileName, serviceId, filesListDivId, loadingFilesDivId) {
  const bodyContent = `
                <div class="alert alert-danger text-center" role="alert">
                    Confirme la eliminación del documento
                    <br>
                    <strong style="overflow-wrap: break-word; font-size:18px;">${friendlyFileName}</strong>
                    <br>
                    <strong>¿Estás seguro de que quieres eliminarlo?</strong>
                </div>`;

  const deleteBtnRandomId = Math.random().toString(36).substring(2, 10);

  const footerButtons = [
    { id: deleteBtnRandomId, label: "Eliminar", classes: "btn btn-barymont-red" },
    { id: "modal-confirm-delete-file-close-btn", label: "Cerrar", classes: "btn btn-barymont-black", attr: "data-dismiss='modal'" },
  ];

  const createdModal = createModal("modal-delete-file", "md", "Confirmar eliminación de documento", bodyContent, footerButtons);

  document.getElementById(deleteBtnRandomId).addEventListener("click", function () {
    removeFileService(path, serviceFileId, serviceId, filesListDivId, loadingFilesDivId, createdModal);
  });

  createdModal.modal("show");
}

async function createServiceFileListModal(serviceId, serviceTypeId) {
  const openUploadFileModalRandomId = Math.random().toString(36).substring(2, 10);
  const filesListDivId = Math.random().toString(36).substring(2, 10);
  const loadingFilesDivId = Math.random().toString(36).substring(2, 10);

  const createDocumentPermission = await checkPermissionByServiceType(
    serviceTypeId,
    {
      financialEducationServiceAccessChecker: "FinancialEducationService:FinancialEducationServiceDocumentsManageAccessChecker",
      spadServiceAccessChecker: "SpadService:SpadServiceDocumentsManageAccessChecker",
      pymeServiceAccessChecker: "PymeService:PymeServiceDocumentsManageAccessChecker",
      cpServiceAccessChecker: "CpService:CpServiceDocumentsManageAccessChecker",
      financialPlanningServiceAccessChecker: "FinancialPlanningService:FinancialPlanningServiceManageAccessChecker",
    },
    {
      financialEducationServiceCheckFunction: null,
      spadServiceCheckFunction: null,
      pymeServiceCheckFunction: () => checkIfCurrentUserSessionIsServiceExpert(serviceId),
      cpServiceCheckFunction: null,
      financialPlanningServiceCheckFunction: null,
    }
  );

  const createDocumentDiv = `
    <div class="row mt-4">
        <div class="col-12">
            <button
                id="${openUploadFileModalRandomId}"
                type="button"
                class="btn btn-lg btn-barymont-black btn-block">
                  <i class="fas fa-solid fa-upload pr-2"></i> Subir nuevo documento
            </button>
        </div>
    </div>
  `;

  const bodyContent = `
        <div class="bg-barymont-white border border-barymont-dark-grey text-dark p-4 box-shadow-barymont-dark-grey h-100 scroll-barymont-red" style="max-height: 600px; overflow-y: auto;">

          <div id="${loadingFilesDivId}">
              <div class="d-flex justify-content-center my-3">
                  <div class="spinner-border text-barymont-red" role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
              </div>
          </div>

          <div id="${filesListDivId}" class="col-12"></div>

        </div>

        ${createDocumentPermission ? createDocumentDiv : ""}
        `;

  const footerButtons = [{ id: "modal-show-service-files-close-btn", label: "Cerrar", classes: "btn btn-barymont-black", attr: "data-dismiss='modal'" }];

  const createdModal = createModal("modal-show-files-service", "xl", "Documentos", bodyContent, footerButtons);

  if (createDocumentPermission) {
    document.getElementById(openUploadFileModalRandomId).addEventListener("click", function () {
      createUploadFileModal(serviceId, filesListDivId, loadingFilesDivId);
    });
  }

  createdModal.modal("show");

  refreshServiceFileList(serviceId, filesListDivId, loadingFilesDivId);
}

function createUploadFileModal(serviceId, filesListDivId, loadingFilesDivId) {
  const uploadFileFormRandomId = Math.random().toString(36).substring(2, 10);
  const uploadFileBtnRandomId = Math.random().toString(36).substring(2, 10);
  const uploadFileInputRandomId = Math.random().toString(36).substring(2, 10);
  const uploadFileNameInputRandomId = Math.random().toString(36).substring(2, 10);
  const uploadFileLoadingDivRandomId = Math.random().toString(36).substring(2, 10);

  const bodyContent = `

      <div id="${uploadFileLoadingDivRandomId}" class="d-none">
          <div class="d-flex justify-content-center my-3">
              <div class="spinner-border text-barymont-red" role="status">
                  <span class="sr-only">Loading...</span>
              </div>
          </div>
      </div>

      <form id="${uploadFileFormRandomId}">

          <div class="custom-file">
              <input type="file" id="${uploadFileInputRandomId}" value="" required>
          </div>

          <div class="form-group">
              <label>Nombre del documento: <i class="fas fa-asterisk text-barymont-red"></i></label>

              <input
                type="text"
                id="${uploadFileNameInputRandomId}"
                class="form-control w-100"
                minlength="5"
                maxlength="250"
                placeholder="Introduce un nombre"
                value=""
                required>

          </div>

          <button
            type="submit"
            id="${uploadFileBtnRandomId}"
            class="btn btn-lg btn-barymont-red btn-block">
              <i class="fas fa-solid fa-upload pr-2"></i> Subir documento
          </button>

      </form>`;

  const footerButtons = [{ id: "modal-upload-service-file-close-btn", label: "Cerrar", classes: "btn btn-barymont-black", attr: "data-dismiss='modal'" }];

  //TODO:  Add backdrop static
  const createdModal = createModal("modal-upload-service-file", "md", "Subir documento", bodyContent, footerButtons);

  document.getElementById(uploadFileFormRandomId).addEventListener("submit", function (event) {
    event.preventDefault();

    if (document.getElementById(uploadFileInputRandomId).files.length === 0 || document.getElementById(uploadFileNameInputRandomId).value === "") {
      createToast("warning", "Ha ocurrido un error", "Debes seleccionar un documento y darle un nombre.", 5000);
      return;
    }

    const file = document.getElementById(uploadFileInputRandomId).files[0];
    const fileName = document.getElementById(uploadFileNameInputRandomId).value;

    const maxFileSize = 20971520; // 20MB;

    if (file.size > maxFileSize) {
      createToast("warning", "Ha ocurrido un error", "El tamaño del documento es demasiado grande.", 5000);
      return;
    }

    if (fileName.length > 250 || fileName.length < 5) {
      createToast("warning", "Ha ocurrido un error", "El nombre del documento debe tener entre 5 y 250 carácteres.", 5000);
      return;
    }

    uploadFileService(serviceId, file, fileName, uploadFileLoadingDivRandomId, uploadFileFormRandomId, filesListDivId, loadingFilesDivId, createdModal);
  });

  createdModal.modal("show");
}

function addDocumentModalsEventListener() {
  document.body.addEventListener("click", async function (event) {
    if (event.target.getAttribute("showServiceDocumentModal") !== null) {
      const serviceId = event.target.getAttribute("data-service-id");
      const serviceTypeId = event.target.getAttribute("data-service-type");
      await createServiceFileListModal(serviceId, serviceTypeId);
    }
  });
}

export { addDocumentModalsEventListener };
