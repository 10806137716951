import handleApiResponse from "../../core/application/handleApiResponse";
import User from "../domain/user";

interface SearchUsersParams {
  profileLevel?: string[];
  roles?: string[];
  isActive?: boolean | null;
  search?: string | null;
  limit?: number | null;
  offset?: number | null;
}

export default async function searchUsers(params: SearchUsersParams): Promise<User[]> {
  const filters: Record<string, string | number | boolean | string[]> = {};
  Object.entries(params).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      filters[key] = value;
    }
  });

  const queryParams = new URLSearchParams(filters as Record<string, string>).toString();
  const response = await fetch(`/api/users${queryParams ? "?" + queryParams : ""}`, {
    method: "GET",
    redirect: "follow",
  });

  return handleApiResponse<User[]>(response);
}
