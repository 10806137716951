"use strict";

const MEDAL_GOLD_PATH = "https://content.barymont.com/files/eca768f7-4e7b-4995-9bfc-02aabe057ed5.svg";
const MEDAL_SILVER_PATH = "https://content.barymont.com/files/da451ee6-d6c5-4f7c-baf0-93391fdd0676.svg";
const MEDAL_BRONZE_PATH = "https://content.barymont.com/files/e3d539a6-8595-40a6-b0ba-43bbfad55611.svg";

const QUANTITY_OF_LOAD_MORE_RANKING_PAST = 12;

const FILTER_STATE_LOCAL_STORAGE_KEY = "RANKING_FILTER_STATE_ACTIVE_LIST";
const FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME = 1000 * 60 * 20; // 20 minutes in milliseconds

function showLoadingContainerById(containerId) {
  $("#" + containerId).append(`
    <div id="load-${containerId}" class="col-12">
        <div class="d-flex justify-content-center my-3">
            <div class="spinner-border text-barymont-red" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
  `);
}

function removeLoadingContainerById(containerId) {
  $("#load-" + containerId).remove();
}

function clearContainerDataById(id) {
  $("#" + id).html("");
}

function showLoadingButton(buttonId, bgClass, onlySpiner = false) {
  const loadingHtml = onlySpiner ? '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="pointer-events: none;"></span>' : '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="pointer-events: none;"></span> Cargando...';
  $("#" + buttonId)
    .removeClass(bgClass)
    .addClass("btn-barymont-grey")
    .attr("disabled", true)
    .html(loadingHtml);
}

function updateButtonLabel(buttonId, newLabel, bgClass) {
  $("#" + buttonId)
    .removeClass("btn-barymont-grey")
    .addClass(bgClass)
    .attr("disabled", false)
    .html(newLabel);
}

function printErrorMessaje(containerId, message) {
  $("#" + containerId).html(`
    <div class="col-12">
      <div class="alert alert-light text-center" role="alert">
        <span>${message}</span>
      </div>
    </div>
  `);
}

/* START RANKING ACTIVE LIST */

async function fetchCurrentRankingListVisibles() {
  let requestOptions = {
    method: "POST",
    redirect: "follow",
  };

  return await fetch("/rankings/ajax/getAllCurrentRankings", requestOptions).then((response) => response.json());
}

async function fetchQuantityOfRankingsPast(searchInputValue, startDate, finishDate, categorySelectValue) {
  let formData = new FormData();

  if (searchInputValue) {
    formData.append("searchInputValue", searchInputValue);
  }

  if (startDate) {
    formData.append("startDate", startDate);
  }

  if (finishDate) {
    formData.append("finishDate", finishDate);
  }

  if (categorySelectValue) {
    formData.append("categorySelectValue", categorySelectValue);
  }

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/rankings/ajax/countAllPastRankings", requestOptions).then((response) => response.json());
}

async function fetchRankingCurrentParticipantBreakdownData(rankingId, userId) {
  let formData = new FormData();

  formData.append("rankingId", rankingId);
  formData.append("userId", userId);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/rankings/ajax/getRankingCurrentParticipantBreakdownData", requestOptions).then((response) => response.json());
}

async function fetchExportRankingCurrentParticipantBreakdownData(rankingId, userId) {
  let formData = new FormData();

  formData.append("rankingId", rankingId);
  formData.append("userId", userId);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/rankings/ajax/exportRankingCurrentParticipantBreakdownData", requestOptions).then((response) => response.json());
}

async function loadCurrentRankings(userId, userRankingsContainerId, punctualContainerId, closeContainerId, yearlyContainerId, allRankingsContainer, showMode) {
  clearContainerDataById(userRankingsContainerId);
  clearContainerDataById(punctualContainerId);
  clearContainerDataById(closeContainerId);
  clearContainerDataById(yearlyContainerId);
  clearContainerDataById(allRankingsContainer);

  showLoadingContainerById(userRankingsContainerId);
  showLoadingContainerById(punctualContainerId);
  showLoadingContainerById(closeContainerId);
  showLoadingContainerById(yearlyContainerId);
  showLoadingContainerById(allRankingsContainer);

  await fetchCurrentRankingListVisibles().then((rankingList) => {
    const rankingListOrdered = orderRankingsByCategory(rankingList);

    switch (showMode) {
      case "showOnlyMine":
        rankingList = filterOnlyMyRankings(rankingListOrdered);
        break;
    }

    printRankingList(filterOnlyMyRankings(rankingListOrdered), userRankingsContainerId, userId, "grid");
    removeLoadingContainerById(userRankingsContainerId);

    $('[data-toggle="tooltip"]').tooltip();

    printRankingOnTabs(rankingList, punctualContainerId, closeContainerId, yearlyContainerId, allRankingsContainer, userId);
  });
}

function filterOnlyMyRankings(rankingList) {
  if (!rankingList.status) {
    return rankingList;
  }

  let rankingListFiltered = {
    status: rankingList.status,
    data: [],
  };

  rankingList.data.forEach((ranking) => {
    if (ranking.sessionUserIsParticipant) {
      rankingListFiltered.data.push(ranking);
    }
  });

  return rankingListFiltered;
}

function orderRankingsByCategory(rankingList) {
  if (!rankingList.status) {
    return rankingList;
  }

  let rankingListOrdered = [];

  rankingList.data.forEach((ranking) => {
    if (ranking.category === "PUNCTUAL") {
      rankingListOrdered.push(ranking);
    }
  });

  rankingList.data.forEach((ranking) => {
    if (ranking.category === "YEARLY") {
      rankingListOrdered.push(ranking);
    }
  });

  rankingList.data.forEach((ranking) => {
    if (ranking.category === "MONTHLY") {
      rankingListOrdered.push(ranking);
    }
  });

  rankingList.data.forEach((ranking) => {
    if (ranking.category !== "PUNCTUAL" && ranking.category !== "MONTHLY" && ranking.category !== "YEARLY") {
      rankingListOrdered.push(ranking);
    }
  });

  rankingList.data = rankingListOrdered;

  return rankingList;
}

function printRankingOnTabs(rankingList, punctualContainerId, closeContainerId, yearlyContainerId, allRankingsContainer, userId) {
  let punctualRankingList = {
    status: rankingList.status,
    data: [],
  };

  let closeRankingList = {
    status: rankingList.status,
    data: [],
  };

  let yearlyRankingList = {
    status: rankingList.status,
    data: [],
  };

  rankingList.data.forEach((ranking) => {
    if (ranking.category === "PUNCTUAL") {
      punctualRankingList.data.push(ranking);
    }
    if (ranking.category === "MONTHLY") {
      closeRankingList.data.push(ranking);
    }
    if (ranking.category === "YEARLY") {
      yearlyRankingList.data.push(ranking);
    }
  });

  printRankingList(punctualRankingList, punctualContainerId, userId, "grid");
  removeLoadingContainerById(punctualContainerId);

  printRankingList(closeRankingList, closeContainerId, userId, "grid");
  removeLoadingContainerById(closeContainerId);

  printRankingList(yearlyRankingList, yearlyContainerId, userId, "grid");
  removeLoadingContainerById(yearlyContainerId);

  printRankingList(rankingList, allRankingsContainer, userId, "grid");
  removeLoadingContainerById(allRankingsContainer);
}

function printPastRankingShowMoreButton(userId, containerId, loadMoreButtonId, limit, offset, searchInputValue = null, startDate = null, finishDate = null, categorySelectValue = null) {
  if (document.getElementById("past-ranking-data-container-load-more-button")) {
    document.getElementById("past-ranking-data-container-load-more-button").remove();
  }

  $("#data-" + containerId).append(`
      <div id="${loadMoreButtonId}" class="col-12 text-center order-last">
        <button id="btnLoadMore-${containerId}" class="btn btn-barymont-red mt-3 px-3 mx-auto">
          Ver más rankings finalizados
        </button>
      </div>
    `);

  document.getElementById(`btnLoadMore-${containerId}`)?.addEventListener("click", function () {
    loadPastRankings(userId, containerId, limit, offset, searchInputValue, startDate, finishDate, categorySelectValue);
  });
}

function removePastRankingShowMoreButton(loadMoreButtonId) {
  $("#" + loadMoreButtonId).remove();
}

function printRankingList(rankingList, containerId, higthligthUserId, viewMode) {
  const AllowedViewModes = ["grid", "list"];

  if (!rankingList.status || !AllowedViewModes.includes(viewMode)) {
    if (!AllowedViewModes.includes(viewMode)) {
      console.error("El modo de vista no es correcto, solo se permiten los siguientes modos: " + AllowedViewModes.join(", "));
    }

    $("#" + containerId).html(`
    <div class="col-12">
        <div class="alert alert-light text-center" role="alert">
            <span>No se han encontrado rankings.</span>
        </div>
    </div>
    `);
    return;
  }

  let viewModeClasses = "";
  let viewContainerClasses = "";

  switch (viewMode) {
    case "grid":
      viewModeClasses = "col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-2 px-0 px-md-2 mt-2";
      viewContainerClasses = "row d-none";
      break;
    case "list":
      viewModeClasses = "col-12 mb-3 px-0 px-md-2";
      viewContainerClasses = "px-2 pt-3 pb-0";
      break;
  }

  if ($("#data-" + containerId).length == 0) {
    $("#" + containerId).append(`<div id="data-${containerId}" class="${viewContainerClasses}"></div>`);
  }

  if (rankingList.data.length == 0) {
    $("#" + containerId).append(`
      <div class="col-12">
        <div class="alert alert-light text-center" role="alert">
          <span>No se ha encontrado ningún ranking</span>
        </div>
      </div>
    `);
    return;
  }

  rankingList.data.forEach((ranking) => {
    $("#data-" + containerId).append(`
        <div id="${ranking.rankingId}" class="${viewModeClasses} ranking-card">
            <a href="/rankings/${ranking.rankingId}" class="text-barymont-black">
                <div class="card clickable-box" style="overflow: hidden;">

                    <div class="card-header p-0">
                        <div class="container-fluid px-0">
                            <div class="row no-gutters">

                                <div class="col-xl-8 col-md-8 text-left">
                                    <p class="font-weight-bold py-1 px-2 mb-0 ranking-card-title" style="font-size: 15px; text-wrap:balance">🏆 ${ranking.name}</p>
                                </div>

                                <div class="col-xl-4 col-md-4 position-relative">
                                    <img src="${ranking.coverImage}" alt="ranking ${ranking.name}" style="height: 100%; object-fit: cover; object-position: center;">
                                    <div class="position-absolute" style="bottom: 0px; left: 2px;">
                                        ${printStatusBadge(ranking.status)}
                                    </div>
                                    <div class="position-absolute" style="bottom: 0px; right: 0px;">
                                        <button class="btn btn-barymont-red px-1 py-0" data-toggle="tooltip" data-placement="top" title="Acceder al ranking">
                                            <i class="fas fa-sign-in-alt fa-sm"></i>
                                        </button>
                                    </div>
                                </div>

                                <div class="col-md-12 border-top bg-white" style="font-size: 13px; min-height:92.5px">
                                    <ul class="list-group w-100">
                                        ${printLeaderBoardForRankingList(ranking.leaderBoard, 3, higthligthUserId)}
                                    </ul>
                                </div>

                                ${printTimeLeftBar(ranking.startDate, ranking.finishDate, ranking.lastUpdateDate, ranking.status, 20, 12)}

                            </div>
                        </div>
                    </div>

                </div>
            </a>
        </div>
      `);
  });

  $("#data-" + containerId).removeClass("d-none");
}

function printStatusBadge(status) {
  switch (status) {
    case "FINISHED":
      return `<span class="badge badge-light" style="font-size: 12px;">Finalizado</span>`;
    case "ANALYSIS":
      return `<span class="badge badge-primary" style="font-size: 12px;">Análisis</span>`;
    case "COMPETING":
      return `<span class="badge badge-success" style="font-size: 12px;">Competición</span>`;
    case "PENDING":
      return `<span class="badge badge-secondary" style="font-size: 12px;">Pendiente</span>`;
    default:
      return ``;
  }
}

function printTimeLeftBar(rankingStartDate, rankingFinishDate, rankingLastUpdateDate, rankingStatus, height, colSize) {
  const startDate = new Date(rankingStartDate);
  const finishDate = new Date(rankingFinishDate);
  const lastUpdateDate = new Date(rankingLastUpdateDate);

  if (rankingStatus === "FINISHED") {
    return `
      <div class="col-sm-${colSize}">
        <div class="progress ${colSize != 12 ? "mr-3" : ""}" style="height: ${height}px;" data-toggle="tooltip" data-placement="top" title="El ranking empezó el día ${startDate.toLocaleDateString()} y terminó el día ${finishDate.toLocaleDateString()}">
            <div class="progress-bar text-barymont-black" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%; background-color:#D6AF36; "><span class="font-weight-bold" style="font-size: 12px;"><i class="fa-solid fa-trophy text-barymont-black mr-2"></i> Finalizado <i class="fa-solid fa-trophy text-barymont-black ml-2"></i></span></div>
        </div>
      </div>
    `;
  }

  if (rankingStatus === "ANALYSIS") {
    const now = new Date();

    const totalDays = (lastUpdateDate - finishDate) / (1000 * 60 * 60 * 24);
    const daysPast = (now - finishDate) / (1000 * 60 * 60 * 24);
    const daysLeft = totalDays - daysPast > 0 ? (totalDays - daysPast).toFixed(0) : 0;
    const percentage = (daysPast * 100) / totalDays;

    return `
      <div class="col-sm-${colSize}">
          <div class="progress ${colSize != 12 ? "mr-3" : ""} bg-white" style="height: ${height}px;" data-toggle="tooltip" data-placement="top" title="El ranking empezó el periodo de analisis el día ${finishDate.toLocaleDateString()} y finalizará de analizar el día ${lastUpdateDate.toLocaleDateString()}">
              <div class="progress-bar progress-bar-striped" role="progressbar" aria-valuenow="${percentage}" aria-valuemin="0" aria-valuemax="100" style="background-color:#B2D7FF; width: ${percentage}%">
                  ${percentage > 50 ? `<span class="text-dark font-weight-bold" style="font-size: 12px;">${daysLeft} días restantes</span>` : ""}
              </div>
              <div class="progress-bar progress-bar-striped  bg-primaty" role="progressbar" aria-valuenow="${100 - percentage}" aria-valuemin="0" aria-valuemax="100" style="width: ${100 - percentage}%">
                  ${percentage < 50 ? `<span class="text-white font-weight-bold" style="font-size: 12px;">${daysLeft} días restantes</span>` : ""}
              </div>
          </div>
      </div>
    `;
  }

  if (rankingStatus === "COMPETING") {
    const now = new Date();

    const totalDays = (finishDate - startDate) / (1000 * 60 * 60 * 24);
    const daysPast = (now - startDate) / (1000 * 60 * 60 * 24);
    const daysLeft = totalDays - daysPast > 0 ? (totalDays - daysPast).toFixed(0) : 0;
    const percentage = (daysPast * 100) / totalDays;

    return `
      <div class="col-sm-${colSize}">
          <div class="progress ${colSize != 12 ? "mr-3" : ""} bg-white" style="height: ${height}px;" data-toggle="tooltip" data-placement="top" title="El ranking empezó el día ${startDate.toLocaleDateString()} y finalizará el día ${finishDate.toLocaleDateString()}">
              <div class="progress-bar progress-bar-striped" role="progressbar" aria-valuenow="${percentage}" aria-valuemin="0" aria-valuemax="100" style="background-color:#BEE4C7; width: ${percentage}%">
                  ${percentage > 50 ? `<span class="text-dark font-weight-bold" style="font-size: 12px;">${daysLeft} días restantes</span>` : ""}
              </div>
              <div class="progress-bar progress-bar-striped  bg-success" role="progressbar" aria-valuenow="${100 - percentage}" aria-valuemin="0" aria-valuemax="100" style="width: ${100 - percentage}%">
                  ${percentage < 50 ? `<span class="text-white font-weight-bold" style="font-size: 12px;">${daysLeft} días restantes</span>` : ""}
              </div>
          </div>
      </div>
    `;
  }

  if (rankingStatus === "PENDING") {
    return `
      <div class="col-sm-${colSize}">
          <div class="progress ${colSize != 12 ? "mr-3" : ""} bg-white" style="height: ${height}px;" data-toggle="tooltip" data-placement="top" title="El ranking empezará el día ${startDate.toLocaleDateString()} y finalizará el día ${finishDate.toLocaleDateString()}">
              <div class="progress-bar progress-bar-striped bg-barymont-grey" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"><span class="text-dark font-weight-bold" style="font-size: 12px;">Pendiente</span></div>
          </div>
      </div>
    `;
  }
}

function printLeaderBoardForRankingList(rankingLeaderboard, usersToPrint, higthligthUserId) {
  let htmlLeaderBoard = "";

  if (rankingLeaderboard.length == 0) {
    return `<div class="alert alert-light text-center">Todavía no hay usuarios que hayan puntuado en este ranking</div>`;
  }

  rankingLeaderboard.forEach((rankingUser, index) => {
    if (index >= usersToPrint) {
      return;
    }

    htmlLeaderBoard += `
      <li class="list-group-item rounded-0 border-0 p-1 ${higthligthUserId == rankingUser.userId ? "font-weight-bold" : ""}">
          <div class="d-flex justify-content-between align-items-center">
              ${printPositionsRankingList(rankingUser.position)}
              <div class="w-100 ml-2 d-flex align-items-center">
                <img src="${rankingUser.avatarPath}" class="rounded-circle" alt="user-avatar" style="width: 15px; height: 15px;">
                <span class="ml-2 mb-0" style="text-wrap:balance; font-size: 1.1rem !important;">
                  ${rankingUser.userName}
                </span>
              </div>
              <span class="score-number">
                  ${formatScoreNumber(rankingUser.finalScore)}
              </span>
          </div>
      </li>
    `;
  });

  return htmlLeaderBoard;
}

function printPositionsRankingList(position) {
  switch (position) {
    case 1:
      return `<span class="badge text-dark" style="min-width: 20px; background-color: #D6AF36;"><span class="font-weight-bold">${position}</span></span>`;
    case 2:
      return `<span class="badge text-dark" style="min-width: 20px; background-color: #D7D7D7;"><span class="font-weight-bold">${position}</span></span>`;
    case 3:
      return `<span class="badge text-white" style="min-width: 20px; background-color: #824A02;"><span class="font-weight-bold">${position}</span></span>`;
    default:
      return `<span class="badge badge-dark" style="min-width: 20px;"><span class="font-weight-bold">${position}</span></span>`;
  }
}

/* END RANKING ACTIVE LIST */

/* START RANKING PAST LIST */

async function fetchPastRankingListVisiblesByFilters(limit, offset, searchInputValue, startDate, finishDate, categorySelectValue) {
  let formData = new FormData();

  if (limit) {
    formData.append("limit", limit);
  }

  if (offset) {
    formData.append("offset", offset);
  }

  if (searchInputValue) {
    formData.append("searchInputValue", searchInputValue);
  }

  if (startDate) {
    formData.append("startDate", startDate);
  }

  if (finishDate) {
    formData.append("finishDate", finishDate);
  }

  if (categorySelectValue) {
    formData.append("categorySelectValue", categorySelectValue);
  }

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/rankings/ajax/getAllPastRankings", requestOptions).then((response) => response.json());
}

async function loadPastRankings(userId, containerId, limit, offset, searchInputValue = null, startDate = null, finishDate = null, categorySelectValue = null) {
  const loadMoreButtonId = containerId + "-load-more-button";

  if ($("#" + containerId).children().length == 0) {
    showLoadingContainerById(containerId);
  }

  if ($("#" + loadMoreButtonId).length) {
    showLoadingButton(loadMoreButtonId);
  }
  await fetchPastRankingListVisiblesByFilters(limit, offset, searchInputValue, startDate, finishDate, categorySelectValue).then((rankingList) => {
    printRankingList(rankingList, containerId, userId, "grid");

    loadSeeMoreButtonOnPastRanking(userId, containerId, loadMoreButtonId, searchInputValue, startDate, finishDate, categorySelectValue);
    removeLoadingContainerById(containerId);

    $('[data-toggle="tooltip"]').tooltip();
  });
}

async function loadSeeMoreButtonOnPastRanking(userId, containerId = null, loadMoreButtonId = null, searchInputValue = null, startDate = null, finishDate = null, categorySelectValue = null) {
  const totalPastRanking = await fetchQuantityOfRankingsPast(searchInputValue, startDate, finishDate, categorySelectValue).then((pastRankingCountedResponse) => (pastRankingCountedResponse.status ? pastRankingCountedResponse.data : 0));

  const printedPastRanking = countPrintedPastRanking(containerId);

  if (printedPastRanking < totalPastRanking) {
    printPastRankingShowMoreButton(userId, containerId, loadMoreButtonId, QUANTITY_OF_LOAD_MORE_RANKING_PAST, printedPastRanking, searchInputValue, startDate, finishDate, categorySelectValue);
  } else {
    removePastRankingShowMoreButton(loadMoreButtonId);
  }
}

function countPrintedPastRanking(containerId) {
  return $("#data-" + containerId)
    .children()
    .filter(".ranking-card").length;
}

/* END RANKING PAST LIST */

/* START RANKING PANEL */

async function fetchRankingDataById(rankingId) {
  let formData = new FormData();

  formData.append("rankingId", rankingId);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/rankings/ajax/getRankingByRankingId", requestOptions).then((response) => response.json());
}

async function fetchUserRankingData(rankingId) {
  let formData = new FormData();

  formData.append("rankingId", rankingId);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/rankings/ajax/getRankingUserByRankingIdAndUserId", requestOptions).then((response) => response.json());
}

async function loadRankingData(rankingId, userId, rankingDataContainerId, rankingLeaderBoardContainerId, breadcrumbContainerId = null) {
  showLoadingContainerById(rankingDataContainerId);
  showLoadingContainerById(rankingLeaderBoardContainerId);

  await fetchRankingDataById(rankingId).then((rankingData) => {
    if (rankingData.status == 0) {
      printErrorMessaje(rankingDataContainerId, "No se puede cargar los datos del ranking.");
      printErrorMessaje(rankingLeaderBoardContainerId, "No se puede cargar el listado de usuarios del ranking.");
      return;
    }

    printRankingData(rankingDataContainerId, rankingData.data.name, rankingData.data.startDate, rankingData.data.finishDate, rankingData.data.lastUpdateDate, rankingData.data.status, rankingData.data.numberOfParticipants, rankingData.data.description, rankingData.data.coverImage);
    removeLoadingContainerById(rankingDataContainerId);

    printRankingLeaderBoard(rankingLeaderBoardContainerId, rankingData.data.leaderBoard, userId);
    removeLoadingContainerById(rankingLeaderBoardContainerId);

    printBreadcrumb(breadcrumbContainerId, rankingData.data.name, rankingData.data.lastUpdateDate);
    $('[data-toggle="tooltip"]').tooltip();
  });
}

async function loadUserRankingData(rankingId, rankingUserContainerId, showUserBreackdownDataButtonId, userId) {
  showLoadingContainerById(rankingUserContainerId);

  await fetchUserRankingData(rankingId).then((rankingUserData) => {
    if (rankingUserData.status == 0) {
      printErrorMessaje(rankingUserContainerId, "No estas participando en este ranking.");
      document.getElementById(showUserBreackdownDataButtonId).remove();
      return;
    }
    printUserLaderboard(rankingUserContainerId, rankingUserData);
    removeLoadingContainerById(rankingUserContainerId);

    document.getElementById(showUserBreackdownDataButtonId).addEventListener("click", function () {
      showLoadingButton(showUserBreackdownDataButtonId, "btn-barymont-black", true);

      const breakdownModalId = "modal-participant-breakdown-ranking";
      const breakdownModalTotalPointsId = "modal-participant-breakdown-total-points";
      const breakdownModalExtraPointsId = "modal-participant-breakdown-extra-points";
      const breakdownModalColExtraPointsId = "modal-col-participant-breakdown-extra-points";
      const breakdownModalTableBodyOfDataId = "modal-participant-breakdown-table-body";

      const breakdownFactorModalId = "modal-participant-breakdown-factor-ranking";
      const breakdownFactorModalTotalPointsId = "modal-participant-breakdown-factor-total-points";
      const breakdownFactorModalTableBodyOfDataId = "modal-participant-breakdown-factor-table-body";

      const breakdownFactorProdcutListButtonContainerId = "modal-participant-breakdown-factor-product-list-button-container";
      const breakdownFactorProdcutListButtonId = "modal-participant-breakdown-factor-product-list-button";
      const tableBreakdownProductListId = "modal-participant-breakdown-factor-table-product-list-body";
      const modalParticipantBreakdownFactorProductListId = "modal-participant-breakdown-factor-product-list-ranking";

      const modalParticipantBreakdownExportDataButtonId = "modal-participant-breakdown-export-data-button";

      const element = document.getElementById(modalParticipantBreakdownExportDataButtonId);
      const clone = element.cloneNode(true);
      element.parentNode.replaceChild(clone, element);

      showUserBreackdownData(rankingId, userId, breakdownModalTotalPointsId, breakdownModalExtraPointsId, breakdownModalColExtraPointsId, breakdownModalTableBodyOfDataId, breakdownFactorModalId, breakdownFactorModalTotalPointsId, breakdownFactorModalTableBodyOfDataId, breakdownFactorProdcutListButtonContainerId, breakdownFactorProdcutListButtonId, tableBreakdownProductListId, modalParticipantBreakdownFactorProductListId, modalParticipantBreakdownExportDataButtonId).then(() => {
        updateButtonLabel(showUserBreackdownDataButtonId, "<i class='fas fa-chart-bar' style='font-size: 12px;'></i>", "btn-barymont-black");
        $("#" + breakdownModalId).modal("show");
      });
    });
  });
}

function printRankingData(rankingDataContainerId, name, startDate, finishDate, lastUpdateDate, status, numberOfParticipants, description, coverImage) {
  if ($("#data-" + rankingDataContainerId).length == 0) {
    $("#" + rankingDataContainerId).append(`<div id="data-${rankingDataContainerId}" class="row d-none"></div>`);
  }

  $("#data-" + rankingDataContainerId).append(`
      <div class="col-12 mb-3">
          <span class="h2 font-weight-bolder text-uppercase">
              ${name}
          </span>
      </div>

      ${printTimeLeftBar(startDate, finishDate, lastUpdateDate, status, 35, 12)}

      ${
        numberOfParticipants != 0
          ? `<div class="col-xl-4 mt-3">
                <span class="badge badge-secondary w-100 py-1"><span class="h5"><span id="ranking-data-participants" class="font-weight-bolder">${numberOfParticipants}</span> Participantes</span></span>
            </div>`
          : ""
      }
        <div class="col-xl-${numberOfParticipants != 0 ? "4" : "6"} mt-3 mt-xl-3">
            <span class="badge badge-light w-100 py-1"><span class="h5">Fecha inicio: <span id="ranking-data-start-date" class="font-weight-bolder">${new Date(startDate).toLocaleDateString()}</span></span></span>
        </div>
        <div class="col-xl-${numberOfParticipants != 0 ? "4" : "6"} mt-3 mt-xl-3">
            <span class="badge badge-light w-100 py-1"><span class="h5">Fecha fin: <span id="ranking-data-end-date" class="font-weight-bolder">${new Date(finishDate).toLocaleDateString()}</span></span></span>
        </div>

      <div class="col-xl-7 text-center mt-3 mb-xl-0" style="font-size: 16px; min-height: 80px;">
          <span class="text-black text-center w-100 mt-2" >
              ${description}
          </span>
      </div>

      <div class="col-xl-5 mt-3 text-center">
          <img src="${coverImage}" alt="ranking-image" style="max-width: 101%; object-fit: cover; object-position: center;">
      </div>
  `);

  $("#data-" + rankingDataContainerId).removeClass("d-none");
}

function printPositions(position) {
  switch (position) {
    case 1:
      return `<img src="${MEDAL_GOLD_PATH}" alt="medal" style="width: 30px; height: 30px;">`;
    case 2:
      return `<img src="${MEDAL_SILVER_PATH}" alt="medal" style="width: 30px; height: 30px;">`;
    case 3:
      return `<img src="${MEDAL_BRONZE_PATH}" alt="medal" style="width: 30px; height: 30px;">`;
    default:
      return `<span class="badge badge-dark" style="min-width: 30px;"><span class="h5 mb-0 font-weight-bold">${position}</span></span>`;
  }
}

function printRankingLeaderBoard(rankingLeaderBoardContainerId, rankingLeaderBoard, userId) {
  $("#" + rankingLeaderBoardContainerId)
    .addClass("h5")
    .append(printLeaderBoard(rankingLeaderBoard, rankingLeaderBoard.length, userId, true));
}

function printLeaderBoard(rankingLeaderboard, usersToPrint, higthligthUserId, showScores) {
  if (rankingLeaderboard.length == 0) {
    return `<div class="alert alert-light text-center">Todavía no hay usuarios que hayan puntuado en este ranking</div>`;
  }

  let htmlLeaderBoard = "<div class='container-fluid'><div class='row'>";

  rankingLeaderboard.forEach((rankingUser, index) => {
    if (index >= usersToPrint) {
      return;
    }

    htmlLeaderBoard += `
      <div class="col-12 px-2 list-group-item ${higthligthUserId == rankingUser.userId ? "font-weight-bold" : ""} w-100" style="min-width: 200px; pointer-events: none; user-select: none;">
              <div class="d-flex justify-content-between align-items-center">
                  ${rankingUser.position != null ? printPositions(rankingUser.position) : printPositions("-")}
                  <div class="w-100 d-flex align-items-center">
                      <img src="${rankingUser.avatarPath}" class="rounded-circle mx-2" alt="user-avatar" style="width: 30px; height: 30px; object-fit: cover; object-position: center;">
                      <span class="" style="text-wrap: balance; font-size: 1.1rem !important;">${rankingUser.userName}</span>
                  </div>
                  ${showScores ? `<span class="score-number">${formatScoreNumber(rankingUser.finalScore)}</span>` : ""}
              </div>
      </div>`;
  });

  htmlLeaderBoard += "</div></div>";

  return htmlLeaderBoard;
}

function printBreadcrumb(breadcrumbContainerId, rankingName, rankingLastUpdateDate) {
  if (breadcrumbContainerId && $("#" + breadcrumbContainerId)) {
    $("#" + breadcrumbContainerId).html(rankingName);

    if (new Date(rankingLastUpdateDate) < new Date()) {
      $("#" + breadcrumbContainerId)
        .parent()
        .children()
        .first()
        .attr("href", "/rankings-finalizados")
        .html("Rankings finalizados");
    }
  }
}

function printUserLaderboard(rankingUserContainerId, rankingUserData) {
  if (!rankingUserData.status) {
    $("#" + rankingUserContainerId).html(`
      <div class="col-12">
        <div class="alert alert-warning" role="alert">
          <span>No se han encontrado datos del usuario.</span>
        </div>
      </div>
    `);
    return;
  }

  if ($("#data-" + rankingUserContainerId).length == 0) {
    $("#" + rankingUserContainerId).append(`<div id="data-${rankingUserContainerId}" class="d-none slider-ranking-user-data scroll-lg-barymont-red"></div>`);
  }

  $("#data-" + rankingUserContainerId).append(`<div style="min-width: 250px;"></div>`);

  if (rankingUserData.data.closestParticipants.length == 0) {
    if ($("#data-" + rankingUserContainerId).find("div.alert").length == 0) {
      $("#data-" + rankingUserContainerId).before('<div class="alert alert-warning text-center">Todavía no tienes puntos en este ranking</div>');
    }

    $("#data-" + rankingUserContainerId).append(printUserLaderboardCard(rankingUserData.data.userName, rankingUserData.data.avatarPath, rankingUserData.data.finalScore, rankingUserData.data.position, rankingUserData.data.userId, rankingUserData.data.userId));
  }

  rankingUserData.data.closestParticipants.forEach((rankingUser) => {
    $("#data-" + rankingUserContainerId).append(printUserLaderboardCard(rankingUser.userName, rankingUser.avatarPath, rankingUser.finalScore, rankingUser.position, rankingUser.userId, rankingUserData.data.userId));
  });

  $("#data-" + rankingUserContainerId).append(`<div style="min-width: 250px;"></div>`);

  $("#data-" + rankingUserContainerId).removeClass("d-none");
}

function printUserLaderboardCard(userName, avatarPath, score, position, userId, higthligthUserId) {
  return `<div id="${userId}" class="card m-2 p-3 d-flex flex-column align-items-center justify-content-center ${higthligthUserId == userId ? "border border-barymont-black font-weight-bold bg-barymont-light-grey" : ""}" style="min-width: 205px; pointer-events: none; user-select: none; ${higthligthUserId == userId ? "" : "transform: scale(0.75);"}">
              <div class="position-relative">
                  <img src="${avatarPath}" alt="user-image" class="rounded-circle" style="width: 100%; height: auto; max-width: 125px; object-fit: cover; object-position: center;">
                  <span class="h4 mb-0 position-absolute" style="bottom: -10px; left: 0px;">
                      ${position != null ? printPositions(position) : printPositions("-")}
                  </span>
                  <span class="h4 mb-0 position-absolute" style="bottom: -10px; right: -10px;">
                    <span class="badge badge-dark" style="min-width: 30px;">
                      ${score ? formatScoreNumber(score) : "<span class='h5 mb-0 font-weight-bold'>-</span>"}
                    </span>
                  </span>
              </div>
              <span class="text-center h5 mb-0 mt-2 d-flex align-items-center justify-content-center w-100 ${higthligthUserId == userId ? "font-weight-bold" : ""}" style="height:60px;">${userName}</span>
          </div>`;
}

function formatScoreNumber(scoreAsString) {
  const num = parseFloat(scoreAsString);

  const roundedNum = Math.floor(num * 100) / 100;

  let formattedScore = roundedNum.toLocaleString("es-ES", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  return formattedScore;
}

function centerSliderOnCurrentUserByUserId(containerId, targetUserId) {
  const sliderContainer = $("#data-" + containerId);
  const targetElement = $("#" + targetUserId);

  if (sliderContainer.length && targetElement.length) {
    const sliderCenter = sliderContainer.width() / 2;
    const targetElementCenter = targetElement.offset().left + targetElement.width() / 2 - sliderContainer.offset().left;
    const scrollPosition = targetElementCenter - sliderCenter;

    sliderContainer.scrollLeft(scrollPosition);
  }
}

function loadHightlightcurrentSlideCard(containerId) {
  const sliderContainer = document.getElementById("data-" + containerId);

  function hightlightCurrentSliderCard(targetUserId) {
    const targetElement = document.getElementById(targetUserId);

    if (sliderContainer && targetElement) {
      Array.from(sliderContainer.children).forEach((child) => {
        child.classList.remove("border", "border-barymont-black", "font-weight-bold", "bg-barymont-light-grey");
      });
      targetElement.classList.add("border", "border-barymont-black", "font-weight-bold", "bg-barymont-light-grey");
    }
  }

  if (sliderContainer) {
    sliderContainer.addEventListener("scroll", function () {
      const closestUserId = getClosestUserIdByScrollPosition();
      hightlightCurrentSliderCard(closestUserId);
    });

    // eslint-disable-next-line no-inner-declarations
    function getClosestUserIdByScrollPosition() {
      const sliderCenter = sliderContainer.offsetWidth / 2;
      const scrollPosition = sliderContainer.scrollLeft;
      const closestUserId = Array.from(sliderContainer.children)
        .map((element) => {
          return {
            userId: element.id,
            position: element.offsetLeft + element.offsetWidth / 2 - scrollPosition,
          };
        })
        .reduce((prev, curr) => (Math.abs(curr.position - sliderCenter) < Math.abs(prev.position - sliderCenter) ? curr : prev)).userId;

      return closestUserId;
    }
  }
}

async function showUserBreackdownData(rankingId, userId, breakdownModalTotalPointsId, breakdownModalExtraPointsId, breakdownModalColExtraPointsId, breakdownModalTableBodyOfDataId, breakdownFactorModalId, breakdownFactorModalTotalPointsId, breakdownFactorModalTableBodyOfDataId, breakdownFactorProdcutListButtonContainerId, breakdownFactorProdcutListButtonId, tableBreakdownProductListId, modalParticipantBreakdownFactorProductListId, modalParticipantBreakdownExportDataButtonId) {
  fetchRankingCurrentParticipantBreakdownData(rankingId, userId).then((rankingUserBreakdownData) => {
    document.getElementById(breakdownModalTotalPointsId).value = formatScoreNumber(rankingUserBreakdownData.data.userScore);

    if (rankingUserBreakdownData.data.userExtraPoints > 0) {
      document.getElementById(breakdownModalExtraPointsId).value = formatScoreNumber(rankingUserBreakdownData.data.userExtraPoints);
      document.getElementById(breakdownModalColExtraPointsId).classList.remove("d-none");
    } else {
      document.getElementById(breakdownModalExtraPointsId).value = 0;
      document.getElementById(breakdownModalColExtraPointsId).classList.add("d-none");
    }

    document.getElementById(breakdownModalTableBodyOfDataId).innerHTML = "";

    if (rankingUserBreakdownData.data.factors.length === 0) {
      document.getElementById(breakdownModalTableBodyOfDataId).innerHTML = "<tr><td colspan='3' class='text-center w-100'>No hay datos</td></tr>";
      return;
    }

    rankingUserBreakdownData.data.factors.forEach((factorData) => {
      const rankingUserBreakdownDataContent = `
        <tr>
          <td>${factorData.description}</td>
          <td><input type="text" class="form-control text-right font-weight-bolder" value="${formatScoreNumber(factorData.score)}" disabled></td>
          <td class="text-center">
            <button type="button"
                class='btn btn-sm btn-barymont-red w-100'
                id="factor-breackdown-data-${factorData.factorId}"
            >
                <i class="fas fa-info px-3"></i>
            </button>
          </td>
        </tr>
      `;

      document.getElementById(breakdownModalTableBodyOfDataId).insertAdjacentHTML("beforeend", rankingUserBreakdownDataContent);

      document.getElementById(`factor-breackdown-data-${factorData.factorId}`).addEventListener("click", function () {
        document.getElementById(breakdownFactorModalTotalPointsId).value = formatScoreNumber(factorData.score);

        document.getElementById(breakdownFactorModalTableBodyOfDataId).innerHTML = "";
        document.getElementById("modal-participant-breakdown-factor-name").innerHTML = factorData.description;
        document.getElementById("modal-participant-breakdown-factor-obtainable-points-text").innerHTML = factorData.scoringTypeText;

        if (factorData.scoreDetails.length === 0) {
          document.getElementById(breakdownFactorModalTableBodyOfDataId).innerHTML = "<tr><td colspan='3' class='text-center w-100'>Todavía no se han conseguido puntos en este factor</td></tr>";
        } else {
          factorData.scoreDetails.forEach((factorUserScoreDetails) => {
            const factorBreackdownDataContent = `
            <tr>
            <td>${factorData.scoreIdentifierSubject} - ${factorUserScoreDetails.id}</td>
            <td><input type="text" class="form-control text-right font-weight-bolder py-0" style="height: 20px;" value="${formatScoreNumber(factorUserScoreDetails.score)}" disabled></td>
                <td class="text-center">${factorUserScoreDetails.participationPercentajeAsString}</td>
              </tr>
            `;

            document.getElementById(breakdownFactorModalTableBodyOfDataId).insertAdjacentHTML("beforeend", factorBreackdownDataContent);
          });
        }

        document.getElementById(breakdownFactorProdcutListButtonContainerId).innerHTML = `<button id="${breakdownFactorProdcutListButtonId}-${factorData.factorId}" class="btn btn-barymont-grey w-100 mb-3">Ver los productos incluidos</button>`;

        document.getElementById(`${breakdownFactorProdcutListButtonId}-${factorData.factorId}`).addEventListener("click", function () {
          document.getElementById(tableBreakdownProductListId).innerHTML = "";

          if (factorData.factorProductsDetails.length === 0) {
            document.getElementById(tableBreakdownProductListId).innerHTML = "<tr><td colspan='4' class='text-center w-100'>No hay productos incluidos</td></tr>";
          } else {
            factorData.factorProductsDetails.forEach((factorProductDetails) => {
              const factorProductDetailsContent = `
                  <tr>
                    <td>${factorProductDetails.name}</td>
                    <td>${factorProductDetails.family}</td>
                    <td>${factorProductDetails.companyName}</td>
                    <td>${factorProductDetails.ebrokerProductId}</td>
                  </tr>
            `;

              document.getElementById(tableBreakdownProductListId).insertAdjacentHTML("beforeend", factorProductDetailsContent);
            });
          }
          $("#" + modalParticipantBreakdownFactorProductListId).modal("show");
        });

        $("#" + breakdownFactorModalId).modal("show");
      });
    });
  });

  document.getElementById(modalParticipantBreakdownExportDataButtonId).addEventListener("click", function () {
    showLoadingButton(modalParticipantBreakdownExportDataButtonId, "btn-barymont-red", true);

    fetchExportRankingCurrentParticipantBreakdownData(rankingId, userId).then((response) => {
      if (response.status) {
        let exportData = response.data;
        let blob = new Blob([exportData], { type: "application/vnd.ms-excel;" });
        let url = URL.createObjectURL(blob);
        window.location.href = url;
      } else {
        createToast("error", "❌ Error", "Se ha producido un error al realizar el proceso de exportación...", 5000);
      }
      updateButtonLabel(modalParticipantBreakdownExportDataButtonId, "<i class='fas fa-file-export mr-2'></i> Exportar desglose", "btn-barymont-red");
    });
  });
}

/* END RANKING PANEL */

/* START FILTERS STATE */

function saveStateOfFilters() {
  const selectedMode = document.getElementById("rankings-show-mode").value;
  const searchInput = document.getElementById("ranking-search-input").value;
  const activeTabId = document.getElementById("ranking-data-tabs").querySelector(".ranking-tabs.active").id.replace("-tab", "");

  localStorage.setItem(
    FILTER_STATE_LOCAL_STORAGE_KEY,
    JSON.stringify({
      time: new Date().getTime(),
      selectedMode,
      activeTabId,
      searchInput,
    })
  );
}

function tryToloadStateOfFilters() {
  let localStorageData = JSON.parse(localStorage.getItem(FILTER_STATE_LOCAL_STORAGE_KEY));

  if (!localStorageData) {
    return;
  }

  if (localStorageData.time < new Date().getTime() - FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME) {
    return;
  }

  if (localStorageData.selectedMode) {
    $("#rankings-show-mode").val(localStorageData.selectedMode).selectpicker("refresh");
  }

  if (localStorageData.searchInput.length > 0) {
    $("#ranking-search-input").val(localStorageData.searchInput);
  }

  if (localStorageData.activeTabId) {
    $("#ranking-data-tabs .ranking-tabs.active").removeClass("active").attr("aria-selected", "false");
    $(".tab-content .tab-pane.active").removeClass("show active");

    $("#" + localStorageData.activeTabId + "-tab")
      .addClass("active")
      .attr("aria-selected", "true");
    $("#" + localStorageData.activeTabId).addClass("show active");
  }
}

function filterRankings(allRankingsContainer) {
  const searchInput = document.getElementById("ranking-search-input").value.toLowerCase();

  document.getElementById("defaultStateMessage")?.remove();

  const container = document.getElementById(allRankingsContainer);

  if (!container) {
    return;
  }

  const rankingCards = container.querySelectorAll(".ranking-card");
  let hiddenChildrens = 0;

  rankingCards.forEach((card) => {
    const rankingName = card.querySelector(".ranking-card-title").textContent.toLowerCase();

    if (searchInput && rankingName.includes(searchInput)) {
      card.classList.remove("d-none");
    } else if (searchInput) {
      card.classList.add("d-none");
      hiddenChildrens++;
    } else {
      card.classList.remove("d-none");
    }
  });

  if (searchInput && rankingCards.length === hiddenChildrens) {
    printDefaultStateOnRankingList(container.id, searchInput);
  }
}

function printDefaultStateOnRankingList(containerId, searchInput = "") {
  const container = document.getElementById(containerId);
  const existingMessage = container.querySelector("#defaultStateMessage");

  if (!existingMessage) {
    const defaultStateContent = `
      <div id="defaultStateMessage" class="text-center w-100">
        <p class="mt-3">No se han encontrado resultados para <strong>${searchInput}</strong></p>
      </div>`;

    container.insertAdjacentHTML("beforeend", defaultStateContent);
  }
}

/* END FILTERS STATE */

const saveDelay = (callback, ms = 0) => {
  let timer = null;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => callback(), ms);
  };
};

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname === "/rankings") {
      const userId = document.getElementById("user-id").value;

      const rankingSearchInput = document.getElementById("ranking-search-input");
      const rankingSearchInputClearButton = document.getElementById("ranking-search-input-clear");
      const rankingShowModeSelect = document.getElementById("rankings-show-mode");

      const userRankingsContainerId = "my-current-ranking-data-container";
      const punctualContainerId = "punctual-current-ranking-data-container";
      const closeContainerId = "close-current-ranking-data-container";
      const yearlyContainerId = "yearly-current-ranking-data-container";
      const allRankingsContainer = "all-current-ranking-data-container";

      tryToloadStateOfFilters();

      loadCurrentRankings(userId, userRankingsContainerId, punctualContainerId, closeContainerId, yearlyContainerId, allRankingsContainer, rankingShowModeSelect.value).then(() => {
        filterRankings(allRankingsContainer);
      });

      $('a[data-toggle="tab"]').on("shown.bs.tab", function () {
        rankingSearchInput.value = "";
        saveStateOfFilters();
      });

      rankingSearchInput.addEventListener("input", function () {
        saveStateOfFilters();

        $("#ranking-data-tabs .ranking-tabs.active").removeClass("active").attr("aria-selected", "false");
        $(".tab-content .tab-pane.active").removeClass("show active");

        $("#" + allRankingsContainer + "-tab")
          .addClass("active")
          .attr("aria-selected", "true");
        $("#" + allRankingsContainer).addClass("show active");

        filterRankings(allRankingsContainer);
      });

      rankingSearchInputClearButton.addEventListener("click", function () {
        rankingSearchInput.value = "";
        saveStateOfFilters();

        filterRankings(allRankingsContainer);
      });

      rankingShowModeSelect.addEventListener("change", () => {
        saveStateOfFilters();
        loadCurrentRankings(userId, userRankingsContainerId, punctualContainerId, closeContainerId, yearlyContainerId, allRankingsContainer, rankingShowModeSelect.value).then(() => {
          filterRankings(allRankingsContainer);
        });
      });
    }

    if (window.location.pathname === "/rankings-finalizados") {
      const userIdValue = document.getElementById("user-id").value;

      const containerId = "past-ranking-data-container";
      const offset = 0;

      // eslint-disable-next-line no-inner-declarations
      function reloadPastRankings() {
        document.getElementById(containerId).innerHTML = "";

        const startDate = document.getElementById("past-ranking-start-date").value;
        const finishDate = document.getElementById("past-ranking-finish-date").value;
        const categorySelect = document.getElementById("past-ranking-category-select").value;
        const searchInput = document.getElementById("past-ranking-search-input").value;

        loadPastRankings(userIdValue, containerId, QUANTITY_OF_LOAD_MORE_RANKING_PAST, offset, searchInput, startDate, finishDate, categorySelect);
      }

      reloadPastRankings();

      document.getElementById("past-ranking-search-input-clear").addEventListener("click", function () {
        const searchInput = document.getElementById("past-ranking-search-input");

        if (searchInput.value !== "") {
          searchInput.value = "";

          reloadPastRankings();
        }
      });

      const debouncedSearchPastRankings = saveDelay(() => {
        reloadPastRankings();
      }, 500);

      document.getElementById("rankings-past-filters").addEventListener("change", function (event) {
        if (event.target.classList.contains("triggerPastRankingFilters")) {
          debouncedSearchPastRankings();
        }
      });
    }

    if (window.location.pathname.includes("/rankings/")) {
      const userId = document.getElementById("user-id").value;
      const rankingId = document.getElementById("ranking-id").value;

      const rankingDataContainerId = "ranking-data-container";
      const rankingLeaderBoardContainerId = "ranking-list-data-container";
      const breadcrumbContainerId = "ranking-data-breadcrumb-title";

      loadRankingData(rankingId, userId, rankingDataContainerId, rankingLeaderBoardContainerId, breadcrumbContainerId);

      const rankingUserContainerId = "ranking-user-container";
      const showUserBreackdownDataButtonId = "show-user-breackdown-data";

      loadUserRankingData(rankingId, rankingUserContainerId, showUserBreackdownDataButtonId, userId)
        .then(() => centerSliderOnCurrentUserByUserId(rankingUserContainerId, userId))
        .then(() => loadHightlightcurrentSlideCard(rankingUserContainerId));
    }
  });
}

export default {
  fetchCurrentRankingListVisibles,
  orderRankingsByCategory,
  filterOnlyMyRankings,
  printRankingList,
};
