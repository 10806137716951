"use strict;";

import { showLoadingButton, updateButtonLabel } from "../shared/shared.js";

const modalHubspotProperties = "modal-lead-hubspot";

async function loadHubspotCategories() {
  const responseHubspotCategories = await fecthHubspotCategories();

  let categoriesLinksTab = document.getElementById(modalHubspotProperties + "-categories-links");

  categoriesLinksTab.innerHTML = "";

  responseHubspotCategories.hubspotCategories.forEach((category, index) => {
    categoriesLinksTab.innerHTML += `
            <li class="nav-item">
              <a class="nav-link font-weight-bolder ${index === 0 ? "active" : ""}" style="font-size:17px;" data-toggle="tab" data-target="#${category.value}" role="tab" aria-controls="deals" aria-selected="false">${category.label}</a>
            </li>
    `;
  });
}

async function loadHubspotProperties() {
  const leadId = document.getElementById("lead-id").value;
  const responseHubspotCategories = await fecthHubspotProperties(leadId);

  if (responseHubspotCategories.success === false) {
    createToast("error", "Error ❌", "Ha ocurrido un error al obtener las propiedades de Hubspot, intentalo de nuevo más tarde.");
    return;
  }

  printHubspotProperties(responseHubspotCategories.leadHubspotPropertiesCategory);

  // Update de data for all properties with the same hubspotId when change one of them.
  document.querySelectorAll("[data-hubspot-input]").forEach((property) => {
    property.addEventListener("change", function () {
      document.querySelectorAll("[data-hubspot-input]").forEach((property) => {
        if (property.id === this.id && property.value !== this.value) {
          property.value = this.value;
        }
      });
    });
  });

  $("#" + modalHubspotProperties).modal("show");
}

async function saveHubspotProperties() {
  const leadId = document.getElementById("lead-id").value;

  let hubspotProperties = getHubspotPropertiesArray();

  const responseHubspot = await fetchSaveHubspotProperties(leadId, hubspotProperties);

  if (responseHubspot.success === false) {
    createToast("error", "Error ❌", "Ha ocurrido un error al guardar las propiedades de Hubspot, intentalo de nuevo más tarde.");
    return;
  }

  createToast("success", "Guardado ✅", "Las propiedades de Hubspot se han guardado correctamente.");

  $("#" + modalHubspotProperties).modal("hide");
}

function getHubspotPropertiesArray() {
  let hubspotProperties = [];

  document.querySelectorAll("[data-hubspot-input]").forEach((property) => {
    if (property.readOnly) return;
    if (property.disabled) return;

    let value = property.value;

    if (property.multiple) {
      value = "";
      property.querySelectorAll("option").forEach((option) => {
        if (option.selected) {
          value += option.value + ";";
        }
      });
    }

    let propertyData = {
      hubspotId: property.id,
      value: value,
    };

    if (hubspotProperties.some((property) => property.id === propertyData.hubspotId)) return;

    hubspotProperties.push(propertyData);
  });

  return hubspotProperties;
}

function printHubspotProperties(leadHubspotPropertiesCategory) {
  let categoriesContentTab = document.getElementById(modalHubspotProperties + "-categories-data");
  let propertiesDataHtml = "";

  categoriesContentTab.innerHTML = "";

  leadHubspotPropertiesCategory.forEach((property, index) => {
    propertiesDataHtml = "";

    property.properties.forEach((propertyData) => {
      propertiesDataHtml += propertyData.html;
    });

    categoriesContentTab.insertAdjacentHTML(
      "beforeend",
      `
      <div class="tab-pane fade ${index === 0 ? "show active" : ""} my-2" id="${property.category}" role="tabpanel" aria-labelledby="${property.category}-tab">
        <div class="container">
          <div class="row">
          ${propertiesDataHtml}
          </div>
        </div>
      </div>`
    );
  });

  $(".selectpicker").selectpicker();
}

async function fecthHubspotCategories() {
  return await fetch("/herramientas/leadsHubspot/ajax/getHubspotCategories", {
    method: "POST",
  }).then((response) => response.json());
}

async function fecthHubspotProperties(leadId) {
  let formData = new FormData();
  formData.append("leadId", leadId);

  return await fetch("/herramientas/leadsHubspot/ajax/getHubspotProperties", {
    method: "POST",
    body: formData,
  }).then((response) => response.json());
}

async function fetchSaveHubspotProperties(leadId, hubspotProperties) {
  let formData = new FormData();
  formData.append("leadId", leadId);
  formData.append("hubspotProperties", JSON.stringify(hubspotProperties));

  return await fetch("/herramientas/leadsHubspot/ajax/saveHubspotProperties", {
    method: "POST",
    body: formData,
  }).then((response) => response.json());
}

if (window) {
  window.addEventListener("load", () => {
    // Check for /herramientas/leads/{leadId}
    if (/^\/herramientas\/leads\/[a-f0-9-]{36}\/?$/.test(window.location.pathname)) {
      document.querySelector(".fb-hubspot")?.addEventListener("click", async function () {
        document.getElementById("hubspot-float-button").classList.remove("fa-hubspot");
        document.getElementById("hubspot-float-button").innerHTML = '<span class="spinner-border spinner-border-sm m-1" role="status" aria-hidden="true" style="pointer-events: none;"></span>';

        await loadHubspotCategories();
        await loadHubspotProperties();

        document.getElementById("hubspot-float-button").classList.add("fa-hubspot");
        document.getElementById("hubspot-float-button").innerHTML = "";
      });

      document.getElementById("modal-lead-hubspot-update-button")?.addEventListener("click", async function () {
        showLoadingButton("modal-lead-hubspot-update-button", "btn-barymont-red", false);
        await saveHubspotProperties();
        updateButtonLabel("modal-lead-hubspot-update-button", "<i class='fa-solid fa-floppy-disk mr-2'></i> Actualizar", "btn-barymont-red");
      });
    }
  });
}
