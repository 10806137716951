"use strict";

// ________________ CHECKLIST ________________ //
export async function searchChecklists(entityUuid, type) {
  const response = await fetch(`/api/checklists/${entityUuid}?entityRelationalType=${type}`);
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function createChecklist(checklistTemplateId, entityRelationalId, entityRelationalType, objectiveDate) {
  const response = await fetch(`/api/checklist`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      checklistTemplateId: checklistTemplateId,
      checklistEntityRelationalId: entityRelationalId,
      checklistEntityRelationalType: entityRelationalType,
      objectiveDate: objectiveDate,
    }),
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function deleteChecklist(checklistId) {
  const response = await fetch(`/api/checklist/${checklistId}`, {
    method: "DELETE",
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function editChecklist(checklistId, title, objectiveDate) {
  const response = await fetch(`/api/checklist/${checklistId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      title: title,
      objectiveDate: objectiveDate,
    }),
  });
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function readChecklist(checklistId) {
  const response = await fetch(`/api/checklist/${checklistId}`);
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function orderChecklistCompletionChecks(checklistId, completionChecskOrder) {
  const response = await fetch(`/api/checklist/${checklistId}/completion-checks/reorder`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(completionChecskOrder),
  });
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function orderEntityChecklists(checklistEntityRelationalId, checklistsOrder, checklistEntityRelationalType) {
  const response = await fetch(`/api/checklists/${checklistEntityRelationalId}/reorder`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      checklistsOrder: checklistsOrder,
      checklistEntityRelationalType: checklistEntityRelationalType,
    }),
  });
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function assignCompletionChecksChecklists(checklistIds, assignedUserId){
  const response = await fetch(`/api/checklists/completion-checks/assign`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      checklistIds: checklistIds,
      assignedUserId: assignedUserId,
    }),
  });
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function updateChecklistNotification(checklistId, state, userId) {
  const response = await fetch(`/api/checklist/${checklistId}/user/${userId}/notification`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      toCreate: state,
    }),
  });
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

// ________________ COMPLETION CHECK ________________ //

export async function updateCompletionCheckData(checklistId, completionCheckId, completionCheckData) {
  if (!("description" in completionCheckData) || !("assignedUserId" in completionCheckData) || !("completed" in completionCheckData)) {
    throw new Error("Invalid completion check data");
  }

  const response = await fetch(`/api/checklist/${checklistId}/completion-check/${completionCheckId}`, {
    method: "PATCH",
    body: JSON.stringify(completionCheckData),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function createCompletionCheck(checklistId, description, assignedUserId) {
  const response = await fetch(`/api/checklist/${checklistId}/completion-check`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      description: description,
      assignedUserId: assignedUserId === "" ? null : assignedUserId,
    }),
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function updateCompletionCheck(checklistId, checklistCompletionCheckId, description, assignedUserId, state) {
  const response = await fetch(`/api/checklist/${checklistId}/completion-check/${checklistCompletionCheckId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      description: description,
      assignedUserId: assignedUserId,
      completed: state,
    }),
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function deleteCompletionCheck(checklistId, completionCheckId) {
  const response = await fetch(`/api/checklist/${checklistId}/completion-check/${completionCheckId}`, {
    method: "DELETE",
  });

  const responseData = await response.json();

  if (!response.ok) {
    if (response.status === 409) {
      throw new Error("No puedes eliminar el último Check de una checklist, elimina la checklist en su lugar");
    }

    throw new Error(responseData.message);
  }

  return responseData;
}

export async function asingUserToCompletionCheck(checklistId, completionCheckId, userId) {
  const response = await fetch(`/api/checklist/${checklistId}/completion-check/${completionCheckId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      assignedUserId: userId,
    }),
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

