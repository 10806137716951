/**
 * Creates a debounced version of the given function that delays its execution until after the specified delay.
 *
 * @template F - The type of the function to debounce.
 * @param {F} fn - The function to debounce.
 * @param {number} delay - The delay in milliseconds.
 * @returns {F} The debounced function.
 */
export default function debounce<F extends (...args: unknown[]) => void>(fn: F, delay: number): F {
  let timerId: number | NodeJS.Timeout | undefined;
  return ((...args: Parameters<F>) => {
    if (timerId !== undefined) {
      clearTimeout(timerId);
    }
    timerId = window.setTimeout(() => fn(...args), delay);
  }) as F;
}
