"use strict";

import { showLoadingButton, updateButtonLabel } from "../shared/shared.js";

async function fetchLeadsData(fechaDesde, fechaHasta, origen) {
  let formData = new FormData();

  if (fechaDesde) {
    formData.append("fechaDesde", fechaDesde);
  }

  if (fechaHasta) {
    formData.append("fechaHasta", fechaHasta);
  }

  if (origen) {
    formData.append("origen", origen);
  }

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/administracion/exportaciones/ajax/exportLeads", requestOptions).then((response) => response.json());
}

async function fetchCalendarioData(fechaDesde, fechaHasta) {
  let formData = new FormData();

  formData.append("fechaDesde", fechaDesde);
  formData.append("fechaHasta", fechaHasta);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/administracion/exportaciones/ajax/exportCalendar", requestOptions).then((response) => response.json());
}

async function fetchFormacionData(fechaDesde, fechaHasta, idCurso) {
  let formData = new FormData();

  formData.append("fechaDesde", fechaDesde);
  formData.append("fechaHasta", fechaHasta);
  formData.append("idCurso", idCurso);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/administracion/exportaciones/ajax/exportFormacion", requestOptions).then((response) => response.json());
}

async function fetchGoalsData(goalPeriodSelected, goalUserSelected, goalCategorySelected) {
  let formData = new FormData();

  formData.append("goalPeriodSelected", goalPeriodSelected);
  formData.append("goalUserSelected", goalUserSelected);
  formData.append("goalCategorySelected", goalCategorySelected);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/administracion/exportaciones/ajax/exportGoals", requestOptions).then((response) => response.json());
}

async function fetchAchievementData(fechaDesde, fechaHasta, achievementType) {
  let formData = new FormData();

  formData.append("fechaDesde", fechaDesde);
  formData.append("fechaHasta", fechaHasta);
  formData.append("achievementType", achievementType);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/administracion/exportaciones/ajax/exportAchievement", requestOptions).then((response) => response.json());
}

async function fetchUsersData() {
  let formData = new FormData();

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/administracion/exportaciones/ajax/exportUsers", requestOptions).then((response) => response.json());
}

async function fetchNifsData() {
  let formData = new FormData();

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/administracion/exportaciones/ajax/exportNifs", requestOptions).then((response) => response.json());
}

async function fetchAllNifsData() {
  let formData = new FormData();

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/nif/ajax/getAllNifs", requestOptions).then((response) => response.json());
}

async function fetchFinancialEducationServicesData(fechaDesde, fechaHasta) {
  let formData = new FormData();
  formData.append("fechaDesde", fechaDesde);
  formData.append("fechaHasta", fechaHasta);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/administracion/exportaciones/ajax/exportFinancialEducationServices", requestOptions).then((response) => response.json());
}

async function fetchSpadData(fechaDesde, fechaHasta) {
  let formData = new FormData();
  formData.append("fechaDesde", fechaDesde);
  formData.append("fechaHasta", fechaHasta);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/administracion/exportaciones/ajax/exportSpad", requestOptions).then((response) => response.json());
}

async function fetchCommissionData(fechaDesde, fechaHasta, serviceTypes, isSettledCommission, nifSelecteds) {
  let formData = new FormData();

  formData.append("fechaDesde", fechaDesde);
  formData.append("fechaHasta", fechaHasta);
  formData.append("serviceTypes", serviceTypes);
  formData.append("isSettledCommission", isSettledCommission);
  formData.append("nifSelecteds", nifSelecteds);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/administracion/exportaciones/ajax/exportCommissions", requestOptions).then((response) => response.json());
}

async function fetchSettlementData(fechaDesde, fechaHasta) {
  let formData = new FormData();

  formData.append("fechaDesde", fechaDesde);
  formData.append("fechaHasta", fechaHasta);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/administracion/exportaciones/ajax/exportSettlement", requestOptions).then((response) => response.json());
}

async function fetchPymeServiceData(fechaDesde, fechaHasta) {
  let formData = new FormData();
  formData.append("fechaDesde", fechaDesde);
  formData.append("fechaHasta", fechaHasta);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/administracion/exportaciones/ajax/exportPymeServices", requestOptions).then((response) => response.json());
}

async function fetchCpServiceData(fechaDesde, fechaHasta) {
  let formData = new FormData();
  formData.append("fechaDesde", fechaDesde);
  formData.append("fechaHasta", fechaHasta);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/administracion/exportaciones/ajax/exportCpServices", requestOptions).then((response) => response.json());
}

async function fetchCorporateEvents() {
  let requestOptions = {
    method: "POST",
    redirect: "follow",
  };

  return await fetch("/administracion/exportaciones/ajax/getCorporateEvents", requestOptions).then((response) => response.json());
}

async function fetchCorporateEventInscriptions(corporateEventId) {
  let formData = new FormData();
  formData.append("corporateEventId", corporateEventId);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/administracion/exportaciones/ajax/exportCorporateEventInscriptions", requestOptions).then((response) => response.json());
}

async function fetchPromoterRequestsData() {
  return await fetch("/administracion/exportaciones/ajax/exportUserPromotersRequest").then((response) => response.json());
}

async function fetchLeadInterestDetectedData() {
  return await fetch("/administracion/exportaciones/ajax/exportLeadInterestReport").then((response) => response.json());
}

function checkIfNifSelectHadOptions(commissionNifSelectId) {
  if (document.getElementById(commissionNifSelectId).options.length === 0) {
    return false;
  }
  return true;
}

if (window) {
  if (window.location.pathname === "/administracion/exportaciones") {
    const params = new URLSearchParams(document.location.search);
    const exportType = params.get("exportType");

    $("#modal-export-commission").on("shown.bs.modal", function () {
      const commissionNifSelectId = "nif-commissions";

      if (!checkIfNifSelectHadOptions(commissionNifSelectId)) {
        fetchAllNifsData().then((response) => {
          if (response.status === 1) {
            const nifSelect = document.getElementById(commissionNifSelectId);

            const option = document.createElement("option");
            option.value = "";
            option.text = "Todos los NIF";
            nifSelect.appendChild(option);

            response.data.forEach((nifObject) => {
              const option = document.createElement("option");
              option.value = nifObject.nif;
              option.text = "[" + nifObject.nif + "] " + nifObject.full_name;
              nifSelect.appendChild(option);
            });

            $(`#${commissionNifSelectId}`).selectpicker("refresh");
          }
        });
      }
    });

    $("#modal-export-corporate-event-inscriptions").on("shown.bs.modal", async function () {
      const response = await fetchCorporateEvents();

      if (response.status === 0) {
        createToast("error", "❌ Error", "Se ha producido un error al cargar los eventos disponibles.", 5000);
        $("#modal-export-corporate-event-inscriptions").modal("hide");
        return;
      }
      let corporateEventsSelect = document.getElementById("corporate-events");

      corporateEventsSelect.innerHTML = "";

      response.corporateEvents.forEach((corporateEvent) => {
        corporateEventsSelect.innerHTML += `<option value="${corporateEvent.corporateEventId}">${corporateEvent.name}</option>`;
      });

      $(`#corporate-events`).selectpicker("refresh");
    });

    if (exportType === "leads") {
      $("#modal-export-leads").modal("show");
    }

    if (exportType === "users") {
      $("#modal-export-users").modal("show");
      document.getElementById("btn-exportar-users").click();
    }

    if (exportType === "calendar") {
      $("#modal-export-calendar").modal("show");
    }

    if (exportType === "formacion") {
      $("#modal-export-formacion").modal("show");
    }

    if (exportType === "educacion-financiera") {
      $("#modal-export-financial-education-services").modal("show");
      document.getElementById("btn-exportar-financial-education-services").click();
    }

    if (exportType === "nifs") {
      $("#modal-export-nifs").modal("show");
      document.getElementById("btn-exportar-nifs").click();
    }

    if (exportType === "metas") {
      $("#modal-export-goals").modal("show");
    }

    if (exportType === "logros") {
      $("#modal-export-achievement").modal("show");
    }

    if (exportType === "spad") {
      $("#modal-export-spad").modal("show");
    }

    if (exportType === "commissions") {
      $("#modal-export-commission").modal("show");
    }

    if (exportType === "settled") {
      $("#modal-export-settled").modal("show");
    }

    if (exportType === "pymeService") {
      $("#modal-export-pyme-services").modal("show");
    }

    if (exportType === "corporateEvent") {
      $("#modal-export-corporate-event-inscriptions").modal("show");
    }

    window.history.replaceState({}, document.title, window.location.pathname);

    document.getElementById("btn-exportar-leads").addEventListener("click", async function () {
      const fechaDesde = document.getElementById("fecha-desde").value;
      const fechaHasta = document.getElementById("fecha-hasta").value;
      const origen = document.getElementById("origen").value;

      showLoadingButton("btn-exportar-leads", "btn-barymont-red");

      const leadsDataResponse = await fetchLeadsData(fechaDesde, fechaHasta, origen);

      if (leadsDataResponse.status === 0) {
        createToast("error", "❌ Error", "Se ha producido un error al realizar el proceso de exportación...", 5000);
      } else {
        let blob = new Blob([leadsDataResponse.exportData], { type: "text/csv;charset=utf-8;" });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "leads_" + new Date().toISOString().slice(0, -5) + ".csv";
        link.click();
        link.remove();
      }

      updateButtonLabel("btn-exportar-leads", "<i class='fas fa-file-export mr-2'></i>Exportar", "btn-barymont-red");
    });

    document.getElementById("btn-exportar-calendario").addEventListener("click", async function () {
      const fechaDesde = document.getElementById("fecha-desde-calendario").value ?? "";
      const fechaHasta = document.getElementById("fecha-hasta-calendario").value ?? "";

      showLoadingButton("btn-exportar-calendario", "btn-barymont-red");

      const calendarioDataResponse = await fetchCalendarioData(fechaDesde, fechaHasta);

      if (calendarioDataResponse.status === 0) {
        createToast("error", "❌ Error", "Error en la exportación", 5000);
      } else {
        let blob = new Blob([calendarioDataResponse.exportData], { type: "text/csv;charset=utf-8;" });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "calendario_" + new Date().toISOString().slice(0, -5) + ".csv";
        link.click();
        link.remove();
      }

      updateButtonLabel("btn-exportar-calendario", "<i class='fas fa-file-export mr-2'></i>Exportar", "btn-barymont-red");
    });

    document.getElementById("btn-exportar-formacion").addEventListener("click", async function () {
      const fechaDesde = document.getElementById("fecha-desde-formacion").value;
      const fechaHasta = document.getElementById("fecha-hasta-formacion").value;
      const idCurso = document.getElementById("curso-formacion").value;

      showLoadingButton("btn-exportar-formacion", "btn-barymont-red");

      const formacionDataResponse = await fetchFormacionData(fechaDesde, fechaHasta, idCurso);

      if (formacionDataResponse.status === 0) {
        createToast("error", "❌ Error", "Se ha producido un error al realizar el proceso de exportación...", 5000);
      } else {
        let blob = new Blob([formacionDataResponse.exportData], { type: "text/csv;charset=utf-8;" });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "formacion_" + new Date().toISOString().slice(0, -5) + ".csv";
        link.click();
        link.remove();
      }
      updateButtonLabel("btn-exportar-formacion", "<i class='fas fa-file-export mr-2'></i>Exportar", "btn-barymont-red");
    });

    document.getElementById("btn-exportar-goals").addEventListener("click", async function () {
      // get all periods select from multiple select
      const goalPeriodSelect = document.getElementById("goal-period-id");
      const goalPeriodOptions = goalPeriodSelect.options;
      const goalPeriodSelected = [];
      for (let i = 0; i < goalPeriodOptions.length; i++) {
        if (goalPeriodOptions[i].selected) {
          goalPeriodSelected.push(goalPeriodOptions[i].value);
        }
      }
      // get all users select from multiple select
      const goalUserSelect = document.getElementById("goal-user-id");
      const goalUserOptions = goalUserSelect.options;
      const goalUserSelected = [];
      for (let i = 0; i < goalUserOptions.length; i++) {
        if (goalUserOptions[i].selected) {
          goalUserSelected.push(goalUserOptions[i].value);
        }
      }

      // get all categories select from multiple select
      const goalCategorySelect = document.getElementById("goal-category-id");
      const goalCategoryOptions = goalCategorySelect.options;
      const goalCategorySelected = [];
      for (let i = 0; i < goalCategoryOptions.length; i++) {
        if (goalCategoryOptions[i].selected) {
          goalCategorySelected.push(goalCategoryOptions[i].value);
        }
      }

      showLoadingButton("btn-exportar-goals", "btn-barymont-red");

      const goalsDataResponse = await fetchGoalsData(goalPeriodSelected, goalUserSelected, goalCategorySelected);

      if (goalsDataResponse.status === 0) {
        createToast("error", "❌ Error", "Se ha producido un error al realizar el proceso de exportación...", 5000);
      } else {
        let blob = new Blob([goalsDataResponse.exportData], { type: "text/csv;charset=utf-8;" });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "metas_" + new Date().toISOString().slice(0, -5) + ".csv";
        link.click();
        link.remove();
      }
      updateButtonLabel("btn-exportar-goals", "<i class='fas fa-file-export mr-2'></i>Exportar", "btn-barymont-red");
    });

    document.getElementById("btn-exportar-achievement").addEventListener("click", async function () {
      showLoadingButton("btn-exportar-achievement", "btn-barymont-red");

      const fechaDesde = document.getElementById("fecha-desde-achievement").value;
      const fechaHasta = document.getElementById("fecha-hasta-achievement").value;
      const achievementType = Array.from(document.getElementById("achievement-type-selector").selectedOptions).map((option) => option.value);

      const achievementDataResponse = await fetchAchievementData(fechaDesde, fechaHasta, achievementType);

      if (achievementDataResponse.status === 0) {
        createToast("error", "❌ Error", "Se ha producido un error al realizar el proceso de exportación...", 5000);
      } else {
        let blob = new Blob([achievementDataResponse.exportData], { type: "text/csv;charset=utf-8;" });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "logros_" + new Date().toISOString().slice(0, -5) + ".csv";
        link.click();
        link.remove();
      }
      updateButtonLabel("btn-exportar-achievement", "<i class='fas fa-file-export mr-2'></i>Exportar", "btn-barymont-red");
    });

    document.getElementById("btn-exportar-users").addEventListener("click", async function () {
      showLoadingButton("btn-exportar-users", "btn-barymont-red");

      const usersDataResponse = await fetchUsersData();

      if (usersDataResponse.status === 0) {
        createToast("error", "❌ Error", "Se ha producido un error al realizar el proceso de exportación...", 5000);
      } else {
        let blob = new Blob([usersDataResponse.exportData], { type: "text/csv;charset=utf-8;" });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "usuarios_" + new Date().toISOString().slice(0, -5) + ".csv";
        link.click();
        link.remove();
      }
      updateButtonLabel("btn-exportar-users", "<i class='fas fa-file-export mr-2'></i>Exportar", "btn-barymont-red");
    });

    document.getElementById("btn-exportar-nifs").addEventListener("click", async function () {
      showLoadingButton("btn-exportar-nifs", "btn-barymont-red");

      const nifsDataResponse = await fetchNifsData();

      if (nifsDataResponse.status === 0) {
        createToast("error", "❌ Error", "Se ha producido un error al realizar el proceso de exportación...", 5000);
      } else {
        let blob = new Blob([nifsDataResponse.exportData], { type: "text/csv;charset=utf-8;" });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "nifs_" + new Date().toISOString().slice(0, -5) + ".csv";
        link.click();
        link.remove();
      }
      updateButtonLabel("btn-exportar-nifs", "<i class='fas fa-file-export mr-2'></i>Exportar", "btn-barymont-red");
    });

    document.getElementById("btn-exportar-financial-education-services").addEventListener("click", async function () {
      showLoadingButton("btn-exportar-financial-education-services", "btn-barymont-red");
      const fechaDesde = document.getElementById("fecha-desde-fes").value;
      const fechaHasta = document.getElementById("fecha-hasta-fes").value;

      const financialEducationServicesDataResponse = await fetchFinancialEducationServicesData(fechaDesde, fechaHasta);

      if (financialEducationServicesDataResponse.status === 0) {
        createToast("error", "❌ Error", "Se ha producido un error al realizar el proceso de exportación...", 5000);
      } else {
        let blob = new Blob([financialEducationServicesDataResponse.exportData], { type: "text/csv;charset=utf-8;" });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "servicios-de-educacion-financiera_" + new Date().toISOString().slice(0, -5) + ".csv";
        link.click();
        link.remove();
      }
      updateButtonLabel("btn-exportar-financial-education-services", "<i class='fas fa-file-export mr-2'></i>Exportar", "btn-barymont-red");
    });

    document.getElementById("btn-exportar-spad").addEventListener("click", async function () {
      showLoadingButton("btn-exportar-spad", "btn-barymont-red");
      const fechaDesde = document.getElementById("fecha-desde-spad").value;
      const fechaHasta = document.getElementById("fecha-hasta-spad").value;

      const spadDataResponse = await fetchSpadData(fechaDesde, fechaHasta);

      if (spadDataResponse.status === 0) {
        createToast("error", "❌ Error", "Se ha producido un error al realizar el proceso de exportación...", 5000);
      } else {
        let blob = new Blob([spadDataResponse.exportData], { type: "text/csv;charset=utf-8;" });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "servicios-spad_" + new Date().toISOString().slice(0, -5) + ".csv";
        link.click();
        link.remove();
      }
      updateButtonLabel("btn-exportar-spad", "<i class='fas fa-file-export mr-2'></i>Exportar", "btn-barymont-red");
    });

    document.getElementById("btn-exportar-commission").addEventListener("click", async function () {
      showLoadingButton("btn-exportar-commission", "btn-barymont-red");
      const fechaDesde = document.getElementById("fecha-desde-commissions").value;
      const fechaHasta = document.getElementById("fecha-hasta-commissions").value;
      const selectedServiceTypes = Array.from(document.getElementById("service-type-commissions").options)
        .filter((option) => option.selected)
        .map((option) => option.value);
      const isSettledCommission = document.getElementById("is-settled-commissions").value;
      const nifSelecteds = Array.from(document.getElementById("nif-commissions").options)
        .filter((option) => option.selected)
        .map((option) => option.value);

      const commissionDataResponse = await fetchCommissionData(fechaDesde, fechaHasta, selectedServiceTypes, isSettledCommission, nifSelecteds);

      if (commissionDataResponse.status === 0) {
        createToast("error", "❌ Error", "Se ha producido un error al realizar el proceso de exportación...", 5000);
      } else {
        let blob = new Blob([commissionDataResponse.exportData], { type: "text/csv;charset=utf-8;" });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "commissions_" + new Date().toISOString().slice(0, -5) + ".csv";
        link.click();
        link.remove();
      }
      updateButtonLabel("btn-exportar-commission", "<i class='fas fa-file-export mr-2'></i>Exportar", "btn-barymont-red");
    });

    document.getElementById("btn-exportar-settled").addEventListener("click", async function () {
      showLoadingButton("btn-exportar-settled", "btn-barymont-red");
      const fechaDesde = document.getElementById("fecha-desde-settled").value;
      const fechaHasta = document.getElementById("fecha-hasta-settled").value;

      const settledDataResponse = await fetchSettlementData(fechaDesde, fechaHasta);

      if (settledDataResponse.status === 0) {
        createToast("error", "❌ Error", "Se ha producido un error al realizar el proceso de exportación...", 5000);
      } else {
        let blob = new Blob([settledDataResponse.exportData], { type: "text/csv;charset=utf-8;" });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "settled_" + new Date().toISOString().slice(0, -5) + ".csv";
        link.click();
        link.remove();
      }
      updateButtonLabel("btn-exportar-settled", "<i class='fas fa-file-export mr-2'></i>Exportar", "btn-barymont-red");
    });

    document.getElementById("btn-export-pyme-services").addEventListener("click", async function () {
      showLoadingButton("btn-export-pyme-services", "btn-barymont-red");
      const fechaDesde = document.getElementById("fecha-desde-pyme-service").value;
      const fechaHasta = document.getElementById("fecha-hasta-pyme-service").value;

      const pymeServiceDataResponse = await fetchPymeServiceData(fechaDesde, fechaHasta);

      if (pymeServiceDataResponse.status === 0) {
        createToast("error", "❌ Error", "Se ha producido un error al realizar el proceso de exportación...", 5000);
      } else {
        let blob = new Blob([pymeServiceDataResponse.exportData], { type: "text/csv;charset=utf-8;" });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "servicios-pymes_" + new Date().toISOString().slice(0, -5) + ".csv";
        link.click();
        link.remove();
      }
      updateButtonLabel("btn-export-pyme-services", "<i class='fas fa-file-export mr-2'></i>Exportar", "btn-barymont-red");
    });

    document.getElementById("btn-export-cp-services").addEventListener("click", async function () {
      showLoadingButton("btn-export-cp-services", "btn-barymont-red");
      const fechaDesde = document.getElementById("fecha-desde-cp-service").value;
      const fechaHasta = document.getElementById("fecha-hasta-cp-service").value;

      const cpServiceDataResponse = await fetchCpServiceData(fechaDesde, fechaHasta);

      if (cpServiceDataResponse.status === 0) {
        createToast("error", "❌ Error", "Se ha producido un error al realizar el proceso de exportación...", 5000);
      } else {
        let blob = new Blob([cpServiceDataResponse.exportData], { type: "text/csv;charset=utf-8;" });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "servicios-cp_" + new Date().toISOString().slice(0, -5) + ".csv";
        link.click();
        link.remove();
      }
      updateButtonLabel("btn-export-cp-services", "<i class='fas fa-file-export mr-2'></i>Exportar", "btn-barymont-red");
    });

    document.getElementById("btn-export-inscriptions").addEventListener("click", async function () {
      showLoadingButton("btn-export-inscriptions", "btn-barymont-red");
      const corporateEventId = document.getElementById("corporate-events").value;

      if (!corporateEventId) {
        createToast("error", "❌ Error", "Debe seleccionar un evento corporativo para exportar las inscripciones.", 5000);
        updateButtonLabel("btn-export-inscriptions", "<i class='fas fa-file-export mr-2'></i>Exportar", "btn-barymont-red");
        return;
      }

      const eventInscriptionDataResponse = await fetchCorporateEventInscriptions(corporateEventId);

      if (eventInscriptionDataResponse.status === 0) {
        createToast("error", "❌ Error", "Se ha producido un error al realizar el proceso de exportación...", 5000);
      } else {
        let blob = new Blob([eventInscriptionDataResponse.exportData], { type: "text/csv;charset=utf-8;" });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "inscriptions_" + new Date().toISOString().slice(0, -5) + ".csv";
        link.click();
        link.remove();
      }
      updateButtonLabel("btn-export-inscriptions", "<i class='fas fa-file-export mr-2'></i>Exportar", "btn-barymont-red");
    });

    document.getElementById("btn-export-user-promoters-request").addEventListener("click", async function () {
      showLoadingButton("btn-export-user-promoters-request", "btn-barymont-red");

      const response = await fetchPromoterRequestsData();

      let blob = new Blob([response.exportData], { type: "text/csv;charset=utf-8;" });
      let link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "promoters_request_" + new Date().toISOString().slice(0, -5) + ".csv";
      link.click();
      link.remove();

      updateButtonLabel("btn-export-user-promoters-request", "<i class='fas fa-file-export mr-2'></i>Exportar", "btn-barymont-red");
    });

    document.getElementById("btn-export-lead-interest-detected").addEventListener("click", async function () {
      showLoadingButton("btn-export-lead-interest-detected", "btn-barymont-red");

      const response = await fetchLeadInterestDetectedData();

      if (response.status === 0) {
        createToast("error", "❌ Error", "Se ha producido un error al realizar el proceso de exportación...", 5000);
        updateButtonLabel("btn-export-lead-interest-detected", "<i class='fas fa-file-export mr-2'></i>Exportar", "btn-barymont-red");
        return;
      }

      let blob = new Blob([response.exportData], { type: "text/csv;charset=utf-8;" });
      let link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "leads_interest_report_" + new Date().toISOString().slice(0, -5) + ".csv";
      link.click();
      link.remove();

      updateButtonLabel("btn-export-lead-interest-detected", "<i class='fas fa-file-export mr-2'></i>Exportar", "btn-barymont-red");
    });
  }
}

export default {};
