"use strict";

import { showLoadingButton, updateButtonLabel, isValidUuid, showLoadingContainerById, removeLoadingContainerById } from "../shared/shared.js";

var FILTER_STATE_LOCAL_STORAGE_KEY = "CORPORATE_EVENT_MANAGEMENT_FILTER_STATE_";
var FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME = 1000 * 60 * 20;

const modalPhase = "modal-corporate-event-phase";
const saveBtnCorporateEventPhase = "modal-corporate-event-phase-save-button";
const saveBtnCorporateEvent = "btn-corporate-event-save";
const corporateEventIdInput = "corporate-event-id";

const containerCorporateEventData = "corporate-event-info-data";
const containerCorporateEventPhasesData = "corporate-event-phases";

function getStateOfCustomFilters() {
  return {
    startDate: $("#corporate-event-management-start-date").val(),
    finishDate: $("#corporate-event-management-finish-date").val(),
  };
}

function recreateStateOfCustomFilters(localStorageData) {
  if (localStorageData.customFilter === undefined) {
    return false;
  }

  $("#corporate-event-management-start-date").val(localStorageData.customFilter.startDate);
  $("#corporate-event-management-finish-date").val(localStorageData.customFilter.finishDate);
}

function resetearFiltros() {
  $("#corporate-event-management-start-date").val("");
  $("#corporate-event-management-finish-date").val("");

  $("#corporate-event-management-table").DataTable().search("").draw();
  $("#corporate-event-management-table").DataTable().ajax.reload();
}

async function loadModalPhaseData(corporateEventPhaseId) {
  let modalPhaseTitle = document.querySelector("#" + modalPhase + " .modal-title");
  let modalPhaseBtn = document.getElementById(saveBtnCorporateEventPhase);

  modalPhaseTitle.innerHTML = "";
  modalPhaseBtn.innerHTML = "";

  modalPhaseTitle.innerHTML = "Editar Fase";
  if (AppGbSession.checkUserHasPermission("CorporateEvent:CorporateEventUpdateAccessChecker")) {
    modalPhaseBtn.innerHTML = "<i class='fas fa-save pr-3'></i> Actualizar";
  } else {
    modalPhaseBtn.classList.add("d-none");
  }

  try {
    await loadModalPhaseDataEdit(corporateEventPhaseId);

    let modalPhaseIdInput = document.getElementById(modalPhase + "-id");
    modalPhaseIdInput.value = corporateEventPhaseId;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function loadModalPhaseDataEdit(corporateEventPhaseId) {
  let responsePhaseData = await fetchCorporateEventPhaseData(corporateEventPhaseId);

  if (responsePhaseData.success === false) {
    throw new Error("Error al cargar la información de la fase.");
  }

  printModalPhaseData(responsePhaseData.corporateEventPhase);
}

function printModalPhaseData(corporatePhase) {
  let modalPhaseBody = document.getElementById(modalPhase + "-body");
  let phaseStartDate = formatDateToInputDateTime(corporatePhase.startInscriptionDate);
  let phaseFinishDate = formatDateToInputDateTime(corporatePhase.finishInscriptionDate);

  let readonly = AppGbSession.checkUserHasPermission("CorporateEvent:CorporateEventUpdateAccessChecker") ? "" : "readonly";

  modalPhaseBody.innerHTML = "";

  modalPhaseBody.insertAdjacentHTML(
    "beforeend",
    `
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="input-group mb-2 mr-sm-2">
              <div class="input-group-prepend">
                <div class="input-group-text bg-light" data-toggle="tooltip" title="Tipo de Fase"><i class="fas fa-user-tie pr-2"></i> Tipo de Fase</div>
              </div>

              <input id="corporate-event-phase-type" data-phase-value="${corporatePhase.corporateEventPhaseType}" type="text" class="form-control" value="${corporatePhase.corporateEventPhaseTypeLabel}" readonly>
            </div>
          </div>

          <div class="col-6">
            <div class="input-group mb-2 mr-sm-2">
              <div class="input-group-prepend">
                <div class="input-group-text bg-light" data-toggle="tooltip" title="Inicio inscripciones"><i class="far fa-calendar-alt pr-2"></i> Inicio inscripciones</div>
              </div>

              <input id="corporate-event-phase-start-date" type="datetime-local" class="form-control" value="${phaseStartDate}" ${readonly}>
            </div>
          </div>
          <div class="col-6">
            <div class="input-group mb-2 mr-sm-2">
              <div class="input-group-prepend">
                <div class="input-group-text bg-light" data-toggle="tooltip" title="Fin inscripciones"><i class="far fa-calendar-alt pr-2"></i> Fin inscripciones</div>
              </div>

              <input id="corporate-event-phase-finish-date" type="datetime-local" class="form-control" value="${phaseFinishDate}" ${readonly}>
            </div>
          </div>

          <div class="col-12">
            <div class="form-group">
              <label for="corporate-event-email-es" class="form-label font-weight-bold">Email en castellano</label>
              <textarea id="corporate-event-email-es" class="tinymce-editor" required>${corporatePhase.inscriptionEmailHtmlEs}</textarea>
            </div>
          </div>

          <div class="col-12">
            <div class="form-group">
              <label for="corporate-event-email-pt" class="form-label font-weight-bold">Email en portugués</label>
              <textarea id="corporate-event-email-pt" class="tinymce-editor" required>${corporatePhase.inscriptionEmailHtmlPt}</textarea>
            </div>
          </div>
        </div>
      </div>
    `
  );
}

async function loadCorporateEventData(corporateEventId = null) {
  if (document.getElementById("load-" + containerCorporateEventData) === null) {
    showLoadingContainerById(containerCorporateEventData);
  }

  let responseTicketMode = await fetchTicketMode();

  if (responseTicketMode.success === false) {
    window.location.href = "/herramientas/corporate-events-management";
    return;
  }

  if (corporateEventId === null) {
    printCorporateEventData(responseTicketMode.ticketModes);
    removeLoadingContainerById(containerCorporateEventData);
    return;
  }

  let response = await fetchCorporateEventData(corporateEventId);

  if (response.success === false) {
    window.location.href = "/herramientas/corporate-events-management";
    return;
  }

  printCorporateEventData(responseTicketMode.ticketModes, response.corporateEvent);

  if (document.getElementById("load-" + containerCorporateEventData) !== null) {
    removeLoadingContainerById(containerCorporateEventData);
  }
}

function printCorporateEventData(ticketModes, corporateEvent = null) {
  const corporateEventInfo = document.getElementById(containerCorporateEventData);

  const corporateEventName = corporateEvent !== null ? corporateEvent.name : "";
  const corporateEventStartDate = corporateEvent !== null ? formatDateToInputDateTime(corporateEvent.startDate) : "";
  const corporateEventFinishDate = corporateEvent !== null ? formatDateToInputDateTime(corporateEvent.finishDate) : "";
  const corporateEventTicketMode = corporateEvent !== null ? corporateEvent.corporateEventMode : "";
  const corporateEventRecheckTime = corporateEvent !== null ? corporateEvent.reCheckInTime : "";
  const corporateEventHubspotAccessCode = corporateEvent !== null ? corporateEvent.hubspotPropertyAccessCode : "";
  const corporateEventHubspotUrlTicket = corporateEvent !== null ? corporateEvent.hubspotPropertyUrlTicket : "";
  const corporateEventUrlLogo = corporateEvent !== null ? corporateEvent.corporateEventLogoPath : "";
  const corporateEventUrlTicketDesign = corporateEvent !== null ? corporateEvent.ticketDesignImagePath : "";

  let readonly = "";
  if (corporateEvent === null && !AppGbSession.checkUserHasPermission("CorporateEvent:CorporateEventCreateAccessChecker")) {
    readonly = "readonly";
  }

  if (corporateEvent !== null && !AppGbSession.checkUserHasPermission("CorporateEvent:CorporateEventUpdateAccessChecker")) {
    readonly = "readonly";
  }

  corporateEventInfo.innerHTML = "";

  corporateEventInfo.insertAdjacentHTML(
    "beforeend",
    `
      <div class="col-12 my-4">
          <div class="input-group mb-3">
              <div class="input-group-prepend">
                  <span class="input-group-text bg-light"><i class="fas fa-heading fa-sm pr-2"></i></i> Nombre</span>
              </div>
              <input id="corporate-event-name" type="text" class="form-control" value="${corporateEventName}" required ${readonly}>
          </div>
      </div>

      <div class="col-6">
          <div class="input-group mb-3">
              <div class="input-group-prepend">
                  <span class="input-group-text bg-light"><i class="far fa-calendar-alt fa-sm pr-2"></i> Fecha de Inicio</span>
              </div>
              <input id="corporate-event-start-date" type="datetime-local" class="form-control" value="${corporateEventStartDate}" required ${readonly}>
          </div>
      </div>

      <div class="col-6">
          <div class="input-group mb-3">
              <div class="input-group-prepend">
                  <span class="input-group-text bg-light"><i class="far fa-calendar-alt fa-sm pr-2"></i> Fecha de Fin</span>
              </div>
              <input id="corporate-event-finish-date" type="datetime-local" class="form-control" value="${corporateEventFinishDate}" required ${readonly}>
          </div>
      </div>

      <div class="col-md-6 my-4">
          <div class="input-group mb-2 mr-sm-2">
              <div class="input-group-prepend">
                  <div class="input-group-text bg-light" data-toggle="tooltip" title="Formato del ticket"><i class="fas fa-th-list pr-2"></i> Formato del ticket</div>
              </div>
              <select id="corporate-event-ticket-mode" class="form-control selectpicker show-tick input-lg modal-selectpicker" title="Selecciona un formato..." required ${readonly !== "" ? 'disabled="disabled"' : ""}>
                  ${ticketModes.map((ticketMode) => `<option value="${ticketMode.value}" ${ticketMode.value === corporateEventTicketMode ? "selected" : ""}>${ticketMode.label}</option>`).join("")}
              </select>
          </div>
      </div>

      <div class="col-6 my-4">
          <div class="input-group mb-3">
              <div class="input-group-prepend">
                  <span class="input-group-text bg-light"><i class="far fa-clock fa-sm pr-2"></i> Tiempo mín. entre accesos (minutos)</span>
              </div>
              <input id="corporate-event-recheck-time" type="number" class="form-control" value="${corporateEventRecheckTime}" required ${readonly}>
          </div>
      </div>

      <div class="col-12">
          <div class="input-group mb-3">
              <div class="input-group-prepend">
                  <span class="input-group-text bg-light"><i class="fa-brands fa-hubspot fa-sm pr-2"></i></i> Propiedad HubSpot Código de acceso</span>
              </div>
              <input id="corporate-event-hubspot-access-code" type="text" class="form-control" value="${corporateEventHubspotAccessCode}" required ${readonly}>
          </div>
      </div>

      <div class="col-12 my-4">
          <div class="input-group mb-3">
              <div class="input-group-prepend">
                  <span class="input-group-text bg-light"><i class="fa-brands fa-hubspot fa-sm pr-2"></i></i> Propiedad HubSpot URL Ticket</span>
              </div>
              <input id="corporate-event-hubspot-url-ticket" type="text" class="form-control" value="${corporateEventHubspotUrlTicket}" required ${readonly}>
          </div>
      </div>

      <div class="col-12">
          <div class="input-group mb-3">
              <div class="input-group-prepend">
                  <span class="input-group-text bg-light"><i class="fa-solid fa-link fa-sm pr-2"></i></i> URL del logo del evento</span>
              </div>
              <input id="corporate-event-url-logo" type="text" class="form-control" value="${corporateEventUrlLogo}" required ${readonly}>
          </div>
      </div>

      <div class="col-12 my-4">
          <div class="input-group mb-3">
              <div class="input-group-prepend">
                  <span class="input-group-text bg-light"><i class="fa-solid fa-link fa-sm pr-2"></i></i> URL del diseño del ticket</span>
              </div>
              <input id="corporate-event-url-ticket-design" type="text" class="form-control" value="${corporateEventUrlTicketDesign}" required ${readonly}>
          </div>
      </div>`
  );

  $(".selectpicker").selectpicker("refresh");
}

async function loadCorporateEventPhasesData(corporateEventId = null) {
  if (corporateEventId === null) {
    printCorporateEventPhasesData();
    removeLoadingContainerById(containerCorporateEventPhasesData);
    return;
  }

  if (document.getElementById("load-" + containerCorporateEventPhasesData) === null) {
    showLoadingContainerById(containerCorporateEventPhasesData);
  }

  let response = await fetchCorporateEventPhasesData(corporateEventId);

  if (response.success === false) {
    window.location.href = "/herramientas/corporate-events-management";
    return;
  }

  let responsePhaseTypesData = await fetchPhaseTypes();

  if (responsePhaseTypesData.success === false) {
    window.location.href = "/herramientas/corporate-events-management";
    return;
  }

  printCorporateEventPhasesData(response.corporateEventPhases);

  document.querySelectorAll("[data-action='edit-corporate-event-phase']").forEach((element) => {
    element.addEventListener("click", async () => {
      try {
        await loadModalPhaseData(element.dataset.corporateEventPhaseId);
        $(".selectpicker").selectpicker("refresh");

        let readonly = AppGbSession.checkUserHasPermission("CorporateEvent:CorporateEventUpdateAccessChecker") ? 0 : 1;

        $(".tinymce-editor").each(function () {
          tinymce.remove("#" + this.id);

          tinymce.init({
            selector: "#" + this.id,
            height: 200,
            menubar: false,
            statusbar: false,
            entity_encoding: "raw",
            toolbar: "bold underline italic",
            readonly: readonly,
          });
        });

        $("#" + modalPhase).modal("show");
      } catch (error) {
        createToast("error", "Error ❌", "No se pudo cargar la información correctamente, intentelo de nuevo más tarde.");
      }
    });
  });

  if (document.getElementById("load-" + containerCorporateEventPhasesData) !== null) {
    removeLoadingContainerById(containerCorporateEventPhasesData);
  }
}

function printCorporateEventPhasesData(corporateEventPhases = null) {
  const corporateEventPhasesData = document.getElementById(containerCorporateEventPhasesData);

  if (corporateEventPhases === null) {
    corporateEventPhasesData.insertAdjacentHTML(
      "beforeend",
      `
        <div class="col-12 mt-1 py-0 px-3">
            <p class="h6 text-dark font-weight-bolder">Es necesario introducir la información del evento y guardar para poder visualizar las fases.</p>
        </div>
      `
    );
    return;
  }

  corporateEventPhasesData.innerHTML = "";

  corporateEventPhases.forEach((corporateEventPhase) => {
    corporateEventPhasesData.insertAdjacentHTML(
      "beforeend",
      `
        <div class="col-12 mt-1 py-0 px-3">
            <div data-action="edit-corporate-event-phase" data-corporate-event-phase-id="${corporateEventPhase.corporateEventPhaseId}" class="course-card mx-md-2 mx-0 my-2 py-2">
                <div class="container-fluid">
                    <div class="row py-md-3 py-0 px-md-3 px-2 align-items-center">
                        <div class="col-6">
                            <span class="h6 mb-0 card-title text-dark font-weight-bolder">
                              ${corporateEventPhase.corporateEventPhaseTypeLabel}
                            </span>
                        </div>
                        <div class="col-6">
                            <span class="h6 mb-0 text-dark">
                                 ${corporateEventPhase.startInscriptionDate} <i class="fa-solid fa-right-long mx-3"></i> ${corporateEventPhase.finishInscriptionDate}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      `
    );
  });
}

async function saveCorporateEvent(corporateEventId = null) {
  let corporateEventName = document.getElementById("corporate-event-name").value;
  let corporateEventStartDate = document.getElementById("corporate-event-start-date").value;
  let corporateEventFinishDate = document.getElementById("corporate-event-finish-date").value;
  let corporateEventTicketMode = document.getElementById("corporate-event-ticket-mode").value;
  let corporateEventRecheckTime = document.getElementById("corporate-event-recheck-time").value;
  let corporateEventHubspotAccessCode = document.getElementById("corporate-event-hubspot-access-code").value;
  let corporateEventHubspotUrlTicket = document.getElementById("corporate-event-hubspot-url-ticket").value;
  let corporateEventUrlLogo = document.getElementById("corporate-event-url-logo").value;
  let corporateEventUrlTicketDesign = document.getElementById("corporate-event-url-ticket-design").value;

  const response = await fetchSaveCorporateEventData(corporateEventId, corporateEventName, corporateEventStartDate, corporateEventFinishDate, corporateEventTicketMode, corporateEventRecheckTime, corporateEventHubspotAccessCode, corporateEventHubspotUrlTicket, corporateEventUrlLogo, corporateEventUrlTicketDesign);

  if (response.success === false) {
    createToast("error", "Error ❌", "No se pudo guardar la información correctamente, intentelo de nuevo más tarde.");
  } else {
    createToast("success", "Éxito ✅", "La información ha sido guardada correctamente.");
    if (response.action === "created") {
      window.location.href = "/herramientas/corporate-events-management-panel/" + response.corporateEventId;
      return;
    }

    await loadCorporateEventData(response.corporateEventId);
    await loadCorporateEventPhasesData(response.corporateEventId);
  }
}

async function saveCorporateEventPhase() {
  let corporateEventPhaseId = document.getElementById(modalPhase + "-id").value !== "" ? document.getElementById(modalPhase + "-id").value : null;
  let corporateEventId = document.getElementById(corporateEventIdInput).value;
  let corporateEventPhaseType = document.getElementById("corporate-event-phase-type").getAttribute("data-phase-value");

  if (corporateEventPhaseType === "") {
    createToast("error", "Error ❌", "Debe seleccionar un tipo de fase.");
    return;
  }

  let corporateEventPhaseStartDate = document.getElementById("corporate-event-phase-start-date").value;
  let corporateEventPhaseFinishDate = document.getElementById("corporate-event-phase-finish-date").value;

  if (corporateEventPhaseStartDate === "" || corporateEventPhaseFinishDate === "") {
    createToast("error", "Error ❌", "Debe seleccionar una fecha de inicio y fin para las inscripciones de la fase.");
    return;
  }

  if (new Date(corporateEventPhaseStartDate) > new Date(corporateEventPhaseFinishDate)) {
    createToast("error", "Error ❌", "La fecha de inicio no puede ser mayor a la fecha de fin.");
    return;
  }

  let corporateEventPhaseInscriptionEmailEs = tinymce.get("corporate-event-email-es").getContent();
  let corporateEventPhaseInscriptionEmailPt = tinymce.get("corporate-event-email-pt").getContent();

  const response = await fetchSaveCorporateEventPhaseData(corporateEventId, corporateEventPhaseType, corporateEventPhaseStartDate, corporateEventPhaseFinishDate, corporateEventPhaseInscriptionEmailEs, corporateEventPhaseInscriptionEmailPt, corporateEventPhaseId);

  if (response.success === false) {
    createToast("error", "Error ❌", "No se pudo guardar la información correctamente, intentelo de nuevo más tarde.");
    return;
  }

  createToast("success", "Éxito ✅", "La información ha sido guardada correctamente.");

  await loadCorporateEventPhasesData(corporateEventId);

  $("#" + modalPhase).modal("hide");
}

async function fetchCorporateEventData(corporateEventId) {
  let formData = new FormData();
  formData.append("corporateEventId", corporateEventId);

  return await fetch("/herramientas/corporate-events-management/ajax/getCorporateEventData", {
    method: "POST",
    body: formData,
  }).then((response) => response.json());
}

async function fetchCorporateEventPhasesData(corporateEventId) {
  let formData = new FormData();
  formData.append("corporateEventId", corporateEventId);

  return await fetch("/herramientas/corporate-events-management/ajax/getCorporateEventPhasesDataByCorporateEventId", {
    method: "POST",
    body: formData,
  }).then((response) => response.json());
}

async function fetchCorporateEventPhaseData(corporateEventPhaseId) {
  let formData = new FormData();
  formData.append("corporateEventPhaseId", corporateEventPhaseId);

  return await fetch("/herramientas/corporate-events-management/ajax/getCorporateEventPhaseData", {
    method: "POST",
    body: formData,
  }).then((response) => response.json());
}

async function fetchPhaseTypes() {
  return await fetch("/herramientas/corporate-events-management/ajax/getCorporateEventPhaseTypes", {
    method: "POST",
  }).then((response) => response.json());
}

async function fetchTicketMode() {
  return await fetch("/herramientas/corporate-events-management/ajax/getTicketModes", {
    method: "POST",
  }).then((response) => response.json());
}

async function fetchSaveCorporateEventData(corporateEventId, corporateEventName, corporateEventStartDate, corporateEventFinishDate, corporateEventTicketMode, corporateEventRecheckTime, corporateEventHubspotAccessCode, corporateEventHubspotUrlTicket, corporateEventUrlLogo, corporateEventUrlTicketDesign) {
  let formData = new FormData();
  formData.append("corporateEventId", corporateEventId);
  formData.append("corporateEventName", corporateEventName);
  formData.append("corporateEventStartDate", corporateEventStartDate);
  formData.append("corporateEventFinishDate", corporateEventFinishDate);
  formData.append("corporateEventTicketMode", corporateEventTicketMode);
  formData.append("corporateEventRecheckTime", corporateEventRecheckTime);
  formData.append("corporateEventHubspotAccessCode", corporateEventHubspotAccessCode);
  formData.append("corporateEventHubspotUrlTicket", corporateEventHubspotUrlTicket);
  formData.append("corporateEventUrlLogo", corporateEventUrlLogo);
  formData.append("corporateEventUrlTicketDesign", corporateEventUrlTicketDesign);

  return await fetch("/herramientas/corporate-events-management/ajax/saveCorporateEventData", {
    method: "POST",
    body: formData,
  }).then((response) => response.json());
}

async function fetchSaveCorporateEventPhaseData(corporateEventId, corporateEventPhaseType, corporateEventPhaseStartDate, corporateEventPhaseFinishDate, corporateEventPhaseInscriptionEmailEs, corporateEventPhaseInscriptionEmailPt, corporateEventPhaseId) {
  let formData = new FormData();

  formData.append("corporateEventId", corporateEventId);
  formData.append("corporateEventPhaseType", corporateEventPhaseType);
  formData.append("corporateEventPhaseStartDate", corporateEventPhaseStartDate);
  formData.append("corporateEventPhaseFinishDate", corporateEventPhaseFinishDate);
  formData.append("corporateEventPhaseInscriptionEmailEs", corporateEventPhaseInscriptionEmailEs);
  formData.append("corporateEventPhaseInscriptionEmailPt", corporateEventPhaseInscriptionEmailPt);
  formData.append("corporateEventPhaseId", corporateEventPhaseId);

  return await fetch("/herramientas/corporate-events-management/ajax/saveCorporateEventPhaseData", {
    method: "POST",
    body: formData,
  }).then((response) => response.json());
}

function formatDateToInputDateTime(originalDate) {
  const [datePart, timePart] = originalDate.split(" ");
  const [day, month, year] = datePart.split("-");

  return `${year}-${month}-${day}T${timePart}`;
}

if (window) {
  window.addEventListener("load", async () => {
    // Creación y Modificación de Eventos Corporativos
    if (window.location.pathname.includes("/herramientas/corporate-events-management-panel")) {
      let corporateEventId = document.getElementById(corporateEventIdInput).value;

      document.getElementById(saveBtnCorporateEvent)?.addEventListener("click", async () => {
        showLoadingButton(saveBtnCorporateEvent);

        let corporateEventId = document.getElementById(corporateEventIdInput).value;

        if (corporateEventId === "") {
          await saveCorporateEvent();
        }

        if (isValidUuid(corporateEventId)) {
          await saveCorporateEvent(corporateEventId);
        }

        updateButtonLabel(saveBtnCorporateEvent, '<i class="fas fa-save pr-3"></i> Guardar');
      });

      document.getElementById(saveBtnCorporateEventPhase)?.addEventListener("click", async () => {
        showLoadingButton(saveBtnCorporateEventPhase);

        await saveCorporateEventPhase();

        updateButtonLabel(saveBtnCorporateEventPhase, '<i class="fas fa-save pr-3"></i> Guardar');
      });

      if (corporateEventId === "") {
        await loadCorporateEventData();
        await loadCorporateEventPhasesData();
        return;
      }

      if (!isValidUuid(corporateEventId)) {
        window.location.href = "/herramientas/corporate-events-management";
        return;
      }

      await loadCorporateEventData(corporateEventId);
      await loadCorporateEventPhasesData(corporateEventId);
    }

    // Listado de Eventos Corporativos
    if (window.location.pathname === "/herramientas/corporate-events-management") {
      $("#corporate-event-management-table").DataTable({
        language: {
          sProcessing: "Procesando Datos...",
          sLengthMenu: "Mostrar _MENU_ eventos",
          sZeroRecords: "No se encontraron eventos coincidentes con el criterio de búsqueda.",
          sEmptyTable: "Ningún Evento coincidente con el criterio de búsqueda.",
          sInfo: "Mostrando eventos del _START_ al _END_ de un total de _TOTAL_ eventos",
          sInfoEmpty: "Mostrando Eventos del 0 al 0 de un total de 0 Eventos",
          sInfoFiltered: "(Filtrados de un total de _MAX_ Eventos)",
          sInfoPostFix: "",
          sSearch: "<div class='input-group-prepend'><div class='input-group-text'><i class='fas fa-search'></i></div></div>",
          searchPlaceholder: "Ingrese su búsqueda...",
          sUrl: "",
          sInfoThousands: ",",
          sLoadingRecords: "Cargando Datos...",
          oPaginate: {
            sFirst: "Primera",
            sLast: "Última",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
          oAria: {
            sSortAscending: ": Activar para ordenar la columna de manera ascendente",
            sSortDescending: ": Activar para ordenar la columna de manera descendente",
          },
          buttons: {
            copySuccess: {
              1: "Copiado 1 Eventos",
              _: "Copiados %d Eventos al Portapapeles",
            },
            copyTitle: "Eventos Copiados al Portapapeles",
          },
          select: {
            rows: "%d Eventos seleccionados",
          },
        },
        pagingType: "input",
        columnDefs: [
          {
            targets: [1],
            searchable: true,
            orderable: true,
          },
          {
            targets: [0],
            searchable: true,
            orderable: false,
            visible: false,
          },
          {
            targets: [2, 3],
            searchable: false,
            orderable: true,
          },
          {
            targets: [4],
            searchable: false,
            orderable: false,
            width: "10%",
          },
          {
            targets: [1, 2, 3, 4],
            className: "dt-center",
          },
        ],
        order: [[2, "desc"]],
        processing: true,
        serverSide: true,
        scrollX: true,
        dom: '<"top"fl>Brt<"bottom"ip><"clear">',
        buttons: [
          {
            extend: "colvis",
            text: "Ver más columnas",
          },
          {
            extend: "collection",
            text: "Herramientas",
            buttons: [
              {
                extend: "copyHtml5",
                text: "Copiar",
              },
              {
                extend: "print",
                text: "Imprimir",
              },
            ],
            fade: true,
          },
          {
            text: '<i class="fas fa-eraser mr-2"></i> Limpiar filtros',
            className: "btn btn-barymont-black my-2 my-md-0",
            action: function () {
              resetearFiltros();
            },
            init: function (api, node) {
              $(node).removeClass("dt-button");
            },
          },
          {
            text: '<i class="fas fa-plus mr-2"></i> Crear evento',
            className: "btn btn-barymont-red btn-create-corporate-event-table",
            action: function () {},
            init: function (api, node) {
              if (AppGbSession.checkUserHasPermission("CorporateEvent:CorporateEventCreateAccessChecker")) {
                $(node).removeClass("dt-button");
              } else {
                $(node).hide();
              }
            },
          },
        ],
        pageLength: 15,
        lengthMenu: [
          [15, 30, 50, 100],
          [15, 30, 50, 100],
        ],
        select: false,
        keys: true,
        searchHighlight: true,
        ajax: {
          url: "/herramientas/corporate-events-management/listprocessing",
          type: "post",
          data: function (d) {
            const checkCorporateEventStartDateFilter = $("#corporate-event-management-start-date").val();
            const checkCorporateEventFinishDateFilter = $("#corporate-event-management-finish-date").val();

            d.startDate = checkCorporateEventStartDateFilter != "" ? checkCorporateEventStartDateFilter : "";
            d.finishDate = checkCorporateEventFinishDateFilter != "" ? checkCorporateEventFinishDateFilter : "";
          },
          error: function (e) {
            if (e.status === 401) {
              createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
              setTimeout(function () {
                location.reload();
              }, 5000);
            } else {
              createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
            }
          },
        },
        fnDrawCallback: function () {
          $('[data-toggle="tooltip"]').tooltip();
        },
        stateSave: true,
        stateSaveCallback: function (settings, data) {
          let storageKey = `${FILTER_STATE_LOCAL_STORAGE_KEY}${settings.sInstance}_${getCookieValue("user_id")}`;

          data.customFilter = getStateOfCustomFilters();

          localStorage.setItem(storageKey, JSON.stringify(data));
        },
        stateLoadCallback: function (settings) {
          let storageKey = `${FILTER_STATE_LOCAL_STORAGE_KEY}${settings.sInstance}_${getCookieValue("user_id")}`;

          if (localStorage.getItem(storageKey) === null) {
            return false;
          }

          let localStorageData = JSON.parse(localStorage.getItem(storageKey));

          if (localStorageData.time < new Date().getTime() - FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME) {
            return false;
          }

          recreateStateOfCustomFilters(localStorageData);

          return localStorageData;
        },
      });

      document.querySelector(".btn-create-corporate-event-table")?.addEventListener("click", () => {
        window.location.href = "/herramientas/corporate-events-management-panel";
      });

      document.querySelectorAll(".triggerDatatableUpdateCorporateEventManagement").forEach((element) => {
        element.addEventListener("change", () => {
          $("#corporate-event-management-table").DataTable().ajax.reload();
        });
      });
    }
  });
}
