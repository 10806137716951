"use strict";

async function showLeadLegalDocuments(event, idLead, legalDocumentRefKey) {
  event.preventDefault();

  await fetch(`/api/leads/${idLead}/legal-documents/${legalDocumentRefKey}`, {
    method: "GET",
  })
    .then(async (response) => {
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Ha ocurrido un error");
      }
      return response.json();
    })
    .then((data) => {
      const leadLegalDocumentsContainer = document.getElementById("LeadLegalDocuments");
      leadLegalDocumentsContainer.innerHTML = "";

      data.forEach((legaldocument) => {
        renderLegalTextContent(legaldocument.name, legaldocument.version, legaldocument.isTheMostRecentVersion, legaldocument.obtainMethod, legaldocument.createdAt, legaldocument.htmlContent);
      });

      $("#modal-documents-versions").modal("show");
    })
    .catch((error) => {
      createToast("error", "Error", `No se han podido cargar los documentos firmados por el lead. ${error.message}`, 5000);
    });
}

function renderLegalTextContent(legalDocumentName, version, isTheMostRecentVersion, obtainMethod, signatureDate, contentLegalDocument) {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = contentLegalDocument;

  const hrElements = tempDiv.querySelectorAll("hr");
  hrElements.forEach((hrElement) => {
    hrElement.parentNode.removeChild(hrElement);
  });

  let checkboxElements = tempDiv.querySelectorAll("input[type=checkbox]");
  checkboxElements.forEach((checkboxElement) => {
    checkboxElement.setAttribute("disabled", "");
    checkboxElement.setAttribute("checked", "");
  });

  const contentWithDisabledAndCheckedRadios = tempDiv.innerHTML;

  const htmlContent = `<div class="card-header accordion-toggle collapsed" id="legal-document-heading-${version}" data-toggle="collapse" data-target="#legal-document-collapse-${version}" aria-expanded="false" aria-controls="legal-document-collapse-${version}">
                          <span class="h4 mb-0" style="display: flex; align-items: center; justify-content: space-between;">
                            <span>
                              ${legalDocumentName}
                            </span>
                            <span class='badge badge-light text-dark ml-2'>
                              ${obtainMethod === "DIGITAL SIGNATURE" ? "Firmado" : "Aceptado"} el ${signatureDate}
                            </span>
                            <span>
                              <span class='badge badge-dark text-white ml-2'>v.${version}</span>
                              ${isTheMostRecentVersion === true ? "<span class='badge badge-success text-white ml-2'>Actual</span>" : "<span class='badge badge-secondary text-white ml-2'>Anterior</span>"}
                            </span>
                            <span><i class="fas fa-eye"></i></span>
                          </span>
                        </div>

                        <div id="legal-document-collapse-${version}" class="collapse" aria-labelledby="heading${version}" data-parent="#LeadLegalDocuments">
                            <div class="card-body">
                              ${contentWithDisabledAndCheckedRadios}
                            </div>
                        </div>`;

  const leadDocumentsContainer = document.getElementById("LeadLegalDocuments");
  leadDocumentsContainer.insertAdjacentHTML("beforeend", htmlContent);
}

function setLoadingUiEffect(enableLoading) {
  const body = document.querySelector("body");

  if (enableLoading) {
    body.classList.add("loading");
  } else {
    body.classList.remove("loading");
  }
}

function sendLeadLegalDocumentAcceptance(idLead, legalDocumentRefKey) {
  bootbox.confirm({
    title: "Enviar " + legalDocumentRefKey,
    message: "¿Estás seguro de que quieres enviar la aceptación del documento legal " + legalDocumentRefKey + "?",
    centerVertical: true,
    buttons: {
      cancel: {
        className: "btn-barymont-black",
        label: '<i class="fa fa-times"></i> Cancelar',
      },
      confirm: {
        className: "btn-barymont-red",
        label: '<i class="fa fa-check"></i> Enviar',
      },
    },
    callback: function (result) {
      if (result === true) {

        // eslint-disable-next-line no-inner-declarations
        async function run() {
          setLoadingUiEffect(true);

          await fetch(`/api/leads/${idLead}/legal-documents/${legalDocumentRefKey}/notify`, {
            method: "POST",
          })
            .then(async (response) => {
              if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || "Ha ocurrido un error");
              }
              return response.json();
            })
            .then(() => {
              createToast("success", legalDocumentRefKey + " enviado!", "El documento legal <strong>" + legalDocumentRefKey + "</strong> ha sido enviado correctamente al Lead. Recuerda que el correo electrónico en ocasiones puede llegar a la bandeja de correo no deseado / spam.", 30000);
            })
            .catch(() => {
              createToast("error", "Error al enviar " + legalDocumentRefKey, "Ha ocurrido un error de sistema al enviar del documento legal <strong>" + legalDocumentRefKey + "</strong>, intentelo de nuevo en unos minutos, si el error continua contacte con soporte.", 30000);
            });
          setLoadingUiEffect(false);
        }

        run();
      }
    },
  });
}

if (window) {
  window.addEventListener("load", function () {
    // Check for /herramientas/leads/{leadId}
    if (/^\/herramientas\/leads\/[a-f0-9-]{36}\/?$/.test(window.location.pathname)) {
      document.querySelectorAll("[data-action-show-legal-documents]").forEach((showLegalDocumentsButton) => {
        showLegalDocumentsButton.addEventListener("click", function (event) {
          showLeadLegalDocuments(event, showLegalDocumentsButton.dataset.idLead, showLegalDocumentsButton.dataset.legalDocumentRefKey);
        });
      });

      document.querySelectorAll("[data-action-send-legal-document-acceptance]").forEach((sendLegalDocumentAcceptanceButton) => {
        sendLegalDocumentAcceptanceButton.addEventListener("click", function () {
          sendLeadLegalDocumentAcceptance(sendLegalDocumentAcceptanceButton.dataset.idLead, this.dataset.legalDocumentRefKey);
        });
      });
    }
  });
}
