"use strict";

export function isValidUuid(uuid) {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
}

export function isValidEmail(email) {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return emailRegex.test(email);
}

export function isValidPhone(phone) {
  const phoneRegex = /^(\+?\d{1,3})?\s?\d{9}$/;
  return phoneRegex.test(phone);
}

export function showLoadingButton(buttonId, bgClass, onlySpiner = false) {
  const loadingHtml = onlySpiner ? '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="pointer-events: none;"></span>' : '<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" style="pointer-events: none;"></span> Cargando...';
  $("#" + buttonId)
    .removeClass(bgClass)
    .addClass("btn-barymont-grey")
    .css("pointer-events", "none")
    .attr("disabled", true)
    .html(loadingHtml);
}

export function updateButtonLabel(buttonId, newLabel, bgClass) {
  $("#" + buttonId)
    .removeClass("btn-barymont-grey")
    .addClass(bgClass)
    .css("pointer-events", "")
    .attr("disabled", false)
    .html(newLabel);
}

export function showLoadingContainerById(containerId) {
  document.getElementById(containerId).innerHTML = `
        <div id="load-${containerId}" class="col-12">
            <div class="d-flex justify-content-center my-3">
                <div class="spinner-border text-barymont-red" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>`;
}

export function removeLoadingContainerById(containerId) {
  document.getElementById("load-" + containerId).remove();
}

export function copyValueToClipboard(value, createToastAlert = true) {
  try {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        if (!createToastAlert) return;
        createToast("info", "Copiado!", "El valor ha sido copiado al portapapeles.", 3000);
      })
      .catch((err) => {
        console.error("Error al copiar al portapapeles: ", err);
      });
  } catch (err) {
    // Thats a fallback for browsers that don't support the Clipboard API
    console.error("Clipboard API no soportada, intentando método alternativo.");

    const tempInput = document.createElement("input");
    tempInput.value = value;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    if (!createToastAlert) return;
    createToast("info", "Copiado!", "El valor ha sido copiado al portapapeles.", 3000);
  }
}

export function createModal(modalId, modalSize, modalTitle, bodyContent, footerButtons = null, modalBodyClasses = null, modalHeaderExtraElements = null, modalFooterExtraElements = null) {
  const modalSizes = ["sm", "md", "lg", "xl"];

  if (!modalSizes.includes(modalSize)) {
    throw new Error("Invalid modal size");
  }

  if (footerButtons === null) {
    footerButtons = [{ id: "close-modal-btn", label: "Cerrar", classes: "btn btn-barymont-black", attr: "data-dismiss='modal'" }];
  }

  if (modalHeaderExtraElements === null) {
    modalHeaderExtraElements = `<button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span>&times;</span>
            </button>`;
  }

  const modalRandomId = Math.random().toString(36).substring(2, 10);
  const fullModalId = `${modalId}-${modalRandomId}`;

  const modalHtml = `
    <div class="modal fade" id="${fullModalId}" tabindex="-1" role="dialog" aria-labelledby="${modalId}-label">
      <div class="modal-dialog modal-dialog-centered modal-${modalSize}" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="${modalId}-label">${modalTitle}</h5>
            ${modalHeaderExtraElements}
          </div>
          <div class="modal-body ${modalBodyClasses ? modalBodyClasses : ""}">
            ${bodyContent}
          </div>
          <div class="modal-footer">
            ${footerButtons.map((btn) => `<button type="button" id="${btn.id}" class="${btn.classes}" ${btn.attr}>${btn.label}</button>`).join("")}
            ${modalFooterExtraElements ? modalFooterExtraElements : ""}
          </div>
        </div>
      </div>
    </div>
  `;

  document.body.insertAdjacentHTML("beforeend", modalHtml);

  const modalElement = $(`#${fullModalId}`);

  modalElement.on("hidden.bs.modal", function () {
    modalElement.remove();
  });

  return modalElement;
}

export function formatDateTime(dateString) {
  const date = new Date(dateString);
  return date.toLocaleString("es-ES", { dateStyle: "short", timeStyle: "short" });
}

export function escapeHtml(text) {
  return text
    .replace(/&/g, "&amp;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
}


//! REMOVE THIS WINDOW OBJECT
window.sharedCopyValueToClipboard = copyValueToClipboard;
