"use strict";

import { showLoadingButton, updateButtonLabel } from "../shared/shared.js";

const MAX_CARACTERS_TITLE_INPUT = 60;
const MAX_CARACTERS_DESCRIPTION_INPUT = 160;

const IMAGE_PLACEHOLDER_URL = "https://content.barymont.com/files/3feb7e40-9ede-4af9-bd79-abf2ff97b9c2.png";

let cropper;

function showLoadingContainerById(containerId) {
  let container = document.getElementById("data-" + containerId);
  let loader = document.createElement("div");
  loader.id = "load-" + containerId;
  loader.classList.add("col-12");
  loader.innerHTML = '<div class="d-flex justify-content-center my-3"><div class="spinner-border text-barymont-red" role="status"><span class="sr-only">Loading...</span></div></div>';

  container.classList.add("d-none");
  container.parentNode.appendChild(loader);
}

function removeLoadingContainerById(containerId) {
  let loader = document.getElementById("load-" + containerId);
  let container = document.getElementById("data-" + containerId);

  loader.remove();
  container.classList.remove("d-none");
}

async function loadNewsData(newsId) {
  let data = await fetchNewsData(newsId);

  if (data.success === false) {
    window.location.replace("/herramientas/noticias");
  }

  printNewsData(data);
}

async function fetchNewsData(newsId) {
  let formData = new FormData();

  formData.append("newsId", newsId);

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    body: formData,
  };

  return await fetch("/herramientas/news/ajax/getNewsData", requestOptions).then((response) => response.json());
}

function tinymceCommonConfig() {
  //? We have version 5.10.9 of tinymce, if we upload the version, the puggins imagetools and toc will not work
  // TODO: uncomment pluggins, toolbar and editor.ui.registry.addButton to enable previewOnWebsite
  return {
    allow_unsafe_link_target: false,
    invalid_elements: "script,object,embed,link,style,form,input",
    selector: "textarea#news-text",
    height: 570,
    menubar: false,
    //plugins: ["link image imagetools table lists preview previewOnWebsite anchor paste toc code insertIframe hubspotCTA"],
    plugins: ["link image imagetools table lists preview anchor paste toc code insertIframe hubspotCTA"],
    //toolbar: "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | table | preview previewOnWebsite | toc | code insertIframe | hubspotCTA",
    toolbar: "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | table | preview | toc | code insertIframe | hubspotCTA ",
    toc_depth: 6,
    style_formats: [
      {
        title: "Títulos",
        items: [
          { title: "Título 1", format: "h2" },
          { title: "Título 2", format: "h3" },
          { title: "Título 3", format: "h4" },
          { title: "Título 4", format: "h5" },
        ],
      },
      {
        title: "Formatos",
        items: [
          { title: "Negrita", format: "bold" },
          { title: "Cursiva", format: "italic" },
          { title: "Subrayada", format: "underline" },
          { title: "Tachada", format: "strikethrough" },
        ],
      },
      { title: "Bloques", items: [{ title: "Párrafo", format: "p" }] },
      {
        title: "Posiciones",
        items: [
          { title: "Izquierda", format: "alignleft" },
          { title: "Centrado", format: "aligncenter" },
          { title: "Derecha", format: "alignright" },
          { title: "Justificado", format: "alignjustify" },
        ],
      },
    ],
    content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
    branding: false,
    setup: function (editor) {
      editor.ui.registry.addButton("hubspotCTA", {
        text: "Insertar CTA de HubSpot",
        onAction: function () {
          editor.execCommand("mceHubspotCTA");
        },
      });
      /*
      editor.ui.registry.addButton("previewOnWebsite", {
        icon: "preview",
        tooltip: "Vista previa en web",
        onAction: function () {
          editor.execCommand("mcePreviewOnWebsite");
        },
      });
      */
    },
  };
}

function printNewsData(data) {
  let parts = data.data.publishDate.split(" ");
  let dateParts = parts[0].split("-");
  let timeParts = parts[1].split(":");
  let formattedDate = dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0] + "T" + timeParts[0] + ":" + timeParts[1];

  document.getElementById("news-data-image").src = data.data.image;
  document.getElementById("news-data-title").value = data.data.title;
  document.getElementById("news-data-published-at-date").value = formattedDate;
  document.getElementById("news-data-url").value = data.data.url;
  document.getElementById("news-short-text").value = data.data.shortText;
  document.getElementById("news-id-category").value = data.data.idCategory;
  document.getElementById("breadcrumb-news-title").innerText = data.data.title;
  document.getElementById("news-id-author").value = data.data.authorId;
  document.getElementById("news-featured-date-hidden").value = data.data.dateFeatured;
  document.getElementById("news-data-meta-title").value = data.data.seoTitle;
  document.getElementById("news-data-meta-description").value = data.data.seoDescription;
  document.getElementById("news-data-meta-keywords").value = data.data.seoKeywords;

  $("#news-visibility-status").bootstrapToggle(data.data.visible ? "on" : "off");
  $("#news-ever-green-status").bootstrapToggle(data.data.everGreen ? "on" : "off");
  $("#news-featured-status").bootstrapToggle(data.data.dateFeatured ? "on" : "off");

  tinymce.init({
    ...tinymceCommonConfig(),
    setup: function (editor) {
      editor.on("init", function () {
        editor.setContent(data.data.fullText);
      });
      editor.on("ExecCommand", function (e) {
        if (e.command === "mceInsertToc" || e.command === "mceUpdateToc") {
          let toc = editor.getDoc().querySelector(".mce-toc h2");
          if (toc) {
            toc.innerHTML = "Índice de contenidos";
          }
        }
      });
    },
  });

  $("#news-id-category").selectpicker("refresh");
  $("#news-id-author").selectpicker("refresh");

  removeLoadingContainerById("news-data");
}

function printDefaultPanel() {
  document.getElementById("news-data-image").src = IMAGE_PLACEHOLDER_URL;

  tinymce.init({
    ...tinymceCommonConfig(),
    setup: function (editor) {
      editor.on("ExecCommand", function (e) {
        if (e.command === "mceInsertToc" || e.command === "mceUpdateToc") {
          let toc = editor.getDoc().querySelector(".mce-toc h2");
          if (toc) {
            toc.innerHTML = "Índice de contenidos";
          }
        }
      });
    },
  });

  removeLoadingContainerById("news-data");
}

function createCropAndBase64Images() {
  const canvas = cropper.getCroppedCanvas({
    width: 825,
    height: 471,
  });

  try {
    canvas.toBlob((blob) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        $("#news-data-image-file-base64").val(JSON.stringify(base64data));
        $("#news-data-image").attr("src", base64data);
        $("#modal-news-image-data").modal("hide");
        createToast("info", "Imagen de la noticia añadida", "La imagen se ha seleccionado correctamente, finaliza la creacion de la noticia para que se suba la imagen.", 5000);
      };
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    createToast("warning", "Ha ocurrido un error", "No se ha podido cambiar el imagen de la noticia, intentelo de nuevo", 5000);
  }
}

async function saveNewsData(e) {
  showLoadingButton(e.target.id, "btn-barymont-red");

  try {
    checkValidData();
  } catch (error) {
    updateButtonLabel(e.target.id, '<i class="fas fa-save pr-3"></i> Guardar', "btn-barymont-red");
    return;
  }

  let formData = new FormData();

  formData.append("newsId", document.getElementById("news-id").value);
  formData.append("title", document.getElementById("news-data-title").value);
  formData.append("visibility", document.getElementById("news-visibility-status").checked);
  formData.append("everGreen", document.getElementById("news-ever-green-status").checked);
  formData.append("idCategory", document.getElementById("news-id-category").value);
  formData.append("publishDate", document.getElementById("news-data-published-at-date").value);
  formData.append("url", document.getElementById("news-data-url").value);
  formData.append("authorId", document.getElementById("news-id-author").value);
  formData.append("shortText", document.getElementById("news-short-text").value);
  formData.append("fullText", tinymce.get("news-text").getContent());
  formData.append("image", document.getElementById("news-data-image-file-base64").value);
  formData.append("seoTitle", document.getElementById("news-data-meta-title").value);
  formData.append("seoDescription", document.getElementById("news-data-meta-description").value);
  formData.append("seoKeywords", document.getElementById("news-data-meta-keywords").value);

  if (document.getElementById("news-featured-status").checked) {
    if (document.getElementById("news-featured-date-hidden").value === null || document.getElementById("news-featured-date-hidden").value === "") {
      formData.append("dateFeatured", "_NEW_DATE_");
    } else {
      formData.append("dateFeatured", document.getElementById("news-featured-date-hidden").value);
    }
  } else {
    formData.append("dateFeatured", null);
  }

  let response = await fetchUpdateNewsData(formData);

  if (response.success === true) {
    showLoadingContainerById("news-data");
    updateButtonLabel(e.target.id, '<i class="fas fa-save pr-3"></i> Guardar', "btn-barymont-red");
    createToast("success", "Noticia guardada", "La noticia se ha guardado correctamente", 5000);
    document.getElementById("news-id").value = response.data.newsId;
    loadNewsData(response.data.newsId);
  } else {
    updateButtonLabel(e.target.id, '<i class="fas fa-save pr-3"></i> Guardar', "btn-barymont-red");
    createToast("error", "Ha ocurrido un error", "No se ha podido guardar la noticia, compruebe que todos los datos introducidos son correctos e intentelo de nuevo", 5000);
  }
}

async function fetchUpdateNewsData(formData) {
  let requestOptions = {
    method: "POST",
    redirect: "follow",
    body: formData,
  };

  return await fetch("/herramientas/news/ajax/saveNewsData", requestOptions).then((response) => response.json());
}

function checkValidData() {
  if (document.getElementById("news-data-title").value === "") {
    createToast("error", "Datos incorrectos", "El título de la noticia no puede estar vacio", 5000);
    throw new Error("El título de la noticia no puede estar vacio");
  }

  if (document.getElementById("news-data-published-at-date").value === "") {
    createToast("error", "Datos incorrectos", "La fecha de publicacion no puede estar vacia", 5000);
    throw new Error("La fecha de publicacion no puede estar vacia");
  }

  if (document.getElementById("news-data-url").value === "") {
    createToast("error", "Datos incorrectos", "La URL de la noticia no puede estar vacia", 5000);
    throw new Error("La URL de la noticia no puede estar vacia");
  }

  if (document.getElementById("news-id-category").value === "") {
    createToast("error", "Datos incorrectos", "La categoria de la noticia no puede estar vacia", 5000);
    throw new Error("La categoria de la noticia no puede estar vacia");
  }

  if (document.getElementById("news-data-image").src === IMAGE_PLACEHOLDER_URL) {
    createToast("error", "Datos incorrectos", "La imagen de la noticia no puede estar vacia", 5000);
    throw new Error("La imagen de la noticia no puede estar vacia");
  }
}

async function fetchAuthorData(authorId) {
  let formData = new FormData();

  formData.append("authorId", authorId);

  let requestOptions = {
    method: "POST",
    body: formData,
  };

  return await fetch("/herramientas/news/ajax/getAuthorData", requestOptions).then((response) => response.json());
}

async function fetchPreviewWebs() {
  let requestOptions = {
    method: "POST",
  };

  return await fetch("/herramientas/news/ajax/getPreviewWebs", requestOptions).then((response) => response.json());
}

function isDataEmpty() {
  let title = document.getElementById("news-data-title").value;
  let category = document.getElementById("news-id-category").value;
  let slug = document.getElementById("news-data-url").value;
  let shortText = document.getElementById("news-short-text").value;
  let fullText = tinymce.get("news-text").getContent();
  let publishDate = document.getElementById("news-data-published-at-date").value;

  if (title === null || title === "") {
    createToast("error", "Datos incorrectos", "El título de la noticia no puede estar vacio", 5000);
    return true;
  }

  if (category === null || category === "") {
    createToast("error", "Datos incorrectos", "La categoría de la noticia no puede estar vacia", 5000);
    return true;
  }

  if (slug === null || slug === "") {
    createToast("error", "Datos incorrectos", "La URL de la noticia no puede estar vacia", 5000);
    return true;
  }

  if (shortText === null || shortText === "") {
    createToast("error", "Datos incorrectos", "El resumen de la noticia no puede estar vacio", 5000);
    return true;
  }

  if (fullText === null || fullText === "") {
    createToast("error", "Datos incorrectos", "El contenido de la noticia no puede estar vacio", 5000);
    return true;
  }

  if (publishDate === null || publishDate === "") {
    createToast("error", "Datos incorrectos", "La fecha de publicación de la noticia no puede estar vacia", 5000);
    return true;
  }

  return false;
}

function updateProgressBar(inputId, progressBarId, maxLength, progressBarTextId) {
  const input = document.getElementById(inputId);
  const length = input.value.length;
  const percentage = (length / maxLength) * 100;

  const progressBar = document.getElementById(progressBarId);
  progressBar.style.width = percentage + "%";

  let tooltipMessage = `${length} / ${maxLength} caracteres`;

  if (percentage === 0) {
    progressBar.classList.remove("bg-success", "bg-warning");
    progressBar.classList.add("bg-danger");
    tooltipMessage += " - Título vacío";
  } else if (percentage <= 70) {
    progressBar.classList.remove("bg-warning", "bg-danger");
    progressBar.classList.add("bg-success");
    tooltipMessage += " - Buen tamaño de título";
  } else if (percentage > 70 && percentage <= 100) {
    progressBar.classList.remove("bg-success", "bg-danger");
    progressBar.classList.add("bg-warning");
    tooltipMessage += " - Casi al límite de caracteres";
  } else {
    progressBar.classList.remove("bg-success", "bg-warning");
    progressBar.classList.add("bg-danger");
    tooltipMessage += " - Demasiado largo";
  }

  const progressBarTextContainer = document.getElementById(progressBarTextId);
  progressBarTextContainer.innerText = tooltipMessage;
}

function initializeProgressBarText(titleProgressBarTextId, maxCaractersTitle, descriptionProgressBarTextId, maxCaractersDescription) {
  const titleProgressBarTextContainer = document.getElementById(titleProgressBarTextId);
  titleProgressBarTextContainer.innerText = `0 / ${maxCaractersTitle} caracteres - Título vacío`;

  const descriptionProgressBarTextContainer = document.getElementById(descriptionProgressBarTextId);
  descriptionProgressBarTextContainer.innerText = `0 / ${maxCaractersDescription} caracteres - Descripción vacía`;
}

async function fetchRemoveNews(newsId) {
  let formData = new FormData();

  formData.append("newsId", newsId);

  let requestOptions = {
    method: "POST",
    body: formData,
  };

  return await fetch("/herramientas/news/ajax/removeNews", requestOptions).then((response) => response.json());
}

if (window) {
  window.addEventListener("load", function () {
    if (window.location.pathname === "/herramientas/noticia" || window.location.pathname.includes("/herramientas/noticia/")) {
      tinymce.PluginManager.add("previewOnWebsite", function (editor) {
        editor.ui.registry.addButton("previewOnWebsite", {
          icon: "browse",
          tooltip: "Vista previa en web",
          onAction: async function () {
            const previewWebs = await fetchPreviewWebs();

            if (previewWebs.success) {
              const items = previewWebs.data.map((web) => ({
                type: "option",
                value: web.urlFormated,
                text: web.label,
              }));

              editor.windowManager.open({
                title: "Seleccionar Web para visualizar el post",
                body: {
                  type: "panel",
                  items: [
                    {
                      type: "selectbox",
                      name: "webUrl",
                      label: "Web",
                      items: items,
                    },
                  ],
                },
                buttons: [
                  {
                    type: "cancel",
                    text: "Cancelar",
                  },
                  {
                    type: "submit",
                    text: "Ver vista previa",
                    primary: true,
                  },
                ],
                onSubmit: async function (api) {
                  const data = api.getData();
                  const selectedUrl = data.webUrl;

                  if (selectedUrl) {
                    createToast("info", "Cargando vista previa...", "Estamos cargando los datos para mostrarte la vista previa, tardará unos segundos...", 5000);

                    if (isDataEmpty()) {
                      return;
                    }

                    let authorData = null;

                    if (document.getElementById("news-id-author").value !== "") {
                      authorData = await fetchAuthorData(document.getElementById("news-id-author").value);
                    }

                    let form = document.createElement("form");
                    form.method = "POST";
                    form.action = selectedUrl;
                    form.target = "_blank";

                    let formData = {
                      newsId: document.getElementById("news-id").value,
                      title: document.getElementById("news-data-title").value,
                      visibility: document.getElementById("news-visibility-status").checked,
                      everGreen: document.getElementById("news-ever-green-status").checked,
                      idCategory: document.getElementById("news-id-category").value,
                      publishDate: document.getElementById("news-data-published-at-date").value,
                      url: document.getElementById("news-data-url").value,
                      authorId: authorData?.data.newsAuthorId || null,
                      authorName: authorData?.data.name || null,
                      authorImage: authorData?.data.image || null,
                      authorDescription: authorData?.data.description || null,
                      authorRole: authorData?.data.role || null,
                      shortText: document.getElementById("news-short-text").value,
                      fullText: tinymce.get("news-text").getContent(),
                      imageBase64: document.getElementById("news-data-image-file-base64").value,
                      imageUrl: document.getElementById("news-data-image").src,
                      seoTitle: document.getElementById("news-data-meta-title").value,
                      seoDescription: document.getElementById("news-data-meta-description").value,
                      seoKeywords: document.getElementById("news-data-meta-keywords").value,
                    };

                    for (let key in formData) {
                      if (Object.prototype.hasOwnProperty.call(formData, key)) {
                        let input = document.createElement("input");
                        input.type = "hidden";
                        input.name = key;
                        input.value = formData[key];
                        form.appendChild(input);
                      }
                    }

                    document.body.appendChild(form);
                    form.submit();
                    document.body.removeChild(form);
                  }

                  api.close();
                },
              });
            } else {
              editor.windowManager.alert("No se pudieron cargar las webs para la vista previa.");
            }
          },
        });
      });

      tinymce.PluginManager.add("hubspotCTA", function (editor) {
        editor.ui.registry.addButton("hubspotCTA", {
          text: "Insertar CTA de HubSpot",
          onAction: function () {
            editor.windowManager.open({
              title: "Insertar CTA de HubSpot",
              body: {
                type: "panel",
                items: [
                  {
                    type: "input",
                    name: "ctaCode",
                    label: "Código del CTA",
                  },
                ],
              },
              buttons: [
                {
                  type: "cancel",
                  text: "Cancelar",
                },
                {
                  type: "submit",
                  text: "Insertar",
                  primary: true,
                },
              ],
              onSubmit: function (api) {
                var data = api.getData();
                editor.insertContent(data.ctaCode);
                api.close();
              },
            });
          },
        });
        editor.addCommand("mceHubspotCTA", function () {
          editor.windowManager.open({
            title: "Insertar CTA de HubSpot",
            body: {
              type: "panel",
              items: [
                {
                  type: "input",
                  name: "ctaCode",
                  label: "Código del CTA",
                },
              ],
            },
            buttons: [
              {
                type: "cancel",
                text: "Cancelar",
              },
              {
                type: "submit",
                text: "Insertar",
                primary: true,
              },
            ],
            onSubmit: function (api) {
              var data = api.getData();
              editor.insertContent(data.ctaCode);
              api.close();
            },
          });
        });
        editor.ui.registry.addButton("insertIframe", {
          icon: "embed",
          tooltip: "Insertar Iframe",
          onAction: function () {
            editor.execCommand("mceInsertIframe");
          },
        });
        editor.addCommand("mceInsertIframe", function () {
          editor.windowManager.open({
            title: "Insertar Iframe",
            body: {
              type: "panel",
              items: [
                {
                  type: "textarea",
                  name: "iframeCode",
                  label: "Código del iframe",
                  placeholder: "<iframe ...></iframe>",
                  multiline: true,
                  minHeight: 100,
                },
              ],
            },
            buttons: [
              {
                type: "cancel",
                text: "Cancelar",
              },
              {
                type: "submit",
                text: "Insertar",
                primary: true,
              },
            ],
            onSubmit: function (api) {
              var data = api.getData();

              if (data.iframeCode) {
                var iframeCode = data.iframeCode;

                iframeCode = iframeCode.replace("<iframe", '<iframe style="position: absolute; top: 0; left: 0; right: 0; width: 100%; height: 100%; border: none;" ');

                const iframeHtmlCode = `
                  <div style="width: 100%; min-width: 200px; max-width: 800px;">
                    <div style="position: relative; width: 100%; overflow: hidden; padding-top: 56.25%;">
                      ${iframeCode}
                    </div>
                  </div>`;

                editor.insertContent(iframeHtmlCode);
                api.close();
              } else {
                editor.notificationManager.open({
                  text: "Por favor, ingrese el código del iframe.",
                  type: "warning",
                });
              }
            },
          });
        });
      });

      $("#news-data-image-file").change((event) => {
        const [file] = event.target.files;

        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            image.src = e.target.result;
            $("#modal-news-image-data").modal("show");
          };
          reader.readAsDataURL(file);
        }
      });

      const image = document.getElementById("crop-modal-news-data-image");

      $("#modal-news-image-data")
        .on("shown.bs.modal", () => {
          cropper = new Cropper(image, {
            aspectRatio: 16 / 9,
            viewMode: 1,
            autoCropArea: 1,
            preview: ".preview-modal-news-data-image",
          });
        })
        .on("hidden.bs.modal", () => {
          cropper.destroy();
          cropper = null;
        });

      document.getElementById("btn-update-modal-news-data-image").addEventListener("click", () => {
        createCropAndBase64Images();
      });

      document.getElementById("btn-news-data-save").addEventListener("click", (e) => {
        saveNewsData(e);
      });

      document.getElementById("news-remove-image").addEventListener("click", () => {
        document.getElementById("news-data-image").src = IMAGE_PLACEHOLDER_URL;
        document.getElementById("news-data-image-file").value = "";
        document.getElementById("news-data-image-file-base64").value = "";
        createToast("info", "Imagen eliminada", "La imagen de la noticia se ha eliminado correctamente", 5000);
      });

      let newsId = document.getElementById("news-id").value;

      if (newsId !== "") {
        loadNewsData(newsId).then(() => {
          initializeProgressBarText(titleProgressBarTextId, MAX_CARACTERS_TITLE_INPUT, descriptionProgressBarTextId, MAX_CARACTERS_DESCRIPTION_INPUT);

          document.getElementById(titleInputId).dispatchEvent(new Event("input"));
          document.getElementById(descriptionInputId).dispatchEvent(new Event("input"));
        });
      } else {
        printDefaultPanel();
      }

      const inputHiddenNewsId = "news-id";

      const titleInputId = "news-data-meta-title";
      const titleProgressBarId = "title-progress-bar";
      const titleProgressBarTextId = "title-progress-bar-text";
      const descriptionInputId = "news-data-meta-description";
      const descriptionProgressBarId = "description-progress-bar";
      const descriptionProgressBarTextId = "description-progress-bar-text";

      const panelNewsDeleteButtonId = "btn-news-data-delete";
      const modalDeleteNewsId = "modal-news-delete";
      const modalDeleteNewsButtonId = "modal-news-delete-button";

      document.getElementById(titleInputId).addEventListener("input", function () {
        updateProgressBar(titleInputId, titleProgressBarId, MAX_CARACTERS_TITLE_INPUT, titleProgressBarTextId);
      });

      document.getElementById(descriptionInputId).addEventListener("input", function () {
        updateProgressBar(descriptionInputId, descriptionProgressBarId, MAX_CARACTERS_DESCRIPTION_INPUT, descriptionProgressBarTextId);
      });

      document.getElementById(panelNewsDeleteButtonId)?.addEventListener("click", function () {
        $("#" + modalDeleteNewsId).modal("show");
      });

      document.getElementById(modalDeleteNewsButtonId)?.addEventListener("click", function () {
        showLoadingButton(modalDeleteNewsButtonId, "btn-barymont-red", true);

        fetchRemoveNews(document.getElementById(inputHiddenNewsId).value, modalDeleteNewsId).then((response) => {
          if (response.success === true) {
            $("#" + modalDeleteNewsId).modal("hide");
            createToast("success", "Noticia eliminada", "La noticia ha sido eliminada correctamente, redirigiendo al listado de noticias", 5000);
            setTimeout(() => {
              window.location.replace("/herramientas/noticias");
            }, 1500);
          } else {
            createToast("error", "Error al eliminar la noticia", "Ha ocurrido un error al eliminar la noticia, ponte en contacto con soporte", 5000);
          }
          updateButtonLabel(modalDeleteNewsButtonId, "<i class='fas fa-trash mr-2'></i>Eliminar", "btn-barymont-red");
        });
      });
    }
  });
}
export {};
