"use strict";

import { createLead } from "../../api/leads.js";
import { searchCountries } from "../../api/countries.js";
import { searchProvinces } from "../../api/provinces.js";
import { showLoadingButton, updateButtonLabel } from "../shared/shared.js";
import { isValidEmail, isValidPhone } from "/assets/js/modules/shared/shared.js";

var FILTER_STATE_LOCAL_STORAGE_KEY = "LEAD_FILTER_STATE_";
var FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME = 1000 * 60 * 20; // 20 minutes in milliseconds

function getStateOfCustomFilters() {
  return {
    checkSoloLeads: document.getElementById("cbox1").checked,
    checkSoloCandidatos: document.getElementById("cbox2").checked,
    checkSoloClientes: document.getElementById("cbox3").checked,
    checkSoloAsignadosPropios: document.getElementById("cbox6").checked,
    checkClienteFiel: document.getElementById("cbox7").checked,
    checkClienteConServiciosSPAD: document.getElementById("cbox8").checked,
    checkClienteConSolicitudesPendientes: document.getElementById("cbox9").checked,
    checkClienteConServiciosPYMES: document.getElementById("cbox10").checked,
    checkSoloWpt: document.getElementById("solowpt").checked,
    subordinateUserSelector: document.getElementById("subordinates-selector").value,
    subordinateFilterMode: document.getElementById("subordinates-filter-mode").value,
    checkFechaInicio: document.getElementById("fecha_inicio").value,
    checkFechaFin: document.getElementById("fecha_fin").value,
    checkIdProvincia: document.getElementById("id_provincia").value,
    checkTareasPendientes: document.getElementById("cbox11").checked,
    isPromoter: document.getElementById("is-promoter").checked,
  };
}

function recreateStateOfCustomFilters(localStorageData) {
  if (localStorageData.customFilter === undefined) {
    return false;
  }

  document.getElementById("cbox1").checked = localStorageData.customFilter.checkSoloLeads;
  document.getElementById("cbox2").checked = localStorageData.customFilter.checkSoloCandidatos;
  document.getElementById("cbox3").checked = localStorageData.customFilter.checkSoloClientes;

  setBootstrapToggle("#cbox6", localStorageData.customFilter.checkSoloAsignadosPropios);
  setBootstrapToggle("#cbox7", localStorageData.customFilter.checkClienteFiel);
  setBootstrapToggle("#cbox8", localStorageData.customFilter.checkClienteConServiciosSPAD);
  setBootstrapToggle("#cbox9", localStorageData.customFilter.checkClienteConSolicitudesPendientes);
  setBootstrapToggle("#cbox10", localStorageData.customFilter.checkClienteConServiciosPYMES);
  setBootstrapToggle("#cbox11", localStorageData.customFilter.checkTareasPendientes);
  setBootstrapToggle("#solowpt", localStorageData.customFilter.checkSoloWpt);
  setBootstrapToggle("#is-promoter", localStorageData.customFilter.isPromoter);

  document.getElementById("id_provincia").value = localStorageData.customFilter.checkIdProvincia;
  $("#id_provincia").selectpicker("refresh");

  document.getElementById("subordinates-selector").value = localStorageData.customFilter.subordinateUserSelector;
  $("#subordinates-selector").selectpicker("refresh");

  document.getElementById("subordinates-filter-mode").value = localStorageData.customFilter.subordinateFilterMode;
  $("#subordinates-filter-mode").selectpicker("refresh");

  document.getElementById("fecha_inicio").value = localStorageData.customFilter.checkFechaInicio;
  document.getElementById("fecha_fin").value = localStorageData.customFilter.checkFechaFin;

  if (localStorageData.customFilter.checkSoloAsignadosPropios || localStorageData.customFilter.checkClienteFiel || localStorageData.customFilter.checkClienteConServiciosSPAD || localStorageData.customFilter.checkClienteConSolicitudesPendientes || localStorageData.customFilter.checkClienteConServiciosPYMES || localStorageData.customFilter.checkSoloWpt || localStorageData.customFilter.checkIdProvincia != "0" || localStorageData.customFilter.checkTareasPendientes) {
    cambiarEstadoOtrosFiltros();
  }
}

function resetearFiltros() {
  document.getElementById("cbox5").checked = true;

  setBootstrapToggle("#cbox6", false);
  setBootstrapToggle("#cbox7", false);
  setBootstrapToggle("#cbox8", false);
  setBootstrapToggle("#cbox9", false);
  setBootstrapToggle("#cbox10", false);
  setBootstrapToggle("#solowpt", false);
  setBootstrapToggle("#cbox11", false);
  setBootstrapToggle("#is-promoter", false);

  document.getElementById("fecha_inicio").value = "";
  document.getElementById("fecha_fin").value = "";
  document.getElementById("id_provincia").value = "0";
  $("#id_provincia").selectpicker("refresh");

  document.getElementById("subordinates-selector").value = "";
  $("#subordinates-selector").selectpicker("refresh");

  document.getElementById("subordinates-filter-mode").value = "is_responsible";
  $("#subordinates-filter-mode").selectpicker("refresh");

  $("#leads_tabla").DataTable().search("").draw();
}

function cambiarEstadoOtrosFiltros() {
  const $elementDisplay = document.getElementById("otrosFiltros").style.display;
  if ($elementDisplay == "none") {
    $("#otrosFiltros").slideDown(250);
    document.getElementById("otrosFiltros").style.display = "block";
    document.getElementById("showhidefiltersbutton").innerHTML = "Ocultar filtros";
  } else {
    $("#otrosFiltros").slideUp(250);
    setTimeout(function () {
      document.getElementById("otrosFiltros").style.display = "none";
    }, 250);
    document.getElementById("showhidefiltersbutton").innerHTML = "Mostrar más filtros";
  }
}

function checkValidDates() {
  var fecha_inicio = document.getElementById("fecha_inicio").value;
  var fecha_fin = document.getElementById("fecha_fin").value;
  if (fecha_inicio > fecha_fin && fecha_fin != "") {
    document.getElementById("fecha_inicio").style.borderColor = "#ff0000";
    document.getElementById("fecha_fin").style.borderColor = "#ff0000";
    $("#fecha_fin").popover("show");
    setTimeout(function () {
      document.getElementById("fecha_inicio").style.borderColor = "#ccc";
      document.getElementById("fecha_fin").style.borderColor = "#ccc";
      $("#fecha_fin").popover("hide");
    }, 2000);
    document.getElementById("fecha_fin").value = fecha_inicio;
  }
}

function setBootstrapToggle(id, value) {
  if (!$(id).hasClass("updatetrigger")) {
    return;
  }

  if (value) {
    $(id).data("bs.toggle").on(true);
    return;
  }

  $(id).data("bs.toggle").off(true);
}

async function openCreateLeadModal() {
  const randomModalId = Math.random().toString(36).substring(7);

  let modal = document.createElement("div");
  modal.id = randomModalId;
  modal.classList.add("modal", "fade");
  modal.setAttribute("tabindex", "-1");
  modal.setAttribute("role", "dialog");
  modal.setAttribute("aria-hidden", "true");

  modal.insertAdjacentHTML(
    "beforeend",
    `<div class="modal-dialog modal-dialog-centered modal-xl" role="document" backdrop="static">
        <div class="modal-content">

            <div class="modal-header">
                <h1 class="modal-title">Crear nuevo Lead</h1>
            </div>

            <div id="create-lead-modal" class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text bg-light">DNI / NIE / CIF</span>
                          </div>
                          <input id="identification-number" type="text" class="form-control font-weight-bolder" required>
                        </div>
                      </div>
                      <div id="div-name" class="col-md-8">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text bg-light">Nombre</span>
                          </div>
                          <input id="name" type="text" class="form-control font-weight-bolder" required>
                        </div>
                      </div>
                      <div id="div-first-surname" class="col-md-6">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text bg-light">Primer Apellido</span>
                          </div>
                          <input id="first-surname" type="text" class="form-control font-weight-bolder" required>
                        </div>
                      </div>
                      <div id="div-second-surname" class="col-md-6">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text bg-light">Segundo Apellido</span>
                          </div>
                          <input id="second-surname" type="text" class="form-control font-weight-bolder" required>
                        </div>
                      </div>
                      <div id="div-company-name" class="col-md-8" style="display: none;">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text bg-light">Razón Social</span>
                          </div>
                          <input id="company-name" type="text" class="form-control font-weight-bolder" required>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text bg-light">Email</span>
                          </div>
                          <input id="email" type="text" class="form-control font-weight-bolder" required>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text bg-light">Teléfono</span>
                          </div>
                          <input id="phone" type="text" class="form-control font-weight-bolder" required>
                        </div>
                      </div>
                      <div id="div-birthdate" class="col-md-6">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text bg-light">Fecha de Nacimiento</span>
                          </div>
                          <input id="birthdate" type="date" class="form-control font-weight-bolder" required>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text bg-light">Provincia</span>
                          </div>
                          <select id="province" class="form-control selectpicker show-tick input-lg modal-selectpicker updatetrigger" title="Selecciona una provincia..." data-live-search="true" data-size="7" data-dropup-auto="true" required>
                          </select>
                        </div>
                      </div>
                      <div id="div-nationality" class="col-md-6" style="display:none;">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text bg-light">Nacionalidad</span>
                          </div>
                          <select id="nationality" class="form-control selectpicker show-tick input-lg modal-selectpicker updatetrigger" title="Selecciona una nacionalidad..." data-live-search="true" data-size="7" data-dropup-auto="true" required>
                          </select>
                        </div>
                      </div>
                    </div>
                </div>

                <p class="text-muted text-center">Tras la creación del nuevo Lead, el sistema <strong>enviará automáticamente un correo electrónico</strong> al cliente para solicitar la aceptación del documento legal <strong>SAGB</strong>.<br>Este paso es obligatorio para cumplir con la normativa vigente de <strong>protección de datos</strong> y para poder continuar con los procesos futuros.</p>
            </div>

            <div class="modal-footer">
                <button id="btn-save-lead" type="button" class="btn btn-barymont-red">Crear</button>
                <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
            </div>

        </div>
    </div>`
  );

  document.body.appendChild(modal);

  let provinces = await searchProvinces();

  provinces.forEach((province) => {
    document.querySelector("#create-lead-modal #province").insertAdjacentHTML("beforeend", `<option value="${province.id}">${province.name}</option>`);
  });

  $("#create-lead-modal #province").selectpicker("refresh");

  document.querySelector("#create-lead-modal #identification-number").addEventListener("input", async function (e) {
    const identificationNumberValidated = ValidateSpanishID(e.target.value);

    if (identificationNumberValidated.valid === false) {
      e.target.classList.add("is-invalid");
      e.target.classList.remove("is-valid");

      return;
    }

    e.target.classList.remove("is-invalid");
    e.target.classList.add("is-valid");

    let countries = null;

    switch (identificationNumberValidated.type) {
      case "CIF":
        document.querySelector("#create-lead-modal #div-name").style.display = "none";
        document.querySelector("#create-lead-modal #name").value = "";

        document.querySelector("#create-lead-modal #div-first-surname").style.display = "none";
        document.querySelector("#create-lead-modal #first-surname").value = "";

        document.querySelector("#create-lead-modal #div-second-surname").style.display = "none";
        document.querySelector("#create-lead-modal #second-surname").value = "";

        document.querySelector("#create-lead-modal #div-birthdate").style.display = "none";
        document.querySelector("#create-lead-modal #birthdate").value = "";

        document.querySelector("#create-lead-modal #div-company-name").style.display = "block";

        document.querySelector("#create-lead-modal #div-nationality").style.display = "none";
        document.querySelector("#create-lead-modal #nationality").value = "";
        break;
      case "NIE":
        if (document.querySelector("#create-lead-modal #nationality").options.length === 0) {
          countries = await searchCountries();

          countries.forEach((country) => {
            // Skip Spain option when creating a new lead with NIE
            if (country.id === 16) {
              return;
            }

            document.querySelector("#create-lead-modal #nationality").insertAdjacentHTML("beforeend", `<option value="${country.id}">${country.name}</option>`);
          });

          $("#create-lead-modal #nationality").selectpicker("refresh");
        }

        document.querySelector("#create-lead-modal #div-name").style.display = "block";
        document.querySelector("#create-lead-modal #div-first-surname").style.display = "block";
        document.querySelector("#create-lead-modal #div-second-surname").style.display = "block";
        document.querySelector("#create-lead-modal #div-birthdate").style.display = "block";

        document.querySelector("#create-lead-modal #div-company-name").style.display = "none";
        document.querySelector("#create-lead-modal #company-name").value = "";

        document.querySelector("#create-lead-modal #div-nationality").style.display = "block";
        break;
      case "NIF":
        document.querySelector("#create-lead-modal #div-name").style.display = "block";
        document.querySelector("#create-lead-modal #div-first-surname").style.display = "block";
        document.querySelector("#create-lead-modal #div-second-surname").style.display = "block";
        document.querySelector("#create-lead-modal #div-birthdate").style.display = "block";

        document.querySelector("#create-lead-modal #div-company-name").style.display = "none";
        document.querySelector("#create-lead-modal #company-name").value = "";

        document.querySelector("#create-lead-modal #div-nationality").style.display = "none";
        document.querySelector("#create-lead-modal #nationality").value = "";
        break;
    }
  });

  $("#" + randomModalId).modal("show");

  document.getElementById("btn-save-lead").addEventListener("click", async function (e) {
    showLoadingButton(e.target.id, "btn-barymont-red");
    try {
      const validatedLead = await createValidatedLead(randomModalId);

      const response = await createLead(validatedLead);

      createToast("success", "✅ Éxito", "El lead se ha creado correctamente.");

      window.location.href = `/herramientas/leads/${response.leadId}`;
    } catch (error) {
      createToast("error", "❌ Error", error.message);
    }

    updateButtonLabel(e.target.id, "Crear", "btn-barymont-red");
  });

  $("#" + randomModalId).on("hidden.bs.modal", function () {
    document.getElementById(randomModalId).remove();
  });
}

async function createValidatedLead() {
  let identificationNumber = document.querySelector("#create-lead-modal #identification-number").value;
  let name = document.querySelector("#create-lead-modal #name").value;
  let companyName = document.querySelector("#create-lead-modal #company-name")?.value;
  let firstSurname = document.querySelector("#create-lead-modal #first-surname")?.value;
  let secondSurname = document.querySelector("#create-lead-modal #second-surname")?.value;
  let email = document.querySelector("#create-lead-modal #email").value;
  let phone = document.querySelector("#create-lead-modal #phone").value;
  let birthDate = document.querySelector("#create-lead-modal #birthdate")?.value;
  let provinceId = document.querySelector("#create-lead-modal #province").value;
  let nationalityId = document.querySelector("#create-lead-modal #nationality")?.value;

  if (identificationNumber === "" || email === "" || phone === "" || provinceId === "") {
    throw new Error("Todos los campos son obligatorios, revisa los datos e inténtalo de nuevo.");
  }

  if (isValidEmail(email) === false) {
    throw new Error("El email introducido no es válido, revisa los datos e inténtalo de nuevo.");
  }

  if (isValidPhone(phone) === false) {
    throw new Error("El teléfono introducido no es válido, revisa los datos e inténtalo de nuevo.");
  }

  if (birthDate) {
    const date = new Date(birthDate);
    const year = String(date.getFullYear());
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    birthDate = `${year}-${month}-${day}`;

    if (new Date(birthDate) > new Date()) {
      throw new Error("La fecha de nacimiento no puede ser mayor a la fecha actual, revisa los datos e inténtalo de nuevo.");
    }
  }

  const identificationNumberValidated = ValidateSpanishID(identificationNumber);

  if (identificationNumberValidated.valid === false) {
    throw new Error("El DNI/NIE/CIF introducido no es válido, revisa los datos e inténtalo de nuevo.");
  }

  switch (identificationNumberValidated.type) {
    case "CIF":
      if (companyName === "") {
        throw new Error("El campo Razón Social es obligatorio, revisa los datos e inténtalo de nuevo.");
      }

      name = companyName;
      break;
    case "NIE":
      if (name === "" || firstSurname === "" || secondSurname === "" || birthDate === "" || nationalityId === "") {
        throw new Error("Todos los campos son obligatorios, revisa los datos e inténtalo de nuevo.");
      }
      break;
    case "NIF":
      if (name === "" || firstSurname === "" || secondSurname === "" || birthDate === "") {
        throw new Error("Todos los campos son obligatorios, revisa los datos e inténtalo de nuevo.");
      }
      break;
  }

  const validatedLead = {
    identificationNumber: identificationNumber,
    name: name,
    firstSurname: firstSurname,
    secondSurname: secondSurname,
    email: email,
    phone: phone,
    provinceId: provinceId,
    birthDate: birthDate,
    nationalityId: nationalityId,
  };

  return validatedLead;
}

const leadDataTableConfig = {
  language: {
    sProcessing: "Procesando Datos...",
    sLengthMenu: "Mostrar _MENU_ leads",
    sZeroRecords: "No se encontraron Leads coincidentes con el criterio de búsqueda.",
    sEmptyTable: "Ningún lead coincidente con el criterio de búsqueda.",
    sInfo: "Mostrando Leads del _START_ al _END_ de un total de _TOTAL_ leads",
    sInfoEmpty: "Mostrando Leads del 0 al 0 de un total de 0 leads",
    sInfoFiltered: "(Filtrados de un total de _MAX_ leads)",
    sInfoPostFix: "",
    sSearch: "<div class='input-group-prepend'><div class='input-group-text'><i class='fas fa-search'></i></div></div>",
    searchPlaceholder: "Datos del Lead",
    sUrl: "",
    sInfoThousands: ",",
    sLoadingRecords: "Cargando Datos...",
    oPaginate: {
      sFirst: "Primera",
      sLast: "Última",
      sNext: "Siguiente",
      sPrevious: "Anterior",
    },
    oAria: {
      sSortAscending: ": Activar para ordenar la columna de manera ascendente",
      sSortDescending: ": Activar para ordenar la columna de manera descendente",
    },
    buttons: {
      copySuccess: {
        1: "Copiado 1 lead al Portapapeles",
        _: "Copiados %d Leads al Portapapeles",
      },
      copyTitle: "Leads Copiados al Portapapeles",
    },
    select: {
      rows: "%d Leads seleccionados",
    },
  },
  pagingType: "input",
  columnDefs: [
    {
      targets: [0, 2, 3],
      visible: false,
      searchable: true,
    },
    {
      targets: [9, 10, 11],
      orderable: false,
    },
    {
      width: "5%",
      targets: [11],
    },
    {
      width: "150px",
      targets: [9],
    },
    {
      targets: [0, 1, 2, 3, 6, 7, 8, 9, 10, 11],
      className: "dt-center",
    },
    {
      width: "5%",
      targets: [1, 7, 8],
    },
  ],
  order: [[7, "desc"]],
  processing: true,
  serverSide: true,
  scrollX: true,
  dom: '<"top"fl>Brt<"bottom"ip><"clear">',
  buttons: [
    {
      extend: "colvis",
      text: "Ver más columnas",
    },
    {
      extend: "collection",
      text: "Herramientas",
      buttons: [
        {
          extend: "copyHtml5",
          text: "Copiar",
        },
        {
          extend: "print",
          text: "Imprimir",
        },
        {
          extend: "excelHtml5",
          title: "Leads Gestibarymont",
          text: "Excel",
        },
      ],
      fade: true,
    },
    {
      text: '<i class="fas fa-eraser mr-2"></i> Limpiar filtros',
      className: "btn btn-barymont-black my-2 mb-md-0",
      action: function () {
        resetearFiltros();
      },
      init: function (api, node) {
        $(node).removeClass("dt-button");
      },
    },
  ],
  pageLength: 10,
  lengthMenu: [
    [10, 25, 50, 100, 500],
    [10, 25, 50, 100, 500],
  ],
  select: false,
  keys: true,
  searchHighlight: true,
  ajax: {
    url: "/herramientas/leads/listaprocessing",
    type: "post",
    data: function (d) {
      let checkSoloLeads = document.getElementById("cbox1").checked;
      let checkSoloCandidatos = document.getElementById("cbox2").checked;
      let checkSoloClientes = document.getElementById("cbox3").checked;
      let checkSoloAsignadosPropios = document.getElementById("cbox6")?.checked;
      let checkClienteFiel = document.getElementById("cbox7")?.checked;
      let checkClienteConServiciosSPAD = document.getElementById("cbox8")?.checked;
      let checkClienteConSolicitudesPendientes = document.getElementById("cbox9")?.checked;
      let checkClienteConServiciosPYMES = document.getElementById("cbox10")?.checked;
      let checkTareasPendientes = document.getElementById("cbox11").checked;
      let isPromoter = document.getElementById("is-promoter") !== null ? document.getElementById("is-promoter").checked : false;

      let checkSoloWpt = document.getElementById("solowpt")?.checked;
      let subordinateUserSelector = document.getElementById("subordinates-selector") !== null ? document.getElementById("subordinates-selector").value : "";
      let subordinateFilterMode = document.getElementById("subordinates-filter-mode")?.value;
      let checkFechaInicio = document.getElementById("fecha_inicio").value;
      let checkFechaFin = document.getElementById("fecha_fin").value;
      let checkIdProvincia = document.getElementById("id_provincia").value;

      let soloLeads = 0;
      let soloCandidatos = 0;
      let soloClientes = 0;
      let soloAsignadosPropios = 0;
      let clienteFiel = 0;
      let clienteConServiciosSPAD = 0;
      let clienteConSolicitudesPendientes = 0;
      let clienteConServiciosPYMES = 0;
      let tareasPendientes = 0;

      let soloWpt = 0;
      let subordinateUser = "";
      let subordinateFilterModeValue = "";
      let fechaInicio = "";
      let fechaFin = "";
      let idProvincia = 0;

      if (checkSoloLeads == true) {
        soloLeads = 1;
      }
      if (checkSoloCandidatos == true) {
        soloCandidatos = 1;
      }
      if (checkSoloClientes == true) {
        soloClientes = 1;
      }
      if (checkSoloAsignadosPropios == true) {
        soloAsignadosPropios = 1;
      }
      if (checkClienteFiel == true) {
        clienteFiel = 1;
      }
      if (checkClienteConServiciosSPAD == true) {
        clienteConServiciosSPAD = 1;
      }
      if (checkClienteConSolicitudesPendientes == true) {
        clienteConSolicitudesPendientes = 1;
      }
      if (checkClienteConServiciosPYMES == true) {
        clienteConServiciosPYMES = 1;
      }
      if (checkSoloWpt == true) {
        soloWpt = 1;
      }
      if (subordinateUserSelector != "") {
        subordinateUser = subordinateUserSelector;
      }
      if (subordinateFilterMode != "") {
        subordinateFilterModeValue = subordinateFilterMode;
      }
      if (checkFechaInicio != "") {
        fechaInicio = checkFechaInicio;
      }
      if (checkFechaFin != "") {
        fechaFin = checkFechaFin;
      }
      if (checkIdProvincia != 0) {
        idProvincia = checkIdProvincia;
      }
      if (checkTareasPendientes == true) {
        tareasPendientes = 1;
      }

      d.soloLeads = soloLeads;
      d.soloCandidatos = soloCandidatos;
      d.soloClientes = soloClientes;
      d.soloAsignadosPropios = soloAsignadosPropios;
      d.clienteFiel = clienteFiel;
      d.clienteConServiciosSPAD = clienteConServiciosSPAD;
      d.clienteConSolicitudesPendientes = clienteConSolicitudesPendientes;
      d.clienteConServiciosPYMES = clienteConServiciosPYMES;
      d.tareasPendientes = tareasPendientes;
      d.isPromoter = isPromoter;

      d.soloWpt = soloWpt;
      d.subordinateUser = subordinateUser;
      d.subordinateFilterMode = subordinateFilterModeValue;
      d.fechaInicio = fechaInicio;
      d.fechaFin = fechaFin;
      d.idProvincia = idProvincia;
    },
    error: function (e) {
      if (e.status === 401) {
        createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
        setTimeout(function () {
          location.reload();
        }, 5000);
      } else {
        createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
      }
    },
  },
  fnDrawCallback: function () {
    $('[data-toggle="tooltip"]').tooltip();
  },
  stateSave: true,
  stateSaveCallback: function (settings, data) {
    let storageKey = `${FILTER_STATE_LOCAL_STORAGE_KEY}${settings.sInstance}_${getCookieValue("user_id")}`;

    data.customFilter = getStateOfCustomFilters();

    localStorage.setItem(storageKey, JSON.stringify(data));
  },
  stateLoadCallback: function (settings) {
    let storageKey = `${FILTER_STATE_LOCAL_STORAGE_KEY}${settings.sInstance}_${getCookieValue("user_id")}`;

    if (localStorage.getItem(storageKey) === null) {
      return false;
    }

    let localStorageData = JSON.parse(localStorage.getItem(storageKey));

    if (localStorageData.time < new Date().getTime() - FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME) {
      return false;
    }

    recreateStateOfCustomFilters(localStorageData);

    return localStorageData;
  },
};

if (window) {
  if (window.location.pathname === "/herramientas/leads") {
    window.addEventListener("load", function () {
      if (AppGbSession.checkUserHasPermission("Leads:LeadCreateAccessChecker")) {
        leadDataTableConfig.buttons.push({
          text: "<i class='fas fa-plus mr-2'></i>Crear nuevo Lead",
          className: "btn btn-barymont-red my-2 mb-md-0",
          action: function () {
            if (AppGbSession.checkUserHasPermission("Leads:LeadToolInteractFullAccessChecker")) {
              location.href = "/herramientas/anf/clienteNuevo/";
            } else {
              openCreateLeadModal();
            }
          },
          init: function (api, node) {
            $(node).removeClass("dt-button");
          },
        });
      }

      $("#leads_tabla").DataTable(leadDataTableConfig);

      $(".updatetrigger").change(function () {
        $("#leads_tabla").DataTable().ajax.reload();
      });

      document.getElementById("subordinates-selector").addEventListener("change", function () {
        $("#leads_tabla").DataTable().ajax.reload();
      });

      document.getElementById("subordinates-filter-mode").addEventListener("change", function () {
        $("#leads_tabla").DataTable().ajax.reload();
      });

      document.getElementById("id_provincia").addEventListener("change", function () {
        $("#leads_tabla").DataTable().ajax.reload();
      });

      document.getElementById("btn-more-filters").addEventListener("click", cambiarEstadoOtrosFiltros);

      document.getElementById("fecha_inicio").addEventListener("change", checkValidDates);
      document.getElementById("fecha_fin").addEventListener("change", checkValidDates);
    });
  }
}
