"use strict";

var FILTER_STATE_LOCAL_STORAGE_KEY = "ACHIEVEMENT_MANAGEMENT_FILTER_STATE_";
var FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME = 1000 * 60 * 20; // 20 minutes in milliseconds

const dataTableId = "achievement-management-table";

function getStateOfCustomFilters() {
  const selectecOptionsOfAchievementTypeFilter = Array.from(document.getElementById("achievement-type-filter").selectedOptions).map((option) => option.value);
  const selectedOptionsOfAchievementUserListFilter = Array.from(document.getElementById("achievement-user-list-filter").selectedOptions).map((option) => option.value);

  return {
    achievementTypeSelected: selectecOptionsOfAchievementTypeFilter,
    achievementUserListSelected: selectedOptionsOfAchievementUserListFilter,
    achievementStartDateSelected: document.getElementById("achievement-start-date-filter").value,
    achievementFinishDateSelected: document.getElementById("achievement-finish-date-filter").value,
  };
}

async function recreateStateOfCustomFilters(localStorageData) {
  if (localStorageData.customFilter === undefined) {
    return false;
  }

  const selectecOptionsOfAchievementTypeFilter = localStorageData.customFilter.achievementTypeSelected;

  if (selectecOptionsOfAchievementTypeFilter.length > 0) {
    const select = document.getElementById("achievement-type-filter");

    selectecOptionsOfAchievementTypeFilter.forEach((optionValue) => {
      const option = select.querySelector(`option[value="${optionValue}"]`);
      if (option) {
        option.selected = true;
      }
    });
  }

  const selectedOptionsOfAchievementUserListFilter = localStorageData.customFilter.achievementUserListSelected;

  if (selectedOptionsOfAchievementUserListFilter.length > 0) {
    const select = document.getElementById("achievement-user-list-filter");

    selectedOptionsOfAchievementUserListFilter.forEach((optionValue) => {
      const option = select.querySelector(`option[value="${optionValue}"]`);
      if (option) {
        option.selected = true;
      } else {
        const option = document.createElement("option");

        option.value = optionValue;
        option.text = "";
        option.selected = true;
        option.dataset.temporal = true;

        select.add(option);
      }
    });
  }

  document.getElementById("achievement-start-date-filter").value = localStorageData.customFilter.achievementStartDateSelected;
  document.getElementById("achievement-finish-date-filter").value = localStorageData.customFilter.achievementFinishDateSelected;
}

function resetearFiltros() {
  document.getElementById("achievement-type-filter").value = "";
  document.getElementById("achievement-user-list-filter").value = "";
  document.getElementById("achievement-start-date-filter").value = "";
  document.getElementById("achievement-finish-date-filter").value = "";

  $("#" + dataTableId)
    .DataTable()
    .search("")
    .draw();

  $("#" + dataTableId)
    .DataTable()
    .ajax.reload();
  $(".selectpicker").selectpicker("refresh");
}

function getLocalStorageData(datatableId) {
  let storageKey = `${FILTER_STATE_LOCAL_STORAGE_KEY}${datatableId}_${getCookieValue("user_id")}`;

  if (localStorage.getItem(storageKey) === null) {
    return false;
  }

  let localStorageData = JSON.parse(localStorage.getItem(storageKey));

  if (localStorageData.time < new Date().getTime() - FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME) {
    return false;
  }
  return localStorageData;
}

function showLoadingButton(buttonId, bgClass, onlySpiner = false) {
  const button = document.getElementById(buttonId);
  const loadingHtml = onlySpiner ? '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="pointer-events: none;"></span>' : '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="pointer-events: none;"></span> Cargando...';

  button.classList.remove(bgClass);
  button.classList.add("btn-barymont-grey");
  button.disabled = true;
  button.innerHTML = loadingHtml;
}

function updateButtonLabel(buttonId, newLabel, bgClass) {
  const button = document.getElementById(buttonId);

  button.classList.remove("btn-barymont-grey");
  button.classList.add(bgClass);
  button.disabled = false;
  button.innerHTML = newLabel;
}

async function fetchAchievementData(achievementId) {
  let formData = new FormData();

  formData.append("achievementId", achievementId);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/logros-management/ajax/getAchievementDataById", requestOptions).then((response) => response.json());
}

async function fetchSaveNewAchievementData(achievementType, achievementUserId, achievementObtainedAt, achievementReason) {
  let formData = new FormData();

  formData.append("achievementUserId", achievementUserId);
  formData.append("achievementType", achievementType);
  formData.append("achievementObtainedAt", achievementObtainedAt);
  formData.append("achievementReason", achievementReason);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/logros-management/ajax/saveNewAchievementData", requestOptions).then((response) => response.json());
}

async function fetchEditAchievementData(achievementId, achievementObtainedAt, achievementReason) {
  let formData = new FormData();

  formData.append("achievementId", achievementId);
  formData.append("achievementObtainedAt", achievementObtainedAt);
  formData.append("achievementReason", achievementReason);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/logros-management/ajax/editAchievementData", requestOptions).then((response) => response.json());
}

async function fetchDeleteAchievementData(achievementId) {
  let formData = new FormData();

  formData.append("achievementId", achievementId);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/logros-management/ajax/deleteAchievementById", requestOptions).then((response) => response.json());
}

async function fetchUserList() {
  let requestOptions = {
    method: "POST",
    redirect: "follow",
  };

  return await fetch("/logros-management/ajax/getAllActiveUsers", requestOptions).then((response) => response.json());
}

async function loadUserListOnFilter(selectIds) {
  try {
    const userListResponse = await fetchUserList();

    if (userListResponse.status === 0) {
      createToast("error", "Error al cargar", "No se ha podido cargar la lista de usuarios", 5000);
      return;
    }

    selectIds.forEach((selectId) => {
      const selectElement = document.getElementById(selectId);
      if (!selectElement) {
        return;
      }

      const selectedAndVisibleOptions = Array.from(selectElement.options)
        .filter((option) => option.selected)
        .map((option) => option.value);

      Array.from(selectElement.options).forEach((option) => {
        if (option.dataset.temporal) {
          option.remove();
        }
      });

      userListResponse.data.forEach((user) => {
        const userOption = document.createElement("option");
        const badgeContent = `<span class="badge bg-${user.userProfileLevelBackgroundColor} text-${user.userProfileLevelTextColor}" style="font-size:12px;">${user.userProfileLevelLabel}</span>`;

        userOption.value = user.userId;
        userOption.text = user.name;
        userOption.setAttribute("data-content", `${user.name} - ${badgeContent}`);

        if (selectedAndVisibleOptions.includes(user.userId.toString())) {
          userOption.selected = true;
        }

        selectElement.add(userOption);
      });

      selectedAndVisibleOptions.forEach((value) => {
        Array.from(selectElement.options).forEach((option) => {
          if (option.value === value) option.selected = true;
        });
      });
    });
  } catch (error) {
    console.error("Error loading user list: ", error);
    createToast("error", "Error al cargar", "No se ha podido procesar la solicitud", 5000);
  }

  $(".selectpicker").selectpicker("refresh");
}

async function checkIfCreateValuesAreValid(achievementType, achievementUser, achievementObtainedAt, achievementReason) {
  if (achievementType == "") {
    createToast("warning", "Error al otorgar", "Debes seleccionar un tipo de logro", 5000);
    return false;
  }

  if (achievementUser.length == 0) {
    createToast("warning", "Error al otorgar", "Debes seleccionar al menos un usuario", 5000);
    return false;
  }

  if (achievementObtainedAt == "") {
    createToast("warning", "Error al otorgar", "Debes seleccionar una fecha", 5000);
    return false;
  }

  if (achievementReason == "" || achievementReason.length < 10) {
    createToast("warning", "Error al otorgar", "Debes ingresar una razón de al menos 10 caracteres", 5000);
    return false;
  }

  return true;
}

async function checkIfUpdateValuesAreValid(achievementObtainedAt, achievementReason) {
  if (achievementObtainedAt == "") {
    createToast("warning", "Error al editar", "Debes seleccionar una fecha", 5000);
    return false;
  }

  if (achievementReason == "" || achievementReason.length < 10) {
    createToast("warning", "Error al editar", "Debes ingresar una razón de al menos 10 caracteres", 5000);
    return false;
  }

  return true;
}

async function openAchievementDataToCreateModal(modalCreateUserList, userId = null) {
  const modalId = "modal-achievement-management-create";

  const select = document.getElementById(modalCreateUserList);
  Array.from(select.options).forEach((option) => (option.selected = false));

  if (userId) {
    const select = document.getElementById(modalCreateUserList);
    const option = select.querySelector(`option[value="${userId}"]`);

    if (option) {
      option.selected = true;
    }
  }

  $(".selectpicker").selectpicker("refresh");

  $("#" + modalId).modal("show");
}

function addEventListenerToCreateModal() {
  const modalId = "modal-achievement-management-create";

  const modalCreateType = "achievement-management-create-type";
  const modalCreateUser = "achievement-management-create-user-list";
  const modalCreateObtainedAt = "achievement-management-create-obtained-at";
  const modalCreateReason = "achievement-management-create-reason";

  const modalCreateSaveBtn = "achievement-management-create-save-btn";
  document.getElementById(modalCreateSaveBtn).addEventListener("click", function () {
    showLoadingButton(modalCreateSaveBtn, "btn-barymont-red");

    const { achievementType, achievementUser, achievementObtainedAt, achievementReason } = getCreateModalData(modalCreateType, modalCreateUser, modalCreateObtainedAt, modalCreateReason);

    checkIfCreateValuesAreValid(achievementType, achievementUser, achievementObtainedAt, achievementReason).then((isValid) => {
      if (!isValid) {
        updateButtonLabel(modalCreateSaveBtn, `<i class="fas fa-save mr-2"></i> Otorgar logro`, "btn-barymont-red");
        return;
      }

      fetchSaveNewAchievementData(achievementType, achievementUser, achievementObtainedAt, achievementReason).then((achievementData) => {
        if (achievementData.status) {
          createToast("success", "Logro otorgado", "Se ha otorgado el logro", 5000);
          updateButtonLabel(modalCreateSaveBtn, `<i class="fas fa-save mr-2"></i> Otorgar logro`, "btn-barymont-red");

          $("#" + modalId).modal("hide");
          $("#" + dataTableId)
            .DataTable()
            .ajax.reload();

          resetCreateModalData(modalCreateType, modalCreateUser, modalCreateObtainedAt, modalCreateReason);
          return;
        }

        createToast("warning", "Error al otorgar", "No se ha podido otorgar el logro, revisa los campos", 5000);
      });
    });
  });
}

function resetCreateModalData(modalCreateType, modalCreateUser, modalCreateObtainedAt, modalCreateReason) {
  document.getElementById(modalCreateType).value = "";
  document.getElementById(modalCreateUser).value = "";
  document.getElementById(modalCreateObtainedAt).value = "";
  document.getElementById(modalCreateReason).value = "";
  $(".selectpicker").selectpicker("refresh");
}

function getCreateModalData(modalCreateType, modalCreateUser, modalCreateObtainedAt, modalCreateReason) {
  const achievementType = document.getElementById(modalCreateType).value;
  const achievementUser = Array.from(document.getElementById(modalCreateUser).selectedOptions).map((option) => option.value);
  const achievementObtainedAt = document.getElementById(modalCreateObtainedAt).value;
  const achievementReason = document.getElementById(modalCreateReason).value;

  return { achievementType, achievementUser, achievementObtainedAt, achievementReason };
}

async function loadAchievementDataToEditModal(achievementId, buttonId, modalEditId, editModalType, editModalQuantity, editModalUser, editModalObtainedAt, editModalReason, saveBtnId, deleteBtnId, modalDeleteId, confirmDeleteBtnId) {
  if (document.getElementById(buttonId)) {
    showLoadingButton(buttonId, "btn-barymont-red", true);
  }

  fetchAchievementData(achievementId).then((achievementData) => {
    if (achievementData.status == 0) {
      createToast("error", "Error al cargar", "No se ha podido cargar la información del logro", 5000);
      return;
    }

    document.getElementById(editModalType).value = achievementData.data.type.value;
    document.getElementById(editModalType).disabled = true;

    document.getElementById(editModalQuantity).innerHTML = achievementData.data.quantity;
    document.getElementById(editModalQuantity).disabled = true;

    document.getElementById(editModalUser).value = achievementData.data.userName;
    document.getElementById(editModalUser).disabled = true;

    document.getElementById(editModalObtainedAt).value = achievementData.data.obtainedAt;

    document.getElementById(editModalReason).value = achievementData.data.reason;

    $(".selectpicker").selectpicker("refresh");
    $("#" + modalEditId).modal("show");
    if (document.getElementById(buttonId)) {
      updateButtonLabel(buttonId, `<i class="fas fa-info"></i>`, "btn-barymont-red");
    }

    const saveBtn = document.getElementById(saveBtnId);
    if (saveBtn !== null) {
      const saveBtnClone = saveBtn.cloneNode(true);
      saveBtn.parentNode.replaceChild(saveBtnClone, saveBtn);

      saveBtnClone.addEventListener("click", function () {
        const achievementObtainedAt = document.getElementById(editModalObtainedAt).value;
        const achievementReason = document.getElementById(editModalReason).value;

        checkIfUpdateValuesAreValid(achievementObtainedAt, achievementReason).then((isValid) => {
          if (!isValid) {
            return;
          }

          showLoadingButton(saveBtnId, "btn-barymont-red");

          fetchEditAchievementData(achievementId, achievementObtainedAt, achievementReason).then((achievementData) => {
            if (achievementData.status) {
              createToast("success", "Logro editado", "Se ha editado la información del logro", 5000);
              updateButtonLabel(saveBtnId, `<i class="fas fa-save"></i> Guardar`, "btn-barymont-red");

              $("#" + modalEditId).modal("hide");
              $("#" + dataTableId)
                .DataTable()
                .ajax.reload();
              return;
            }

            createToast("error", "Error al editar", "No se ha podido editar la información del logro", 5000);
          });
        });
      });
    }

    document.getElementById(deleteBtnId)?.addEventListener("click", function () {
      $("#" + modalDeleteId).modal("show");

      const confirmDeleteBtn = document.getElementById(confirmDeleteBtnId);
      const confirmDeleteBtnClone = confirmDeleteBtn.cloneNode(true);
      confirmDeleteBtn.parentNode.replaceChild(confirmDeleteBtnClone, confirmDeleteBtn);

      confirmDeleteBtnClone.addEventListener("click", function () {
        showLoadingButton(confirmDeleteBtnId, "btn-barymont-red");

        fetchDeleteAchievementData(achievementId).then((achievementData) => {
          if (achievementData.status) {
            createToast("success", "Logro eliminado", "Se ha eliminado el logro", 5000);
            updateButtonLabel(confirmDeleteBtnId, `<i class="fas fa-trash"></i> Eliminar`, "btn-barymont-red");

            $("#" + modalDeleteId).modal("hide");
            $("#" + modalEditId).modal("hide");
            $("#" + dataTableId)
              .DataTable()
              .ajax.reload();
            return;
          }

          createToast("error", "Error al eliminar", "No se ha podido eliminar el logro", 5000);
        });
      });
    });
  });
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname === "/herramientas/logros-management") {
      $(".triggerDatatableUpdateAchievementManagement").change(function () {
        $("#" + dataTableId)
          .DataTable()
          .ajax.reload();
      });

      const params = new URLSearchParams(document.location.search);

      window.history.replaceState({}, document.title, window.location.pathname);

      const achievementIdByGet = params.get("achievementId");
      const modeByGet = params.get("mode");

      const modalEditId = "modal-achievement-management-edit";

      const editModalType = "achievement-management-edit-type";
      const editModalQuantity = "achievement-management-edit-quantity";
      const editModalUser = "achievement-management-edit-user";
      const editModalObtainedAt = "achievement-management-edit-obtained-at";
      const editModalReason = "achievement-management-edit-reason";

      const saveBtnId = "achievement-management-edit-save-btn";
      const deleteBtnId = "achievement-management-edit-delete-btn";

      const modalDeleteId = "modal-achievement-management-delete";
      const confirmDeleteBtnId = "modal-achievement-management-delete-button";
      addEventListenerToCreateModal();

      const modalCreateUserList = "achievement-management-create-user-list";
      const filterAchievelemtUserList = "achievement-user-list-filter";

      document.getElementById("achievement-management-container").addEventListener("click", function (event) {
        const achievementId = event.target.getAttribute("data-achievement-uuid");

        if (event.target.matches(".edit-achievement-btn-to-open-edit-modal")) {
          const buttonId = "edit-achievement-" + achievementId;

          loadAchievementDataToEditModal(achievementId, buttonId, modalEditId, editModalType, editModalQuantity, editModalUser, editModalObtainedAt, editModalReason, saveBtnId, deleteBtnId, modalDeleteId, confirmDeleteBtnId);
        }
      });

      loadUserListOnFilter([modalCreateUserList, filterAchievelemtUserList]).then(() => {
        if (modeByGet === "create") {
          const userIdToCreateAchievement = params.get("userId");

          openAchievementDataToCreateModal(modalCreateUserList, userIdToCreateAchievement);
          window.history.replaceState({}, document.title, window.location.pathname);
        }

        if (modeByGet === "update" && achievementIdByGet) {
          const buttonId = "edit-achievement-" + achievementIdByGet;

          loadAchievementDataToEditModal(achievementIdByGet, buttonId, modalEditId, editModalType, editModalQuantity, editModalUser, editModalObtainedAt, editModalReason, saveBtnId, deleteBtnId, modalDeleteId, confirmDeleteBtnId);
        }

        $(".selectpicker").selectpicker("refresh");
      });

      $("#" + dataTableId).DataTable({
        language: {
          sProcessing: "Procesando Datos...",
          sLengthMenu: "Mostrar _MENU_ Logros",
          sZeroRecords: "No se encontraron logros coincidentes con el criterio de búsqueda.",
          sEmptyTable: "Ningún logro coincidente con el criterio de búsqueda.",
          sInfo: "Mostrando logros del _START_ al _END_ de un total de _TOTAL_ logros",
          sInfoEmpty: "Mostrando logros del 0 al 0 de un total de 0 logros",
          sInfoFiltered: "(Filtrados de un total de _MAX_ logros)",
          sInfoPostFix: "",
          sSearch: "<div class='input-group-prepend'><div class='input-group-text'><i class='fas fa-search'></i></div></div>",
          searchPlaceholder: "Cualquier dato del logro...",
          sUrl: "",
          sInfoThousands: ",",
          sLoadingRecords: "Cargando Datos...",
          oPaginate: {
            sFirst: "Primera",
            sLast: "Última",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
          oAria: {
            sSortAscending: ": Activar para ordenar la columna de manera ascendente",
            sSortDescending: ": Activar para ordenar la columna de manera descendente",
          },
          buttons: {
            copySuccess: {
              1: "Copiado 1 logros",
              _: "Copiados %d logros al Portapapeles",
            },
            copyTitle: "Logros Copiados al Portapapeles",
          },
          select: {
            rows: "%d Logros seleccionados",
          },
        },
        pagingType: "input",
        columnDefs: [
          {
            targets: [0, 2, 3, 4, 5, 6],
            orderable: true,
          },
          {
            targets: [1, 7],
            orderable: false,
          },
          {
            targets: [0, 1, 7],
            searchable: false,
          },
          {
            targets: [2, 3, 4, 5, 6],
            searchable: true,
          },
          {
            targets: [0, 1, 2, 4, 5, 6, 7],
            className: "dt-center",
          },
          {
            targets: [0],
            visible: false,
          },
          {
            targets: [4],
            width: "25%",
          },
        ],
        order: [[5, "desc"]],
        processing: true,
        serverSide: true,
        scrollX: true,
        dom: '<"top"fl>Brt<"bottom"ip><"clear">',
        buttons: [
          {
            extend: "colvis",
            text: "Ver más columnas",
          },
          {
            extend: "collection",
            text: "Herramientas",
            buttons: [
              {
                extend: "copyHtml5",
                text: "Copiar",
              },
              {
                extend: "print",
                text: "Imprimir",
              },
            ],
            fade: true,
          },
          {
            text: '<i class="fas fa-eraser mr-2"></i> Limpiar filtros',
            className: "btn btn-barymont-black my-2 my-md-0",
            action: function () {
              resetearFiltros();
            },
            init: function (api, node) {
              $(node).removeClass("dt-button");
            },
          },
          {
            text: '<i class="fas fa-download mr-2"></i> Exportar',
            className: "btn btn-barymont-black my-2 my-md-0",
            action: function () {
              window.open("/administracion/exportaciones?exportType=logros", "_blank");
            },
            init: function (api, node) {
              if (AppGbSession.checkUserHasPermission("Achievement:AchievementExportAccessChecker")) {
                $(node).removeClass("dt-button");
              } else {
                $(node).hide();
              }
            },
          },
          {
            text: '<i class="fas fa-plus fa-sm"></i> <i class="fas fa-medal mr-2"></i> Otorgar logro',
            className: "btn btn-barymont-red my-2 my-md-0",
            action: function () {
              openAchievementDataToCreateModal("achievement-management-create-user-list");
            },
            init: function (api, node) {
              if (AppGbSession.checkUserHasPermission("Achievement:AchievementManageAccessChecker")) {
                $(node).removeClass("dt-button");
              } else {
                $(node).hide();
              }
            },
          },
        ],
        pageLength: 10,
        lengthMenu: [
          [10, 25, 50],
          [10, 25, 50],
        ],
        select: false,
        keys: true,
        searchHighlight: true,
        ajax: {
          url: "/herramientas/logros-management/listaprocessing",
          type: "post",
          data: function (d) {
            const checkAchievementType = $("#achievement-type-filter").val();
            const checkAchievementStartDate = $("#achievement-start-date-filter").val();
            const checkAchievementFinishDate = $("#achievement-finish-date-filter").val();
            const checkAchievementUserList = $("#achievement-user-list-filter").val();

            d.achievementType = checkAchievementType != "" ? checkAchievementType : "";
            d.achievementStartDate = checkAchievementStartDate != "" ? checkAchievementStartDate : "";
            d.achievementFinishDate = checkAchievementFinishDate != "" ? checkAchievementFinishDate : "";
            d.achievementUserList = checkAchievementUserList != "" ? checkAchievementUserList : "";
          },
          error: function (e) {
            if (e.status === 401) {
              createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
              setTimeout(function () {
                location.reload();
              }, 5000);
            } else {
              createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
            }
          },
        },
        fnDrawCallback: function () {
          $('[data-toggle="tooltip"]').tooltip();
        },
        stateSave: true,
        stateSaveCallback: function (settings, data) {
          let storageKey = `${FILTER_STATE_LOCAL_STORAGE_KEY}${settings.sInstance}_${getCookieValue("user_id")}`;

          data.customFilter = getStateOfCustomFilters();

          localStorage.setItem(storageKey, JSON.stringify(data));
        },
        stateLoadCallback: function (settings) {
          let localStorageData = getLocalStorageData(settings.sInstance);

          recreateStateOfCustomFilters(localStorageData);

          return localStorageData;
        },
      });
    }
  });
}

export default {};
