"use strict";

const QUANTITY_OF_NETWORK_ACHIEVEMENTS_ON_DASHBOARD = 10;
const QUANTITY_OF_NETWORK_ACHIEVEMENTS_ON_ACHIEVEMENT_PANEL = 20;
const QUANTITY_OF_NETWORK_HISTORY_ACHIEVEMENTS_ON_ACHIEVEMENT_PANEL = 51;

const badgeSizeValidValues = ["lg", "md", "sm"];

let totalOfAchievementsQuantity = 0;

function createLoadingContainerAfterPrint(containerId) {
  document.getElementById(containerId).innerHTML = `
    <div id="${containerId}-loading" class="d-flex justify-content-center align-items-center my-5">
        <div class="spinner-border text-barymont-red" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    `;
}

function removeLoadingContainerBeforePrint(containerId) {
  document.getElementById(`${containerId}-loading`)?.remove();
}

function printToolOnMaintenance(containerId) {
  document.getElementById(containerId).innerHTML = `
    <div class="col-12 d-flex justify-content-center align-items-center p-4">
        <div class="h5 text-center">Esta herramienta se encuentra en mantenimiento, por favor intentalo de nuevo más tarde</div>
    </div>
    `;
}

function printDefaultMessageOnEmptyContainer(networkHistoryAchievementContainerId) {
  document.getElementById(networkHistoryAchievementContainerId).innerHTML = `
    <div class="col-12 d-flex justify-content-center align-items-center p-4">
        <div class="h5 text-center">No se han encontrado logros</div>
    </div>
    `;
}

const searchDelay = (callback, ms = 0) => {
  let timer = null;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => callback(), ms);
  };
};

async function fetchUserAchievements() {
  let requestOptions = {
    method: "POST",
    redirect: "follow",
  };

  return await fetch("/logros/ajax/getUserAchievements", requestOptions).then((response) => response.json());
}

async function fetchUserAchievementsByType(achievementTypeValue) {
  let formData = new FormData();

  formData.append("achievementTypeValue", achievementTypeValue);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/logros/ajax/getUserAchievementsByAchievementType", requestOptions).then((response) => response.json());
}

async function fetchNetworkAchievements(limit, offset) {
  let formData = new FormData();

  formData.append("limit", limit);

  if (offset != null) {
    formData.append("offset", offset);
  }

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/logros/ajax/getNetworkAchievements", requestOptions).then((response) => response.json());
}

async function fetchNetworkAchievementsByType(achievementTypeValue, userId) {
  let formData = new FormData();

  formData.append("achievementTypeValue", achievementTypeValue);
  formData.append("userId", userId);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/logros/ajax/getNetworkAchievementsByAchievementType", requestOptions).then((response) => response.json());
}

async function fetchAchievementNotNotified() {
  let requestOptions = {
    method: "POST",
    redirect: "follow",
  };

  return await fetch("/logros/ajax/getAchievementNotNotified", requestOptions).then((response) => response.json());
}

async function fetchNetworkHistoryAchievementsByCriteria(achievementType, userIdList, startDate, finishDate, limit, offset) {
  let formData = new FormData();

  if (achievementType != null) {
    formData.append("achievementType", achievementType);
  }

  if (userIdList != null) {
    formData.append("userIdList", userIdList);
  }

  if (startDate != null) {
    formData.append("startDate", startDate);
  }

  if (finishDate != null) {
    formData.append("finishDate", finishDate);
  }

  if (limit != null) {
    formData.append("limit", limit);
  }

  if (offset != null) {
    formData.append("offset", offset);
  }

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/logros/ajax/getNetworkHistoryAchievementsByCriteria", requestOptions).then((response) => response.json());
}

async function getCountNetworkHistoryAchievementsByCriteria(achievementType, userIdList, startDate, finishDate) {
  let formData = new FormData();

  if (achievementType != null) {
    formData.append("achievementType", achievementType);
  }

  if (userIdList != null) {
    formData.append("userIdList", userIdList);
  }

  if (startDate != null) {
    formData.append("startDate", startDate);
  }

  if (finishDate != null) {
    formData.append("finishDate", finishDate);
  }

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/logros/ajax/getCountNetworkHistoryAchievementsByCriteria", requestOptions).then((response) => response.json());
}

async function getCountRankingNetworkHistoryAchievementsByAchievementTypeGroupByUser(achievementTypeValue) {
  let formData = new FormData();

  formData.append("achievementTypeValue", achievementTypeValue);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/logros/ajax/getCountRankingNetworkHistoryAchievementsByAchievementTypeGroupByUser", requestOptions).then((response) => response.json());
}

async function fetchGroupedNetworkAchievementByAchievementType(achievementType, limit, offset) {
  let formData = new FormData();

  if (achievementType != null) {
    formData.append("achievementType", achievementType);
  }

  if (limit != null) {
    formData.append("limit", limit);
  }

  if (offset != null) {
    formData.append("offset", offset);
  }

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/logros/ajax/getNetworkAchievementByTypeAndGroupByUserAndType", requestOptions).then((response) => response.json());
}

async function fetchHigherNetworkAchievementByAchievementType() {
  let requestOptions = {
    method: "POST",
    redirect: "follow",
  };

  return await fetch("/logros/ajax/getHigherNetworkAchievementByAchievementType", requestOptions).then((response) => response.json());
}

async function fetchUserListWithSomeAchievement(searchValue) {
  let formData = new FormData();

  formData.append("searchValue", searchValue);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/logros/ajax/getActiveUsersWithAchievement", requestOptions).then((response) => response.json());
}

async function printUserAchievementsMedals(achievementMedalContainerId, prefixMedalId, achievementsData, modalId, modalHeaderId, modalHeaderImageContainerId, modalBodyId, badgeSize) {
  const achievementContainer = document.getElementById(achievementMedalContainerId);

  const achievementMedalContainer = document.createElement("div");
  achievementMedalContainer.classList.add("row", "p-3", "m-0", "justify-content-center");

  achievementContainer.appendChild(achievementMedalContainer);

  if (achievementsData.length === 0) {
    achievementMedalContainer.insertAdjacentHTML(
      "beforeend",
      `<div class="col-12">
        <div class="alert alert-light text-center" role="alert">
          <span>Todavia no has desbloqueado ningún logro</span>
        </div>
      </div>`
    );
  }

  let colSize = "";

  switch (badgeSize) {
    case "lg":
      colSize = "col-6 col-lg-4";
      break;
    case "md":
      colSize = "col-6 col-lg-4 col-xl-3";
      break;
    case "sm":
      colSize = "col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2";
      break;
  }

  achievementsData.forEach((achievement) => {
    const achievementMedal = `
      <div id="${prefixMedalId}-${achievement.id}" class="${colSize} mb-3" style="cursor: pointer;">
          <div class="achievement-container">
              <img src="${achievement.type.image}" class="img-fluid" alt="${achievement.type.value}" draggable="false">
              <span class="badge badge-pill bg-dark text-white date">${achievement.obtainedAt}</span>
              <span class="badge badge-pill bg-dark text-white amount">${achievement.quantity}</span>
              <span class="badge badge-pill name">${achievement.type.label}</span>
          </div>
      </div>
    `;

    achievementMedalContainer.insertAdjacentHTML("beforeend", achievementMedal);

    document.getElementById(`${prefixMedalId}-${achievement.id}`).addEventListener("click", () => {
      const achievementElement = document.getElementById(`${prefixMedalId}-${achievement.id}`);
      const imageElement = achievementElement.querySelector("img");

      imageElement.style.filter = "grayscale(100%)";

      achievementElement.insertAdjacentHTML(
        "beforeend",
        `
        <div id="achievement-medal-loader" class="d-flex justify-content-center align-items-center" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
            <div class="spinner-border text-barymont-red" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        `
      );

      fetchUserAchievementsByType(achievement.type.value).then((achievementsByTypeData) => {
        updateAchievementModalStyles(modalId, modalHeaderId, modalHeaderImageContainerId, modalBodyId, achievement, achievementsByTypeData.data);

        imageElement.style.filter = "";

        document.getElementById("achievement-medal-loader").remove();
      });
    });
  });
}

function updateAchievementModalStyles(modalId, modalHeaderId, modalHeaderImageContainerId, modalBodyId, achievement, achievementsByTypeData, userName, userAvatar) {
  const modalHeader = document.getElementById(modalHeaderId);
  const modalHeaderImageContainer = document.getElementById(modalHeaderImageContainerId);
  const modalBody = document.getElementById(modalBodyId);

  modalHeaderImageContainer.innerHTML = `<img class="image-flip" src="${achievement.type.image}" alt="${achievement.type.value}" style="width: 125px; height: 125px;">`;

  modalHeader.style.background = `radial-gradient(circle, ${achievement.type.secondaryColor} 40%, ${achievement.type.primaryColor} 70%)`;

  modalBody.innerHTML = "";

  const achievementDescription = `
    <div class="col-12 bg-barymont-grey p-4">
      <span class="h4">${achievement.type.label}</span> <span id="achievement-quantity" class="badge badge-pill bg-barymont-black text-white ml-2" style="font-size: 14px;"></span>
    </div>
    `;

  modalBody.insertAdjacentHTML("beforeend", achievementDescription);

  document.getElementById("achievement-quantity").innerText = `x ${achievementsByTypeData.length}`;

  if (userName != null && userAvatar != null) {
    modalBody.insertAdjacentHTML(
      "beforeend",
      `<div class="col-12 pt-4 pb-2 px-4">
          <div class="d-flex align-items-center">
              <div class="mr-2"><img src="${userAvatar}" alt="test" style="max-width: 35px; border-radius: 50%;"></div>
              <div class="h5 mb-0">${userName}</div>
          </div>
      </div>`
    );
  }

  modalBody.insertAdjacentHTML(
    "beforeend",
    `<div class="col-12 ${userName != null && userAvatar != null ? "py-2" : "pt-4 pb-2"} px-4">
        <p class="text-justify mx-2">${achievement.type.description}</p>
        <hr>
    </div>`
  );

  achievementsByTypeData.forEach((achievementByType) => {
    const achievementMedal = `
      <div class="col-12 pt-2 px-4">
          <div class="achievement-data mb-2">
              <span class="badge badge-pill bg-dark text-white mb-0 " style="font-size: 12px;">${achievementByType.obtainedAt}</span>
              <span class="mb-0 ml-1" style="line-height: 1.2;">${achievementByType.reason}</span>
          </div>
      </div>
    `;

    modalBody.insertAdjacentHTML("beforeend", achievementMedal);
  });

  $(`#${modalId}`).modal("show");
}

async function printNetworkAchievementsMedals(networkLastAchievementContainerId, networkAchievementsData, modalId, modalHeaderId, modalHeaderImageContainerId, modalBodyId, badgeSize, showButtonSeeMoreAchievements, showButtonRankingAchievements, showReason, appendRandomCustomIdToAvoidCollisions = false) {
  const networkAchievementContainer = document.getElementById(networkLastAchievementContainerId);

  let colSize = "";

  switch (badgeSize) {
    case "lg":
    case "md":
      colSize = "col-12";
      break;
    case "sm":
      colSize = "col-12 col-lg-6 col-xl-4";
      break;
  }

  if (networkAchievementContainer.querySelector(".container-fluid") == null && networkAchievementContainer.querySelector(".container-fluid .row") == null) {
    const networkAchievementDivContainer = document.createElement("div");
    networkAchievementDivContainer.classList.add("container-fluid");

    networkAchievementContainer.appendChild(networkAchievementDivContainer);

    const networkAchievementDivRow = document.createElement("div");
    networkAchievementDivRow.classList.add("row", "px-0");

    networkAchievementDivContainer.appendChild(networkAchievementDivRow);
  }

  networkAchievementsData.forEach((achievementNetwork) => {
    const customAppendId = appendRandomCustomIdToAvoidCollisions ? `${Math.random().toString(36).substring(2, 15)}-` : "";

    const achievementMedal = `
        <div class="${colSize}">
          <div id="${customAppendId}network-achievement-${achievementNetwork.id}" class="achievement-network-container">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12 col-sm-3 d-flex align-items-center justify-content-center">
                  <div class="achievement-container my-3">
                    <img src="${achievementNetwork.type.image}" class="img-fluid image-zoom" alt="${achievementNetwork.type.value}" draggable="false" style="max-width: 75px; width: 100%">
                    <span class="badge badge-pill bg-dark text-white date">${achievementNetwork.obtainedAt}</span>
                    <span class="badge badge-pill bg-dark text-white amount">${achievementNetwork.quantity}</span>
                  </div>
                </div>
                <div class="col-12 col-sm-9 my-3 position-relative" style="min-height:70px;">
                  <div class="position-absolute" style="top: -5px; right: 7px;"><img src="${achievementNetwork.avatar}" alt="test" style="max-width: 30px; border-radius: 50%;"></div>
                  <div class="position-absolute" style="bottom: -12px; right: 7px; font-size: 12px; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical;">${achievementNetwork.userName}</div>
                  <div class="h4 mb-2 mb-md-2 mb-lg-0 font-weight-bold text-left">${achievementNetwork.type.label}</div>
                  ${showReason ? `<div class="text-left" style="font-size: 12px; line-height: 1.2; height: 2.4em; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;">${achievementNetwork.reason}</div>` : ""}
                </div>
              </div>
            </div>
          </div>
        </div>`;

    networkAchievementContainer.querySelector(".container-fluid .row").insertAdjacentHTML("beforeend", achievementMedal);

    document.getElementById(`${customAppendId}network-achievement-${achievementNetwork.id}`).addEventListener("click", () => {
      const achievementElement = document.getElementById(`${customAppendId}network-achievement-${achievementNetwork.id}`);
      const imageElement = achievementElement.querySelector("img");

      imageElement.style.filter = "grayscale(100%)";

      achievementElement.insertAdjacentHTML(
        "beforeend",
        `
        <div id="achievement-medal-loader" class="d-flex justify-content-center align-items-center" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
            <div class="spinner-border text-barymont-red" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        `
      );

      fetchNetworkAchievementsByType(achievementNetwork.type.value, achievementNetwork.userId).then((achievementsByTypeData) => {
        updateAchievementModalStyles(modalId, modalHeaderId, modalHeaderImageContainerId, modalBodyId, achievementNetwork, achievementsByTypeData.data, achievementNetwork.userName, achievementNetwork.avatar);

        imageElement.style.filter = "";

        document.getElementById("achievement-medal-loader").remove();
      });
    });
  });

  if (showButtonSeeMoreAchievements || showButtonRankingAchievements) {
    networkAchievementContainer.insertAdjacentHTML(
      "beforeend",
      `<div class="p-2" style="position: sticky; bottom: 0; display: flex; justify-content: center; align-items: center;">
        ${showButtonSeeMoreAchievements ? '<a href="/logros/ultimos-logros" class="btn btn-lg btn-barymont-grey w-100 mx-2" style="flex-grow: 1; text-align: center;"><i class="fas fa-medal pr-2 text-dark d-none d-sm-inline"></i>Ver más logros</a>' : ""}
        ${showButtonRankingAchievements ? '<a href="/logros/ranking-logros" class="btn btn-lg btn-barymont-grey w-100 mx-2" style="flex-grow: 1; text-align: center;"><i class="fas fa-trophy pr-2 text-dark d-none d-sm-inline"></i> Ranking de logros</a>' : ""}
      </div>`
    );
  }
}

async function loadUserAchievements(achievementMedalContainerId, prefixMedalId, modalMedalId, modalMedalHeaderId, modalMedalHeaderImageContainerId, modalMedalBodyId, badgeSize) {
  createLoadingContainerAfterPrint(achievementMedalContainerId);

  if (!badgeSizeValidValues.includes(badgeSize)) {
    badgeSize = "md";
  }

  fetchUserAchievements().then((achievementsData) => {
    if (achievementsData.status) {
      printUserAchievementsMedals(achievementMedalContainerId, prefixMedalId, achievementsData.data, modalMedalId, modalMedalHeaderId, modalMedalHeaderImageContainerId, modalMedalBodyId, badgeSize);
      removeLoadingContainerBeforePrint(achievementMedalContainerId);
    } else {
      printToolOnMaintenance(achievementMedalContainerId);
    }
  });
}

async function loadNetworkAchievements(networkLastAchievementContainerId, modalId, modalHeaderId, modalHeaderImageContainerId, modalBodyId, quantity, badgeSize, shorLoadMoreButton, showRankingButton) {
  createLoadingContainerAfterPrint(networkLastAchievementContainerId);

  if (!badgeSizeValidValues.includes(badgeSize)) {
    badgeSize = "md";
  }

  fetchNetworkAchievements(quantity).then((netwoerkAchievementsData) => {
    if (netwoerkAchievementsData.status) {
      printNetworkAchievementsMedals(networkLastAchievementContainerId, netwoerkAchievementsData.data, modalId, modalHeaderId, modalHeaderImageContainerId, modalBodyId, badgeSize, shorLoadMoreButton, showRankingButton, true);
      removeLoadingContainerBeforePrint(networkLastAchievementContainerId);
    } else {
      printToolOnMaintenance(networkLastAchievementContainerId);
    }
  });
}

async function loadNetworkHistoryAchievementsWithLoadMoreOption(networkHistoryAchievementContainerId, achievementType, achievementUsers, achievementStartDate, achievementFinishDate, modalId, modalHeaderId, modalHeaderImageContainerId, modalBodyId, quantity, badgeSize) {
  if (!document.getElementById(networkHistoryAchievementContainerId).hasChildNodes()) {
    createLoadingContainerAfterPrint(networkHistoryAchievementContainerId);
  }

  if (!badgeSizeValidValues.includes(badgeSize)) {
    badgeSize = "md";
  }

  const achievementsPrinted = document.getElementById(networkHistoryAchievementContainerId).querySelectorAll("[id^='network-achievement-']").length;

  fetchNetworkHistoryAchievementsByCriteria(achievementType, achievementUsers, achievementStartDate, achievementFinishDate, quantity, achievementsPrinted).then((networkAchievementsData) => {
    if (networkAchievementsData.status) {
      if (networkAchievementsData.data.length === 0) {
        printDefaultMessageOnEmptyContainer(networkHistoryAchievementContainerId);
      }

      printNetworkAchievementsMedals(networkHistoryAchievementContainerId, networkAchievementsData.data, modalId, modalHeaderId, modalHeaderImageContainerId, modalBodyId, badgeSize, false, false, true).then(() => {
        removeLoadMoreButton();

        const currentAchievementsPrinted = document.getElementById(networkHistoryAchievementContainerId).querySelectorAll("[id^='network-achievement-']").length;

        if (totalOfAchievementsQuantity > currentAchievementsPrinted) {
          const functionToLoadMoreAchievements = () => loadNetworkHistoryAchievementsWithLoadMoreOption(networkHistoryAchievementContainerId, achievementType, achievementUsers, achievementStartDate, achievementFinishDate, modalId, modalHeaderId, modalHeaderImageContainerId, modalBodyId, quantity, badgeSize);
          createLoadMoreButton(functionToLoadMoreAchievements, networkHistoryAchievementContainerId);
        }
      });

      removeLoadingContainerBeforePrint(networkHistoryAchievementContainerId);
    } else {
      printToolOnMaintenance(networkHistoryAchievementContainerId);
    }
  });
}

async function loadRankingNetworkHistoryAchievementsWithLoadMoreOption(networkHistoryAchievementContainerId, networkHistoryAchievementTypeSelectId, modalId, modalHeaderId, modalHeaderImageContainerId, modalBodyId, quantity, badgeSize) {
  if (!document.getElementById(networkHistoryAchievementContainerId).hasChildNodes()) {
    createLoadingContainerAfterPrint(networkHistoryAchievementContainerId);
  }

  if (!badgeSizeValidValues.includes(badgeSize)) {
    badgeSize = "md";
  }

  const networkHistoryAchievementTypeSelect = document.getElementById(networkHistoryAchievementTypeSelectId).value != "" ? document.getElementById(networkHistoryAchievementTypeSelectId).value : null;

  const achievementsPrinted = document.getElementById(networkHistoryAchievementContainerId).querySelectorAll("[id^='network-achievement-']").length;

  fetchGroupedNetworkAchievementByAchievementType(networkHistoryAchievementTypeSelect, quantity, achievementsPrinted).then((netwoerkAchievementsData) => {
    if (netwoerkAchievementsData.status) {
      if (netwoerkAchievementsData.data.length === 0) {
        printDefaultMessageOnEmptyContainer(networkHistoryAchievementContainerId);
      }

      printNetworkAchievementsMedals(networkHistoryAchievementContainerId, netwoerkAchievementsData.data, modalId, modalHeaderId, modalHeaderImageContainerId, modalBodyId, badgeSize, false, false, false).then(() => {
        removeLoadMoreButton();

        const currentAchievementsPrinted = document.getElementById(networkHistoryAchievementContainerId).querySelectorAll("[id^='network-achievement-']").length;

        if (totalOfAchievementsQuantity > currentAchievementsPrinted) {
          const functionToLoadMoreAchievements = () => loadRankingNetworkHistoryAchievementsWithLoadMoreOption(networkHistoryAchievementContainerId, networkHistoryAchievementTypeSelectId, modalId, modalHeaderId, modalHeaderImageContainerId, modalBodyId, quantity, badgeSize);
          createLoadMoreButton(functionToLoadMoreAchievements, networkHistoryAchievementContainerId);
        }
      });

      removeLoadingContainerBeforePrint(networkHistoryAchievementContainerId);
    } else {
      printToolOnMaintenance(networkHistoryAchievementContainerId);
    }
  });
}

async function loadRankingHigherNetworkHistoryAchievements(networkHistoryAchievementContainerId, networkHistoryAchievementTypeSelectId, modalId, modalHeaderId, modalHeaderImageContainerId, modalBodyId, quantity, badgeSize) {
  if (!document.getElementById(networkHistoryAchievementContainerId).hasChildNodes()) {
    createLoadingContainerAfterPrint(networkHistoryAchievementContainerId);
  }

  if (!badgeSizeValidValues.includes(badgeSize)) {
    badgeSize = "md";
  }

  fetchHigherNetworkAchievementByAchievementType().then((netwoerkAchievementsData) => {
    if (netwoerkAchievementsData.status) {
      printNetworkAchievementsMedals(networkHistoryAchievementContainerId, netwoerkAchievementsData.data, modalId, modalHeaderId, modalHeaderImageContainerId, modalBodyId, badgeSize, false, false, false).then(() => {
        removeLoadingContainerBeforePrint(networkHistoryAchievementContainerId);
      });
    } else {
      printToolOnMaintenance(networkHistoryAchievementContainerId);
    }
  });
}

async function loadUserWithAchievementListOnFilter(selectId, selectLabelId) {
  const searchValue = document.querySelector("div.bs-searchbox > input").value;
  const select = document.getElementById(selectId);
  const selectLabel = document.getElementById(selectLabelId);

  for (let i = select.options.length - 1; i >= 0; i--) {
    if (!select.options[i].selected) {
      select.remove(i);
    }
  }

  if (searchValue.length < 3) {
    if (document.getElementsByClassName("no-results")[0]) {
      document.getElementsByClassName("no-results")[0].textContent = "Escribe al menos 3 caracteres";
    }

    $(`#${selectId}`).selectpicker("refresh");
    return;
  }

  if (document.getElementsByClassName("no-results")[0]) {
    document.getElementsByClassName("no-results")[0].textContent = `No se encontraron resultados con "${searchValue}"`;
  }

  select.disabled = true;
  selectLabel.innerHTML = "<span class='spinner-border spinner-border-sm mr-1' role='status' aria-hidden='true'></span>";

  try {
    const userData = await fetchUserListWithSomeAchievement(searchValue);
    if (userData.status == 0) {
      createToast("warning", "Error al cargar", "No se ha podido cargar la lista de usuarios", 5000);
      return;
    }

    const existingValues = Array.from(select.options).map((opt) => opt.value);

    userData.data.forEach((user) => {
      if (!existingValues.includes(user.userId.toString())) {
        const option = document.createElement("option");
        option.value = user.userId;
        option.text = user.name;
        select.appendChild(option);
      }
    });
  } catch (error) {
    console.error("Error cargando la lista de usuarios: ", error);
    createToast("error", "Error al cargar", "Ocurrió un error al cargar los datos", 5000);
  } finally {
    select.disabled = false;
    selectLabel.innerHTML = "Usuarios";
    $(`#${selectId}`).selectpicker("refresh");
  }
}

function createLoadMoreButton(callbackToLoadMore, networkHistoryAchievementContainerId) {
  const loadMoreButton = document.createElement("div");
  loadMoreButton.classList.add("col-12", "order-12", "d-flex", "justify-content-center", "align-items-center", "p-4");
  loadMoreButton.id = "load-more-button-container";

  loadMoreButton.innerHTML = `<button id="load-more-button" class="btn btn-lg btn-barymont-grey">Cargar más</button>`;

  document.getElementById(networkHistoryAchievementContainerId).children[0].appendChild(loadMoreButton);

  document.getElementById("load-more-button").addEventListener("click", () => {
    document.getElementById("load-more-button").disabled = true;
    document.getElementById("load-more-button").innerHTML = `<span class="spinner-border spinner-border" role="status" aria-hidden="true"></span>`;

    callbackToLoadMore();
  });
}

function removeLoadMoreButton() {
  document.getElementById("load-more-button-container")?.remove();
}

function UILoadAchievementObtainedModal(modalId, prefixMedalId, modalContainerId, modalMedalId, modalMedalHeaderId, modalMedalHeaderImageContainerId, modalMedalBodyId) {
  const canvas = document.querySelector("canvas");

  const jsConfetti = new JSConfetti({ canvas });

  fetchAchievementNotNotified().then((achievementResponse) => {
    if (achievementResponse.status) {
      if (achievementResponse.data.length === 0) {
        return;
      }

      printUserAchievementsMedals(modalContainerId, prefixMedalId, achievementResponse.data, modalMedalId, modalMedalHeaderId, modalMedalHeaderImageContainerId, modalMedalBodyId, "md").then(() => {
        $(`#${modalId}`).modal("show");

        $(`#${modalId}`).on("shown.bs.modal", function () {
          jsConfetti.addConfetti({
            confettiColors: ["#9b1233", "#66041a", "#66041a", "#151824", "#c18795", "#eeeeee"],
          });
        });
      });
    }
  });
}

function getDataOfNetworkHistoryAchievementFilters(achievementTypeSelectId, achievementUserFilterId, achievementStartDateFilterId, achievementFinishDateFilterId) {
  const achievementType = document.getElementById(achievementTypeSelectId).value;
  const achievementUsers = Array.from(document.getElementById(achievementUserFilterId).selectedOptions).map((option) => option.value);
  const achievementStartDate = document.getElementById(achievementStartDateFilterId).value;
  const achievementFinishDate = document.getElementById(achievementFinishDateFilterId).value;

  return { achievementType, achievementUsers, achievementStartDate, achievementFinishDate };
}

function resetDataOfNetworkHistoryAchievementFilters(networkHistoryAchievementContainerId, achievementTypeSelectId, achievementUserFilterId, achievementStartDateFilterId, achievementFinishDateFilterId) {
  document.getElementById(achievementTypeSelectId).value = "";
  document.getElementById(achievementUserFilterId).value = "";
  document.getElementById(achievementStartDateFilterId).value = "";
  document.getElementById(achievementFinishDateFilterId).value = "";

  $(".selectpicker").selectpicker("refresh");

  document.getElementById(networkHistoryAchievementContainerId).innerHTML = "";
  createLoadingContainerAfterPrint(networkHistoryAchievementContainerId);

  return { achievementType: null, achievementUsers: null, achievementStartDate: null, achievementFinishDate: null };
}

if (window) {
  window.addEventListener("load", () => {
    const modalMedalId = "modal-achievement-data";
    const modalMedalHeaderId = "modal-achievement-header-data";
    const modalMedalHeaderImageContainerId = "modal-achievement-header-data-image-container";
    const modalMedalBodyId = "modal-achievement-body-data";

    const achievementMedalId = "achievement-id";

    if (window.location.pathname === "/dashboard" && AppGbSession.checkIfSubscriptionIsActive()) {
      document.getElementById("modal-achievement-data").addEventListener("shown.bs.modal", function () {
        setTimeout(() => (document.querySelector(".image-flip").style.animation = "flip 1.2s ease-in-out forwards"), 1000);
      });

      const achievementMedalContainerId = "logros";

      loadUserAchievements(achievementMedalContainerId, achievementMedalId, modalMedalId, modalMedalHeaderId, modalMedalHeaderImageContainerId, modalMedalBodyId, "md");

      const networkLastAchievementContainerId = "ultimos-logros";

      loadNetworkAchievements(networkLastAchievementContainerId, modalMedalId, modalMedalHeaderId, modalMedalHeaderImageContainerId, modalMedalBodyId, QUANTITY_OF_NETWORK_ACHIEVEMENTS_ON_DASHBOARD, "md", true, true);

      const achievementObtainedModalId = "achievement-obtained-modal";
      const achievementObtainedModalContainerId = "achievement-obtained-modal-container";
      const newAchievementMedalId = "new-achievement-id";

      UILoadAchievementObtainedModal(achievementObtainedModalId, newAchievementMedalId, achievementObtainedModalContainerId, modalMedalId, modalMedalHeaderId, modalMedalHeaderImageContainerId, modalMedalBodyId);
    }

    if (window.location.pathname === "/logros") {
      const userAchievementContainerId = "user-achievement-container";

      loadUserAchievements(userAchievementContainerId, achievementMedalId, modalMedalId, modalMedalHeaderId, modalMedalHeaderImageContainerId, modalMedalBodyId, "sm");

      const networkAchievementContainerId = "network-achievement-container";

      loadNetworkAchievements(networkAchievementContainerId, modalMedalId, modalMedalHeaderId, modalMedalHeaderImageContainerId, modalMedalBodyId, QUANTITY_OF_NETWORK_ACHIEVEMENTS_ON_ACHIEVEMENT_PANEL, "md", true, false);
    }

    if (window.location.pathname === "/logros/ultimos-logros") {
      const networkHistoryAchievementContainerId = "network-history-achievement-container";

      const networkHistoryAchievementTypeSelectId = "network-history-achievement-type-selected-filter";
      const rankingAchievementUserFilterId = "ranking-achievement-user-filter";
      const rankingAchievementUserFilterLabelId = "ranking-achievement-user-filter-label";
      const rankingAchievementStartDateFilterId = "ranking-achievement-start-date-filter";
      const rankingAchievementFinishDateFilterId = "ranking-achievement-finish-date-filter";

      const debouncedSearchPastRankings = searchDelay(() => {
        loadUserWithAchievementListOnFilter(rankingAchievementUserFilterId, rankingAchievementUserFilterLabelId);
      }, 500);

      document
        .getElementById("ranking-achievement-user-filter")
        .parentNode.querySelector(".bs-searchbox input")
        ?.addEventListener("input", function () {
          debouncedSearchPastRankings();
        });

      loadNetworkHistoryAchievementsWithLoadMoreOption(networkHistoryAchievementContainerId, null, null, null, null, modalMedalId, modalMedalHeaderId, modalMedalHeaderImageContainerId, modalMedalBodyId, QUANTITY_OF_NETWORK_HISTORY_ACHIEVEMENTS_ON_ACHIEVEMENT_PANEL, "sm");

      document.querySelectorAll("input.triggerFilterUpdateHistoryAchievement, select.triggerFilterUpdateHistoryAchievement").forEach(function (element) {
        element.addEventListener("change", function () {
          document.getElementById(networkHistoryAchievementContainerId).innerHTML = "";

          const { achievementType, achievementUsers, achievementStartDate, achievementFinishDate } = getDataOfNetworkHistoryAchievementFilters(networkHistoryAchievementTypeSelectId, rankingAchievementUserFilterId, rankingAchievementStartDateFilterId, rankingAchievementFinishDateFilterId);
          getCountNetworkHistoryAchievementsByCriteria(achievementType, achievementUsers, achievementStartDate, achievementFinishDate).then((response) => {
            totalOfAchievementsQuantity = response.status ? response.data : 0;
            loadNetworkHistoryAchievementsWithLoadMoreOption(networkHistoryAchievementContainerId, achievementType, achievementUsers, achievementStartDate, achievementFinishDate, modalMedalId, modalMedalHeaderId, modalMedalHeaderImageContainerId, modalMedalBodyId, QUANTITY_OF_NETWORK_HISTORY_ACHIEVEMENTS_ON_ACHIEVEMENT_PANEL, "sm");
          });
        });
      });

      const clearFiltersButtonId = "ranking-achievement-clear-filters";

      document.getElementById(clearFiltersButtonId).addEventListener("click", () => {
        resetDataOfNetworkHistoryAchievementFilters(networkHistoryAchievementContainerId, networkHistoryAchievementTypeSelectId, rankingAchievementUserFilterId, rankingAchievementStartDateFilterId, rankingAchievementFinishDateFilterId);

        const { achievementType, achievementUsers, achievementStartDate, achievementFinishDate } = getDataOfNetworkHistoryAchievementFilters(networkHistoryAchievementTypeSelectId, rankingAchievementUserFilterId, rankingAchievementStartDateFilterId, rankingAchievementFinishDateFilterId);

        getCountNetworkHistoryAchievementsByCriteria(achievementType, achievementUsers, achievementStartDate, achievementFinishDate).then((response) => {
          totalOfAchievementsQuantity = response.status ? response.data : 0;
          loadNetworkHistoryAchievementsWithLoadMoreOption(networkHistoryAchievementContainerId, achievementType, achievementUsers, achievementStartDate, achievementFinishDate, modalMedalId, modalMedalHeaderId, modalMedalHeaderImageContainerId, modalMedalBodyId, QUANTITY_OF_NETWORK_HISTORY_ACHIEVEMENTS_ON_ACHIEVEMENT_PANEL, "sm");
        });
      });
    }

    if (window.location.pathname === "/logros/ranking-logros") {
      const rankingHistoryAchievementContainerId = "ranking-history-achievement-container";
      const rankingHistoryAchievementTypeSelectId = "ranking-history-achievement-type-selected";

      document.getElementById(rankingHistoryAchievementTypeSelectId).addEventListener("change", () => {
        document.getElementById(rankingHistoryAchievementContainerId).innerHTML = "";

        if (document.getElementById(rankingHistoryAchievementTypeSelectId).value == "") {
          totalOfAchievementsQuantity = 0;
          loadRankingHigherNetworkHistoryAchievements(rankingHistoryAchievementContainerId, rankingHistoryAchievementTypeSelectId, modalMedalId, modalMedalHeaderId, modalMedalHeaderImageContainerId, modalMedalBodyId, QUANTITY_OF_NETWORK_HISTORY_ACHIEVEMENTS_ON_ACHIEVEMENT_PANEL, "sm");
        } else {
          getCountRankingNetworkHistoryAchievementsByAchievementTypeGroupByUser(document.getElementById(rankingHistoryAchievementTypeSelectId).value).then((response) => {
            totalOfAchievementsQuantity = response.status ? response.data : 0;
            loadRankingNetworkHistoryAchievementsWithLoadMoreOption(rankingHistoryAchievementContainerId, rankingHistoryAchievementTypeSelectId, modalMedalId, modalMedalHeaderId, modalMedalHeaderImageContainerId, modalMedalBodyId, QUANTITY_OF_NETWORK_HISTORY_ACHIEVEMENTS_ON_ACHIEVEMENT_PANEL, "sm");
          });
        }
      });

      document.getElementById(rankingHistoryAchievementTypeSelectId).dispatchEvent(new Event("change"));
    }
  });
}

export default {};
