"use strict";

var FILTER_STATE_LOCAL_STORAGE_KEY = "NEWS_FILTER_STATE_";
var FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME = 1000 * 60 * 20; // 20 minutes in milliseconds

let categoriesData = null;

function getStateOfCustomFilters() {
  return {
    selectCategories: $("#id-category").val(),
    selectDateFrom: $("#news-published-date-from").val(),
    selectDateTo: $("#news-published-date-to").val(),
  };
}

function recreateStateOfCustomFilters(localStorageData) {
  if (localStorageData.customFilter === undefined) {
    return false;
  }

  $("#id-category").val(localStorageData.customFilter.selectCategories);
  $("#news-published-date-from").val(localStorageData.customFilter.selectDateFrom);
  $("#news-published-date-to").val(localStorageData.customFilter.selectDateTo);
}

function resetearFiltros(datatableId) {
  $("#id-category").val("").selectpicker("refresh");
  $("#news-published-date-from").val("");
  $("#news-published-date-to").val("");

  $("#" + datatableId)
    .DataTable()
    .search("")
    .draw();
  $("#" + datatableId)
    .DataTable()
    .ajax.reload();
}

function loadNewsDatatable(datatableId) {
  if ($.fn.DataTable.isDataTable("#" + datatableId)) {
    $("#" + datatableId)
      .DataTable()
      .destroy();
    $("#" + datatableId).empty();
  }

  $("#" + datatableId).DataTable({
    language: {
      sProcessing: "Procesando Datos...",
      sLengthMenu: "Mostrar _MENU_ Noticias",
      sZeroRecords: "No se encontraron noticias coincidentes con el criterio de búsqueda.",
      sEmptyTable: "Ninguna noticia coincidente con el criterio de búsqueda.",
      sInfo: "Mostrando noticias del _START_ al _END_ de un total de _TOTAL_ noticias",
      sInfoEmpty: "Mostrando noticias del 0 al 0 de un total de 0 noticias",
      sInfoFiltered: "(Filtrados de un total de _MAX_ noticias)",
      sInfoPostFix: "",
      sSearch: "<div class='input-group-prepend'><div class='input-group-text'><i class='fas fa-search'></i></div></div>",
      searchPlaceholder: "Datos de las noticias...",
      sUrl: "",
      sInfoThousands: ",",
      sLoadingRecords: "Cargando Datos...",
      oPaginate: {
        sFirst: "Primera",
        sLast: "Última",
        sNext: "Siguiente",
        sPrevious: "Anterior",
      },
      oAria: {
        sSortAscending: ": Activar para ordenar la columna de manera ascendente",
        sSortDescending: ": Activar para ordenar la columna de manera descendente",
      },
    },
    pagingType: "input",
    columnDefs: [
      {
        targets: [0, 2, 3, 4, 5, 6, 7, 8],
        className: "dt-center",
      },
      {
        targets: [0],
        orderable: true,
      },
      {
        targets: [1, 2, 3, 4, 5, 6, 7, 8],
        orderable: false,
      },
    ],
    order: [[0, "desc"]],
    processing: true,
    serverSide: true,
    scrollX: true,
    dom: '<"top"fl>Brt<"bottom"ip><"clear">',
    buttons: [
      {
        extend: "colvis",
        text: "Ver más columnas",
      },
      {
        text: '<i class="fas fa-eraser mr-2"></i> Limpiar filtros',
        className: "btn btn-barymont-black my-2 my-md-0",
        action: function () {
          resetearFiltros(datatableId);
        },
        init: function (api, node) {
          $(node).removeClass("dt-button");
        },
      },
      {
        text: '<i class="fas fa-newspaper mr-2"></i> Crear nueva noticia',
        className: "btn btn-barymont-red my-2 my-md-0",
        action: function () {
          window.location.href = "/herramientas/noticia";
        },
        init: function (api, node) {
          $(node).removeClass("dt-button");
        },
      },
      {
        text: '<i class="fas fa-star mr-2"></i> Ver noticias destacadas <span class="spinner-border spinner-border-sm d-none" role="status" aria-hidden="true"></span>',
        className: "btn btn-barymont-red my-2 my-md-0",
        action: function (e, dt, node) {
          $(node).prop("disabled", true);
          $(node).find(".spinner-border").removeClass("d-none");

          fetchCategoriesData()
            .then((data) => {
              categoriesData = data.data;
              loadAndOpenFeaturedNewsModal().finally(() => {
                $(node).prop("disabled", false);
                $(node).find(".spinner-border").addClass("d-none");
              });
            })
            .catch(() => {
              $(node).prop("disabled", false);
              $(node).find(".spinner-border").addClass("d-none");
            });
        },
        init: function (api, node) {
          $(node).removeClass("dt-button");
        },
      },
    ],
    pageLength: 10,
    lengthMenu: [
      [10, 25, 50],
      [10, 25, 50],
    ],
    select: false,
    keys: true,
    searchHighlight: true,
    ajax: {
      url: "/herramientas/news/listaprocessing",
      type: "post",
      data: function (d) {
        let selectCategories = $("#id-category").val();
        let selectDateFrom = $("#news-published-date-from").val();
        let selectDateTo = $("#news-published-date-to").val();

        d.newsCategories = $("#news-categories").val();
        d.selectAuthors = $("#news-authors-select").val();

        d.selectCategories = selectCategories != "" ? selectCategories : "";
        d.selectDateFrom = selectDateFrom != "" ? selectDateFrom : "";
        d.selectDateTo = selectDateTo != "" ? selectDateTo : "";
      },
      error: function (e) {
        if (e.status === 401) {
          createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
          setTimeout(function () {
            location.reload();
          }, 5000);
        } else {
          createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
        }
      },
    },
    fnDrawCallback: function () {
      $('[data-toggle="tooltip"]').tooltip();
    },
    stateSave: true,
    stateSaveCallback: function (settings, data) {
      let storageKey = `${FILTER_STATE_LOCAL_STORAGE_KEY}${settings.sInstance}_${getCookieValue("user_id")}`;

      data.customFilter = getStateOfCustomFilters();

      localStorage.setItem(storageKey, JSON.stringify(data));
    },
    stateLoadCallback: function (settings) {
      let storageKey = `${FILTER_STATE_LOCAL_STORAGE_KEY}${settings.sInstance}_${getCookieValue("user_id")}`;

      if (localStorage.getItem(storageKey) === null) {
        return false;
      }

      let localStorageData = JSON.parse(localStorage.getItem(storageKey));

      if (localStorageData.time < new Date().getTime() - FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME) {
        return false;
      }

      recreateStateOfCustomFilters(localStorageData);

      return localStorageData;
    },
  });

  $(".triggerDatatableUpdateNews").change(function () {
    $("#" + datatableId)
      .DataTable()
      .ajax.reload();
  });

  $("#" + datatableId)
    .DataTable()
    .on("draw", function () {
      document.querySelectorAll(".remove-news").forEach((element) => {
        element.addEventListener("click", function () {
          $("#modal-news-delete").modal("show");

          document.getElementById("modal-news-delete-button").dataset.newsId = this.dataset.newsId;
        });
      });
    });

  document.getElementById("modal-news-delete-button")?.addEventListener("click", function () {
    removeNews(this.dataset.newsId, datatableId);
  });
}

async function removeNews(newsId, datatableId) {
  let formData = new FormData();

  formData.append("newsId", newsId);

  let requestOptions = {
    method: "POST",
    body: formData,
  };

  let response = await fetch("/herramientas/news/ajax/removeNews", requestOptions).then((response) => response.json());

  if (response.success === true) {
    $("#modal-news-delete").modal("hide");
    $("#" + datatableId)
      .DataTable()
      .ajax.reload();
    createToast("success", "Noticia eliminada", "La noticia ha sido eliminada correctamente", 5000);
  } else {
    createToast("error", "Error al eliminar la noticia", "Ha ocurrido un error al eliminar la noticia", 5000);
  }
}

async function fetchCategoriesData() {
  let requestOptions = {
    method: "POST",
  };

  return await fetch("/herramientas/news/ajax/getNewsCategories", requestOptions).then((response) => response.json());
}

async function fetchFeaturedNews(categoryIds) {
  let formData = new FormData();

  formData.append("categoryIds", categoryIds);

  let requestOptions = {
    method: "POST",
    body: formData,
  };

  return await fetch("/herramientas/news/ajax/getFeaturedNews", requestOptions).then((response) => response.json());
}

async function loadAndOpenFeaturedNewsModal() {
  const randomModalId = Math.random().toString(36).substring(7);

  const newsFeaturedDatatableId = "news-featured-datatable-" + randomModalId;
  const newsFeaturedCategoriesInputId = "news-featured-categories-" + randomModalId;

  let modal = document.createElement("div");
  modal.id = randomModalId;
  modal.classList.add("modal", "fade");
  modal.setAttribute("tabindex", "-1");
  modal.setAttribute("role", "dialog");
  modal.setAttribute("aria-labelledby", "news-featured-datatable-modal");
  modal.setAttribute("aria-hidden", "true");

  modal.insertAdjacentHTML(
    "beforeend",
    `<div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h1 class="modal-title">Noticias destacadas</h1>
            </div>

            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">

                        <div class="col-12 mb-3">
                            <label for="${newsFeaturedCategoriesInputId}" class="form-label">Categorías</label>
                            <select
                                name="${newsFeaturedCategoriesInputId}"
                                id="${newsFeaturedCategoriesInputId}"
                                class="form-control selectpicker show-tick input-lg modal-selectpicker triggerDatatableUpdateNews"
                                title="Selecciona una categoría..."
                                multiple
                                data-live-search="true"
                                data-actions-box="true"
                                data-selected-text-format="count > 2"
                                data-count-selected-text="{0} categorías seleccionadas"
                                data-none-selected-text="Selecciona una categoría..."
                                data-none-results-text="No hay resultados"
                                data-deselect-all-text="Deseleccionar todo"
                                data-select-all-text="Seleccionar todo"
                              >
                            </select>
                        </div>

                        <div class="col-12">
                            <table id="${newsFeaturedDatatableId}" class="display nowrap compact row-border cell-border stripe hover w-100">
                              <thead>
                                  <tr>
                                      <th>Fecha destacado</th>
                                      <th>Título</th>
                                      <th>Categoría</th>
                                      <th
                                          data-toggle="tooltip"
                                          data-html="true"
                                          title="
                                              <i class='fa-solid fa-circle-check text-success'></i> Todas las etiquetas añadidas <br>
                                              <i class='fa-solid fa-circle-exclamation text-warning'></i> Falta alguna etiqueta <br>
                                              <i class='fa-solid fa-circle-xmark text-danger'></i> Ninguna etiqueta añadida
                                          ">
                                              SEO<i class="fa-solid fa-circle-info ml-2"></i>
                                      </th>
                                      <th>EverGreen</th>
                                      <th>Visible</th>
                                      <th><i class="fas fa-tools text-white"></i><span class="d-none">Opciones</span></th>
                                  </tr>
                              </thead>
                          </table>
                        </div>

                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
            </div>

        </div>
    </div>`
  );

  document.body.appendChild(modal);

  categoriesData.forEach((category) => {
    $("#" + newsFeaturedCategoriesInputId).append(`<option value="${category.categoryId}">${category.friendlyName}</option>`);
  });
  $("#" + newsFeaturedCategoriesInputId).selectpicker("refresh");

  $("#" + randomModalId).modal("show");

  let data = await fetchFeaturedNews([]);
  printFeaturedNewsDatatableByJson(data.data, newsFeaturedDatatableId);

  $("#" + newsFeaturedCategoriesInputId).on("changed.bs.select", function () {
    let selectedCategories = $(this).val();

    fetchFeaturedNews(selectedCategories ?? []).then((data) => {
      printFeaturedNewsDatatableByJson(data.data, newsFeaturedDatatableId);
    });
  });
}

function printFeaturedNewsDatatableByJson(data, tableId) {
  if ($.fn.DataTable.isDataTable("#" + tableId)) {
    $("#" + tableId)
      .DataTable()
      .destroy();
    $("#" + tableId).empty();
  }

  var transformedData = data.map(function (item) {
    return {
      dateFeatured: {
        render: item.dateFeatured,
        sort: function () {
          const dateParts = item.dateFeatured.split(/[- :]/);
          return new Date(dateParts[2], dateParts[1] - 1, dateParts[0], dateParts[3], dateParts[4]).toISOString();
        },
      },
      title: `<span class="text-wrap" style="max-width: 300px;">${item.title}</span>`,
      categoryName: `<span class="text-wrap" style="max-width: 300px;">${item.categoryName}</span>`,
      seo: function () {
        if (item.seoTitle === null && item.seoDescription === null && item.seoKeywords === null) {
          return '<span style="font-size: 16px;" data-toggle="tooltip" title="Esta noticia no tiene configurada ninguna etiqueta SEO"><i class="fa-solid fa-circle-xmark text-danger"></i></span>';
        } else if (item.seoTitle !== null && item.seoDescription !== null && item.seoKeywords !== null) {
          return '<span style="font-size: 16px;" data-toggle="tooltip" title="Todas las etiquetas SEO estan configuradas"><i class="fa-solid fa-circle-check text-success"></i></span>';
        }

        return '<span style="font-size: 16px;" data-toggle="tooltip" title="Alguna/s de las etiquetas SEO no esta configuradas"><i class="fa-solid fa-circle-exclamation text-warning"></i></span>';
      },
      everGreen: item.everGreen ? '<span class="badge badge-success w-100" style="font-size: 13px;">Sí</span>' : '<span class="badge badge-secondary w-100" style="font-size: 13px;">No</span>',
      visible: item.visible ? '<span class="badge badge-success w-100" style="font-size: 13px;">Sí</span>' : '<span class="badge badge-secondary w-100" style="font-size: 13px;">No</span>',
      options: `<div class="datatable-btn-grid-container"><a href="/herramientas/noticia/${item.newsId}" class="btn btn-barymont-red" data-toggle="tooltip" title="Acceder a la noticia"><i class="fas fa-info"></i></a></div>`,
    };
  });

  $("#" + tableId).DataTable({
    language: {
      sProcessing: "Procesando datos...",
      sLengthMenu: "Mostrar _MENU_ noticias destacadas",
      sZeroRecords: "No se encontraron noticias destacadas coincidentes con el criterio de búsqueda.",
      sEmptyTable: "No hay noticias destacadas disponibles",
      sInfo: "Mostrando noticias destacadas del _START_ al _END_ de un total de _TOTAL_",
      sInfoEmpty: "Mostrando noticias destacadas del 0 al 0 de un total de 0",
      sInfoFiltered: "(Filtrados de un total de _MAX_ noticias destacadas)",
      sSearch: "<div class='input-group-prepend'><div class='input-group-text'><i class='fas fa-search'></i></div></div>",
      sUrl: "",
      sInfoThousands: ",",
      sLoadingRecords: "Cargando Datos...",
      oPaginate: {
        sFirst: "Primera",
        sLast: "Última",
        sNext: "Siguiente",
        sPrevious: "Anterior",
      },
      oAria: {
        sSortAscending: ": Activar para ordenar la columna de manera ascendente",
        sSortDescending: ": Activar para ordenar la columna de manera descendente",
      },
    },
    buttons: [
      {
        extend: "colvis",
        text: "Ver más columnas",
      },
    ],
    pagingType: "input",
    order: [[0, "desc"]],
    processing: true,
    dom: '<"top"fl>Brt<"bottom"ip><"clear">',
    data: transformedData,
    columns: [
      {
        data: "dateFeatured",
        title: "Fecha destacado",
        render: function (data, type) {
          if (type === "sort") return data.sort;
          return data.render;
        },
      },
      { data: "title", title: "Título" },
      { data: "categoryName", title: "Categoría" },
      { data: "seo", title: "SEO <i class='fa-solid fa-circle-info text-white'></i>", orderable: false, searchable: false, className: "text-center" },
      { data: "everGreen", title: "EverGreen" },
      { data: "visible", title: "Visible" },
      { data: "options", title: "<i class='fas fa-tools text-white'></i><span class='d-none'>Opciones</span>", orderable: false },
    ],
    pageLength: 10,
    lengthMenu: [
      [10, 25, 50],
      [10, 25, 50],
    ],
    keys: true,
    scrollX: true,
    searchHighlight: true,
    fnDrawCallback: function () {
      $('[data-toggle="tooltip"]').tooltip();
    },
  });
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname === "/herramientas/noticias") {
      loadNewsDatatable("news-table");
    }
  });
}

export {};
