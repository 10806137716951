"use strict";

import { showLoadingButton, updateButtonLabel } from "../shared/shared.js";

const congresoEducacionFinanciera2025CorporateEventId = "155e61a6-2eb1-49e5-9a31-4da4890df7d2";

const inscriptionForm = "inscription-form";
const inscriptionFormBtn = "inscription-form-btn";

async function saveInscriptionData(btnHtml) {
  let corporateEventId = document.getElementById("corporate-event-id").value;
  let phaseId = document.getElementById("corporate-event-phase-id").value;
  let language = document.getElementById("language").value;

  let name = document.getElementById("name").value;
  let surname = document.getElementById("surname").value;
  let secondSurname = document.getElementById("second-surname")?.value ?? "";
  let email = document.getElementById("email").value;
  let phone = document.getElementById("phone").value;
  let inscriptionCode = document.getElementById("inscription-code") ? document.getElementById("inscription-code").value : "";
  let referredCode = document.getElementById("referred-code") ? document.getElementById("referred-code").value : "";

  //! Additional Information for III Congreso de Educación y Salud Financiera Cordoba 2025
  let additionalInformation = "";
  if (corporateEventId === congresoEducacionFinanciera2025CorporateEventId) {
    let whenYouWillCome = document.getElementById("when-will-you-come") && document.getElementById("when-will-you-come").value ? document.getElementById("when-will-you-come").value : "";
    let howDidYouKnowUs = document.getElementById("how-did-you-know-us") && document.getElementById("how-did-you-know-us").value ? document.getElementById("how-did-you-know-us").value : "";

    additionalInformation = [whenYouWillCome, howDidYouKnowUs].filter((item) => item !== "").join(", ");
  }
  const response = await fetchInscriptionData(corporateEventId, phaseId, language, name, surname, secondSurname, email, phone, inscriptionCode, referredCode, additionalInformation);

  let alertDiv = document.querySelector(".alert");

  if (response.success === false) {
    alertDiv.innerHTML = response.message;
    alertDiv.classList.remove("d-none");
    alertDiv.classList.remove("alert-success");
    alertDiv.classList.add("alert-danger");

    updateButtonLabel(inscriptionFormBtn, btnHtml, "btn-barymont-red");
    return;
  }

  alertDiv.classList.add("d-none");

  let formDataDiv = document.getElementById(inscriptionForm + "-data");
  formDataDiv.classList.add("d-none");

  document.getElementById("access-code").value = response.accessCode;
  document.getElementById("base64-access-code").src = response.accessCodeBase64Url;

  document.getElementById("download-ticket-url").addEventListener("click", async function () {
    showLoadingButton(this.id);
    await downloadInscriptionTicket(response.corporateEventInscriptionId);
    updateButtonLabel(this.id, "<i class='fa fa-file-download pr-1'></i> Descargar", "btn-secondary");
  });

  let completeRegistrationDiv = document.getElementById("complete-register-info");
  completeRegistrationDiv.classList.remove("d-none");
}

export async function downloadInscriptionTicket(corporateInscriptionId) {
  let formData = new FormData();
  formData.append("inscriptionId", corporateInscriptionId);

  await fetch("/herramientas/corporate-event-inscription/ajax/downloadTicket", {
    method: "POST",
    body: formData,
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `ticket_${corporateInscriptionId}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    });
}

async function fetchInscriptionData(corporateEventId, phaseId, language, name, surname, secondSurname, email, phone, inscriptionCode, referredCode, additionalInformation) {
  let formData = new FormData();

  formData.append("corporateEventId", corporateEventId);
  formData.append("phaseId", phaseId);
  formData.append("language", language);
  formData.append("name", name);
  formData.append("surname", surname);
  formData.append("secondSurname", secondSurname);
  formData.append("email", email);
  formData.append("phone", phone);
  formData.append("inscriptionCode", inscriptionCode);
  formData.append("referredCode", referredCode);
  formData.append("additionalInformation", additionalInformation);

  return await fetch("/herramientas/corporate-event-inscription/ajax/saveInscriptionData", {
    method: "POST",
    body: formData,
  }).then((response) => response.json());
}

function emitInscriptionSuccessEventToParent() {
  window.parent.postMessage("inscriptionSuccess", "*");
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname.includes("/herramientas/corporate-event-inscription/")) {
      document.getElementById(inscriptionForm)?.addEventListener("submit", async function (event) {
        event.preventDefault();
        let btnHtml = document.getElementById(inscriptionFormBtn).innerHTML;
        showLoadingButton(inscriptionFormBtn);

        await saveInscriptionData(btnHtml);

        updateButtonLabel(inscriptionFormBtn, btnHtml, "btn-barymont-red");

        emitInscriptionSuccessEventToParent();
      });
    }
  });
}
