import debounce from "../core/debounce";
import requireElementOfTypeById from "../dom/requireElementOfTypeById";
import ApiRequestError from "../../../../api/core/domain/apiRequestError";

/**
 * A function that performs a search based on the provided query string and returns a promise with the results.
 * @template T
 * @param {string} query - The search query.
 * @returns {Promise<T>} A promise resolving with the search results.
 */
type SearchFunction<T> = (query: string) => Promise<T>;

/**
 * A function that formats the search results into a string (typically HTML options).
 * @template T
 * @param {T} results - The search results to format.
 * @returns {string} The formatted options string.
 */
type FormatOptionsFunction<T> = (results: T) => string;

/**
 * Initializes a searchable select picker element with a search input.
 *
 * This function finds the select element by its id and then locates the search input within its parent element.
 * When the user types in the search box, it checks that the query length is at least 3 characters.
 * If it is, it performs an asynchronous search using the provided `searchFunction`,
 * formats the resulting options using the `formatOptionsFunction`, and updates the select element.
 * Finally, it refreshes the select picker to apply the changes.
 *
 * @template T
 * @param {string} selectId - The id of the select element to enhance.
 * @param {SearchFunction<T>} searchFunction - The asynchronous function used to perform the search.
 * @param {FormatOptionsFunction<T>} formatOptionsFunction - The function that formats the search results into HTML option elements.
 * @throws {Error} If the select element or its parent element is not found.
 */
export default function initializeSearchableSelectpicker<T>(selectId: string, searchFunction: SearchFunction<T>, formatOptionsFunction: FormatOptionsFunction<T>): void {
  const selectElement = requireElementOfTypeById(selectId, HTMLSelectElement);

  // INFO: We need to ensure the select element is initialized as a selectpicker before proceeding.
  $(`#${selectId}`).selectpicker();

  const parentElement = selectElement.parentElement;

  if (parentElement instanceof HTMLElement === false) {
    throw new Error(`Parent element of "${selectId}" not found`);
  }

  const searchInput = parentElement.querySelector<HTMLInputElement>(".bs-searchbox input");

  if (searchInput instanceof HTMLInputElement === false) {
    throw new Error(`Search input not found for "${selectId}, make sure the select element is initialized as a selectpicker with the live search option enabled`);
  }

  const handleInput = (event: Event): void => {
    const target = event.target as HTMLInputElement | null;
    if (!target) return;
    const query = target.value;

    if (query.length < 3) {
      selectElement.innerHTML = "";
      $(`#${selectId}`).selectpicker("refresh");
      return;
    }

    void (async () => {
      try {
        const results = await searchFunction(query);
        selectElement.innerHTML = formatOptionsFunction(results);
        $(`#${selectId}`).selectpicker("refresh");
      } catch (error: unknown) {
        // INFO: Since our API returns a 404 status code when no results are found, we can handle it specifically without logging an error.
        if (error instanceof ApiRequestError && error.status === 404) {
          selectElement.innerHTML = "";
          $(`#${selectId}`).selectpicker("refresh");
        } else {
          console.error("Error searching:", error);
          throw error;
        }
      }
    })();
  };

  const debouncedInputHandler = debounce(handleInput, 300);
  searchInput.addEventListener("input", debouncedInputHandler);
}
