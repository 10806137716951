"use strict";

import { createServiceExpert, deleteServiceExpert, readServiceExpert } from "../../api/service.js";
import { searchUsers } from "../../api/users.js";
import { showLoadingButton, updateButtonLabel } from "../shared/shared.js";

function getServiceExperts({ serviceId, experts, permissionKey }) {
  document.getElementById("experts-data").innerHTML = `
    <div class="col-12 text-center my-4" id="experts-data-spinner">
      <span class="spinner-border text-barymont-red" role="status" aria-hidden="true"></span>
    </div>`;

  printExpertData(experts, serviceId, permissionKey);

  document.getElementById("experts-data-spinner")?.remove();
}

function printExpertData(response, serviceId, permissionKey) {
  if (response.length > 0) {
    $("#experts-data").append(`<div class="col-12" id="experts-data-container">`);

    response.sort((a, b) => a.fullName.localeCompare(b.fullName));

    response.forEach((expert) => {
      const { userId, fullName, role } = expert;
      const userHasPermission = AppGbSession.checkUserHasPermission(permissionKey);

      $("#experts-data-container").append(`
        <div class="input-group my-3">
          <input type="text" class="form-control" value="${fullName}" readonly>

          <div class="input-group-append">
            <span class="input-group-text bg-light font-weight-bold w-100" data-toggle="tooltip" title="Rol del experto">
              ${role}
            </span>
            ${
              userHasPermission
                ? `
                  <button
                    class="btn btn-barymont-red"
                    data-toggle="tooltip"
                    data-placement="top"
                    id="btn-service-delete-expert"
                    title="Eliminar experto"
                    data-service-id="${serviceId}"
                    data-user-id="${userId}"
                  >
                    <i class="fa fa-trash"></i>
                  </button>`
                : ``
            }
          </div>
        </div>
      `);

      $('[data-toggle="tooltip"]').tooltip();

      const btnDelete = document.querySelectorAll(`#btn-service-delete-expert`);
      btnDelete.forEach((btnDelete) => {
        let cloneButton = btnDelete.cloneNode(true);
        btnDelete.parentNode.replaceChild(cloneButton, btnDelete);

        cloneButton.addEventListener("click", async function () {
          const serviceId = this.getAttribute("data-service-id");
          const userId = this.getAttribute("data-user-id");

          try {
            await deleteServiceExpert(serviceId, userId);
            createToast("success", "Éxito", "Experto eliminado correctamente.", 5000);

            const experts = await readServiceExpert(serviceId);

            getServiceExperts({ serviceId, experts, permissionKey });
          } catch (error) {
            createToast("error", "Error", error.message, 5000);
          }
        });
      });
    });
  } else {
    $("#experts-data").append(`<div class="col-12 my-4 text-center">No hay expertos asociados a este servicio</div>`);
  }
}

async function loadAndOpenRegisterExpertModal(serviceId = null, permissionKey) {
  const randomModalId = `modal-${Math.random().toString(36).substring(2, 10)}`;
  const expertsUserSelectorId = "experts-user-list";

  const fragment = document.createDocumentFragment();

  const temporalDiv = document.createElement("div");

  temporalDiv.innerHTML = `
    <div class="modal fade" id="${randomModalId}" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">Vincular Experto</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body text-center">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-light"><i class="fa-solid fa-md fa-user"></i></span>
                    </div>
                    <select
                        id="${expertsUserSelectorId}"
                        class="form-control selectpicker show-tick input-lg"
                        title="Usuarios"
                        data-live-search="true"
                        data-live-search-placeholder="Introduce 3 caracteres para buscar un usuario"
                        data-none-results-text="No se encontraron usuarios que coincidan con '{0}'">
                    </select>
                </div>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-light">Rol</span>
                    </div>
                    <input type="text" class="form-control" id="expert-role" required>
                </div>
                <small class="form-text text-muted text-left"><i class="fa-regular fa-question-circle text-barymont-red pr-2"></i>Al añadir un experto, se añadirá visibilidad al experto seleccionado y toda su estructura de responsables del servicio correspondiente.</small>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-barymont-red" id="confirm-btn-add-expert">Añadir</button>
                  <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
              </div>
          </div>
      </div>
    </div>`;

  fragment.appendChild(temporalDiv.firstElementChild);

  document.body.appendChild(fragment);

  const $modalElement = $(`#${randomModalId}`).modal("show");

  $("#" + expertsUserSelectorId).selectpicker("refresh");

  const searchInput = $(`#${expertsUserSelectorId}`).parent().find(".bs-searchbox input");
  searchInput.on("input", async function (event) {
    if (event.target.value.length < 3) {
      $("#" + expertsUserSelectorId).empty();
      $("#" + expertsUserSelectorId).selectpicker("refresh");
      return;
    }

    await paintUsersExpertsSelector(event.target.value, expertsUserSelectorId);
  });

  return new Promise((resolve) => {
    let expertUser = null;

    const handleConfirmClick = async () => {
      if (serviceId !== null) {
        await addExpertUser(serviceId, document.getElementById(expertsUserSelectorId).value, document.getElementById("expert-role").value, randomModalId);
        const experts = await readServiceExpert(serviceId);
        getServiceExperts({ serviceId, experts, permissionKey });
      } else {
        let optionUserExpert = document.getElementById(expertsUserSelectorId);
        const userName = document.querySelector(`#${expertsUserSelectorId} option[value="${optionUserExpert.value}"]`).getAttribute("data-user-name");
        expertUser = addTemporalExpertUser(optionUserExpert.value, userName, document.getElementById("expert-role").value);
        $modalElement.modal("hide");
      }
    };

    const addButtonElement = document.getElementById("confirm-btn-add-expert");
    const addButtonElementClone = addButtonElement.cloneNode(true);
    addButtonElement.parentNode.replaceChild(addButtonElementClone, addButtonElement);
    addButtonElementClone.addEventListener("click", handleConfirmClick);

    $modalElement.on("hidden.bs.modal", function () {
      $modalElement.remove();

      resolve(expertUser);
    });
  });
}

async function paintUsersExpertsSelector(searchValue, expertsUsersSelectorId) {
  const usersExperts = await searchUsers([], [], true, searchValue, 10, null);

  $("#" + expertsUsersSelectorId).empty();

  usersExperts.forEach((user) => {
    $("#" + expertsUsersSelectorId).append(`<option value="${user.userId}" data-user-name="${user.name}" data-content="<span>${user.name}</span> <span class='badge bg-${user.userProfile.backgroundColor} text-${user.userProfile.textColor} ml-2'>${user.userProfile.label}</span>">${user.name}</option>`);
  });

  $("#" + expertsUsersSelectorId).selectpicker("refresh");
}

async function addExpertUser(serviceId, userId, role, modalId) {
  showLoadingButton("confirm-btn-add-expert", "btn-barymont-red");

  if (userId === "") {
    createToast("error", "Error", "No se ha seleccionado ningún usuario.", 5000);
    return;
  }

  try {
    await createServiceExpert(serviceId, userId, role);

    $(`#${modalId}`).modal("hide");
    createToast("success", "Éxito", "Experto añadido correctamente.", 5000);
  } catch (error) {
    createToast("error", "Error", error.message, 5000);
  }

  updateButtonLabel("confirm-btn-add-expert", "Añadir", "btn-barymont-red");
}

function addTemporalExpertUser(userId, userName, role) {
  showLoadingButton("confirm-btn-add-expert", "btn-barymont-red");

  let expertUser = null;

  if (userId === "") {
    createToast("error", "Error", "No se ha seleccionado ningún usuario.", 5000);
    return expertUser;
  }

  if (role === "" || role.length < 3) {
    createToast("error", "Error", "El rol del experto debe tener al menos 3 caracteres.", 5000);
    return expertUser;
  }

  try {
    expertUser = {
      userId: userId,
      name: userName,
      role: role,
    };

    createToast("success", "Éxito", "Experto añadido correctamente.", 5000);
  } catch (error) {
    createToast("error", "Error", error.message, 5000);
  }

  updateButtonLabel("confirm-btn-add-expert", "Añadir", "btn-barymont-red");

  return expertUser;
}

const checkIfCurrentUserSessionIsServiceExpert = async (serviceId) => {
  try {
    return readServiceExpert(serviceId).then((experts) => {
      if (!Array.isArray(experts)) {
        return false;
      }
      return experts.some((expert) => expert.userId === AppGbSession.getSessionData().userId);
    });
  } catch (error) {
    return Promise.resolve(false);
  }
};

export { loadAndOpenRegisterExpertModal, getServiceExperts, checkIfCurrentUserSessionIsServiceExpert };
