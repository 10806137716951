"use strict";

import { isValidUuid } from "../shared/shared.js";

const saveDelay = (callback, ms = 0) => {
  let timer = null;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback(...args), ms);
  };
};

const delayedTableSearch = saveDelay((searchInputValue) => {
  let actualUrl = window.location.href;
  let params = new URLSearchParams(window.location.search);

  if (actualUrl.includes("searchAll=") && searchInputValue !== "") {
    actualUrl = actualUrl.replace(/(^|&|\?)searchAll=[^&]*/g, "");
    document.getElementById("all-data-search-filter").value = "";
    history.replaceState({}, "", actualUrl);
  }

  if (params.has("search")) {
    actualUrl = actualUrl.replace(/(^|&|\?)search=[^&]*/g, "");
    actualUrl = actualUrl.includes("?") ? actualUrl : actualUrl.replace(/&/, "?");
  }

  if (searchInputValue === "" || actualUrl.includes("searchAll=")) {
    history.replaceState({}, "", actualUrl);
    loadDataFilteredBySearch();
    return;
  }

  let param = (actualUrl.includes("?") ? "&" : "?") + "search=" + searchInputValue;
  history.replaceState({}, "", actualUrl + param);

  loadDataFilteredBySearch();
}, 1000);

const delayedAllSearch = saveDelay((searchInputValue) => {
  if (searchInputValue !== "" && searchInputValue.length < 3) {
    createToast("info", "Información", "La búsqueda debe tener al menos 3 caracteres...", 5000);
    return;
  }

  let actualUrl = window.location.href;
  let params = new URLSearchParams(window.location.search);

  if (actualUrl.includes("search=") && searchInputValue !== "") {
    actualUrl = actualUrl.replace(/(^|&|\?)search=[^&]*/g, "");
    document.getElementById("folder-data-search-filter").value = "";
    history.replaceState({}, "", actualUrl);
  }

  if (params.has("searchAll")) {
    actualUrl = actualUrl.replace(/(^|&|\?)searchAll=[^&]*/g, "");
    actualUrl = actualUrl.includes("?") ? actualUrl : actualUrl.replace(/&/, "?");
  }

  if (searchInputValue === "") {
    history.replaceState({}, "", actualUrl);
    loadDataFilteredBySearch();
    return;
  }

  let param = (actualUrl.includes("?") ? "&" : "?") + "searchAll=" + searchInputValue;
  history.replaceState({}, "", actualUrl + param);

  loadAllDataFilteredBySearch();
}, 1000);

async function loadAllDataFilteredBySearch() {
  let response = "";
  let params = new URLSearchParams(window.location.search);
  if (!params.has("searchAll")) {
    response = await fetchDocumentDataFiltered(params.get("search"), params.get("orderBy"), params.get("orderType"));
  } else {
    response = await fetchAllDocumentDataFiltered(params.get("orderBy"), params.get("orderType"));
  }

  let tbody = document.querySelector("#documents-table-view tbody");
  let documentsNotFoundMessage = document.getElementById("documents-not-found-message");
  tbody.innerHTML = "";
  tbody.appendChild(documentsNotFoundMessage);

  if (response.data.documents === undefined || response.data.documents.length === 0) {
    documentsNotFoundMessage.classList.remove("d-none");
    document.getElementById("breadcrumb-actual-route").innerHTML = `<a href="/herramientas/documentation-list">Documentación</a> / <span class="text-barymont-red">Filtrados: 0 documentos</span>`;
    return;
  }

  documentsNotFoundMessage.classList.add("d-none");

  printDocumentDataFiltered(response.data.documents, tbody);

  document.getElementById("breadcrumb-actual-route").innerHTML = `<a href="/herramientas/documentation-list">Documentación</a> / <span class="text-barymont-red">Filtrados: ${response.data.documents.length} documentos</span>`;
}

async function fetchAllDocumentDataFiltered(orderBy, orderType) {
  let params = new URLSearchParams(window.location.search);
  let formData = new FormData();
  formData.append("ui", "user-ui");

  if (params.has("searchAll")) {
    let searchAll = params.get("searchAll");
    formData.append("searchAll", searchAll);
  }

  if (orderBy !== null && orderType !== null) {
    formData.append("orderBy", orderBy);
    formData.append("orderType", orderType);
  }

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    body: formData,
  };

  return await fetch("/herramientas/documentation-list/ajax/getDocumentsFiltered", requestOptions).then((response) => response.json());
}

async function loadDataFilteredBySearch() {
  let params = new URLSearchParams(window.location.search);
  const response = await fetchDocumentDataFiltered(params.get("search"), params.get("orderBy"), params.get("orderType"));
  let tbody = document.querySelector("#documents-table-view tbody");
  let documentsNotFoundMessage = document.getElementById("documents-not-found-message");
  tbody.innerHTML = "";
  tbody.appendChild(documentsNotFoundMessage);

  if (response.data.documents.length === 0) {
    documentsNotFoundMessage.classList.remove("d-none");
    return;
  }

  documentsNotFoundMessage.classList.add("d-none");

  printDocumentDataFiltered(response.data.documents, tbody);

  await loadDocumentParentsBreadcrumb(params.get("search"), response.data.documents.length);
}

async function fetchDocumentDataFiltered(search = null, orderBy = null, orderType = null) {
  let searchAllParam = new URLSearchParams(window.location.search).get("searchAll");
  let documentId = window.location.href.split("/").pop().split("?")[0];
  let formData = new FormData();
  formData.append("ui", "user-ui");

  if (isValidUuid(documentId)) {
    formData.append("documentId", documentId);
  }

  if (searchAllParam !== null) {
    formData.append("searchAll", searchAllParam);
  } else if (search !== null) {
    formData.append("search", search);
  }

  if (orderBy !== null && orderType !== null) {
    formData.append("orderBy", orderBy);
    formData.append("orderType", orderType);
  }

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    body: formData,
  };

  return await fetch("/herramientas/documentation-list/ajax/getDocumentsFiltered", requestOptions).then((response) => response.json());
}

async function loadDocumentParentsBreadcrumb(search = null, counter) {
  let documentId = window.location.href.split("/").pop().split("?")[0];
  let breadcrumb = document.getElementById("breadcrumb-actual-route");

  breadcrumb.innerHTML = `<a href="/herramientas/documentation-list">Documentación</a>`;

  if (!isValidUuid(documentId)) {
    return;
  }

  let formData = new FormData();
  formData.append("documentId", documentId);

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    body: formData,
  };

  let response = await fetch("/herramientas/documentation-list/ajax/getDocumentParents", requestOptions).then((response) => response.json());

  response.data.documents.forEach((document) => {
    breadcrumb.innerHTML += ` / <a href="/herramientas/documentation-list/${document.documentId}">${document.name}</a>`;
  });

  if (search !== null) {
    breadcrumb.innerHTML += ` / <span class="text-barymont-red">Filtrados: ${counter} documentos</span>`;
  }
}

function printDocumentDataFiltered(documents, tableBodyElement) {
  let iconHighlight = "";
  let linkButton = "";
  documents.forEach((document) => {
    iconHighlight = "";
    linkButton = "";
    if (document.isActive === true) {
      if (document.type === "FOLDER") {
        if (document.highlightedAt !== null) {
          iconHighlight = `
            <span class="fa-stack fa-1x">
              <i class="fa-solid fa-folder fa-stack-2x text-barymont-red"></i>
              <i class="fa-solid fa-star fa-stack-1x" style="color:gold;"></i>
            </span>`;
        } else {
          iconHighlight = `<i class="fa-solid fa-folder fa-2x text-barymont-red"></i>`;
        }
      } else {
        iconHighlight = `<i class="${document.typeIcon} float-left" style="font-size: 2em;"></i>`;
      }

      if (document.type === "FILE") {
        linkButton = `<button data-action="link" data-document-url="/herramientas/documentation-list/${document.documentId}" class="btn-barymont-grey" data-toggle="tooltip" title="Copiar enlace">
            <i class="fa-solid fa-link" style="pointer-events: none;"></i>
        </button>`;
      }

      tableBodyElement.innerHTML += `
      <tr class="border" data - document - name="${document.name}" data - document - date="${document.createdAt}" >
        <td class="py-3 d-flex justify-content-center">
          <a href="/herramientas/documentation-list/${document.documentId}" ${document.type === "FOLDER" ? "" : 'target="_blank"'} class="text-barymont-red" style="cursor: pointer;">
            ${iconHighlight}
          </a>
        </td>
        <td class="py-3">
            <a href="/herramientas/documentation-list/${document.documentId}" ${document.type === "FOLDER" ? "" : 'target="_blank"'} class="text-barymont-red" style="cursor: pointer;">
                <span class="h5">${document.name}</span>
            </a>
        </td>
        <td class="text-center font-weight-bold">${new Date(document.createdAt).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })}</td>
        <td class="pr-3">
          <div class="text-center datatable-btn-grid-container">
            <a href="/herramientas/documentation-list/${document.documentId}" ${document.type === "FOLDER" ? "" : 'target="_blank"'} class="btn ${document.type === "FOLDER" ? "btn-barymont-grey" : "btn-barymont-red"}" data-toggle="tooltip" title="${document.type === "FOLDER" ? "Acceder a carpeta" : "Ver documento"}"><i class="fa-solid fa-lg ${document.type === "FOLDER" ? "fa-folder-open" : "fa-eye"}" style="pointer-events: none;"></i></a>
            ${linkButton}
           </div>
        </td>
      </tr> `;
    }
  });

  $('[data-toggle="tooltip"]').tooltip();
  createEventsClickDocuments();
}

function createEventsClickDocuments() {
  document.querySelectorAll("[data-action='link']").forEach((element) => {
    element.addEventListener("click", function (e) {
      let url = e.target.getAttribute("data-document-url");
      navigator.clipboard.writeText(location.origin + url).then(
        function () {
          createToast("info", "Información", "Enlace copiado al portapapeles", 5000);
        },
        function () {
          createToast("error", "Error", "No se pudo copiar el enlace al portapapeles", 5000);
        }
      );
    });
  });
}

if (window) {
  window.addEventListener("load", function () {
    if (window.location.pathname.includes("/herramientas/documentation-list")) {
      document.getElementById("folder-data-search-filter").addEventListener("keyup", function (e) {
        delayedTableSearch(e.target.value);
      });

      document.getElementById("all-data-search-filter").addEventListener("keyup", function (e) {
        delayedAllSearch(e.target.value);
      });

      document.getElementById("btn-clear-filters").addEventListener("click", function () {
        let documentId = window.location.href.split("/").pop().split("?")[0];
        if (isValidUuid(documentId)) {
          window.location.href = "/herramientas/documentation-list/" + documentId;
          return;
        }

        window.location.href = "/herramientas/documentation-list";
      });

      document.querySelectorAll(".triggerTableFilter").forEach((element) => {
        element.addEventListener("click", function (e) {
          document.querySelectorAll(".triggerTableFilter").forEach((element) => {
            element.style.color = "#ffffff";
            element.style.pointerEvents = "auto";
            element.style.cursor = "pointer";
          });

          let actualUrl = window.location.href;
          let params = new URLSearchParams(window.location.search);
          let currentOrder = actualUrl.includes("orderType") ? params.get("orderType") : "";

          if (params.has("orderBy")) {
            actualUrl = actualUrl.replace(/(^|&|\?)orderBy=[^&]*/g, "");
            actualUrl = actualUrl.replace(/(^|&|\?)orderType=[^&]*/g, "");
            actualUrl = actualUrl.includes("?") ? actualUrl : actualUrl.replace(/&/, "?");
          }

          if (e.target.getAttribute("data-current-order") !== null) {
            let orderSelectedElement = "";
            let param = "";

            switch (currentOrder) {
              case "ASC":
                e.target.setAttribute("data-current-order", "DESC");
                document.querySelectorAll(".triggerTableFilter:not([data-current-order])").forEach((element) => {
                  element.style.color = "#ffffff";
                  element.style.pointerEvents = "auto";
                  element.style.cursor = "pointer";
                });

                orderSelectedElement = document.querySelector(".triggerTableFilter[data-order-by='" + e.target.getAttribute("data-order-by") + "'][data-order-type='DESC']");
                orderSelectedElement.style.color = "#808080";
                orderSelectedElement.style.pointerEvents = "none";
                orderSelectedElement.style.cursor = "default";

                param = (actualUrl.includes("?") ? "&" : "?") + "orderBy=" + e.target.getAttribute("data-order-by") + "&orderType=DESC";
                history.replaceState({}, "", actualUrl + param);
                break;

              case "DESC":
                e.target.setAttribute("data-current-order", "");
                document.querySelectorAll(".triggerTableFilter:not([data-current-order])").forEach((element) => {
                  element.style.color = "#ffffff";
                  element.style.pointerEvents = "auto";
                  element.style.cursor = "pointer";
                });

                history.replaceState({}, "", actualUrl + param);
                break;

              default:
                e.target.setAttribute("data-current-order", "ASC");
                document.querySelectorAll(".triggerTableFilter:not([data-current-order])").forEach((element) => {
                  element.style.color = "#ffffff";
                  element.style.pointerEvents = "auto";
                  element.style.cursor = "pointer";
                });

                orderSelectedElement = document.querySelector(".triggerTableFilter[data-order-by='" + e.target.getAttribute("data-order-by") + "'][data-order-type='ASC']");
                orderSelectedElement.style.color = "#808080";
                orderSelectedElement.style.pointerEvents = "none";
                orderSelectedElement.style.cursor = "default";

                param = (actualUrl.includes("?") ? "&" : "?") + "orderBy=" + e.target.getAttribute("data-order-by") + "&orderType=ASC";
                history.replaceState({}, "", actualUrl + param);
                break;
            }
          } else {
            element.style.color = "#808080";
            element.style.pointerEvents = "none";
            element.style.cursor = "default";

            let param = (actualUrl.includes("?") ? "&" : "?") + "orderBy=" + e.target.getAttribute("data-order-by") + "&orderType=" + e.target.getAttribute("data-order-type");
            history.replaceState({}, "", actualUrl + param);
          }

          params = new URLSearchParams(window.location.search);
          fetchDocumentDataFiltered(params.get("search"), params.get("orderBy"), params.get("orderType")).then((response) => {
            let tbody = document.querySelector("#documents-table-view tbody");
            let documentsNotFoundMessage = document.getElementById("documents-not-found-message");
            tbody.innerHTML = "";
            tbody.appendChild(documentsNotFoundMessage);

            if (response.data.documents.length === 0) {
              documentsNotFoundMessage.classList.remove("d-none");
              return;
            }

            printDocumentDataFiltered(response.data.documents, tbody);
          });
        });
      });

      createEventsClickDocuments();
    }
  });
}
