import { SubscriptionPeriodicity } from "../domain/subscriptionPeriodicity";

export default function validateFormData(promoterUserId: string, planifierUserId: string, subscriptionPeriodicity: SubscriptionPeriodicity): string[] {
  const errors: string[] = [];
  if (!promoterUserId) errors.push("Es necesario seleccionar un <strong>Promotor</strong>.");
  if (!planifierUserId) errors.push("Es necesario seleccionar un <strong>Planificador</strong>.");
  if (!subscriptionPeriodicity) errors.push("Es necesario seleccionar la <strong>Periódicidad de la suscripción</strong>.");
  if (errors.length) {
    return errors;
  }
  return [];
}
