"use strict";

import { searchUserRoles } from "../../api/users.js";
import { isValidUuid } from "../shared/shared.js";

const MODAL_ID_CREATE_OR_UPDATE_DOCUMENT = "modal-documentation-management-create-update";
const MODAL_ID_DELETE_DOCUMENT = "modal-documentation-management-delete";

function showLoadingButton(buttonId, bgClass, onlySpiner = false) {
  const button = document.getElementById(buttonId);
  const loadingHtml = onlySpiner ? '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="pointer-events: none;"></span>' : '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="pointer-events: none;"></span> Cargando...';

  button.classList.remove(bgClass);
  button.classList.add("btn-barymont-grey");
  button.disabled = true;
  button.innerHTML = loadingHtml;
}

function updateButtonLabel(buttonId, newLabel, bgClass) {
  const button = document.getElementById(buttonId);

  button.classList.remove("btn-barymont-grey");
  button.classList.add(bgClass);
  button.disabled = false;
  button.innerHTML = newLabel;
}

const saveDelay = (callback, ms = 0) => {
  let timer = null;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback(...args), ms);
  };
};

const delayedTableSearch = saveDelay((searchInputValue) => {
  let actualUrl = window.location.href;
  let params = new URLSearchParams(window.location.search);

  if (actualUrl.includes("searchAll=") && searchInputValue !== "") {
    actualUrl = actualUrl.replace(/(^|&|\?)searchAll=[^&]*/g, "");
    document.getElementById("all-data-search-filter").value = "";
    history.replaceState({}, "", actualUrl);
  }

  if (params.has("search")) {
    actualUrl = actualUrl.replace(/(^|&|\?)search=[^&]*/g, "");
    actualUrl = actualUrl.includes("?") ? actualUrl : actualUrl.replace(/&/, "?");
  }

  if (searchInputValue === "" || actualUrl.includes("searchAll=")) {
    history.replaceState({}, "", actualUrl);
    loadDataFilteredBySearch();
    return;
  }

  let param = (actualUrl.includes("?") ? "&" : "?") + "search=" + searchInputValue;
  history.replaceState({}, "", actualUrl + param);

  loadDataFilteredBySearch();
}, 1000);

const delayedAllSearch = saveDelay((searchInputValue) => {
  if (searchInputValue !== "" && searchInputValue.length < 3) {
    createToast("info", "Información", "La búsqueda debe tener al menos 3 caracteres...", 5000);
    return;
  }

  let actualUrl = window.location.href;
  let params = new URLSearchParams(window.location.search);

  if (actualUrl.includes("search=") && searchInputValue !== "") {
    actualUrl = actualUrl.replace(/(^|&|\?)search=[^&]*/g, "");
    document.getElementById("folder-data-search-filter").value = "";
    history.replaceState({}, "", actualUrl);
  }

  if (params.has("searchAll")) {
    actualUrl = actualUrl.replace(/(^|&|\?)searchAll=[^&]*/g, "");
    actualUrl = actualUrl.includes("?") ? actualUrl : actualUrl.replace(/&/, "?");
  }

  if (searchInputValue === "") {
    history.replaceState({}, "", actualUrl);
    loadDataFilteredBySearch();
    return;
  }

  let param = (actualUrl.includes("?") ? "&" : "?") + "searchAll=" + searchInputValue;
  history.replaceState({}, "", actualUrl + param);

  loadAllDataFilteredBySearch();
}, 1000);

async function loadModalCreateOrUpdateDocumentData(type, action = "create", documentId = null) {
  const modalTitleIdCreateOrUpdateDocument = "modal-documentation-management-title";
  const modalInputFileIdCreateOrUpdateDocument = "add-file-column";
  const selectElementId = "documentation-management-create-folder-list";

  let modalTitle = "";
  let response = "";

  clearModalCreateOrUpdateDocumentData();
  await fetchUsersList();
  await printUserRolesSelector();

  if (action === "create") {
    await fetchParentFoldersList(selectElementId);

    if (type !== "FOLDER") {
      document.getElementById(modalInputFileIdCreateOrUpdateDocument).style.display = "block";
      modalTitle = "Crear documento";
    }

    if (type === "FOLDER") {
      document.getElementById(modalInputFileIdCreateOrUpdateDocument).style.display = "none";
      modalTitle = "Crear carpeta";
    }

    let documentIdUrl = window.location.href.split("/").pop().split("?")[0];

    if (isValidUuid(documentIdUrl)) {
      response = await fetchDocumentData(documentIdUrl);

      if (!response.success) {
        createToast("error", "Error ❌ ", "❌ Ha ocurrido un error al obtener los datos del documento. Por favor, intentelo nuevamente...", 5000);
        return;
      }

      document.getElementById("documentation-management-create-folder-list").value = documentIdUrl;
      $("#documentation-management-create-user-profile-list").selectpicker(
        "val",
        JSON.parse(response.data.document.allowedUserProfiles).map((profile) => profile)
      );
      $("#documentation-management-create-user-list").selectpicker(
        "val",
        response.data.document.allowedUserIds.map((userId) => userId)
      );
      $("#documentation-management-create-user-roles-list").selectpicker(
        "val",
        JSON.parse(response.data.document.allowedUserRoles).map((role) => role)
      );
    }

    $("#documentation-management-create-user-list").selectpicker("refresh");
    $("#documentation-management-create-folder-list").selectpicker("refresh");
    $("#documentation-management-create-user-profile-list").selectpicker("refresh");
    $("#documentation-management-create-user-roles-list").selectpicker("refresh");

    document.getElementById("documentation-management-save-btn").removeAttribute("data-document-id");
  }

  if (action === "update") {
    if (!isValidUuid(documentId)) {
      createToast("error", "Error ❌ ", "❌ Ha ocurrido un error al obtener el identificador del documento. Por favor, intentelo nuevamente...", 5000);
      return;
    }

    response = await fetchDocumentData(documentId);

    if (!response.success) {
      createToast("error", "Error ❌ ", "❌ Ha ocurrido un error al obtener los datos del documento. Por favor, intentelo nuevamente...", 5000);
      return;
    }

    if (type !== "FOLDER") {
      await fetchParentFoldersList(selectElementId);
      document.getElementById(modalInputFileIdCreateOrUpdateDocument).style.display = "block";
      modalTitle = "Actualizar Documento";
    }

    if (type === "FOLDER") {
      await fetchFolderHierachyWithOutCurrentFolder(selectElementId, documentId);
      document.getElementById(modalInputFileIdCreateOrUpdateDocument).style.display = "none";
      modalTitle = "Actualizar Carpeta";
    }

    await printModalCreateOrUpdateDocumentData(response.data.document);
  }

  document.getElementById(modalTitleIdCreateOrUpdateDocument).innerHTML = modalTitle;
}

async function fetchUsersList() {
  let requestOptions = {
    method: "POST",
    redirect: "follow",
  };

  let response = await fetch("/herramientas/documentation-management/ajax/getUsersList", requestOptions).then((response) => response.json());

  if (!response.success) {
    createToast("error", "Error ❌ ", "❌ Ha ocurrido un error al obtener el listado de usuarios. Por favor, intentelo nuevamente...", 5000);
    return;
  }

  let selectUsers = document.getElementById("documentation-management-create-user-list");

  selectUsers.innerHTML = "";
  response.data.users.forEach((user) => {
    selectUsers.innerHTML += `<option value="${user.userId}">${user.name}</option>`;
  });

  $("#documentation-management-create-user-list").selectpicker("refresh");
}

async function fetchParentFoldersList(selectElementId) {
  let requestOptions = {
    method: "POST",
    redirect: "follow",
  };

  let response = await fetch("/herramientas/documentation-management/ajax/getParentFoldersList", requestOptions).then((response) => response.json());

  if (!response.success) {
    createToast("error", "Error ❌ ", "❌ Ha ocurrido un error al obtener el listado de carpetas. Por favor, intentelo nuevamente...", 5000);
    return;
  }

  let selectParentFolders = document.getElementById(selectElementId);

  selectParentFolders.innerHTML = "";
  selectParentFolders.innerHTML += `<option value="0">Carpeta Raíz</option>`;
  response.data.folders.forEach((folder) => {
    selectParentFolders.innerHTML += `<option value="${folder.documentId}">${folder.nameHierarchyFormat}</option>`;
  });

  $("#" + selectElementId).selectpicker("refresh");
}

async function fetchFolderHierachyWithOutCurrentFolder(selectElementId, documentId) {
  let formData = new FormData();
  formData.append("documentId", documentId);

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    body: formData,
  };

  let response = await fetch("/herramientas/documentation-management/ajax/getFolderHierachyWithoutCurrentFolder", requestOptions).then((response) => response.json());

  if (!response.success) {
    createToast("error", "Error ❌ ", "❌ Ha ocurrido un error al obtener el listado de carpetas. Por favor, intentelo nuevamente...", 5000);
    return;
  }

  let selectParentFolders = document.getElementById(selectElementId);

  selectParentFolders.innerHTML = "";
  selectParentFolders.innerHTML += `<option value="0">Carpeta Raíz</option>`;
  response.data.folders.forEach((folder) => {
    selectParentFolders.innerHTML += `<option value="${folder.documentId}">${folder.nameHierarchyFormat}</option>`;
  });

  $("#" + selectElementId).selectpicker("refresh");
}

async function loadAllDataFilteredBySearch() {
  let response = "";
  let params = new URLSearchParams(window.location.search);
  if (!params.has("searchAll")) {
    response = await fetchDocumentDataFiltered(params.get("search"), params.get("orderBy"), params.get("orderType"));
  } else {
    response = await fetchAllDocumentDataFiltered(params.get("orderBy"), params.get("orderType"));
  }

  let tbody = document.querySelector("#documents-table-view tbody");
  let documentsNotFoundMessage = document.getElementById("documents-not-found-message");
  tbody.innerHTML = "";
  tbody.appendChild(documentsNotFoundMessage);

  if (response.data.documents === undefined || response.data.documents.length === 0) {
    documentsNotFoundMessage.classList.remove("d-none");
    document.getElementById("breadcrumb-actual-route").innerHTML = `<a href="/herramientas/documentation-management">Documentación</a> / <span class="text-barymont-red">Filtrados: 0 documentos</span>`;
    return;
  }

  documentsNotFoundMessage.classList.add("d-none");

  printDocumentDataFiltered(response.data.documents, tbody);

  document.getElementById("breadcrumb-actual-route").innerHTML = `<a href="/herramientas/documentation-management">Documentación</a> / <span class="text-barymont-red">Filtrados: ${response.data.documents.length} documentos</span>`;
}

async function fetchAllDocumentDataFiltered(orderBy, orderType) {
  let params = new URLSearchParams(window.location.search);
  let formData = new FormData();
  formData.append("ui", "admin-ui");

  if (params.has("searchAll")) {
    let searchAll = params.get("searchAll");
    formData.append("searchAll", searchAll);
  }

  if (orderBy !== null && orderType !== null) {
    formData.append("orderBy", orderBy);
    formData.append("orderType", orderType);
  }

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    body: formData,
  };

  return await fetch("/herramientas/documentation-list/ajax/getDocumentsFiltered", requestOptions).then((response) => response.json());
}

async function loadDataFilteredBySearch() {
  let params = new URLSearchParams(window.location.search);
  const response = await fetchDocumentDataFiltered(params.get("search"), params.get("orderBy"), params.get("orderType"));
  let tbody = document.querySelector("#documents-table-view tbody");
  let documentsNotFoundMessage = document.getElementById("documents-not-found-message");
  tbody.innerHTML = "";
  tbody.appendChild(documentsNotFoundMessage);

  if (response.data.documents.length === 0) {
    documentsNotFoundMessage.classList.remove("d-none");
    return;
  }

  documentsNotFoundMessage.classList.add("d-none");

  printDocumentDataFiltered(response.data.documents, tbody);

  await loadDocumentParentsBreadcrumb(params.get("search"), response.data.documents.length);
}

async function fetchDocumentDataFiltered(search = null, orderBy = null, orderType = null) {
  let searchAllParam = new URLSearchParams(window.location.search).get("searchAll");
  let documentId = window.location.href.split("/").pop().split("?")[0];
  let formData = new FormData();
  formData.append("ui", "admin-ui");

  if (isValidUuid(documentId)) {
    formData.append("documentId", documentId);
  }

  if (searchAllParam !== null) {
    formData.append("searchAll", searchAllParam);
  } else if (search !== null) {
    formData.append("search", search);
  }

  if (orderBy !== null && orderType !== null) {
    formData.append("orderBy", orderBy);
    formData.append("orderType", orderType);
  }

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    body: formData,
  };

  return await fetch("/herramientas/documentation-list/ajax/getDocumentsFiltered", requestOptions).then((response) => response.json());
}

async function fetchDocumentData(documentId) {
  let formData = new FormData();
  formData.append("documentId", documentId);

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    body: formData,
  };

  return await fetch("/herramientas/documentation-management/ajax/getDocumentData", requestOptions).then((response) => response.json());
}

async function saveDocumentData(documentId) {
  if (document.getElementById("documentation-management-create-name").value === "") {
    createToast("error", "Error ❌ ", "❌ Debes introducir un nombre para el documento...", 5000);
    return;
  }

  let formData = new FormData();
  formData.append("documentId", documentId);
  formData.append("name", document.getElementById("documentation-management-create-name").value);
  formData.append("parentId", document.getElementById("documentation-management-create-folder-list").value);
  formData.append("allowedUserProfiles", JSON.stringify($("#documentation-management-create-user-profile-list").val()));
  formData.append("allowedUserRoles", JSON.stringify($("#documentation-management-create-user-roles-list").val()));
  formData.append("allowedUserIds", JSON.stringify($("#documentation-management-create-user-list").val()));
  formData.append("visibility", document.getElementById("documentation-management-create-visibility").checked);
  formData.append("highlight", document.getElementById("documentation-management-create-highlight").checked);

  if (document.getElementById("add-file-column").style.display !== "none") {
    if (document.getElementsByClassName("custom-file-label").innerHTML === "Seleccionar archivo...") {
      createToast("error", "Error ❌ ", "❌ Debes seleccionar un archivo para subir...", 5000);
      return;
    }

    formData.append("type", "FILE");
    if (document.getElementById("documentation-management-file-input").files.length > 0) {
      let file = document.getElementById("documentation-management-file-input").files[0];
      formData.append("file", file);

      formData.append("extension", file.name.split(".").pop().toUpperCase());
    }
  } else {
    formData.append("type", "FOLDER");
  }

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    body: formData,
  };

  let response = await fetch("/herramientas/documentation-management/ajax/saveDocumentData", requestOptions).then((response) => response.json());

  if (!response.success) {
    createToast("error", "Error ❌ ", "❌ Ha ocurrido un error al guardar el documento. Por favor, intentelo nuevamente...", 5000);
    return;
  }

  createToast("success", "Éxito ✅ ", "✅ El documento ha sido guardado correctamente.", 5000);
  $("#" + MODAL_ID_CREATE_OR_UPDATE_DOCUMENT).modal("hide");

  loadDataFilteredBySearch();
}

async function deleteDocument(documentId, documentType, moveFolderId = null) {
  let formData = new FormData();
  formData.append("documentId", documentId);
  formData.append("type", documentType);
  formData.append("moveFolderId", moveFolderId);

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    body: formData,
  };

  let response = await fetch("/herramientas/documentation-management/ajax/deleteDocument", requestOptions).then((response) => response.json());

  if (!response.success) {
    createToast("error", "Error ❌ ", "❌ Ha ocurrido un error al eliminar el documento. Por favor, intentelo nuevamente...", 5000);
    return;
  }

  createToast("success", "Éxito ✅ ", "✅ El documento ha sido eliminado correctamente.", 5000);
  $("#" + MODAL_ID_DELETE_DOCUMENT).modal("hide");

  loadDataFilteredBySearch();
}

async function fetchUserByUserId(userId) {
  let formData = new FormData();
  formData.append("userId", userId);

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    body: formData,
  };

  return await fetch("/herramientas/documentation-management/ajax/getUserByUserId", requestOptions).then((response) => response.json());
}

function printDocumentDataFiltered(documents, tableBodyElement) {
  let iconHighlight = "";

  documents.forEach((document) => {
    iconHighlight = "";
    if (document.type === "FOLDER") {
      if (document.highlightedAt !== null) {
        iconHighlight = `
          <span class="fa-stack fa-1x">
            <i class="fa-solid fa-folder fa-stack-2x text-barymont-red"></i>
            <i class="fa-solid fa-star fa-stack-1x" style="color:gold;"></i>
          </span>`;
      } else {
        iconHighlight = `<i class="fa-solid fa-folder fa-2x text-barymont-red"></i>`;
      }
    } else {
      iconHighlight = `<i class="${document.typeIcon} float-left" style="font-size: 2em;"></i>`;
    }

    tableBodyElement.innerHTML += `
      <tr class="border" data-document-name="${document.name}" data-document-date="${document.createdAt}">
        <td class="py-3 d-flex justify-content-center">
          <a href="/herramientas/documentation-management/${document.documentId}" ${document.type === "FOLDER" ? "" : 'target="_blank"'} class="text-barymont-red" style="cursor: pointer;">
            ${iconHighlight}
          </a>
        </td>
        <td class="py-3">
            <a href="/herramientas/documentation-management/${document.documentId}" ${document.type === "FOLDER" ? "" : 'target="_blank"'} class="text-barymont-red" style="cursor: pointer;">
                <span class="h5">${document.name}</span>
            </a>
        </td>
        <td class="text-center font-weight-bold">${new Date(document.createdAt).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })}</td>
        <td class="pr-3">
          <div class="text-center datatable-btn-grid-container">
            <a href="/herramientas/documentation-management/${document.documentId}" ${document.type === "FOLDER" ? "" : 'target="_blank"'} class="btn btn-barymont-red" data-toggle="tooltip" title="${document.type === "FILE" ? "Descargar documento" : "Acceder a carpeta"}"><i class="fa-solid fa-lg ${document.type === "FOLDER" ? "fa-folder-open" : "fa-file-arrow-down"}" style="pointer-events: none;"></i></a>
            <button type="button" class="btn ${document.isActive === true ? "btn-barymont-green" : "btn-barymont-red"}" data-document-id="${document.documentId}" data-action="visibility" data-visibility="${document.isActive === true ? "1" : "0"}" data-toggle="tooltip" title="Cambiar visibilidad"><i class="fa-solid ${document.isActive === true ? "fa-eye" : "fa-eye-slash"}" style="pointer-events: none;"></i></button>
            <button type="button" class="btn btn-barymont-yellow" data-document-id="${document.documentId}" data-action="highlight" data-highlight="${document.highlightedAt !== null ? "1" : "0"}"  data-toggle="tooltip" title="Cambiar opción destacado"><i class="${document.highlightedAt !== null ? "fa-solid" : "fa-regular"} fa-star" style="pointer-events: none;"></i></button>
            <button id="edit-${document.documentId}" type="button" class="btn btn-barymont-red" data-document-id="${document.documentId}" data-document-type="${document.type}" data-action="edit" data-toggle="tooltip" title="Editar"><i class="fa-solid fa-pencil" style="pointer-events: none;"></i></button>
            <button id="delete-${document.documentId}" type="button" class="btn btn-barymont-black" data-toggle="modal" data-target="#modalDeleteDocumentation" data-action="delete" data-type="${document.type}" data-document-id="${document.documentId}" data-toggle="tooltip" title="Eliminar"><i class="fa-solid fa-trash" style="pointer-events: none;"></i></button>
          </div>
        </td>
      </tr>`;
  });

  $('[data-toggle="tooltip"]').tooltip();
  createEventsClickDocuments();
}

async function printModalCreateOrUpdateDocumentData(documentData) {
  document.getElementById("documentation-management-create-name").value = documentData.name;
  document.getElementById("documentation-management-create-folder-list").value = documentData.parentId;
  if (documentData.type === "FILE") {
    document.getElementsByClassName("custom-file-label")[0].innerHTML = documentData.storagePath ?? "Seleccionar archivo...";
  }

  $("#documentation-management-create-user-profile-list").selectpicker(
    "val",
    JSON.parse(documentData.allowedUserProfiles).map((profile) => profile)
  );
  $("#documentation-management-create-user-list").selectpicker(
    "val",
    documentData.allowedUserIds.map((userId) => userId)
  );
  $("#documentation-management-create-user-roles-list").selectpicker(
    "val",
    JSON.parse(documentData.allowedUserRoles).map((role) => role)
  );

  $("#documentation-management-create-visibility").bootstrapToggle(documentData.isActive === true ? "on" : "off");
  $("#documentation-management-create-highlight").bootstrapToggle(documentData.highlightedAt !== null ? "on" : "off");

  $("#documentation-management-create-folder-list").selectpicker("refresh");
  $("#documentation-management-create-user-profile-list").selectpicker("refresh");
  $("#documentation-management-create-user-list").selectpicker("refresh");
  $("#documentation-management-create-user-roles-list").selectpicker("refresh");

  document.getElementById("documentation-management-save-btn").setAttribute("data-document-id", documentData.documentId);

  if (documentData.lastModifierUserId !== null && documentData.modifiedAt !== null) {
    let user = await fetchUserByUserId(documentData.lastModifierUserId);
    document.getElementById("documentation-management-last-modification-date").value = `${new Date(documentData.modifiedAt).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })}`;
    document.getElementById("documentation-management-last-modifier").value = `${user.data.userName}`;
    document.getElementById("last-modification-info").classList.remove("d-none");
  }
}

function clearModalCreateOrUpdateDocumentData() {
  document.getElementById("documentation-management-create-name").value = "";
  document.getElementById("documentation-management-create-folder-list").value = "";
  document.getElementById("documentation-management-create-user-profile-list").value = "";
  document.getElementById("documentation-management-create-user-roles-list").value = "";
  document.getElementById("documentation-management-create-user-list").value = "";
  document.getElementById("documentation-management-create-visibility").checked = false;
  document.getElementById("documentation-management-create-highlight").checked = false;
  document.getElementsByClassName("custom-file-label")[0].innerHTML = "Subir fichero...";

  $("#documentation-management-create-folder-list").selectpicker("refresh");
  $("#documentation-management-create-user-profile-list").selectpicker("refresh");
  $("#documentation-management-create-user-roles-list").selectpicker("refresh");
  $("#documentation-management-create-user-list").selectpicker("refresh");

  $("#documentation-management-create-visibility").bootstrapToggle("off");
  $("#documentation-management-create-highlight").bootstrapToggle("off");

  document.getElementById("documentation-management-save-btn").removeAttribute("data-document-id");
  document.getElementById("last-modification-info").classList.add("d-none");
}

async function changeDocumentVisibility(documentId, value) {
  let visibility = value === "1" ? false : true;
  let formData = new FormData();
  formData.append("documentId", documentId);
  formData.append("visibility", visibility);

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    body: formData,
  };

  let response = await fetch("/herramientas/documentation-management/ajax/changeDocumentVisibility", requestOptions).then((response) => response.json());

  if (!response.success) {
    createToast("error", "Error ❌ ", "❌ Ha ocurrido un error al cambiar la visibilidad del documento. Por favor, intentelo nuevamente...", 5000);
    return;
  }

  let documentVisibilityButton = document.querySelector(`[data-action='visibility'][data-document-id='${documentId}']`);
  let documentVisibilityIcon = documentVisibilityButton.querySelector("i");

  if (visibility) {
    documentVisibilityButton.classList.remove("btn-barymont-red");
    documentVisibilityButton.classList.add("btn-barymont-green");
    documentVisibilityIcon.classList.remove("fa-eye-slash");
    documentVisibilityIcon.classList.add("fa-eye");
  } else {
    documentVisibilityButton.classList.remove("btn-barymont-green");
    documentVisibilityButton.classList.add("btn-barymont-red");
    documentVisibilityIcon.classList.remove("fa-eye");
    documentVisibilityIcon.classList.add("fa-eye-slash");
  }

  documentVisibilityButton.setAttribute("data-visibility", visibility === true ? "1" : "0");
  createToast("success", "Éxito ✅ ", "✅ La visibilidad del documento ha sido cambiada correctamente.", 5000);
}

async function changeDocumentHighlight(documentId, value) {
  let highlight = value === "1" ? false : true;
  let formData = new FormData();
  formData.append("documentId", documentId);
  formData.append("highlight", highlight);

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    body: formData,
  };

  let response = await fetch("/herramientas/documentation-management/ajax/changeDocumentHighlight", requestOptions).then((response) => response.json());

  if (!response.success) {
    createToast("error", "Error ❌ ", "❌ Ha ocurrido un error al cambiar el estado de destacado del documento. Por favor, intentelo nuevamente...", 5000);
    return;
  }

  let documentHighlightButton = document.querySelector(`[data-action='highlight'][data-document-id='${documentId}']`);
  let documentHighlightIcon = documentHighlightButton.querySelector("i");

  if (highlight) {
    documentHighlightIcon.classList.remove("fa-regular");
    documentHighlightIcon.classList.add("fa-solid");
  } else {
    documentHighlightIcon.classList.remove("fa-solid");
    documentHighlightIcon.classList.add("fa-regular");
  }

  documentHighlightButton.setAttribute("data-highlight", highlight === true ? "1" : "0");
  createToast("success", "Éxito ✅ ", "✅ Se ha cambiado el estado de destacado del documento correctamente.", 5000);

  loadDataFilteredBySearch();
}

async function loadDocumentParentsBreadcrumb(search = null, counter) {
  let documentId = window.location.href.split("/").pop().split("?")[0];
  let breadcrumb = document.getElementById("breadcrumb-actual-route");

  breadcrumb.innerHTML = `<a href="/herramientas/documentation-management">Documentación</a>`;

  if (!isValidUuid(documentId)) {
    return;
  }

  let formData = new FormData();
  formData.append("documentId", documentId);

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    body: formData,
  };

  let response = await fetch("/herramientas/documentation-list/ajax/getDocumentParents", requestOptions).then((response) => response.json());

  response.data.documents.forEach((document) => {
    breadcrumb.innerHTML += ` / <a href="/herramientas/documentation-management/${document.documentId}">${document.name}</a>`;
  });

  if (search !== null) {
    breadcrumb.innerHTML += ` / <span class="text-barymont-red">Filtrados: ${counter} documentos</span>`;
  }
}

function createEventsClickDocuments() {
  document.querySelectorAll("[data-action='visibility']").forEach((element) => {
    element.addEventListener("click", function (e) {
      let documentId = e.target.getAttribute("data-document-id");
      let value = e.target.getAttribute("data-visibility");

      changeDocumentVisibility(documentId, value);
    });
  });

  document.querySelectorAll("[data-action='highlight']").forEach((element) => {
    element.addEventListener("click", function (e) {
      let documentId = e.target.getAttribute("data-document-id");
      let value = e.target.getAttribute("data-highlight");

      changeDocumentHighlight(documentId, value);
    });
  });

  document.querySelectorAll("[data-action='edit']").forEach((element) => {
    element.addEventListener("click", function (e) {
      showLoadingButton(e.target.id, "btn-barymont-grey", true);
      loadModalCreateOrUpdateDocumentData(e.target.getAttribute("data-document-type"), "update", e.target.getAttribute("data-document-id")).then(() => {
        $("#" + MODAL_ID_CREATE_OR_UPDATE_DOCUMENT).modal("show");
        updateButtonLabel(e.target.id, '<i class="fa-solid fa-pencil" style="pointer-events: none;"></i>', "btn-barymont-red");
      });
    });
  });

  document.querySelectorAll("[data-action='delete']").forEach((element) => {
    element.addEventListener("click", async function (e) {
      showLoadingButton(e.target.id, "btn-barymont-grey", true);
      document.getElementById("modal-documentation-management-delete-button").setAttribute("data-document-id", element.getAttribute("data-document-id"));
      document.getElementById("modal-documentation-management-delete-button").setAttribute("data-type", element.getAttribute("data-type"));

      let name = element.closest("[data-document-name]").getAttribute("data-document-name");
      let title = element.getAttribute("data-type") === "FOLDER" ? "Eliminar Carpeta" : "Eliminar Documento";
      let text = element.getAttribute("data-type") === "FOLDER" ? "¿Estás seguro que deseas eliminar la carpeta '" + name + "'?" : "¿Estás seguro que deseas eliminar el documento '" + name + "'?";
      document.getElementById(MODAL_ID_DELETE_DOCUMENT + "-title").innerHTML = title;
      document.getElementById(MODAL_ID_DELETE_DOCUMENT + "-text").innerHTML = text;

      document.getElementById("documentation-management-move-documents-container").classList.add("d-none");

      if (element.getAttribute("data-type") === "FOLDER") {
        document.getElementById("documentation-management-move-documents-container").classList.remove("d-none");

        await fetchFolderHierachyWithOutCurrentFolder("documentation-management-folder-list", element.getAttribute("data-document-id"));

        $("#documentation-management-move-documents-input").change(function () {
          let folderList = document.getElementById("selectpicker-folder-container");
          folderList.classList.contains("d-none") ? folderList.classList.remove("d-none") : folderList.classList.add("d-none");
        });
      }

      $("#" + MODAL_ID_DELETE_DOCUMENT).modal("show");
      updateButtonLabel(e.target.id, '<i class="fa-solid fa-trash" style="pointer-events: none;"></i>', "btn-barymont-black");
    });
  });
}

async function printUserRolesSelector() {
  try {
    const response = await searchUserRoles();

    document.getElementById("documentation-management-create-user-roles-list").innerHTML = "";

    response.map((role) => {
      document.getElementById("documentation-management-create-user-roles-list").innerHTML += `<option value="${role.role}">${role.label}</option>`;
    });

    $("#documentation-management-create-user-roles-list").selectpicker("refresh");
  } catch (error) {
    createToast("error", "Error ❌ ", error.message, 5000);
    return;
  }
}

if (window) {
  window.addEventListener("load", function () {
    if (window.location.pathname.includes("/herramientas/documentation-management")) {
      document.getElementById("folder-data-search-filter").addEventListener("keyup", function (e) {
        delayedTableSearch(e.target.value);
      });

      document.getElementById("all-data-search-filter").addEventListener("keyup", function (e) {
        delayedAllSearch(e.target.value);
      });

      document.getElementById("btn-clear-filters").addEventListener("click", function () {
        let documentId = window.location.href.split("/").pop().split("?")[0];

        if (isValidUuid(documentId)) {
          window.location.href = "/herramientas/documentation-management/" + documentId;
          return;
        }

        window.location.href = "/herramientas/documentation-management";
      });

      document.getElementById("btn-new-folder").addEventListener("click", function () {
        showLoadingButton("btn-new-folder", "btn-barymont-grey");
        loadModalCreateOrUpdateDocumentData("FOLDER").then(() => {
          $("#" + MODAL_ID_CREATE_OR_UPDATE_DOCUMENT).modal("show");
          updateButtonLabel("btn-new-folder", '<i class="fa-solid fa-folder-plus mr-2"></i>Nueva Carpeta', "btn-barymont-red");
        });
      });

      document.getElementById("btn-new-file").addEventListener("click", function () {
        showLoadingButton("btn-new-file", "btn-barymont-grey");
        loadModalCreateOrUpdateDocumentData("FILE").then(() => {
          $("#" + MODAL_ID_CREATE_OR_UPDATE_DOCUMENT).modal("show");
          updateButtonLabel("btn-new-file", '<i class="fa-solid fa-file-circle-plus mr-2"></i>Nuevo Documento', "btn-barymont-red");
        });
      });

      document.querySelectorAll(".triggerTableFilter").forEach((element) => {
        element.addEventListener("click", function (e) {
          document.querySelectorAll(".triggerTableFilter").forEach((element) => {
            element.style.color = "#ffffff";
            element.style.pointerEvents = "auto";
            element.style.cursor = "pointer";
          });

          let actualUrl = window.location.href;
          let params = new URLSearchParams(window.location.search);
          let currentOrder = actualUrl.includes("orderType") ? params.get("orderType") : "";

          if (params.has("orderBy")) {
            actualUrl = actualUrl.replace(/(^|&|\?)orderBy=[^&]*/g, "");
            actualUrl = actualUrl.replace(/(^|&|\?)orderType=[^&]*/g, "");
            actualUrl = actualUrl.includes("?") ? actualUrl : actualUrl.replace(/&/, "?");
          }

          if (e.target.getAttribute("data-current-order") !== null) {
            let orderSelectedElement = "";
            let param = "";

            switch (currentOrder) {
              case "ASC":
                e.target.setAttribute("data-current-order", "DESC");
                document.querySelectorAll(".triggerTableFilter:not([data-current-order])").forEach((element) => {
                  element.style.color = "#ffffff";
                  element.style.pointerEvents = "auto";
                  element.style.cursor = "pointer";
                });

                orderSelectedElement = document.querySelector(".triggerTableFilter[data-order-by='" + e.target.getAttribute("data-order-by") + "'][data-order-type='DESC']");
                orderSelectedElement.style.color = "#808080";
                orderSelectedElement.style.pointerEvents = "none";
                orderSelectedElement.style.cursor = "default";

                param = (actualUrl.includes("?") ? "&" : "?") + "orderBy=" + e.target.getAttribute("data-order-by") + "&orderType=DESC";
                history.replaceState({}, "", actualUrl + param);
                break;

              case "DESC":
                e.target.setAttribute("data-current-order", "");
                document.querySelectorAll(".triggerTableFilter:not([data-current-order])").forEach((element) => {
                  element.style.color = "#ffffff";
                  element.style.pointerEvents = "auto";
                  element.style.cursor = "pointer";
                });

                history.replaceState({}, "", actualUrl + param);
                break;

              default:
                e.target.setAttribute("data-current-order", "ASC");
                document.querySelectorAll(".triggerTableFilter:not([data-current-order])").forEach((element) => {
                  element.style.color = "#ffffff";
                  element.style.pointerEvents = "auto";
                  element.style.cursor = "pointer";
                });

                orderSelectedElement = document.querySelector(".triggerTableFilter[data-order-by='" + e.target.getAttribute("data-order-by") + "'][data-order-type='ASC']");
                orderSelectedElement.style.color = "#808080";
                orderSelectedElement.style.pointerEvents = "none";
                orderSelectedElement.style.cursor = "default";

                param = (actualUrl.includes("?") ? "&" : "?") + "orderBy=" + e.target.getAttribute("data-order-by") + "&orderType=ASC";
                history.replaceState({}, "", actualUrl + param);
                break;
            }
          } else {
            element.style.color = "#808080";
            element.style.pointerEvents = "none";
            element.style.cursor = "default";

            let param = (actualUrl.includes("?") ? "&" : "?") + "orderBy=" + e.target.getAttribute("data-order-by") + "&orderType=" + e.target.getAttribute("data-order-type");
            history.replaceState({}, "", actualUrl + param);
          }

          params = new URLSearchParams(window.location.search);
          fetchDocumentDataFiltered(params.get("search"), params.get("orderBy"), params.get("orderType")).then((response) => {
            let tbody = document.querySelector("#documents-table-view tbody");
            let documentsNotFoundMessage = document.getElementById("documents-not-found-message");
            tbody.innerHTML = "";
            tbody.appendChild(documentsNotFoundMessage);

            if (response.data.documents.length === 0) {
              documentsNotFoundMessage.classList.remove("d-none");
              return;
            }

            printDocumentDataFiltered(response.data.documents, tbody);
          });
        });
      });

      document.getElementById("documentation-management-save-btn").addEventListener("click", async function (e) {
        await saveDocumentData(e.target.getAttribute("data-document-id"));
      });

      document.getElementById("modal-documentation-management-delete-button").addEventListener("click", async function (e) {
        let moveFolderId = null;
        if (document.getElementById("documentation-management-move-documents-input").checked) {
          moveFolderId = document.getElementById("documentation-management-folder-list").value;
        }
        await deleteDocument(e.target.getAttribute("data-document-id"), e.target.getAttribute("data-type"), moveFolderId);
      });

      createEventsClickDocuments();
    }
  });
}
