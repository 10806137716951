"use strict";

function showLoadingButton(buttonId, bgClass, onlySpiner = false) {
  const loadingHtml = onlySpiner ? '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="pointer-events: none;"></span>' : '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="pointer-events: none;"></span> Cargando...';
  $("#" + buttonId)
    .removeClass(bgClass)
    .addClass("btn-barymont-grey")
    .attr("disabled", true)
    .html(loadingHtml);
}

function updateButtonLabel(buttonId, newLabel, bgClass) {
  $("#" + buttonId)
    .removeClass("btn-barymont-grey")
    .addClass(bgClass)
    .attr("disabled", false)
    .html(newLabel);
}

function printSettledResultContent(modalIdToOpen, settledResults, modalTittleId, type, downloadFileData = null) {
  document.getElementById("modal-massive-settled-result-error-container").innerHTML = "";
  document.getElementById("modal-massive-settled-result-success-container").innerHTML = "";

  const validTypes = ["SETTLEMENT", "INVOICE", "PAYMENT"];

  if (!validTypes.includes(type)) {
    throw new Error("Invalid type");
  }

  let tableTitle;
  let tableErrorTitle;
  switch (type) {
    case "SETTLEMENT":
      document.getElementById(modalTittleId).innerHTML = "Resultados de la liquidación masiva";
      tableTitle = "Liquidaciones generadas";
      tableErrorTitle = "No ha sido posible generar las siguientes liquidaciones";
      break;
    case "INVOICE":
      document.getElementById(modalTittleId).innerHTML = "Resultados de la facturación masiva";
      tableTitle = "Facturas generadas";
      tableErrorTitle = "No ha sido posible facturar las siguientes liquidaciones";
      break;
    case "PAYMENT":
      document.getElementById(modalTittleId).innerHTML = "Resultados de los pagos masivos";
      tableTitle = "Pagos generados";
      tableErrorTitle = "No ha sido posible marcar como pagadas las siguientes liquidaciones";
      break;
  }

  let outputHtmlError = `
    <div class="table-responsive">
      <label class="h4">${tableErrorTitle}</label>
      <table class="table table-bordered table-hover table-sm">
        <thead class="bg-danger">
          <tr>
            <th scope="col" class="font-bold">NIF</th>
            <th scope="col">Nombre</th>
            <th scope="col" class="text-center" style="width:50px">Ver</th>
          </tr>
        </thead>
        <tbody>`;

  let errorCounter = 0;

  let outputHtmlSuccess = `
    <div class="table-responsive">
      <label class="h4">${tableTitle}</label>
      <table class="table table-bordered table-hover table-striped table-sm">
        <thead class="bg-success">
          <tr>
            <th scope="col">NIF</th>
            <th scope="col">Nombre</th>
            <th scope="col">Importe líquido</th>
            <th scope="col" class="text-center" style="width:50px">Ver</th>
          </tr>
        </thead>
        <tbody>`;

  let successCounter = 0;

  settledResults.forEach((settled) => {
    let urlToSettledUi = `/herramientas/liquidaciones/comisiones/${settled.settledId}`;
    if (settled.isCorrectlyCreated === false) {
      errorCounter++;
      outputHtmlError += `
            <tr>
              <td>${settled.identificationNumber}</td>
              <td>${settled.fullName}</td>
              <td><a class="btn btn-barymont-red btn-block btn-sm" href="${urlToSettledUi}" target="_BLANK"><i class="fas fa-info"></i></a></td>
            </tr>
            <tr>
              <td colspan="3">${settled.errorReason}</td>
            </tr>`;
    }
    if (settled.isCorrectlyCreated === true) {
      successCounter++;
      outputHtmlSuccess += `
            <tr>
              <td>${settled.identificationNumber}</td>
              <td>${settled.fullName}</td>
              <td class="text-right">${settled.netAmountTotal} €</td>
              <td><a class="btn btn-barymont-red btn-block btn-sm" href="${urlToSettledUi}" target="_BLANK"><i class="fas fa-info"></i></a></td>
            </tr>`;
    }
  });

  outputHtmlError += `</tbody></table></div>`;

  outputHtmlSuccess += `</tbody></table></div>`;

  if (errorCounter > 0) {
    document.getElementById("modal-massive-settled-result-error-container").innerHTML = outputHtmlError;
  }

  if (successCounter > 0) {
    document.getElementById("modal-massive-settled-result-success-container").innerHTML = outputHtmlSuccess;
  }

  if (errorCounter === 0 && successCounter === 0) {
    document.getElementById("modal-massive-settled-result-error-container").innerHTML = "No se han generado liquidaciones";
  }

  if (downloadFileData !== null && type === "PAYMENT") {
    document.getElementById("modal-massive-settled-csv-btn").classList.remove("d-none");

    document.getElementById("modal-massive-settled-csv-btn").onclick = function () {
      const blob = new Blob([downloadFileData.csvContent], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = downloadFileData.fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    };
  }

  $("#" + modalIdToOpen).modal("show");
}

export default {
  printSettledResultContent,
  showLoadingButton,
  updateButtonLabel,
};
