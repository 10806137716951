"use strict";

export async function updatePromoter(userId, leadId) {
  const response = await fetch(`/api/leads/${leadId}/promoter`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ promoterId: userId }),
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function readLeadPromotersHistory(leadId) {
  const response = await fetch(`/api/leads/${leadId}/promoter/history`);
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function readLeadVisibility(leadId) {
  const response = await fetch(`/api/leads/${leadId}/visibility`);
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function createLeadVisibility(leadId, userId) {
  const response = await fetch(`/api/leads/${leadId}/visibility/${userId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function deleteLeadVisibility(leadId, userId) {
  const response = await fetch(`/api/leads/${leadId}/visibility/${userId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function createLead({ identificationNumber, name, firstSurname, secondSurname, email, phone, provinceId, birthDate = null, nationalityId = null, responsibleUserId = null, loyaltyCustomerId = null, promoterNif = null }) {
  provinceId = parseInt(provinceId);

  const newLeadData = {
    identificationNumber,
    name,
    firstSurname,
    secondSurname,
    email,
    phone,
    provinceId,
  };

  if (birthDate !== "") {
    newLeadData.birthDate = birthDate;
  }

  if (nationalityId !== null) {
    nationalityId = parseInt(nationalityId);
    newLeadData.nationalityId = nationalityId;
  }

  if (responsibleUserId !== null) {
    newLeadData.responsibleUserId = responsibleUserId;
  }

  if (loyaltyCustomerId !== null) {
    newLeadData.loyaltyCustomerId = loyaltyCustomerId;
  }

  if (promoterNif !== null) {
    newLeadData.promoterNif = promoterNif;
  }

  let requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newLeadData),
  };

  const response = await fetch("/api/leads", requestOptions);
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function createLeadInterestDetected(leadId, interests, economyModel, description) {
  const newLeadInterestData = {
    interests,
    economyModel,
    description,
  };

  let requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newLeadInterestData),
  };

  const response = await fetch(`/api/leads/${leadId}/interest-detected`, requestOptions);
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}
