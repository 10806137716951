"use strict";

function formatBytes(bytes, decimals) {
  if (bytes == 0) return "0 Bytes";
  var k = 1024,
    dm = decimals || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

async function openFolder(leadId, tableBody) {
  const relativePath = "/" + decodeURIComponent(window.location.pathname.split("/").slice(5).join("/"));
  let data = await fetchFilesData(leadId, relativePath);

  if (data.status === 0) {
    data = await fetchFilesData(leadId, relativePath);
    if (data.status === 0) {
      createToast("error", "Error ❌ ", "❌ No ha sido posible cargar los archivos, intentelo más tarde...", 5000);
      return;
    }
  }

  printFilesData(data.filesResponseList, tableBody);
}

async function fetchFilesData(leadId, path = "") {
  let formData = new FormData();

  formData.append("id_lead", leadId);
  formData.append("path", path);

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    body: formData,
  };

  return await fetch("/herramientas/leadsDocumentos/ajax/listFiles", requestOptions).then((response) => response.json());
}

async function printFilesData(fileResponseList, tableBody) {
  if (fileResponseList.length === 0) {
    tableBody.innerHTML = `
      <tr>
        <td class="text-center py-3" colspan="5">No se han encontrado archivos en esta ruta</td>
      </tr>
    `;
    return;
  }

  let fileHtml = "";

  fileResponseList.sort((a, b) => {
    if (a.leadFileType === "folder" && b.leadFileType === "folder") {
      return 0;
    } else if (a.leadFileType === "folder") {
      return -1;
    } else if (b.leadFileType === "folder") {
      return 1;
    } else {
      return a.modifiedAt > b.modifiedAt ? -1 : 1;
    }
  });

  fileResponseList.forEach((file) => {
    if (file.leadFileType === "folder") {
      fileHtml += `
        <tr>
          <td class="text-center py-3"><i class="${file.extensionIconClass} fa-lg" style="pointer-events: none;"></i></td>
          <td class="text-left py-3" style="font-weight: 700;"><a href="/herramientas/anf/documentacion/${file.leadId}${file.path}">${file.name}</a></td>
          <td></td>
          <td class="text-center py-3 text-monospace text-muted">${file.modifiedAt ?? "-"}</td>
          <td class="text-center py-3"><span class="btn btn-barymont-red btn-block" role="button" data-action="download-folder" data-btn="true" data-path="${file.path}"><i class="fas fa-download fa-lg" style="pointer-events: none;"></i></span></td>
        </tr>
      `;
    } else {
      fileHtml += `
        <tr>
          <td class="text-center py-3"><i class="${file.extensionIconClass} fa-lg" style="pointer-events: none;"></i></td>
          <td class="text-left py-3"><span role="button" data-action="download" data-path="${file.path}" style="font-weight: 500;">${file.name}</span></td>
          <td class="text-center py-3 text-monospace text-muted">${formatBytes(file.size)}</td>
          <td class="text-center py-3 text-monospace text-muted">${file.modifiedAt ?? "-"}</td>
          <td class="text-center py-3"><span class="btn btn-barymont-red btn-block" role="button" data-action="download" data-btn="true" data-path="${file.path}"><i class="fas fa-download fa-lg" style="pointer-events: none;"></i></span></td>
        </tr>
      `;
    }
  });

  tableBody.innerHTML = fileHtml;
}

async function downloadFile(leadId, path) {
  let formData = new FormData();

  formData.append("path", path);
  formData.append("id_lead", leadId);

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    body: formData,
  };

  const downloadButton = document.querySelector(`[data-action="download"][data-path="${path}"][data-btn="true"]`);
  const nameDownloadButton = document.querySelector(`[data-action="download"][data-path="${path}"]`);

  downloadButton.innerHTML = `
    <div class="spinner-border spinner-border-sm" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  `;
  downloadButton.disabled = true;
  nameDownloadButton.style.pointerEvents = "none";

  await fetch("/herramientas/leadsDocumentos/ajax/downloadFile", requestOptions)
    .then((response) => {
      if (response.status !== 200) {
        throw "Error al descargar el archivo";
      }
      const filename = response.headers.get("Content-Disposition").split("filename=")[1].replace(/['"]/g, "");
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
        a.remove();
      });
    })
    .catch(() => {
      createToast("error", "Error ❌ ", "❌ No ha sido posible descargar el archivo, intentelo más tarde...", 5000);
    });

  downloadButton.innerHTML = `<i class="fas fa-download fa-lg" style="pointer-events: none;"></i>`;
  downloadButton.disabled = false;
  nameDownloadButton.style.pointerEvents = "auto";
}

async function downloadFolder(leadId, path) {
  let formData = new FormData();

  formData.append("path", path);
  formData.append("id_lead", leadId);

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    body: formData,
  };

  const downloadButton = document.querySelector(`[data-action="download-folder"][data-path="${path}"][data-btn="true"]`);
  downloadButton.innerHTML = `
    <div class="spinner-border spinner-border-sm" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  `;
  downloadButton.disabled = true;

  await fetch("/herramientas/leadsDocumentos/ajax/downloadFolder", requestOptions)
    .then((response) => {
      if (response.status !== 200) {
        throw "Error al descargar el carpeta";
      }
      const filename = response.headers.get("Content-Disposition").split("filename=")[1].replace(/['"]/g, "");
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      });
    })
    .catch(() => {
      createToast("error", "Error ❌ ", "❌ No ha sido posible descargar el carpeta, intentelo más tarde...", 5000);
    });

  downloadButton.innerHTML = `<i class="fas fa-download fa-lg" style="pointer-events: none;"></i>`;
  downloadButton.disabled = false;
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname.includes("/herramientas/anf/documentacion/")) {
      document.getElementById("lead-documents-table-body").addEventListener("click", (event) => {
        const leadId = document.getElementById("lead-id").value;
        const action = event.target.dataset.action;
        const path = event.target.dataset.path;

        switch (action) {
          case "download":
            downloadFile(leadId, path);
            break;
          case "download-folder":
            downloadFolder(leadId, path);
            break;
          default:
            return;
        }
      });

      const leadId = document.getElementById("lead-id").value;
      const tableBody = document.getElementById("lead-documents-table-body");
      openFolder(leadId, tableBody);
    }
  });
}
