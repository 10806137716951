"use strict";

export async function validateIban(ibanString) {
  const response = await fetch(`/api/validation/iban?value=${ibanString}`, { method: "GET" });
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}
