import ClientErrorResponse from "../domain/clientErrorResponse";
import ServerErrorResponse from "../domain/serverErrorResponse";
import ApiRequestError from "../domain/apiRequestError";

function isClientErrorResponse(data: unknown): data is ClientErrorResponse {
  return typeof data === "object" && data !== null && "message" in data && typeof (data as Record<string, unknown>).message === "string";
}

function isServerErrorResponse(data: unknown): data is ServerErrorResponse {
  return typeof data === "object" && data !== null && "message" in data && typeof (data as Record<string, unknown>).message === "string";
}

/**
 * Handle the response from the API and return the data if the response is OK, otherwise throw an error with the response status and message
 * The error type is determined by the response status code
 * @param response
 * @returns The data from the response if the response is OK (status code 200-299) as a promise
 * @throws ApiError if the response is not OK (status code 400-599) with the status code and message from the response data (>599 status codes are handled as unknown errors)
 */
export default async function handleApiResponse<T>(response: Response): Promise<T> {
  const responseData: unknown = await response.json();

  if (!response.ok) {
    if (response.status >= 400 && response.status < 500) {
      if (isClientErrorResponse(responseData)) {
        throw new ApiRequestError(responseData.message, response.status, "clientError", responseData);
      }
      throw new ApiRequestError("Unknown error", response.status, "clientError", { message: "Unknown client error message" });
    }

    if (response.status >= 500 && response.status < 600) {
      if (isServerErrorResponse(responseData)) {
        throw new ApiRequestError(responseData.message, response.status, "serverError", responseData);
      }
      throw new ApiRequestError("Unknown error", response.status, "serverError", { message: "Unknown server error message" });
    }

    throw new ApiRequestError("Unknown error", response.status, "unknownError", { message: "Unknown error message with unknown status" });
  }

  return responseData as T;
}
