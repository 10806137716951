"use strict";

import { showLoadingButton, updateButtonLabel } from "../shared/shared.js";
import { downloadInscriptionTicket } from "./corporate_event_inscription.js";

const corporateEventsAvailable = "corporate-events-available";
const corporateEventsFinished = "corporate-events-finished";
const inscriptionCodeModal = "modal-corporate-event-inscription-code";
const corporateEventInfoModal = "modal-corporate-event-data";
const corporateEventInscriptionConfirmModal = "modal-inscription-event";

function _removeAllEventListeners(element) {
  const newElement = element.cloneNode(true);
  element.replaceWith(newElement);
  return newElement;
}

async function loadEventsData() {
  const responseEvents = await fetchEventsData();

  if (responseEvents.success === false) {
    createToast("error", "Error ❌", "Ha ocurrido un error al cargar los eventos.", 5000);
    return;
  }

  const responseInscriptions = await fetchInscriptionsData();

  if (responseInscriptions.success === false) {
    createToast("error", "Error ❌", "Ha ocurrido un error al cargar las inscripciones.", 5000);
    return;
  }

  printEventsData(responseEvents.events, responseInscriptions.events);

  document.getElementById("load-" + corporateEventsAvailable).classList.add("d-none");
  document.getElementById(corporateEventsAvailable).classList.remove("d-none");
}

async function loadEventsFinishedData() {
  const responseEvents = await fetchEventsFinishedData();

  if (responseEvents.success === false) {
    createToast("error", "Error ❌", "Ha ocurrido un error al cargar los eventos finalizados.", 5000);
    return;
  }

  printEventsFinishedData(responseEvents.events);

  document.getElementById("load-" + corporateEventsFinished).classList.add("d-none");
  document.getElementById(corporateEventsFinished).classList.remove("d-none");
}

function printEventsData(eventsAvailable, eventsWithInscription) {
  const corporateEventsList = document.getElementById(corporateEventsAvailable + "-list");
  corporateEventsList.innerHTML = "";

  if (eventsAvailable.length === 0) {
    corporateEventsList.insertAdjacentHTML(
      "beforeend",
      `
      <div class="col-12 text-center my-5">
        <h3 class="text-barymont-black mb-0">No hay eventos disponibles a los que puedas inscribirte actualmente.</h3>
      </div>
      `
    );
    return;
  }

  let buttonsEntry = "";
  eventsAvailable.forEach((event) => {
    buttonsEntry = "";

    const eventInscription = eventsWithInscription.find((eventWithInscription) => eventWithInscription.corporateEventId === event.corporateEventId);

    if (eventInscription !== undefined) {
      buttonsEntry = `

        <div class="col-6 my-2 pr-2">
          <button id="download-${eventInscription.corporateEventInscriptionId}" data-action="download" data-inscription-id="${eventInscription.corporateEventInscriptionId}" class="btn btn-barymont-black w-100" style="font-size: 12px;">
            <i class="fa-solid fa-download mr-2"></i>
            Ticket
          </button>
        </div>

        <div class="col-6 my-2 pl-2">
          <button id="open-${eventInscription.corporateEventInscriptionId}" data-action="open" data-inscription-id="${eventInscription.corporateEventInscriptionId}" class="btn btn-barymont-red w-100" style="font-size: 12px;">
            <i class="fa-solid fa-qrcode mr-2"></i>
            Ver QR
          </button>
        </div>

        <div class="col-12 my-2">
          <button id="info-${event.corporateEventId}" data-action="info" data-event-id="${event.corporateEventId}" class="btn btn-barymont-grey w-100" style="font-size: 12px;">
            <i class="fa-solid fa-info mr-2"></i>
            Información
          </button>
        </div>
        `;

      /*
      buttonsEntry += `
          <input type="hidden" id="impulsa-inscription-url-${event.corporateEventId}" value="${eventInscription.barymontImpulsaDefaultUrl}">

          <button type="button" class="btn btn-barymont-black btn-block my-2"
            data-input-id-to-copy="#impulsa-inscription-url-${event.corporateEventId}"
            data-toggle="tooltip"
            title=""
            data-original-title="Copiar enlace al portapapeles"
          >
            <i class="fa-sm fas fa-users pr-2"></i> Copiar enlace de inscripción para <strong>Invitados</strong> de <strong>Bárymont Impulsa</strong>
          </button>
          `;
      */

      if (eventInscription.guestInscriptionUrl !== null) {
        buttonsEntry += `
        <input type="hidden" id="guestInscriptionUrl-${event.corporateEventId}" value="${eventInscription.guestInscriptionUrl}">

        <button type="button" class="btn btn-barymont-black btn-block my-2"
          data-input-id-to-copy="#guestInscriptionUrl-${event.corporateEventId}"
          data-toggle="tooltip"
          title=""
          data-original-title="Copiar enlace al portapapeles"
        >
          <i class="fa-sm fas fa-users pr-2"></i> Copiar enlace de inscripción para tus <strong>Invitados</strong>
        </button>
        `;
      }

      if (eventInscription.accompanyingInscriptionUrl !== null) {
        buttonsEntry += `
        <input type="hidden" id="accompanyingInscriptionUrl-${event.corporateEventId}" value="${eventInscription.accompanyingInscriptionUrl}">

        <button type="button" class="btn btn-barymont-black btn-block my-2"
          data-input-id-to-copy="#accompanyingInscriptionUrl-${event.corporateEventId}"
          data-toggle="tooltip"
          title=""
          data-original-title="Copiar enlace al portapapeles"
        >
          <i class="fa-sm fas fa-users pr-2"></i> Copiar enlace de inscripción para tus <strong>Acompañantes</strong>
        </button>
        `;
      }
    } else {
      buttonsEntry = `
        <div class="col-4">
          <button id="info-${event.corporateEventId}" data-action="inscription" data-event-id="${event.corporateEventId}" class="btn btn-barymont-black w-100" style="font-size: 12px;">
            <i class="fa-solid fa-info mr-2"></i>
            Inscribirme
          </button>
        </div>`;
    }

    corporateEventsList.insertAdjacentHTML(
      "beforeend",
      `
        <div class="col-lg-4 col-md-6 col-12 my-4">
          <div class="card clickable-box text-barymont-black" style="overflow: hidden;">

              <div class="card-body p-0" style="overflow: hidden; background-image: url('${event.corporateEventLogoPath}'); background-size: cover; background-position: center; height: 250px;">
              </div>

              <div class="card-footer bg-barymont-white text-center">
                  <div class="container-fluid px-0">
                      <div class="col-12 my-1">
                          <div class="col-12 text-left">
                            <p class="font-weight-bold py-1 px-2 mb-0 text-center" style="font-size: 15px; text-wrap:balance">${event.name}</p>
                            <p class="py-1 px-2 mb-0 text-center" style="font-size: 13px;">${event.startDate.split(" ")[0]}</p>
                          </div>
                          <div class="row no-gutters d-flex mt-2 justify-content-center">
                            ${buttonsEntry}
                          </div>
                      </div>
                 </div>
              </div>

          </div>
        </div>
      `
    );
  });

  document.querySelectorAll("[data-input-id-to-copy]").forEach((button) => {
    button.addEventListener("click", function () {
      copyInputValueToClipboard(button.getAttribute("data-input-id-to-copy"));
      createToast("success", "Éxito ✅", "Enlace copiado al portapapeles.", 5000);
    });
  });

  document.querySelectorAll("[data-action='download']").forEach((button) => {
    button.addEventListener("click", async function () {
      showLoadingButton(button.id, "btn-barymont-black");
      await downloadInscriptionTicket(button.getAttribute("data-inscription-id"));
      updateButtonLabel(button.id, "<i class='fa-solid fa-download mr-2'></i>Ticket", "btn-barymont-black");
    });
  });

  document.querySelectorAll("[data-action='open']").forEach((button) => {
    button.addEventListener("click", async function () {
      showLoadingButton(button.id, "btn-barymont-red");
      await loadInscriptionCode(button.getAttribute("data-inscription-id"));
      updateButtonLabel(button.id, "<i class='fa-solid fa-qrcode mr-2'></i>Ver QR", "btn-barymont-red");
    });
  });

  document.querySelectorAll("[data-action='info']").forEach((button) => {
    button.addEventListener("click", async function () {
      showLoadingButton(button.id, "btn-barymont-black");
      await loadEventInfo(button.getAttribute("data-event-id"));
      updateButtonLabel(button.id, "<i class='fa-solid fa-info mr-2'></i>Información", "btn-barymont-grey");
    });
  });

  document.querySelectorAll("[data-action='inscription']").forEach((button) => {
    button.addEventListener("click", async function () {
      showLoadingButton(button.id, "btn-barymont-black");
      await loadEventInfo(button.getAttribute("data-event-id"));
      updateButtonLabel(button.id, "<i class='fa-solid fa-info mr-2'></i>Inscribirme", "btn-barymont-black");
    });
  });
}

function printEventsFinishedData(eventsFinished) {
  const corporateEventsFinishedList = document.getElementById(corporateEventsFinished + "-list");

  if (eventsFinished.length === 0) {
    corporateEventsFinishedList.insertAdjacentHTML(
      "beforeend",
      `
      <div class="col-12 text-center my-5">
        <h3 class="text-barymont-black mb-0">No hay eventos finalizados en los cuales te hayas inscrito.</h3>
      </div>
      `
    );
    return;
  }

  eventsFinished.forEach((event) => {
    corporateEventsFinishedList.insertAdjacentHTML(
      "beforeend",
      `
        <div class="col-lg-4 col-md-6 col-12 my-4">
          <div class="card clickable-box text-barymont-black">
            <div class="card-body p-0" style="overflow: hidden; background-image: url('${event.corporateEventLogoPath}'); background-size: cover; background-position: center; height: 250px;">
            </div>
            <div class="card-footer bg-barymont-white text-center">
              <div class="container-fluid px-0">
                <div class="row no-gutters">
                  <div class="col-12 text-left">
                    <p class="font-weight-bold py-1 px-2 mb-0 text-center" style="font-size: 15px; text-wrap:balance">${event.name}</p>
                    <p class="px-2 mb-0 text-center" style="font-size: 13px;">${event.startDate.split(" ")[0]}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>`
    );
  });
}

async function loadEventInfo(eventId) {
  const responseEventData = await fetchEventInfo(eventId);

  if (responseEventData.success === false) {
    createToast("error", "Error ❌", "Ha ocurrido un error al cargar la información del evento.", 5000);
    return;
  }

  const responseEventInscriptionData = await fetchEventInscriptionInfo(eventId);

  if (responseEventInscriptionData.success === false) {
    createToast("error", "Error ❌", "Ha ocurrido un error al cargar la información de la inscripción.", 5000);
    return;
  }

  let responseAccompanyingData = null;
  if (responseEventInscriptionData.corporateEventInscription !== null) {
    responseAccompanyingData = await fetchEventAccompanyingInscriptionInfo(responseEventData.corporateEvent.corporateEventId, responseEventInscriptionData.corporateEventInscription.corporateEventInscriptionId);

    if (responseAccompanyingData.success === false) {
      createToast("error", "Error ❌", "Ha ocurrido un error al cargar la información de la inscripción acompañante.", 5000);
      return;
    }
  }

  printEventInfo(responseEventData.corporateEvent, responseEventInscriptionData.corporateEventInscription, responseEventInscriptionData?.corporateEventCode, responseAccompanyingData?.corporateEventInscriptionAccompanying);

  $("#" + corporateEventInfoModal).modal("show");
}

function printEventInfo(event, eventInscription, eventInscriptionCode, accompanyingData) {
  let modalEventInfoBody = document.getElementById(corporateEventInfoModal + "-body");

  modalEventInfoBody.innerHTML = "";

  if (eventInscription === null) {
    modalEventInfoBody.insertAdjacentHTML(
      "beforeend",
      `
      <div class="row">
      <div class="col-12 text-center">
            <img src="${event.corporateEventLogoPath}" class="w-50" alt="${event.name}">
            <h4 class="text-barymont-black mt-3 font-weight-bolder">${event.name}</h4>
            <div class="d-flex justify-content-around">
              <p class="text-barymont-black font-weight-bolder">Fecha de inicio: ${event.startDate.split(" ")[0]}</p>
              <p class="text-barymont-black font-weight-bolder">Fecha de fin: ${event.finishDate.split(" ")[0]}</p>
            </div>
          </div>
        </div>
        `
    );

    document.getElementById("btn-download-inscription").classList.add("d-none");
    document.getElementById("btn-inscription").classList.remove("d-none");

    let addInscriptionBtn = document.getElementById("btn-inscription");
    const addInscriptionBtnClone = addInscriptionBtn.cloneNode(true);

    addInscriptionBtnClone.addEventListener("click", async function () {
      const responseCheckUserData = await fetchCheckUserData();

      if (responseCheckUserData.success === false) {
        document.getElementById(corporateEventInscriptionConfirmModal + "-phone-col").classList.remove("d-none");
        document.getElementById(corporateEventInscriptionConfirmModal + "-phone").required = true;
      } else {
        document.getElementById(corporateEventInscriptionConfirmModal + "-phone-col").classList.add("d-none");
        document.getElementById(corporateEventInscriptionConfirmModal + "-phone").required = false;
      }

      $("#" + corporateEventInscriptionConfirmModal).modal("show");

      let confirmBtn = _removeAllEventListeners(document.getElementById(corporateEventInscriptionConfirmModal + "-confirm"));

      confirmBtn.addEventListener("click", async function () {
        showLoadingButton(corporateEventInscriptionConfirmModal + "-confirm", "btn-barymont-black");
        await addInscription(event.corporateEventId);
        updateButtonLabel(corporateEventInscriptionConfirmModal + "-confirm", "<i class='fa-solid fa-check mr-2'></i>Confirmar", "btn-barymont-black");
        $("#" + corporateEventInscriptionConfirmModal).modal("hide");
        $("#" + corporateEventInfoModal).modal("hide");
      });
    });

    addInscriptionBtn.parentNode.replaceChild(addInscriptionBtnClone, addInscriptionBtn);
  } else {
    const colors = {
      Gestor: "#198754",
      "Acompañante de gestor": "#75b798",
      Planificador: "#3d8bfd",
      "Acompañante de planificador": "#6ea8fe",
      Invitado: "#fd9843",
      "Invitado de Red": "#fd7e14",
      Staff: "#8c68cd",
    };

    let accompanyingInscriptions = "";
    if (accompanyingData.length > 0) {
      accompanyingData.forEach((accompanying) => {
        let backgroundColor = colors[accompanying.corporateEventPhaseLabel];
        accompanyingInscriptions += `
        <div class="col-12 my-1">
          <div class="row">
            <div class="col-12 col-md-2 text-center">
              <span class="badge badge-secondary w-100 py-2">${accompanying.createdAt}</span>
            </div>
            <div class="col-7 col-md-7 text-center">
              <span class="text-barymont-black font-weight-bolder">${accompanying.name + " " + accompanying.firstSurname + " " + accompanying.secondSurname}</span>
            </div>
            <div class="col-5 col-md-3 text-center">
              <span class="badge w-100 py-2" style="background-color: ${backgroundColor}; color: #FFFFFF;">${accompanying.corporateEventPhaseLabel}</span>
            </div>
          </div>
        </div>`;
      });
    } else {
      accompanyingInscriptions = `
        <div class="col-12 text-center">
          <span class="text-barymont-black font-weight-bolder">No hay inscripciones de acompañantes.</span>
        </div>`;
    }

    modalEventInfoBody.insertAdjacentHTML(
      "beforeend",
      `
        <div class="row">
          <div class="col-md-8 col-12 text-center">
            <img src="${event.corporateEventLogoPath}" class="w-75" alt="${event.name}">
            <h4 class="text-barymont-black mt-3 font-weight-bolder">${event.name}</h4>
            <div class="d-flex justify-content-around">
              <p class="text-barymont-black font-weight-bolder">Fecha de inicio: ${event.startDate.split(" ")[0]}</p>
              <p class="text-barymont-black font-weight-bolder">Fecha de fin: ${event.finishDate.split(" ")[0]}</p>
            </div>
          </div>
          <div class="col-md-4 col-12 d-flex flex-column align-items-center justify-content-center text-center">
            <img src="${eventInscriptionCode}" class="w-100" alt="${eventInscription.accessCode}">
            <p class="text-barymont-black text-center">${eventInscription.accessCode}</p>
            <p class="badge badge-secondary text-white font-weight-bolder" style="font-size: 15px">Código de Referido: ${eventInscription.inscriptionCode}</p>
          </div>
          <div class="col-12 text-center">
            <h5 class="text-barymont-black mt-3 font-weight-bolder bg-barymont-grey py-3 my-2">Inscripciones de mi equipo</h5>
            <div class="row">
              <div class="scroll-barymont-red w-100" style="max-height: 300px; overflow-y: scroll;">
                ${accompanyingInscriptions}
              </div>
            </div>
          </div>
        </div>
        `
    );

    document.getElementById("btn-download-inscription").classList.remove("d-none");
    document.getElementById("btn-inscription").classList.add("d-none");

    let downloadInscriptionBtn = document.getElementById("btn-download-inscription");
    const downloadInscriptionBtnClone = downloadInscriptionBtn.cloneNode(true);

    downloadInscriptionBtnClone.addEventListener("click", async function () {
      showLoadingButton("btn-download-inscription", "btn-barymont-black");
      await downloadInscriptionTicket(eventInscription.corporateEventInscriptionId);
      updateButtonLabel("btn-download-inscription", "<i class='fa-solid fa-download mr-2'></i>Ticket", "btn-barymont-black");
    });

    downloadInscriptionBtn.parentNode.replaceChild(downloadInscriptionBtnClone, downloadInscriptionBtn);
  }
}

async function loadInscriptionCode(corporateInscriptionId) {
  const response = await fetchInscriptionCode(corporateInscriptionId);

  if (response.success === false) {
    createToast("error", "Error ❌", "Ha ocurrido un error al cargar el código de inscripción.", 5000);
    return;
  }

  let modalCodeInscriptionBody = document.getElementById(inscriptionCodeModal + "-body");
  modalCodeInscriptionBody.innerHTML = "";

  modalCodeInscriptionBody.insertAdjacentHTML(
    "beforeend",
    `
    <div class="row">
      <div class="col-12 text-center">
        <img src="${response.corporateEventInscriptionCode}" alt="qr-code" style="width: 50%; object-fit: cover; object-position: center;">
        <p class="text-barymont-black mt-3">${response.corporateEventInscriptionAccessCode}</p>
        </div>
      </div>
    </div>`
  );

  $("#" + inscriptionCodeModal).modal("show");
}

async function addInscription(eventId) {
  const name = document.getElementById(corporateEventInscriptionConfirmModal + "-name").value;
  const surname = document.getElementById(corporateEventInscriptionConfirmModal + "-surname").value;
  const secondSurname = document.getElementById(corporateEventInscriptionConfirmModal + "-second-surname").value;
  const phoneInput = document.getElementById(corporateEventInscriptionConfirmModal + "-phone");

  if (name === "" || surname === "" || (phoneInput.required && phoneInput.value === "")) {
    createToast("error", "Error ❌", "Debes completar los campos obligatorios para poder inscribirte.", 5000);
    return;
  }

  const responseAddInscription = await fetchAddInscription(eventId, name, surname, secondSurname, phoneInput.value);

  if (responseAddInscription.success === false) {
    createToast("error", "Error ❌", responseAddInscription.message, 5000);
    return;
  }

  createToast("success", "Éxito ✅", "Te has inscrito correctamente en el evento.", 5000);

  await loadEventsData();
}

async function fetchInscriptionCode(corporateInscriptionId) {
  let formData = new FormData();
  formData.append("inscriptionId", corporateInscriptionId);

  return await fetch("/herramientas/corporate-event/ajax/getInscriptionCode", {
    method: "POST",
    body: formData,
  }).then((response) => response.json());
}

async function fetchEventsData() {
  return await fetch("/herramientas/corporate-event/ajax/getEventsData", {
    method: "POST",
  }).then((response) => response.json());
}

async function fetchInscriptionsData() {
  return await fetch("/herramientas/corporate-event/ajax/getInscriptionsData", {
    method: "POST",
  }).then((response) => response.json());
}

async function fetchEventsFinishedData() {
  return await fetch("/herramientas/corporate-event/ajax/getEventsInscriptionFinishedData", {
    method: "POST",
  }).then((response) => response.json());
}

async function fetchEventInfo(eventId) {
  let formData = new FormData();
  formData.append("corporateEventId", eventId);

  return await fetch("/herramientas/corporate-event/ajax/getEventInfo", {
    method: "POST",
    body: formData,
  }).then((response) => response.json());
}

async function fetchEventInscriptionInfo(eventId) {
  let formData = new FormData();
  formData.append("corporateEventId", eventId);

  return await fetch("/herramientas/corporate-event/ajax/getEventInscriptionInfo", {
    method: "POST",
    body: formData,
  }).then((response) => response.json());
}

async function fetchEventAccompanyingInscriptionInfo(eventId, inscriptionId) {
  let formData = new FormData();
  formData.append("corporateEventId", eventId);
  formData.append("corporateEventInscriptionId", inscriptionId);

  return await fetch("/herramientas/corporate-event/ajax/getEventAccompanyingInscriptionInfo", {
    method: "POST",
    body: formData,
  }).then((response) => response.json());
}

async function fetchAddInscription(eventId, name, surname, secondSurname, phone) {
  let formData = new FormData();
  formData.append("corporateEventId", eventId);
  formData.append("name", name);
  formData.append("surname", surname);
  formData.append("secondSurname", secondSurname);
  formData.append("phone", phone);

  return await fetch("/herramientas/corporate-event/ajax/addInscription", {
    method: "POST",
    body: formData,
  }).then((response) => response.json());
}

async function fetchCheckUserData() {
  return await fetch("/herramientas/corporate-event/ajax/checkUserData", {
    method: "POST",
  }).then((response) => response.json());
}

if (window) {
  window.addEventListener("load", async function () {
    if (window.location.pathname === "/corporate-events") {
      await loadEventsData();
      await loadEventsFinishedData();
    }
  });
}
