import ApiRequestError from "../../../api/core/domain/apiRequestError";
import paymentRequest from "../../../api/financialPlanningService/application/paymentRequest";

export const enum CreationPaymentResult {
  SUCCESS = "SUCCESS",
  TOO_MANY_REQUESTS = "TOO_MANY_REQUESTS",
  ERROR = "ERROR",
}

export default async function createPaymentRequest(leadId: string, subscriptionPeriodicity: "MONTHLY" | "YEARLY", promoterUserId: string, planifierUserId: string, isClientBuyingAsBusiness: boolean, isClientTaxExempt: boolean): Promise<CreationPaymentResult> {
  try {
    await paymentRequest({
      leadId,
      subscriptionPeriodicity,
      promoterUserId,
      planifierUserId,
      isClientBuyingAsBusiness,
      isClientTaxExempt,
    });
  } catch (error) {
    if (error instanceof ApiRequestError && error.status === 429) {
      return CreationPaymentResult.TOO_MANY_REQUESTS;
    } else {
      console.error("Error processing payment request:", error);
      return CreationPaymentResult.ERROR;
    }
  }

  return CreationPaymentResult.SUCCESS;
}
