"use strict";

import Sortable from "sortablejs";
import { createChecklistTemplate, createChecklistTemplateCompletionCheck, deleteChecklistTemplate, deleteChecklistTemplateCompletionCheck, readChecklistTemplateCompletionChecks, updateChecklistTemplate, updateChecklistTemplateCompletionCheck, updateChecklistTemplateCompletionCheckOrder } from "../../api/checklist_management";
import { escapeHtml, showLoadingButton, updateButtonLabel } from "../shared/shared";

var selectedRow = null;

async function paintChecklistTemplatesData() {
  let datatable = $("#checklist-template-table").DataTable({
    language: {
      sProcessing: "Procesando Datos...",
      sLengthMenu: "Mostrar _MENU_ plantillas",
      sZeroRecords: "No se encontraron plantillas coincidentes con el criterio de búsqueda.",
      sEmptyTable: "Ninguna inscripción coincidente con el criterio de búsqueda.",
      sInfo: "Mostrando plantillas del _START_ al _END_ de un total de _TOTAL_ plantillas",
      sInfoEmpty: "Mostrando Plantillas del 0 al 0 de un total de 0 Plantillas",
      sInfoFiltered: "(Filtrados de un total de _MAX_ Plantillas)",
      sInfoPostFix: "",
      sSearch: "<div class='input-group-prepend'><div class='input-group-text'><i class='fas fa-search'></i></div></div>",
      searchPlaceholder: "Ingrese su búsqueda...",
      sUrl: "",
      sInfoThousands: ",",
      sLoadingRecords: "Cargando Datos...",
      oPaginate: {
        sFirst: "Primera",
        sLast: "Última",
        sNext: "Siguiente",
        sPrevious: "Anterior",
      },
      oAria: {
        sSortAscending: ": Activar para ordenar la columna de manera ascendente",
        sSortDescending: ": Activar para ordenar la columna de manera descendente",
      },
      buttons: {
        copySuccess: {
          1: "Copiado 1 plantillas",
          _: "Copiados %d plantillas al Portapapeles",
        },
        copyTitle: "Plantillas Copiados al Portapapeles",
      },
      select: {
        rows: "",
      },
    },
    pagingType: "input",
    columnDefs: [
      {
        targets: [1],
        searchable: true,
        orderable: true,
      },
      {
        targets: [0, 2],
        searchable: true,
        orderable: false,
      },
      {
        targets: [3],
        searchable: false,
        orderable: true,
      },
      {
        targets: [4],
        searchable: false,
        orderable: false,
      },
      {
        targets: [0, 1, 2, 3, 4],
        className: "dt-center",
      },
      {
        targets: [0],
        visible: false,
      },
    ],
    select: {
      style: "single",
      selector: "td:nth-child(1)",
      className: "clickable",
    },
    order: [[3, "desc"]],
    processing: true,
    serverSide: true,
    scrollX: true,
    dom: '<"top"fl>Brt<"bottom"ip><"clear">',
    buttons: [
      {
        extend: "colvis",
        text: "Ver más columnas",
      },
      {
        text: '<i class="fas fa-plus mr-2"></i> Crear Plantilla',
        className: "btn btn-barymont-red my-2 my-md-0",
        action: function () {
          createChecklistTemplateModal();
        },
        init: function (api, node) {
          $(node).removeClass("dt-button");
        },
      },
    ],
    pageLength: 15,
    lengthMenu: [
      [15, 30, 50, 100],
      [15, 30, 50, 100],
    ],
    keys: true,
    searchHighlight: true,
    ajax: {
      url: "/herramientas/checklist-management/listprocessing",
      type: "post",
      error: function (e) {
        if (e.status === 401) {
          createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
          setTimeout(function () {
            location.reload();
          }, 5000);
        } else {
          createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
        }
      },
    },
    stateSave: true,
    createdRow: function (row, data) {
      $(row).attr("data-id", data[0]);
    },
    fnDrawCallback: function () {
      const elementDatatable = document.getElementById("checklist-template-table");

      elementDatatable.querySelectorAll("tbody tr").forEach((r) => {
        r.classList.remove("clickable");
        r.style.backgroundColor = "transparent";
      });

      elementDatatable.querySelectorAll("tbody tr").forEach((row) => {
        row.addEventListener("click", function (e) {
          const clickedCell = e.target.closest("td");
          const firstCell = row.querySelector("td");

          if (!clickedCell || clickedCell !== firstCell) {
            return;
          }

          if (row.classList.contains("clickable")) {
            return;
          }

          elementDatatable.querySelectorAll("tbody tr").forEach((r) => {
            r.style.backgroundColor = "transparent";
          });

          row.style.backgroundColor = "var(--barymont-green)";
        });

        if (row.getAttribute("data-id") === selectedRow) {
          row.classList.add("clickable");
          row.style.backgroundColor = "var(--barymont-green)";
        }
      });

      generateDatatableEventListeners();
      paintChecklistTemplateCompletionChecks(selectedRow);
    },
  });

  datatable.on("select", function (e, dt, type, indexes) {
    if (type === "row") {
      let data = datatable.rows(indexes).data().toArray();
      paintChecklistTemplateCompletionChecks(data[0][0]);
      selectedRow = data[0][0];
    }
  });
}

function createEditChecklistTemplateModal(checklistTemplateId, checklistTemplateTitle) {
  const randomModalId = `modal-${Math.random().toString(36).substring(2, 10)}`;
  const fragment = document.createDocumentFragment();

  const temporalDiv = document.createElement("div");
  temporalDiv.innerHTML = `
    <div class="modal fade" id="${randomModalId}" tabindex="-1" role="dialog" data-backdrop="static">
      <div class="modal-xl modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Editar Plantilla</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div id="modal-body-${randomModalId}" class="modal-body">

            <div class="form-group d-flex align-items-center mb-0">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text bg-light">Título de la Plantilla</div>
                </div>
                <textarea
                  id="checklist-template-title"
                  type="text"
                  class="form-control"
                  rows="5"
                  placeholder="Escribe un titulo para el checklist"
                  required
                >${checklistTemplateTitle}</textarea>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button id="modal-edit-checklist-template" type="button" class="btn btn-barymont-red" data-action="edit" data-id="${checklistTemplateId}">Editar</button>
            <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>`;

  fragment.appendChild(temporalDiv.firstElementChild);
  document.body.appendChild(fragment);

  const modalElement = $(`#${randomModalId}`).modal("show");

  modalElement.on("shown.bs.modal", function () {
    document.getElementById("checklist-template-title").focus();
  });

  modalElement.on("hidden.bs.modal", function () {
    modalElement.remove();
  });

  const editButton = document.querySelector(`#modal-edit-checklist-template`);
  editButton.addEventListener("click", async function () {
    const checklistTemplateTitleModal = document.getElementById("checklist-template-title").value;
    try {
      showLoadingButton(editButton.id, "btn-barymont-red", true);
      const response = await updateChecklistTemplate(checklistTemplateId, checklistTemplateTitleModal);
      createToast("success", "Plantilla Editada", response.message, 5000);
      $("#checklist-template-table").DataTable().ajax.reload();

      modalElement.modal("hide");
    } catch (error) {
      createToast("error", "Error", error.message, 5000);
      updateButtonLabel(editButton.id, "Editar", "btn-barymont-red");
    }
  });
}

function createDeleteChecklistTemplateModal(checklistTemplateId, checklistTemplateTitle) {
  const randomModalId = `modal-${Math.random().toString(36).substring(2, 10)}`;
  const fragment = document.createDocumentFragment();

  const temporalDiv = document.createElement("div");
  temporalDiv.innerHTML = `
    <div class="modal fade" id="${randomModalId}" tabindex="-1" role="dialog" data-backdrop="static">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Eliminar Plantilla</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div id="modal-body-${randomModalId}" class="modal-body text-center">
            <span>¿Estás seguro de que deseas eliminar la plantilla <strong>${checklistTemplateTitle}</strong>?</span>
          </div>
          <div class="modal-footer">
            <button id="modal-delete-checklist-template" type="button" class="btn btn-barymont-red" data-action="delete" data-id="${checklistTemplateId}">Eliminar</button>
            <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>`;

  fragment.appendChild(temporalDiv.firstElementChild);
  document.body.appendChild(fragment);

  const modalElement = $(`#${randomModalId}`).modal("show");

  modalElement.on("hidden.bs.modal", function () {
    modalElement.remove();
  });

  const deleteButton = document.querySelector(`#modal-delete-checklist-template`);
  deleteButton.addEventListener("click", async function () {
    try {
      showLoadingButton(deleteButton.id, "btn-barymont-red", true);
      const response = await deleteChecklistTemplate(checklistTemplateId);
      createToast("success", "Plantilla Eliminada", response.message, 5000);
      $("#checklist-template-table").DataTable().ajax.reload();

      modalElement.modal("hide");
    } catch (error) {
      createToast("error", "Error", error.message, 5000);
      updateButtonLabel(deleteButton.id, "Eliminar", "btn-barymont-red");
    }
  });
}

function createChecklistTemplateModal() {
  const randomModalId = `modal-${Math.random().toString(36).substring(2, 10)}`;
  const fragment = document.createDocumentFragment();

  const temporalDiv = document.createElement("div");
  temporalDiv.innerHTML = `
    <div class="modal fade" id="${randomModalId}" tabindex="-1" role="dialog" data-backdrop="static">
      <div class="modal-xl modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Crear Plantilla</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div id="modal-body-${randomModalId}" class="modal-body">

            <div class="form-group d-flex align-items-center mb-0">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text bg-light">Título de la Plantilla</div>
                </div>
                 <textarea
                  id="create-checklist-template-title"
                  type="text"
                  class="form-control"
                  rows="5"
                  placeholder="Escribe un titulo para el checklist"
                  required
                ></textarea>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button id="modal-create-checklist-template" type="button" class="btn btn-barymont-red">Guardar</button>
            <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>`;

  fragment.appendChild(temporalDiv.firstElementChild);
  document.body.appendChild(fragment);

  const modalElement = $(`#${randomModalId}`).modal("show");

  modalElement.on("shown.bs.modal", function () {
    document.getElementById("create-checklist-template-title").focus();
  });

  modalElement.on("hidden.bs.modal", function () {
    modalElement.remove();
  });

  const createButton = document.querySelector(`#modal-create-checklist-template`);
  createButton.addEventListener("click", async function () {
    try {
      showLoadingButton(createButton.id, "btn-barymont-red", true);

      const checklistTemplateTitle = document.getElementById("create-checklist-template-title").value;
      const response = await createChecklistTemplate(checklistTemplateTitle);
      createToast("success", "Plantilla Creada", response.message, 5000);
      $("#checklist-template-table").DataTable().ajax.reload();

      modalElement.modal("hide");
    } catch (error) {
      createToast("error", "Error", error.message, 5000);
      updateButtonLabel(createButton.id, "Guardar", "btn-barymont-red");
    }
  });
}

function generateDatatableEventListeners() {
  const datatable = document.getElementById("checklist-template-table");

  datatable.querySelectorAll("[data-action='edit']").forEach((element) => {
    element.addEventListener("click", function (e) {
      e.stopPropagation();
      const id = this.getAttribute("data-id");
      const title = this.getAttribute("data-title");
      createEditChecklistTemplateModal(id, title);
    });
  });

  datatable.querySelectorAll("[data-action='delete']").forEach((element) => {
    element.addEventListener("click", function (e) {
      e.stopPropagation();
      const id = this.getAttribute("data-id");
      const title = this.getAttribute("data-title");
      createDeleteChecklistTemplateModal(id, title);
    });
  });
}

async function paintChecklistTemplateCompletionChecks(checklistTemplateId = null) {
  const checklistTemplateCompletionChecksTable = document.getElementById("checklist-template-completion-checks-table");
  checklistTemplateCompletionChecksTable.innerHTML = "";

  if (checklistTemplateId === null) {
    checklistTemplateCompletionChecksTable.innerHTML = `
        <tr>
          <td colspan="2" class="py-2 px-0">
            <div class="alert alert-info text-center" role="alert">
              Selecciona alguna plantilla para poder ver sus checks asociados.
            </div>
          </td>
        </tr>
      `;
    return;
  }

  const loaderChecklistTemplateCompletionChecks = document.getElementById("load-checklist-template-completion-checks-data");

  loaderChecklistTemplateCompletionChecks.innerHTML = `
                                        <div class="d-flex justify-content-center my-3">
                                          <div class="spinner-border text-barymont-red" role="status">
                                              <span class="sr-only">Loading...</span>
                                          </div>
                                        </div>`;

  const checklistTemplateCompletionChecks = await readChecklistTemplateCompletionChecks(checklistTemplateId);

  if (checklistTemplateCompletionChecks.length === 0) {
    checklistTemplateCompletionChecksTable.innerHTML = `
        <tr>
          <td colspan="2" class="py-2">
            <div class="alert alert-warning text-center" role="alert">
              No hay checks asociados a esta plantilla.
            </div>
          </td>
        </tr>
      `;

    loaderChecklistTemplateCompletionChecks.innerHTML = "";

    return;
  }

  checklistTemplateCompletionChecks.forEach((checklistTemplateCompletionCheck) => {
    checklistTemplateCompletionChecksTable.innerHTML += `
        <tr data-id="${checklistTemplateCompletionCheck.checklistTemplateCompletionCheckId}">
          <td colspan="2" class="py-2">
           <div class="card shadow-sm">
               <div class="card-body p-2">
                   <div class="row align-items-center no-gutters">
                       <div class="col-10 d-inline-flex align-items-center">
                          <i class="fas fa-grip-lines px-3" style="cursor: move;"></i>
                          <div class="mb-0 completion-check-template-tinymce-overwrite">${checklistTemplateCompletionCheck.description}</div>
                       </div>
                       <div class="col-2 float-right text-right">
                           <div class="btn-group">
                               <button type="button" data-action="edit-checklits-template" data-id="${checklistTemplateCompletionCheck.checklistTemplateCompletionCheckId}" data-description="${escapeHtml(checklistTemplateCompletionCheck.description)}" class="btn btn-barymont-red btn-md">
                                   <i class="fas fa-edit"></i>
                               </button>
                               <button type="button" data-action="delete-checklits-template" data-id="${checklistTemplateCompletionCheck.checklistTemplateCompletionCheckId}" data-description="${escapeHtml(checklistTemplateCompletionCheck.description)}" class="btn btn-barymont-black btn-md ml-2">
                                   <i class="fas fa-trash-alt"></i>
                               </button>
                           </div>
                       </div>
                   </div>
               </div>
              </div>
          </td>
        </tr>
      `;
  });

  loaderChecklistTemplateCompletionChecks.innerHTML = "";

  const editChecklistTemplateCompletionCheckButtons = document.querySelectorAll("[data-action=edit-checklits-template]");
  editChecklistTemplateCompletionCheckButtons.forEach((button) => {
    button.addEventListener("click", () => {
      const checklistTemplateCompletionCheckId = button.getAttribute("data-id");
      const checklistTemplateCompletionCheckDescription = button.getAttribute("data-description");
      createEditChecklistTemplateCompletionCheckModal({
        checklistTemplateId: checklistTemplateId,
        checklistTemplateCompletionCheckId: checklistTemplateCompletionCheckId,
        checklistTemplateCompletionCheckDescription: checklistTemplateCompletionCheckDescription,
      });
    });
  });

  const deleteChecklistTemplateCompletionCheckButtons = document.querySelectorAll("[data-action=delete-checklits-template]");
  deleteChecklistTemplateCompletionCheckButtons.forEach((button) => {
    button.addEventListener("click", () => {
      const checklistTemplateCompletionCheckId = button.getAttribute("data-id");
      const checklistTemplateCompletionCheckDescription = button.getAttribute("data-description");
      createDeleteChecklistTemplateCompletionCheckModal({
        checklistTemplateId: checklistTemplateId,
        checklistTemplateCompletionCheckId: checklistTemplateCompletionCheckId,
        checklistTemplateCompletionCheckDescription: checklistTemplateCompletionCheckDescription,
        datatableId: "checklist-template-table",
      });
    });
  });

  Sortable.create(checklistTemplateCompletionChecksTable, {
    animation: 150,
    draggable: "tr",
    handle: ".fa-grip-lines",
  });
}

function tinymceCommonConfig(selector) {
  return {
    allow_unsafe_link_target: false,
    invalid_elements: "script,object,embed,link,form,input,iframe",
    selector: `#${selector}`,
    height: 250,
    width: "100%",
    menubar: false,
    plugins: ["link image table lists preview anchor paste"],
    toolbar: "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link",
    style_formats: [
      {
        title: "Títulos",
        items: [
          { title: "Título 1", format: "h3" },
          { title: "Título 2", format: "h4" },
          { title: "Título 3", format: "h5" },
          { title: "Título 4", format: "h6" },
        ],
      },
      {
        title: "Formatos",
        items: [
          { title: "Negrita", format: "bold" },
          { title: "Cursiva", format: "italic" },
          { title: "Subrayada", format: "underline" },
          { title: "Tachada", format: "strikethrough" },
        ],
      },
      { title: "Bloques", items: [{ title: "Párrafo", format: "p" }] },
      {
        title: "Posiciones",
        items: [
          { title: "Izquierda", format: "alignleft" },
          { title: "Centrado", format: "aligncenter" },
          { title: "Derecha", format: "alignright" },
          { title: "Justificado", format: "alignjustify" },
        ],
      },
    ],
    content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
    branding: false,
  };
}

function createEditChecklistTemplateCompletionCheckModal({ checklistTemplateId, checklistTemplateCompletionCheckId, checklistTemplateCompletionCheckDescription }) {
  const randomModalId = `modal-${Math.random().toString(36).substring(2, 10)}`;
  const fragment = document.createDocumentFragment();

  const temporalDiv = document.createElement("div");
  temporalDiv.innerHTML = `
    <div class="modal fade" id="${randomModalId}" tabindex="-1" role="dialog" data-backdrop="static">
      <div class="modal-xl modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Editar Check</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div id="modal-body-${randomModalId}" class="modal-body">
            <label for="modal-checklist-template-completion-check-description">Descripción</label>
            <textarea id="modal-checklist-template-completion-check-description" type="text" class="form-control" rows="5"
              placeholder="Escribe una descripción para el check"
              required
            >${checklistTemplateCompletionCheckDescription}</textarea>
          </div>
          <div class="modal-footer">
            <button id="modal-edit-checklist-template-completion-check" type="button" class="btn btn-barymont-red" data-action="edit" data-id="${checklistTemplateCompletionCheckId}">Editar</button>
            <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>`;

  fragment.appendChild(temporalDiv.firstElementChild);
  document.body.appendChild(fragment);

  tinymce.init({
    ...tinymceCommonConfig("modal-checklist-template-completion-check-description"),
    setup: function (editor) {
      editor.on("init", function () {
        if (checklistTemplateCompletionCheckDescription) {
          editor.setContent(checklistTemplateCompletionCheckDescription);
        } else {
          editor.setContent("");
        }
      });
    },
  });

  const modalElement = $(`#${randomModalId}`).modal("show");

  modalElement.on("shown.bs.modal", function () {
    document.getElementById("modal-checklist-template-completion-check-description").focus();
  });

  modalElement.on("hidden.bs.modal", function () {
    tinymce.remove();
    modalElement.remove();
  });

  const editButton = document.querySelector(`#modal-edit-checklist-template-completion-check`);
  editButton.addEventListener("click", async function () {
    try {
      showLoadingButton(editButton.id, "btn-barymont-red", true);
      const editor = tinymce.get("modal-checklist-template-completion-check-description");
      const checklistTemplateCompletionCheckDescriptionModal = editor ? editor.getContent().trim() : "";
      const response = await updateChecklistTemplateCompletionCheck(checklistTemplateId, checklistTemplateCompletionCheckId, checklistTemplateCompletionCheckDescriptionModal);
      createToast("success", "Check Actualizado", response.message, 5000);
      await paintChecklistTemplateCompletionChecks(checklistTemplateId);

      modalElement.modal("hide");
    } catch (error) {
      createToast("error", "Error", error.message, 5000);
      updateButtonLabel(editButton.id, "Editar", "btn-barymont-red");
    }
  });
}

function createDeleteChecklistTemplateCompletionCheckModal({ checklistTemplateId, checklistTemplateCompletionCheckId, checklistTemplateCompletionCheckDescription, datatableId }) {
  const randomModalId = `modal-${Math.random().toString(36).substring(2, 10)}`;
  const fragment = document.createDocumentFragment();

  const temporalDiv = document.createElement("div");
  temporalDiv.innerHTML = `
    <div class="modal fade" id="${randomModalId}" tabindex="-1" role="dialog" data-backdrop="static">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Eliminar Plantilla</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div id="modal-body-${randomModalId}" class="modal-body text-center">
            <span>¿Estás seguro de que deseas eliminar el check con la siguiente descripción: <strong>${checklistTemplateCompletionCheckDescription}</strong>?</span>
          </div>
          <div class="modal-footer">
            <button id="modal-delete-checklist-template-completion-check" type="button" class="btn btn-barymont-red" data-action="delete" data-id="${checklistTemplateCompletionCheckId}">Eliminar</button>
            <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>`;

  fragment.appendChild(temporalDiv.firstElementChild);
  document.body.appendChild(fragment);

  const modalElement = $(`#${randomModalId}`).modal("show");

  modalElement.on("hidden.bs.modal", function () {
    modalElement.remove();
  });

  const deleteButton = document.querySelector(`#modal-delete-checklist-template-completion-check`);
  deleteButton.addEventListener("click", async function () {
    try {
      const response = await deleteChecklistTemplateCompletionCheck(checklistTemplateId, checklistTemplateCompletionCheckId);
      createToast("success", "Check Eliminado", response.message, 5000);

      $("#" + datatableId)
        .DataTable()
        .ajax.reload(function () {
          $("#" + datatableId)
            .DataTable()
            .rows()
            .every(function () {
              const data = this.data();
              if (data.id === selectedRow) {
                this.select();
              }
            });
        }, false);

      modalElement.modal("hide");
    } catch (error) {
      createToast("error", "Error", error.message, 5000);
    }
  });
}

function createChecklistTemplateCompletionCheckModal(checklistTemplateId, datatableId) {
  const randomModalId = `modal-${Math.random().toString(36).substring(2, 10)}`;
  const fragment = document.createDocumentFragment();

  const temporalDiv = document.createElement("div");
  temporalDiv.innerHTML = `
    <div class="modal fade" id="${randomModalId}" tabindex="-1" role="dialog" data-backdrop="static">
      <div class="modal-xl modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Añadir Check</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div id="modal-body-${randomModalId}" class="modal-body">
            <label for="modal-checklist-template-completion-check-description">Descripción</label>
            <textarea id="modal-checklist-template-completion-check-description" type="text" class="form-control" rows="5"
              placeholder="Escribe una descripción para el check"
              required
            ></textarea>
          </div>
          <div class="modal-footer">
            <button id="modal-create-checklist-template-completion-check" type="button" class="btn btn-barymont-red">Guardar</button>
            <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>`;

  fragment.appendChild(temporalDiv.firstElementChild);
  document.body.appendChild(fragment);

  tinymce.init({
    ...tinymceCommonConfig("modal-checklist-template-completion-check-description"),
    setup: function (editor) {
      editor.on("init", function () {
        editor.setContent("");
      });
    },
  });

  const modalElement = $(`#${randomModalId}`).modal("show");

  modalElement.on("shown.bs.modal", function () {
    document.getElementById("modal-checklist-template-completion-check-description").focus();
  });

  modalElement.on("hidden.bs.modal", function () {
    tinymce.remove();
    modalElement.remove();
  });

  const createButton = document.querySelector(`#modal-create-checklist-template-completion-check`);
  createButton.addEventListener("click", async function () {
    try {
      showLoadingButton(createButton.id, "btn-barymont-red", true);
      const editor = tinymce.get("modal-checklist-template-completion-check-description");
      const checklistTemplateCompletionCheckDescriptionModal = editor ? editor.getContent().trim() : "";
      const response = await createChecklistTemplateCompletionCheck(checklistTemplateId, checklistTemplateCompletionCheckDescriptionModal);
      createToast("success", "Check Creado", response.message, 5000);

      $("#" + datatableId)
        .DataTable()
        .ajax.reload(function () {
          $("#" + datatableId)
            .DataTable()
            .rows()
            .every(function () {
              const data = this.data();
              if (data.id === selectedRow) {
                this.select();
              }
            });
        }, false);
      modalElement.modal("hide");
    } catch (error) {
      createToast("error", "Error", error.message, 5000);
      updateButtonLabel(createButton.id, "Guardar", "btn-barymont-red");
    }
  });
}

async function updateChecklistTemplateCompletionChecksOrder(checklistTemplateId) {
  const checklistTemplateCompletionChecksTable = document.getElementById("checklist-template-completion-checks-table");

  const validRows = checklistTemplateCompletionChecksTable.querySelectorAll("tr[data-id]");

  if (validRows.length < 2) {
    createToast("warning", "No se encuentran items", "Añade al menos 2 items para reordenar", 5000);
    return;
  }

  const checklistTemplateCompletionChecks = Array.from(validRows).map((element, index) => ({
    checklistTemplateCompletionCheckId: element.getAttribute("data-id"),
    order: index + 1,
  }));

  try {
    showLoadingButton("save-checklist-completion-checks-order", "btn-barymont-red", true);
    const response = await updateChecklistTemplateCompletionCheckOrder(checklistTemplateId, checklistTemplateCompletionChecks);
    createToast("success", "Orden Actualizado", response.message, 5000);
    await paintChecklistTemplateCompletionChecks(checklistTemplateId);
    updateButtonLabel("save-checklist-completion-checks-order", "Actualizar orden", "btn-barymont-red");
  } catch (error) {
    createToast("error", "Error", error.message, 5000);
    updateButtonLabel("save-checklist-completion-checks-order", "Actualizar orden", "btn-barymont-red");
  }
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname === "/herramientas/checklist-management") {
      paintChecklistTemplatesData();

      document.getElementById("create-completion-check-button").addEventListener("click", async function () {
        const datatable = document.getElementById("checklist-template-table");

        if (selectedRow === null || selectedRow === undefined) {
          createToast("error", "Error", "Por favor, selecciona una plantilla para añadir un check.", 5000);
          return;
        }

        createChecklistTemplateCompletionCheckModal(selectedRow, datatable.id);
      });

      document.getElementById("save-checklist-completion-checks-order").addEventListener("click", async function () {
        if (selectedRow === null || selectedRow === undefined) {
          createToast("error", "Error", "Por favor, selecciona una plantilla para reordenar sus checks.", 5000);
          return;
        }

        updateChecklistTemplateCompletionChecksOrder(selectedRow);
      });

      $(document).on('focusin', function(e) {
        if ($(e.target).closest('.tox-dialog').length) {
          e.stopImmediatePropagation();
        }
      });
    }
  });
}
