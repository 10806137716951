export const enum SubscriptionPeriodicity {
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

interface SubscriptionPeriodicitySelectOption {
  label: string;
  enabled: boolean;
  isDefault: boolean;
}

export const subscriptionPeriodicitySelectOptions: Record<SubscriptionPeriodicity, SubscriptionPeriodicitySelectOption> = {
  [SubscriptionPeriodicity.MONTHLY]: {
    label: "Suscripción mensual",
    enabled: true,
    isDefault: true,
  },
  [SubscriptionPeriodicity.YEARLY]: {
    label: "Suscripción anual",
    enabled: false,
    isDefault: false,
  },
};
