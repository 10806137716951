"use strict";

let leadData = {};

function showLoadingButton(buttonId, bgClass, onlySpiner = false) {
  const loadingHtml = onlySpiner ? '<span class="spinner-border spinner-border-sm my-1 mx-2" role="status" aria-hidden="true" style="pointer-events: none;"></span>' : '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="pointer-events: none;"></span> Cargando...';
  $("#" + buttonId)
    .removeClass(bgClass)
    .addClass("btn-barymont-grey")
    .attr("disabled", true)
    .html(loadingHtml);
}

function updateButtonLabel(buttonId, newLabel, bgClass) {
  $("#" + buttonId)
    .removeClass("btn-barymont-grey")
    .addClass(bgClass)
    .attr("disabled", false)
    .html(newLabel);
}

function createLoadingContainerAfterPrint(containerId) {
  document.getElementById(containerId).innerHTML = `
    <div id="${containerId}-loading" class="d-flex justify-content-center align-items-center my-5">
        <div class="spinner-border text-barymont-red" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    `;
}

function removeLoadingContainerBeforePrint(containerId) {
  document.getElementById(`${containerId}-loading`)?.remove();
}

async function fetchLeadData(idLead) {
  let formData = new FormData();

  formData.append("idLead", idLead);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/anf/documentacionAdjunta/ajax/getLeadData", requestOptions).then((response) => response.json());
}

async function fetchLeadDocumentData(leadId) {
  let formData = new FormData();

  formData.append("leadId", leadId);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/anf/documentacionAdjunta/ajax/getLeadDocumentData", requestOptions).then((response) => response.json());
}

async function fetchDeleteLeadDocuemnt(leadDocumentId) {
  let formData = new FormData();

  formData.append("leadDocumentId", leadDocumentId);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/anf/documentacionAdjunta/ajax/deleteLeadDocument", requestOptions).then((response) => response.json());
}

async function fetchDownloadLeadDocument(leadDocumentId) {
  let formData = new FormData();

  formData.append("leadDocumentId", leadDocumentId);

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    body: formData,
  };

  return await fetch("/herramientas/anf/documentacionAdjunta/ajax/downloadFile", requestOptions).then((response) => response.json());
}

async function fetchUploadFile(leadDocumentUploadFormData, leadId) {
  let formData = new FormData();

  formData.append("fileType", leadDocumentUploadFormData.fileType);
  formData.append("fileName", leadDocumentUploadFormData.fileName);
  formData.append("identificationNumber", leadDocumentUploadFormData.identificationNumber);
  formData.append("file", leadDocumentUploadFormData.file);
  formData.append("leadId", leadId);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/anf/documentacionAdjunta/ajax/uploadFile", requestOptions).then((response) => response.json());
}

async function printLeadDocuments(leadDocumentsData, containerId, modalDeleteId, confirmDeleteBtnId) {
  const container = document.getElementById(containerId);

  if (leadDocumentsData.length === 0) {
    container.innerHTML = "<div class='text-center'><span>No hay documentos adjuntos</span></div>";
    return;
  }

  leadDocumentsData.forEach((leadDocument) => {
    const html = `
      <div id="${leadDocument.leadDocumentId}" class="card clickable-box mb-3">
        <div class="card-body p-0">

          <div class="container-fluid">
            <div class="row">

              <div class="col-lg-6 d-flex align-items-center">
                <i class="fas fa-file-alt mr-2 text-dark"></i>
                <span class="p-1">${leadDocument.name}</span>
              </div>

              <div class="col-lg-3 d-flex align-items-center justify-content-center">
                <span class="badge ${leadDocument.leadDocumentType.renderStyles}">${leadDocument.leadDocumentType.description}</span>
              </div>

              <div class="col-lg-2 d-flex align-items-center">
                <span class="font-weight-bold p-1">${leadDocument.createdAt}</span>
              </div>

              <div class="col-lg-1 d-flex align-items-center justify-content-end pr-0">
                ${
                  leadDocument.isLinkedDocument || leadDocument.isClausulaIGP
                    ? `<a class="btn btn-barymont-grey mr-2" disabled>
                        <i class="fas fa-trash py-1 px-2" style="cursor: not-allowed;" title="No se puede eliminar el documento está vinculado con alguna solicitud."></i>
                      </a>`
                    : `<button id="button-to-remove-${leadDocument.leadDocumentId}" data-id-to-delete="${leadDocument.leadDocumentId}" class="btn btn-barymont-black mr-2">
                        <i class="fas fa-trash py-1 px-2" style="pointer-events: none;" title="Eliminar"></i>
                      </button>`
                }
                <a id="button-to-download-${leadDocument.leadDocumentId}" class="btn btn-barymont-red">
                  <i class="fas fa-download py-1 px-2" style="pointer-events: none;" title="Descargar"></i>
                </a>
              </div>

            </div>
          </div>

        </div>
      </div>
    `;

    container.insertAdjacentHTML("beforeend", html);

    document.getElementById(`button-to-download-${leadDocument.leadDocumentId}`).addEventListener("click", () => {
      showLoadingButton(`button-to-download-${leadDocument.leadDocumentId}`, "btn-barymont-red", true);

      fetchDownloadLeadDocument(leadDocument.leadDocumentId).then((response) => {
        updateButtonLabel(`button-to-download-${leadDocument.leadDocumentId}`, `<i class="fas fa-download py-1 px-2" style="pointer-events: none;" title="Descargar"></i>`, "btn-barymont-red");
        if (response.status) {
          fetch(`data:application/octet-stream;base64,${response.data.file}`)
            .then((res) => res.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = url;
              a.download = response.data.fileName;
              document.body.appendChild(a);
              a.click();
              a.remove();
              window.URL.revokeObjectURL(url);
            });

          createToast("success", "Documento descargado", "Se ha descargado el documento", 5000);
          return;
        }

        createToast("error", "Error al descargar", "No se ha podido descargar el documento", 5000);
      });
    });

    document.getElementById(`button-to-remove-${leadDocument.leadDocumentId}`)?.addEventListener("click", (e) => {
      $("#" + modalDeleteId).modal("show");

      const leadDocuemntId = e.target.dataset.idToDelete;
      const confirmDeleteBtn = document.getElementById(confirmDeleteBtnId);
      const confirmDeleteBtnClone = confirmDeleteBtn.cloneNode(true);

      confirmDeleteBtn.parentNode.replaceChild(confirmDeleteBtnClone, confirmDeleteBtn);

      confirmDeleteBtnClone.addEventListener("click", function () {
        showLoadingButton(confirmDeleteBtnId, "btn-barymont-red");

        fetchDeleteLeadDocuemnt(leadDocuemntId).then((response) => {
          if (response.status) {
            document.getElementById(`${leadDocuemntId}`).remove();

            createToast("success", "Documento eliminado", "Se ha eliminado el documento del lead", 5000);
            updateButtonLabel(confirmDeleteBtnId, `<i class="fas fa-trash"></i> Eliminar`, "btn-barymont-red");

            $("#" + modalDeleteId).modal("hide");

            return;
          }

          updateButtonLabel(confirmDeleteBtnId, `<i class="fas fa-trash"></i> Eliminar`, "btn-barymont-red");
          createToast("error", "Error al eliminar", "No se ha podido eliminar el documento del lead", 5000);
        });
      });
    });
  });
}

function changeUploadFileForm(selected, leadAttachFileNameId, leadAttachFileNameInputId, leadAttachFileIdentificationNumberId, leadAttachFileIdentificationNumberInputId) {
  const nameContainer = document.getElementById(leadAttachFileNameId);
  const nameInput = document.getElementById(leadAttachFileNameInputId);

  const identificationNumberContainer = document.getElementById(leadAttachFileIdentificationNumberId);
  const identificationNumberInput = document.getElementById(leadAttachFileIdentificationNumberInputId);

  switch (selected) {
    case "OTRO":
      nameContainer.classList.remove("d-none");
      nameInput.required = true;
      nameInput.value = "";

      identificationNumberContainer.classList.add("d-none");
      identificationNumberInput.required = false;
      identificationNumberInput.value = "";
      break;
    case "DNI":
      identificationNumberContainer.classList.remove("d-none");
      identificationNumberInput.required = true;
      identificationNumberInput.value = "";

      nameContainer.classList.add("d-none");
      nameInput.required = false;
      nameInput.value = "";
      break;
    default:
      identificationNumberContainer.classList.add("d-none");
      identificationNumberInput.required = false;
      identificationNumberInput.value = "";

      nameContainer.classList.add("d-none");
      nameInput.required = false;
      nameInput.value = "";
      break;
  }
}

function validarDni(leadAttachFileTypeSelect, leadAttachFileButtonUploadFileId) {
  const sendButton = document.getElementById(leadAttachFileButtonUploadFileId);
  let identificador = leadAttachFileTypeSelect.value;
  identificador = identificador.split(" ").join("");
  identificador = identificador.split("-").join("");

  const validateIds = ValidateSpanishID(identificador);

  if (validateIds["valid"] != true) {
    leadAttachFileTypeSelect.focus();
    leadAttachFileTypeSelect.setAttribute("class", "form-control is-invalid");
    sendButton.disabled = true;
    return false;
  } else {
    leadAttachFileTypeSelect.setAttribute("class", "form-control is-valid");
    sendButton.disabled = false;
  }
}

function checkifFileIsValid(fileElement, leadAttachFileInputId) {
  const file = fileElement.files[0];

  if (file === undefined) {
    return;
  }

  const fileSize = file.size;
  const fileSizeInKb = fileSize / 1024;

  if (fileSizeInKb >= 20480) {
    createToast("error", "Error al subir el archivo", "El archivo no puede superar los 20MB de tamaño", 5000);

    document.getElementById(leadAttachFileInputId).value = null;
    return;
  }

  if (fileSizeInKb < 2) {
    createToast("error", "Error al subir el archivo", "El nombre del archivo no puede superar los 50 caracteres o ", 5000);
    document.getElementById(leadAttachFileInputId).value = null;
    return;
  }
}

function checkIfFormDataIsCorrect(leadAttachFileTypeSelectId, leadAttachFileNameInputId, leadAttachFileIdentificationNumberInputId, leadAttachFileInputId) {
  const leadAttachFileTypeSelect = document.getElementById(leadAttachFileTypeSelectId);
  const leadAttachFileNameInput = document.getElementById(leadAttachFileNameInputId);
  const leadAttachFileIdentificationNumberInput = document.getElementById(leadAttachFileIdentificationNumberInputId);
  const leadAttachFileInput = document.getElementById(leadAttachFileInputId);

  if (leadAttachFileTypeSelect.value === "") {
    createToast("error", "Error al subir el archivo", "El tipo de archivo es obligatorio", 5000);
    return false;
  }

  if (leadAttachFileTypeSelect.value === "OTRO" && leadAttachFileNameInput.value === "") {
    createToast("error", "Error al subir el archivo", "El nombre del archivo es obligatorio", 5000);
    return false;
  }

  if (leadAttachFileTypeSelect.value === "DNI" && leadAttachFileIdentificationNumberInput.value === "") {
    createToast("error", "Error al subir el archivo", "El número de identificación es obligatorio", 5000);
    return false;
  }

  if (leadAttachFileInput.files.length === 0) {
    createToast("error", "Error al subir el archivo", "El archivo es obligatorio", 5000);
    return false;
  }

  return true;
}

function getUploadFormData(leadAttachFileTypeSelectId, leadAttachFileNameInputId, leadAttachFileIdentificationNumberInputId, leadAttachFileInputId) {
  const leadAttachFileType = document.getElementById(leadAttachFileTypeSelectId);
  const leadAttachFileName = document.getElementById(leadAttachFileNameInputId);
  const leadAttachFileIdentificationNumber = document.getElementById(leadAttachFileIdentificationNumberInputId);
  const leadAttachFile = document.getElementById(leadAttachFileInputId);

  return {
    fileType: leadAttachFileType.value != "" ? leadAttachFileType.value : null,
    fileName: leadAttachFileName.value != "" ? leadAttachFileName.value : null,
    identificationNumber: leadAttachFileIdentificationNumber.value != "" ? leadAttachFileIdentificationNumber.value : null,
    file: leadAttachFile.files[0],
  };
}

function clearInputs(args = []) {
  args.forEach((arg) => {
    let input = document.getElementById(arg);
    input.value = "";
    input.dispatchEvent(new Event("change"));
    input.classList.remove("is-valid");
  });
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname.includes("/herramientas/anf/documentacionAdjunta/")) {
      const idLead = document.getElementById("idLead").value;

      const documentosAdjuntosLeadContainerId = "documentos-adjuntos-lead-container";
      const modalDeleteId = "modal-lead-document-delete";
      const confirmDeleteBtnId = "modal-lead-document-delete-button";

      const leadAttachFileTypeSelectId = "lead-attach-file-type-select";

      const leadAttachFileNameId = "lead-attach-file-name";
      const leadAttachFileNameInputId = "lead-attach-file-name-input";

      const leadAttachFileIdentificationNumberId = "lead-attach-file-identification-number";
      const leadAttachFileIdentificationNumberInputId = "lead-attach-file-identification-number-input";

      const leadAttachFileInputId = "lead-attach-file-input";
      const leadAttachFileButtonUploadFileId = "lead-attach-file-button-upload-file";

      if (document.getElementById(documentosAdjuntosLeadContainerId) === null) {
        return;
      }

      createLoadingContainerAfterPrint(documentosAdjuntosLeadContainerId);

      fetchLeadData(idLead).then((response) => {
        leadData = response.data;

        fetchLeadDocumentData(leadData.leadId).then((response) => {
          printLeadDocuments(response.data, documentosAdjuntosLeadContainerId, modalDeleteId, confirmDeleteBtnId).then(() => {
            removeLoadingContainerBeforePrint(documentosAdjuntosLeadContainerId);
          });
        });
      });

      document.getElementById(leadAttachFileTypeSelectId).addEventListener("change", function () {
        changeUploadFileForm(this.value, leadAttachFileNameId, leadAttachFileNameInputId, leadAttachFileIdentificationNumberId, leadAttachFileIdentificationNumberInputId, leadAttachFileInputId);
        if (document.getElementById(leadAttachFileTypeSelectId).value !== "DNI") {
          document.getElementById(leadAttachFileButtonUploadFileId).disabled = false;
        }
      });

      document.getElementById(leadAttachFileTypeSelectId).dispatchEvent(new Event("change"));

      document.getElementById(leadAttachFileInputId).addEventListener("change", function () {
        checkifFileIsValid(this, leadAttachFileInputId);
      });

      document.getElementById(leadAttachFileIdentificationNumberInputId).addEventListener("input", function () {
        validarDni(this, leadAttachFileButtonUploadFileId);
      });

      document.getElementById(leadAttachFileButtonUploadFileId).addEventListener("click", function () {
        showLoadingButton(leadAttachFileButtonUploadFileId, "btn-barymont-red", true);

        if (checkIfFormDataIsCorrect(leadAttachFileTypeSelectId, leadAttachFileNameInputId, leadAttachFileIdentificationNumberInputId, leadAttachFileInputId)) {
          const leadDocumentUploadFormData = getUploadFormData(leadAttachFileTypeSelectId, leadAttachFileNameInputId, leadAttachFileIdentificationNumberInputId, leadAttachFileInputId);
          createToast("info", "Subiendo documento", "Se esta subiendo el documento", 5000);

          fetchUploadFile(leadDocumentUploadFormData, leadData.leadId).then((response) => {
            if (response.status) {
              createLoadingContainerAfterPrint(documentosAdjuntosLeadContainerId);
              createToast("success", "Documento subido", "Se ha subido el documento al lead", 5000);
              updateButtonLabel(leadAttachFileButtonUploadFileId, "Adjuntar Documentación", "btn-barymont-red");

              fetchLeadDocumentData(leadData.leadId).then((response) => {
                printLeadDocuments(response.data, documentosAdjuntosLeadContainerId, modalDeleteId, confirmDeleteBtnId).then(() => {
                  removeLoadingContainerBeforePrint(documentosAdjuntosLeadContainerId);
                  clearInputs([leadAttachFileNameInputId, leadAttachFileIdentificationNumberInputId, leadAttachFileInputId]);
                });
              });

              return;
            }

            createToast("error", "Error al subir el documento", "No se ha podido subir el documento al lead", 5000);

            updateButtonLabel(leadAttachFileButtonUploadFileId, "Adjuntar Documentación", "btn-barymont-red");
          });
        } else {
          updateButtonLabel(leadAttachFileButtonUploadFileId, "Adjuntar Documentación", "btn-barymont-red");
        }
      });
    }
  });
}

export default {};
