"use strict";

export async function findManagerByCommercialEntityId(commercialEntityId) {
  const response = await fetch(`/api/ebroker-commercial-entity/${commercialEntityId}/manager`);
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}
