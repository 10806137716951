"use strict";

import Sortable from "sortablejs";
import { readChecklistTemplates } from "../../api/checklist_management.js";
import { createServiceExpert, readPymeServiceTypes, readServiceTypeChecklistTemplateActions, readServiceTypeChecklistTemplates, readServiceTypeSchema, updateServiceTypeChecklistTemplates } from "../../api/service.js";
import { searchUserPreferences, updateUserPreference } from "../../api/users.js";
import { showLoadingButton, updateButtonLabel } from "../shared/shared.js";
import { addCommonServiceModalsEventListeners } from "./services.js";
import { loadAndOpenRegisterExpertModal } from "./service_experts.js";

var FILTER_STATE_LOCAL_STORAGE_KEY = "PYMESERVICE_FILTER_STATE_";
var FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME = 1000 * 60 * 20;
const USER_PREFERENCE_TOOL_VALUE = "SERVICE";

const pymeServiceModal = "modal-add-pyme-service";
const pymeServiceUpdateModal = "modal-update-pyme-service";
const pymeServiceOpenModalButton = "pymes-service-button-open-modal";
const pymeServiceSaveButton = "pymes-service-save-button";
const pymeServiceUpdateButton = "pymes-service-update-button";
const pymeServiceExpertAddButton = "pymes-expert-modal";

const pymeServiceHistoricalModal = "pyme-service-historical-modal";
const pymeServiceAddStateModal = "pyme-service-change-state-modal";

function resetearFiltros() {
  $("#pyme-filter-state").val("");
  $("#pyme-filter-type").val(0);
  $("#pyme-filter-provider").val("");
  $("#pyme-filter-user").val("");
  $("#pyme-filter-user-mode").val("is_promoter");

  $("#pyme-filter-state").selectpicker("refresh");
  $("#pyme-filter-type").selectpicker("refresh");
  $("#pyme-filter-provider").selectpicker("refresh");
  $("#pyme-filter-user").selectpicker("refresh");
  $("#pyme-filter-user-mode").selectpicker("refresh");

  $("#pyme-service-table").DataTable().search("").draw();
  $("#pyme-service-table").DataTable().ajax.reload();

  const actualUrl = new URL(window.location.href);
  if (actualUrl.pathname.split("/").pop() !== "companyservice") {
    $("#pyme-service-table").DataTable().state.save();
    window.location.href = "/herramientas/companyservice";
  }
}

function getStateOfCustomFilters() {
  return {
    estadoServicio: $("#pyme-filter-state").val(),
    tipoServicio: $("#pyme-filter-type").val(),
    proveedorServicio: $("#pyme-filter-provider").val(),
    userId: $("#pyme-filter-user").val(),
    userIdFilterMode: $("#pyme-filter-user-mode").val(),
  };
}

function recreateStateOfCustomFilters(localStorageData) {
  if (localStorageData.customFilter === undefined) {
    return false;
  }

  $("#pyme-filter-state").val(localStorageData.customFilter.estadoServicio);
  $("#pyme-filter-type").val(localStorageData.customFilter.tipoServicio);
  $("#pyme-filter-provider").val(localStorageData.customFilter.proveedorServicio);
  $("#pyme-filter-user").val(localStorageData.customFilter.userId);
  $("#pyme-filter-user-mode").val(localStorageData.customFilter.userIdFilterMode);

  $("#pyme-filter-state").selectpicker("refresh");
  $("#pyme-filter-type").selectpicker("refresh");
  $("#pyme-filter-provider").selectpicker("refresh");
  $("#pyme-filter-user").selectpicker("refresh");
  $("#pyme-filter-user-mode").selectpicker("refresh");
}

async function savePymeService(expertUsers) {
  let leadId = document.getElementById("lead-id").value;
  let userId = document.getElementById("user-id").value;
  let serviceType = document.getElementById("pyme-service-type").value;
  let serviceProvider = document.getElementById("pyme-service-provider").value;
  let comments = document.getElementById("comments").value;

  if (userId === "" || serviceType === "" || serviceProvider === "") {
    createToast("error", "Error ❌", "Por favor, complete todos los campos obligatorios para poder crear el servicio.");
    updateButtonLabel(pymeServiceSaveButton, "Crear Servicio Empresas", "btn-barymont-red");
    return;
  }

  let response = await fetchSavePymeServiceData(leadId, userId, serviceType, serviceProvider, comments);

  if (response.success === false) {
    createToast("error", "Error ❌", response.message);
    return;
  }

  if (expertUsers.length > 0) {
    expertUsers.forEach(async (expert) => {
      try {
        await createServiceExpert(response.serviceId, expert.userId, expert.role);
      } catch (error) {
        createToast("error", "Error", error.message, 5000);
        return;
      }
    });
  }

  document.getElementById("user-id").value = "";
  document.getElementById("pyme-service-type").value = "";
  document.getElementById("pyme-service-provider").value = "";
  document.getElementById("comments").value = "";

  window.location.href = "/herramientas/companyservice/" + leadId;
}

async function fetchSavePymeServiceData(leadId, userId, serviceType, serviceProvider, comments) {
  let formData = new FormData();
  formData.append("leadId", leadId);
  formData.append("userId", userId);
  formData.append("serviceType", serviceType);
  formData.append("serviceProvider", serviceProvider);
  formData.append("comments", comments);

  return await fetch("/herramientas/companyservice/ajax/savePymeService", {
    method: "POST",
    body: formData,
  }).then((response) => response.json());
}

async function openHistoricalModal(serviceId, modalId) {
  try {
    let historicalData = await fetchHistoricalData(serviceId);

    if (historicalData.success === false) {
      throw new Error("Error fetching historical data");
    }

    let modalBody = document.getElementById(modalId + "-body");

    modalBody.innerHTML = "";

    modalBody.insertAdjacentHTML(
      "beforeend",
      `<div class="container-fluid">
          <div class="row">
              <div class="col-4 align-middle text-center text-barymont-black h6 font-weight-bold">◀ ANTERIOR</div>
              <div class="col-4 align-middle text-center text-barymont-black h6 font-weight-bold">⏱️ FECHA CAMBIO</div>
              <div class="col-4 align-middle text-center text-barymont-black h6 font-weight-bold">NUEVO ▶</div>
          </div>
      </div>
      <hr>`
    );

    if (historicalData.historical.length === 0) {
      modalBody.innerHTML += `<div class="text-center">No se encontraron cambios de estado para este servicio</div>`;
    }

    historicalData.historical.forEach((item) => {
      let itemMessageString = "";

      if (item.message != "" && item.message != null) {
        itemMessageString = '<div class="col-12 align-middle text-center pt-2 text-wrap text-break">💬 ' + item.message + "</div>";
      }

      if (item.previousState.value === item.newState.value) {
        modalBody.insertAdjacentHTML(
          "beforeend",
          `<div id='servicio-historico-estado-id-${item.serviceId}' class="row p-1">
              <div class="col-12 d-flex flex-column justify-content-center">
                  <span style="font-size:13px; white-space: normal;" class="badge bg-barymont-grey w-100 p-3">${item.createdAt}</span>
              </div>
              ${itemMessageString}
          </div>`
        );
      } else {
        modalBody.insertAdjacentHTML(
          "beforeend",
          `<div id='servicio-historico-estado-id-${item.serviceId}' class="row p-1">
              <div class="col-4 d-flex flex-column justify-content-center">
                  <span style="font-size:13px; white-space: normal;" class="badge ${item.previousState.backgroundColor} ${item.previousState.textColor} w-100 p-3 text-uppercase">${item.previousState.label}</span>
              </div>
              <div class="col-4 d-flex flex-column justify-content-center">
                  <span style="font-size:13px; white-space: normal;" class="badge bg-barymont-grey w-100 p-3">${item.createdAt}</span>
              </div>
              <div class="col-4 d-flex flex-column justify-content-center">
                  <span style="font-size:13px; white-space: normal;" class="badge ${item.newState.backgroundColor} ${item.newState.textColor} w-100 p-3 text-uppercase">${item.newState.label}</span>
              </div>
              ${itemMessageString}
            </div>`
        );
      }
    });

    $("#" + modalId).modal("show");
    $('[data-toggle="tooltip"]').tooltip();
  } catch (error) {
    console.error(error);
    createToast("error", "Error ❌", "Ha ocurrido un error al intentar obtener los datos históricos del servicio. Inténtelo de nuevo más tarde.");
    return;
  }
}

async function fetchHistoricalData(serviceId) {
  let formData = new FormData();
  formData.append("serviceId", serviceId);

  const response = await fetch("/herramientas/companyservice/ajax/getHistoricalData", {
    method: "POST",
    body: formData,
  });

  if (!response.ok) {
    throw new Error("Error fetching historical data");
  }

  return await response.json();
}

async function openAddNewStateModal(serviceId, modalId) {
  try {
    let serviceData = await fetchServiceData(serviceId);

    if (serviceData.success === false) {
      throw new Error("Error fetching service data");
    }

    let states = await fetchServiceStates();
    let selectOptionsStates = "";

    states.serviceStates.forEach((item) => {
      selectOptionsStates += `<option class="${item.backgroundColor} ${item.textColor} text-uppercase" value="${item.value}">${item.label}</option>`;
    });

    let modalBody = document.getElementById(modalId + "-body");

    modalBody.innerHTML = "";

    modalBody.insertAdjacentHTML(
      "beforeend",
      `<div class="container-fluid">
          <div class="row">
              <div class="col-6">
                  <label for="estado-actual" class="control-label font-weight-bold">Estado Actual:</label>
                  <input type="hidden" id="service-current-state" name="service-current-state" value="${serviceData.service.stateType.value}">
                  <span class="badge ${serviceData.service.stateType.backgroundColor} ${serviceData.service.stateType.textColor} w-100 py-3 text-uppercase" style="font-size:13px; white-space: normal;">${serviceData.service.stateType.label}</span>
              </div>
              <div class="col-6">
                  <label for="service-new-state" class="control-label font-weight-bold">Nuevo Estado:</label>
                  <select id="service-new-state" class="form-control input-lg text-uppercase selectpicker" name="service-new-state" placeholder="Seleccionar nuevo estado" title="Seleccionar nuevo estado" required>
                    ${selectOptionsStates}
                  </select>
                  <div class="invalid-feedback">Es necesario seleccionar un Nuevo Estado.</div>
              </div>
              <div class="col-12">
                  <label for="service-comment" class="control-label font-weight-bold">Comentario:</label>
                  <input type="text" id="service-comment" class="form-control input-lg" name="service-comment">
              </div>
          </div>
      </div>`
    );

    $("#" + modalId).modal("show");
    $('[data-toggle="tooltip"]').tooltip();
    $(".selectpicker").selectpicker();

    const saveStateButton = document.getElementById(pymeServiceAddStateModal + "-submit");
    const newUpdateStateButton = saveStateButton.cloneNode(true);
    saveStateButton.parentNode.replaceChild(newUpdateStateButton, saveStateButton);

    return new Promise((resolve) => {
      newUpdateStateButton.addEventListener("click", async () => {
        showLoadingButton(pymeServiceAddStateModal + "-submit", "btn-barymont-red");
        let previousState = document.getElementById("service-current-state").value;
        let newState = document.getElementById("service-new-state").value;
        let comment = document.getElementById("service-comment").value;

        if (previousState === newState && comment === "") {
          createToast("error", "Error ❌", "Es necesario añadir un mensaje para poder mantener el estado servicio.");
          updateButtonLabel(pymeServiceAddStateModal + "-submit", "Guardar Cambio", "btn-barymont-red");
          return;
        }

        const response = await fetchUpdateServiceState(serviceId, newState, comment);
        if (!response.success) {
          createToast("error", "Error ❌", response.message, 3000);
          updateButtonLabel(pymeServiceAddStateModal + "-submit", "Cambiar estado", "btn-barymont-red");
          return;
        }

        createToast("success", "Estado del servicio cambiado", response.message, 3000);
        $("#pyme-service-table").DataTable().ajax.reload();
        $("#" + modalId).modal("hide");
        updateButtonLabel(pymeServiceAddStateModal + "-submit", "Cambiar estado", "btn-barymont-red");
        resolve();
      });
    });
  } catch (error) {
    createToast("error", "Error ❌", "Ha ocurrido un error al intentar obtener los datos del servicio. Inténtelo de nuevo más tarde.");
    return;
  }
}

async function fetchServiceData(serviceId) {
  let formData = new FormData();
  formData.append("serviceId", serviceId);

  const response = await fetch("/herramientas/companyservice/ajax/getServiceData", {
    method: "POST",
    body: formData,
  });

  if (!response.ok) {
    throw new Error("Error fetching service data");
  }

  return await response.json();
}

async function fetchActiveUsers() {
  const response = await fetch("/herramientas/companyservice/ajax/getActiveUsers", {
    method: "POST",
  });

  if (!response.ok) {
    throw new Error("Error fetching active users");
  }

  return await response.json();
}

async function fetchServiceProviders() {
  const response = await fetch("/herramientas/companyservice/ajax/getServiceProviders", {
    method: "POST",
  });

  if (!response.ok) {
    throw new Error("Error fetching service providers");
  }

  return await response.json();
}

async function fetchServiceStates() {
  const response = await fetch("/herramientas/companyservice/ajax/getServiceStates", {
    method: "POST",
  });

  if (!response.ok) {
    throw new Error("Error fetching service states");
  }

  return await response.json();
}

async function fetchServiceTypes() {
  const response = await fetch("/herramientas/companyservice/ajax/getServiceTypes", {
    method: "POST",
  });

  if (!response.ok) {
    throw new Error("Error fetching service type");
  }

  return await response.json();
}

async function fetchUpdateServiceState(serviceId, newState, comment) {
  let formData = new FormData();
  formData.append("serviceId", serviceId);
  formData.append("newState", newState);
  formData.append("comment", comment);

  const response = await fetch("/herramientas/companyservice/ajax/updateServiceState", {
    method: "POST",
    body: formData,
  });

  if (!response.ok) {
    throw new Error("Error updating service state");
  }

  return await response.json();
}

async function openUpdateServiceModal(serviceId, modalId) {
  try {
    let serviceData = await fetchServiceData(serviceId);

    if (serviceData.success === false) {
      throw new Error("Error fetching service data");
    }

    let users = await fetchActiveUsers();
    let selectOptionsUsers = "";

    users.activeUsers.forEach((item) => {
      selectOptionsUsers += `<option value="${item.userId}" ${item.userId === serviceData.service.requestUserId ? "selected" : ""}>${item.name}${item.identificationNumber !== null ? ` - ${item.identificationNumber}` : ""}</option>`;
    });

    let providers = await fetchServiceProviders();
    let selectOptionsProviders = "";

    providers.serviceProviders.forEach((item) => {
      selectOptionsProviders += `<option value="${item.value}" ${item.value === serviceData.service.serviceProvider ? "selected" : ""}>${item.label}</option>`;
    });

    let types = await fetchServiceTypes();
    let selectOptionsTypes = "";

    types.serviceTypes.forEach((item) => {
      selectOptionsTypes += `<option value="${item.value}" ${item.value === serviceData.service.serviceType ? "selected" : ""}>${item.label}</option>`;
    });

    let modalBody = document.getElementById(modalId + "-body");

    modalBody.innerHTML = "";

    modalBody.insertAdjacentHTML(
      "beforeend",
      `<div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="service-provider" class="control-label font-weight-bold">Seleccionar Tipo Proveedor <i class="fas fa-asterisk text-barymont-red"></i></label>
                <select id="service-provider" class="form-control input-lg selectpicker" name="service-provider-id" placeholder="Seleccionar Tipo Proveedor" title="Seleccionar Tipo Proveedor" required>
                  ${selectOptionsProviders}
                </select>
                <div class="invalid-feedback">Es necesario seleccionar un tipo de Proveedor.</div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="user-id" class="control-label font-weight-bold">Seleccionar Promotor <i class="fas fa-asterisk text-barymont-red"></i></label>
                <i id="service-provider-popover" class="fas fa-info-circle d-none" style="font-size:16px" data-toggle="tooltip" title="No se puede eliminar el promotor." data-placement="top"></i>
                <select id="user-id" class="form-control input-lg selectpicker" name="user-id" placeholder="Seleccionar Promotor" title="Seleccionar Promotor" data-live-search="true" data-size="15" required>
                  ${selectOptionsUsers}
                </select>
                <div class="invalid-feedback">Es necesario seleccionar un Promotor.</div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="pyme-service-type" class="control-label font-weight-bold" data-toggle="tooltip">Seleccione tipo de servicio <i class="fas fa-asterisk text-barymont-red"></i></label>
                <select id="pyme-service-type" class="form-control selectpicker show-tick input-lg modal-selectpicker" title="Seleccione tipo de servicio..." data-live-search="true" data-size="10" data-dropup-auto="false" required>
                  ${selectOptionsTypes}
                </select>
                <div class="invalid-feedback">Es necesario seleccionar el tipo de servicio.</div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="comments" data-toggle="tooltip">Observaciones</label>
                <input type="text" id="comments" class="form-control input-lg" value="${serviceData.service.data}">
              </div>
            </div>
          </div>
      </div>`
    );

    const updateServiceButton = document.getElementById(pymeServiceUpdateButton);
    const newUpdateServiceButton = updateServiceButton.cloneNode(true);
    updateServiceButton.parentNode.replaceChild(newUpdateServiceButton, updateServiceButton);
    newUpdateServiceButton.addEventListener("click", async () => {
      await updateServiceData(serviceId);
    });

    $("#" + modalId).modal("show");
    $('[data-toggle="tooltip"]').tooltip();
    $(".selectpicker").selectpicker();
  } catch (error) {
    console.error(error);
    createToast("error", "Error ❌", "Ha ocurrido un error al intentar obtener los datos del servicio. Inténtelo de nuevo más tarde.");
    return;
  }
}

async function updateServiceData(serviceId) {
  let userId = document.getElementById("user-id").value;
  let serviceType = document.getElementById("pyme-service-type").value;
  let provider = document.getElementById("service-provider").value;
  let comments = document.getElementById("comments").value;

  if (userId === "" || serviceType === "" || provider === "") {
    createToast("error", "Error ❌", "Por favor, complete todos los campos obligatorios para poder actualizar el servicio.");
    return;
  }

  showLoadingButton(pymeServiceUpdateButton, "btn-barymont-red");
  await fetchUpdateServiceData(serviceId, userId, serviceType, provider, comments);
}

async function fetchUpdateServiceData(serviceId, userId, serviceType, provider, comments) {
  let formData = new FormData();
  formData.append("serviceId", serviceId);
  formData.append("userId", userId);
  formData.append("serviceType", serviceType);
  formData.append("provider", provider);
  formData.append("comments", comments);

  const response = await fetch("/herramientas/companyservice/ajax/updateServiceData", {
    method: "POST",
    body: formData,
  });

  if (!response.ok) {
    createToast("error", "Error ❌", "Ha ocurrido un error al intentar actualizar el servicio. Inténtelo de nuevo más tarde.");
    updateButtonLabel(pymeServiceUpdateButton, "Actualizar Servicio", "btn-barymont-red");
    return;
  }

  let responseDecode = await response.json();

  if (responseDecode.success === false) {
    createToast("error", "Error ❌", responseDecode.message);
    updateButtonLabel(pymeServiceUpdateButton, "Actualizar Servicio", "btn-barymont-red");
    return;
  }

  createToast("success", "Servicio Actualizado", responseDecode.message);
  $("#" + pymeServiceUpdateModal).modal("hide");
  updateButtonLabel(pymeServiceUpdateButton, "Actualizar Servicio", "btn-barymont-red");

  document.dispatchEvent(new CustomEvent("serviceUpdated"));
}

async function openCreateServiceModal(modalId) {
  let users = await fetchActiveUsers();
  let selectOptionsUsers = "";

  users.activeUsers.forEach((item) => {
    selectOptionsUsers += `<option value="${item.userId}">${item.name}${item.identificationNumber ? ` - ${item.identificationNumber}` : ""}</option>`;
  });

  let providers = await fetchServiceProviders();
  let selectOptionsProviders = "";

  providers.serviceProviders.forEach((item) => {
    selectOptionsProviders += `<option value="${item.value}">${item.label}</option>`;
  });

  let types = await fetchServiceTypes();
  let selectOptionsTypes = "";

  types.serviceTypes.forEach((item) => {
    selectOptionsTypes += `<option value="${item.value}">${item.label}</option>`;
  });

  let modalBody = document.getElementById(modalId + "-body");

  modalBody.innerHTML = "";

  modalBody.insertAdjacentHTML(
    "beforeend",
    `<div class="container-fluid">
      <div class="row">

        <div class="col-12">
          <div class="form-group">
            <label for="pyme-service-provider" class="control-label font-weight-bold">Seleccionar Tipo Proveedor <i class="fas fa-asterisk text-barymont-red"></i></label>
            <select id="pyme-service-provider" class="form-control input-lg selectpicker" name="pyme-service-provider" placeholder="Seleccionar Tipo Proveedor" title="Seleccionar Tipo Proveedor" required>
              ${selectOptionsProviders}
            </select>
            <div class="invalid-feedback">Es necesario seleccionar un tipo de Proveedor.</div>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label for="user-id" class="control-label font-weight-bold">Seleccionar Promotor <i class="fas fa-asterisk text-barymont-red"></i></label>
            <select id="user-id" class="form-control input-lg selectpicker" name="user-id" placeholder="Seleccionar Promotor" title="Seleccionar Promotor" data-live-search="true" data-size="15" required>
              ${selectOptionsUsers}
            </select>
            <div class="invalid-feedback">Es necesario seleccionar un Promotor.</div>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label for="pyme-service-type" class="control-label font-weight-bold" data-toggle="tooltip">Seleccione tipo de servicio <i class="fas fa-asterisk text-barymont-red"></i></label>
            <select id="pyme-service-type" class="form-control selectpicker show-tick input-lg modal-selectpicker" title="Seleccione tipo de servicio..." data-live-search="true" data-size="10" data-dropup-auto="false" required>
              ${selectOptionsTypes}
            </select>
            <div class="invalid-feedback">Es necesario seleccionar el tipo de servicio.</div>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label for="comments" data-toggle="tooltip" class="font-weight-bold">Observaciones</label>
            <input type="text" id="comments" class="form-control input-lg" value="">
          </div>
        </div>

        <div class="col-12">
          <label class="control-label font-weight-bold">Expertos Asociados</label>
          <div id="experts-data-container" class="text-center">
            <p>No hay expertos asociados a este servicio</p>
          </div>
        </div>

      </div>
    </div>`
  );

  const addExpertButton = document.getElementById(pymeServiceExpertAddButton);
  addExpertButton.addEventListener("click", async () => {
    const expertUser = await loadAndOpenRegisterExpertModal(null, "PymeService:PymeServiceUpdateAccessChecker");

    if (expertUser === null) {
      return;
    }

    const expertsDataContainer = document.getElementById("experts-data-container");
    if (expertsDataContainer.querySelectorAll("[data-expert-user]").length === 0) {
      expertsDataContainer.innerHTML = "";
    }

    expertsDataContainer.innerHTML += `
        <div class="input-group my-1" data-expert-user>
          <input type="text" class="form-control" value="${expertUser.name}" readonly="">

          <div class="input-group-append">
            <span class="input-group-text bg-light font-weight-bold w-100" data-toggle="tooltip" title="" data-original-title="Rol del experto">
              ${expertUser.role}
            </span>

            <button class="btn btn-barymont-red btn-service-delete-expert" data-toggle="tooltip" data-placement="top" title="" data-user-id="${expertUser.userId}" data-original-title="Eliminar experto">
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </div>`;

    document.getElementById("experts-data-container").addEventListener("click", async function (event) {
      let deleteButton = event.target.closest(".btn-service-delete-expert");
      if (deleteButton) {
        deleteButton.closest("div[data-expert-user]").remove();
      }

      if (expertsDataContainer.querySelectorAll("[data-expert-user]").length === 0) {
        expertsDataContainer.innerHTML = `<p>No hay expertos asociados a este servicio</p>`;
      }
    });
  });

  document.getElementById(pymeServiceSaveButton).addEventListener("click", function () {
    showLoadingButton(pymeServiceSaveButton, "btn-barymont-red");

    let expertUsers = [];
    document
      .getElementById("experts-data-container")
      .querySelectorAll("[data-expert-user]")
      .forEach((expert) => {
        expertUsers.push({
          userId: expert.querySelector("button").getAttribute("data-user-id"),
          role: expert.querySelector("span").textContent,
        });
      });

    savePymeService(expertUsers);
  });

  $("#" + modalId).modal("show");
  $(".selectpicker").selectpicker();
}

function loadAndOpenHistoricalModal() {
  openHistoricalModal(document.getElementById("service-id").value, pymeServiceHistoricalModal);
}

function loadAndOpenUpdateServiceModal() {
  openUpdateServiceModal(document.getElementById("service-id").value, pymeServiceUpdateModal);
}

async function loadAndOpenUserPreferencesModal() {
  const randomModalId = `modal-${Math.random().toString(36).substring(2, 10)}`;

  const fragment = document.createDocumentFragment();

  const temporalDiv = document.createElement("div");

  const response = await readUserPreferences(USER_PREFERENCE_TOOL_VALUE);

  let userPreferenceHTML = "";
  response.forEach((preference) => {
    userPreferenceHTML += preference.defaultHtmlData;
  });

  temporalDiv.innerHTML = `
    <div class="modal fade" id="${randomModalId}" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">Configuración Preferencias</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body preferences-modal">
                ${userPreferenceHTML}
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
      </div>
    </div>`;

  fragment.appendChild(temporalDiv.firstElementChild);
  document.body.appendChild(fragment);

  const modalElement = document.getElementById(randomModalId);

  const $modalElement = $(`#${randomModalId}`).modal("show");

  $modalElement.on("hidden.bs.modal", function () {
    $modalElement.remove();
  });

  $(modalElement).on("change", "input[data-preference]", async function () {
    await updatePreferenceData(this);
  });

  $(`#${randomModalId} [data-toggle="toggle"]`).bootstrapToggle();
  $('[data-toggle="popover"]').popover();
}

async function readUserPreferences(toolValue) {
  try {
    const response = await searchUserPreferences(toolValue);

    return response;
  } catch (error) {
    createToast("error", "Error", error.message, 5000);
  }
}

async function updatePreferenceData(input) {
  try {
    const preferenceType = input.getAttribute("data-type");
    const preferenceValue = input.checked;
    await updateUserPreference(AppGbSession.getSessionData().userId, preferenceType, preferenceValue);

    createToast("success", "Éxito", "Preferencia actualizada correctamente.", 5000);
  } catch (error) {
    createToast("error", "Error", error.message, 5000);
  }
}

async function loadAndOpenServiceTypeChecklistModal() {
  const serviceTypeId = document.getElementById("pyme-service-type-id").value;
  const randomModalId = `modal-${Math.random().toString(36).substring(2, 10)}`;

  const fragment = document.createDocumentFragment();

  const temporalDiv = document.createElement("div");

  const checklistTemplates = await readChecklistTemplates();
  const checklistTemplatesActions = await readServiceTypeChecklistTemplateActions();
  const pymeServiceSchema = await readServiceTypeSchema(serviceTypeId);
  const pymeServiceTypes = await readPymeServiceTypes();

  let optionHtml = "";
  checklistTemplates.forEach((template) => {
    optionHtml += `<option value="${template.checklistTemplateId}">${template.title}</option>`;
  });

  let actionsOptionHtml = "";
  checklistTemplatesActions.forEach((action) => {
    actionsOptionHtml += `<option value="${action.value}">${action.label}</option>`;
  });

  let pymeServiceTypesOptionHtml = "";
  pymeServiceTypes.forEach((type) => {
    pymeServiceTypesOptionHtml += `<option value="${type.value}">${type.label}</option>`;
  });

  let checklistTemplateHtml = `
        <tr>
          <td colspan="2" class="py-2 px-0">
            <div class="alert alert-info text-center" role="alert">
              Selecciona un tipo de acción y tipo de servicio para poder ver las plantillas asignadas.
            </div>
          </td>
        </tr>`;

  temporalDiv.innerHTML = `
    <div class="modal fade" id="${randomModalId}" tabindex="-1" role="dialog">
      <div class="modal-xl modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">Configuración Automatizaciones</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-4 col-12">
                    <div class="form-group mb-3">
                      <label for="checklist-template" class="form-label">Plantillas <i class="fas fa-asterisk text-barymont-red"></i></label>
                      <select id="checklist-template" class="form-control selectpicker show-tick input-lg modal-selectpicker" data-allow-clear="true" title="Seleccione la plantilla..." data-max-options="1" data-live-search="true" data-size="10" data-dropup-auto="true" required>
                        ${optionHtml}
                      </select>
                      <label for="checklist-template-action" class="form-label">Acciones <i class="fas fa-asterisk text-barymont-red"></i></label>
                      <select id="checklist-template-action" class="form-control selectpicker show-tick input-lg modal-selectpicker" title="Seleccione la acción..." required>
                        ${actionsOptionHtml}
                      </select>
                      ${pymeServiceSchema.htmlData}
                      <label for="checklist-days-to-complete" class="form-label">Días para completarlo</label>
                      <input type="number" id="checklist-days-to-complete" class="form-control" max="9999" min="1">
                    </div>
                    <button class="btn btn-barymont-red w-100 mb-3" id="add-checklist-template"><i class="fa-solid fa-plus pr-2"></i>Añadir</button>
                  </div>
                  <div class="col-md-8 col-12 pr-2">
                    <div class="row">
                      <div class="col-md-6">
                        <select id="checklist-template-action-list" class="form-control selectpicker show-tick input-lg" title="Seleccione una acción...">
                          ${actionsOptionHtml}
                        </select>
                      </div>
                      <div class="col-md-6">
                        <select id="pyme-service-types" class="form-control selectpicker show-tick input-lg" title="Seleccione un tipo de servicio...">
                          ${pymeServiceTypesOptionHtml}
                        </select>
                      </div>
                    </div>
                    <div class="w-100 h-100 scroll-barymont-red pr-2 py-3" style="max-height: 40vh; overflow-y: auto; overflow-x: hidden;">
                      <table class="table table-borderless mb-0">
                        <tbody id="service-type-checklist-templates-table" class="row_position">
                          ${checklistTemplateHtml}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>`;

  fragment.appendChild(temporalDiv.firstElementChild);
  document.body.appendChild(fragment);

  const modalElement = document.getElementById(randomModalId);

  $(`#${randomModalId}`).modal("show");

  $("#" + randomModalId).on("hidden.bs.modal", function () {
    modalElement.remove();
  });

  $(".selectpicker").selectpicker("refresh");

  document.getElementById("checklist-template-action-list").addEventListener("change", async function () {
    const pymesTypeValue = document.getElementById("pyme-service-types").value;

    if (pymesTypeValue === "") {
      return;
    }

    await reloadServiceTypeChecklistTemplatesList(serviceTypeId, this.value, pymesTypeValue);
  });

  document.getElementById("pyme-service-types").addEventListener("change", async function () {
    const actionTypeValue = document.getElementById("checklist-template-action-list").value;

    if (actionTypeValue === "") {
      return;
    }

    await reloadServiceTypeChecklistTemplatesList(serviceTypeId, actionTypeValue, this.value);
  });

  document.getElementById("add-checklist-template").addEventListener("click", async function () {
    showLoadingButton(this.id, "btn-barymont-red");
    const checklistTemplateId = document.getElementById("checklist-template").value;
    const actionValue = document.getElementById("checklist-template-action").value;
    const pymeServiceTypeSelect = document.querySelector("[data-service-type-id][data-key='pymeServiceType']");
    const pymeServiceLabelInput = document.querySelector("[data-service-type-id][data-key='pymeServiceTypeLabel']");
    const daysToComplete = document.getElementById("checklist-days-to-complete").value;

    pymeServiceLabelInput.value = pymeServiceTypeSelect.options[pymeServiceTypeSelect.selectedIndex].text;

    await addServiceTypeChecklistTemplate(serviceTypeId, checklistTemplateId, actionValue, pymeServiceTypeSelect, pymeServiceLabelInput, daysToComplete);
    updateButtonLabel(this.id, '<i class="fa-solid fa-plus pr-2"></i>Añadir', "btn-barymont-red");
  });
}

async function reloadServiceTypeChecklistTemplatesList(serviceTypeId, actionValue, pymesTypeValue) {
  const serviceTypeChecklistTemplates = await readServiceTypeChecklistTemplates({ serviceTypeId: serviceTypeId, actionKey: actionValue, additionalData: { pymeServiceType: pymesTypeValue } });

  const tableBody = document.getElementById("service-type-checklist-templates-table");

  let checklistTemplateHtml = "";
  tableBody.innerHTML = "";
  if (serviceTypeChecklistTemplates.length === 0) {
    checklistTemplateHtml = `
        <tr>
          <td colspan="2" class="py-2 px-0">
            <div class="alert alert-info text-center" role="alert">
              No existe ninguna plantilla asignada para esta acción y en este tipo de servicio.
            </div>
          </td>
        </tr>`;

    tableBody.innerHTML = checklistTemplateHtml;
    return;
  }

  let daysToCompleteHTML = "";
  let additionalDataHTML = "";
  serviceTypeChecklistTemplates.forEach((serviceTypeTemplate) => {
    daysToCompleteHTML = "";
    additionalDataHTML = "";

    if (serviceTypeTemplate.daysToComplete !== null) {
      daysToCompleteHTML = `<span data-days-complete="${serviceTypeTemplate.daysToComplete}">Completar en: ${serviceTypeTemplate.daysToComplete} días</span>`;
    }

    if (serviceTypeTemplate.additionalData.pymeServiceType !== null) {
      additionalDataHTML = `<span data-subtype-key="${serviceTypeTemplate.additionalData.pymeServiceType}">${serviceTypeTemplate.additionalData.pymeServiceTypeLabel}</span>`;
    }

    checklistTemplateHtml += `
        <tr data-template-id="${serviceTypeTemplate.checklistTemplateId}" data-additional-data="${serviceTypeTemplate.additionalData.pymeServiceType}">
          <td colspan="2" class="py-2 px-0">
            <div class="card shadow-sm">
              <div class="card-body p-2">

              <div class="row align-items-center no-gutters">

                <div class="col-12">
                  <div class="row no-gutters">

                    <div class="col-1 d-flex text-center align-items-center">
                      <i class="fas fa-grip-lines w-100 py-3" style="cursor: move;"></i>
                    </div>

                    <div class="col-10 text-center">
                      <div class="container-fluid">
                        <div class="row">

                          <div class="col-12 text-center">
                            <span class="h6 font-weight-bold mb-0">${serviceTypeTemplate.title}</span>
                          </div>

                          <div class="col-3 px-0 text-center text-muted">
                            <span data-action-key="${serviceTypeTemplate.actionKey}">${serviceTypeTemplate.actionLabel}</span>
                          </div>
                          <div class="col-6 px-0 text-center text-muted">
                            ${additionalDataHTML}
                          </div>
                          <div class="col-3 px-1 text-center text-muted">
                            ${daysToCompleteHTML}
                          </div>

                        </div>
                      </div>
                    </div>

                    <div class="col-1 d-flex justify-content-center align-items-center">
                      <div class="btn-group">
                        <button id="delete-${serviceTypeTemplate.checklistTemplateId}-${serviceTypeTemplate.additionalData.pymeServiceType}" type="button" data-action="delete-service-type-checklits-template" data-id="${serviceTypeTemplate.checklistTemplateId}" data-description="${serviceTypeTemplate.title}" data-additional-data="${serviceTypeTemplate.additionalData.pymeServiceType}" class="btn btn-barymont-black btn-sm ml-2">
                          <i class="fas fa-trash-alt" style="pointer-events: none;"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              </div>
            </div>
          </td>
        </tr>`;
  });

  tableBody.innerHTML = checklistTemplateHtml;

  Sortable.create(tableBody, {
    animation: 150,
    draggable: "tr",
    handle: ".fa-grip-lines",
    onEnd: async function () {
      const currentActionValue = document.getElementById("checklist-template-action-list").value;
      const currentPymeServiceType = document.getElementById("pyme-service-types").value;

      try {
        await updateServiceTypeChecklistTemplate(serviceTypeId, currentActionValue, currentPymeServiceType);
        createSnack("success", "Orden actualizado", 3000);
      } catch (error) {
        createToast("error", "Error", error.message, 5000);
      }
    },
  });

  document.querySelectorAll("[data-action='delete-service-type-checklits-template']").forEach((button) => {
    button.addEventListener("click", async function () {
      const checklistTemplateId = this.getAttribute("data-id");
      const currentPymeServiceType = document.getElementById("pyme-service-types").value;

      showLoadingButton(this.id, "btn-barymont-black", true);
      try {
        await deleteServiceTypeChecklistTemplate(serviceTypeId, checklistTemplateId, actionValue, currentPymeServiceType);
        createSnack("info", "Plantilla eliminada", 3000);
      } catch (error) {
        updateButtonLabel(this.id, '<i class="fas fa-trash-alt" style="pointer-events: none;"></i>', "btn-barymont-black");
        createToast("error", "Error", error.message, 5000);
      }
    });
  });
}

async function addServiceTypeChecklistTemplate(serviceTypeId, checklistTemplateId, actionValue, pymeServiceTypeSelect, pymeServiceLabelInput, daysToComplete) {
  if (checklistTemplateId === "") {
    createToast("error", "Error", "Es necesario seleccionar una plantilla.", 5000);
    return;
  }

  if (actionValue === "") {
    createToast("error", "Error", "Es necesario seleccionar una acción.", 5000);
    return;
  }

  if (pymeServiceTypeSelect.getAttribute("data-is-required") === "1" && pymeServiceTypeSelect.value === "") {
    createToast("error", "Error", "Es necesario seleccionar un tipo de servicio.", 5000);
    return;
  }

  let pymeServiceTypeValue = null;
  let pymeServiceLabelInputValue = null;
  if (pymeServiceTypeSelect.value !== "") {
    pymeServiceTypeValue = pymeServiceTypeSelect.value;
    pymeServiceLabelInputValue = pymeServiceLabelInput.value;
  }

  const serviceTypeChecklistTemplatesObtained = await readServiceTypeChecklistTemplates({ serviceTypeId: serviceTypeId, actionKey: actionValue, additionalData: { pymeServiceType: pymeServiceTypeSelect.value } });
  const pymeServiceSelectKey = pymeServiceTypeSelect.getAttribute("data-key");
  const pymeServiceLabelKey = pymeServiceLabelInput.getAttribute("data-key");

  let additionalData = {};
  additionalData[pymeServiceSelectKey] = pymeServiceTypeValue;
  additionalData[pymeServiceLabelKey] = pymeServiceLabelInputValue;

  try {
    let serviceTypeChecklistTemplates = [];
    serviceTypeChecklistTemplatesObtained.forEach((template) => {
      if (template.checklistTemplateId === checklistTemplateId && template.actionKey === actionValue && template.additionalData[pymeServiceSelectKey] === pymeServiceTypeValue) {
        throw new Error("La plantilla seleccionada ya está asignada a esta acción.");
      }

      serviceTypeChecklistTemplates.push({
        checklistTemplateId: template.checklistTemplateId,
        actionKey: template.actionKey,
        daysToComplete: template.daysToComplete,
        order: template.order,
        additionalData: template.additionalData,
      });
    });

    serviceTypeChecklistTemplates.push({
      checklistTemplateId: checklistTemplateId,
      actionKey: actionValue,
      daysToComplete: daysToComplete !== "" ? daysToComplete : null,
      order: serviceTypeChecklistTemplatesObtained.length + 1,
      additionalData: additionalData,
    });

    await updateServiceTypeChecklistTemplates({ serviceTypeId: serviceTypeId, actionKey: actionValue, additionalData: { pymeServiceType: pymeServiceTypeValue }, data: serviceTypeChecklistTemplates });
  } catch (error) {
    createToast("error", "Error", error.message, 5000);
  }

  $("#checklist-template-action-list").val(actionValue);
  $("#checklist-template-action-list").selectpicker("refresh");

  $("#pyme-service-types").val(pymeServiceTypeValue);
  $("#pyme-service-types").selectpicker("refresh");

  await reloadServiceTypeChecklistTemplatesList(serviceTypeId, actionValue, pymeServiceTypeValue);
}

async function deleteServiceTypeChecklistTemplate(serviceTypeId, checklistTemplateId, actionValue, pymesTypeValue) {
  let serviceChecklistTemplates = [];

  const serviceTypeChecklistTemplatesObtained = await readServiceTypeChecklistTemplates({ serviceTypeId: serviceTypeId, actionKey: actionValue, additionalData: { pymeServiceType: pymesTypeValue } });

  let actualOrder = null;
  serviceTypeChecklistTemplatesObtained.forEach((template) => {
    if (template.checklistTemplateId === checklistTemplateId) {
      actualOrder = template.order;
      return;
    }

    if (actualOrder !== null) {
      serviceChecklistTemplates.push({
        checklistTemplateId: template.checklistTemplateId,
        order: template.order - 1,
        daysToComplete: template.daysToComplete,
        actionKey: template.actionKey,
        additionalData: template.additionalData,
      });
    } else {
      serviceChecklistTemplates.push({
        checklistTemplateId: template.checklistTemplateId,
        order: template.order,
        daysToComplete: template.daysToComplete,
        actionKey: template.actionKey,
        additionalData: template.additionalData,
      });
    }
  });

  try {
    await updateServiceTypeChecklistTemplates({ serviceTypeId: serviceTypeId, actionKey: actionValue, additionalData: { pymeServiceType: pymesTypeValue }, data: serviceChecklistTemplates });
  } catch (error) {
    createToast("error", "Error", error.message, 5000);
  }

  $("#checklist-template-action-list").val(actionValue);
  $("#checklist-template-action-list").selectpicker("refresh");
  await reloadServiceTypeChecklistTemplatesList(serviceTypeId, actionValue, pymesTypeValue);
}

async function updateServiceTypeChecklistTemplate(serviceTypeId, actionValue, pymesTypeValue) {
  const tableBody = document.getElementById("service-type-checklist-templates-table");
  const templates = await readServiceTypeChecklistTemplates({ serviceTypeId: serviceTypeId, actionKey: actionValue, additionalData: { pymeServiceType: pymesTypeValue } });

  let templatesReordered = [];
  tableBody.querySelectorAll("tr").forEach((element) => {
    const templateId = element.getAttribute("data-template-id");

    const template = templates.find((t) => t.checklistTemplateId === templateId);

    if (template) {
      template.order = Array.from(tableBody.querySelectorAll("tr")).indexOf(element) + 1;
      templatesReordered.push(template);
    }
  });

  try {
    await updateServiceTypeChecklistTemplates({ serviceTypeId: serviceTypeId, actionKey: actionValue, additionalData: { pymeServiceType: pymesTypeValue }, data: templatesReordered });
  } catch (error) {
    createToast("error", "Error", error.message, 5000);
  }
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname.includes("/herramientas/companyservice")) {
      $("#pyme-service-table").DataTable({
        language: {
          sProcessing: "Procesando Datos...",
          sLengthMenu: "Mostrar _MENU_ servicios",
          sZeroRecords: "No se encontraron servicios coincidentes con el criterio de búsqueda.",
          sEmptyTable: "Ningún servicio coincidente con el criterio de búsqueda.",
          sInfo: "Mostrando servicios del _START_ al _END_ de un total de _TOTAL_ servicios",
          sInfoEmpty: "Mostrando servicios del 0 al 0 de un total de 0 servicios",
          sInfoFiltered: "(Filtrados de un total de _MAX_ servicios)",
          sInfoPostFix: "",
          sSearch: "<div class='input-group-prepend'><div class='input-group-text'><i class='fas fa-search'></i></div></div>",
          searchPlaceholder: "Datos del Servicio",
          sUrl: "",
          sInfoThousands: ",",
          sLoadingRecords: "Cargando Datos...",
          oPaginate: {
            sFirst: "Primera",
            sLast: "Última",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
          oAria: {
            sSortAscending: ": Activar para ordenar la columna de manera ascendente",
            sSortDescending: ": Activar para ordenar la columna de manera descendente",
          },
          buttons: {
            copySuccess: {
              1: "Copiado 1 servicio al Portapapeles",
              _: "Copiados %d servicios al Portapapeles",
            },
            copyTitle: "Servicios Copiados al Portapapeles",
          },
          select: {
            rows: "%d Servicios seleccionados",
          },
        },
        pagingType: "input",
        columnDefs: [
          {
            visible: false,
            targets: [0, 3, 9],
          },
          {
            searchable: true,
            targets: [0, 1, 2, 3, 4, 6, 7, 8],
          },
          {
            className: "dt-center",
            targets: [1, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13],
          },
          {
            orderable: true,
            targets: [1, 2, 6],
          },
          {
            orderable: false,
            targets: [3, 4, 5, 10, 11, 12, 13, 14],
          },
          {
            width: "10%",
            targets: [4, 5, 6, 7, 8, 9],
          },
          {
            width: "6%",
            targets: [3],
          },
          {
            width: "2%",
            targets: [13],
          },
          {
            visible: AppGbSession.checkUserHasPermission("PymeService:PymeServiceTransactionManageAccessChecker"),
            targets: [12],
          },
          {
            visible: true,
            targets: [13],
          },
        ],
        order: [[8, "desc"]],
        processing: true,
        serverSide: true,
        scrollX: true,
        dom: '<"top"fl>Brt<"bottom"ip><"clear">',
        buttons: [
          {
            extend: "colvis",
            text: "Más columnas",
          },
          {
            extend: "collection",
            text: "Herramientas",
            buttons: [
              {
                extend: "copyHtml5",
                text: "Copiar",
              },
              {
                extend: "print",
                text: "Imprimir",
              },
              {
                extend: "excelHtml5",
                title: "Servicios Gestibarymont",
                text: "Excel",
              },
            ],
            fade: true,
          },
          {
            text: '<i class="fas fa-eraser mr-2"></i> Limpiar filtros',
            className: "btn btn-barymont-black my-2 my-md-0",
            action: function () {
              resetearFiltros();
            },
            init: function (api, node) {
              $(node).removeClass("dt-button");
            },
          },
          {
            text: '<i class="fas fa-download mr-2"></i> Exportar',
            className: "btn btn-barymont-black my-2 my-md-0",
            action: function () {
              window.open("/administracion/exportaciones?exportType=pymeService", "_blank");
            },
            init: function (api, node) {
              if (AppGbSession.checkUserHasPermission("PymeService:PymeServiceExportAccessChecker")) {
                $(node).removeClass("dt-button");
              } else {
                $(node).addClass("d-none");
              }
            },
          },
          {
            text: "<i class='fa-solid fa-toolbox mr-2'></i>Automatización checklists",
            className: "btn btn-barymont-red my-2 my-md-0",
            action: async function (node) {
              showLoadingButton(node.currentTarget.id, "btn-barymont-red");
              await loadAndOpenServiceTypeChecklistModal();
              updateButtonLabel(node.currentTarget.id, "<i class='fa-solid fa-toolbox mr-2'></i>Automatización checklists", "btn-barymont-red");
            },
            init: function (api, node) {
              if (AppGbSession.checkUserHasPermission("ChecklistTool:ChecklistToolManageAccessChecker")) {
                $(node).removeClass("dt-button");
                $(node).attr("id", "checklist-tool-button");
              } else {
                $(node).hide();
              }
            },
          },
        ],
        pageLength: 10,
        lengthMenu: [
          [10, 25, 50, 100],
          [10, 25, 50, 100],
        ],
        select: false,
        keys: true,
        searchHighlight: true,
        ajax: {
          url: "/herramientas/companyservice/listaprocessing",
          type: "post",
          data: function (data) {
            const checkServiceState = document.getElementById("pyme-filter-state").value;
            data.serviceState = checkServiceState != "" ? checkServiceState : "";

            const checkServiceType = document.getElementById("pyme-filter-type").value;
            data.serviceType = checkServiceType != "" ? checkServiceType : "";

            const checkServiceProvider = document.getElementById("pyme-filter-provider").value;
            data.serviceProvider = checkServiceProvider != "" ? checkServiceProvider : "";

            const leadId = document.getElementById("pyme-service-lead-id").value;
            data.leadId = leadId != "" ? leadId : "";

            const userId = document.getElementById("pyme-filter-user").value;
            data.userId = userId != "" ? userId : "";

            const userIdFilterMode = document.getElementById("pyme-filter-user-mode").value;
            data.userIdFilterMode = userIdFilterMode != "" ? userIdFilterMode : "";

            const checkUserChecksAssignedState = document.getElementById("cbox7")?.checked;
            data.userChecksAssignedUserId = checkUserChecksAssignedState ? AppGbSession.getSessionData().userId : "";
          },
          error: function (e) {
            if (e.status === 401) {
              createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
              setTimeout(function () {
                location.reload();
              }, 5000);
            } else {
              createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
            }
          },
        },
        fnDrawCallback: function () {
          $('[data-toggle="tooltip"]').tooltip();

          document.querySelectorAll(".historical-btn-to-open-modal").forEach((item) => {
            item.addEventListener("click", (e) => {
              let serviceId = e.target.getAttribute("data-service-id");
              openHistoricalModal(serviceId, pymeServiceHistoricalModal);
            });
          });

          document.querySelectorAll(".add-new-state-btn").forEach((item) => {
            item.addEventListener("click", (e) => {
              let serviceId = e.target.getAttribute("data-service-id");

              openAddNewStateModal(serviceId, pymeServiceAddStateModal);
            });
          });
        },
        stateSave: true,
        stateSaveCallback: function (settings, data) {
          let storageKey = FILTER_STATE_LOCAL_STORAGE_KEY + settings.sInstance;

          data.customFilter = getStateOfCustomFilters();

          localStorage.setItem(storageKey, JSON.stringify(data));
        },
        stateLoadCallback: function (settings) {
          let storageKey = FILTER_STATE_LOCAL_STORAGE_KEY + settings.sInstance;

          if (localStorage.getItem(storageKey) === null) {
            return false;
          }

          let localStorageData = JSON.parse(localStorage.getItem(storageKey));

          if (localStorageData.time < new Date().getTime() - FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME) {
            return false;
          }

          recreateStateOfCustomFilters(localStorageData);

          return localStorageData;
        },
      });

      $(".updateTriggerPymeService").change(function () {
        $("#pyme-service-table").DataTable().ajax.reload();
      });

      document.getElementById("pyme-filter-user").addEventListener("change", function () {
        $("#pyme-service-table").DataTable().ajax.reload();
      });

      document.getElementById("pyme-filter-user-mode").addEventListener("change", function () {
        $("#pyme-service-table").DataTable().ajax.reload();
      });

      document.getElementById("pyme-service-config").addEventListener("click", function () {
        loadAndOpenUserPreferencesModal();
      });

      addCommonServiceModalsEventListeners();
    }

    // Check for /herramientas/leads/{leadId}
    if (window.location.pathname.includes("/herramientas/companyservice") || /^\/herramientas\/leads\/[a-f0-9-]{36}\/?$/.test(window.location.pathname)) {
      if (!AppGbSession.checkUserHasPermission("PymeService:PymeServiceCreateAccessChecker")) {
        document.getElementById(pymeServiceOpenModalButton)?.remove();
      }

      document.getElementById(pymeServiceOpenModalButton)?.addEventListener("click", function () {
        openCreateServiceModal(pymeServiceModal);
      });
    }
  });
}

export default {
  loadAndOpenHistoricalModal,
  loadAndOpenUpdateServiceModal,
  openAddNewStateModal,
};
