"use strict";

var FILTER_STATE_LOCAL_STORAGE_KEY = "PAYMENTS_FILTER_STATE_";
var FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME = 1000 * 60 * 20;

function triggerDatatableUpdateGestionPagos() {
  $("#gestion_pagos_table").DataTable().ajax.reload();
}

function getStateOfCustomFilters() {
  let checkboxes = [];
  document
    .getElementById("configurar_filtros")
    .querySelectorAll("input[type=radio]")
    .forEach(function (checkbox) {
      checkboxes[checkbox.id] = checkbox.checked;
    });

  return {
    valueStartDate: $("#pagoExpiredDateFrom").val(),
    valueEndDate: $("#pagoExpiredDateTo").val(),
    ...checkboxes,
  };
}

function recreateStateOfCustomFilters(localStorageData) {
  if (localStorageData.customFilter === undefined) {
    return false;
  }

  document
    .getElementById("configurar_filtros")
    .querySelectorAll("input[type=radio]")
    .forEach(function (checkbox) {
      $("#" + checkbox.id).prop("checked", localStorageData.customFilter[checkbox.id]);
    });

  $("#pagoExpiredDateFrom").val(localStorageData.customFilter.valueStartDate);
  $("#pagoExpiredDateTo").val(localStorageData.customFilter.valueEndDate);
}

function initializeDataTablesGestionPagos(tableId) {
  $("#" + tableId).DataTable({
    language: {
      sProcessing: "Procesando Datos...",
      sLengthMenu: "Mostrar _MENU_ pagos",
      sZeroRecords: "No se encontraron pagos coincidentes con el criterio de búsqueda.",
      sEmptyTable: "Ningún pago coincidente con el criterio de búsqueda.",
      sInfo: "Mostrando pagos del _START_ al _END_ de un total de _TOTAL_ pagos",
      sInfoEmpty: "Mostrando pagos del 0 al 0 de un total de 0 pagos",
      sInfoFiltered: "(Filtrados de un total de _MAX_ pagos)",
      sInfoPostFix: "",
      sSearch: "<div class='input-group-prepend'><div class='input-group-text'><i class='fas fa-search'></i></div></div>",
      searchPlaceholder: "Datos del pago",
      sUrl: "",
      sInfoThousands: ",",
      sLoadingRecords: "Cargando Datos...",
      oPaginate: {
        sFirst: "Primera",
        sLast: "Última",
        sNext: "Siguiente",
        sPrevious: "Anterior",
      },
      oAria: {
        sSortAscending: ": Activar para ordenar la columna de manera ascendente",
        sSortDescending: ": Activar para ordenar la columna de manera descendente",
      },
      buttons: {
        copySuccess: {
          1: "Copiado 1 pago al portapapeles",
          _: "Copiados %d pagos al portapapeles",
        },
        copyTitle: "Pagos copiados al portapapeles",
      },
      select: {
        rows: "%d Pagos seleccionados",
      },
    },
    pagingType: "input",
    columnDefs: [
      {
        visible: false,
        targets: [0, 1, 5, 7, 12],
      },
      {
        searchable: true,
        targets: [0, 1, 4, 5, 6, 7, 8, 9],
      },
      {
        className: "dt-center",
        targets: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
      },
      {
        width: "35%",
        targets: [6],
      },
      {
        width: "8%",
        targets: [8],
      },
      {
        width: "3%",
        targets: [13],
      },
      {
        orderable: false,
        targets: [0, 1, 5, 6, 7, 13],
      },
    ],
    order: [[11, "desc"]],
    processing: true,
    serverSide: true,
    scrollX: true,
    dom: '<"top"fl>Brt<"bottom"ip><"clear">',
    buttons: [
      {
        extend: "colvis",
        text: "Más columnas",
      },
      {
        extend: "collection",
        text: "Herramientas",
        buttons: [
          {
            extend: "copyHtml5",
            text: "Copiar",
          },
          {
            extend: "print",
            text: "Imprimir",
          },
          {
            extend: "excelHtml5",
            title: "Pagos Gestibarymont",
            text: "Excel",
          },
        ],
        fade: true,
      },
      {
        text: '<i class="fas fa-eraser mr-2"></i> Limpiar filtros',
        className: "btn btn-barymont-black my-2 my-md-0",
        action: function () {
          resetDataValues();
        },
        init: function (api, node) {
          $(node).removeClass("dt-button");
        },
      },
      {
        text: "<i class='fas fa-plus mr-2'></i> Crear pago",
        className: "btn btn-barymont-red m-1",
        action: function () {
          location.href = "/herramientas/gestionpagos/crear";
        },
        init: function (api, node) {
          if (AppGbSession.checkUserHasPermission("Payments:PaymentsCreateAccessChecker")) {
            $(node).removeClass("dt-button");
          } else {
            $(node).hide();
          }
        },
      },
    ],
    pageLength: 10,
    lengthMenu: [
      [10, 25, 50, 100, 500],
      [10, 25, 50, 100, 500],
    ],
    select: false,
    keys: true,
    searchHighlight: true,
    ajax: {
      url: "/herramientas/gestionpagos/ajaxDatatables",
      type: "post",
      data: function (data) {
        let checkTodos = $("#cboxAll").prop("checked");
        let checkWaiting = $("#cboxWAITING").prop("checked");
        let checkPaid = $("#cboxPAID").prop("checked");
        let checkCancel = $("#cboxCANCEL").prop("checked");

        data.allStatus = checkTodos === true ? 1 : 0;
        data.soloWaiting = checkWaiting === true ? 1 : 0;
        data.soloPaid = checkPaid === true ? 1 : 0;
        data.soloCancel = checkCancel === true ? 1 : 0;

        let checkPagoExpiredDateTo = $("#pagoExpiredDateTo").val();
        data.pagoExpiredDateTo = checkPagoExpiredDateTo != "" ? checkPagoExpiredDateTo : 0;

        let checkPagoExpiredDateFrom = $("#pagoExpiredDateFrom").val();
        data.pagoExpiredDateFrom = checkPagoExpiredDateFrom != "" ? checkPagoExpiredDateFrom : 0;
      },
      error: function (e) {
        if (e.status === 401) {
          createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
          setTimeout(function () {
            location.reload();
          }, 5000);
        } else {
          createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
        }
      },
    },
    fnDrawCallback: function () {
      document.querySelectorAll("[data-action='copy-url']").forEach((element) => {
        element.addEventListener("click", () => {
          copyUrlPagoToClipboard(element.dataset.url);
        });
      });

      $('[data-toggle="tooltip"]').tooltip();
    },
    stateSave: true,
    stateSaveCallback: function (settings, data) {
      let storageKey = `${FILTER_STATE_LOCAL_STORAGE_KEY}${settings.sInstance}_${getCookieValue("user_id")}`;

      data.customFilter = getStateOfCustomFilters();

      localStorage.setItem(storageKey, JSON.stringify(data));
    },
    stateLoadCallback: function (settings) {
      let storageKey = `${FILTER_STATE_LOCAL_STORAGE_KEY}${settings.sInstance}_${getCookieValue("user_id")}`;

      if (localStorage.getItem(storageKey) === null) {
        return null;
      }

      let localStorageData = JSON.parse(localStorage.getItem(storageKey));

      if (localStorageData.time < new Date().getTime() - FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME) {
        return false;
      }

      recreateStateOfCustomFilters(localStorageData);

      return localStorageData;
    },
  });
}

function resetDataValues() {
  $("#pagoExpiredDateTo").val("");
  $("#pagoExpiredDateFrom").val("");

  $("#select_filter_order").val("");
  $("#select_filter_order").selectpicker("refresh");

  $("#select_filter_colum").val("");
  $("#select_filter_colum").selectpicker("refresh");

  document.getElementById("cboxAll").checked = true;

  $("#cboxWAITING").prop("checked", false);
  $("#cboxPAID").prop("checked", false);
  $("#cboxCANCEL").prop("checked", false);

  $("#gestion_pagos_table").DataTable().search("").draw();

  triggerDatatableUpdateGestionPagos();
}

function checkpagoExpiredDate() {
  let pagoExpiredDateFrom = new Date($("#pagoExpiredDateFrom").val());
  let pagoExpiredDateTo = new Date($("#pagoExpiredDateTo").val());

  if (pagoExpiredDateFrom > pagoExpiredDateTo) {
    createToast("warning", "Fecha introducida, incorrecta ⚠️", "La fecha INICIO no puede ser mayor que la fecha de FIN", 3000);
  }
}

function cancelPayment(uuid) {
  $.ajax({
    url: "/herramientas/gestionpagos/ajax/cancelarPago",
    type: "POST",
    data: {
      uuid: uuid,
    },
  })
    .done(function (data) {
      if (data != "") {
        if (data["status"] == 1) {
          const upercaseLabel = data["badgeData"]["label"].toUpperCase();

          document.getElementById("statusRow").innerHTML = `
          <div class="col-12">
            <div class="h2"><span class="mb-0 badge badge-${data["badgeData"]["style"]} font-weight-bold w-100 d-block py-3 px-2 m-2">${upercaseLabel}</span></div>
          </div>
        `;

          const shareInfoContainer = document.getElementById("shareInfo");

          shareInfoContainer.innerHTML = "";
          shareInfoContainer.className = "";
        } else {
          createToast("error", "Error al cancelar el pago ❌", "No se ha podido cancelar el pago", 3000);
        }
      }
    })
    .fail(function (e) {
      if (e.status === 401) {
        createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
        setTimeout(function () {
          location.reload();
        }, 5000);
      } else {
        createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
      }
    });
}

function copyUrlPagoToClipboard(urlPago) {
  let aux = document.createElement("input");
  aux.setAttribute("value", urlPago);
  document.body.appendChild(aux);
  aux.select();
  document.execCommand("copy");
  document.body.removeChild(aux);
  createToast("success", "Enlace copiado al portapapeles ✅", "El enlace del pago se ha copiado al portapapeles", 3000);
}

function enviarEmailEnlaceGestionPagos() {
  let email = document.getElementById("sendEnlaceGestionPagosShareEmail");
  let urlPago = document.getElementById("enlaceGestionPagosShare");
  const regex = /^(("[\u00F1-\w-\s]+")|([\u00F1-\w-]+(?:\.[\u00F1-\w-]+)*)|("[\u00F1-\w-\s]+")([\u00F1-\w-]+(?:\.[\u00F1-\w-]+)*))(@((?:[\u00F1a-zA-Z0-9-]+\.)*\w[\u00F1a-zA-Z0-9-]{0,66})\.([a-z-\u00F1]{2,6}(?:\.[a-z-\u00F1]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;

  if (!email.checkValidity() && !String(email.value).toLowerCase().match(regex)) {
    createToast("warning", "Email incorrecto ⚠️", "El email introducido no es correcto", 3000);
    return;
  }
  $.ajax({
    url: "/herramientas/gestionpagos/ajax/enviarEmailEnlaceGestionPagos",
    type: "POST",
    data: {
      email: email.value,
      urlPago: urlPago.value,
    },
  })
    .done(function (data) {
      if (data != "") {
        if (data["status"] == 1) {
          createToast("success", "Email enviado ✅", "El email se ha enviado correctamente", 3000);
          document.getElementById("enlaceBox").innerHTML = `✅ <span class="text-success"> Email enviado correctamente a <strong>${data["message"]["email"]}</strong></span>`;
        } else {
          createToast("error", "Error al enviar el email ❌", "No se ha podido enviar el email", 3000);
        }
      }
    })
    .fail(function (e) {
      if (e.status === 401) {
        createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
        setTimeout(function () {
          location.reload();
        }, 5000);
      } else {
        createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
      }
    });
}

function updateElemValueIfDifferent(elem, newValue) {
  if (elem.value !== String(newValue)) {
    elem.value = newValue;
  }
}

function calculateTaxPercentage() {
  let importePagoElem = document.getElementById("importe_pago");
  let tasaPorcentajeElem = document.getElementById("tasa_porcentaje");

  let importePagoValue = parseFloat(importePagoElem.value ? parseFloat(importePagoElem.value).toFixed(2) : 0);
  let tasaPorcentajeValue = parseFloat(tasaPorcentajeElem.value ? parseFloat(tasaPorcentajeElem.value).toFixed(2) : 0);

  if (tasaPorcentajeValue > 100) {
    tasaPorcentajeValue = 100;
  }

  if (tasaPorcentajeValue < 0) {
    tasaPorcentajeValue = 0;
  }

  updateElemValueIfDifferent(importePagoElem, importePagoValue);
  updateElemValueIfDifferent(tasaPorcentajeElem, tasaPorcentajeValue);

  document.getElementById("valor_bruto").innerHTML = (importePagoValue + importePagoValue * (tasaPorcentajeValue / 100)).toFixed(2);
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname.includes("/herramientas/gestionpagos")) {
      document.getElementById("importe_pago")?.addEventListener("keyup", calculateTaxPercentage);
      document.getElementById("tasa_porcentaje")?.addEventListener("keyup", calculateTaxPercentage);

      document.getElementById("buttonEnlaceGestionPagosShareEmail")?.addEventListener("click", enviarEmailEnlaceGestionPagos);

      document.querySelectorAll("[data-action='cancel']").forEach((element) => {
        element.addEventListener("click", () => {
          cancelPayment(element.dataset.uuid);
        });
      });

      document.getElementById("pagoExpiredDateFrom")?.addEventListener("change", checkpagoExpiredDate);
      document.getElementById("pagoExpiredDateTo")?.addEventListener("change", checkpagoExpiredDate);

      initializeDataTablesGestionPagos("gestion_pagos_table");

      $(".triggerDatatableUpdateGestionPagos").change(function () {
        $("#gestion_pagos_table").DataTable().ajax.reload();
      });
    }
  });
}
