"use strict";

import { createModal } from "../shared/shared.js";
import settledSharedFunctions from "./settledShared.js";

let settledForListToPayDatatable;
let selectedNifForSettledToPayArray = [];
let maxNifForSettledToPay = 0;

async function fetchNifsForSettledToPay() {
  let requestOptions = {
    method: "POST",
    redirect: "follow",
  };

  return await fetch("/herramientas/liquidaciones/ajax/getSettledForListToPaid", requestOptions).then((response) => response.json());
}

function printContainerLoadingState(containerId) {
  document.getElementById(containerId).innerHTML = `
    <div class="col-12 text-center mt-5">
      <div class="spinner-border text-dark" role="status"></div>
      <div class="mt-3">Cargando liquidaciones disponibles para ser pagadas...</div>
    </div>`;
}

function clearContainer(containerId) {
  document.getElementById(containerId).innerHTML = "";
}

function printAlertMessage(containerId) {
  const container = document.getElementById(containerId);

  const divCol12AlertMessaje = document.createElement("div");
  divCol12AlertMessaje.className = "col-12";

  const alertDiv = document.createElement("div");
  alertDiv.className = "alert alert-primary w-100 mx-1 text-center mb-3";
  alertDiv.setAttribute("role", "alert");
  alertDiv.style.fontSize = "15px";
  alertDiv.style.fontWeight = "bold";
  alertDiv.textContent = "📄 Selecciona las liquidaciones facturadas que deseas pagar";

  divCol12AlertMessaje.appendChild(alertDiv);
  container.appendChild(divCol12AlertMessaje);
}

function createDatatableStructure(containerId, tableId) {
  const container = document.getElementById(containerId);

  const divCol12 = document.createElement("div");
  divCol12.className = "col-12";

  const table = document.createElement("table");
  table.id = tableId;
  table.className = "display nowrap compact row-border cell-border stripe hover w-100";

  divCol12.appendChild(table);
  container.appendChild(divCol12);
}

function printSettledForListToPayDatatableByJson(data, tableId) {
  if ($.fn.DataTable.isDataTable("#" + tableId)) {
    $("#" + tableId)
      .DataTable()
      .destroy();
    $("#" + tableId).empty();
  }

  maxNifForSettledToPay = data.length;

  var transformedData = data.map(function (item) {
    return {
      settled_id: item.settledId,
      nif: item.nif.nif,
      name: item.nif.full_name,
      commission_quantity: item.numberOfCommissions,
      tax_base: {
        render: item.grossAmountTotal + " €",
        sort: function () {
          return item.grossAmountTotal.replace(/\s/g, "").replace(/\./g, "");
        },
      },
      iva_percentage: {
        render: item.nif.tax_area / 100 + " %",
        sort: function () {
          return item.nif.tax_area;
        },
      },
      iva_total: {
        render: item.ivaAmountTotal + " €",
        sort: function () {
          return item.ivaAmountTotal.replace(/\s/g, "").replace(/\./g, "");
        },
      },
      irpf_percentage: {
        render: item.nif.tax_personal / 100 + " %",
        sort: function () {
          return item.nif.tax_personal;
        },
      },
      irpf_total: {
        render: item.irpfAmountTotal + " €",
        sort: function () {
          return item.irpfAmountTotal.replace(/\s/g, "").replace(/\./g, "");
        },
      },
      total_liquid: {
        render: item.netAmountTotal + " €",
        sort: function () {
          return item.netAmountTotal.replace(/\s/g, "").replace(/\./g, "");
        },
      },
      dateTo: item.dateTo,
      dateFrom: item.dateFrom,
      service_providers: JSON.stringify(item.serviceProviderEnumCollection),
    };
  });

  settledForListToPayDatatable = $("#" + tableId).DataTable({
    language: {
      sProcessing: "Procesando datos...",
      sLengthMenu: "Mostrar _MENU_ liquidaciones para pagar",
      sZeroRecords: "No se encontraron liquidaciones para pagar coincidentes con el criterio de búsqueda.",
      sEmptyTable: "No hay liquidaciones para pagar disponibles",
      sInfo: "Mostrando liquidaciones para pagar del _START_ al _END_ de un total de _TOTAL_",
      sInfoEmpty: "Mostrando liquidaciones para pagar del 0 al 0 de un total de 0",
      sInfoFiltered: "(Filtrados de un total de _MAX_ liquidaciones para pagar)",
      sSearch: "<div class='input-group-prepend'><div class='input-group-text'><i class='fas fa-search'></i></div></div>",
      sUrl: "",
      sInfoThousands: ",",
      sLoadingRecords: "Cargando Datos...",
      oPaginate: {
        sFirst: "Primera",
        sLast: "Última",
        sNext: "Siguiente",
        sPrevious: "Anterior",
      },
      oAria: {
        sSortAscending: ": Activar para ordenar la columna de manera ascendente",
        sSortDescending: ": Activar para ordenar la columna de manera descendente",
      },
      select: {
        rows: {
          _: "",
        },
      },
    },
    pagingType: "input",
    order: [[0, "desc"]],
    processing: true,
    dom: '<"top"fl>Brt<"bottom"ip><"clear">',
    buttons: [
      {
        text: "Seleccionar todos",
        className: "btn btn-barymont-red my-1 select-all-commissions",
        action: function () {
          selectAllNifForSettled();
        },
        init: function (api, node) {
          $(node).removeClass("dt-button");
        },
      },
      {
        text: "Limpiar seleccion",
        className: "btn btn-barymont-black my-2 my-md-0 deselect-all-commissions",
        action: function () {
          resetSelectedRows();
        },
        init: function (api, node) {
          $(node).removeClass("dt-button");
        },
      },
      {
        text: "Haz click en un fila para seleccionar una liquidacion para pagar",
        className: "btn btn-barymont-grey font-weight-bold total-selected not-clickable",
        init: function (api, node) {
          $(node).removeClass("dt-button");
        },
      },
    ],
    data: transformedData,
    columns: [
      {
        data: "settled_id",
        title: "id liquidación",
        visible: false,
      },
      {
        data: "nif",
        title: "NIF",
      },
      { data: "name", title: "Nombre", className: "text-left" },
      { data: "commission_quantity", title: "Nº Com.", className: "text-center" },
      {
        data: "tax_base",
        title: "B. Imponible",
        className: "text-right",
        render: function (data, type) {
          if (type === "sort") return data.sort;
          return data.render;
        },
      },
      {
        data: "iva_percentage",
        title: "% TAX Área",
        className: "text-right",
        render: function (data, type) {
          if (type === "sort") return data.sort;
          return data.render;
        },
      },
      {
        data: "iva_total",
        title: "I. TAX Área",
        className: "text-right",
        render: function (data, type) {
          if (type === "sort") return data.sort;
          return data.render;
        },
      },
      {
        data: "irpf_percentage",
        title: "% TAX Personal",
        className: "text-right",
        render: function (data, type) {
          if (type === "sort") return data.sort;
          return data.render;
        },
      },
      {
        data: "irpf_total",
        title: "I. TAX Personal",
        className: "text-right",
        render: function (data, type) {
          if (type === "sort") return data.sort;
          return data.render;
        },
      },
      {
        data: "total_liquid",
        title: "I. Líquido",
        className: "text-right",
        render: function (data, type) {
          if (type === "sort") return data.sort;
          return data.render;
        },
      },
    ],
    pageLength: 15,
    scrollX: true,
    lengthMenu: [
      [15, 25, 50],
      [15, 25, 50],
    ],
    select: {
      style: "multi",
      selector: "td",
      className: "clickable",
    },
    keys: true,
    searchHighlight: true,
    fnRowCallback: function (nRow) {
      updateNifForSettledDatatableActionButtons();
      return nRow;
    },
    fnDrawCallback: function () {
      updateNifForSettledDatatableActionButtons();
    },
  });

  function selectAllNifForSettled() {
    var allData = settledForListToPayDatatable.rows().data().toArray();

    allData.forEach(function (data) {
      if ($.inArray(data.settled_id, selectedNifForSettledToPayArray) === -1) {
        selectedNifForSettledToPayArray.push(data.settled_id);
      }
    });

    settledForListToPayDatatable.rows().nodes().to$().css("background-color", "var(--barymont-green)").select();
    updateNifForSettledDatatableActionButtons();
  }

  function resetSelectedRows() {
    selectedNifForSettledToPayArray = [];
    settledForListToPayDatatable.rows().deselect();
    settledForListToPayDatatable.rows().nodes().to$().css("background-color", "white");
    updateNifForSettledDatatableActionButtons();
  }

  settledForListToPayDatatable.on("select", function (e, dt, type, indexes) {
    const rowData = settledForListToPayDatatable.rows(indexes).data().toArray();

    if ($.inArray(rowData[0].settled_id, selectedNifForSettledToPayArray) === -1) {
      settledForListToPayDatatable.rows(indexes).nodes().to$().css("background-color", "var(--barymont-green)");
      selectedNifForSettledToPayArray.push(rowData[0].settled_id);
    }
    updateNifForSettledDatatableActionButtons();
  });

  settledForListToPayDatatable.on("deselect", function (e, dt, type, indexes) {
    const rowData = settledForListToPayDatatable.rows(indexes).data().toArray();

    settledForListToPayDatatable.rows(indexes).nodes().to$().css("background-color", "white");
    selectedNifForSettledToPayArray.splice($.inArray(rowData[0].settled_id, selectedNifForSettledToPayArray), 1);

    if ($(".select-all-commissions").text() === "Deseleccionar todos") {
      $(".select-all-commissions").text("Seleccionar todos");
      $(".select-all-commissions").removeClass("btn-barymont-green");
      $(".select-all-commissions").addClass("btn-barymont-red");
    }
    updateNifForSettledDatatableActionButtons();
  });

  $(".select-all-commissions")
    .off("click")
    .on("click", function () {
      if ($(this).text() === "Seleccionar todos") {
        selectAllNifForSettled();
      } else {
        resetSelectedRows();
      }
    });

  resetSelectedRows();
}

function updateNifForSettledDatatableActionButtons() {
  $(".total-selected").html(selectedNifForSettledToPayArray.length + " nif" + (selectedNifForSettledToPayArray.length === 1 ? "" : "s") + " seleccionado" + (selectedNifForSettledToPayArray.length === 1 ? "" : "s"));

  if (maxNifForSettledToPay === selectedNifForSettledToPayArray.length && maxNifForSettledToPay !== 0) {
    $(".select-all-commissions").text("Deselecionar todos");
    $(".select-all-commissions").removeClass("btn-barymont-red");
    $(".select-all-commissions").addClass("btn-barymont-green");
  } else {
    $(".select-all-commissions").text("Seleccionar todos");
    $(".select-all-commissions").removeClass("btn-barymont-green");
    $(".select-all-commissions").addClass("btn-barymont-red");
  }
}

async function fetchMarkSettlementAsPaid(paymentMethod, generateCsvForSepa, reason) {
  const formData = new FormData();

  formData.append("settledIdArray", JSON.stringify(selectedNifForSettledToPayArray));
  formData.append("paymentMethod", paymentMethod);
  formData.append("generateCsvForSepa", generateCsvForSepa);
  formData.append("reason", reason);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/liquidaciones/ajax/markSettlementAsPaid", requestOptions).then((response) => response.json());
}

function createNifWithoutBankAccountModal(nifCollection) {
  const modalId = "modal-nif-without-bank-account";

  const alertDiv = `
    <div class="alert alert-light w-100 text-center" role="alert" style="font-size: 15px; font-weight: bold;">
      No se ha podido realizar la liquidación porque los siguientes NIFs no tienen una cuenta bancaria asociada. Por favor, completa la información de la cuenta bancaria para poder procesar la liquidación masiva.
    </div>
  `;

  const tableHeader = `
    <thead class="thead-light">
      <tr>
        <th>NIF</th>
        <th>Nombre</th>
        <th style="width: 100px;">Acceso al Nif</th>
      </tr>
    </thead>
  `;

  const tableBody = nifCollection.map((nif) => `
    <tr>
      <td>${nif.nif}</td>
      <td>${nif.full_name}</td>
      <td>
        <a class="btn btn-barymont-red w-100" href="/herramientas/nif/?mode=update&nif=${nif.nif}" target="_blank">
          <i class="fas fa-external-link-alt"></i>
        </a>
      </td>
    </tr>
  `).join("");

  const table = `
    <table class="table table-striped table-bordered table-hover table-sm w-100 text-center mb-0">
      ${tableHeader}
      <tbody>${tableBody}</tbody>
    </table>
  `;

  const modalBodyContent = `
    ${alertDiv}
    ${table}
  `;

  const footerButtons = [
    { id: "close-modal-btn", label: "Cerrar", classes: "btn btn-barymont-black", attr: "data-dismiss='modal'" }
  ];

  const modalElement = createModal(modalId, "lg", "NIFs sin cuenta bancaria", modalBodyContent, footerButtons, "p-0");

  modalElement.modal("show");
}


if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname === "/herramientas/liquidaciones") {
      const btnPaidMassiveSettledId = "btn-massive-payment";

      const modalMassivePaymentId = "modal-massive-payment";
      const modalMassivePaymentContainerId = "modal-massive-payment-container";
      const modalDatatableId = "table-with-nifs-for-massive-payment-table";

      const btnPaidMassivePaymentGenerateId = "btn-massive-payment-generate";

      const modalMassivePaymentConfirmId = "modal-massive-payment-confirm";
      const modalMassivePaymentonfirmNumberOfNifsSelectedId = "modal-massive-payment-number-of-nifs-selected";
      const modalMassivePaymentPaymentMethodId = "modal-massive-payment-method";
      const modalMassivePaymentReasonId = "modal-massive-payment-reason";
      const modalMassivePaymentGenerateSepaCsvId = "modal-massive-payment-generate-sepa-csv";

      const modalMassivePaymentConfirmGenerateId = "btn-massive-payment-confirm-generate";

      const modalMassiveSettledResultId = "modal-massive-settled-result";
      const modalMassiveSettledResultTitleId = "modal-massive-settled-result-title";

      document.getElementById(btnPaidMassiveSettledId)?.addEventListener("click", () => {
        printContainerLoadingState(modalMassivePaymentContainerId);
        $("#" + modalMassivePaymentId).modal("show");
      });

      $("#" + modalMassivePaymentId).on("shown.bs.modal", function () {
        fetchNifsForSettledToPay().then((response) => {
          if (response.status === 1) {
            clearContainer(modalMassivePaymentContainerId);
            printAlertMessage(modalMassivePaymentContainerId);
            createDatatableStructure(modalMassivePaymentContainerId, modalDatatableId);
            printSettledForListToPayDatatableByJson(response.data, modalDatatableId);
          } else {
            createToast("warning", "Ha ocurrido un error", response.error.message, 5000);
          }
        });
      });

      document.getElementById(btnPaidMassivePaymentGenerateId).addEventListener("click", () => {
        if (selectedNifForSettledToPayArray.length === 0 || selectedNifForSettledToPayArray.length == []) {
          createToast("warning", "Error", "No has seleccionado ningúna liquidacion facturada para pagar", 5000);
        } else {
          document.getElementById(modalMassivePaymentonfirmNumberOfNifsSelectedId).textContent = selectedNifForSettledToPayArray.length;
          $("#" + modalMassivePaymentConfirmId).modal("show");
        }
      });

      document.getElementById(modalMassivePaymentConfirmGenerateId).addEventListener("click", () => {
        const paymentMethod = document.getElementById(modalMassivePaymentPaymentMethodId).value;

        if (paymentMethod === "") {
          createToast("warning", "Error", "Debes seleccionar un método de pago", 5000);
          return;
        }

        settledSharedFunctions.showLoadingButton(modalMassivePaymentConfirmGenerateId, "btn-barymont-red");

        const generateCsvForSepa = document.getElementById(modalMassivePaymentGenerateSepaCsvId).checked || false;
        const reason = document.getElementById(modalMassivePaymentReasonId).value || null;

        fetchMarkSettlementAsPaid(paymentMethod, generateCsvForSepa, reason).then((response) => {
          if (response.status === 1) {
            createToast("success", "Operación realizada", response.data.message, 5000);
            $("#" + modalMassivePaymentConfirmId).modal("hide");
            $("#" + modalMassivePaymentId).modal("hide");

            window.dispatchEvent(new CustomEvent("massiveSettlementPaid"));

            let downloadFileData = null;
            if (generateCsvForSepa) {
               downloadFileData = {
                csvContent: response.data.csvContent,
                fileName: "csv_to_sepa_" + new Date().toISOString().split("T")[0] + "_" + new Date().toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, "-").replace(/\./g, "_") + ".csv",
               }
            }
            settledSharedFunctions.printSettledResultContent(modalMassiveSettledResultId, response.data.settledResults, modalMassiveSettledResultTitleId, "PAYMENT", downloadFileData);
          } else {
            createToast("warning", "Ha ocurrido un error", response.error.message, 5000);

            if (response.error?.nifWithoutBankAccountCollection !== null && response.error?.nifWithoutBankAccountCollection.length > 0) {
              createNifWithoutBankAccountModal(response.error.nifWithoutBankAccountCollection);
            }
          }
          settledSharedFunctions.updateButtonLabel(modalMassivePaymentConfirmGenerateId, "Pagar", "btn-barymont-red");
        });
      });

      document.getElementById(modalMassivePaymentPaymentMethodId).addEventListener("change", () => {
        if (document.getElementById(modalMassivePaymentPaymentMethodId).value == "BANK_TRANSFER") {
          document.getElementById(modalMassivePaymentGenerateSepaCsvId).parentElement.classList.remove("d-none");
        } else {
          document.getElementById(modalMassivePaymentGenerateSepaCsvId).parentElement.classList.add("d-none");
        }
      });

      document.getElementById(modalMassivePaymentGenerateSepaCsvId).addEventListener("click", () => {
        if (document.getElementById(modalMassivePaymentGenerateSepaCsvId).checked) {
          document.getElementById(modalMassivePaymentReasonId).parentElement.classList.remove("d-none");
        } else {
          document.getElementById(modalMassivePaymentReasonId).parentElement.classList.add("d-none");
        }
      });

      document.getElementById
    }
  });
}
