"use strict";

export async function importFile(file) {

  if (!(file instanceof File)) {
    throw new Error("The file must be a File object.");
  }

  if (file.type !== "text/csv") {
    throw new Error("The file must be a CSV file.");
  }

  if (file.size <= 0) {
    throw new Error("The file its empty.");
  }

  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch("/api/commercial-products/import", {
    method: "POST",
    body: formData,
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}
