"use strict";

import { importFile } from "../../api/commercial-products.js";
import { showLoadingButton, updateButtonLabel } from "../shared/shared.js";
import { addCommonServiceModalsEventListeners } from "./services.js";

var FILTER_STATE_LOCAL_STORAGE_KEY = "CP_FILTER_STATE_";
var FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME = 1000 * 60 * 20;

function getStateOfCustomFilters() {
  return {
    tipoServicio: $("#cp-filter-type").val(),
    updatedTo: $("#cp-filter-last-update-to").val(),
    updatedFrom: $("#cp-filter-last-update-from").val(),
  };
}

function recreateStateOfCustomFilters(localStorageData) {
  if (localStorageData.customFilter === undefined) {
    return false;
  }

  $("#cp-filter-type").val(localStorageData.customFilter.tipoServicio);
  $("#cp-filter-last-update-to").val(localStorageData.customFilter.updatedTo);
  $("#cp-filter-last-update-from").val(localStorageData.customFilter.updatedFrom);

  $("#cp-filter-type").selectpicker("refresh");
}

function loadDatatable(checkAdminToolsRights) {
  $("#cp-service-table").DataTable({
    language: {
      sProcessing: "Procesando Datos...",
      sLengthMenu: "Mostrar _MENU_ servicios",
      sZeroRecords: "No se encontraron servicios coincidentes con el criterio de búsqueda.",
      sEmptyTable: "Ningún servicio coincidente con el criterio de búsqueda.",
      sInfo: "Mostrando servicios del _START_ al _END_ de un total de _TOTAL_ servicios",
      sInfoEmpty: "Mostrando servicios del 0 al 0 de un total de 0 servicios",
      sInfoFiltered: "(Filtrados de un total de _MAX_ servicios)",
      sInfoPostFix: "",
      sSearch: "<div class='input-group-prepend'><div class='input-group-text'><i class='fas fa-search'></i></div></div>",
      searchPlaceholder: "Datos del Servicio",
      sUrl: "",
      sInfoThousands: ",",
      sLoadingRecords: "Cargando Datos...",
      oPaginate: {
        sFirst: "Primera",
        sLast: "Última",
        sNext: "Siguiente",
        sPrevious: "Anterior",
      },
      oAria: {
        sSortAscending: ": Activar para ordenar la columna de manera ascendente",
        sSortDescending: ": Activar para ordenar la columna de manera descendente",
      },
      buttons: {
        copySuccess: {
          1: "Copiado 1 servicio al Portapapeles",
          _: "Copiados %d servicios al Portapapeles",
        },
        copyTitle: "Servicios Copiados al Portapapeles",
      },
      select: {
        rows: "%d Servicios seleccionados",
      },
    },
    pagingType: "input",
    columnDefs: [
      {
        visible: false,
        targets: [0],
      },
      {
        searchable: true,
        targets: [0, 1, 2, 3],
      },
      {
        width: "20%",
        className: "text-wrap",
        targets: [3],
      },
      {
        className: "dt-center",
        targets: [1, 2, 4, 5, 6, 7],
      },
      {
        orderable: true,
        targets: [1, 2, 6, 7],
      },
      {
        orderable: false,
        targets: [0, 3, 4, 5, 6, 8],
      },
      {
        width: "30px",
        targets: [4, 5],
      },
      {
        width: "10%",
        targets: [6, 7],
      },
      {
        width: "50px",
        targets: [8],
      },
    ],
    order: [[6, "desc"]],
    processing: true,
    serverSide: true,
    scrollX: true,
    dom: '<"top"fl>Brt<"bottom"ip><"clear">',
    buttons: [
      {
        extend: "colvis",
        text: "Más columnas",
      },
      {
        extend: "collection",
        text: "Herramientas",
        buttons: [
          {
            extend: "copyHtml5",
            text: "Copiar",
          },
          {
            extend: "print",
            text: "Imprimir",
          },
          {
            extend: "excelHtml5",
            title: "Servicios Gestibarymont",
            text: "Excel",
          },
        ],
        fade: true,
      },
      {
        text: '<i class="fas fa-eraser mr-2"></i> Limpiar filtros',
        className: "btn btn-barymont-black my-2 my-md-0",
        action: function () {
          resetearFiltros();
        },
        init: function (api, node) {
          $(node).removeClass("dt-button");
        },
      },
      {
        text: '<i class="fas fa-download mr-2"></i> Exportar',
        className: "btn btn-barymont-black my-2 my-md-0",
        action: function () {
          window.open("/administracion/exportaciones?exportType=cp", "_blank");
        },
        init: function (api, node) {
          if (AppGbSession.checkUserHasPermission("CpService:CpServiceExportAccessChecker")) {
            $(node).removeClass("dt-button");
          } else {
            $(node).hide();
          }
        },
      },
      checkAdminToolsRights == 1
        ? {
            text: '<i class="fa-solid fa-file-import mr-2"></i> Importar Liquidación Bárymont Promotores',
            className: "btn btn-barymont-red my-2 my-md-0",
            action: function () {
              createImportServiceModal();
            },
            init: function (api, node) {
              if (AppGbSession.checkUserHasPermission("CpService:CpServiceCreateAccessChecker")) {
                $(node).removeClass("dt-button");
              } else {
                $(node).hide();
              }
            },
          }
        : "",
    ],
    pageLength: 10,
    lengthMenu: [
      [10, 25, 50, 100],
      [10, 25, 50, 100],
    ],
    select: false,
    keys: true,
    searchHighlight: true,
    ajax: {
      url: "/herramientas/commercial-products/listaprocessing",
      type: "post",
      data: function (data) {
        const checkServiceType = document.getElementById("cp-filter-type").value;
        data.serviceType = checkServiceType != "" ? checkServiceType : "";

        data.dateLastUpdateTo = document.getElementById("cp-filter-last-update-to").value;
        data.dateLastUpdateFrom = document.getElementById("cp-filter-last-update-from").value;
      },
      error: function (e) {
        if (e.status === 401) {
          createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
          setTimeout(function () {
            location.reload();
          }, 5000);
        } else {
          createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
        }
      },
    },
    fnDrawCallback: function () {
      $('[data-toggle="tooltip"]').tooltip();
      $('[data-toggle="popover"]').popover();
    },
    stateSave: true,
    stateSaveCallback: function (settings, data) {
      let storageKey = FILTER_STATE_LOCAL_STORAGE_KEY + settings.sInstance;

      data.customFilter = getStateOfCustomFilters();

      localStorage.setItem(storageKey, JSON.stringify(data));
    },
    stateLoadCallback: function (settings) {
      let storageKey = FILTER_STATE_LOCAL_STORAGE_KEY + settings.sInstance;

      if (localStorage.getItem(storageKey) === null) {
        return false;
      }

      let localStorageData = JSON.parse(localStorage.getItem(storageKey));

      if (localStorageData.time < new Date().getTime() - FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME) {
        return false;
      }

      recreateStateOfCustomFilters(localStorageData);

      return localStorageData;
    },
  });
}

function resetearFiltros() {
  $("#cp-filter-type").val("");
  $("#cp-filter-last-update-to").val("");
  $("#cp-filter-last-update-from").val("");

  $("#cp-filter-type").selectpicker("refresh");

  $("#cp-service-table").DataTable().search("").draw();
  $("#cp-service-table").DataTable().ajax.reload();
}

function createImportServiceModal() {
  const randomModalId = `modal-${Math.random().toString(36).substring(2, 10)}`;
  const fragment = document.createDocumentFragment();

  const temporalDiv = document.createElement("div");
  temporalDiv.innerHTML = `
    <div class="modal fade" id="${randomModalId}" tabindex="-1" role="dialog" data-backdrop="static">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Importar Liquidación de Bárymont Promotores</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div id="modal-body-${randomModalId}" class="modal-body">
            <div class="form-group">
              <div class="form-group d-flex align-items-center">
                <label for="csv-file-input" class="control-label font-weight-bold mr-2">Seleccione el fichero CSV</label>
                <span
                  class="info-icon" tabindex="0" role="button" data-toggle="popover" data-trigger="hover" data-placement="right"
                  data-title="Formato del CSV"
                  data-content="El fichero CSV debe contener las siguientes columnas: Código, Abrev.Cía, Cód. Poliza, Liqui, Ente.">
                  <i class="fas fa-info-circle text-muted px-2"></i>
                </span>
              </div>
              <div class="custom-file">
                <input id="csv-file-input" type="file" class="custom-file-input" accept=".csv" required>
                <label class="custom-file-label" for="csv-file-input" data-browse="Selecciona el fichero">Elegir archivo</label>
              </div>

              <small class="form-text text-muted">Solamente se permite importar un fichero de liquidación de eBroker del ente <strong>Bárymont Promotores</strong> con el <a href="https://content.barymont.com/files/9809afa5-7713-44b9-a74b-c80ff278c1f9.csv" target="_BLANK">formato acordado</a>.</small>
            </div>
            <button type="button" class="btn btn-barymont-grey w-100" id="view-errors-btn" style="display: none;">Ver detalles de los errores</button>
            <button type="button" class="btn btn-barymont-grey w-100 my-2" id="download-errors-btn" style="display: none;">Descargar CSV con los errores</button>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-barymont-red" id="csv-upload-btn">Importar fichero</button>
            <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>`;

  fragment.appendChild(temporalDiv.firstElementChild);
  document.body.appendChild(fragment);

  const modalElement = $(`#${randomModalId}`).modal("show");

  $('[data-toggle="popover"]').popover();

  modalElement.on("hidden.bs.modal", function () {
    modalElement.remove();
  });

  const uploadButton = document.getElementById("csv-upload-btn");
  const downloadErrorsButton = document.getElementById("download-errors-btn");
  const viewErrorsButton = document.getElementById("view-errors-btn");

  document.getElementById("csv-file-input").addEventListener("change", function () {
    const fileName = document.getElementById("csv-file-input").files[0]?.name || "Elegir archivo";
    document.querySelector("#csv-file-input + .custom-file-label").textContent = fileName;
  });

  uploadButton.addEventListener("click", async function () {
    document.getElementById(`modal-message-${randomModalId}`)?.remove();

    const csvFileInput = document.getElementById("csv-file-input").files[0];

    if (!csvFileInput) {
      createToast("error", "Error", "Debe seleccionar un fichero CSV antes de continuar.", 3000);
      return;
    }

    showLoadingButton("csv-upload-btn", "btn-barymont-red");

    try {
      const responseBody = await importFile(csvFileInput);

      document.getElementById("csv-file-input").value = "";
      document.querySelector("#csv-file-input + .custom-file-label").textContent = "Elegir archivo";

      if (responseBody.totalErrors && responseBody.totalErrors > 0) {
        createToast("warning", "Advertencia", `${responseBody.message}`, 5000);
        downloadErrorsButton.style.display = "inline-block";
        viewErrorsButton.style.display = "inline-block";

        document.getElementById(`modal-body-${randomModalId}`).insertAdjacentHTML(
          "beforeend",
          `<div id="modal-message-${randomModalId}" class="alert alert-danger text-center" role="alert">
              Se han encontrado <b>${responseBody.totalErrors}</b> errores en la importación con el fichero seleccionado <b>${csvFileInput.name}</b>
            </div>`
        );

        $("#cp-service-table").DataTable().ajax.reload();

        downloadErrorsButton.onclick = () => downloadErrorCSV(responseBody.csvContent);
        viewErrorsButton.onclick = () => showErrorDetailsModal(responseBody.errors);
      } else {
        createToast("success", "Éxito", "Servicios importados correctamente.", 3000);

        document.getElementById(`modal-body-${randomModalId}`).insertAdjacentHTML(
          "beforeend",
          `<div id="modal-message-${randomModalId}" class="alert alert-success text-center" role="alert">
              Servicios importados correctamente.
            </div>`
        );

        $("#cp-service-table").DataTable().ajax.reload();
      }
    } catch (error) {
      createToast("error", "Error", `No se pudo procesar el fichero. ${error.message}`, 5000);
    } finally {
      updateButtonLabel("csv-upload-btn", "Importar Fichero", "btn-barymont-red");
      document.getElementById("csv-file-input").value = "";
    }
  });
}

// Función para descargar el CSV usando el contenido ya obtenido
function downloadErrorCSV(csvContent) {
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const downloadUrl = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = downloadUrl;
  a.download = "errores_importacion_" + new Date().toISOString().split("T")[0] + ".csv";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(downloadUrl);
}

// Función para mostrar los detalles de los errores en un modal
function showErrorDetailsModal(errors) {
  console.log(errors);
  const errorModalId = `modal-${Math.random().toString(36).substring(2, 10)}`;
  const fragment = document.createDocumentFragment();

  const errorDiv = document.createElement("div");
  errorDiv.innerHTML = `
    <div class="modal fade" id="${errorModalId}" tabindex="-1" role="dialog" data-backdrop="static">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Detalles de los Errores</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body scroll-barymont-red" style="max-height: 80vh; overflow-y: auto;">
            <div class="container">
              ${errors
                .map(
                  (error) => `
                <div class="card mb-3">
                  <div class="card-body py-1">
                    <div class="row">
                      <div class="col-6">
                        <strong>Código del recibo:</strong> ${error.ebroker_receipt_ordinal_code}
                      </div>
                      <div class="col-6">
                        <strong>Id de la Póliza:</strong> ${error.ebroker_policy_id}
                      </div>
                      <div class="${error.url ? "col-11" : "col-12"} mt-2">
                        <strong>Error:</strong> ${error.error}
                      </div>
                      ${
                        error.url
                          ? `<div class="col-1 mt-2">
                        <a href="${error.url}" data-toggle="tooltip" title="Ver más detalles" data-placement="top" class="btn btn-barymont-red" target="_blank">
                          <i class="fas fa-info-circle"></i>
                        </a>
                      </div>`
                          : ""
                      }
                    </div>
                  </div>
                </div>
              `
                )
                .join("")}
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>`;

  fragment.appendChild(errorDiv.firstElementChild);
  document.body.appendChild(fragment);

  const $errorModalElement = $(`#${errorModalId}`).modal("show");

  $errorModalElement.on("hidden.bs.modal", function () {
    $errorModalElement.remove();
  });
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname === "/herramientas/commercial-products") {
      $(".updatetriggerservicio").change(function () {
        $("#cp-service-table").DataTable().ajax.reload();
      });

      const scriptElement = document.querySelector("script[data-user-session-info]");
      const userSessionInfo = JSON.parse(scriptElement.textContent);

      const hasAccessChecker = userSessionInfo.accessCheckers.includes("CpService:CpServiceCreateAccessChecker");

      loadDatatable(hasAccessChecker ? 1 : 0);

      addCommonServiceModalsEventListeners();
    }
  });
}

export default {};
