"use strict";

import { showLoadingButton, updateButtonLabel } from "../shared/shared.js";
import { showModalUserBusinessIndex } from "../users/user_business_index.js";
import { readCurrentUserNotifications, searchCurrentUserStructure, updateNotificationState } from "../../api/current-user.js";
import RankingUIServices from "../rankings/rankings.js";
import GoalUIServices from "../goals/goals.js";
import { UIloadUserStructureModal } from "./myStructure.js";

const BASE_PREVIEW_IMAGE_URL = "https://content.barymont.com/files/760f6e8f-bb69-4a00-9fec-05e8ad79211d.png";
var MY_TEAM_DATA = null;

function loadDashboardRankings(UserId, RankingContainerId) {
  UIhelperShowLoadingEffectOnContainerById(RankingContainerId);

  RankingUIServices.fetchCurrentRankingListVisibles().then((rankingList) => {
    const rankingListOrdered = RankingUIServices.orderRankingsByCategory(rankingList);

    rankingList = RankingUIServices.filterOnlyMyRankings(rankingListOrdered);

    RankingUIServices.printRankingList(rankingList, RankingContainerId, UserId, "list");

    UIhelperRemoveLoadingEffectContainerById(RankingContainerId);

    $('[data-toggle="tooltip"]').tooltip();
  });
}

function loadDashboardFormacion(UserId, FormacionContainerId) {
  UIhelperShowLoadingEffectOnContainerById(FormacionContainerId);

  // This function in the future will be in a separate file on the Formacion section
  async function getCourseData(userId) {
    let formData = new FormData();

    formData.append("userId", userId);

    let requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };

    return await fetch("/herramientas/plataformaformacion/ajax/getCoursesFormacionByUserId", requestOptions).then((response) => response.json());
  }

  // This function in the future will be in a separate file on the Formacion section
  function paintDashboardCourseData(courseData, containerId, courseAmount) {
    let filteredCourses = [...courseData.data];

    $("#" + containerId).empty();

    $("#" + containerId).append(`
      <div class="col-12">
        <div class="alert alert-info d-none" id="notfoundalert" role="alert"></div>
      </div>
    `);

    if (courseData.data.length === 0) {
      $("#" + containerId).append(`
      <div class="col-12 mt-3">
        <div class="bg-barymont-grey">
            <div class="container-fluid">
                <div class="row p-3">
                    <div class="col-12 text-center">
                      <span class="h5 mb-1 card-title">
                        No tienes formación disponible
                      </span>
                    </div>
                </div>
            </div>
        </div>
      </div>`);
      return;
    }

    filteredCourses.slice(0, courseAmount).map((course) => {
      $("#" + containerId).append(`
          <div class="col-12 mt-3 p-0 px-3">
            <a href="/herramientas/plataformaformacion/info-curso/${course.id}">
              <div id="${course.id}" class="course-card mx-md-2 mx-0 my-2">
                <div class="container-fluid">
                  <div class="row py-md-3 py-0 px-md-3 px-2 align-items-center">
                    <div class="col-3 p-0">
                      <div class="text-center w-100 d-flex justify-content-center align-items-center">
                        <img class="couse-image" src="${course.image}">
                      </div>
                    </div>
                    <div class="col-9">
                      <span class="h6 mb-3 card-title text-dark">
                        ${course.name}
                      </span>
                      <div class="progress mb-2" style="height:10px;">
                        <div class="progress-bar bg-barymont" role="progressbar" style="width: ${course.percentageCompleted}%;" aria-valuenow="${course.percentageCompleted}" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <div class="container-fluid text-barymont-light-black px-0 rounded-lg">
                        <div class="row no-gutters">
                          <div class="col-4 text-center py-1" data-toggle="tooltip" data-placement="bottom" title="${course.description !== null ? course.description : "Sin descripción"}">
                            <i class="fas fa-info-circle text-barymont-light-black"></i>
                          </div>
                          <div class="col-4 text-center border-left border-right py-1" data-toggle="tooltip" data-placement="bottom" title="Contenidos completados">
                            <i class="far fa-check-square text-barymont-light-black mr-2"></i> <span class="text-barymont-light-black" style="font-size:16px;">${course.totalContentCompleted}</span>
                          </div>
                          <div class="col-4 text-center py-1" data-toggle="tooltip" data-placement="bottom" title="Total de contenidos">
                            <i class="fas fa-sitemap text-barymont-light-black mr-2"></i> <span class="text-barymont-light-black" style="font-size:16px;">${course.totalContent}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>`);
    });

    $('[data-toggle="tooltip"]').tooltip();
  }

  function paintBadgeCoursesAbleLastMonth(courseData) {
    let nowDate = new Date();
    let pastMonthDate = new Date(nowDate.getFullYear(), nowDate.getMonth() - 1, nowDate.getDate());
    let newCourseCounter = 0;
    courseData.forEach((course) => {
      let dateSplit = course.createdAt.split(" ")[0].split("-");
      let courseDate = new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0]);

      if (courseDate > pastMonthDate && course.percentageCompleted === 0) {
        newCourseCounter++;
      }
    });

    if (newCourseCounter > 0) {
      document.querySelector(".container-tools a[href*='plataformaformacion']").insertAdjacentHTML("beforeend", `<span class="badge bg-barymont text-white" style="position:absolute; top:5px; right:5px;">NUEVOS: ${newCourseCounter}</span>`);
    }
  }

  const CourseAmount = 6;

  getCourseData(UserId).then((courseData) => {
    paintDashboardCourseData(courseData, FormacionContainerId, CourseAmount);
    paintBadgeCoursesAbleLastMonth(courseData.data);
    UIhelperRemoveLoadingEffectContainerById(FormacionContainerId);
  });
}

function loadDashboardGoals(UserId, GoalsContainerId) {
  UIhelperShowLoadingEffectOnContainerById(GoalsContainerId);

  GoalUIServices.fetchFavoriteGoalsData(UserId).then((goals) => {
    GoalUIServices.printGoalsList(goals, GoalsContainerId);
    UIhelperRemoveLoadingEffectContainerById(GoalsContainerId);
  });
}

function UIhelperShowLoadingEffectOnContainerById(containerId) {
  const container = document.getElementById(containerId);
  const loaderElement = document.createElement("div");
  loaderElement.innerHTML = `
    <div id="load-${containerId}" class="col-12 mt-5">
        <div class="d-flex justify-content-center my-3">
            <div class="spinner-border text-barymont-red" role="status" style="width: 3.5rem; height: 3.5rem;>
                <span class="sr-only"></span>
            </div>
        </div>
    </div>
`;
  container.appendChild(loaderElement);
}

function UIloadCropperUserAvatarModal(modalId, imageId, previewClass, buttonId, userImageId, userInputFileId, userInputBase64Id) {
  var modal = $("#" + modalId);
  var image = document.getElementById(imageId);
  var cropper;

  $("#" + userInputFileId).change(function (event) {
    var files = event.target.files;

    var done = function (url) {
      image.src = url;
      modal.modal("show");
    };

    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = function () {
        done(reader.result);
      };
      reader.readAsDataURL(files[0]);
    }
  });

  modal
    .on("shown.bs.modal", function () {
      cropper = new Cropper(image, {
        aspectRatio: 1,
        viewMode: 1,
        autoCropArea: 1,
        preview: previewClass,
      });
    })
    .on("hidden.bs.modal", function () {
      cropper.destroy();
      cropper = null;
    });

  /* Base64 */

  $("#" + buttonId).click(function () {
    const canvas = cropper.getCroppedCanvas({
      width: 500,
      height: 500,
    });
    try {
      canvas.toBlob(function (blob) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          var base64data = reader.result;
          $("#" + userInputBase64Id).val(base64data);
          $("#" + userImageId).attr("src", base64data);
          modal.modal("hide");

          createToast("info", "Avatar actualizado", "Recuerda guardar los cambios para que el cambio sea efectivo.", 5000);
        };
      });
    } catch (error) {
      createToast("warning", "Ha ocurrido un error", "No se ha podido cambiar el avatar del usuario, intentelo de nuevo", 5000);
    }
  });
}

async function fetchUpdateUserAvatar(userId, userAvatarBase64Image) {
  let formData = new FormData();

  formData.append("userId", userId);
  formData.append("userAvatarBase64Image", userAvatarBase64Image);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/user-profile-management/ajax/saveUserAvatar", requestOptions).then((response) => response.json());
}

function UIhelperRemoveLoadingEffectContainerById(containerId) {
  const loadingElement = document.getElementById("load-" + containerId);
  if (loadingElement) {
    loadingElement.parentNode.removeChild(loadingElement);
  }
}

function UIloadUserAvatarEventListeners(userConfigModalId, deleteAvatarModalId, userInputBase64Id, userInputFileId, previewUserImageId, userImageDashboardId) {
  document.getElementById("user-config-delete-btn-modal").addEventListener("click", function () {
    $("#" + deleteAvatarModalId).modal("show");
  });

  document.getElementById("btn-confirm-remove-avatar").addEventListener("click", function () {
    document.getElementById(userInputBase64Id).value = "_REMOVE_AVATAR_";
    document.getElementById(userInputFileId).value = "";
    document.getElementById(previewUserImageId).src = BASE_PREVIEW_IMAGE_URL;

    createToast("info", "Avatar eliminado", "Recuerda guardar los cambios para que el cambio sea efectivo.", 5000);
    $("#" + deleteAvatarModalId).modal("hide");
  });

  document.getElementById("user-config-save-btn-modal").addEventListener("click", function () {
    const userAvatarBase64Image = document.getElementById(userInputBase64Id).value;
    const userId = document.getElementById("user-id").value;

    fetchUpdateUserAvatar(userId, userAvatarBase64Image).then((data) => {
      if (data.success) {
        if (data.data.imageBase64 === "_REMOVE_AVATAR_") {
          document.getElementById(userImageDashboardId).src = BASE_PREVIEW_IMAGE_URL;
        } else {
          document.getElementById(userImageDashboardId).src = data.data.imageBase64;
        }
        createToast("success", "Datos actualizados", "Los datos han sido actualizados correctamente.", 5000);
        $("#" + userConfigModalId).modal("hide");
      } else {
        createToast("warning", "Ha ocurrido un error", "No se han podido actualizar los datos del usuario, intentelo de nuevo", 5000);
      }
    });
  });
}

function UIloadUserAliasConfigEventListeners(userAliasConfigId) {
  document.getElementById(userAliasConfigId)?.addEventListener("click", async function () {
    showLoadingButton(userAliasConfigId, "btn-barymont-red", true);
    const userId = document.getElementById("user-id").value;
    const responseAliasData = await fetchUserAliasData(userId);

    if (responseAliasData.success === false) {
      createToast("error", "Error ❌", "No se han podido cargar los datos públicos en logros y rankings del usuario, intentelo de nuevo más tarde.", 5000);
      return;
    }

    let userAliasData = null;
    if (responseAliasData.aliasData !== undefined) {
      userAliasData = responseAliasData.aliasData.userPreferenceData;
    }

    const randomModalId = Math.random().toString(36).substring(7);

    let modal = document.createElement("div");
    modal.id = randomModalId;
    modal.classList.add("modal", "fade");
    modal.setAttribute("tabindex", "-1");
    modal.setAttribute("role", "dialog");
    modal.setAttribute("aria-labelledby", "user-alias-config-modal");
    modal.setAttribute("aria-hidden", "true");

    let imgHtml = `<img id="user-data-alternative-avatar-image-${randomModalId}" src="${userAliasData?.alternativeAvatar ?? BASE_PREVIEW_IMAGE_URL}" alt="Avatar Reconocimientos" class="rounded-circle img-fluid" style="width: 250px;">`;
    let imgBtn = `<div id="buttons-image">
                    <label class="btn btn-barymont-red px-3 font-weight-bold" for="user-data-alternative-avatar-file-${randomModalId}">Añadir avatar para reconocimientos</label>
                  </div>`;
    let removeBtn = "";

    if (userAliasData?.alternativeAvatar !== undefined) {
      imgBtn = `<div id="buttons-image">
                    <label class="btn btn-barymont-red px-3 font-weight-bold" for="user-data-alternative-avatar-file-${randomModalId}">Cambiar avatar para recocimientos</label>
                    </div>`;
      removeBtn = `<button type="button" class="btn btn-barymont-black" id="user-alias-delete-${randomModalId}"><i class="fas fa-trash mr-2"></i> Eliminar</button>`;
    }

    modal.insertAdjacentHTML(
      "beforeend",
      `<div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title" id="user-config-modal-title">Perfil personalizado en reconocimientos
                <i class="far fa-question-circle text-white" data-toggle="popover" title="" data-placement="right" data-trigger="hover" data-dismiss="popover" data-content="Personaliza tu perfil añadiendo un alias y un avatar alternativo. Esto te permitirá aparecer en los rankings y logros con un nombre y una imagen diferentes a los de tu perfil de usuario." data-original-title="Información"></i>
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body">
              <div class="container">
                  <div class="row">
                      <div class="col-12 my-2 justify-content-center text-center">
                        <p class="text-muted">El alias y el avatar que configures se mostrarán en las secciones de reconocimientos de la plataforma. (Rankings, Logros...)</p>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fa-solid fa-user-tag text-dark mr-2"></i> Alias</span>
                          </div>
                          <input id="alias-config-user-${randomModalId}" type="text" class="form-control" value="${userAliasData?.alias ?? ""}" />
                          </div>
                      </div>
                      <div class="col-12 justify-content-center text-center my-3">
                        ${imgHtml}
                      </div>
                      <div class="col-12 justify-content-center text-center">
                          <input id="alias-config-user-id-${randomModalId}" type="hidden" value="${userId}" />
                          <input id="user-data-alternative-avatar-file-${randomModalId}" type="file" accept="image/*" hidden />
                          <input id="user-data-alternative-avatar-file-base64-${randomModalId}" type="hidden" value="" />
                          ${imgBtn}
                      </div>
                  </div>
              </div>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-barymont-red" id="user-alias-avatar-save-${randomModalId}"><i class="fas fa-floppy-disk mr-2"></i> Guardar</button>
              ${removeBtn}
              <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>`
    );

    document.body.appendChild(modal);

    UIloadCropperUserAlternativeAvatarModal(`user-data-alternative-avatar-file-${randomModalId}`, `user-data-alternative-avatar-file-base64-${randomModalId}`, `user-data-alternative-avatar-image-${randomModalId}`);

    document.getElementById(`user-alias-avatar-save-${randomModalId}`).addEventListener("click", async function () {
      showLoadingButton(`user-alias-avatar-save-${randomModalId}`, "btn-barymont-red", true);
      const userId = document.getElementById("user-id").value;
      let userAlternativeAvatarBase64Image = document.getElementById(`user-data-alternative-avatar-file-base64-${randomModalId}`).value;
      const userAlternativeAvatar = document.getElementById(`user-data-alternative-avatar-image-${randomModalId}`).src;
      const userAlias = document.getElementById(`alias-config-user-${randomModalId}`).value;

      if ((userAlternativeAvatar === BASE_PREVIEW_IMAGE_URL && userAlternativeAvatarBase64Image === "") || userAlias === "") {
        createToast("warning", "Error ❌", "Debes completar todos los campos para poder guardar los cambios.", 5000);
        updateButtonLabel(`user-alias-avatar-save-${randomModalId}`, "<i class='fas fa-floppy-disk mr-2'></i> Guardar", "btn-barymont-red");
        return;
      }

      if (userAlternativeAvatarBase64Image === "" && userAlternativeAvatar !== BASE_PREVIEW_IMAGE_URL) {
        userAlternativeAvatarBase64Image = userAlternativeAvatar;
      }

      const responseAliasConfig = await fetchUpdateUserAliasConfig(userId, userAlias, userAlternativeAvatarBase64Image);

      if (responseAliasConfig.success === false) {
        createToast("error", "Error ❌", "No se han podido actualizar los datos alternativos en el usuario, intentelo de nuevo", 5000);
        updateButtonLabel(`user-alias-avatar-save-${randomModalId}`, "<i class='fas fa-floppy-disk mr-2'></i> Guardar", "btn-barymont-red");
        return;
      }

      createToast("success", "Datos actualizados", "Los datos han sido actualizados correctamente.", 5000);
      $("#" + randomModalId).modal("hide");
    });

    document.getElementById(`user-alias-delete-${randomModalId}`)?.addEventListener("click", function () {
      UIloadDeleteUserAlternativeAvatar(randomModalId);
    });

    $('[data-toggle="popover"]').popover();
    $("#" + randomModalId).modal("show");
    updateButtonLabel(userAliasConfigId, "<i class='fa-solid fa-medal pr-1'></i><i class='fa-solid fa-address-card'></i>", "btn-barymont-red");

    $("#" + randomModalId).on("hidden.bs.modal", function () {
      document.getElementById(randomModalId).remove();
    });
  });
}

function UIloadDeleteUserAlternativeAvatar(randomModalId) {
  const randomDeleteModalId = Math.random().toString(36).substring(7);

  let deleteModal = document.createElement("div");
  deleteModal.id = randomDeleteModalId;
  deleteModal.classList.add("modal", "fade");
  deleteModal.setAttribute("tabindex", "-1");
  deleteModal.setAttribute("data-backdrop", "static");

  deleteModal.insertAdjacentHTML(
    "beforeend",
    `<div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Eliminar perfil personalizado en reconocimientos</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>¿Estás seguro de que deseas eliminar la configuración establecida para personalizar tu perfil en los reconocimientos?</p>
                <p>Al eliminar esta configuración, tu perfil en los reconocimientos volverá a mostrar tu nombre y avatar de usuario.</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-barymont-red" id="user-alias-delete-confirm-${randomDeleteModalId}">Eliminar</button>
                <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cancelar</button>
            </div>
          </div>
        </div>`
  );

  document.body.appendChild(deleteModal);

  document.getElementById(`user-alias-delete-confirm-${randomDeleteModalId}`).addEventListener("click", async function () {
    const userId = document.getElementById("user-id").value;
    const responseDeleteData = await fetchDeleteUserAlias(userId);

    if (responseDeleteData.success === false) {
      createToast("error", "Error ❌", "No se ha podido eliminar la información de tu alias, intentelo de nuevo", 5000);
      $(`#${randomDeleteModalId}`).modal("hide");
      return;
    }

    createToast("success", "Eliminada correctamente", "Se ha eliminado correctamente la información de tu alias.", 5000);
    $(`#${randomDeleteModalId}`).modal("hide");
    $(`#${randomModalId}`).modal("hide");
  });

  $(`#${randomDeleteModalId}`).modal("show");
}

function UIloadCropperUserAlternativeAvatarModal(userInputFileId, userInputBase64Id, previewUserImageId) {
  const randomModalId = Math.random().toString(36).substring(7);

  let cropperModal = document.createElement("div");
  cropperModal.id = randomModalId;
  cropperModal.classList.add("modal", "fade");
  cropperModal.setAttribute("tabindex", "-1");
  cropperModal.setAttribute("data-backdrop", "static");

  cropperModal.insertAdjacentHTML(
    "beforeend",
    `
      <div class="modal-dialog modal-dialog-centered modal-xl" role="dialog">
          <div class="modal-content">
              <form id="upload-user-alternative-avatar" method="POST" action="javascript:void(0);" onSubmit="" name="update-alternative-avatar" class="needs-validation" novalidate>
                  <div class="modal-header">
                      <h1 class="modal-title">Añadir avatar para reconocimientos</h1>
                  </div>
                  <div class="modal-body">

                      <div class="img-container">
                          <div class="row">

                              <div class="col-md-8 px-0">
                                  <img id="crop-user-alternative-avatar-${randomModalId}" style="max-height: 500px;" />
                              </div>
                              <div class="col-md-4 px-0 d-flex">
                                  <div class="preview-user-alternative-avatar overflow-hidden border-barymont-black mx-auto align-center" style="width: 100%; height: 100%; min-width: 170px; min-height: 170px;"></div>
                              </div>

                          </div>
                      </div>

                  </div>
                  <div class="modal-footer">
                      <button id="btn-update-user-alternative-avatar-${randomModalId}" type="submit" class="btn btn-barymont-red">Actualizar avatar alternativo</button>
                      <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
                  </div>
              </form>
          </div>
      </div>`
  );

  document.body.appendChild(cropperModal);

  var image = document.getElementById(`crop-user-alternative-avatar-${randomModalId}`);
  var cropper;

  $("#" + userInputFileId).change(function (event) {
    var files = event.target.files;

    var done = function (url) {
      image.src = url;
      $(`#${randomModalId}`).modal("show");
    };

    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = function () {
        done(reader.result);
      };
      reader.readAsDataURL(files[0]);
    }
  });

  $(`#${randomModalId}`)
    .on("shown.bs.modal", function () {
      cropper = new Cropper(image, {
        aspectRatio: 1,
        viewMode: 1,
        autoCropArea: 1,
        preview: ".preview-user-alternative-avatar",
      });
    })
    .on("hidden.bs.modal", function () {
      cropper.destroy();
      cropper = null;
    });

  /* Base64 */

  $(`#btn-update-user-alternative-avatar-${randomModalId}`).click(function () {
    const canvas = cropper.getCroppedCanvas({
      width: 500,
      height: 500,
    });
    try {
      canvas.toBlob(function (blob) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          var base64data = reader.result;
          $("#" + userInputBase64Id).val(base64data);
          $("#" + previewUserImageId).attr("src", base64data);
          $(`#${randomModalId}`).modal("hide");

          createToast("info", "Avatar alternativo actualizado", "Recuerda guardar los cambios para que el cambio sea efectivo.", 5000);
        };
      });
    } catch (error) {
      createToast("warning", "Ha ocurrido un error", "No se ha podido cambiar el avatar alternativo, intentelo de nuevo", 5000);
    }
  });
}

async function fetchUserAliasData(userId) {
  let formData = new FormData();

  formData.append("userId", userId);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/user-profile-management/ajax/getUserPreferenceAliasData", requestOptions).then((response) => response.json());
}

async function fetchUpdateUserAliasConfig(userId, userAlias, userAlternativeAvatarBase64Image) {
  let formData = new FormData();

  formData.append("userId", userId);
  formData.append("userAlias", userAlias);
  formData.append("userAlternativeAvatar", userAlternativeAvatarBase64Image);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/user-profile-management/ajax/saveUserAliasData", requestOptions).then((response) => response.json());
}

async function fetchDeleteUserAlias(userId) {
  let formData = new FormData();

  formData.append("userId", userId);
  formData.append("preferenceType", "RECONIGTIONS_CUSTOM_PROFILE");

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/user-profile-management/ajax/deleteUserPreference", requestOptions).then((response) => response.json());
}

// Its used on /dashboard tools box
// eslint-disable-next-line no-unused-vars
async function loadUserBusinessIndex() {
  const modal = document.createElement("div");
  modal.id = "modal-user-business-index-data";
  modal.className = "modal fade";

  const modalDialog = document.createElement("div");
  modalDialog.className = "modal-dialog modal-dialog-centered modal-xl";
  modalDialog.setAttribute("role", "dialog");

  const modalContent = document.createElement("div");
  modalContent.className = "modal-content";

  const modalHeader = document.createElement("div");
  modalHeader.className = "modal-header";

  const modalTitle = document.createElement("h1");
  modalTitle.className = "modal-title";
  modalTitle.innerHTML = 'Índices de negocio <span id="user-business-index-data-date"></span>';

  const modalBody = document.createElement("div");
  modalBody.className = "modal-body";

  const input = document.createElement("input");
  input.id = "business-index-category";
  input.type = "hidden";
  input.value = "";

  const dataDiv = document.createElement("div");
  dataDiv.id = "data-user-business-index-data";
  dataDiv.className = "row d-flex justify-content-center";

  const modalFooter = document.createElement("div");
  modalFooter.className = "modal-footer";

  const closeButton = document.createElement("button");
  closeButton.type = "button";
  closeButton.className = "btn btn-barymont-black";
  closeButton.setAttribute("data-dismiss", "modal");
  closeButton.innerText = "Cerrar";

  modalHeader.appendChild(modalTitle);
  modalBody.appendChild(input);
  modalBody.appendChild(dataDiv);
  modalFooter.appendChild(closeButton);

  modalContent.appendChild(modalHeader);
  modalContent.appendChild(modalBody);
  modalContent.appendChild(modalFooter);

  modalDialog.appendChild(modalContent);
  modal.appendChild(modalDialog);

  document.body.appendChild(modal);

  await showModalUserBusinessIndex();

  $("#" + modal.id).on("hidden.bs.modal", function () {
    modal.remove();
  });
}

/** START NOTIFICATIONS UI */
async function UIloadUserNotifications() {
  try {
    const notifications = await readCurrentUserNotifications();

    if (notifications.length === 0) {
      return;
    }

    notifications.forEach(async (notification) => {
      printModalNotifications(notification);
      await updateNotificationState(notification.notificationId, true);
    });
  } catch (error) {
    // TODO: Log error
    return;
  }
}

function printModalNotifications(notification) {
  const randomModalId = Math.random().toString(36).substring(7);

  let modal = document.createElement("div");
  modal.id = randomModalId;
  modal.classList.add("modal", "fade");
  modal.setAttribute("tabindex", "-1");
  modal.setAttribute("role", "dialog");
  modal.setAttribute("aria-labelledby", "user-notifications-modal");
  modal.setAttribute("aria-hidden", "true");

  let modalDialog = document.createElement("div");
  modalDialog.classList.add("modal-dialog", "modal-dialog-centered", "modal-xl");
  modalDialog.setAttribute("role", "document");

  let modalContent = document.createElement("div");
  modalContent.classList.add("modal-content");

  let modalHeader = document.createElement("div");
  modalHeader.classList.add("modal-header");
  modalHeader.innerHTML = `<h1 class="modal-title">${notification.title}</h1>`;
  modalHeader.insertAdjacentHTML(
    "beforeend",
    `<button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
     </button>`
  );

  let modalBody = document.createElement("div");
  modalBody.classList.add("modal-body");
  modalBody.innerHTML = notification.content;

  let modalFooter = document.createElement("div");
  modalFooter.classList.add("modal-footer");

  let closeButton = document.createElement("button");
  closeButton.type = "button";
  closeButton.classList.add("btn", "btn-barymont-black");
  closeButton.setAttribute("data-dismiss", "modal");
  closeButton.innerText = "Cerrar";

  modalFooter.appendChild(closeButton);
  modalContent.appendChild(modalHeader);
  modalContent.appendChild(modalBody);
  modalContent.appendChild(modalFooter);
  modalDialog.appendChild(modalContent);
  modal.appendChild(modalDialog);

  document.body.appendChild(modal);

  $("#" + modal.id).modal("show");
}
/** END NOTIFICATIONS UI */

async function loadDashboardPromoterEducation(UserId, ContainerId) {
  UIhelperShowLoadingEffectOnContainerById(ContainerId);

  // This function in the future will be in a separate file on the Formacion section
  async function getPromoterCoursesData(userId) {
    let formData = new FormData();

    formData.append("userId", userId);

    let requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };

    return await fetch("/herramientas/plataformaformacion/ajax/getPromoterCoursesByUserId", requestOptions).then((response) => response.json());
  }

  async function getPromoterContentsData(userId) {
    let formData = new FormData();

    formData.append("userId", userId);

    let requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };

    return await fetch("/herramientas/plataformaformacion/ajax/getPromoterContentsByUserId", requestOptions).then((response) => response.json());
  }

  // This function in the future will be in a separate file on the Formacion section
  function paintDashboardPromoterCourseData(courseData, contentData, containerId, courseAmount) {
    let filteredCourses = [...courseData.data];
    let filteredContents = [...contentData.data];

    $("#" + containerId).empty();

    $("#" + containerId).append(`
        <div class="col-12">
          <div class="alert alert-info d-none" id="notfoundalert" role="alert"></div>
        </div>
      `);

    if (courseData.data.length === 0 && contentData.data.length === 0) {
      $("#" + containerId).append(`
        <div class="col-12 mt-3">
          <div class="bg-barymont-grey">
              <div class="container-fluid">
                  <div class="row p-3">
                      <div class="col-12 text-center">
                        <span class="h5 mb-1 card-title">
                          Estás al día de todo el contenido disponible en Mundo Bárymont
                        </span>
                      </div>
                  </div>
              </div>
          </div>
        </div>`);
      return;
    }

    filteredCourses.slice(0, courseAmount).map((course) => {
      $("#" + containerId).append(`
            <div class="col-12 mt-3 p-0 px-3">
              <a href="/herramientas/plataformaformacion/info-curso/${course.id}">
                <div id="${course.id}" class="course-card mx-md-2 mx-0 my-2">
                  <div class="container-fluid">
                    <div class="row py-md-3 py-0 px-md-3 px-2 align-items-center">
                      <div class="col-3 p-0">
                        <div class="text-center w-100 d-flex justify-content-center align-items-center">
                          <img class="couse-image" src="${course.image}">
                        </div>
                      </div>
                      <div class="col-9">
                        <span class="h6 mb-3 card-title text-dark">
                          ${course.name}
                        </span>
                        <div class="progress mb-2" style="height:10px;">
                          <div class="progress-bar bg-barymont" role="progressbar" style="width: ${course.percentageCompleted}%;" aria-valuenow="${course.percentageCompleted}" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div class="container-fluid text-barymont-light-black px-0 rounded-lg">
                          <div class="row no-gutters">
                            <div class="col-4 text-center py-1" data-toggle="tooltip" data-placement="bottom" title="${course.description !== null ? course.description : "Sin descripción"}">
                              <i class="fas fa-info-circle text-barymont-light-black"></i>
                            </div>
                            <div class="col-4 text-center border-left border-right py-1" data-toggle="tooltip" data-placement="bottom" title="Contenidos completados">
                              <i class="far fa-check-square text-barymont-light-black mr-2"></i> <span class="text-barymont-light-black" style="font-size:16px;">${course.totalContentCompleted}</span>
                            </div>
                            <div class="col-4 text-center py-1" data-toggle="tooltip" data-placement="bottom" title="Total de contenidos">
                              <i class="fas fa-sitemap text-barymont-light-black mr-2"></i> <span class="text-barymont-light-black" style="font-size:16px;">${course.totalContent}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>`);
    });

    filteredContents.slice(0, courseAmount - filteredCourses.length).map((content) => {
      $("#" + containerId).append(`
            <div class="col-12 mt-3 p-0 px-3">
              <a href="/herramientas/plataformaformacion/recurso/${content.id}">
                <div id="${content.id}" class="course-card mx-md-2 mx-0 my-2">
                  <div class="container-fluid">
                    <div class="row py-md-3 py-0 px-md-3 px-2 align-items-center">
                      <div class="col-3 p-0">
                        <div class="text-center w-100 d-flex justify-content-center align-items-center">
                          <img class="couse-image" src="${content.image}">
                        </div>
                      </div>
                      <div class="col-9">
                        <div class="row">
                          <div class="col-10">
                            <span class="h6 mb-3 card-title text-dark">
                              ${content.name}
                            </span>
                          </div>
                          <div class="col-2 text-center mb-3" data-toggle="tooltip" data-placement="top" title="${content.description !== null ? content.description : "Sin descripción"}">
                            <i class="fas fa-info-circle text-barymont-light-black"></i>
                          </div>
                        </div>
                        <div class="progress mb-2" style="height:10px;">
                          <div class="progress-bar bg-barymont" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>`);
    });

    $("#" + containerId).append(`
      <div class="p-2" style="position: sticky; bottom: 0; display: flex; justify-content: center; align-items: center;">
        <a href="/herramientas/plataformaformacion?tag=BARYMONT_WORLD" class="btn btn-lg btn-barymont-grey w-100 mx-2" style="flex-grow: 1; text-align: center;"><i class="fa-solid fa-book-open-reader pr-2 text-dark d-none d-sm-inline"></i>Ver más formación</a>
      </div>`);

    $('[data-toggle="tooltip"]').tooltip();
  }

  const promoterCourseAmount = 6;

  const coursesData = await getPromoterCoursesData(UserId);
  const contentsData = await getPromoterContentsData(UserId);

  paintDashboardPromoterCourseData(coursesData, contentsData, ContainerId, promoterCourseAmount);
  UIhelperRemoveLoadingEffectContainerById(ContainerId);
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname === "/dashboard" && AppGbSession.checkIfSubscriptionIsActive()) {
      const UserId = document.getElementById("user-id").value;

      const RankingContainerId = "rankings";
      loadDashboardRankings(UserId, RankingContainerId);

      if (AppGbSession.checkUserHasPermission("EducationPlatform:EducationPlatformReadAccessChecker")) {
        const FormacionContainerId = "formacion";
        loadDashboardFormacion(UserId, FormacionContainerId);

        const PromoterEducationContainerId = "barymont-world";
        if (document.getElementById(PromoterEducationContainerId)) {
          loadDashboardPromoterEducation(UserId, PromoterEducationContainerId);
        }
      }

      const MyTeamButtonId = "my-team-button";
      document.getElementById(MyTeamButtonId)?.addEventListener("click", async function () {
        showLoadingButton(MyTeamButtonId, "btn-barymont-red", true);

        if (MY_TEAM_DATA === null) {
          MY_TEAM_DATA = await searchCurrentUserStructure("Full");
        }

        UIloadUserStructureModal(MY_TEAM_DATA, true, "Mi equipo");

        updateButtonLabel(MyTeamButtonId, "<i class='fa-solid fa-people-group mr-2'></i>Mi equipo", "btn-barymont-red");
      });

      const GoalsContainerId = "goals";
      loadDashboardGoals(UserId, GoalsContainerId);

      const userConfigModalId = "user-config-modal";
      const avatarUpdateModalId = "modal-user-data-avatar";
      const deleteAvatarModalId = "modal-confirm-remove-avatar";

      const userImageDashboardId = "user-avatar-image";
      const cropPreviewImageId = "crop-user-data-avatar";
      const cropperUpdateButtonId = "btn-update-user-data-avatar";
      const previewUserImageId = "user-data-avatar-image";
      const userInputFileId = "user-data-avatar-file";
      const userInputBase64Id = "user-data-avatar-file-base64";

      const previewClass = ".preview-user-data-avatar";

      UIloadCropperUserAvatarModal(avatarUpdateModalId, cropPreviewImageId, previewClass, cropperUpdateButtonId, previewUserImageId, userInputFileId, userInputBase64Id);

      UIloadUserAvatarEventListeners(userConfigModalId, deleteAvatarModalId, userInputBase64Id, userInputFileId, previewUserImageId, userImageDashboardId);

      const userAliasConfigId = "user-alias-config-button";
      UIloadUserAliasConfigEventListeners(userAliasConfigId);

      document.querySelectorAll("[data-toggle='modal']").forEach((element) => {
        element.addEventListener("click", async function () {
          const functionGenerator = element.getAttribute("data-function");
          if (functionGenerator) {
            const functionToExecute = eval(functionGenerator);
            if (typeof functionToExecute === "function") {
              await functionToExecute();
            } else {
              console.error("Content of data-function is invalid", functionGenerator);
            }
          }
        });
      });

      UIloadUserNotifications();
    }
  });
}
