"use strict";

var USER_FILES = [];

const ELEMENT_IDS = {
  btnLoadUserFiles: "btn-load-user-files",
  userFilesQuantity: "badge-user-files-quantity",
  userFilesModalBody: "user-files-modal-body",
};

async function fetchUserFiles(userId) {
  let requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  const response = await fetch(`/api/users/${userId}/files`, requestOptions);

  return { status: response.status, data: await response.json() };
}

async function fetchUploadUserFile(userId, form) {
  const formData = new FormData();

  const fileInput = form.querySelector('input[name="file-user-input"]');
  formData.append("files", fileInput.files[0]);

  const friendlyNameInput = form.querySelector('input[name="file-user-input-friendlyName"]');
  formData.append("friendlyName", friendlyNameInput.value);

  try {
    const response = await fetch(`/api/users/${userId}/files`, {
      method: "POST",
      body: formData,
    });

    return { status: response.status, data: await response.json() };
  } catch (error) {
    return { status: 500, data: { message: "Error en la solicitud" } };
  }
}

async function fetchDeleteUserFile(userId, fileId) {
  try {
    const response = await fetch(`/api/users/${userId}/files/${fileId}`, {
      method: "DELETE",
    });

    return { status: response.status, data: await response.json() };
  } catch (error) {
    return { status: 500, data: { message: "Error en la solicitud al eliminar el fichero" } };
  }
}

async function loadQuantityOfUserFiles(userId, quantityId, btnId) {
  document.getElementById(btnId).disabled = true;
  const element = document.getElementById(quantityId);
  if (element !== null) {
    element.innerHTML = "<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>";
  }
  const response = await fetchUserFiles(userId);

  if (response.status === 200) {
    if (element !== null) {
      element.innerText = response.data.length;
    }
    USER_FILES = response.data;

    document.getElementById(btnId).disabled = false;
  } else {
    if (element !== null) {
      element.classList.add("text-dark", "badge-warning");
      element.classList.remove("badge-secondary");
      element.innerHTML = "<i class='fas fa-exclamation-triangle text-dark'></i>";

      createToast("warning", "Ha ocurrido un error al cargar los ficheros del usuario, ponte en contacto con soporte", response.error.message, 5000);
    }
  }
}

function printFileListOnModal(userId, modalBodyId) {
  const randomModalId = Math.random().toString(36).substring(7);

  let modal = document.createElement("div");
  modal.id = randomModalId;
  modal.classList.add("modal", "fade");
  modal.setAttribute("tabindex", "-1");
  modal.setAttribute("role", "dialog");
  modal.setAttribute("aria-labelledby", "user-notifications-modal");
  modal.setAttribute("aria-hidden", "true");

  let modalDialog = document.createElement("div");
  modalDialog.classList.add("modal-dialog", "modal-dialog-centered", "modal-xl");
  modalDialog.setAttribute("role", "document");

  let modalContent = document.createElement("div");
  modalContent.classList.add("modal-content");

  let modalHeader = document.createElement("div");
  modalHeader.classList.add("modal-header");
  modalHeader.innerHTML = `<h1 class="modal-title">Ficheros del usuario</h1>`;
  modalHeader.insertAdjacentHTML(
    "beforeend",
    `<button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
     </button>`
  );

  let modalBody = document.createElement("div");
  modalBody.classList.add("modal-body");
  modalBody.id = modalBodyId;
  modalBody.innerHTML = `<div class="d-flex justify-content-center align-items-center" style="height: 200px;">
    <div class="spinner-border text-barymont-red" role="status">
      <span class="sr-only">Cargando...</span>
    </div>
  </div>`;

  let modalFooter = document.createElement("div");
  modalFooter.classList.add("modal-footer");

  let closeButton = document.createElement("button");
  closeButton.type = "button";
  closeButton.classList.add("btn", "btn-barymont-black");
  closeButton.setAttribute("data-dismiss", "modal");
  closeButton.innerText = "Cerrar";

  let uploadButton = document.createElement("button");
  uploadButton.type = "button";
  uploadButton.classList.add("btn", "btn-barymont-red", "px-5");
  uploadButton.innerHTML = `<i class="fas fa-upload mr-2 text-white"></i> Subir Fichero`;

  uploadButton.addEventListener("click", function () {
    openFileUploadModal(userId, modalBodyId);
  });

  modalFooter.appendChild(uploadButton);
  modalFooter.appendChild(closeButton);

  modalContent.appendChild(modalHeader);
  modalContent.appendChild(modalBody);
  modalContent.appendChild(modalFooter);
  modalDialog.appendChild(modalContent);
  modal.appendChild(modalDialog);

  document.body.appendChild(modal);
  $("#" + randomModalId).modal("show");

  $("#" + randomModalId).on("hidden.bs.modal", function () {
    $(this).remove();
  });

  loadModalFilesContent(userId, modalBodyId);
}

async function loadModalFilesContent(userId, modalBodyId) {
  const response = await fetchUserFiles(userId);

  if (response.status === 200) {
    USER_FILES = response.data;

    const modalBody = document.getElementById(modalBodyId);

    if (modalBody === null) {
      return;
    }

    modalBody.innerHTML = generateHtmlToPrintFiles(USER_FILES);

    addEventListenersToFiles(userId, modalBodyId);

    $('[data-toggle="tooltip"]').tooltip();
  } else {
    createToast("error", "Error al cargar ficheros", response.data.message, 5000);
  }
}

function openFileUploadModal(userId, modalBodyId) {
  const randomId = Math.random().toString(36).substring(7);
  const modalId = "uploadFileModal_" + randomId;

  let modal = document.createElement("div");
  modal.id = modalId;
  modal.classList.add("modal", "fade");
  modal.setAttribute("tabindex", "-1");
  modal.setAttribute("role", "dialog");
  modal.setAttribute("aria-labelledby", "upload-file-modal");
  modal.setAttribute("aria-hidden", "true");

  let modalDialog = document.createElement("div");
  modalDialog.classList.add("modal-dialog", "modal-dialog-centered");
  modalDialog.setAttribute("role", "document");

  let modalContent = document.createElement("div");
  modalContent.classList.add("modal-content");

  let modalHeader = document.createElement("div");
  modalHeader.classList.add("modal-header");

  let headerTitle = document.createElement("h1");
  headerTitle.classList.add("modal-title");
  headerTitle.innerText = "Subir fichero";
  modalHeader.appendChild(headerTitle);

  let modalBody = document.createElement("div");
  modalBody.classList.add("modal-body");

  let form = document.createElement("form");
  form.id = "form-upload-file";
  form.classList.add("needs-validation");
  form.setAttribute("novalidate", "");

  let fileInputDiv = document.createElement("div");
  fileInputDiv.classList.add("custom-file");

  let fileInput = document.createElement("input");
  fileInput.type = "file";
  fileInput.classList.add("custom-file-input");
  fileInput.name = "file-user-input";
  fileInput.id = "file-user-input";
  fileInput.required = true;

  let fileLabel = document.createElement("label");
  fileLabel.classList.add("custom-file-label");
  fileLabel.setAttribute("for", "file-user-input");
  fileLabel.innerText = "Selecciona un fichero";
  fileLabel.setAttribute("data-browse", "Selecciona un fichero");

  fileInputDiv.appendChild(fileInput);
  fileInputDiv.appendChild(fileLabel);

  let formGroup = document.createElement("div");
  formGroup.classList.add("form-group");

  let label = document.createElement("label");
  label.setAttribute("for", "file-user-input-friendlyName");
  label.innerHTML = 'Nombre del fichero: <i class="fas fa-asterisk text-barymont-red"></i>';

  let textInput = document.createElement("input");
  textInput.type = "text";
  textInput.classList.add("form-control", "w-100");
  textInput.name = "file-user-input-friendlyName";
  textInput.placeholder = "Introduce un nombre";
  textInput.id = "file-user-input-friendlyName";
  textInput.minLength = 5;
  textInput.maxLength = 250;
  textInput.required = true;
  textInput.autocomplete = "off";
  textInput.setAttribute("list", "file-user-input-friendlyName-datalist");

  let textInputDataList = document.createElement("datalist");
  textInputDataList.id = "file-user-input-friendlyName-datalist";

  let textInputDataListOptions = ["CONTRATO MERCANTIL DE PROMOTOR PERSONA FÍSICA 241010", "CONTRATO MERCANTIL DE PROMOTOR PERSONA JURÍDICA 241010", "NIF", "CIF", "CONTRATO MERCANTIL DE CE PERSONA FÍSICA", "CONTRATO MERCANTIL DE CE PERSONA JURÍDICA", "SOLICITUD DE CONTRATO CE", "BAJA VOLUNTARIA"];

  textInputDataListOptions.forEach((option) => {
    let textInputDataOption = document.createElement("option");
    textInputDataOption.value = option;
    textInputDataOption.innerHTML = option;
    textInputDataList.appendChild(textInputDataOption);
  });

  let invalidFeedback = document.createElement("div");
  invalidFeedback.classList.add("invalid-feedback");
  invalidFeedback.innerText = "El nombre del fichero debe tener entre 5 y 250 caracteres.";
  formGroup.appendChild(label);
  formGroup.appendChild(textInput);
  formGroup.appendChild(textInputDataList);
  formGroup.appendChild(invalidFeedback);

  let submitButton = document.createElement("button");
  submitButton.type = "submit";
  submitButton.classList.add("btn", "btn-lg", "btn-barymont-red", "btn-block");
  submitButton.innerHTML = '<i class="fas fa-upload pr-2"></i> Subir fichero';
  submitButton.id = `submit-user-upload-file-${randomId}`;

  form.appendChild(fileInputDiv);
  form.appendChild(formGroup);
  form.appendChild(submitButton);

  modalBody.appendChild(form);

  let modalFooter = document.createElement("div");
  modalFooter.classList.add("modal-footer");

  let closeButton = document.createElement("button");
  closeButton.type = "button";
  closeButton.classList.add("btn", "btn-barymont-black");
  closeButton.setAttribute("data-dismiss", "modal");
  closeButton.innerText = "Cerrar";
  modalFooter.appendChild(closeButton);

  modalContent.appendChild(modalHeader);
  modalContent.appendChild(modalBody);
  modalContent.appendChild(modalFooter);
  modalDialog.appendChild(modalContent);
  modal.appendChild(modalDialog);

  document.body.appendChild(modal);
  $("#" + modalId).modal("show");

  fileInput.addEventListener("change", function () {
    let fileName = this.files[0]?.name || "Selecciona un fichero";
    fileLabel.innerHTML = "<i class='fas fa-file mr-2'></i> " + fileName;
  });

  form.addEventListener("submit", async (event) => {
    event.preventDefault();
    submitButton.disabled = true;
    submitButton.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Subiendo fichero...';

    if (!form.checkValidity()) {
      form.classList.add("was-validated");

      if (!fileInput.validity.valid) {
        createToast("error", "Error en el archivo", "Selecciona un archivo para subir.", 5000);
      } else if (!textInput.validity.valid) {
        if (textInput.validity.valueMissing) {
          createToast("error", "Error en el nombre", "El nombre del fichero es obligatorio.", 5000);
        } else if (textInput.validity.tooShort || textInput.validity.tooLong) {
          createToast("error", "Error en el nombre", "El nombre debe tener entre 5 y 250 caracteres.", 5000);
        }
      }

      submitButton.disabled = false;
      submitButton.innerHTML = '<i class="fas fa-upload pr-2"></i> Subir fichero';
      return;
    }

    const response = await fetchUploadUserFile(userId, form);

    if (response.status === 200) {
      createToast("success", "Archivo subido con éxito", "El archivo se ha subido correctamente.", 5000);
      $("#" + modalId).modal("hide");
      document.dispatchEvent(new CustomEvent("userFileUploaded"));
      await loadModalFilesContent(userId, modalBodyId);
    } else {
      const errorMessage = response.data.message || "Error desconocido al subir el archivo";
      createToast("error", "Error en la subida del archivo", errorMessage, 5000);
    }

    const submitButtonelement = document.getElementById(`submit-user-upload-file-${randomId}`);

    if (submitButtonelement) {
      submitButtonelement.disabled = false;
      submitButtonelement.innerHTML = '<i class="fas fa-upload pr-2"></i> Subir fichero';
    }
  });

  $("#" + modalId).on("hidden.bs.modal", function () {
    $(this).remove();
  });
}

function generateHtmlToPrintFiles(files) {
  if (files.length === 0) {
    return `<div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <div class="alert alert-secondary text-center" role="alert">
                    <span>El usuario no tiene ficheros asociados.</span>
                  </div>
                </div>
              </div>
            </div>`;
  }

  let filesHtml = '<div class="container-fluid"><div class="row">';

  files.forEach((file) => {
    filesHtml += `
      <div id="user-file-${file.id}" class="col-12">
        <div class="input-group mb-2">
          <input type="text" class="form-control font-weight-bolder" value="${file.friendlyName}" readonly data-toggle="tooltip" data-placement="right" title="Nombre del documento">
          <div class="input-group-append">
            <span class="input-group-text text-center" data-toggle="tooltip" data-placement="left" data-html="true" title="Fecha de subida ${file.createdAt}">
              <i class="far fa-calendar-alt mr-2"></i> ${file.createdAt}
            </span>
            <span class="input-group-text text-center" data-toggle="tooltip" data-placement="top" data-html="true" title="Subido por ${file.uploaderName}">
              <i class="fas fa-user px-2"></i>
            </span>
            <a href="/api/users/${file.userId}/files/${file.userFileId}/render" class="btn btn-barymont-red mx-2" data-toggle="tooltip" data-placement="top" title="Descargar documento">
              <i class="fas fa-download mr-2"></i> Descargar
            </a>
            <button class="btn btn-barymont-black btn-delete-file" data-file-id="${file.userFileId}" data-file-name="${file.friendlyName}" data-toggle="tooltip" data-placement="top" title="Eliminar documento">
              <i class="far fa-sm fa-trash-alt"></i>
            </button>
          </div>
        </div>
      </div>
    `;
  });

  filesHtml += "</div></div>";

  return filesHtml;
}

function addEventListenersToFiles(userId, modalBodyId) {
  document.querySelectorAll(".btn-delete-file").forEach((button) => {
    button.addEventListener("click", () => {
      const fileId = button.getAttribute("data-file-id");
      openModalDeleteFile(userId, fileId, modalBodyId, button.getAttribute("data-file-name"));
    });
  });
}

function openModalDeleteFile(userId, fileId, modalBodyId, fileName) {
  const modalId = "deleteFileModal_" + Math.random().toString(36).substring(7);

  let modal = document.createElement("div");
  modal.id = modalId;
  modal.classList.add("modal", "fade");
  modal.setAttribute("tabindex", "-1");
  modal.setAttribute("role", "dialog");
  modal.setAttribute("aria-labelledby", "delete-file-modal");
  modal.setAttribute("aria-hidden", "true");

  let modalDialog = document.createElement("div");
  modalDialog.classList.add("modal-dialog", "modal-dialog-centered");
  modalDialog.setAttribute("role", "document");

  let modalContent = document.createElement("div");
  modalContent.classList.add("modal-content");

  let modalHeader = document.createElement("div");
  modalHeader.classList.add("modal-header");
  modalHeader.innerHTML = `<h5 class="modal-title">Eliminar fichero</h5>`;
  modalHeader.insertAdjacentHTML(
    "beforeend",
    `<button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
     </button>`
  );

  let modalBody = document.createElement("div");
  modalBody.classList.add("modal-body", "text-center");
  modalBody.innerHTML = `<div class="alert alert-danger" role="alert">
    ¿Estás seguro de que deseas eliminar el fichero "<strong>${fileName}</strong>"?
  </div>`;

  let modalFooter = document.createElement("div");
  modalFooter.classList.add("modal-footer");

  let closeButton = document.createElement("button");
  closeButton.type = "button";
  closeButton.classList.add("btn", "btn-barymont-black");
  closeButton.setAttribute("data-dismiss", "modal");
  closeButton.innerText = "Cancelar";

  let deleteButton = document.createElement("button");
  deleteButton.type = "button";
  deleteButton.classList.add("btn", "btn-barymont-red");
  deleteButton.innerText = "Eliminar";

  deleteButton.addEventListener("click", async () => {
    const response = await fetchDeleteUserFile(userId, fileId);
    $("#" + modalId).modal("hide");

    if (response.status === 200) {
      createToast("success", "Fichero eliminado", "El fichero ha sido eliminado exitosamente.", 5000);
      await loadModalFilesContent(userId, modalBodyId);
    } else {
      const errorMessage = response.data.message || "Error al eliminar el fichero.";
      createToast("error", "Error al eliminar", errorMessage, 5000);
    }
  });

  modalFooter.appendChild(deleteButton);
  modalFooter.appendChild(closeButton);
  modalContent.appendChild(modalHeader);
  modalContent.appendChild(modalBody);
  modalContent.appendChild(modalFooter);
  modalDialog.appendChild(modalContent);
  modal.appendChild(modalDialog);

  document.body.appendChild(modal);
  $("#" + modalId).modal("show");

  $("#" + modalId).on("hidden.bs.modal", function () {
    $(this).remove();
  });
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname === "/herramientas/user-management") {
      const datatableId = "user-management-table";
      const preBtnUserFilesId = "btn-user-files-from-";
      const { userFilesQuantity, userFilesModalBody } = ELEMENT_IDS;

      document.getElementById(datatableId).addEventListener("click", (event) => {
        if (event.target.classList.contains("btn-load-user-files")) {
          const userId = event.target.getAttribute("data-user-id");

          loadQuantityOfUserFiles(userId, userFilesQuantity, preBtnUserFilesId + userId);

          document.getElementById(preBtnUserFilesId + userId).disabled = true;

          printFileListOnModal(userId, userFilesModalBody);

          document.getElementById(preBtnUserFilesId + userId).disabled = false;
        }
      });

      document.addEventListener("userFileUploaded", () => {
        $("#user-management-table").DataTable().ajax.reload();
      });
    }

    if (window.location.pathname.includes("/herramientas/user-management/")) {
      if (AppGbSession.checkUserHasPermission("User:UserFileManageAccessChecker")) {
        const { btnLoadUserFiles, userFilesQuantity, userFilesModalBody } = ELEMENT_IDS;
        const userId = document.getElementById("user-id").value;

        loadQuantityOfUserFiles(userId, userFilesQuantity, btnLoadUserFiles);

        document.getElementById(btnLoadUserFiles)?.addEventListener("click", () => {
          document.getElementById(btnLoadUserFiles).disabled = true;

          printFileListOnModal(userId, userFilesModalBody);

          document.getElementById(btnLoadUserFiles).disabled = false;
        });

        document.addEventListener("userFileUploaded", () => {
          loadQuantityOfUserFiles(userId, userFilesQuantity, btnLoadUserFiles);
        });
      }
    }
  });
}

export {};
