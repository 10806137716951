"use strict";

export async function searchUsers(profileLevel = [], roles = [], isActive = null, search = null, limit = null, offset = null, permissions = []) {
  if (!Array.isArray(profileLevel)) {
    throw new Error("profileLevel must be an array");
  }

  if (!Array.isArray(permissions)) {
    throw new Error("permissions must be an array");
  }

  if (!Array.isArray(roles)) {
    throw new Error("roles must be an array");
  }

  if (isActive !== null && typeof isActive !== "boolean") {
    throw new Error("isActive must be a boolean");
  }

  if (search !== null && typeof search !== "string") {
    throw new Error("search must be a string");
  }

  if (limit !== null && typeof limit !== "number") {
    throw new Error("limit must be a number");
  }

  if (offset !== null && typeof offset !== "number") {
    throw new Error("offset must be a number");
  }

  let filters = {};

  if (profileLevel.length > 0) {
    filters.profileLevel = profileLevel;
  }

  if (roles.length > 0) {
    filters.roles = roles;
  }

  if (permissions.length > 0) {
    filters.permissions = permissions;
  }

  if (isActive !== null) {
    filters.isActive = isActive;
  }

  if (search !== null) {
    filters.search = search;
  }

  if (limit !== null) {
    filters.limit = limit;
  }

  if (offset !== null) {
    filters.offset = offset;
  }

  let queryParams = "";

  if (Object.keys(filters).length !== 0) {
    const urlSearchParams = new URLSearchParams(filters);

    if (urlSearchParams.toString() !== "") {
      queryParams = "?" + urlSearchParams.toString();
    }
  }

  const response = await fetch("/api/users" + queryParams, { method: "GET", redirect: "follow" });

  const responseData = await response.json().catch((error) => {
    throw new Error("Something went wrong parsing json response check backend" + error);
  });

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function searchUserRoles() {
  const response = await fetch("/api/users/roles", { method: "GET", redirect: "follow" });
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function searchUser({ nif = null, uuid = null, externalProviderAccountType = null, externalProviderAccountMostImportantValue = null }) {
  let queryParams = "";

  if (nif !== null) {
    queryParams = `?nif=${nif}`;
  } else if (uuid !== null) {
    queryParams = `?uuid=${uuid}`;
  } else if (externalProviderAccountType !== null && externalProviderAccountMostImportantValue !== null) {
    queryParams = `?externalProviderAccountType=${externalProviderAccountType}&externalProviderAccountMostImportantValue=${externalProviderAccountMostImportantValue}`;
  } else {
    throw new Error("nif, uuid or externalProviderAccountType and externalProviderAccountMostImportantValue must be provided");
  }

  const response = await fetch("/api/user" + queryParams);
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function searchUserStructure(userId, mode) {
  const validModes = ["Full", "Subordinates", "Responsibles"];

  if (!validModes.includes(mode)) {
    throw new Error("Invalid mode, must be one of" + validModes.join(", "));
  }

  if (!userId) {
    throw new Error("userId must be provided");
  }

  const response = await fetch(`/api/users/${userId}/structure?mode=${mode}`);
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function checkAssignmentsUserById(uuid) {
  const response = await fetch(`/api/users/${uuid}/assignments`);
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function searchUserPreferences(toolValue) {
  const response = await fetch(`/api/user/preferences?preferenceTypeTool=${toolValue}`);
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function updateUserPreference(userId, preferenceType, preferenceValue, preferenceData) {
  const response = await fetch(`/api/user/${userId}/preferences`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      type: preferenceType,
      state: preferenceValue,
      userPreferenceData: preferenceData,
    }),
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

//! REMOVE THIS WINDOW OBJECT
window.usersApiSearchUsers = searchUsers;
window.userCheckAssignmentsUserById = checkAssignmentsUserById;
window.usersApiSearchUser = searchUser;
window.userSearchUserStructure = searchUserStructure;
