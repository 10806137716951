"use strict";

import { findManagerByCommercialEntityId } from "../../api/ebroker-commercial-entities.js";

// Validate sellers code and update reviewer select on proposal creation UI
async function validateAsesorNumber(asesorNumber) {
  if (asesorNumber !== 1 && asesorNumber !== 2) return;

  const element = document.getElementById(`code_asesor_${asesorNumber}`);

  if (!element) return;

  removeElementsIfExists(`reviewer_option_${element.id}`);

  const handleInvalid = (message) => {
    element.classList.add("is-invalid");
    element.classList.remove("is-valid");
    element.parentNode.insertBefore(createMessageElement(`${element.id}_msg`, "danger", message), element.nextSibling);
  };

  const handleValid = (userManager) => {
    element.classList.add("is-valid");
    element.classList.remove("is-invalid");
    element.parentNode.insertBefore(createMessageElement(`${element.id}_msg`, "success", userManager.commercialEntityName), element.nextSibling);

    const optionText = `${userManager.managerName} - ${userManager.managerEmail}`;
    const requiresReviewSelect = document.getElementById("reviewer_user_id");

    if (requiresReviewSelect && !Array.from(requiresReviewSelect.options).some((option) => option.value == userManager.managerUserId) && userManager.managerEmail !== "") {
      const newOption = document.createElement("option");
      newOption.value = userManager.managerUserId;
      newOption.textContent = optionText;
      newOption.setAttribute("id", `reviewer_option_${element.id}`);
      requiresReviewSelect.appendChild(newOption);
    }
  };

  if (element.value === "") {
    if (asesorNumber === 1) {
      handleInvalid("Introduce un código de asesor.");
    } else if (asesorNumber === 2) {
      element.classList.remove("is-invalid", "is-valid");
      removeElementsIfExists(`${element.id}_msg`);
    }
    return;
  }

  if (element.value.length < 5 || element.value < 23000 || element.value > 40000) {
    handleInvalid("El código de asesor introducido no es válido.");
    return;
  }

  try {
    const userManager = await findManagerByCommercialEntityId(element.value);
    handleValid(userManager);
  } catch (error) {
    handleInvalid(error.message ? error.message : "No se ha encontrado el asesor con el código introducido.");
  }
}

function createMessageElement(id, type, message) {
  removeElementsIfExists(id);
  const newDiv = document.createElement("div");
  newDiv.setAttribute("id", id);
  newDiv.innerHTML = `<span class="badge badge-${type} font-weight-bold mt-2" style="font-size: 12px;">${message}</span>`;
  return newDiv;
}

function removeElementsIfExists(id) {
  const elements = document.querySelectorAll(`#${id}`);
  elements.forEach((element) => element.remove());
}

// Below functions are used in the proposal creation UI with input events (Must be removed when the proposal creation UI refactoring is done)
window.proposalCreationModuleValidateAsesorNumber = validateAsesorNumber;
