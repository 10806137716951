"use strict";

import { showLoadingButton, updateButtonLabel } from "../shared/shared.js";

const ACTION_MODES = ["CREATE", "UPDATE"];

async function fetchAllAuthors() {
  return await fetch("/herramientas/news/author/ajax/getAllAuthors").then((response) => response.json());
}

async function fetchAuthorData(authorId) {
  const formData = new FormData();

  formData.append("authorId", authorId);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/news/author/ajax/getAuthorData", requestOptions).then((response) => response.json());
}

async function fetchCreateAuthor(imageBase64, name, role, description) {
  const formData = new FormData();

  formData.append("imageBase64", imageBase64);
  formData.append("name", name);
  formData.append("role", role);
  formData.append("description", description);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/news/author/ajax/createAuthor", requestOptions).then((response) => response.json());
}

async function fetchUpdateAuthor(authorId, imageBase64, name, role, description) {
  const formData = new FormData();

  formData.append("authorId", authorId);
  formData.append("imageBase64", imageBase64);
  formData.append("name", name);
  formData.append("role", role);
  formData.append("description", description);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/news/author/ajax/updateAuthor", requestOptions).then((response) => response.json());
}

async function fetchDeleteAuthor(authorId) {
  const formData = new FormData();

  formData.append("authorId", authorId);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/news/author/ajax/deleteAuthor", requestOptions).then((response) => response.json());
}

function transformAuthorData(data) {
  return data.map((item) => ({
    newsAuthorId: item.newsAuthorId,
    name: item.name,
    role: item.role,
    image: item.image,
    description: item.description,
    createdAt: item.createdAt,
    updatedAt: item.updatedAt,
    actions: `
      <div class="datatable-btn-grid-container">
        ${
          AppGbSession.checkUserHasPermission("News:NewsAuthorDeleteAccessChecker")
            ? `
        <button id="btn-delete-${item.newsAuthorId}" data-author-id="${item.newsAuthorId}" class="btn btn-barymont-black remove-author" data-toggle="tooltip" title="Eliminar autor">
          <i class="fas fa-trash" style="pointer-events: none;"></i>
        </button>`
            : ""
        }
        <button id="btn-edit-${item.newsAuthorId}" data-author-id="${item.newsAuthorId}" class="btn btn-barymont-red edit-author" data-toggle="tooltip" title="Editar autor">
          <i class="fas fa-edit" style="pointer-events: none;"></i>
        </button>
      </div>
    `,
  }));
}

function printAuthorDatatable(data, tableId, modalAuthorCreateId, modalAuthorActionStateId) {
  if ($.fn.DataTable.isDataTable("#" + tableId)) {
    $("#" + tableId)
      .DataTable()
      .destroy();
    $("#" + tableId).empty();
  }

  const dataFormated = transformAuthorData(data);

  $("#" + tableId).DataTable({
    language: {
      sProcessing: "Procesando Datos...",
      sLengthMenu: "Mostrar _MENU_ Autores",
      sZeroRecords: "No se encontraron autores coincidentes con el criterio de búsqueda.",
      sEmptyTable: "Ningun autor coincidente con el criterio de búsqueda.",
      sInfo: "Mostrando autores del _START_ al _END_ de un total de _TOTAL_ autores",
      sInfoEmpty: "Mostrando autores del 0 al 0 de un total de 0 autores",
      sInfoFiltered: "(Filtrados de un total de _MAX_ autores)",
      sInfoPostFix: "",
      sSearch: "<div class='input-group-prepend'><div class='input-group-text'><i class='fas fa-search'></i></div></div>",
      searchPlaceholder: "Datos de los autores...",
      sUrl: "",
      sInfoThousands: ",",
      sLoadingRecords: "Cargando Datos...",
      oPaginate: {
        sFirst: "Primera",
        sLast: "Última",
        sNext: "Siguiente",
        sPrevious: "Anterior",
      },
      oAria: {
        sSortAscending: ": Activar para ordenar la columna de manera ascendente",
        sSortDescending: ": Activar para ordenar la columna de manera descendente",
      },
    },
    pagingType: "input",
    columnDefs: [
      {
        targets: [0, 1, 2, 3],
        className: "dt-center",
      },
      {
        targets: [1, 2],
        orderable: true,
      },
      {
        targets: [0, 3],
        orderable: false,
      },
    ],
    order: [[1, "asc"]],
    processing: true,
    data: dataFormated,
    columns: [
      {
        data: "image",
        title: "<i class='fa-regular fa-circle-user'></i><span class='d-none'>Imagen</span>",
        render: function (data) {
          return `<img src="${data}" class="rounded" style="width:25px; max-width:25px;">`;
        },
        className: "text-center",
        orderable: false,
        searchable: false,
      },
      { data: "name", title: "Nombre", className: "text-left" },
      { data: "role", title: "Rol", className: "text-left" },
      {
        data: "actions",
        title: "<i class='fas fa-tools text-white'></i><span class='d-none'>Opciones</span>",
        className: "text-center",
        orderable: false,
        searchable: false,
      },
    ],
    dom: '<"top"fl>Brt<"bottom"ip><"clear">',
    buttons: [
      {
        extend: "colvis",
        text: "Ver más columnas",
      },
      {
        text: '<i class="fas fa-user mr-2"></i> Crear nuevo autor',
        className: "btn btn-barymont-red my-2 my-md-0",
        action: function () {
          document.getElementById(modalAuthorActionStateId).value = ACTION_MODES[0];
          $("#" + modalAuthorCreateId).modal("show");
        },
        init: function (api, node) {
          $(node).removeClass("dt-button");
        },
      },
    ],
    pageLength: 10,
    lengthMenu: [
      [10, 25, 50],
      [10, 25, 50],
    ],
    select: false,
    keys: true,
    searchHighlight: true,
    fnDrawCallback: function () {
      document.querySelectorAll('[data-toggle="tooltip"]').forEach(function (element) {
        new bootstrap.Tooltip(element);
      });
    },
  });
}

function tinymceCommonConfig(selector) {
  return {
    allow_unsafe_link_target: false,
    invalid_elements: "script,object,embed,link,style,form,input,iframe",
    selector: `textarea#${selector}`,
    height: 250,
    menubar: false,
    plugins: ["link image imagetools table lists preview anchor paste"],
    toolbar: "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | table | preview ",
    style_formats: [
      {
        title: "Títulos",
        items: [
          { title: "Título 1", format: "h3" },
          { title: "Título 2", format: "h4" },
          { title: "Título 3", format: "h5" },
          { title: "Título 4", format: "h6" },
        ],
      },
      {
        title: "Formatos",
        items: [
          { title: "Negrita", format: "bold" },
          { title: "Cursiva", format: "italic" },
          { title: "Subrayada", format: "underline" },
          { title: "Tachada", format: "strikethrough" },
        ],
      },
      { title: "Bloques", items: [{ title: "Párrafo", format: "p" }] },
      {
        title: "Posiciones",
        items: [
          { title: "Izquierda", format: "alignleft" },
          { title: "Centrado", format: "aligncenter" },
          { title: "Derecha", format: "alignright" },
          { title: "Justificado", format: "alignjustify" },
        ],
      },
    ],
    content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
    branding: false,
  };
}

function initializeModalAuthor(modalId, imagePreviewId, imageFileId, imageFileBase64Id, btnUpdateModalImageId, authorRemoveImageId, cropModalImageId, descriptionId, modalAuthorImageDataId, modalAuthorActionStateId, modalAuthorActionButtonId) {
  const imagePreview = document.getElementById(imagePreviewId);
  const imageFile = document.getElementById(imageFileId);
  const imageFileBase64 = document.getElementById(imageFileBase64Id);
  const btnUpdateModalImage = document.getElementById(btnUpdateModalImageId);
  const authorRemoveImage = document.getElementById(authorRemoveImageId);
  const cropModalImage = document.getElementById(cropModalImageId);

  let cropper;

  imageFile.addEventListener("change", function (event) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        cropModalImage.src = e.target.result;
        $("#" + modalAuthorImageDataId).modal("show");
      };
      reader.readAsDataURL(file);
    }
  });

  $("#" + modalId).on("shown.bs.modal", () => {
    if (!tinymce.activeEditor) {
      tinymce.init(tinymceCommonConfig(descriptionId));
    }

    if (document.getElementById(modalAuthorActionStateId).value === ACTION_MODES[0]) {
      document.getElementById(modalAuthorActionButtonId).innerText = "Crear";
    }

    if (document.getElementById(modalAuthorActionStateId).value === ACTION_MODES[1]) {
      document.getElementById(modalAuthorActionButtonId).innerText = "Guardar cambios";
    }
  });

  $("#" + modalAuthorImageDataId)
    .on("shown.bs.modal", () => {
      cropper = new Cropper(cropModalImage, {
        aspectRatio: 1,
        viewMode: 1,
        autoCropArea: 1,
        preview: ".preview-modal-author-data-image",
      });
    })
    .on("hidden.bs.modal", () => {
      if (cropper) {
        cropper.destroy();
        cropper = null;
        imageFile.value = "";
      }
    });

  btnUpdateModalImage.addEventListener("click", function () {
    const canvas = cropper.getCroppedCanvas({
      width: 300,
      height: 300,
    });

    try {
      canvas.toBlob((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result;
          imageFileBase64.value = JSON.stringify(base64data);
          imagePreview.src = base64data;
          $("#" + modalAuthorImageDataId).modal("hide");
          createToast("info", "Imagen del autor añadida", "La imagen se ha seleccionado correctamente. Complete la creación del autor para subir la imagen.", 5000);
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      createToast("warning", "Ha ocurrido un error", "No se pudo cambiar la imagen del autor. Por favor, inténtelo de nuevo.", 5000);
    }
  });

  authorRemoveImage.addEventListener("click", function () {
    if (document.getElementById(modalAuthorActionStateId).value === ACTION_MODES[0]) {
      imageFileBase64.value = "";
    }

    if (document.getElementById(modalAuthorActionStateId).value === ACTION_MODES[1]) {
      imageFileBase64.value = "_DELETE_CURRENT_AUTHOR_IMAGE_";
    }

    imagePreview.src = "https://content.barymont.com/files/760f6e8f-bb69-4a00-9fec-05e8ad79211d.png";
    createToast("info", "Imagen del autor eliminada", "La imagen del autor se ha eliminado correctamente.", 5000);
  });
}

function loadAuthorsDatatable(dataTableId, modalAuthorCreateId, modalAuthorActionStateId) {
  fetchAllAuthors().then((data) => {
    if (data.success) {
      printAuthorDatatable(data.data, dataTableId, modalAuthorCreateId, modalAuthorActionStateId);
    } else {
      createToast("warning", "Error al cargar", "No se ha podido cargar la lista de autores", 5000);
    }
  });
}

function clearModalData(authorIdId, authorNameId, authorRoleId, authorDescriptionId, authorImageFileBase64Id, authorImagePreviewId) {
  document.getElementById(authorIdId).value = "";
  document.getElementById(authorNameId).value = "";
  document.getElementById(authorRoleId).value = "";
  tinymce.get(authorDescriptionId).setContent("");
  document.getElementById(authorImageFileBase64Id).value = "";
  document.getElementById(authorImagePreviewId).src = "https://content.barymont.com/files/760f6e8f-bb69-4a00-9fec-05e8ad79211d.png";
}

function setAuthorData(authorIdId, authorNameId, authorRoleId, authorDescriptionId, authorImageFileBase64Id, authorImagePreviewId, data) {
  document.getElementById(authorIdId).value = data.newsAuthorId;
  document.getElementById(authorNameId).value = data.name;
  document.getElementById(authorRoleId).value = data.role;
  document.getElementById(authorImageFileBase64Id).value = "";
  document.getElementById(authorImagePreviewId).src = data.image;

  document.getElementById(authorDescriptionId).value = data.description;

  if (tinymce.get(authorDescriptionId)) {
    tinymce.get(authorDescriptionId).remove();
  }

  tinymce.init({
    ...tinymceCommonConfig(authorDescriptionId),
    setup: function (editor) {
      editor.on("init", function () {
        if (data.description) {
          editor.setContent(data.description);
        } else {
          editor.setContent("");
        }
      });
    },
  });
}

function createAuthor(authorIdId, authorImageFileBase64Id, authorNameId, authorRoleId, authorDescriptionId, authorImagePreviewId, modalAuthorDataId, dataTableId, modalAuthorActionButtonId, modalAuthorActionStateId) {
  const imageBase64 = document.getElementById(authorImageFileBase64Id).value;
  const name = document.getElementById(authorNameId).value;
  const role = document.getElementById(authorRoleId).value;
  const description = tinymce.get(authorDescriptionId).getContent();

  fetchCreateAuthor(imageBase64, name, role, description).then((data) => {
    if (data.success) {
      createToast("success", "Autor creado", "El autor se ha creado correctamente", 5000);
      $("#" + modalAuthorDataId).modal("hide");
      loadAuthorsDatatable(dataTableId, modalAuthorDataId, modalAuthorActionStateId);
      clearModalData(authorIdId, authorNameId, authorRoleId, authorDescriptionId, authorImageFileBase64Id, authorImagePreviewId);
    } else {
      createToast("warning", "Error al crear", "No se ha podido crear el autor", 5000);
    }
    updateButtonLabel(modalAuthorActionButtonId, "Crear", "btn-barymont-red");
  });
}

function updateAuthorData(authorIdId, authorImageFileBase64Id, authorNameId, authorRoleId, authorDescriptionId, authorImagePreviewId, modalAuthorDataId, dataTableId, modalAuthorActionButtonId, modalAuthorActionStateId) {
  const authorId = document.getElementById(authorIdId).value;
  const imageBase64 = document.getElementById(authorImageFileBase64Id).value;
  const name = document.getElementById(authorNameId).value;
  const role = document.getElementById(authorRoleId).value;
  const description = tinymce.get(authorDescriptionId).getContent();

  fetchUpdateAuthor(authorId, imageBase64, name, role, description).then((data) => {
    if (data.success) {
      createToast("success", "Autor actualizado", "El autor se ha actualizado correctamente", 5000);
      $("#" + modalAuthorDataId).modal("hide");
      loadAuthorsDatatable(dataTableId, modalAuthorDataId, modalAuthorActionStateId);
      clearModalData(authorIdId, authorNameId, authorRoleId, authorDescriptionId, authorImageFileBase64Id, authorImagePreviewId);
    } else {
      createToast("warning", "Error al actualizar", "No se ha podido actualizar el autor", 5000);
    }
    updateButtonLabel(modalAuthorActionButtonId, "Guardar cambios", "btn-barymont-red");
  });
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname === "/herramientas/noticias" && AppGbSession.checkUserHasPermission("News:NewsAuthorManageAccessChecker")) {
      const dataTableId = "author-table";

      // Form inputs ids
      const authorImagePreviewId = "author-image-preview";
      const authorImageFileId = "author-image-file";
      const authorImageFileBase64Id = "author-image-file-base64";
      const authorRemoveImageId = "author-remove-image";
      const authorNameId = "author-name";
      const authorRoleId = "author-role";
      const authorDescriptionId = "author-description";

      // Modal ids
      const modalAuthorDataId = "modal-author-data";
      const modalAuthorIdId = "modal-author-id";
      const modalAuthorActionStateId = "modal-author-action-state";
      const modalAuthorActionButtonId = "modal-author-action-button";
      const modalAuthorImageDataId = "modal-author-image-data";
      const cropModalAuthorDataImageId = "crop-modal-author-data-image";
      const btnUpdateModalAuthorDataImageId = "btn-update-modal-author-data-image";

      //modal delete
      const modalAuthorDeleteId = "modal-author-delete";

      const modalAuthorDeleteIdId = "modal-author-delete-id";
      const modalAuthorDeleteButtonId = "modal-author-delete-button";

      loadAuthorsDatatable(dataTableId, modalAuthorDataId, modalAuthorActionStateId);

      initializeModalAuthor(modalAuthorDataId, authorImagePreviewId, authorImageFileId, authorImageFileBase64Id, btnUpdateModalAuthorDataImageId, authorRemoveImageId, cropModalAuthorDataImageId, authorDescriptionId, modalAuthorImageDataId, modalAuthorActionStateId, modalAuthorActionButtonId);

      //create author actions
      document.getElementById(modalAuthorActionButtonId).addEventListener("click", function () {
        showLoadingButton(modalAuthorActionButtonId, "btn-barymont-red");
        const actionState = document.getElementById(modalAuthorActionStateId).value;

        if (actionState === ACTION_MODES[0]) {
          createAuthor(modalAuthorIdId, authorImageFileBase64Id, authorNameId, authorRoleId, authorDescriptionId, authorImagePreviewId, modalAuthorDataId, dataTableId, modalAuthorActionButtonId, modalAuthorActionStateId);
        }

        if (actionState === ACTION_MODES[1]) {
          updateAuthorData(modalAuthorIdId, authorImageFileBase64Id, authorNameId, authorRoleId, authorDescriptionId, authorImagePreviewId, modalAuthorDataId, dataTableId, modalAuthorActionButtonId, modalAuthorActionStateId);
        }
      });

      //pre delete author actions
      document.getElementById(dataTableId)?.addEventListener("click", function (event) {
        if (event.target.classList.contains("remove-author")) {
          document.getElementById(modalAuthorDeleteIdId).value = event.target.getAttribute("data-author-id");

          $("#" + modalAuthorDeleteId).modal("show");
        }
      });

      //delete author actions
      document.getElementById(modalAuthorDeleteButtonId).addEventListener("click", function () {
        const authorId = document.getElementById(modalAuthorDeleteIdId).value;
        showLoadingButton(modalAuthorDeleteButtonId, "btn-barymont-red");

        fetchDeleteAuthor(authorId).then((data) => {
          if (data.success) {
            createToast("success", "Autor eliminado", "El autor se ha eliminado correctamente", 5000);
            $("#" + modalAuthorDeleteId).modal("hide");
            loadAuthorsDatatable(dataTableId, modalAuthorDataId, modalAuthorActionStateId);
            document.getElementById(modalAuthorDeleteIdId).value = "";
          } else {
            createToast("warning", "Error al eliminar", "No se ha podido eliminar el autor", 5000);
          }
          updateButtonLabel(modalAuthorDeleteButtonId, "Eliminar", "btn-barymont-red");
        });
      });

      //edit author actions
      document.getElementById(dataTableId)?.addEventListener("click", function (event) {
        if (event.target.classList.contains("edit-author")) {
          const authorId = event.target.getAttribute("data-author-id");
          showLoadingButton("btn-edit-" + authorId, "btn-barymont-red", true);

          fetchAuthorData(authorId).then((data) => {
            if (data.success) {
              setAuthorData(modalAuthorIdId, authorNameId, authorRoleId, authorDescriptionId, authorImageFileBase64Id, authorImagePreviewId, data.data);
              $("#" + modalAuthorDataId).modal("show");
              document.getElementById(modalAuthorActionStateId).value = ACTION_MODES[1];
            } else {
              createToast("warning", "Error al cargar", "No se ha podido cargar los datos del autor", 5000);
            }
            updateButtonLabel("btn-edit-" + authorId, "<i class='fas fa-edit' style='pointer-events: none;'></i>", "btn-barymont-red");
          });
        }
      });
    }
  });
}

export {};
