export default class ModalHandler {
  private modalId: string;
  private modalJqueryElement: JQuery<HTMLElement>;

  private constructor(modalId: string) {
    this.modalId = modalId;
    this.modalJqueryElement = $(`#${this.modalId}`);
    if (this.modalJqueryElement.length === 0) {
      throw new Error(`No se encontró el elemento con id ${this.modalId}`);
    }
  }

  /**
   * Initialize the modal handler with the modal id
   */
  static init(modalId: string): ModalHandler {
    return new ModalHandler(modalId);
  }

  /**
   *  Show the modal
   */
  show(): ModalHandler {
    this.modalJqueryElement.modal("show");
    return this;
  }

  /**
   * Hide the modal
   */
  hide(): ModalHandler {
    this.modalJqueryElement.modal("hide");
    return this;
  }

  /**
   * Remove the modal from the DOM
   */
  remove(): void {
    this.modalJqueryElement.remove();
  }

  /**
   * Configure the modal to be removed from the DOM when it is hidden
   */
  onHideRemove(): ModalHandler {
    this.modalJqueryElement.on("hidden.bs.modal", () => {
      this.remove();
    });
    return this;
  }

}
