"use strict";

import { showLoadingButton, updateButtonLabel } from "../shared/shared.js";

var FILTER_STATE_LOCAL_STORAGE_KEY = "NEWS_REDIRECT_FILTER_STATE_";
var FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME = 1000 * 60 * 20; // 20 minutes in milliseconds

var redirectCodesData = null;

function getStateOfCustomFilters() {
  return {
    selectRedirectCode: Array.from(document.getElementById("redirect-code-select").selectedOptions).map((option) => option.value),
    selectDateFrom: document.getElementById("news-redirect-created-date-from").value,
    selectDateTo: document.getElementById("news-redirect-created-date-to").value,
    selectRedirectState: document.getElementById("redirect-state").value,
  };
}

function recreateStateOfCustomFilters(localStorageData) {
  if (localStorageData.customFilter === undefined) {
    return false;
  }

  const selectRedirectCode = document.getElementById("redirect-code-select");
  const selectedCodes = localStorageData.customFilter.selectRedirectCode || [];
  Array.from(selectRedirectCode.options).forEach((option) => {
    option.selected = selectedCodes.includes(option.value);
  });

  document.getElementById("news-redirect-created-date-from").value = localStorageData.customFilter.selectDateFrom;
  document.getElementById("news-redirect-created-date-to").value = localStorageData.customFilter.selectDateTo;
  document.getElementById("redirect-state").value = localStorageData.customFilter.selectRedirectState;

  $(".selectpicker").selectpicker("refresh");
}

function resetearFiltros(datatableId) {
  $("#redirect-code-select").val([]).selectpicker("refresh");
  document.getElementById("news-redirect-created-date-from").value = "";
  document.getElementById("news-redirect-created-date-to").value = "";
  $("#redirect-state").val("").selectpicker("refresh");

  $("#" + datatableId)
    .DataTable()
    .search("")
    .draw();
  $("#" + datatableId)
    .DataTable()
    .ajax.reload();
}

function loadNewsRedirectDatatable(datatableId) {
  if ($.fn.DataTable.isDataTable("#" + datatableId)) {
    $("#" + datatableId)
      .DataTable()
      .destroy();
    $("#" + datatableId).empty();
  }

  $("#" + datatableId).DataTable({
    language: {
      sProcessing: "Procesando Datos...",
      sLengthMenu: "Mostrar _MENU_ Redirecciones",
      sZeroRecords: "No se encontraron redirecciones coincidentes con el criterio de búsqueda.",
      sEmptyTable: "Ninguna redirección coincidente con el criterio de búsqueda.",
      sInfo: "Mostrando redirecciones del _START_ al _END_ de un total de _TOTAL_ redirecciones",
      sInfoEmpty: "Mostrando redirecciones del 0 al 0 de un total de 0 redirecciones",
      sInfoFiltered: "(Filtrados de un total de _MAX_ redirecciones)",
      sInfoPostFix: "",
      sSearch: "<div class='input-group-prepend'><div class='input-group-text'><i class='fas fa-search'></i></div></div>",
      searchPlaceholder: "Datos de las redirecciones...",
      sUrl: "",
      sInfoThousands: ",",
      sLoadingRecords: "Cargando Datos...",
      oPaginate: {
        sFirst: "Primera",
        sLast: "Última",
        sNext: "Siguiente",
        sPrevious: "Anterior",
      },
      oAria: {
        sSortAscending: ": Activar para ordenar la columna de manera ascendente",
        sSortDescending: ": Activar para ordenar la columna de manera descendente",
      },
    },
    pagingType: "input",
    columnDefs: [
      {
        targets: [0, 1, 5, 6, 7, 8, 9],
        className: "dt-center",
      },
      {
        targets: [6],
        orderable: true,
      },
      {
        targets: [0, 1, 2, 3, 4, 5, 6, 8, 9],
        orderable: false,
      },
      {
        targets: [0],
        visible: false,
      },
    ],
    order: [[6, "desc"]],
    processing: true,
    serverSide: true,
    scrollX: true,
    dom: '<"top"fl>Brt<"bottom"ip><"clear">',
    buttons: [
      {
        extend: "colvis",
        text: "Ver más columnas",
      },
      {
        text: '<i class="fas fa-eraser mr-2"></i> Limpiar filtros',
        className: "btn btn-barymont-black my-2 my-md-0",
        action: function () {
          resetearFiltros(datatableId);
        },
        init: function (api, node) {
          $(node).removeClass("dt-button");
        },
      },
      {
        text: '<i class="fa-solid fa-shuffle mr-2"></i> Crear nueva redirección',
        className: "btn btn-barymont-red my-2 my-md-0",
        id: "btn-create-redirect",
        action: async function () {
          showLoadingButton("btn-create-redirect", "btn-barymont-red");
          openCreateUpdateRedirectModal("create", datatableId).then(() => {
            updateButtonLabel("btn-create-redirect", '<i class="fa-solid fa-shuffle mr-2"></i> Crear nueva redirección', "btn-barymont-red");
          });
        },
        init: function (api, node) {
          $(node).removeClass("dt-button").attr("id", "btn-create-redirect");
        },
      },
    ],
    pageLength: 10,
    lengthMenu: [
      [10, 25, 50],
      [10, 25, 50],
    ],
    select: false,
    keys: true,
    searchHighlight: true,
    ajax: {
      url: "/herramientas/news/redirect/listaprocessing",
      type: "post",
      data: function (d) {
        let selectDateFrom = $("#news-redirect-created-date-from").val();
        let selectDateTo = $("#news-redirect-created-date-to").val();

        d.selectRedirectCode = $("#redirect-code-select").val();
        d.selectRedirectState = $("#redirect-state").val();
        d.selectDateFrom = selectDateFrom != "" ? selectDateFrom : "";
        d.selectDateTo = selectDateTo != "" ? selectDateTo : "";
      },
      error: function (e) {
        if (e.status === 401) {
          createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
          setTimeout(function () {
            location.reload();
          }, 5000);
        } else {
          createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
        }
      },
    },
    fnDrawCallback: function () {
      $('[data-toggle="tooltip"]').tooltip();
    },
    stateSave: true,
    stateSaveCallback: function (settings, data) {
      let storageKey = `${FILTER_STATE_LOCAL_STORAGE_KEY}${settings.sInstance}_${getCookieValue("user_id")}`;

      data.customFilter = getStateOfCustomFilters();

      localStorage.setItem(storageKey, JSON.stringify(data));
    },
    stateLoadCallback: function (settings) {
      let storageKey = `${FILTER_STATE_LOCAL_STORAGE_KEY}${settings.sInstance}_${getCookieValue("user_id")}`;

      if (localStorage.getItem(storageKey) === null) {
        return false;
      }

      let localStorageData = JSON.parse(localStorage.getItem(storageKey));

      if (localStorageData.time < new Date().getTime() - FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME) {
        return false;
      }

      recreateStateOfCustomFilters(localStorageData);

      return localStorageData;
    },
  });

  $(".triggerDatatableUpdateNewsRedirects").change(function () {
    $("#" + datatableId)
      .DataTable()
      .ajax.reload();
  });

  $("#" + datatableId)
    .DataTable()
    .on("draw", function () {
      document.querySelectorAll(".remove-news-redirect").forEach((element) => {
        element.addEventListener("click", async function () {
          showLoadingButton(this.id, "btn-barymont-black", true);
          createDeleteRedirectModal(this.dataset.newsRedirectId, datatableId).then(() => {
            updateButtonLabel(this.id, '<i class="fas fa-trash"></i>', "btn-barymont-black");
          });
        });
      });
    });

  $("#" + datatableId)
    .DataTable()
    .on("draw", function () {
      document.querySelectorAll(".update-news-redirect").forEach((element) => {
        element.addEventListener("click", async function () {
          showLoadingButton(this.id, "btn-barymont-red", true);
          openCreateUpdateRedirectModal("update", datatableId, this.dataset.newsRedirectId).then(() => {
            updateButtonLabel(this.id, '<i class="fas fa-edit"></i>', "btn-barymont-red");
          });
        });
      });
    });
}

async function fetchRedirectCodes() {
  return await fetch("/api/news/redirect/codes").then((response) => response.json());
}

async function loadRedirectCodesOptions(selectRedirectCodeId, selectedValue) {
  const selectRedirectCode = document.getElementById(selectRedirectCodeId);

  selectRedirectCode.innerHTML = "";

  const defaultOption = document.createElement("option");
  defaultOption.value = "";
  defaultOption.text = "Selecciona un código de redirección";
  defaultOption.disabled = true;
  defaultOption.selected = false;

  selectRedirectCode.appendChild(defaultOption);

  redirectCodesData.forEach((redirectCode) => {
    const option = document.createElement("option");
    option.value = redirectCode.value;
    option.setAttribute("data-content", `<span>${redirectCode.value}</span><small class="text-muted"> ${redirectCode.description}</small>`);

    if (selectedValue && selectedValue === redirectCode.value) {
      option.selected = true;
    }

    selectRedirectCode.appendChild(option);
  });

  $("#" + selectRedirectCodeId).selectpicker("refresh");
}

async function fetchDeleteRedirect(redirectId) {
  try {
    const response = await fetch(`/api/news/redirect/${redirectId}`, { method: "DELETE", redirect: "follow" });
    const data = await response.json();
    return { status: response.status, data };
  } catch (error) {
    console.error("Error durante la solicitud:", error);
    return { status: 500, data: { message: "Error en la solicitud." } };
  }
}

async function createDeleteRedirectModal(redirectId, datatableId) {
  const modalId = "delete-redirect-modal";

  const existingModal = document.getElementById(modalId);
  if (existingModal) {
    existingModal.remove();
  }

  const modal = document.createElement("div");
  modal.id = modalId;
  modal.classList.add("modal", "fade");
  modal.setAttribute("tabindex", "-1");
  modal.setAttribute("role", "dialog");
  modal.setAttribute("aria-labelledby", "delete-redirect-modal-label");
  modal.setAttribute("aria-hidden", "true");

  modal.insertAdjacentHTML(
    "beforeend",
    `<div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="delete-redirect-modal-label">Eliminar Redirección</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>¿Estás seguro de que deseas eliminar esta redirección?</p>
        </div>
        <div class="modal-footer">
        <button type="button" class="btn btn-barymont-red" id="confirm-delete-redirect-btn"><i class="fas fa-trash mr-2"></i>Eliminar</button>
        <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cancelar</button>
        </div>
      </div>
    </div>`
  );

  document.body.appendChild(modal);

  $(`#${modalId}`).modal("show");

  document.getElementById("confirm-delete-redirect-btn").addEventListener("click", async function () {
    showLoadingButton(this.id, "btn-barymont-red");

    try {
      const response = await fetchDeleteRedirect(redirectId);
      $(`#${modalId}`).modal("hide");

      if (response.status === 200) {
        createToast("success", "Redirección eliminada", response.data.message, 2000);
        $("#" + datatableId)
          .DataTable()
          .ajax.reload();
      } else {
        const errorMessage = response.status === 404 ? "La redirección que estás intentando eliminar no existe." : "Ha ocurrido un error inesperado, contacte con soporte.";
        createToast("error", "Error ❌", errorMessage, 5000);
      }
    } catch (error) {
      console.error("Error al eliminar la redirección:", error);
      createToast("error", "Error ❌", "Ha ocurrido un error al intentar eliminar la redirección. Inténtelo de nuevo más tarde.", 5000);
    } finally {
      updateButtonLabel(this.id, '<i class="fas fa-trash mr-2"></i>Eliminar', "btn-barymont-red");
    }
  });

  $(`#${modalId}`).on("hidden.bs.modal", function () {
    document.getElementById(modalId).remove();
  });
}

async function fetchRedirectData(redirectId) {
  return fetch(`/api/news/redirect/${redirectId}`).then((response) => response.json());
}

async function openCreateUpdateRedirectModal(mode, datatableId, redirectId = null) {
  const VALID_MODES = ["create", "update"];
  const MODAL_ID = "create-redirect-modal";
  const FORM_ID = "create-redirect-form";
  const REDIRECT_CODE_ID = "create-redirect-redirect-code";
  const REDIRECT_URL_ID = "create-redirect-redirect-url";
  const NEW_REDIRECT_URL_ID = "create-redirect-new-redirect-url";
  const OBSERVATION_ID = "create-redirect-observation";
  const EXPIRES_AT_ID = "create-redirect-expires-at";
  const IS_ENABLED_ID = "create-redirect-isEnabled";
  const CONFIRM_BUTTON_ID = "confirm-create-update-redirect-btn";

  if (mode === null || mode === "" || mode === undefined) {
    console.error("Modo no especificado para el modal de creación/actualización de redirecciones.");
    return;
  }

  if (!VALID_MODES.includes(mode)) {
    console.error("Modo inválido para el modal de creación/actualización de redirecciones.");
    return;
  }

  const existingModal = document.getElementById(MODAL_ID);
  if (existingModal) {
    existingModal.remove();
  }

  let redirectData = null;

  if (mode === "update") {
    if (!redirectId) {
      createToast("error", "Error ❌", "Por favor, selecciona una redirección para actualizar.", 5000);
      return;
    }
    const response = await fetchRedirectData(redirectId);
    redirectData = response.redirect;
  }

  const modal = document.createElement("div");
  modal.id = MODAL_ID;
  modal.classList.add("modal", "fade");
  modal.setAttribute("tabindex", "-1");
  modal.setAttribute("role", "dialog");
  modal.setAttribute("aria-labelledby", "create-redirect-modal-label");
  modal.setAttribute("aria-hidden", "true");

  modal.innerHTML = `
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="create-redirect-modal-label">${mode === "create" ? "Crear" : "Actualizar"} Redirección</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form id="${FORM_ID}">
            <div class="form-group">
              <label for="${REDIRECT_CODE_ID}">Código de Redirección <span class="text-danger">*</span></label>
              <select class="form-control selectpicker" id="${REDIRECT_CODE_ID}" required data-live-search="true"></select>
            </div>
            <div class="form-group">
              <label for="${REDIRECT_URL_ID}">URL de Redirección <span class="text-danger">*</span></label>
              <input type="url" class="form-control" id="${REDIRECT_URL_ID}" required>
              <div class="invalid-feedback">Por favor, introduzca una URL válida.</div>
            </div>
            <div class="form-group">
              <label for="${NEW_REDIRECT_URL_ID}">Nueva URL</label>
              <input type="url" class="form-control" id="${NEW_REDIRECT_URL_ID}">
              <div class="invalid-feedback">Por favor, introduzca una URL válida.</div>
            </div>
            <div class="form-group">
              <label for="${OBSERVATION_ID}">Observaciones</label>
              <textarea class="form-control" id="${OBSERVATION_ID}"></textarea>
            </div>
            <div class="form-group">
              <label for="${EXPIRES_AT_ID}">Fecha de Expiración</label>
              <input type="datetime-local" class="form-control" id="${EXPIRES_AT_ID}">
            </div>
            <div class="form-group">
              <input type="checkbox" id="${IS_ENABLED_ID}" class="triggerDatatableUpdateTasks" data-toggle="toggle" data-on="Redirección activa" data-off="Redirección inactiva" data-onstyle="success" data-offstyle="secondary" data-width="100%" data-height="32" checked>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-barymont-red" id="${CONFIRM_BUTTON_ID}">${mode === "create" ? "Crear Redirección" : "Actualizar Redirección"}</button>
          <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cancelar</button>
        </div>
      </div>
    </div>
  `;

  document.body.appendChild(modal);

  if (mode === "update" && redirectData) {
    setModalValues(redirectData, REDIRECT_URL_ID, NEW_REDIRECT_URL_ID, OBSERVATION_ID, EXPIRES_AT_ID, IS_ENABLED_ID);
  }

  await loadRedirectCodesOptions(REDIRECT_CODE_ID, redirectData ? redirectData.redirectCode : null);

  $(`#${IS_ENABLED_ID}`).bootstrapToggle();

  document.getElementById(CONFIRM_BUTTON_ID).addEventListener("click", async function () {
    showLoadingButton(CONFIRM_BUTTON_ID, "btn-barymont-red");

    document.getElementById(REDIRECT_URL_ID).value = document.getElementById(REDIRECT_URL_ID).value.trim();
    document.getElementById(NEW_REDIRECT_URL_ID).value = document.getElementById(NEW_REDIRECT_URL_ID).value.trim();

    const redirectUrl = document.getElementById(REDIRECT_URL_ID);
    const newRedirectUrl = document.getElementById(NEW_REDIRECT_URL_ID);
    const redirectCode = document.getElementById(REDIRECT_CODE_ID).value;

    const validation = validateRedirectData(redirectUrl, newRedirectUrl, redirectCode);

    if (validation.isValid === false) {
      createToast("error", "Error ❌", validation.message, 5000);
      updateButtonLabel(CONFIRM_BUTTON_ID, mode === "create" ? "Crear Redirección" : "Actualizar Redirección", "btn-barymont-red");
      return;
    }

    const data = {
      newsRedirectId: redirectData ? redirectData.newsRedirectId : null,
      redirectCode: redirectCode,
      redirectUrl: redirectUrl.value,
      isEnabled: document.getElementById(IS_ENABLED_ID).checked ? 1 : 0,
      newRedirectUrl: newRedirectUrl.value || null,
      observation: document.getElementById(OBSERVATION_ID).value || null,
      expiresAt: document.getElementById(EXPIRES_AT_ID).value || null,
    };

    try {
      let response = null;

      if (mode === "update") {
        response = await updateRedirect(data);

        if (response.status === 200) {
          createToast("success", "Redirección actualizada", response.data.message, 2000);
          $(`#${MODAL_ID}`).modal("hide");
        } else {
          if (response.data.error === "Url already exists") {
            createToast("error", "Error al actualizar ❌", "La URL de redirección ya existe", 5000);
          } else {
            createToast("error", "Error al actualizar ❌", response.data.message, 5000);
          }
        }
      }

      if (mode === "create") {
        response = await createRedirect(data);

        if (response.status === 201) {
          createToast("success", "Redirección creada", response.data.message, 2000);
          $(`#${MODAL_ID}`).modal("hide");
        } else {
          createToast("error", "Error al crear ❌", response.data.message, 5000);
        }
      }

      $("#" + datatableId)
        .DataTable()
        .ajax.reload();

      if (!VALID_MODES.includes(mode)) {
        throw new Error("Modo inválido para el modal de creación/actualización de redirecciones.");
      }

      updateButtonLabel(this.id, mode === "create" ? "Crear redirección" : "Actualizar redirección", "btn-barymont-red");
    } catch (error) {
      console.error("Error al crear la redirección:", error);
      createToast("error", "Error ❌", "Ha ocurrido un error al intentar crear la redirección.", 5000);
    }
  });

  $(`#${MODAL_ID}`).on("hidden.bs.modal", function () {
    document.getElementById(MODAL_ID).remove();
  });

  $(`#${MODAL_ID}`).modal("show");
}

function setModalValues(redirectData, REDIRECT_URL_ID, NEW_REDIRECT_URL_ID, OBSERVATION_ID, EXPIRES_AT_ID, IS_ENABLED_ID) {
  document.getElementById(REDIRECT_URL_ID).value = redirectData.redirectUrl ?? "";
  document.getElementById(NEW_REDIRECT_URL_ID).value = redirectData.newRedirectUrl ?? "";
  document.getElementById(OBSERVATION_ID).value = redirectData.observation ?? "";
  document.getElementById(EXPIRES_AT_ID).value = redirectData.expiresAt ?? "";
  document.getElementById(IS_ENABLED_ID).checked = redirectData.isEnable ?? false;

  $(`#${IS_ENABLED_ID}`).bootstrapToggle("destroy");
  $(`#${IS_ENABLED_ID}`).bootstrapToggle();
}

function validateUrlInput(inputElement) {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocolo opcional
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // dominio
      "((\\d{1,3}\\.){3}\\d{1,3})|" + // o dirección IP (v4)
      "\\[([a-f\\d]{0,4}:){1,7}[a-f\\d]{0,4}\\])" + // o dirección IP (v6)
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // puerto y ruta opcionales
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // cadena de consulta opcional
      "(\\#[-a-z\\d_]*)?$", // fragmento opcional
    "i"
  );

  if (!urlPattern.test(inputElement.value)) {
    inputElement.classList.add("is-invalid");
    return false;
  } else {
    inputElement.classList.remove("is-invalid");
    return true;
  }
}

async function createRedirect(data) {
  try {
    const response = await fetch("/api/news/redirect", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    return { status: response.status, data: responseData };
  } catch (error) {
    console.error("Error durante la solicitud:", error);
    return { status: 500, data: { message: "Error en la solicitud." } };
  }
}

function validateRedirectData(redirectUrl, newRedirectUrl, redirectCode) {
  if (!validateUrlInput(redirectUrl) || (newRedirectUrl.value && !validateUrlInput(newRedirectUrl))) {
    return {
      isValid: false,
      message: "Por favor, introduce URLs válidas.",
    };
  }

  if (redirectCode.startsWith("3") && !newRedirectUrl.value) {
    return {
      isValid: false,
      message: "Por favor, introduce una nueva URL para redirecciones 3XX.",
    };
  }

  if (redirectCode.startsWith("4") && newRedirectUrl.value) {
    return {
      isValid: false,
      message: "Las redirecciones 4XX no pueden tener una nueva URL",
    };
  }

  return { isValid: true };
}

async function updateRedirect(data) {
  try {
    const response = await fetch(`/api/news/redirect/${data.newsRedirectId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    return { status: response.status, data: responseData };
  } catch (error) {
    console.error("Error durante la solicitud:", error);
    return { status: 500, data: { message: "Error en la solicitud." } };
  }
}

async function obtainRedirectCodesData() {
  redirectCodesData = await fetchRedirectCodes();
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname === "/herramientas/noticias/redirecciones") {
      obtainRedirectCodesData().then(() => {
        loadRedirectCodesOptions("redirect-code-select");
        loadNewsRedirectDatatable("news-redirect-table");
      });
    }
  });
}

export {};
