"use strict";

var current_user_qbis_data = [];
var current_user_qbis_data_categories = [];

function showContainerDataById(id) {
  $("#load-" + id).addClass("d-none");
  $("#data-" + id).removeClass("d-none");
}

function paintUserBusinessIndexCategories() {
  $("#data-user-business-index-data").html(`
      <div id="business-index-categories" class="col-12 d-flex justify-content-center align-items-center mb-5"></div>
      <div class="container-full">
        <div id="business-index-content-data" class="row"></div>
      </div>
    `);

  current_user_qbis_data_categories.forEach((category) => {
    $("#business-index-categories").append(`
      <button id="btn-business-index-${category.value}" class="btn btn-lg btn-barymont-grey w-100" type="button">${category.label}</button>
    `);

    document.getElementById(`btn-business-index-${category.value}`).addEventListener("click", function () {
      paintUserBusinessIndexByCategory(category.value);
    });
  });

  if ($("#business-index-category").val() !== "") {
    const currentCategoryValue = $("#business-index-category").val();
    $(`#btn-business-index-${currentCategoryValue}`).addClass("btn-barymont-red").removeClass("btn-barymont-grey");
  }
}

function paintUserBusinessIndex() {
  if (current_user_qbis_data.length === 0) {
    $("#business-index-content-data").html(`
    <div class="d-flex justify-content-center align-items-center" data-toggle="tooltip" data-placement="bottom" title="No se han podido calcular los índices de negocio para este usuario, porque no cumple los requisitos mínimos">
      <div class="d-block py-3">
        <div class="text-center">
          <i class="fas fa-info-circle fa-lg text-barymont-red pr-2"></i> No disponible
        </div>
      </div>
    </div>
    `);
    return;
  }

  $("#business-index-content-data").html("");

  current_user_qbis_data.forEach((businessIndexData) => {
    $(`#business-index-content-data`).append(`
      <div class="col-12 col-lg-4 col-md-6 d-flex justify-content-center align-items-center mb-5">
        <div class="bg-barymont-white border border-barymont-dark-grey text-dark scroll-barymont-red h-100 shadow-sm w-100">
          <div class="text-center mb-4 bg-barymont-grey p-2">
            <span class="h5 font-weight-bold">${businessIndexData.typeName}</span>
            <a tabindex="0" class="text-barymont-red float-right" style="cursor:pointer;" data-toggle="popover" data-html="true" data-trigger="focus" title="Información sobre el índice" data-content="<strong>Descripción:</strong><br>${businessIndexData.typeDescription}<br><br><strong>Formula:</strong><br>${businessIndexData.typeCalculationFormula}">
              <i class="fas fa-info-circle fa-lg text-barymont-red"></i>
            </a>
          </div>
          <div id="${businessIndexData.type}"></div>
        </div>
      </div>
      `);
  });

  current_user_qbis_data.forEach((businessIndexData) => {
    if (businessIndexData.userIndex === undefined) {
      $(`#${businessIndexData.type}`).html(`
        <div class="d-flex justify-content-center align-items-center" data-toggle="tooltip" data-placement="bottom" title="No se ha podido calcular el índice de negocio para este usuario, porque no cumple los requisitos mínimos">
          <div class="d-block py-3">
            <div class="text-center">
              <i class="fas fa-info-circle fa-lg text-barymont-red pr-2"></i> No disponible
            </div>
          </div>
        </div>
        `);
      return;
    }

    new JustGage({
      id: businessIndexData.type,
      customSectors: {
        percents: true,
        ranges: businessIndexData.rangesPercentsAndColors.map((range) => {
          return {
            color: range.rangeColor,
            lo: range.lower,
            hi: range.higer,
          };
        }),
      },
      value: businessIndexData.userIndex / 100,
      label: businessIndexData.typeNameShort,
      min: 0,
      max: 100,
      decimals: 2,
      gaugeWidthScale: 0.6,
      counter: true,
      labelMinFontSize: 12,
      valueMinFontSize: 14,
      minLabelMinFontSize: 12,
      maxLabelMinFontSize: 12,
      symbol: "%",
      humanFriendly: true,
      humanFriendlyDecimal: 2,
      formatNumber: true,
      showInnerShadow: true,
      shadowOpacity: 0.2,
      shadowSize: 5,
      shadowVerticalOffset: 3,
      pointer: true,
      pointerOptions: {
        toplength: -15,
        bottomlength: 10,
        bottomwidth: 12,
        color: "#8e8e93",
        stroke: "#ffffff",
        stroke_width: 3,
        stroke_linecap: "round",
      },
    });
  });

  $('[data-toggle="popover"]').popover();
  $('[data-toggle="tooltip"]').tooltip();
}

async function paintUserBusinessIndexByCategory(categoryValue) {
  $("#business-index-category").val(categoryValue);

  $('button[id^="btn-business-index-"]').addClass("btn-barymont-grey").removeClass("btn-barymont-red");
  $(`#btn-business-index-${categoryValue}`).addClass("btn-barymont-red").removeClass("btn-barymont-grey");

  current_user_qbis_data = await getUserBusinessIndexByCategory($("#user-id").val(), categoryValue).then((response) => Object.values(response.data));
  paintUserBusinessIndex();
}

function paintDefaultStatusOfModal(categorySelectedByDefault) {
  $("#business-index-category").val(categorySelectedByDefault);
  $('button[id^="btn-business-index-"]').addClass("btn-barymont-grey").removeClass("btn-barymont-red");
  $(`#btn-business-index-${categorySelectedByDefault}`).addClass("btn-barymont-red").removeClass("btn-barymont-grey");
}

// Es necesario resetear este modal asi porque gauges no se renderizan bien si el div donde pintamos esta oculto
function resetBusinesIndexModalData() {
  $("#data-user-business-index-data").html(`
      <div class="d-flex justify-content-center my-3">
          <div class="spinner-border text-barymont-red" role="status">
              <span class="sr-only">Loading...</span>
          </div>
      </div>
    `);
}

async function showModalUserBusinessIndex() {
  resetBusinesIndexModalData();
  $("#modal-user-business-index-data").modal("show");

  $("#modal-user-business-index-data").on("shown.bs.modal", async function () {
    current_user_qbis_data_categories = await getUserBusinessIndexCategories().then((response) => Object.values(response.data));

    current_user_qbis_data = await getUserBusinessIndexByCategory($("#user-id").val(), current_user_qbis_data_categories[0].value).then((response) => Object.values(response.data));

    paintUserBusinessIndexCategories();
    paintUserBusinessIndex();
    paintDefaultStatusOfModal(current_user_qbis_data_categories[0].value);

    $(this).off("shown.bs.modal");
    showContainerDataById("user-business-index-data");
  });
}

async function getUserBusinessIndexCategories() {
  let requestOptions = {
    method: "POST",
    redirect: "follow",
  };

  return await fetch("/herramientas/user-business-index/ajax/getBusinessIndexCategories", requestOptions).then((response) => response.json());
}

async function getUserBusinessIndexByCategory(userId, categoryValue) {
  let formData = new FormData();

  formData.append("userId", userId);
  formData.append("categoryValue", categoryValue);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/user-business-index/ajax/getUserBusinessIndexByCategory", requestOptions).then((response) => response.json());
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname.includes("/herramientas/user-management/")) {
      document.getElementById("business-index-btn")?.addEventListener("click", showModalUserBusinessIndex);

      window.onresize = function () {
        if ($("#modal-user-business-index-data").hasClass("show")) {
          document.getElementById("data-user-business-index-data").innerHTML = "";
          paintUserBusinessIndexCategories();
          paintUserBusinessIndex();
        }
      };
    }
  });
}

export { showModalUserBusinessIndex };
