"use strict";

import Sortable from "sortablejs";
import { asingUserToCompletionCheck, assignCompletionChecksChecklists, createChecklist, createCompletionCheck, deleteChecklist, deleteCompletionCheck, editChecklist, orderChecklistCompletionChecks, orderEntityChecklists, searchChecklists, updateChecklistNotification, updateCompletionCheck } from "../../api/checklist";
import { readChecklistTemplates } from "../../api/checklist_management.js";
import { searchUsers } from "../../api/users.js";
import { escapeHtml, formatDateTime, showLoadingButton, updateButtonLabel } from "../shared/shared.js";

var USER_LIST_OF_ACTIVES_USERS = null;
const MAX_OPTION_ON_SELECTPICKER = 10;
const CACHE_EXPIRATION_TIME = 30 * 60 * 1000;
const LOCAL_STORAGE_ACCORDION_KEY = "CHECKLIST_ACCORDION_STATES";

function saveAccordionStates(entityId) {
  let storage = localStorage.getItem(LOCAL_STORAGE_ACCORDION_KEY);
  let allStates = [];

  if (storage !== null) {
    let localStorageData = JSON.parse(storage);
    if (localStorageData.time < new Date().getTime() - CACHE_EXPIRATION_TIME) {
      localStorage.removeItem(LOCAL_STORAGE_ACCORDION_KEY);
    } else {
      allStates = localStorageData.data;
    }
  }

  allStates = allStates.filter((entry) => entry.entityId !== entityId);

  const checklistsState = [];
  const accordionElements = document.querySelectorAll(`[id^="accordion-checklist-"]`);
  accordionElements.forEach((accordion) => {
    const checklistId = accordion.id.replace("accordion-checklist-", "");
    const collapseElem = document.getElementById(`collapse-${checklistId}`);
    const isOpen = collapseElem && collapseElem.classList.contains("show");

    checklistsState.push({ checklistId, open: isOpen });
  });

  allStates.push({ entityId, checklists: checklistsState });

  localStorage.setItem(
    LOCAL_STORAGE_ACCORDION_KEY,
    JSON.stringify({
      time: new Date().getTime(),
      data: allStates,
    })
  );
}

function getAccordionState(entityId, checklistId) {
  let storage = localStorage.getItem(LOCAL_STORAGE_ACCORDION_KEY);
  if (storage === null) {
    return true;
  }

  let localStorageData = JSON.parse(storage);

  if (localStorageData.time < new Date().getTime() - CACHE_EXPIRATION_TIME) {
    return true;
  }

  let allStates = localStorageData.data;
  let entityState = allStates.find((entry) => entry.entityId === entityId);
  if (!entityState) {
    return true;
  }
  let checklistState = entityState.checklists.find((item) => item.checklistId === checklistId);
  return checklistState ? checklistState.open : true;
}

function obtainChecklistDataFromList({ containerId, entityRelationalType, datatableId = null }) {
  const container = document.getElementById(containerId);
  if (!container) {
    return;
  }

  container.addEventListener("click", async (e) => {
    const button = e.target.closest(".checklist-btn");
    if (!button) {
      return;
    }

    const previousBtnHtml = button.innerHTML;

    showLoadingButton(button.id, "btn-barymont-grey", true);

    await openChecklistModal({
      entityRelationalType: entityRelationalType,
      entityId: button.dataset.entityId,
      buttonId: button.id,
      datatableId: datatableId,
    });

    updateButtonLabel(button.id, previousBtnHtml, "btn-barymont-grey");
  });
}

async function openChecklistModal({ entityRelationalType, entityId, datatableId = null }) {
  if (USER_LIST_OF_ACTIVES_USERS === null) {
    USER_LIST_OF_ACTIVES_USERS = await searchUsers([], [], null, null, null, null, []);
  }

  const hasManagePermission = AppGbSession.checkUserHasPermission("ChecklistTool:ChecklistToolManageAccessChecker");

  const modalElement = await loadChecklistModal({
    usersWithVisibility: USER_LIST_OF_ACTIVES_USERS,
    isAdmin: false,
    entityId: entityId,
    entityRelationalType: entityRelationalType,
    datatableId: datatableId,
    hasManagePermission: hasManagePermission,
  });

  if (modalElement.dataset.viewMode === "admin") {
    initializeAddChecklistToEntityListeners(modalElement);
    initializeChecklistDeleteListeners(modalElement);
    initializeCompletionCheckDeleteListeners(modalElement);
    initializeChecklistObjectiveDateEditors(modalElement);
    initializeChecklistTitleEditors(modalElement);
    initializeChecklistDescriptionEditors(modalElement);
    initializeChecklistTaskEditors(modalElement);
    initializeUserListSelects({ modalElement: modalElement, selectorId: "completion-check-assign-user" });
  } else {
    initializeUserAsingButtonListeners(modalElement);
  }

  initializeCompletionCheckStateButtons(modalElement);
  updateToggleButtonText(modalElement);
}

// #__________ Funciones auxiliares __________//
function generateUserSelect({ item = {}, checklist = {}, selectId = null }) {
  let selectGeneratedId = selectId;
  if (selectGeneratedId === null) {
    selectGeneratedId = item.completionCheckId ? `completion-check-assign-user-${item.completionCheckId}` : "completion-check-assign-user";
  }

  const prependId = `prepend-user-assign-${selectGeneratedId}`;

  const commonAttributes = `
      id="${selectGeneratedId}"
      class="form-control selectpicker show-tick modal-selectpicker py-0"
      data-container="body"
      data-item-id="${item.completionCheckId}"
      data-checklist-id="${checklist.checklistId}"
      data-allow-clear="true"
      title="Seleccione el usuario..."
      data-max-options="1"
      data-live-search="true"
      data-size="10"
      data-dropup-auto="true"
      data-live-search-placeholder="Introduce 3 caracteres para buscar un usuario"
      data-none-results-text="No se encontraron usuarios que coincidan con '{0}'"
      multiple
  `.trim();

  let selectHtml = "";
  if (item.assignedUserId) {
    const userData = USER_LIST_OF_ACTIVES_USERS.find((user) => user.userId === item.assignedUserId);
    if (userData) {
      const userProfileBadge = `<span class="badge bg-${userData.userProfile.backgroundColor} text-${userData.userProfile.textColor} font-weight-bold">${userData.userProfile.label}</span>`;
      const dataContent = `<img src="${userData.avatarPath}" width="20" height="20" alt="User Image - ${userData.userId}" class="rounded-circle mr-2">
                           ${userData.userName ?? userData.name} - ${userProfileBadge}`;
      selectHtml = `<select ${commonAttributes}>
                      <option value="${userData.userId}" data-content='${dataContent}' selected>
                        ${userData.userName ?? userData.name}
                      </option>
                    </select>`;
    }
  } else {
    selectHtml = `<select ${commonAttributes}></select>`;
  }

  return `
    <div class="form-group mb-0">
      <div class="input-group">
        <div class="input-group-prepend" id="${prependId}" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Usuario asignado">
          <span class="input-group-text"><i class="fas fa-user text-dark"></i></span>
        </div>
        ${selectHtml}
      </div>
    </div>
  `;
}

function renderProgressBar(completedCompletionChecks, totalCompletionChecks) {
  const percentage = Math.round((completedCompletionChecks / totalCompletionChecks) * 100);

  return `
    <div class="progress my-2 my-sm-0" style="height: 23px; position: relative;">
      <div class="progress-bar bg-success"
           role="progressbar"
           style="width: ${percentage}%; transition: width 0.6s ease; height: 100%;"
           aria-valuenow="${percentage}"
           aria-valuemin="0"
           aria-valuemax="100">
      </div>
      <div class="d-flex justify-content-center align-items-center"
           style="position: absolute; top: 0; left: 0; right: 0; bottom: 0;">
        <span class="${percentage > 55 ? "text-white" : "text-dark"}" style="font-size: 14px;">
          <b>${completedCompletionChecks} / ${totalCompletionChecks} - ${percentage}%</b>
        </span>
      </div>
    </div>`;
}

function initializeUpdateIconsForAccordeons(modalElement) {
  $(document).on("shown.bs.collapse", ".accordion .collapse", function () {
    $(`[data-target="#${this.id}"]`).find("i").removeClass("fa-chevron-down").addClass("fa-chevron-up");

    const entityId = document.getElementById("checklist-entity-id") ? document.getElementById("checklist-entity-id").value : "";
    if (entityId) {
      saveAccordionStates(entityId);
    }

    updateToggleButtonText(modalElement);
    updateDragHandleVisibility(modalElement);
  });

  $(document).on("hidden.bs.collapse", ".accordion .collapse", function () {
    $(`[data-target="#${this.id}"]`).find("i").removeClass("fa-chevron-up").addClass("fa-chevron-down");

    const entityId = document.getElementById("checklist-entity-id") ? document.getElementById("checklist-entity-id").value : "";
    if (entityId) {
      saveAccordionStates(entityId);
    }

    updateToggleButtonText(modalElement);
    updateDragHandleVisibility(modalElement);
  });
}

function initializeSortableForChecklistsCompletionChecksOnAccordionEvents(modalElement) {
  $(".accordion").on("shown.bs.collapse", function (event) {
    const checklistId = event.target.id.replace("collapse-", "");

    const tableBody = document.querySelector(`.card-body[data-checklist-id="${checklistId}"]`);

    instaciateSotableOnChecklistCompletionChecks(tableBody, checklistId);

    updateChecklistsContainerSortable(modalElement);
    updateToggleButtonText(modalElement);
  });

  $(".accordion").on("hidden.bs.collapse", function (event) {
    const checklistId = event.target.id.replace("collapse-", "");
    const tableBody = document.querySelector(`.card-body[data-checklist-id="${checklistId}"]`);
    if (tableBody && tableBody.sortableInstance) {
      tableBody.sortableInstance.destroy();
      tableBody.sortableInstance = null;
    }

    updateChecklistsContainerSortable(modalElement);
    updateToggleButtonText(modalElement);
  });
}

function initializeToggleAcordeonsButton(modalElement) {
  const btn = modalElement.querySelector("#btn-all-accordions-actions");

  if (btn.toggleAccordeonFunction) {
    btn.removeEventListener("click", btn.toggleAccordeonFunction);
  }

  btn.toggleAccordeonFunction = function () {
    const collapses = modalElement.querySelectorAll(".collapse");
    let anyOpen = false;
    collapses.forEach((collapse) => {
      if (collapse.classList.contains("show")) {
        anyOpen = true;
      }
    });

    if (anyOpen) {
      collapses.forEach((collapse) => {
        $(collapse).collapse("hide");
      });
    } else {
      collapses.forEach((collapse) => {
        $(collapse).collapse("show");
      });
    }

    setTimeout(() => updateToggleButtonText(modalElement), 350);
  };

  btn.addEventListener("click", btn.toggleAccordeonFunction);
}

function initializeSortablesForChecklistCompletionChecksOnFirstLoad(modalElement) {
  document.querySelectorAll(".collapse.show").forEach((collapseElement) => {
    const checklistId = collapseElement.id.replace("collapse-", "");
    const checklistContainer = collapseElement.querySelector(`.card-body[data-checklist-id="${checklistId}"]`);

    instaciateSotableOnChecklistCompletionChecks(checklistContainer, checklistId);
  });

  updateChecklistsContainerSortable(modalElement);
}

function instaciateSotableOnChecklistCompletionChecks(tableBody, checklistId) {
  if (tableBody && !tableBody.sortableInstance) {
    tableBody.sortableInstance = Sortable.create(tableBody, {
      draggable: ".card-checklist-completion-check",
      forceFallback: true,
      animation: 150,
      handle: ".drag-handle",
      ghostClass: "sortable-ghost",
      onEnd: function () {
        const updatedOrder = Array.from(tableBody.querySelectorAll(".card-checklist-completion-check")).map((row, index) => ({
          checklistCompletionCheckId: row.dataset.itemId,
          order: index,
        }));
        updateChecklistCompletionChecksOrder(checklistId, updatedOrder);
      },
    });
  }
}

function updateDragHandleVisibility(modalElement) {
  const accordions = modalElement.querySelectorAll(".accordion");
  let anyOpen = false;

  accordions.forEach((accordion) => {
    const collapseElem = accordion.querySelector(".collapse");
    if (collapseElem && collapseElem.classList.contains("show")) {
      anyOpen = true;
    }
  });

  const dragHandles = modalElement.querySelectorAll(".checklist-drag-handle");
  dragHandles.forEach((handle) => {
    if (anyOpen) {
      handle.classList.add("d-none");
    } else {
      handle.classList.remove("d-none");
    }
  });
}

function updateToggleButtonText(modalElement) {
  const toggleBtn = modalElement.querySelector("#toggle-checklist-view");
  if (toggleBtn) {
    const isAdminView = modalElement.dataset.viewMode === "admin";
    toggleBtn.setAttribute("data-content", isAdminView ? "Cambiar a vista usuario" : "Cambiar a vista administrador");
    $(toggleBtn).popover("dispose").popover();
  }

  const collapses = modalElement.querySelectorAll(".collapse");
  const toggleButton = modalElement.querySelector("#btn-all-accordions-actions");

  if (collapses.length === 0) {
    toggleButton.classList.add("d-none");
    return;
  } else {
    toggleButton.classList.remove("d-none");
  }

  let anyOpen = false;

  collapses.forEach((collapse) => {
    if (collapse.classList.contains("show")) {
      anyOpen = true;
    }
  });

  if (anyOpen) {
    toggleButton.textContent = "Contraer checklists";
  } else {
    toggleButton.textContent = "Desplegar checklist";
  }
}

function updateChecklistsContainerSortable(modalElement) {
  let allClosed = true;

  modalElement.querySelectorAll(".accordion").forEach((accordion) => {
    const checklistId = accordion.id.replace("accordion-checklist-", "");
    const collapseElem = document.getElementById(`collapse-${checklistId}`);
    if (collapseElem && collapseElem.classList.contains("show")) {
      allClosed = false;
    }
  });

  const modalBody = modalElement.querySelector(".modal-body");
  if (allClosed) {
    if (!modalElement.checklistsSortableInstance) {
      modalElement.checklistsSortableInstance = Sortable.create(modalBody, {
        animation: 150,
        handle: ".checklist-drag-handle",
        ghostClass: "sortable-ghost",
        onEnd: function () {
          updateEntityChecklistsOrder(modalElement);
        },
      });
    }
  } else {
    if (modalElement.checklistsSortableInstance) {
      modalElement.checklistsSortableInstance.destroy();
      modalElement.checklistsSortableInstance = null;
    }
  }
}

function updateUserSelectOptions(selectId, filterActives = true, searchQuery = "") {
  const currentSelect = document.getElementById(selectId);
  const $select = $(`#${selectId}`);
  const selectedValue = currentSelect.getAttribute("data-current-assigned") || currentSelect.value;
  const maxOptions = 10;

  currentSelect.innerHTML = "";

  let list = USER_LIST_OF_ACTIVES_USERS;
  if (filterActives) {
    list = list.filter((user) => user.active);
  }
  if (searchQuery && searchQuery.length >= 3) {
    list = list.filter((user) => {
      const name = (user.userName || user.name).toLowerCase();
      return name.includes(searchQuery.toLowerCase());
    });
  }

  let selectedUser = list.find((user) => user.userId === selectedValue);

  if (!selectedUser && selectedValue) {
    selectedUser = USER_LIST_OF_ACTIVES_USERS.find((user) => user.userId === selectedValue);
  }

  if (selectedUser) {
    const userProfileBadge = `<span class="badge bg-${selectedUser.userProfile.backgroundColor} text-${selectedUser.userProfile.textColor} font-weight-bold">${selectedUser.userProfile.label}</span>`;
    const dataContent = `<img src="${selectedUser.avatarPath}" width="20" height="20" alt="User Image - ${selectedUser.userId}" class="rounded-circle mr-2">
                         <span>${selectedUser.userName ?? selectedUser.name}</span> ${userProfileBadge}`;

    const option = document.createElement("option");

    option.value = selectedUser.userId;
    option.setAttribute("data-content", dataContent);
    option.selected = true;
    currentSelect.appendChild(option);
  }

  let count = selectedUser ? 1 : 0;

  for (let i = 0; i < list.length && count < maxOptions; i++) {
    const user = list[i];
    if (user.userId === selectedValue) continue;
    const option = document.createElement("option");
    option.value = user.userId;
    option.setAttribute(
      "data-content",
      `<img src="${user.avatarPath}" width="20" height="20" alt="User Image - ${user.userId}" class="rounded-circle mr-2">
       <span>${user.name}</span>
       <span class="badge bg-${user.userProfile.backgroundColor} text-${user.userProfile.textColor} ml-2">
         ${user.userProfile.label}
       </span>`
    );
    currentSelect.appendChild(option);
    count++;
  }

  $select.val(selectedValue);
  $select.selectpicker("refresh");
}

function initializeSuscriptionEvents(modalElement) {
  modalElement.querySelectorAll(".btn-suscribe-notification").forEach((element) =>
    element.addEventListener("click", async function (e) {
      const btn = e.currentTarget;
      const checklistId = btn.dataset.checklistId;
      const isSuscribed = btn.dataset.isSuscribed !== "true";

      if (btn.getAttribute("aria-describedby")) {
        $(btn).popover("dispose");
      }

      try {
        await updateSuscriptionNotificationChecklist(checklistId, isSuscribed, AppGbSession.getSessionData().userId);

        btn.dataset.isSuscribed = isSuscribed ? "true" : "false";
        btn.innerHTML = isSuscribed ? "<i class='fas fa-bell-slash' style='pointer-events: none;'></i>" : "<i class='fas fa-bell' style='pointer-events: none;'></i>";
        btn.classList.remove(isSuscribed ? "btn-barymont-red" : "btn-barymont-black");
        btn.classList.add(isSuscribed ? "btn-barymont-black" : "btn-barymont-red");
        btn.setAttribute("data-content", isSuscribed ? "Desactivar notificaciones de cercanía a la fecha objetivo o al completar la checklist" : "Activar notificaciones de cercanía a la fecha objetivo o al completar la checklist");
        createToast("success", isSuscribed ? "Notificaciones activadas" : "Notificaciones desactivadas", "Las notificaciones se han actualizado correctamente");
        $('[data-toggle="popover"]').popover();
      } catch (error) {
        createToast("error", error.message);
      }
    })
  );
}

// __________ Funciones de renderizado de checklists ADMIN __________//
function renderChecklistItemAdmin(item, checklist) {
  const usuarioAsignado = generateUserSelect({ item: item, checklist: checklist });
  let completedAtBadge = "";

  if (item.completedAt) {
    const userWhoCompleted = USER_LIST_OF_ACTIVES_USERS.find((user) => user.userId === item.completedByUserId);
    const userImage = userWhoCompleted
      ? `<img
          src="${userWhoCompleted.avatarPath}"
          width="35"
          height="35"
          alt="User Image - ${userWhoCompleted.userId}"
          class="rounded-circle mr-2"
          data-toggle="popover"
          data-trigger="hover focus"
          title="Completado por"
          data-content="${userWhoCompleted.userName ?? userWhoCompleted.name}"
        >`
      : "";

    let completedInTime = true;
    if (checklist.objectiveDate) {
      completedInTime = new Date(checklist.objectiveDate) > new Date(item.completedAt);
    }

    completedAtBadge = `
      <div class="d-flex align-items-center px-2" style="min-width: 225px;">
        ${userImage}
        <span
          class="badge badge-light w-100 d-flex justify-content-center align-items-center"
          style="font-size: 14px; height: 31px;"
          data-toggle="popover"
          data-trigger="hover focus"
          data-content="${completedInTime ? "Completado a tiempo" : "Completado fuera de tiempo"}"
          data-placement="top"
        >
          ${formatDateTime(item.completedAt)}
          ${completedInTime ? "" : "<i class='fa-solid fa-clock text-danger ml-2'></i>"}
        </span>
      </div>`;
  } else {
    completedAtBadge = `
      <div class="d-flex align-items-center px-2" style="min-width: 225px;">
        <span
          class="badge badge-light w-100 d-flex justify-content-center align-items-center"
          style="font-size: 14px; height: 31px;"
        >
          Pendiente
        </span>
      </div>`;
  }

  return `
    <div
      class="card shadow-sm card-checklist-completion-check mb-2"
      data-checklist-id="${checklist.checklistId}"
      data-item-id="${item.completionCheckId}"
    >

      <div class="card-body p-2">
        <div class="d-flex flex-wrap align-items-center">
          <div
            class="drag-handle text-center align-middle px-2 pt-2 mr-3"
            style="width: 20px; cursor: move;"
            title="Arrastra para reordenar">
              <i class="fas fa-grip-lines text-dark"></i>
          </div>
          <div class="checklist-item-description completion-check-template-tinymce-overwrite"
            data-checklist-id="${checklist.checklistId}"
            data-item-id="${item.completionCheckId}">
              ${item.description}
          </div>
        </div>

        <div class="d-flex flex-wrap flex-lg-nowrap align-items-center">
          <div class="order-1 order-lg-2 w-100 w-lg-auto mb-2 mb-lg-0 px-0 px-sm-2">
            ${usuarioAsignado}
          </div>

          <div class="order-2 order-lg-1" style="width:45px;">
            <button
              type="button"
              id="btn-completion-check-state-${item.completionCheckId}"
              data-completion-check-id="${item.completionCheckId}"
              data-checklist-id="${checklist.checklistId}"
              class="btn btn-completion-check-state p-0 bg-none"
              aria-pressed="${item.state}"
              title="${item.state ? "Marcar como pendiente" : "Marcar como completado"}"
              style="border-radius: 0; transition: background-color 0.3s ease; font-size: 20px; height:35px; width:35px;">
              ${item.state ? '<i class="fas fa-check-circle text-success"></i>' : '<i class="far fa-circle text-secondary"></i>'}
            </button>
          </div>

          <div class="order-3 flex-grow-1">
            ${completedAtBadge}
          </div>

          <div class="order-4" style="width:35px;">
            <button
              type="button"
              class="btn btn-barymont-black btn-delete-completion-check"
              title="Eliminar check"
              data-item-id="${item.completionCheckId}"
              data-checklist-id="${checklist.checklistId}"
              style="height:35px; width:35px;">
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
        </div>

        <input id="completion-check-id-${item.completionCheckId}" type="hidden" value="${item.completionCheckId}">
        <input id="completion-check-state-${item.completionCheckId}" type="hidden" value="${item.state}">
        <input id="completion-check-checklist-id-${item.completionCheckId}" type="hidden" value="${checklist.checklistId}">
        <input id="completion-check-description-${item.completionCheckId}" type="hidden" value="${escapeHtml(item.description)}">
        <input id="completion-check-assign-user-${item.completionCheckId}" type="hidden" value="${item.assignedUserId}">
        <input id="completion-check-completed-at-${item.completionCheckId}" type="hidden" value="${item.completedAt}">
      </div>
    </div>
  `;
}

function renderChecklistAdmin(checklist) {
  const entityId = document.getElementById("checklist-entity-id").value;
  const isOpen = getAccordionState(entityId, checklist.checklistId);
  const collapseClass = isOpen ? "show" : "";
  const ariaExpanded = isOpen ? "true" : "false";

  return `
    <div class="accordion pb-3" id="accordion-checklist-${checklist.checklistId}">
      <div class="card">

        <div class="card-header p-2" id="heading-${checklist.checklistId}">
          <div class="d-flex flex-wrap align-items-center">
            <div class="order-1 d-flex align-items-center flex-grow-1 px-2 w-100 w-sm-auto">

              <div class="checklist-drag-handle px-3" style="cursor: move;" data-checklist-id="${checklist.checklistId}">
                <i class="fas fa-grip-lines text-dark"></i>
              </div>

              <span
                id="checklist-title-${checklist.checklistId}"
                data-checklist-id="${checklist.checklistId}"
                class="checklist-title m-0 h5 px-2 w-100">
                ${checklist.title}
              </span>

              <button
                data-checklist-id="${checklist.checklistId}"
                data-is-suscribed="${checklist.isSuscribed}"
                type="button"
                class="btn btn-barymont-${checklist.isSuscribed ? "black" : "red"} btn-suscribe-notification"
                data-toggle="popover"
                data-trigger="hover focus"
                data-content="${checklist.isSuscribed ? "Desactivar notificaciones de cercanía a la fecha objetivo o al completar la checklist" : "Activar notificaciones de cercanía a la fecha objetivo o al completar la checklist"}"
                data-placement="left"
                style="min-width: 38px;"
              >
                <i class="fas ${checklist.isSuscribed ? "fa-bell-slash" : "fa-bell"}" style="pointer-events: none;"></i>
              </button>

              <button
                type="button"
                class="btn btn-barymont-black btn-delete-checklist-data mx-1"
                data-checklist-id="${checklist.checklistId}"
                style="min-width: 38px;"
              >
                  <i class="fas fa-trash-alt"></i>
              </button>

              <button
                type="button"
                class="btn btn-barymont-black btn-accordeon-collapse d-none d-sm-inline"
                data-toggle="collapse"
                data-target="#collapse-${checklist.checklistId}"
                aria-expanded="${ariaExpanded}"
                aria-controls="collapse-${checklist.checklistId}"
                style="min-width: 38px;"
              >
                  <i class="fas ${isOpen ? "fa-chevron-up" : "fa-chevron-down"}"></i>
              </button>
            </div>

            <div class="order-2 w-100 d-flex flex-wrap align-items-center justify-content-center px-2 mt-2">
              <div class="mx-1 w-50" style="max-width: 400px; min-width: 225px;">

                <div class="input-group input-group-sm">
                  <div class="input-group-prepend" id="prepend-checklist-objective-date-${checklist.checklistId}">
                    <span class="input-group-text">
                      <span class="d-none d-sm-inline">Fecha objetivo</span>
                      <span class="d-inline d-sm-none"><i class="fas fa-calendar-alt"></i></span>
                    </span>
                  </div>
                  <input
                    type="datetime-local"
                    class="form-control"
                    id="checklist-objective-date-${checklist.checklistId}"
                    data-checklist-id="${checklist.checklistId}"
                    value="${checklist.objectiveDate ? checklist.objectiveDate.slice(0, -3) : ""}">
                </div>
              </div>

              <button
                type="button"
                class="btn btn-barymont-black btn-accordeon-collapse d-block d-sm-none mx-1"
                data-toggle="collapse"
                data-target="#collapse-${checklist.checklistId}"
                aria-expanded="${ariaExpanded}"
                aria-controls="collapse-${checklist.checklistId}"
                style="min-width: 38px;"
              >
                <i class="fas ${isOpen ? "fa-chevron-up" : "fa-chevron-down"}"></i>
              </button>

              <div class="mx-1 flex-grow-1 d-none d-sm-block">
                ${renderProgressBar(checklist.completedCompletionChecks, checklist.totalCompletionChecks)}
              </div>
            </div>

            <div class="order-3 w-100 px-2 d-block d-sm-none">
              ${renderProgressBar(checklist.completedCompletionChecks, checklist.totalCompletionChecks)}
            </div>
          </div>
        </div>

        <div
          id="collapse-${checklist.checklistId}"
          class="collapse ${collapseClass}"
          aria-labelledby="heading-${checklist.checklistId}"
          data-parent="#accordion-checklist-${checklist.checklistId}"
        >
          <div class="card-body p-2" data-checklist-id="${checklist.checklistId}">
            ${checklist.completionChecks.map((item) => renderChecklistItemAdmin(item, checklist)).join("")}

            <div class="card mt-3 bg-light">
              <div class="card-body p-0 text-center">
                <button
                  data-action="add-completion-check"
                  data-checklist-id="${checklist.checklistId}"
                  type="button"
                  class="btn btn-barymont-grey w-100 py-1">
                  <i class="fas fa-plus"></i> Añadir check
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>`;
}

// __________ Funciones de renderizado de checklists USER __________//

function renderChecklistItemUser(item, checklist) {
  let assignedComponent;
  if (item.assignedUserId) {
    const userData = USER_LIST_OF_ACTIVES_USERS.find((user) => user.userId === item.assignedUserId);
    assignedComponent = `
      <div
        class="position-relative d-inline-block"
        data-toggle="popover"
        data-trigger="hover focus"
        data-placement="top"
        data-original-title="Usuario asignado"
        data-content="${userData ? userData.name : "Usuario desconocido"}">
        <img
          src="${userData ? userData.avatarPath : "#"}"
          style="width:30px; height:30px; object-fit:cover;"
          alt="${userData ? userData.name : "??"}"
          class="rounded-circle">
        <i
          class="fas fa-user text-dark"
          style="font-size:10px; position: absolute; bottom: -2px; right: -2px; background: #fff; border-radius: 50%; padding: 2px;"></i>
      </div>`;
  } else if (item.state === true) {
    assignedComponent = `
      <div
        class="d-inline-block"
        data-toggle="popover"
        data-trigger="hover focus"
        data-placement="top"
        data-content="Check completado, sin asignar">
        <i class="fas fa-check-circle text-success" style="font-size: 30px;"></i>
      </div>`;
  } else {
    assignedComponent = `
      <button
        id="btn-assign-current-user-${item.completionCheckId}"
        type="button"
        class="btn btn-barymont-dark-grey btn-asing-current-user px-2"
        data-checklist-id="${checklist.checklistId}"
        data-completion-check-id="${item.completionCheckId}"
        data-toggle="popover"
        data-trigger="hover focus"
        data-original-title="Asignar check"
        data-content="Haz click para asignarte este check"
        data-placement="top">
        <i class="fas fa-user-plus"></i>
      </button>`;
  }

  let completedComponent = "";
  if (item.completedAt) {
    const userWhoCompleted = USER_LIST_OF_ACTIVES_USERS.find((user) => user.userId === item.completedByUserId);
    const completedInTime = checklist.objectiveDate ? new Date(checklist.objectiveDate) > new Date(item.completedAt) : true;
    const timeStatus = completedInTime ? "Completado a tiempo" : "Completado fuera de tiempo";

    const unifiedPopoverText = userWhoCompleted ? `Completado por: ${userWhoCompleted.name}<br>${formatDateTime(item.completedAt)} - <i>${timeStatus}</i>` : `${timeStatus} - ${formatDateTime(item.completedAt)}`;

    completedComponent = `
      <div
        class="position-relative d-inline-block"
        data-toggle="popover"
        data-trigger="hover focus"
        data-placement="top"
        data-html="true"
        data-original-title="Check completado"
        data-content="${unifiedPopoverText}">
        ${
          userWhoCompleted
            ? `
              <img
                src="${userWhoCompleted.avatarPath}"
                style="width:30px; height:30px; object-fit:cover;"
                alt="${userWhoCompleted.userId}"
                class="rounded-circle">
              <i
                class="fas fa-clock"
                style="color: ${completedInTime ? "green" : "red"}; font-size:10px; position: absolute; bottom: -2px; right: -2px; background: #fff; border-radius: 50%; padding: 2px;"></i>`
            : `<i
                class="fas fa-clock"
                style="color: ${completedInTime ? "green" : "red"}; font-size:18px;"></i>`
        }
      </div>`;
  } else {
    completedComponent = `
      <div
        class="d-inline-block"
        data-toggle="popover"
        data-trigger="hover focus"
        data-placement="top"
        data-content="Pendiente">
        <span class="fa-stack" style="width: 30px; height: 30px; display: inline-block; position: relative;">
          <i class="fas fa-circle" style="font-size: 30px; color: #f8f9fa; display: block; border-radius: 50%;"></i>
          <i class="fas fa-hourglass-half" style="font-size: 16px; color: #6c757d; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"></i>
        </span>
      </div>`;
  }

  const checkButton = `
    <button
      type="button"
      id="btn-completion-check-state-${item.completionCheckId}"
      class="btn btn-completion-check-state p-0 bg-light"
      aria-pressed="${item.state}"
      data-completion-check-id="${item.completionCheckId}"
      data-checklist-id="${checklist.checklistId}"
      style="border-radius: 50%; transition: background-color 0.3s ease; font-size: 20px; width:30px; height:30px;">
      ${item.state ? '<i class="fas fa-check-circle text-success"></i>' : '<i class="far fa-circle text-secondary"></i>'}
    </button>`;

  return `
    <div
      class="card shadow-sm mb-2"
      ${item.state ? "style='background-color: #eeeeee'" : ""}
      data-checklist-id="${checklist.checklistId}"
      data-item-id="${item.completionCheckId}">
      <div class="card-body p-2">
        <div class="d-flex flex-wrap flex-xl-nowrap align-items-center justify-content-between">

          <div class="order-2 order-xl-1 mr-3 mb-2 mb-xl-0">
            ${checkButton}
          </div>

          <div class="order-1 order-xl-2 flex-grow-1 w-100 w-xl-auto mb-2 mb-xl-0 completion-check-template-tinymce-overwrite">
            ${item.description}
          </div>

          <div class="order-3 order-xl-3 ml-3" style="min-width: 100px;">
            <div class="d-flex align-items-center">

              <div class="mr-4">
                ${assignedComponent}
              </div>

              <div>
                ${completedComponent}
              </div>
            </div>
          </div>
        </div>

        <input id="completion-check-id-${item.completionCheckId}" type="hidden" value="${item.completionCheckId}">
        <input id="completion-check-state-${item.completionCheckId}" type="hidden" value="${item.state}">
        <input id="completion-check-checklist-id-${item.completionCheckId}" type="hidden" value="${checklist.checklistId}">
        <input id="completion-check-description-${item.completionCheckId}" type="hidden" value="${escapeHtml(item.description)}">
        <input id="completion-check-assign-user-${item.completionCheckId}" type="hidden" value="${item.assignedUserId}">
        <input id="completion-check-completed-at-${item.completionCheckId}" type="hidden" value="${item.completedAt}">
      </div>
    </div>`;
}

function renderChecklistUser(checklist) {
  const entityId = document.getElementById("checklist-entity-id").value;
  const isOpen = getAccordionState(entityId, checklist.checklistId);
  const collapseClass = isOpen ? "show" : "";
  const ariaExpanded = isOpen ? "true" : "false";

  return `
    <div class="accordion pb-3" id="accordion-checklist-${checklist.checklistId}">
      <div class="card">

        <div class="card-header p-2" id="heading-${checklist.checklistId}">
          <div class="d-flex flex-wrap align-items-center">
            <div class="order-1 d-flex align-items-center flex-grow-1 px-2 w-100 w-sm-auto">
              <span class="m-0 h5 px-2 w-100">
                ${checklist.title}
              </span>

              <div class="d-sm-flex align-items-center text-right">

                <button
                  data-checklist-id="${checklist.checklistId}"
                  data-is-suscribed="${checklist.isSuscribed}"
                  type="button"
                  class="btn btn-barymont-${checklist.isSuscribed ? "black" : "red"} btn-suscribe-notification mr-0 mr-sm-1 mb-1 mb-sm-0"
                  data-toggle="popover"
                  data-trigger="hover focus"
                  data-content="${checklist.isSuscribed ? "Desactivar notificaciones de cercanía a la fecha objetivo o al completar la checklist" : "Activar notificaciones de cercanía a la fecha objetivo o al completar la checklist"}"
                  data-placement="left"
                  style="min-width: 38px;"
                >
                  <i class="fas ${checklist.isSuscribed ? "fa-bell-slash" : "fa-bell"}" style="pointer-events: none;"></i>
                </button>

                <button
                  type="button"
                  class="btn btn-barymont-black btn-accordeon-collapse d-inline"
                  data-toggle="collapse"
                  data-target="#collapse-${checklist.checklistId}"
                  aria-expanded="${ariaExpanded}"
                  aria-controls="collapse-${checklist.checklistId}"
                  style="min-width: 38px;"
                >
                    <i class="fas ${isOpen ? "fa-chevron-up" : "fa-chevron-down"}"></i>
                </button>
              </div>
            </div>

            <div class="order-2 w-100 d-flex flex-wrap align-items-center justify-content-center px-2 mt-2">
              ${
                checklist.objectiveDate
                  ? `
                    <div class="mx-1 w-50" style="max-width: 400px; min-width: 225px;">
                      <div class="input-group input-group-sm">
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            <span class="d-none d-sm-inline">Fecha objetivo</span>
                            <span class="d-inline d-sm-none"><i class="fas fa-calendar-alt"></i></span>
                          </div>
                        </div>
                        <input
                          type="datetime-local"
                          class="form-control"
                          value="${checklist.objectiveDate}"
                          disabled
                        >
                      </div>
                    </div>`
                  : ""
              }

              <div class="mx-1 flex-grow-1 d-none d-sm-block">
                ${renderProgressBar(checklist.completedCompletionChecks, checklist.totalCompletionChecks)}
              </div>
            </div>

            <div class="order-3 w-100 px-2 d-block d-sm-none">
              ${renderProgressBar(checklist.completedCompletionChecks, checklist.totalCompletionChecks)}
            </div>
          </div>
        </div>

        <div
          id="collapse-${checklist.checklistId}"
          class="collapse ${collapseClass}"
          aria-labelledby="heading-${checklist.checklistId}"
          data-parent="#accordion-checklist-${checklist.checklistId}"
        >

          <div class="card-body p-2">
            ${checklist.completionChecks.map((item) => renderChecklistItemUser(item, checklist)).join("")}
          </div>
        </div>
      </div>
    </div>
  `;
}

// ___________________ MODALS ___________________//
async function loadChecklistModal({ usersWithVisibility, isAdmin = false, entityId, entityRelationalType, datatableId = null, hasManagePermission = false }) {
  const randomModalId = Math.random().toString(36).substring(7);
  const modalElement = document.createElement("div");

  modalElement.id = randomModalId;
  modalElement.classList.add("modal", "fade");
  modalElement.setAttribute("tabindex", "-1");
  modalElement.setAttribute("role", "dialog");
  modalElement.setAttribute("aria-hidden", "true");
  modalElement.setAttribute("data-backdrop", "static");

  modalElement.dataset.viewMode = "user";

  const toggleButtonHtml = hasManagePermission
    ? `<button
        id="toggle-checklist-view"
        type="button"
        class="btn btn-barymont-grey mr-3 py-1"
        data-toggle="popover"
        data-placement="left"
        data-content="Cambiar a vista administrador"
        data-trigger="hover focus"
      >
       <i class="fas fa-pencil-alt text-dark"></i> Modo administrador
     </button>`
    : "";

  modalElement.innerHTML = `
      <div class="modal-xl modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document" >
        <input type="hidden" id="checklist-entity-id" value="${entityId}">
        <input type="hidden" id="checklist-entity-relational-type" value="${entityRelationalType}">
        <div class="modal-content">

          <div class="modal-header">
            <h5 class="modal-title">Checklists</h5>
            <div class="d-flex align-items-center">
              ${toggleButtonHtml}
              <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>

          <div class="modal-body scroll-barymont-red overflow-auto p-0 p-md-3" style="max-height: 80vh;">
            <div class="d-flex justify-content-center my-3">
              <div class="spinner-border text-barymont-red" role="status">
                  <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>

          <div class="modal-footer d-flex">
            <button id="btn-all-accordions-actions" type="button" class="btn btn-barymont-black">Contraer todos los checklist</button>
            <button id="add-checklist-modal-button" type="button" class="btn btn-barymont-red ${isAdmin ? "" : "d-none"}">Añadir checklist</button>
            <div class="ml-auto">
              <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
            </div>
          </div>

        </div>
      </div>`;
  document.body.appendChild(modalElement);

  await paintChecklistModalBody({
    modalId: randomModalId,
    usersWithVisibility,
    isAdmin: false,
    entityId: entityId,
    entityRelationalType: entityRelationalType,
  });

  const toggleButton = modalElement.querySelector("#toggle-checklist-view");
  if (toggleButton) {
    toggleButton.addEventListener("click", () => {
      showLoadingButton(toggleButton.id, "btn-barymont-grey", true);
      modalElement.dataset.viewMode = modalElement.dataset.viewMode === "user" ? "admin" : "user";
      updateModalView(modalElement, usersWithVisibility, entityId, entityRelationalType);
      const buttonLabel = modalElement.dataset.viewMode === "admin" ? "<i class='fas fa-pencil-alt text-dark'></i> Modo usuario" : "<i class='fas fa-pencil-alt text-dark'></i> Modo administrador";
      updateButtonLabel(toggleButton.id, buttonLabel, "btn-barymont-grey");
    });
  }

  initializeReloadChecklistEvent({ modalElement, randomModalId, usersWithVisibility, entityId, entityRelationalType });

  $("#" + randomModalId).modal("show");

  $("#" + randomModalId).on("hidden.bs.modal", function () {
    $(`#${datatableId}`).DataTable().ajax.reload();
    modalElement.remove();
  });

  return modalElement;
}

function updateModalView(modalElement, usersWithVisibility, entityId, entityRelationalType) {
  const isAdminView = modalElement.dataset.viewMode === "admin";

  paintChecklistModalBody({
    modalId: modalElement.id,
    usersWithVisibility,
    isAdmin: isAdminView,
    entityId,
    entityRelationalType,
  }).then(() => {
    if (isAdminView) {
      initializeAddChecklistToEntityListeners(modalElement);
      initializeChecklistDeleteListeners(modalElement);
      initializeCompletionCheckDeleteListeners(modalElement);
      initializeChecklistObjectiveDateEditors(modalElement);
      initializeChecklistTitleEditors(modalElement);
      initializeChecklistDescriptionEditors(modalElement);
      initializeChecklistTaskEditors(modalElement);
      initializeUserListSelects({ modalElement: modalElement, selectorId: "completion-check-assign-user" });
      modalElement.querySelector("#add-checklist-modal-button").classList.remove("d-none");
    } else {
      initializeUserAsingButtonListeners(modalElement);
      modalElement.querySelector("#add-checklist-modal-button").classList.add("d-none");
    }
    initializeCompletionCheckStateButtons(modalElement);
    updateToggleButtonText(modalElement);
  });
}

async function paintChecklistModalBody({ modalId, usersWithVisibility, isAdmin = false, entityId, entityRelationalType }) {
  const checklistsData = await searchChecklists(entityId, entityRelationalType);
  const modalElement = document.getElementById(modalId);
  modalElement.querySelector(".modal-body").remove();

  const modalBody = document.createElement("div");
  modalBody.classList.add("modal-body", "scroll-barymont-red", "overflow-auto", "p-0", "p-md-3");
  modalBody.style.maxHeight = "80vh";
  modalBody.innerHTML = "";

  modalElement.querySelector(".modal-content").insertBefore(modalBody, modalElement.querySelector(".modal-footer"));

  if (checklistsData.checklists.length === 0) {
    modalBody.innerHTML = `
      <div class="alert text-center alert-warning" role="alert">
        No hay checklists disponibles.
      </div>`;
    return;
  }

  let renderedChecklists = isAdmin ? checklistsData.checklists.map((checklist) => renderChecklistAdmin(checklist)).join("") : checklistsData.checklists.map((checklist) => renderChecklistUser(checklist, usersWithVisibility)).join("");
  modalBody.innerHTML = renderedChecklists;

  $('[id^="completion-check-assign-user-"]').selectpicker("refresh");
  $('.popover').remove();

  $('[data-toggle="popover"]').popover();

  if (isAdmin) {
    initializeSortablesForChecklistCompletionChecksOnFirstLoad(modalElement);
    initializeSortableForChecklistsCompletionChecksOnAccordionEvents(modalElement);
    updateDragHandleVisibility(modalElement);
  }

  initializeSuscriptionEvents(modalElement);
  initializeToggleAcordeonsButton(modalElement);
  initializeUpdateIconsForAccordeons(modalElement);

  const openModalButton = document.getElementById("checklist-btn-" + entityId);

  if (openModalButton) {
    const badge = openModalButton.querySelector(".badge");
    if (badge) {
      badge.innerHTML = `${checklistsData.completedCompletionChecks} / ${checklistsData.totalCompletionChecks}`;
    }
  }
}

async function loadAddChecklistModal({ entityId, entityRelationalType, modalElement }) {
  const randomModalId = Math.random().toString(36).substring(7);
  const modalAddChecklistElement = document.createElement("div");
  const checklistTemplates = await readChecklistTemplates();

  let optionHtml = "";

  checklistTemplates.forEach((template) => {
    optionHtml += `<option value="${template.checklistTemplateId}" class="text-wrap">${template.title}</option>`;
  });

  modalAddChecklistElement.id = randomModalId;
  modalAddChecklistElement.classList.add("modal", "fade");
  modalAddChecklistElement.setAttribute("tabindex", "-1");
  modalAddChecklistElement.setAttribute("role", "dialog");
  modalAddChecklistElement.setAttribute("aria-hidden", "true");

  modalAddChecklistElement.innerHTML = `
    <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Añadir checklist</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="max-height: 80vh;">
          <form id="add-checklist-form">
            <div class="form-group mb-3">
              <label for="checklist-template" class="form-label">Plantillas <i class="fas fa-asterisk text-barymont-red"></i></label>
              <select id="checklist-template" class="form-control selectpicker show-tick input-lg modal-selectpicker" data-allow-clear="true" title="Seleccione la plantilla..." data-max-options="1" data-live-search="true" data-size="10" data-dropup-auto="true" required>
                ${optionHtml}
              </select>
              <label for="checklist-objective-date" class="form-label">Fecha objetivo</label>
              <input type="datetime-local" id="checklist-objective-date" class="form-control">
              <label for="modal-create-user-select" class="form-label">Selecciona un usuario para asignarlo a todos los checks</label>
              ${generateUserSelect({ item: {}, checklist: {}, selectId: "modal-create-user-select" })}
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button id="add-checklist-button" type="button" class="btn btn-barymont-red">Guardar</button>
          <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>`;

  document.body.appendChild(modalAddChecklistElement);

  $(".selectpicker").selectpicker("refresh");
  $("#" + randomModalId).modal("show");

  $("#" + randomModalId).on("hidden.bs.modal", function () {
    modalAddChecklistElement.remove();
  });

  initializeUserListSelects({ modalElement: modalAddChecklistElement, selectorId: "modal-create-user-select", isOnChecklist: false });

  modalAddChecklistElement.querySelector("#add-checklist-button").addEventListener("click", async function (e) {
    const checklistTemplateId = document.getElementById("checklist-template").value;
    const objectiveDateValue = document.getElementById("checklist-objective-date");
    const objectiveDate = objectiveDateValue.value !== "" ? objectiveDateValue.value.replace("T", " ").substring(0, 16) : null;
    const assignedUserId = document.getElementById("modal-create-user-select").value;

    try {
      showLoadingButton(e.target.id, "btn-barymont-grey", true);
      const response = await createChecklist(checklistTemplateId, entityId, entityRelationalType, objectiveDate);

      if (assignedUserId !== "") {
        await assignCompletionChecksChecklists([response.checklistId], assignedUserId);
      }

      createToast("success", "Checklist añadido", response.message, 3000);

      modalElement.dispatchEvent(new CustomEvent("reloadChecklistData"));

      $("#" + randomModalId).modal("hide");
    } catch (error) {
      updateButtonLabel(e.target.id, "Guardar", "btn-barymont-red");
      createToast("danger", "Error al añadir el checklist", error.message, 3000);
    }
  });
}

async function loadDeleteChecklistModal(checklistId, modalElement) {
  const randomModalId = Math.random().toString(36).substring(7);
  const modalDeleteChecklistelement = document.createElement("div");

  modalDeleteChecklistelement.id = randomModalId;
  modalDeleteChecklistelement.classList.add("modal", "fade");
  modalDeleteChecklistelement.setAttribute("tabindex", "-1");
  modalDeleteChecklistelement.setAttribute("role", "dialog");
  modalDeleteChecklistelement.setAttribute("aria-hidden", "true");

  modalDeleteChecklistelement.innerHTML = `
    <div class="modal-md modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Eliminar Checklist</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="max-height: 80vh;">
          <div class="alert text-center alert-danger" role="alert">
            ¿Estás seguro de que deseas eliminar este checklist?
          </div>
        </div>
        <div class="modal-footer">
          <button id="modal-confirm-delete-checklist-button" type="button" class="btn btn-barymont-red">Eliminar</button>
          <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>`;

  document.body.appendChild(modalDeleteChecklistelement);

  $("#" + randomModalId).modal("show");
  $("#" + randomModalId).on("hidden.bs.modal", function () {
    modalDeleteChecklistelement.remove();
  });

  modalDeleteChecklistelement.querySelector("#modal-confirm-delete-checklist-button").addEventListener("click", async function () {
    try {
      const response = await deleteChecklist(checklistId);

      modalElement.dispatchEvent(new CustomEvent("reloadChecklistData"));

      createToast("success", "Checklist eliminado", response.message, 3000);
      $("#" + randomModalId).modal("hide");
    } catch (error) {
      createToast("danger", "Error al eliminar el checklist", error.message, 3000);
    }
  });
}

async function loadDeleteCompletionCheckModal(checklistId, completionCheckId, modalElement) {
  const randomModalId = Math.random().toString(36).substring(7);
  const modalDeleteCompletionElement = document.createElement("div");

  modalDeleteCompletionElement.id = randomModalId;
  modalDeleteCompletionElement.classList.add("modal", "fade");
  modalDeleteCompletionElement.setAttribute("tabindex", "-1");
  modalDeleteCompletionElement.setAttribute("role", "dialog");
  modalDeleteCompletionElement.setAttribute("aria-hidden", "true");

  modalDeleteCompletionElement.innerHTML = `
    <div class="modal-md modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Eliminar Check</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="max-height: 80vh;">
          <div class="alert text-center alert-danger" role="alert">
            ¿Estás seguro de que deseas eliminar este Check?
          </div>
        </div>
        <div class="modal-footer">
          <button id="modal-confirm-delete-check-btn" type="button" class="btn btn-barymont-red">Eliminar</button>
          <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  `;

  document.body.appendChild(modalDeleteCompletionElement);

  $("#" + randomModalId).modal("show");
  $("#" + randomModalId).on("hidden.bs.modal", function () {
    modalDeleteCompletionElement.remove();
  });

  modalDeleteCompletionElement.querySelector("#modal-confirm-delete-check-btn").addEventListener("click", async function () {
    try {
      const response = await deleteCompletionCheck(checklistId, completionCheckId);

      modalElement.dispatchEvent(new CustomEvent("reloadChecklistData"));

      createToast("success", "Check eliminado", response.message, 3000);
      $("#" + randomModalId).modal("hide");
    } catch (error) {
      createToast("danger", "Error al eliminar el Check", error.message, 3000);
    }
  });
}

/*_____________________ FETCHS ________________________*/
async function updateChecklistData(checklistId, title, objectiveDate) {
  try {
    const response = await editChecklist(checklistId, title, objectiveDate);

    createToast("success", "Checklist actualizado", response.message, 3000);
  } catch (error) {
    createToast("danger", "Error al actualizar el checklist", error.message, 3000);
  }
}

async function updateCompletionCheckData(checklistId, completionCheckId, description, selectedUser, completionCheckState) {
  try {
    await updateCompletionCheck(checklistId, completionCheckId, description, selectedUser, completionCheckState);

    createSnack("success", "Check actualizado", 3000);
  } catch (error) {
    createToast("danger", "Error al asignar usuario", error.message, 3000);
  }
}

async function createCompletionCheckInChecklist(checklistId, description) {
  try {
    await createCompletionCheck(checklistId, description);

    createSnack("success", "Check añadido", 3000);
  } catch (error) {
    createToast("danger", "Error al añadir el check", error.message, 3000);
  }
}

async function updateSuscriptionNotificationChecklist(checklistId, isSubscribed, userId) {
  try {
    await updateChecklistNotification(checklistId, isSubscribed, userId);
  } catch (error) {
    createToast("danger", "Error al actualizar notificaciones", error.message, 3000);
  }
}

// _____________________________ INLINE EDITORS _____________________________ //
const createElementWithOptions = (tag, { props = {}, styles = {}, html = "" } = {}) => {
  const element = document.createElement(tag);
  Object.assign(element, props);
  Object.assign(element.style, styles);
  if (html) element.innerHTML = html;
  return element;
};

function closeAllInlineEditors() {
  document.querySelectorAll(".inline-editor").forEach((editor) => {
    const target = editor.previousElementSibling;
    if (target && target.dataset.isEditing) {
      editor.remove();
      target.classList.remove("d-none");
      delete target.dataset.originalText;
      delete target.dataset.isEditing;
    }
  });
}

function cleanupDetachedEditors() {
  tinymce.editors.forEach((editor) => {
    if (!document.body.contains(editor.targetElm)) {
      editor.remove();
    }
  });
}

function activateInlineEditor({ element, onConfirm, confirmButtonId, useTinyMCE }) {
  closeAllInlineEditors();

  if (element.dataset.isEditing) {
    return;
  }

  document.querySelectorAll(".inline-editor").forEach((editor) => {
    const target = editor.previousElementSibling;
    if (target && target.dataset.isEditing) {
      editor.remove();
      target.classList.remove("d-none");
      delete target.dataset.originalText;
      delete target.dataset.isEditing;
    }
  });

  const originalText = element.innerHTML.trim();

  element.dataset.originalText = originalText;
  element.dataset.isEditing = "true";
  element.classList.add("d-none", "editing");

  const editorContainer = createElementWithOptions("div", {
    props: { className: "inline-editor editable-container" },
    styles: { display: "flex", alignItems: "center", width: "100%" },
  });

  const uniqueId = "inline-editor-textarea-" + element.dataset.itemId;
  const textarea = createElementWithOptions("textarea", {
    props: { value: originalText, id: uniqueId },
    styles: { flex: "1", minHeight: "40px", boxSizing: "border-box" },
  });

  const buttonContainer = createElementWithOptions("div", {
    styles: { display: "flex", marginLeft: "8px" },
  });

  const confirmButton = createElementWithOptions("button", {
    props: { className: "btn btn-barymont-red btn-sm mr-1", id: confirmButtonId },
    html: '<i class="fa-solid fa-floppy-disk"></i>',
  });

  const cancelButton = createElementWithOptions("button", {
    props: { className: "btn btn-barymont-black btn-sm mr-2" },
    html: '<i class="fas fa-times"></i>',
  });

  setTimeout(() => {
    textarea.focus();
  }, 0);

  buttonContainer.append(confirmButton, cancelButton);
  editorContainer.append(textarea, buttonContainer);
  element.parentNode.insertBefore(editorContainer, element.nextSibling);

  const existingEditor = tinymce.get(uniqueId);
  if (existingEditor) {
    existingEditor.remove();
  }

  if (useTinyMCE) {
    tinymce.init({
      ...tinymceCommonConfig(uniqueId),
      setup: function (editor) {
        editor.on("init", function () {
          if (originalText) {
            editor.setContent(originalText);
          } else {
            editor.setContent("");
          }
        });
      },
    });
  }

  const removeEditor = () => {
    editorContainer.remove();
    element.classList.remove("d-none", "editing");
    delete element.dataset.originalText;
    delete element.dataset.isEditing;
    document.removeEventListener("click", onOutsideClick);
    const tinymceEditor = tinymce.get(uniqueId);
    if (tinymceEditor) tinymceEditor.remove();
  };

  const onOutsideClick = (event) => {
    if (event.target.closest(".tox-dialog")) {
      return;
    }

    if (!editorContainer.contains(event.target)) {
      removeEditor();
    }
  };

  confirmButton.addEventListener("click", (event) => {
    event.stopPropagation();
    let newText;

    if (useTinyMCE) {
      const editor = tinymce.get(uniqueId);
      newText = editor ? editor.getContent().trim() : "";
    } else {
      newText = textarea.value.trim();
    }
    if (!newText) {
      createToast("warning", "El texto no puede estar vacío", "", 2000);
      return;
    }
    confirmButton.disabled = true;
    cancelButton.disabled = true;
    showLoadingButton(confirmButton.id, "btn-success", true);

    onConfirm(newText)
      .then(() => {
        element.innerHTML = newText;
        removeEditor();
      })
      .catch((error) => {
        createToast("danger", "Error al actualizar", error.message, 3000);
        confirmButton.disabled = false;
        cancelButton.disabled = false;
        updateButtonLabel(confirmButton.id, '<i class="fas fa-check text-white"></i>', "btn-success");
      });
  });

  cancelButton.addEventListener("click", (event) => {
    event.stopPropagation();
    removeEditor();
  });
  setTimeout(() => document.addEventListener("click", onOutsideClick), 0);
}

function tinymceCommonConfig(selector) {
  return {
    allow_unsafe_link_target: false,
    invalid_elements: "script,object,embed,link,form,input,iframe",
    selector: `.inline-editor.editable-container textarea#${selector}`,
    height: 250,
    width: "100%",
    menubar: false,
    plugins: ["link", "image", "table", "lists", "preview", "anchor", "paste"],
    toolbar:
      "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link",
    style_formats: [
      {
        title: "Títulos",
        items: [
          { title: "Título 1", format: "h3" },
          { title: "Título 2", format: "h4" },
          { title: "Título 3", format: "h5" },
          { title: "Título 4", format: "h6" },
        ],
      },
      {
        title: "Formatos",
        items: [
          { title: "Negrita", format: "bold" },
          { title: "Cursiva", format: "italic" },
          { title: "Subrayada", format: "underline" },
          { title: "Tachada", format: "strikethrough" },
        ],
      },
      { title: "Bloques", items: [{ title: "Párrafo", format: "p" }] },
      {
        title: "Posiciones",
        items: [
          { title: "Izquierda", format: "alignleft" },
          { title: "Centrado", format: "aligncenter" },
          { title: "Derecha", format: "alignright" },
          { title: "Justificado", format: "alignjustify" },
        ],
      },
    ],
    content_style:
      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
    branding: false,
    cache_suffix: "v1",
  };
}


function activateInlineAddTaskEditor({ element, onConfirm, confirmButtonId, checklistId }) {
  if (element.dataset.isEditing) {
    return;
  }

  const originalHTML = element.innerHTML;
  element.dataset.originalHTML = originalHTML;
  element.dataset.isEditing = "true";
  element.classList.add("d-none", "editing");

  const editorContainer = createElementWithOptions("div", {
    props: { className: "inline-editor editable-container" },
    styles: { display: "flex", alignItems: "center", width: "100%" },
  });

  const uniqueId = "inline-editor-textarea-add-" + checklistId;
  const textarea = createElementWithOptions("textarea", {
    props: { value: "", placeholder: "Introduzca la descripcion del check", id: uniqueId },
    styles: { flex: "1", minHeight: "40px", boxSizing: "border-box" },
  });

  const buttonContainer = createElementWithOptions("div", {
    styles: { display: "flex", marginLeft: "8px" },
  });

  const confirmButton = createElementWithOptions("button", {
    props: { className: "btn btn-barymont-red btn-sm mr-1", id: confirmButtonId },
    html: '<i class="fa-solid fa-floppy-disk"></i>',
  });

  const cancelButton = createElementWithOptions("button", {
    props: { className: "btn btn-barymont-black btn-sm" },
    html: '<i class="fas fa-times"></i>',
  });

  setTimeout(() => {
    textarea.focus();
  }, 0);

  buttonContainer.append(confirmButton, cancelButton);
  editorContainer.append(textarea, buttonContainer);
  element.parentNode.insertBefore(editorContainer, element.nextSibling);

  const existingEditor = tinymce.get(uniqueId);
  if (existingEditor) {
    existingEditor.remove();
  }

  tinymce.init({
    ...tinymceCommonConfig(uniqueId),
    setup: function (editor) {
      editor.on("init", function () {
        editor.setContent("");
      });
    },
  });

  const removeEditor = () => {
    editorContainer.remove();
    element.classList.remove("d-none", "editing");
    delete element.dataset.originalHTML;
    delete element.dataset.isEditing;
    document.removeEventListener("click", onOutsideClick);

    const editorInstance = tinymce.get(uniqueId);
    if (editorInstance) {
      editorInstance.remove();
    }
  };

  const onOutsideClick = (event) => {
    if (!editorContainer.contains(event.target)) {
      removeEditor();
      element.classList.remove("d-none");
    }
  };

  confirmButton.addEventListener("click", (event) => {
    event.stopPropagation();

    const editor = tinymce.get(uniqueId);
    const newText = editor ? editor.getContent().trim() : "";

    if (!newText) {
      createToast("warning", "El texto no puede estar vacío", "", 2000);
      return;
    }
    confirmButton.disabled = true;
    cancelButton.disabled = true;
    showLoadingButton(confirmButton.id, "btn-success", true);

    onConfirm(newText)
      .then(() => {
        removeEditor();
      })
      .catch((error) => {
        createToast("danger", "Error al añadir tarea", error.message, 3000);
        confirmButton.disabled = false;
        cancelButton.disabled = false;
        updateButtonLabel(confirmButton.id, '<i class="fas fa-check text-white"></i>', "btn-success");
      });
  });

  cancelButton.addEventListener("click", (event) => {
    event.stopPropagation();
    removeEditor();
  });

  setTimeout(() => document.addEventListener("click", onOutsideClick), 0);
}

async function updateChecklistCompletionChecksOrder(checklistId, completionCheckOrder) {
  if (!completionCheckOrder || completionCheckOrder.length === 0) return;

  try {
    await orderChecklistCompletionChecks(checklistId, { completionChecksOrder: completionCheckOrder });

    createSnack("success", "Checks ordenados");
  } catch (error) {
    createToast("danger", "Error ordenar checks", error.message, 3000);
  }
}

async function updateEntityChecklistsOrder(modalElement) {
  const entityIdValue = document.getElementById("checklist-entity-id").value;
  const entityRelationalTypeValue = document.getElementById("checklist-entity-relational-type").value;

  if (!entityIdValue || !entityRelationalTypeValue) {
    return;
  }

  const checklistElements = modalElement.querySelectorAll("[id^='accordion-checklist-']");

  const checklistsOrder = Array.from(checklistElements).map((element, index) => ({
    checklistId: element.id.replace("accordion-checklist-", ""),
    order: index,
  }));

  try {
    await orderEntityChecklists(entityIdValue, checklistsOrder, entityRelationalTypeValue);

    createSnack("success", "Checklists ordenadas");
  } catch (error) {
    createToast("danger", "Error ordenar checklists", error.message, 3000);
  }
}

// ______________________ EVENT LISTENERS ______________________ //
function initializeAddChecklistToEntityListeners(modalElement) {
  if (modalElement.dataset.addChecklistListenerAdded === "true") return;
  modalElement.dataset.addChecklistListenerAdded = "true";

  modalElement.addEventListener("click", function (e) {
    const button = e.target.closest("[id*='add-checklist-modal-button']");
    if (!button) return;

    const entityId = document.getElementById("checklist-entity-id").value;
    const entityRelationalType = document.getElementById("checklist-entity-relational-type").value;

    loadAddChecklistModal({
      entityId,
      entityRelationalType,
      modalElement,
    });
  });
}

function initializeChecklistDeleteListeners(modalElement) {
  if (modalElement.dataset.checklistDeleteListenerAdded === "true") return;
  modalElement.dataset.checklistDeleteListenerAdded = "true";

  modalElement.addEventListener("click", function (e) {
    const button = e.target.closest(".btn-delete-checklist-data");
    if (!button) return;

    const checklistId = button.getAttribute("data-checklist-id");
    loadDeleteChecklistModal(checklistId, modalElement);
  });
}

function initializeCompletionCheckDeleteListeners(modalElement) {
  if (modalElement.dataset.completionCheckDeleteListenerAdded === "true") return;
  modalElement.dataset.completionCheckDeleteListenerAdded = "true";

  modalElement.addEventListener("click", function (e) {
    const button = e.target.closest(".btn-delete-completion-check");
    if (!button) return;

    const checklistId = button.getAttribute("data-checklist-id");
    const completionCheckId = button.getAttribute("data-item-id");
    loadDeleteCompletionCheckModal(checklistId, completionCheckId, modalElement);
  });
}

function initializeChecklistObjectiveDateEditors(modalElement) {
  if (modalElement.dataset.checklistObjectiveDateListenerAdded === "true") return;
  modalElement.dataset.checklistObjectiveDateListenerAdded = "true";

  modalElement.addEventListener("change", async function (e) {
    const inputElement = e.target.closest("input[id^='checklist-objective-date-']");
    if (!inputElement) return;

    const checklistId = inputElement.getAttribute("data-checklist-id");
    const titleElem = document.getElementById(`checklist-title-${checklistId}`);
    const title = titleElem ? titleElem.textContent.trim() : "";
    const objectiveDate = inputElement.value ? inputElement.value.replace("T", " ").substring(0, 16) : null;

    const prependElem = document.getElementById(`prepend-checklist-objective-date-${checklistId}`);
    let originalHTML = "";

    if (prependElem) {
      const groupTextElem = prependElem.querySelector(".input-group-text");
      if (groupTextElem) {
        originalHTML = groupTextElem.innerHTML;
        groupTextElem.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="pointer-events: none;"></span>';
      }
    }

    try {
      await updateChecklistData(checklistId, title, objectiveDate);

      if (prependElem) {
        const groupTextElem = prependElem.querySelector(".input-group-text");
        if (groupTextElem) groupTextElem.innerHTML = originalHTML;
      }

      createToast("success", "Fecha actualizada", "La fecha objetivo se ha actualizado correctamente.", 3000);
      modalElement.dispatchEvent(new CustomEvent("reloadChecklistData"));
    } catch (error) {
      if (prependElem) {
        const groupTextElem = prependElem.querySelector(".input-group-text");
        if (groupTextElem) groupTextElem.innerHTML = originalHTML;
      }
      createToast("danger", "Error al actualizar", error.message, 3000);
    }
  });
}

function initializeChecklistTitleEditors(modalElement) {
  if (modalElement.dataset.inlineTextAreaEditorsListenerAdded === "true") return;
  modalElement.dataset.inlineTextAreaEditorsListenerAdded = "true";

  modalElement.addEventListener("click", function (e) {
    const titleElement = e.target.closest(".checklist-title");
    if (!titleElement) return;

    const checklistId = titleElement.getAttribute("data-checklist-id");
    const objectiveDateElem = document.getElementById(`checklist-objective-date-${checklistId}`);
    const objectiveDate = objectiveDateElem.value ? objectiveDateElem.value.replace("T", " ").substring(0, 16) : null;

    activateInlineEditor({
      element: titleElement,
      onConfirm: (newText) => updateChecklistData(checklistId, newText, objectiveDate),
      confirmButtonId: `update-checklist-title-text-area-${checklistId}`,
      useTinyMCE: false,
    });
  });
}

function initializeChecklistDescriptionEditors(modalElement) {
  if (modalElement.dataset.inlineTextDescriptionsAreaEditorsListenerAdded === "true") return;
  modalElement.dataset.inlineTextDescriptionsAreaEditorsListenerAdded = "true";

  modalElement.addEventListener("click", function (e) {
    const descriptionElement = e.target.closest(".checklist-item-description");
    if (!descriptionElement) return;

    const checklistId = descriptionElement.getAttribute("data-checklist-id");
    const completionCheckId = descriptionElement.getAttribute("data-item-id");

    activateInlineEditor({
      element: descriptionElement,
      onConfirm: (newText) => {
        const assignedUserElem = document.getElementById(`completion-check-assign-user-${completionCheckId}`);
        const assignedUserValue = assignedUserElem ? assignedUserElem.value : "";
        const assignedUser = assignedUserValue === "" ? null : assignedUserValue;
        const stateElem = document.getElementById(`completion-check-state-${completionCheckId}`);
        const state = stateElem ? stateElem.value : "";
        return updateCompletionCheckData(checklistId, completionCheckId, newText, assignedUser, state);
      },
      confirmButtonId: `update-completion-check-description-text-area-${checklistId}`,
      useTinyMCE: true,
    });
  });
}

function initializeChecklistTaskEditors(modalElement) {
  modalElement.addEventListener("click", function (e) {
    const addTaskElement = e.target.closest("[data-action='add-completion-check']");
    if (!addTaskElement) return;

    const checklistId = addTaskElement.getAttribute("data-checklist-id");
    activateInlineAddTaskEditor({
      element: addTaskElement,
      onConfirm: (newText) =>
        createCompletionCheckInChecklist(checklistId, newText).then((response) => {
          modalElement.dispatchEvent(new CustomEvent("reloadChecklistData"));
          return response;
        }),
      confirmButtonId: `create-completion-check-button-${checklistId}`,
      checklistId: checklistId,
    });
  });
}

function initializeUserListSelects({ modalElement, selectorId, isOnChecklist = true }) {
  const selects = modalElement.querySelectorAll(`[id^='${selectorId}']`);

  selects.forEach((selectElement) => {
    const selectId = selectElement.id;
    const $select = $(`#${selectId}`);

    if (!selectElement.hasAttribute("data-current-assigned")) {
      selectElement.setAttribute("data-current-assigned", selectElement.value);
    }

    if (!selectElement.hasAttribute("data-original-assigned")) {
      selectElement.setAttribute("data-original-assigned", selectElement.value);
    }

    if (isOnChecklist) {
      $select.on("changed.bs.select", async function () {
        const newValue = selectElement.value;
        const originalValue = selectElement.getAttribute("data-original-assigned");
        const prependId = "prepend-user-assign-" + selectId;
        const prependElem = document.getElementById(prependId);
        const iconSpan = prependElem && prependElem.querySelector("span.input-group-text");

        if (newValue !== originalValue) {
          if (iconSpan) {
            iconSpan.innerHTML = `<i class="spinner-border spinner-border-sm"></i>`;
          }
          const completionCheckId = selectElement.getAttribute("data-item-id");
          const checklistIdElem = document.getElementById(`completion-check-checklist-id-${completionCheckId}`);
          const descriptionElem = document.getElementById(`completion-check-description-${completionCheckId}`);
          const stateElem = document.getElementById(`completion-check-state-${completionCheckId}`);
          const checklistId = checklistIdElem ? checklistIdElem.value : "";
          const description = descriptionElem ? descriptionElem.value : "";
          const completionCheckState = stateElem ? stateElem.value : "";

          try {
            await updateCompletionCheckData(checklistId, completionCheckId, description, newValue, completionCheckState);
            selectElement.setAttribute("data-original-assigned", newValue);
          } catch (error) {
            console.error("Error al actualizar usuario asignado:", error);
          } finally {
            if (iconSpan) {
              iconSpan.innerHTML = `<i class="fas fa-user text-dark"></i>`;
            }
          }
        }
        selectElement.setAttribute("data-current-assigned", newValue);
      });
    }

    $select.on("shown.bs.select", function () {
      const $dropdown = $select.data("selectpicker").$menu;

      if (!$dropdown.find(".custom-dropdown-header").length) {
        const headerHtml = `
          <div class="custom-dropdown-header p-2 border-bottom">
            <div class="btn-group btn-group-sm d-flex" style="width: 100%;" role="group">
              <button type="button" class="btn btn-outline-success flex-fill filter-actives active" style="transition: background-color 0.3s;">
                Usuarios activos
              </button>
              <button type="button" class="btn btn-outline-secondary flex-fill filter-todos" style="transition: background-color 0.3s;">
                Todos los usuarios
              </button>
            </div>
          </div>`;

        $dropdown.prepend(headerHtml);

        $dropdown.find(".filter-actives").on("click", function (event) {
          event.stopPropagation();
          $(this).addClass("active");
          $dropdown.find(".filter-todos").removeClass("active");

          const searchQuery = $select.parent().find(".bs-searchbox input").val() || "";
          updateUserSelectOptions(selectId, true, searchQuery);

          if (searchQuery.length < 3) {
            clearNonSelectedOptions();
          }

          $select.selectpicker("refresh");
        });

        $dropdown.find(".filter-todos").on("click", function (event) {
          event.stopPropagation();
          $(this).addClass("active");
          $dropdown.find(".filter-actives").removeClass("active");

          const searchQuery = $select.parent().find(".bs-searchbox input").val() || "";
          updateUserSelectOptions(selectId, false, searchQuery);

          if (searchQuery.length < 3) {
            clearNonSelectedOptions();
          }

          $select.selectpicker("refresh");
        });
      }
    });

    const $searchInput = $select.parent().find(".bs-searchbox input");
    if (!$searchInput.length) {
      return;
    }

    $searchInput.on("input", function (event) {
      const inputValue = event.target.value.trim();
      const currentSelect = document.getElementById(selectId);
      const selectedValue = currentSelect.getAttribute("data-current-assigned") || currentSelect.value;

      if (inputValue.length < 3) {
        clearNonSelectedOptions();
        $select.val(selectedValue);
        $select.selectpicker("refresh");
        return;
      }

      const filteredUsers = USER_LIST_OF_ACTIVES_USERS.filter((user) => {
        const name = (user.userName || user.name).toLowerCase();
        return name.includes(inputValue.toLowerCase());
      });

      currentSelect.innerHTML = "";
      if (selectedValue) {
        const selectedUser = USER_LIST_OF_ACTIVES_USERS.find((user) => user.userId === selectedValue);
        if (selectedUser) {
          const userProfileBadge = `<span class="badge bg-${selectedUser.userProfile.backgroundColor} text-${selectedUser.userProfile.textColor} font-weight-bold">${selectedUser.userProfile.label}</span>`;
          const dataContent = `<img src="${selectedUser.avatarPath}" width="20" height="20" alt="User Image - ${selectedUser.userId}" class="rounded-circle mr-2">
                               <span>${selectedUser.userName ?? selectedUser.name}</span> ${userProfileBadge}`;
          const option = document.createElement("option");
          option.value = selectedUser.userId;
          option.setAttribute("data-content", dataContent);
          option.selected = true;
          currentSelect.appendChild(option);
        }
      }

      let count = 0;
      filteredUsers.forEach((item) => {
        if (count >= MAX_OPTION_ON_SELECTPICKER) {
          return;
        }

        if (item.userId === selectedValue) {
          return;
        }
        const option = document.createElement("option");
        option.value = item.userId;
        option.setAttribute(
          "data-content",
          `<img src="${item.avatarPath}" width="20" height="20" alt="User Image - ${item.userId}" class="rounded-circle mr-2">
           <span>${item.name}</span>
           <span class="badge bg-${item.userProfile.backgroundColor} text-${item.userProfile.textColor} ml-2">
             ${item.userProfile.label}
           </span>`
        );
        currentSelect.appendChild(option);
        count++;
      });

      $select.selectpicker("refresh");
    });

    function clearNonSelectedOptions() {
      const currentSelect = document.getElementById(selectId);
      const selectedValue = currentSelect.getAttribute("data-current-assigned") || currentSelect.value;
      currentSelect.innerHTML = "";
      if (selectedValue) {
        const userData = USER_LIST_OF_ACTIVES_USERS.find((user) => user.userId === selectedValue);
        if (userData) {
          const userProfileBadge = `<span class="badge bg-${userData.userProfile.backgroundColor} text-${userData.userProfile.textColor} font-weight-bold">${userData.userProfile.label}</span>`;
          const dataContent = `<img src="${userData.avatarPath}" width="20" height="20" alt="User Image - ${userData.userId}" class="rounded-circle mr-2">
                               <span>${userData.userName ?? userData.name}</span> ${userProfileBadge}`;
          const option = document.createElement("option");
          option.value = userData.userId;
          option.setAttribute("data-content", dataContent);
          option.selected = true;
          currentSelect.appendChild(option);
        }
      }
      $select.selectpicker("refresh");
    }
  });
}

function initializeCompletionCheckStateButtons(modalElement) {
  if (modalElement.dataset.completionCheckStateListenerAdded === "true") return;
  modalElement.dataset.completionCheckStateListenerAdded = "true";

  modalElement.addEventListener("click", async (e) => {
    const completionCheckButton = e.target.closest(".btn-completion-check-state");
    if (!completionCheckButton) return;

    const buttonId = completionCheckButton.id;
    const completionCheckId = completionCheckButton.getAttribute("data-completion-check-id");
    const checklistId = completionCheckButton.getAttribute("data-checklist-id");
    const description = modalElement.querySelector(`#completion-check-description-${completionCheckId}`).value;
    const userSelected = modalElement.querySelector(`#completion-check-assign-user-${completionCheckId}`).value;

    const currentState = completionCheckButton.getAttribute("aria-pressed") === "true";
    const newState = !currentState;

    showLoadingButton(buttonId, "btn-barymont-grey", true);
    try {
      await updateCompletionCheckData(checklistId, completionCheckId, description, userSelected, newState);
      modalElement.dispatchEvent(new CustomEvent("reloadChecklistData"));

      $(modalElement).find('[data-toggle="popover"]').popover("hide");
    } catch (error) {
      const originalIcon = currentState ? '<i class="fas fa-check-circle text-success" style="pointer-events: none;"></i>' : '<i class="far fa-circle text-white" style="pointer-events: none;"></i>';
      updateButtonLabel(buttonId, originalIcon, "btn-barymont-grey");
    }
  });
}

function initializeReloadChecklistEvent({ modalElement, usersWithVisibility, entityId, entityRelationalType }) {
  if (modalElement.dataset.reloadChecklistListenerAdded === "true") return;
  modalElement.dataset.reloadChecklistListenerAdded = "true";

  modalElement.addEventListener("reloadChecklistData", async () => {
    const modalBodyOld = modalElement.querySelector(".modal-body");
    const scrollPosition = modalBodyOld ? modalBodyOld.scrollTop : 0;

    const currentViewIsAdmin = modalElement.dataset.viewMode === "admin";
    await paintChecklistModalBody({
      modalId: modalElement.id,
      usersWithVisibility,
      isAdmin: currentViewIsAdmin,
      entityId,
      entityRelationalType,
    });

    const modalBodyNew = modalElement.querySelector(".modal-body");
    if (modalBodyNew) {
      modalBodyNew.scrollTop = scrollPosition;
    }

    if (currentViewIsAdmin) {
      initializeAddChecklistToEntityListeners(modalElement);
      initializeChecklistDeleteListeners(modalElement);
      initializeCompletionCheckDeleteListeners(modalElement);
      initializeChecklistObjectiveDateEditors(modalElement);
      initializeChecklistTitleEditors(modalElement);
      initializeChecklistDescriptionEditors(modalElement);
      initializeChecklistTaskEditors(modalElement);
      initializeUserListSelects({ modalElement: modalElement, selectorId: "completion-check-assign-user" });
    } else {
      initializeUserAsingButtonListeners(modalElement);
    }

    cleanupDetachedEditors();

    updateToggleButtonText(modalElement);
  });
}

function initializeUserAsingButtonListeners(modalElement) {
  if (modalElement.dataset.userAsingListenerAdded === "true") return;
  modalElement.dataset.userAsingListenerAdded = "true";

  modalElement.addEventListener("click", async function (e) {
    const button = e.target.closest(".btn-asing-current-user");
    if (!button) return;

    $(modalElement).find('[data-toggle="popover"]').popover("hide");

    showLoadingButton(button.id, "btn-barymont-grey", true);

    const checklistId = button.getAttribute("data-checklist-id");
    const completionCheckId = button.getAttribute("data-completion-check-id");
    const userId = AppGbSession.getSessionData().userId;

    try {
      await asingUserToCompletionCheck(checklistId, completionCheckId, userId);

      createSnack("success", "Te has asignado el check", 3000);

      modalElement.dispatchEvent(new CustomEvent("reloadChecklistData"));
    } catch (error) {
      createToast("danger", "Error al asignarte al check", error.message, 3000);
    }
  });
}

// ________________________ WINDOW LOAD ________________________ //
if (window) {
  window.addEventListener("load", () => {
    // PANEL DE SERVICIOS
    if (window.location.pathname.includes("/herramientas/servicio")) {

      document.querySelector('[id^="checklist-btn-"]')?.addEventListener("click", async function (e) {
        let button = e.target;

        const previousBtnHtml = button.innerHTML;

        document.getElementById(button.id).classList.remove("fa-tasks");
        document.getElementById(button.id).innerHTML = '<span class="spinner-border spinner-border-sm m-1" role="status" aria-hidden="true" style="pointer-events: none;"></span>';

        await openChecklistModal({
          entityRelationalType: button.dataset.entityRelationalType,
          entityId: button.dataset.entityId,
          buttonId: button.id,
        });

        document.getElementById(button.id).classList.add("fa-tasks");
        document.getElementById(button.id).innerHTML = previousBtnHtml;
      });

      $(document).on("focusin", function (e) {
        if ($(e.target).closest(".tox-dialog").length) {
          e.stopImmediatePropagation();
        }
      });
    }

    // PYMES
    if ((window.location.pathname === "/herramientas/companyservice" || window.location.pathname.includes("/herramientas/companyservice/")) && AppGbSession.checkUserHasPermission("PymeService:PymeServiceReadAccessChecker")) {
      const containerId = "pyme-service-container";
      obtainChecklistDataFromList({
        containerId: containerId,
        entityRelationalType: "PYME_SERVICE",
        datatableId: "pyme-service-table",
      });

      $(document).on("focusin", function (e) {
        if ($(e.target).closest(".tox-dialog").length) {
          e.stopImmediatePropagation();
        }
      });
    }
  });
}

export default {};
