"use strict";

import settledSharedFunctions from "./settledShared.js";

const TOTAL_STEPS = 2;

var currentStep = 1;
var selectedSettledsArray = [];
var maxSettledToDownload = 0;
var settledInvoiceToDownloadTable;

async function fetchSettledWithInvoiceByDateRange(dateFrom, dateTo) {
  let formData = new FormData();

  formData.append("dateFrom", dateFrom);
  formData.append("dateTo", dateTo);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/liquidaciones/ajax/getSettlementWithInvoices", requestOptions).then((response) => response.json());
}

async function fetchGetSettlementUrlToDownload(dateFrom, dateTo, selectedSettledsArray) {
  let formData = new FormData();

  formData.append("dateFrom", dateFrom);
  formData.append("dateTo", dateTo);
  formData.append("settledIdArray", JSON.stringify(selectedSettledsArray));

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/liquidaciones/ajax/downloadMassiveInvoices", requestOptions).then((response) => response.json());
}

function printSettledInvoiceToDownloadDatatableByJson(data, tableId) {
  if ($.fn.DataTable.isDataTable("#" + tableId)) {
    $("#" + tableId)
      .DataTable()
      .destroy();
    $("#" + tableId).empty();
  }

  maxSettledToDownload = data.length;

  var transformedData = data.map(function (item) {
    return {
      settled_id: item.settledId,
      nif: item.nif.nif,
      name: item.nif.full_name,
      commission_quantity: item.numberOfCommissions,
      tax_base: {
        render: item.grossAmountTotal + " €",
        sort: function () {
          return item.grossAmountTotal.replace(/\s/g, "").replace(/\./g, "");
        },
      },
      iva_percentage: {
        render: item.nif.tax_area / 100 + " %",
        sort: function () {
          return item.nif.tax_area;
        },
      },
      iva_total: {
        render: item.ivaAmountTotal + " €",
        sort: function () {
          return item.ivaAmountTotal.replace(/\s/g, "").replace(/\./g, "");
        },
      },
      irpf_percentage: {
        render: item.nif.tax_personal / 100 + " %",
        sort: function () {
          return item.nif.tax_personal;
        },
      },
      irpf_total: {
        render: item.irpfAmountTotal + " €",
        sort: function () {
          return item.irpfAmountTotal.replace(/\s/g, "").replace(/\./g, "");
        },
      },
      total_liquid: {
        render: item.netAmountTotal + " €",
        sort: function () {
          return item.netAmountTotal.replace(/\s/g, "").replace(/\./g, "");
        },
      },
    };
  });

  settledInvoiceToDownloadTable = $("#" + tableId).DataTable({
    language: {
      sProcessing: "Procesando datos...",
      sLengthMenu: "Mostrar _MENU_ Liquidaciones con factura",
      sZeroRecords: "No se encontraron liquidaciones con factura coincidentes con el criterio de búsqueda.",
      sEmptyTable: "Ningúna liquidaciones con factura coincidente con el criterio de búsqueda.",
      sInfo: "Mostrando liquidaciones con factura del _START_ al _END_ de un total de _TOTAL_ liquidaciones con factura",
      sInfoEmpty: "Mostrando liquidaciones con factura del 0 al 0 de un total de 0 liquidaciones con factura",
      sInfoFiltered: "(Filtrados de un total de _MAX_ liquidaciones con factura)",
      sSearch: "<div class='input-group-prepend'><div class='input-group-text'><i class='fas fa-search'></i></div></div>",
      sUrl: "",
      sInfoThousands: ",",
      sLoadingRecords: "Cargando Datos...",
      oPaginate: {
        sFirst: "Primera",
        sLast: "Última",
        sNext: "Siguiente",
        sPrevious: "Anterior",
      },
      oAria: {
        sSortAscending: ": Activar para ordenar la columna de manera ascendente",
        sSortDescending: ": Activar para ordenar la columna de manera descendente",
      },
      select: {
        rows: {
          _: "",
        },
      },
    },
    pagingType: "input",
    order: [[0, "desc"]],
    processing: true,
    dom: '<"top"fl>Brt<"bottom"ip><"clear">',
    buttons: [
      {
        text: "Seleccionar todos",
        className: "btn btn-barymont-red my-1 select-all-commissions",
        action: function () {
          selectSettledWithInvoiced();
        },
        init: function (api, node) {
          $(node).removeClass("dt-button");
        },
      },
      {
        text: "Limpiar seleccion",
        className: "btn btn-barymont-black my-2 my-md-0 deselect-all-commissions",
        action: function () {
          resetSelectedRows();
        },
        init: function (api, node) {
          $(node).removeClass("dt-button");
        },
      },
      {
        text: "Haz click en un fila para seleccionar una liquidación con factura",
        className: "btn btn-barymont-grey font-weight-bold total-selected not-clickable",
        init: function (api, node) {
          $(node).removeClass("dt-button");
        },
      },
    ],
    data: transformedData,
    columns: [
      {
        data: "settled_id",
        title: "id liquidación",
        visible: false,
      },
      {
        data: "nif",
        title: "NIF",
      },
      { data: "name", title: "Nombre", className: "text-left" },
      { data: "commission_quantity", title: "Nº Com.", className: "text-center" },
      {
        data: "tax_base",
        title: "B. Imponible",
        className: "text-right",
        render: function (data, type) {
          if (type === "sort") return data.sort;
          return data.render;
        },
      },
      {
        data: "iva_percentage",
        title: "% TAX Área",
        className: "text-right",
        render: function (data, type) {
          if (type === "sort") return data.sort;
          return data.render;
        },
      },
      {
        data: "iva_total",
        title: "I. TAX Área",
        className: "text-right",
        render: function (data, type) {
          if (type === "sort") return data.sort;
          return data.render;
        },
      },
      {
        data: "irpf_percentage",
        title: "% TAX Personal",
        className: "text-right",
        render: function (data, type) {
          if (type === "sort") return data.sort;
          return data.render;
        },
      },
      {
        data: "irpf_total",
        title: "I. TAX Personal",
        className: "text-right",
        render: function (data, type) {
          if (type === "sort") return data.sort;
          return data.render;
        },
      },
      {
        data: "total_liquid",
        title: "I. Líquido",
        className: "text-right",
        render: function (data, type) {
          if (type === "sort") return data.sort;
          return data.render;
        },
      },
    ],
    pageLength: 10,
    scrollX: true,
    lengthMenu: [
      [10, 25, 50],
      [10, 25, 50],
    ],
    select: {
      style: "multi",
      selector: "td",
      className: "clickable",
    },
    keys: true,
    searchHighlight: true,
    fnRowCallback: function (nRow) {
      updateSettledToDownloadInvoiceDatatableActionButtons();
      return nRow;
    },
    fnDrawCallback: function () {
      updateSettledToDownloadInvoiceDatatableActionButtons();
    },
  });

  function selectSettledWithInvoiced() {
    var allData = settledInvoiceToDownloadTable.rows().data().toArray();

    allData.forEach(function (data) {
      if ($.inArray(data.settled_id, selectedSettledsArray) === -1) {
        selectedSettledsArray.push(data.settled_id);
      }
    });

    settledInvoiceToDownloadTable.rows().nodes().to$().css("background-color", "var(--barymont-green)").select();
    updateSettledToDownloadInvoiceDatatableActionButtons();
  }

  function resetSelectedRows() {
    selectedSettledsArray = [];
    settledInvoiceToDownloadTable.rows().deselect();
    settledInvoiceToDownloadTable.rows().nodes().to$().css("background-color", "white");
    updateSettledToDownloadInvoiceDatatableActionButtons();
  }

  settledInvoiceToDownloadTable.on("select", function (e, dt, type, indexes) {
    const rowData = settledInvoiceToDownloadTable.rows(indexes).data().toArray();

    if ($.inArray(rowData[0].settled_id, selectedSettledsArray) === -1) {
      settledInvoiceToDownloadTable.rows(indexes).nodes().to$().css("background-color", "var(--barymont-green)");
      selectedSettledsArray.push(rowData[0].settled_id);
    }
    updateSettledToDownloadInvoiceDatatableActionButtons();
  });

  settledInvoiceToDownloadTable.on("deselect", function (e, dt, type, indexes) {
    const rowData = settledInvoiceToDownloadTable.rows(indexes).data().toArray();
    settledInvoiceToDownloadTable.rows(indexes).nodes().to$().css("background-color", "white");
    selectedSettledsArray.splice($.inArray(rowData[0].settled_id, selectedSettledsArray), 1);

    if ($(".select-all-commissions").text() === "Deseleccionar todos") {
      $(".select-all-commissions").text("Seleccionar todos");
      $(".select-all-commissions").removeClass("btn-barymont-green");
      $(".select-all-commissions").addClass("btn-barymont-red");
    }
    updateSettledToDownloadInvoiceDatatableActionButtons();
  });

  $(".select-all-commissions")
    .off("click")
    .on("click", function () {
      if ($(this).text() === "Seleccionar todos") {
        selectSettledWithInvoiced();
      } else {
        resetSelectedRows();
      }
    });

  resetSelectedRows();
}

function updateSettledToDownloadInvoiceDatatableActionButtons() {
  $(".total-selected").html(selectedSettledsArray.length + " seleccion" + (selectedSettledsArray.length === 1 ? "" : "es"));

  if (maxSettledToDownload === selectedSettledsArray.length && maxSettledToDownload !== 0) {
    $(".select-all-commissions").text("Deselecionar todos");
    $(".select-all-commissions").removeClass("btn-barymont-red");
    $(".select-all-commissions").addClass("btn-barymont-green");
  } else {
    $(".select-all-commissions").text("Seleccionar todos");
    $(".select-all-commissions").removeClass("btn-barymont-green");
    $(".select-all-commissions").addClass("btn-barymont-red");
  }
}

function printInstructionDataByStep(step, instructionsId) {
  const instructionContainer = document.getElementById(instructionsId);

  switch (step) {
    case 1:
      instructionContainer.innerHTML = "📅 Selecciona el rango de fechas de las facturas que quieres descargar";
      break;
    case 2:
      instructionContainer.innerHTML = "🚚 Selecciona las liquidaciones que quieras exportar";
      break;
  }
}

function manageNavigateButtonsByStep(step, btnPrevId, btnNextId, btnGenerateId) {
  const btnPrev = document.getElementById(btnPrevId);
  const btnNext = document.getElementById(btnNextId);
  const btnGenerate = document.getElementById(btnGenerateId);

  if (step === 1) {
    btnPrev.classList.add("d-none");
    btnPrev.classList.remove("inline-block");
  } else {
    btnPrev.classList.add("inline-block");
    btnPrev.classList.remove("d-none");
  }

  if (step === TOTAL_STEPS) {
    btnNext.classList.add("d-none");
    btnNext.classList.remove("inline-block");
    btnGenerate.classList.add("inline-block");
    btnGenerate.classList.remove("d-none");
  } else {
    btnNext.classList.add("inline-block");
    btnNext.classList.remove("d-none");
    btnGenerate.classList.add("d-none");
    btnGenerate.classList.remove("inline-block");
  }
}

function showMasiveSettledStep(step, totalSteps, modalFormStepsBaseId, instructionsId, btnPrevId, btnNextId, btnGenerateId) {
  for (let i = 1; i <= totalSteps; i++) {
    if (i === step) {
      document.getElementById(modalFormStepsBaseId + i).classList.remove("d-none");
    } else {
      document.getElementById(modalFormStepsBaseId + i).classList.add("d-none");
    }
  }
  printInstructionDataByStep(step, instructionsId);
  manageNavigateButtonsByStep(step, btnPrevId, btnNextId, btnGenerateId);
}

function clearValuesMasiveSettlementFormByStep(step, dateFromId, dateToId, dataTableId) {
  switch (step) {
    case 1:
      document.getElementById(dateFromId).value = "";
      document.getElementById(dateToId).value = "";
      break;
    case 2:
      if ($.fn.DataTable.isDataTable("#" + dataTableId)) {
        $("#" + dataTableId)
          .DataTable()
          .destroy();
        $("#" + dataTableId).empty();
      }
      break;
  }
}

function resetMasiveSettlementFormData(dateFromId, dateToId, tableId, btnPrevId, btnNextId, btnGenerateId) {
  currentStep = 1;
  selectedSettledsArray = [];
  maxSettledToDownload = 0;
  clearValuesMasiveSettlementFormByStep(1, dateFromId, dateToId, tableId);
  clearValuesMasiveSettlementFormByStep(2, dateFromId, dateToId, tableId);
  clearValuesMasiveSettlementFormByStep(3, dateFromId, dateToId, tableId);
  if ($.fn.DataTable.isDataTable("#" + tableId)) {
    settledInvoiceToDownloadTable.rows().deselect();
    settledInvoiceToDownloadTable.rows().nodes().to$().css("background-color", "white");
  }
  updateSettledToDownloadInvoiceDatatableActionButtons();
  manageNavigateButtonsByStep(currentStep, btnPrevId, btnNextId, btnGenerateId);
}

function validateMasiveSettlementFormByStep(currentStep, dateFromId, dateToId) {
  switch (currentStep) {
    case 1:
      if (!document.getElementById(dateFromId).value || !document.getElementById(dateToId).value || document.getElementById(dateFromId).value > document.getElementById(dateToId).value) {
        createToast("warning", "Rango de fechas inválido", "Debes seleccionar un rango de fechas válido", 5000);
        return true;
      }
      break;
    case 2:
      if (selectedSettledsArray.length === 0) {
        createToast("warning", "No hay selecciones", "Debes seleccionar al menos una liquidacion para descargar", 5000);
        return true;
      }
      break;
  }
  return false;
}

async function loadDataByStep(step, dateFromId, dateToId, dataTableId) {
  if (step === 1) {
    const dateFrom = document.getElementById(dateFromId).value;
    const dateTo = document.getElementById(dateToId).value;

    const settledInvoiceToDownloadResponse = await fetchSettledWithInvoiceByDateRange(dateFrom, dateTo);

    if (settledInvoiceToDownloadResponse.status === 0) {
      createToast("error", "Error", "Ocurrió un error al cargar las liquidaciones con factura", 5000);
      return false;
    }

    if (settledInvoiceToDownloadResponse.data.length === 0) {
      createToast("warning", "No hay liquidaciones", "No se encontraron liquidaciones con factura en el rango de fechas seleccionado", 5000);
      return false;
    }

    if (settledInvoiceToDownloadResponse.status && settledInvoiceToDownloadResponse.data.length > 0) {
      printSettledInvoiceToDownloadDatatableByJson(settledInvoiceToDownloadResponse.data, dataTableId);
    }
  }

  return true;
}

function sendDataTocontroller(url, dateFromValue, dateToValue, selectedSettledsArray) {
  const form = document.createElement("form");
  form.method = "POST";
  form.action = url;
  form.target = "_blank";

  const settledIdsInput = document.createElement("input");
  settledIdsInput.type = "hidden";
  settledIdsInput.name = "settledIds";
  settledIdsInput.value = JSON.stringify(selectedSettledsArray);
  form.appendChild(settledIdsInput);

  const dateFromInput = document.createElement("input");
  dateFromInput.type = "hidden";
  dateFromInput.name = "dateFrom";
  dateFromInput.value = dateFromValue;
  form.appendChild(dateFromInput);

  const dateToInput = document.createElement("input");
  dateToInput.type = "hidden";
  dateToInput.name = "dateTo";
  dateToInput.value = dateToValue;
  form.appendChild(dateToInput);

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname === "/herramientas/liquidaciones") {
      const btnOpenModalId = "btn-open-massive-download-settled-modal";
      const modalId = "modal-massive-invoice-download";

      // Modals containers ids
      const modalInstructionsContainerId = "modal-massive-invoice-download-instructions";
      const modalStepBaseId = "modal-massive-invoice-download-form-step-";

      // Inputs ids
      const modalDateFromId = "modal-massive-invoice-download-date-from";
      const modalDateToId = "modal-massive-invoice-download-date-to";
      const modalDataTableId = "modal-massive-invoice-download-table";

      // Buttons ids
      const btnPrevId = "btn-modal-massive-invoice-download-prev";
      const btnNextId = "btn-modal-massive-invoice-download-next";
      const btnDownloadId = "btn-modal-massive-invoice-download";

      document.getElementById(btnNextId).addEventListener("click", () => {
        settledSharedFunctions.showLoadingButton(btnNextId, "btn-barymont-red", true);
        if (validateMasiveSettlementFormByStep(currentStep, modalDateFromId, modalDateToId)) {
          settledSharedFunctions.updateButtonLabel(btnNextId, "Siguiente", "btn-barymont-red");
          return;
        }

        if (currentStep < TOTAL_STEPS) {
          let nextStep = currentStep + 1;
          showMasiveSettledStep(nextStep, TOTAL_STEPS, modalStepBaseId, modalInstructionsContainerId, btnPrevId, btnNextId, btnDownloadId);

          loadDataByStep(currentStep, modalDateFromId, modalDateToId, modalDataTableId).then((isDataLoaded) => {
            if (isDataLoaded === false) {
              showMasiveSettledStep(currentStep, TOTAL_STEPS, modalStepBaseId, modalInstructionsContainerId, btnPrevId, btnNextId, btnDownloadId);
              settledSharedFunctions.updateButtonLabel(btnNextId, "Siguiente", "btn-barymont-red");
              return;
            }
            currentStep = nextStep;

            settledSharedFunctions.updateButtonLabel(btnNextId, "Siguiente", "btn-barymont-red");
          });
        }
      });

      document.getElementById(btnPrevId).addEventListener("click", () => {
        if (currentStep > 1) {
          currentStep--;
          clearValuesMasiveSettlementFormByStep(currentStep, modalDateFromId, modalDateToId, modalDataTableId);
          showMasiveSettledStep(currentStep, TOTAL_STEPS, modalStepBaseId, modalInstructionsContainerId, btnPrevId, btnNextId, btnDownloadId);
        }
      });

      document.getElementById(btnDownloadId).addEventListener("click", () => {
        settledSharedFunctions.showLoadingButton(btnDownloadId, "btn-barymont-black", true);

        if (selectedSettledsArray.length === 0) {
          createToast("warning", "liquidaciones no seleccionadas", "Debes seleccionar al menos una liquidacion para descargar el informe", 5000);
          settledSharedFunctions.updateButtonLabel(btnDownloadId, "Descargar facturas", "btn-barymont-black");
          return;
        }

        const dateFromValue = document.getElementById(modalDateFromId).value;
        const dateToValue = document.getElementById(modalDateToId).value;

        fetchGetSettlementUrlToDownload(dateFromValue, dateToValue, selectedSettledsArray).then((response) => {
          if (response.status === 0) {
            settledSharedFunctions.updateButtonLabel(btnDownloadId, "Descargar facturas", "btn-barymont-black");
            createToast("error", "Error", "Ocurrió un error al descargar las liquidaciones", 5000);
            return;
          }

          if (response.status === 1) {
            sendDataTocontroller(response.data.exportDataUrlArray, dateFromValue, dateToValue, selectedSettledsArray);
          }
          settledSharedFunctions.updateButtonLabel(btnDownloadId, "Descargar facturas", "btn-barymont-black");
        });
      });

      document.getElementById(btnOpenModalId)?.addEventListener("click", () => {
        $("#" + modalId).modal("show");
        resetMasiveSettlementFormData(modalDateFromId, modalDateToId, modalDataTableId, btnPrevId, btnNextId, btnDownloadId);
        showMasiveSettledStep(currentStep, TOTAL_STEPS, modalStepBaseId, modalInstructionsContainerId, btnPrevId, btnNextId, btnDownloadId);
      });
    }
  });
}

export default {};
