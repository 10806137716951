"use strict";

const countersContainerId = "counters-access-data-container";
const tableAccessData = "access-table-data";
const ticketData = "ticket-data";

async function loadCountersData() {
  const corporateEventId = document.getElementById("corporate-event-id").value;

  const response = await fetchCountersData(corporateEventId);

  if (response.success === false) {
    createToast("error", "Error ❌", "Ocurrió un error al obtener la información de los contadores de acceso.");
    return;
  }

  document.getElementById("total-access").textContent = response.counterAllAccess;
  document.getElementById("total-access-last-24-hours").textContent = response.counterLast24HoursAccess;
  document.getElementById("total-access-last-4-hours").textContent = response.counterLast4HoursAccess;
}

async function fetchCountersData(corporateEventId) {
  let formData = new FormData();
  formData.append("corporateEventId", corporateEventId);

  return await fetch("/herramientas/corporate-event-access-management/ajax/getAccessCountersData", {
    method: "POST",
    body: formData,
  }).then((response) => response.json());
}

async function checkAccessCode() {
  const accessCode = document.getElementById("access-code").value;
  const corporateEventId = document.getElementById("corporate-event-id").value;

  if (accessCode === "") {
    clearAccessCodeDataTable();
    createToast("error", "Error ❌", "El código de acceso no puede estar vacío.");
    return;
  }

  const response = await fetchCheckAccessCodeData(accessCode, corporateEventId);

  if (response.success === false) {
    clearAccessCodeDataTable();
    createToast("error", "Error ❌", response.message);
    document.getElementById("access-code").value = "";
    return;
  }

  document.getElementById("access-code").value = "";

  printAccessCodeDataTable(response.accessDataCollection, response.accessCode, response.corporateEventInscription, response.accessCodeImage);

  await loadCountersData();

  createToast("success", "Éxito ✅", response.message);
}

function clearAccessCodeDataTable() {
  let ticketDataContainer = document.getElementById(ticketData + "-container");
  let ticketDataDiv = document.getElementById(ticketData + "-data");
  let tableData = document.getElementById(tableAccessData);
  let tableZeroData = document.getElementById(tableAccessData + "-none");

  ticketDataContainer.classList.add("d-none");
  ticketDataDiv.innerHTML = "";
  tableData.classList.add("d-none");
  tableZeroData.classList.remove("d-none");
  tableZeroData.classList.add("d-flex");
}

function printAccessCodeDataTable(accessDataCollection, accessCode, corporateEventInscription, accessCodeImage) {
  let ticketDataContainer = document.getElementById(ticketData + "-container");
  let ticketDataDiv = document.getElementById(ticketData + "-data");
  let tableData = document.getElementById(tableAccessData);
  let tableZeroData = document.getElementById(tableAccessData + "-none");

  ticketDataContainer.classList.add("d-none");
  ticketDataDiv.innerHTML = "";

  let ticketLevel =
    corporateEventInscription.corporateEventInscriptionLevel !== null
      ? `<div class="col-3 align-content-center">
          <span class="badge w-100 py-4" style="background-color: ${corporateEventInscription.corporateEventInscriptionLevelBackgroundColor}; color: ${corporateEventInscription.corporateEventInscriptionLevelColor}; font-size: 20px;">${corporateEventInscription.corporateEventInscriptionLevelLabel}</span>
        </div>`
      : "";

  ticketDataDiv.insertAdjacentHTML(
    "beforeend",
    `<div class="container-fluid">
      <div class="row">
        <div class="col-6">
          <div class="row">
            <div class="col-12">
                <div class="input-group mb-2 mr-sm-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text bg-light" data-toggle="tooltip" title="Código de Accesso">Código Acceso</div>
                  </div>
                <input type="text" class="form-control" value="${corporateEventInscription.accessCode}" readonly>
              </div>
            </div>
            <div class="col-12">
                <div class="input-group mb-2 mr-sm-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text bg-light" data-toggle="tooltip" title="Código Inscripción">Código Inscripción</div>
                  </div>
                <input id="inscription-code" type="text" class="form-control" value="${corporateEventInscription.corporateEventInscriptionId}" readonly>
              </div>
            </div>
            <div class="col-12">
                <div class="input-group mb-2 mr-sm-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text bg-light" data-toggle="tooltip" title="Código Referido">Código Referido</div>
                  </div>
                <input type="text" class="form-control" value="${corporateEventInscription.referencedCode}" readonly>
              </div>
            </div>
            <div class="col-12">
                <div class="input-group mb-2 mr-sm-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text bg-light" data-toggle="tooltip" title="Nombre">Nombre</div>
                  </div>
                <input type="text" class="form-control" value="${corporateEventInscription.name + " " + corporateEventInscription.firstSurname + " " + corporateEventInscription.secondSurname}" readonly>
              </div>
            </div>
            <div class="col-12">
                <div class="input-group mb-2 mr-sm-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text bg-light" data-toggle="tooltip" title="Email">Email</div>
                  </div>
                <input type="text" class="form-control" value="${corporateEventInscription.email}" readonly>
              </div>
            </div>
            <div class="col-12">
                <div class="input-group mb-2 mr-sm-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text bg-light" data-toggle="tooltip" title="Teléfono">Teléfono</div>
                  </div>
                <input type="text" class="form-control" value="${corporateEventInscription.phone}" readonly>
              </div>
            </div>
          </div>
        </div>
        ${ticketLevel}
        <div class="col-${ticketLevel === "" ? "6" : "3"} align-content-center">
          <div class="row">
            <div class="col-12 d-flex justify-content-center">
              <img style="width: ${ticketLevel === "" ? "30" : "50"}%;" src="${accessCodeImage}"/>
            </div>
            <div class="col-12 d-flex justify-content-center">
              <p>${corporateEventInscription.accessCode}</p>
            </div>
          </div>
        </div>
      </div>`
  );

  ticketDataContainer.classList.remove("d-none");

  if (accessDataCollection.length === 0) {
    tableZeroData.classList.remove("d-none");
    tableZeroData.classList.add("d-flex");
    tableData.classList.add("d-none");
    return;
  }

  tableData.classList.remove("d-none");
  tableZeroData.classList.remove("d-flex");
  tableZeroData.classList.add("d-none");
  tableData.innerHTML = "";

  accessDataCollection.forEach((accessData) => {
    tableData.insertAdjacentHTML(
      "beforeend",
      `<div class="row d-flex justify-content-center">
        <div class="col-3 d-flex justify-content-center">
            <span class="font-weight-bolder">${accessData.createdAt}</span>
        </div>
        <div class="col-3 d-flex justify-content-center">
            <span class="font-weight-bolder">${corporateEventInscription.name + " " + corporateEventInscription.firstSurname + " " + corporateEventInscription.secondSurname}</span>
        </div>
        <div class="col-3 d-flex justify-content-center">
            <span class="font-weight-bolder">${accessCode}</span>
        </div>
        <div class="col-3 d-flex justify-content-center">
            <span class="font-weight-bolder">${accessData.userName}</span>
        </div>
      </div>`
    );
  });
}

async function fetchCheckAccessCodeData(accessCode, corporateEventId) {
  let formData = new FormData();
  formData.append("accessCode", accessCode);
  formData.append("corporateEventId", corporateEventId);

  return await fetch("/herramientas/corporate-event-access-management/ajax/getAccessCodeData", {
    method: "POST",
    body: formData,
  }).then((response) => response.json());
}

if (window) {
  window.addEventListener("load", async () => {
    if (window.location.pathname.includes("/herramientas/corporate-event-access-management/")) {
      let accessCodeInput = document.getElementById("access-code");
      accessCodeInput.focus();

      accessCodeInput.addEventListener("blur", () => {
        accessCodeInput.focus();
      });

      accessCodeInput.addEventListener("change", async () => {
        await checkAccessCode();
      });

      await loadCountersData();
      document.getElementById("load-" + countersContainerId).classList.add("d-none");
      document.getElementById(countersContainerId).classList.remove("d-none");

      setInterval(async () => {
        document.getElementById("load-" + countersContainerId).classList.remove("d-none");
        document.getElementById(countersContainerId).classList.add("d-none");
        await loadCountersData();
        document.getElementById("load-" + countersContainerId).classList.add("d-none");
        document.getElementById(countersContainerId).classList.remove("d-none");
      }, 30000);
    }
  });
}
