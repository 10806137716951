"use strict";

import { showLoadingContainerById, removeLoadingContainerById, showLoadingButton, updateButtonLabel } from "../shared/shared.js";

const USER_PERMISSIONS_CONFIG_DATA_CONTAINER_ID = "user-permission-config-data";
const USER_PERMISSIONS_PREFIX_ON_GROUP_ID = "group_";

// Renderiza los permisos de usuario en el contenedor especificado
function renderPermissions(responseData, userPermission, containerId, showAllPermissions, editable, showDefaultMessage, prefixOnInputId, colClass, withAccordion) {
  const container = document.getElementById(containerId);

  const allPermissions = {};
  Object.keys(responseData).forEach((groupKey) => {
    const group = responseData[groupKey];
    Object.keys(group.permission).forEach((permissionKey) => {
      allPermissions[permissionKey] = group.permission[permissionKey];
    });
  });

  Object.keys(responseData).forEach((groupKey) => {
    const group = responseData[groupKey];
    const groupPermissionsKeys = Object.keys(group.permission);
    const userPermissionsInGroup = groupPermissionsKeys.filter((permissionKey) => userPermission.includes(permissionKey));

    if (showAllPermissions || userPermissionsInGroup.length > 0) {
      const groupCard = createGroupCard(groupKey, group, editable, userPermissionsInGroup, showAllPermissions, prefixOnInputId, colClass, allPermissions, withAccordion);
      container.appendChild(groupCard);
    }
  });

  if (userPermission.length === 0 && showDefaultMessage && !showAllPermissions) {
    const message = document.createElement("span");
    message.classList.add("text-muted");
    message.textContent = "El usuario no tiene permisos asignados";

    const messageContainer = document.createElement("div");
    messageContainer.classList.add("col-12", "alert", "mt-3", "text-center");

    messageContainer.appendChild(message);
    container.appendChild(messageContainer);
  }

  $('[data-toggle="popover"]').popover("dispose");
  $('[data-toggle="popover"]').popover();

  if (editable) {
    container.addEventListener("change", handlePermissionChange);

    updateAllStyles(container);
  }
}

// Crear card para un grupo de permisos
function createGroupCard(groupId, group, editable, userPermissionsInGroup, showAllPermissions, prefixOnInputId, colClass, allPermissions, withAccordion = true) {
  const card = document.createElement("div");
  card.classList.add("card", "mb-2", "w-100");

  const cardHeader = document.createElement("div");
  cardHeader.classList.add("card-header", "d-flex", "align-items-center", "justify-content-between", "py-0");

  const groupCheckbox = createGroupCheckbox(groupId, group, editable, userPermissionsInGroup, prefixOnInputId);
  const groupTitle = document.createElement("label");

  groupTitle.setAttribute("for", `${USER_PERMISSIONS_PREFIX_ON_GROUP_ID}${groupId}`);
  groupTitle.classList.add("ml-2", "w-100", "text-left", "font-weight-bold");
  groupTitle.style.fontSize = "14px";
  groupTitle.textContent = group.label;

  cardHeader.appendChild(groupCheckbox);
  cardHeader.appendChild(groupTitle);

  let cardBody;
  if (withAccordion) {
    const accordionButton = document.createElement("span");
    accordionButton.classList.add("btn", "btn-link", "ml-auto", "accordion-toggle", "text-dark");
    accordionButton.setAttribute("data-toggle", "collapse");
    accordionButton.setAttribute("data-target", `#collapse_${groupId}`);
    accordionButton.setAttribute("aria-expanded", "true");
    accordionButton.setAttribute("aria-controls", `collapse_${groupId}`);
    accordionButton.innerHTML = '<i class="fas fa-chevron-up px-2"></i>';

    cardHeader.appendChild(accordionButton);

    cardBody = document.createElement("div");
    cardBody.classList.add("card-body", "collapse", "show", "px-2", "py-1");
    cardBody.id = `collapse_${groupId}`;

    accordionButton.addEventListener("click", function () {
      const icon = accordionButton.querySelector("i");
      if (cardBody.classList.contains("show")) {
        icon.classList.remove("fa-chevron-up");
        icon.classList.add("fa-chevron-down");
      } else {
        icon.classList.remove("fa-chevron-down");
        icon.classList.add("fa-chevron-up");
      }
    });
  } else {
    cardBody = document.createElement("div");
    cardBody.classList.add("card-body", "px-2", "py-1");
  }

  const cardBodyContainer = document.createElement("div");
  cardBodyContainer.classList.add("container-fluid");

  const cardBodyContainerRow = document.createElement("div");
  cardBodyContainerRow.classList.add("row");

  Object.keys(group.permission).forEach((permissionKey) => {
    const permission = group.permission[permissionKey];

    if (showAllPermissions || userPermissionsInGroup.includes(permissionKey)) {
      const dependenciesInfo = permission.dependsOn.map((dependencyKey) => allPermissions[dependencyKey]);

      const permissionElement = createPermissionElement(permission, editable, groupId, userPermissionsInGroup, prefixOnInputId, dependenciesInfo);

      const permissionContainer = document.createElement("div");
      permissionContainer.classList.add(colClass);

      permissionContainer.appendChild(permissionElement);
      cardBodyContainerRow.appendChild(permissionContainer);
    }
  });

  cardBodyContainer.appendChild(cardBodyContainerRow);
  cardBody.appendChild(cardBodyContainer);

  card.appendChild(cardHeader);
  card.appendChild(cardBody);

  return card;
}

// Crear checkbox del grupo con 3 estados
function createGroupCheckbox(groupId, group, editable, userPermissionsInGroup, prefixOnInputId) {
  const groupCheckbox = document.createElement("input");

  groupCheckbox.type = "checkbox";
  groupCheckbox.classList.add("group-checkbox");
  groupCheckbox.id = `${prefixOnInputId}${prefixOnInputId !== "" ? "_" : ""}${USER_PERMISSIONS_PREFIX_ON_GROUP_ID}${groupId}`;
  groupCheckbox.dataset.permissions = Object.keys(group.permission).join(",");

  if (userPermissionsInGroup.length === Object.keys(group.permission).length) {
    groupCheckbox.checked = true;
  }

  if (userPermissionsInGroup.length > 0 && userPermissionsInGroup.length < Object.keys(group.permission).length) {
    groupCheckbox.indeterminate = true;
  }

  if (userPermissionsInGroup.length === 0) {
    groupCheckbox.checked = false;
  }

  if (!editable) {
    groupCheckbox.disabled = true;
  }

  return groupCheckbox;
}

// Crear el elemento del permiso con dependencias
function createPermissionElement(permission, editable, groupId, userPermissionsInGroup, prefixOnInputId, dependenciesInfo) {
  const permissionWrapper = document.createElement("div");
  permissionWrapper.classList.add("form-group", "d-flex", "align-items-center", "mb-1", "w-100");

  permissionWrapper.setAttribute("tabindex", "0");
  permissionWrapper.setAttribute("data-toggle", "popover");
  permissionWrapper.setAttribute("data-trigger", "hover");
  permissionWrapper.setAttribute("data-placement", "top");
  permissionWrapper.setAttribute("data-html", "true");

  // Construir el contenido del popover incluyendo las dependencias
  let popoverContent = `<p class="mb-0">${permission.description}</p>`;

  if (dependenciesInfo.length > 0) {
    const dependencyLabels = dependenciesInfo.map((dep) => (dep ? dep.label : "Permiso no encontrado"));
    popoverContent += `<strong>Depende de:</strong><ul>`;
    dependencyLabels.forEach((label) => {
      popoverContent += `<li>- ${label}</li>`;
    });
    popoverContent += `</ul>`;
  }

  permissionWrapper.setAttribute("data-content", popoverContent);
  permissionWrapper.setAttribute("role", "span");

  // Crear el checkbox de permiso
  const permissionCheckbox = document.createElement("input");
  permissionCheckbox.type = "checkbox";
  permissionCheckbox.classList.add("permission-checkbox");
  permissionCheckbox.id = `${prefixOnInputId}${prefixOnInputId !== "" ? "_" : ""}${permission.value}`;
  permissionCheckbox.dataset.dependsOn = permission.dependsOn.join(",");
  permissionCheckbox.dataset.group = groupId;
  permissionCheckbox.checked = userPermissionsInGroup.includes(permission.value);
  permissionCheckbox.setAttribute("tabindex", "0");

  if (!editable) {
    permissionCheckbox.disabled = true;
  }

  // Crear el label
  const labelText = document.createElement("label");
  labelText.setAttribute("for", permissionCheckbox.id);
  labelText.classList.add("ml-2", "badge", "badge-light", "d-inline-block", "text-left");

  labelText.style.fontSize = "12px";
  labelText.style.wordBreak = "break-word";
  labelText.style.whiteSpace = "normal";
  labelText.style.width = "calc(100% - 24px)";
  labelText.textContent = permission.label;

  // Crear el icono de interrogación
  const infoIcon = document.createElement("i");
  infoIcon.classList.add("far", "fa-circle-question", "ml-2", "text-muted", "float-right");

  // Añadir los elementos al contenedor
  permissionWrapper.appendChild(permissionCheckbox);
  permissionWrapper.appendChild(labelText);
  labelText.appendChild(infoIcon);

  return permissionWrapper;
}

function updateAllStyles(container) {
  const permissionCheckboxes = container.querySelectorAll("input[type='checkbox'].permission-checkbox");
  permissionCheckboxes.forEach((checkbox) => {
    const labelText = checkbox.nextElementSibling;
    if (checkbox.checked) {
      labelText.classList.remove("badge-light");
      labelText.style.backgroundColor = "#e0f4e4";
    } else {
      labelText.style.backgroundColor = "";
      labelText.classList.add("badge-light");
    }
  });

  const groupCheckboxes = container.querySelectorAll("input[type='checkbox'].group-checkbox");
  groupCheckboxes.forEach((checkbox) => {
    const card = checkbox.closest(".card");
    const cardHeader = card.querySelector(".card-header");

    if (checkbox.checked && !checkbox.indeterminate) {
      card.style.borderColor = "#28a745";
      cardHeader.style.backgroundColor = "#d4edda";
    } else {
      card.style.borderColor = "";
      cardHeader.style.backgroundColor = "";
    }
  });

  updateSelectedPermissionsBadge();
}

// Maneja el cambio de estado de los checkboxes de permisos y grupos
function handlePermissionChange(event) {
  const target = event.target;

  if (target.classList.contains("group-checkbox")) {
    handleGroupCheckboxChange(target);
  } else if (target.classList.contains("permission-checkbox")) {
    handlePermissionCheckboxChange(target);
  }

  updateSelectedPermissionsBadge();
}

// Maneja el cambio de estado de un checkbox de grupo de forma recursiva
function handleGroupCheckboxChange(groupCheckbox) {
  const permissionIds = groupCheckbox?.dataset.permissions.split(",");
  const isChecked = groupCheckbox.checked;

  permissionIds.forEach((permissionId) => {
    const permissionCheckbox = document.getElementById(permissionId);

    if (permissionCheckbox) {
      permissionCheckbox.checked = isChecked;
      handlePermissionCheckboxChange(permissionCheckbox);
    }
  });
}

// Maneja el cambio de estado de un checkbox de permiso de forma recursiva
function handlePermissionCheckboxChange(permissionCheckbox) {
  const dependsOn = permissionCheckbox?.dataset.dependsOn ? permissionCheckbox.dataset.dependsOn.split(",").filter(Boolean) : [];

  if (permissionCheckbox.checked) {
    // Activar las dependencias recursivamente
    dependsOn.forEach((dependentId) => {
      const dependentCheckbox = document.getElementById(dependentId);

      if (dependentCheckbox && !dependentCheckbox.checked) {
        dependentCheckbox.checked = true;
        handlePermissionCheckboxChange(dependentCheckbox);
      }
    });
  } else {
    // Deseleccionar las dependencias recursivamente
    const container = document.getElementById(USER_PERMISSIONS_CONFIG_DATA_CONTAINER_ID);
    const dependentCheckboxes = Array.from(container.querySelectorAll("input[type='checkbox']")).filter((checkbox) => checkbox.dataset.dependsOn && checkbox.dataset.dependsOn.includes(permissionCheckbox.id));

    dependentCheckboxes.forEach((dependentCheckbox) => {
      dependentCheckbox.checked = false;
      handlePermissionCheckboxChange(dependentCheckbox);
    });
  }

  updateGroupState(permissionCheckbox.dataset.group);
}

// Actualiza el estado del checkbox de grupo basado en los permisos individuales
function updateGroupState(groupId) {
  const groupCheckbox = document.getElementById(`${USER_PERMISSIONS_PREFIX_ON_GROUP_ID}${groupId}`);
  const permissionIds = groupCheckbox?.dataset.permissions.split(",");

  const totalPermissions = permissionIds.length;
  const activePermissions = permissionIds.filter((id) => document.getElementById(id).checked).length;

  if (activePermissions === totalPermissions) {
    groupCheckbox.checked = true;
    groupCheckbox.indeterminate = false;
  } else if (activePermissions > 0) {
    groupCheckbox.checked = false;
    groupCheckbox.indeterminate = true;
  } else {
    groupCheckbox.checked = false;
    groupCheckbox.indeterminate = false;
  }
}

async function loadUserPermissionsPanel(userId, containerId, showAllPermissions, editable, showDefaultMessage, prefixOnInputId = "", colClass = ["col-12"], withAccordion, btnAccessPermissionConfigId = null) {
  showLoadingContainerById(containerId);

  if (userId !== null) {
    if (!AppGbSession.checkUserHasPermission("User:UserPermissionManageAccessChecker")) {
      document.getElementById(containerId).innerHTML = `<div class="col-12 py-3 px-0">
        <div class="alert alert-info font-weight-bold py-3">
          <span>
          <i class="fas fa-info-circle pr-2"></i> No tienes permisos para acceder a esta sección
          </span>
        </div>
      </div>`;
      document.getElementById(btnAccessPermissionConfigId)?.remove();
      return;
    }

    const userPermissionsData = await fetchUserPermissions(userId);
    if (userPermissionsData.status === 0) {
      createToast("Error", "No se pudieron cargar los permisos", "error", 5000);
      return;
    }

    renderPermissions(userPermissionsData.data.allPermissions, userPermissionsData.data.permissions, containerId, showAllPermissions, editable, showDefaultMessage, prefixOnInputId, colClass, withAccordion);
  } else {
    const userPermissionsData = await fetchAllUserPermissions();
    renderPermissions(userPermissionsData.data, [], containerId, showAllPermissions, editable, showDefaultMessage, prefixOnInputId, colClass, withAccordion);
  }

  removeLoadingContainerById(containerId);
  initializePermissionCheckboxes();
}

async function fetchUserPermissions(userId) {
  let formData = new FormData();
  formData.append("userId", userId);

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/user-permission-management/ajax/getUserPermissions", requestOptions).then((response) => response.json());
}

async function fetchUserPermissionsProfiles() {
  let requestOptions = {
    method: "POST",
    redirect: "follow",
  };

  return await fetch("/herramientas/user-permission-management/ajax/getUserPermissionsProfiles", requestOptions).then((response) => response.json());
}

async function fetchAllUserPermissions() {
  let requestOptions = {
    method: "POST",
    redirect: "follow",
  };

  return await fetch("/herramientas/user-permission-management/ajax/getAllPermissions", requestOptions).then((response) => response.json());
}

async function fetchUpdateUserPermissions(userId, selectedPermissions) {
  const formData = new FormData();

  formData.append("userId", userId);
  formData.append("permissions", JSON.stringify(selectedPermissions));

  const requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/user-permission-management/ajax/updateUserPermissions", requestOptions).then((response) => response.json());
}

async function printUserPermissionsProfilesSelect(userProfileSelectId, containerId, btnPermissionsSelectAllId, selectedValue = null) {
  const userPermissionsProfilesData = await fetchUserPermissionsProfiles();
  const container = document.getElementById(containerId);

  container.innerHTML = "";

  const select = document.createElement("select");
  select.classList.add("selectpicker", "form-control");
  select.id = userProfileSelectId;

  const placeholder = document.createElement("option");
  placeholder.value = "";
  placeholder.textContent = "Selecciona un perfil";
  placeholder.classList.add("btn", "btn-block", "btn-sm", "w-100", "bg-light", "text-secondary", "font-weight-bold", "mb-1");
  placeholder.disabled = true;

  if (selectedValue === null) {
    placeholder.selected = true;
  }

  select.appendChild(placeholder);

  userPermissionsProfilesData.data.forEach((profile) => {
    const option = document.createElement("option");
    option.value = profile.value;
    option.textContent = profile.label;

    option.classList.add("btn", "btn-block", "btn-sm", "w-100", profile.styles.backgroundColor, `text-${profile.styles.textColor}`, "font-weight-bold", "mb-1");

    if (profile.value === selectedValue) {
      option.selected = true;
    }

    select.appendChild(option);
  });

  container.appendChild(select);

  $("#" + userProfileSelectId).selectpicker("refresh");

  document.getElementById(userProfileSelectId).addEventListener("change", (event) => {
    const container = document.getElementById(USER_PERMISSIONS_CONFIG_DATA_CONTAINER_ID);
    const checkboxes = container.querySelectorAll("input[type='checkbox']");

    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
      checkbox.indeterminate = false;
    });

    const selectedProfile = userPermissionsProfilesData.data.find((profile) => profile.value === event.target.value);
    if (selectedProfile && selectedProfile.defaultPermissions) {
      selectedProfile.defaultPermissions.forEach((permissionId) => {
        const input = container.querySelector(`input[id='${permissionId}']`);
        if (input) {
          input.checked = true;
          handlePermissionCheckboxChange(input);
        }
      });
    }

    updateToggleButtonState(btnPermissionsSelectAllId, USER_PERMISSIONS_CONFIG_DATA_CONTAINER_ID);
    updateAllStyles(container);
  });
}

function printSelectAllCheckbox(containerId, permissionContainerId, btnPermissionsSelectAllId, userProfileSelectId) {
  const container = document.getElementById(containerId);
  container.innerHTML = "";

  const toggleAllBtn = document.createElement("span");

  toggleAllBtn.classList.add("btn", "btn-barymont-red", "w-100");
  toggleAllBtn.textContent = "Seleccionar todos";
  toggleAllBtn.setAttribute("data-state", "select");
  toggleAllBtn.id = btnPermissionsSelectAllId;

  container.appendChild(toggleAllBtn);

  updateToggleButtonState(btnPermissionsSelectAllId, permissionContainerId);

  toggleAllBtn.addEventListener("click", () => {
    const currentState = toggleAllBtn.getAttribute("data-state");
    const shouldSelectAll = currentState === "select";

    toggleAllCheckboxes(permissionContainerId, shouldSelectAll);

    updateToggleButtonState(btnPermissionsSelectAllId, permissionContainerId);
    updateAllStyles(document.getElementById(USER_PERMISSIONS_CONFIG_DATA_CONTAINER_ID));

    document.getElementById(userProfileSelectId).value = "";
    $("#" + userProfileSelectId).selectpicker("refresh");
  });
}

function printToggleAccordionButton(containerId) {
  const container = document.getElementById(containerId);
  container.innerHTML = "";

  const toggleAccordionBtn = document.createElement("button");
  toggleAccordionBtn.classList.add("btn", "btn-barymont-grey", "w-100");
  toggleAccordionBtn.textContent = "Contraer todo";
  toggleAccordionBtn.setAttribute("data-state", "collapse");

  container.appendChild(toggleAccordionBtn);

  toggleAccordionBtn.addEventListener("click", () => {
    const currentState = toggleAccordionBtn.getAttribute("data-state");
    const shouldCollapseAll = currentState === "collapse";
    const accordionButtons = document.querySelectorAll(`#${USER_PERMISSIONS_CONFIG_DATA_CONTAINER_ID} .accordion-toggle`);

    accordionButtons.forEach((button) => {
      const target = document.querySelector(button.getAttribute("data-target"));
      if (shouldCollapseAll && target.classList.contains("show")) {
        button.click();
      } else if (!shouldCollapseAll && !target.classList.contains("show")) {
        button.click();
      }
    });

    toggleAccordionBtn.setAttribute("data-state", shouldCollapseAll ? "expand" : "collapse");
    toggleAccordionBtn.textContent = shouldCollapseAll ? "Expandir todo" : "Contraer todo";
  });

  initializePermissionCheckboxes();
}

function updateToggleButtonState(buttonId, containerId) {
  const button = document.getElementById(buttonId);
  const container = document.getElementById(containerId);
  const checkboxes = container.querySelectorAll("input[type='checkbox']");

  const allChecked = Array.from(checkboxes).every((checkbox) => checkbox.checked);

  if (allChecked) {
    button.setAttribute("data-state", "deselect");
    button.textContent = "Limpiar selecciones";
    button.classList.remove("btn-barymont-red");
    button.classList.add("btn-barymont-black");
  } else {
    button.setAttribute("data-state", "select");
    button.textContent = "Seleccionar todos";
    button.classList.remove("btn-barymont-black");
    button.classList.add("btn-barymont-red");
  }
}

function toggleAllCheckboxes(containerId, shouldSelect) {
  const container = document.getElementById(containerId);
  const checkboxes = container.querySelectorAll("input[type='checkbox']");

  checkboxes.forEach((checkbox) => {
    checkbox.checked = shouldSelect;
    checkbox.indeterminate = false;
  });
}

function initializePermissionCheckboxes() {
  const container = document.getElementById(USER_PERMISSIONS_CONFIG_DATA_CONTAINER_ID);

  if (!container) return;

  container.querySelectorAll("input.permission-checkbox[type='checkbox']").forEach((checkbox) => {
    checkbox.addEventListener("change", () => {
      handlePermissionCheckboxChange(checkbox);
      updateSelectedPermissionsBadge();
    });
  });

  container.querySelectorAll("input.group-checkbox[type='checkbox']").forEach((checkbox) => {
    checkbox.addEventListener("change", () => {
      handleGroupCheckboxChange(checkbox);
      updateSelectedPermissionsBadge();
    });
  });
}

function updateSelectedPermissionsBadge() {
  const container = document.getElementById(USER_PERMISSIONS_CONFIG_DATA_CONTAINER_ID);
  const badge = document.getElementById("selected-permissions-badge");

  if (!container || !badge) return;

  const selectedPermissionsCount = Array.from(container.querySelectorAll("input.permission-checkbox[type='checkbox']:checked")).length;

  badge.textContent = `${selectedPermissionsCount} permisos seleccionados`;
}

async function loadAndOpenPermissionsConfigPanelModal(userId, userPermissionsDataContainerId, userProfileSelectId) {
  const randomModalId = Math.random().toString(36).substring(7);

  const userPermissionsProfileDataContainerId = "user-permission-profile-data";
  const btnSaveUserPermissionsConfig = "btn-save-user-permissions-config";
  const btnToggleAccordionId = "btn-toggle-accordion";
  const userPermissionsSelectAllId = "user-permissions-select-all";
  const btnPermissionsSelectAllId = "btn-permissions-select-all";
  const selectedPermissionsBadgeId = "selected-permissions-badge";

  let modal = document.createElement("div");
  modal.id = randomModalId;
  modal.classList.add("modal", "fade");
  modal.setAttribute("tabindex", "-1");
  modal.setAttribute("role", "dialog");
  modal.setAttribute("aria-labelledby", "user-permission-data-modal");
  modal.setAttribute("aria-hidden", "true");

  modal.insertAdjacentHTML(
    "beforeend",
    `<div class="modal-xl modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <form action="javascript:void(0);" class="needs-validation" novalidate>
                <div class="modal-header">
                    <h1 class="modal-title">Configurador de permisos de usuario</h1>
                </div>
                <div class="modal-body">
                  <div class="container-fluid">
                      <div class="row mb-3">
                        <div id="${userPermissionsProfileDataContainerId}" class="col-lg-6"></div>
                        <div id="${btnToggleAccordionId}" class="col-lg-3"></div>
                        <div id="${userPermissionsSelectAllId}" class="col-lg-3"></div>
                      </div>
                      <div class="row scroll-barymont-red" style="max-height: 70vh; overflow-y: auto;">
                        <div id="${USER_PERMISSIONS_CONFIG_DATA_CONTAINER_ID}" class="col-12"></div>
                      </div>
                  </div>
                </div>
                <div class="modal-footer">
                    <span id="${selectedPermissionsBadgeId}" class="badge badge-dark mr-2" style="font-size:14px;">0 permisos seleccionados</span>
                    <button type="button" id="${btnSaveUserPermissionsConfig}" class="btn btn-barymont-red">Guardar</button>
                    <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
                </div>
            </form>
        </div>
    </div>`
  );

  document.body.appendChild(modal);

  printUserPermissionsProfilesSelect(userProfileSelectId, userPermissionsProfileDataContainerId, btnPermissionsSelectAllId);
  await loadUserPermissionsPanel(userId, USER_PERMISSIONS_CONFIG_DATA_CONTAINER_ID, true, true, false, "", ["col-lg-6"], true);
  printSelectAllCheckbox(userPermissionsSelectAllId, USER_PERMISSIONS_CONFIG_DATA_CONTAINER_ID, btnPermissionsSelectAllId, userProfileSelectId);
  printToggleAccordionButton(btnToggleAccordionId);

  $("#" + randomModalId).modal("show");

  try {
    document.getElementById(USER_PERMISSIONS_CONFIG_DATA_CONTAINER_ID).addEventListener("change", () => {
      updateToggleButtonState(btnPermissionsSelectAllId, USER_PERMISSIONS_CONFIG_DATA_CONTAINER_ID);
      updateAllStyles(document.getElementById(USER_PERMISSIONS_CONFIG_DATA_CONTAINER_ID));
    });

    document.getElementById(btnSaveUserPermissionsConfig).addEventListener("click", async (event) => {
      event.preventDefault();

      showLoadingButton(btnSaveUserPermissionsConfig, "btn-barymont-red");

      const container = document.getElementById(USER_PERMISSIONS_CONFIG_DATA_CONTAINER_ID);

      // Obtener los permisos seleccionados
      const selectedPermissions = Array.from(container.querySelectorAll("input[type='checkbox']"))
        .filter((input) => !input.id.includes(USER_PERMISSIONS_PREFIX_ON_GROUP_ID) && input.checked)
        .map((input) => input.id);

      // Obtener los permisos actuales del usuario
      const currentPermissionsResponse = await fetchUserPermissions(userId);
      const currentPermissions = currentPermissionsResponse.data.permissions || [];

      // Obtener la lista completa de permisos (con labels, values y grupo)
      const allPermissions = Object.values(currentPermissionsResponse.data.allPermissions).flatMap((group) =>
        Object.keys(group.permission).map((permissionKey) => ({
          value: permissionKey,
          label: group.permission[permissionKey].label,
          group: group.label,
        }))
      );

      const getPermissionDiff = (permissionsA, permissionsB) =>
        permissionsA
          .filter((permissionValue) => !permissionsB.includes(permissionValue))
          .map((permissionValue) => allPermissions.find((perm) => perm.value === permissionValue))
          .filter(Boolean);

      const removedPermissions = getPermissionDiff(currentPermissions, selectedPermissions);
      const addedPermissions = getPermissionDiff(selectedPermissions, currentPermissions);

      if (removedPermissions.length === 0 && addedPermissions.length === 0) {
        createToast("info", "No hay cambios", "No se han realizado cambios en los permisos del usuario", 5000);
        updateButtonLabel(btnSaveUserPermissionsConfig, "Guardar", "btn-barymont-red");
        return;
      }
      savePermissionsSelectedsOnLocalStorage(USER_PERMISSIONS_CONFIG_DATA_CONTAINER_ID, "selectedPermissions");
      createConfirmationModal(removedPermissions, addedPermissions, userPermissionsDataContainerId, userId, selectedPermissions, randomModalId);
      updateButtonLabel(btnSaveUserPermissionsConfig, "Guardar", "btn-barymont-red");
    });

    $("#" + randomModalId).on("hidden.bs.modal", function () {
      document.getElementById(randomModalId).remove();
    });
  } catch (error) {
    console.error(error);
    createToast("error", "Error ❌", "Ha ocurrido un error al intentar obtener los permisos del usuario", 5000);
    return;
  }
}

function createConfirmationModal(removedPermissions, addedPermissions, userPermissionsDataContainerId, userId, selectedPermissions, configModalId) {
  const btnConfirmPermissionsUpdateId = "btn-confirm-save";
  const randomConfirmationModalId = Math.random().toString(36).substring(7);

  let modal = document.createElement("div");
  modal.id = randomConfirmationModalId;
  modal.classList.add("modal", "fade");
  modal.setAttribute("tabindex", "-1");
  modal.setAttribute("role", "dialog");
  modal.setAttribute("aria-labelledby", "confirmation-modal");
  modal.setAttribute("aria-hidden", "true");

  modal.innerHTML = `
    <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">Confirmar Cambios de Permisos</h1>
        </div>
        <div class="modal-body scroll-barymont-red" style="max-height: 80vh; overflow-y: auto;">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-6">
                ${createPermissionsCard(removedPermissions, "bg-danger", "Permisos eliminados", "No se eliminarán permisos.")}
              </div>
              <div class="col-lg-6">
                ${createPermissionsCard(addedPermissions, "bg-success", "Permisos otorgados", "No se otorgarán permisos nuevos.")}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" id="${btnConfirmPermissionsUpdateId}" class="btn btn-barymont-red">Confirmar</button>
          <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  `;

  document.body.appendChild(modal);

  $("#" + randomConfirmationModalId).modal("show");

  document.getElementById(btnConfirmPermissionsUpdateId).addEventListener("click", async () => {
    showLoadingButton(btnConfirmPermissionsUpdateId, "btn-barymont-red");

    const response = await fetchUpdateUserPermissions(userId, selectedPermissions);

    if (response.status === 1) {
      createToast("success", "Permisos actualizados", response.message, 5000);
    } else {
      createToast("error", "Error al actualizar los permisos", response.message, 5000);
    }

    updateButtonLabel(btnConfirmPermissionsUpdateId, "Guardar", "btn-barymont-red");

    $("#" + randomConfirmationModalId).modal("hide");
    $("#" + configModalId).modal("hide");
    loadUserPermissionsPanel(userId, userPermissionsDataContainerId, false, false, true, "preview", ["col-12"], false);
  });

  $("#" + randomConfirmationModalId).on("hidden.bs.modal", function () {
    document.getElementById(randomConfirmationModalId).remove();
  });
}

function createPermissionsCard(permissions, cardClass, cardTitle, emptyMessage) {
  const boxShadowStyle = "box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);";

  if (permissions.length === 0) {
    return `
      <div class="card mb-3" style="${boxShadowStyle}">
        <div class="card-header ${cardClass}">
          <h5 class="card-title mb-0 text-white font-weight-bold">${cardTitle}</h5>
        </div>
        <div class="card-body py-2">
          <span class="text-muted">${emptyMessage}</span>
        </div>
      </div>
    `;
  }

  //Agrupación de permisos por grupo
  const groupedPermissions = permissions.reduce((groups, permission) => {
    groups[permission.group] = groups[permission.group] || [];
    groups[permission.group].push(permission.label);
    return groups;
  }, {});

  return `
    <div class="card mb-3" style="${boxShadowStyle}">
      <div class="card-header ${cardClass}">
        <h5 class="card-title mb-0 text-white font-weight-bold">${cardTitle}</h5>
      </div>
      <div class="card-body py-2">
        ${Object.entries(groupedPermissions)
          .map(
            ([groupLabel, permissionLabels]) => `
          <div class="card px-2 py-0 mb-2">
            <strong>${groupLabel}</strong>
            <ul class="list-group list-group-flush">
              ${permissionLabels.map((label) => `<li class="list-group-item p-1">${label}</li>`).join("")}
            </ul>
          </div>
        `
          )
          .join("")}
      </div>
    </div>
  `;
}

function savePermissionsSelectedsOnLocalStorage(containerId, localStorageKey) {
  const container = document.getElementById(containerId);

  // Filtramos los inputs que no contienen 'USER_PERMISSIONS_PREFIX_ON_GROUP_ID' en su id
  const selectedPermissions = Array.from(container.querySelectorAll("input[type='checkbox']"))
    .filter((input) => input.checked && !input.id.includes(USER_PERMISSIONS_PREFIX_ON_GROUP_ID))
    .map((input) => input.id);

  localStorage.setItem(localStorageKey, JSON.stringify(selectedPermissions));
}

function getPermissionsSelectedsOnLocalStorage(localStorageKey) {
  return JSON.parse(localStorage.getItem(localStorageKey));
}

function clearPermissionsFromLocalStorage(localStorageKey) {
  localStorage.removeItem(localStorageKey);
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname.includes("/herramientas/user-management/")) {
      const userId = document.getElementById("user-id").value;
      const userPermissionsDataContainerId = "user-permission-data";
      const btnUserPermissionDataModalId = "btn-user-permission-data-modal";
      const userProfilePermissionsSelectId = "user-permissions-profiles-select";

      loadUserPermissionsPanel(userId, userPermissionsDataContainerId, false, false, true, "preview", ["col-12"], false, btnUserPermissionDataModalId);

      document.getElementById(btnUserPermissionDataModalId)?.addEventListener("click", async () => {
        await loadAndOpenPermissionsConfigPanelModal(userId, userPermissionsDataContainerId, userProfilePermissionsSelectId).then(() => {
          updateSelectedPermissionsBadge();
        });
      });
    }
  });
}

//! Exponiendo las funciones al ámbito global hasta que el fichero "user_management.js" sea refactorizado a type module
window.loadUserPermissionsPanel = loadUserPermissionsPanel;
window.savePermissionsSelectedsOnLocalStorage = savePermissionsSelectedsOnLocalStorage;
window.getPermissionsSelectedsOnLocalStorage = getPermissionsSelectedsOnLocalStorage;
window.clearPermissionsFromLocalStorage = clearPermissionsFromLocalStorage;
window.printUserPermissionsProfilesSelect = printUserPermissionsProfilesSelect;
window.printSelectAllCheckbox = printSelectAllCheckbox;
window.printToggleAccordionButton = printToggleAccordionButton;
window.updateToggleButtonState = updateToggleButtonState;
window.updateAllStyles = updateAllStyles;

export { loadUserPermissionsPanel, savePermissionsSelectedsOnLocalStorage, getPermissionsSelectedsOnLocalStorage, clearPermissionsFromLocalStorage, printUserPermissionsProfilesSelect, printSelectAllCheckbox, updateToggleButtonState, updateAllStyles };
