import { v4 as uuidv4 } from "uuid";

export default class Uuid {
  private constructor(private readonly _value: string) {}

  public static random(): Uuid {
    const uuid = typeof crypto !== "undefined" && typeof crypto.randomUUID === "function" ? crypto.randomUUID() : uuidv4();
    return new Uuid(uuid);
  }

  public static fromString(value: string): Uuid {
    if (!Uuid.isValid(value)) {
      throw new Error(`UUID inválido: ${value}`);
    }
    return new Uuid(value);
  }

  public get value(): string {
    return this._value;
  }

  public static isValid(uuid: string): boolean {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return regex.test(uuid);
  }
}
