"use strict";

var FILTER_STATE_LOCAL_STORAGE_KEY = "GOAL_PERIOD_FILTER_STATE_";
var FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME = 1000 * 60 * 20;

function getStateOfCustomFilters() {
  return {
    startDatePeriodFilter: $("#start-date").val(),
    finishDatePeriodFilter: $("#finish-date").val(),
    checkAllTemporalityFilter: $("#cboxAll").prop("checked") === true ? 1 : 0,
    checkCategory: $('[data-filter="true"]:checked').data("category"),
  };
}

function recreateStateOfCustomFilters(localStorageData) {
  if (localStorageData.customFilter === undefined) {
    return false;
  }

  $("#start-date").val(localStorageData.customFilter.startDatePeriodFilter);
  $("#finish-date").val(localStorageData.customFilter.finishDatePeriodFilter);
  $("#cboxAll").prop("checked", localStorageData.customFilter.checkAllTemporalityFilter === 1 ? true : false);

  $('[data-filter="true"]').each(function () {
    if ($(this).data("category") === localStorageData.customFilter.checkCategory) {
      $(this).prop("checked", true);
      return;
    }
  });
}

function checkDateFilters() {
  let startDate = new Date(document.getElementById("start-date").value);
  let finishDate = new Date(document.getElementById("finish-date").value);

  if (finishDate <= startDate) {
    createToast("warning", "Fecha introducida, incorrecta", "La fecha INICIO no puede ser mayor o igual que la fecha de FIN", 3000);

    return;
  }

  $("#goal-periods-table").DataTable().ajax.reload();
}

function openModalDeleteGoalPeriod(goalPeriodId) {
  document.getElementById("modal-delete-goal-period-confirm").dataset.goalPeriodId = goalPeriodId;
  $("#modal-delete-goal-period").modal("show");
}

async function openModalGoalPeriod(goalPeriodId = null) {
  let modalTitle = document.getElementById("modal-goal-period-title");
  let saveButton = document.getElementById("modal-btn-submit-goal");

  if (goalPeriodId === null) {
    modalTitle.innerHTML = "Crear Período";
    saveButton.dataset.action = "create";

    document.getElementById("modal-goal-period-data-id").value = "";
    document.getElementById("modal-goal-period-data-name").value = "";
    document.getElementById("modal-goal-period-data-category").value = "";
    document.getElementById("modal-goal-period-data-start-date").value = "";
    document.getElementById("modal-goal-period-data-finish-date").value = "";
  } else {
    modalTitle.innerHTML = "Editar Período";
    saveButton.dataset.action = "update";
    let goalPeriodData = await fetchGoalPeriodData(goalPeriodId);

    document.getElementById("modal-goal-period-data-id").value = goalPeriodData.data.goalPeriodId;
    document.getElementById("modal-goal-period-data-name").value = goalPeriodData.data.description;
    document.getElementById("modal-goal-period-data-category").value = goalPeriodData.data.categoryValue;
    document.getElementById("modal-goal-period-data-start-date").value = goalPeriodData.data.startDate;
    document.getElementById("modal-goal-period-data-finish-date").value = goalPeriodData.data.finishDate;
  }

  $("#modal-goal-period-data-category").selectpicker("refresh");
  $("#modal-goal-period").modal("show");
}

async function fetchGoalPeriodData(goalPeriodId) {
  let formData = new FormData();

  formData.append("goalPeriodId", goalPeriodId);

  let requestData = {
    method: "POST",
    body: formData,
  };

  return await fetch("/herramientas/goals-periods/ajax/getGoalPeriodData", requestData).then((response) => response.json());
}

async function saveGoalPeriodData() {
  let saveButton = document.getElementById("modal-btn-submit-goal");
  let response = null;
  if (!checkDataIsValid()) {
    return;
  }

  let formData = new FormData();

  formData.append("goalPeriodId", $("#modal-goal-period-data-id").val());
  formData.append("description", $("#modal-goal-period-data-name").val());
  formData.append("category", $("#modal-goal-period-data-category").val());
  formData.append("startDate", $("#modal-goal-period-data-start-date").val());
  formData.append("finishDate", $("#modal-goal-period-data-finish-date").val());
  formData.append("status", $("#modal-goal-period-data-status").val());

  let requestData = {
    method: "POST",
    body: formData,
  };

  if (saveButton.dataset.action === "create") {
    response = await fetch("/herramientas/goals-periods/ajax/createGoalPeriod", requestData).then((response) => response.json());
  }

  if (saveButton.dataset.action === "update") {
    response = await fetch("/herramientas/goals-periods/ajax/updateGoalPeriod", requestData).then((response) => response.json());
  }

  if (response.status) {
    $("#modal-goal-period-data-category").selectpicker("refresh");
    $("#modal-goal-period").modal("hide");
    $("#goal-periods-table").DataTable().ajax.reload();
    createToast("success", "Datos guardados", "Los datos se guardaron correctamente", 3000);
  } else {
    createToast("error", "Error al guardar", "Ocurrió un error al guardar los datos, intentalo de nuevo más tarde", 3000);
  }
}

function checkDataIsValid() {
  let description = document.getElementById("modal-goal-period-data-name").value;
  let category = document.getElementById("modal-goal-period-data-category").value;
  let startDate = document.getElementById("modal-goal-period-data-start-date").value;
  let finishDate = document.getElementById("modal-goal-period-data-finish-date").value;

  if (description === "") {
    createToast("warning", "Descripción vacía", "Debe introducir una descripción", 3000);
    return false;
  }

  if (category === "") {
    createToast("warning", "Categoría vacía", "Debe introducir una categoría", 3000);
    return false;
  }

  if (startDate === "") {
    createToast("warning", "Fecha vacía", "Debe introducir una fecha de inicio", 3000);
    return false;
  }

  if (finishDate === "") {
    createToast("warning", "Fecha vacía", "Debe introducir una fecha de fin", 3000);
    return false;
  }

  if (new Date(finishDate) < new Date(startDate)) {
    createToast("warning", "Fecha introducida, incorrecta", "La fecha de inicio no puede ser mayor que la fecha de fin", 3000);

    return false;
  }

  return true;
}

function deleteGoalPeriod(goalPeriodId) {
  let formData = new FormData();

  formData.append("goalPeriodId", goalPeriodId);

  let requestData = {
    method: "POST",
    body: formData,
  };

  fetch("/herramientas/goals-periods/ajax/deleteGoalPeriod", requestData)
    .then((response) => response.json())
    .then((response) => {
      if (response.status) {
        $("#modal-delete-goal-period").modal("hide");
        $("#goal-periods-table").DataTable().ajax.reload();
        createToast("success", "Período eliminado", "El período se eliminó correctamente", 3000);
      } else {
        createToast("error", "Error al eliminar", "Ocurrió un error al eliminar el período, intentalo de nuevo más tarde", 3000);
      }
    });
}

function resetFilters() {
  $("#cboxAll").prop("checked", true);
  $("#start-date").val("");
  $("#finish-date").val("");
  $("#goal-periods-table").DataTable().search("").draw();
}

if (window) {
  if (window.location.pathname.includes("/herramientas/goals-periods")) {
    window.addEventListener("load", function () {
      $("#goal-periods-table").DataTable({
        language: {
          sProcessing: "Procesando Datos...",
          sLengthMenu: "Mostrar _MENU_ Períodos",
          sZeroRecords: "No se encontraron Períodos coincidentes con el criterio de búsqueda.",
          sEmptyTable: "Ningún Período coincidente con el criterio de búsqueda.",
          sInfo: "Mostrando Período del _START_ al _END_ de un total de _TOTAL_ Períodos",
          sInfoEmpty: "Mostrando Períodos del 0 al 0 de un total de 0 Períodos",
          sInfoFiltered: "(Filtrados de un total de _MAX_ Períodos)",
          sInfoPostFix: "",
          sSearch: "<div class='input-group-prepend'><div class='input-group-text'><i class='fas fa-search'></i></div></div>",
          searchPlaceholder: "Ingrese su búsqueda...",
          sUrl: "",
          sInfoThousands: ",",
          sLoadingRecords: "Cargando Datos...",
          oPaginate: {
            sFirst: "Primera",
            sLast: "Última",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
          oAria: {
            sSortAscending: ": Activar para ordenar la columna de manera ascendente",
            sSortDescending: ": Activar para ordenar la columna de manera descendente",
          },
        },
        pagingType: "input",
        columnDefs: [
          {
            targets: [0, 1, 2, 3, 4],
            className: "dt-center",
          },
          {
            targets: [0, 1],
            searchable: true,
            orderable: false,
          },
          {
            targets: [2, 3],
            orderable: true,
          },
          {
            targets: [4],
            searchable: false,
            orderable: false,
          },
        ],
        order: [[3, "desc"]],
        processing: true,
        serverSide: true,
        scrollX: true,
        dom: '<"top"fl>Brt<"bottom"ip><"clear">',
        buttons: [
          {
            text: '<i class="fas fa-eraser mr-3"></i> Limpiar filtros',
            className: "btn btn-barymont-black my-2 my-md-0",
            action: function () {
              resetFilters();
            },
            init: function (api, node) {
              $(node).removeClass("dt-button");
            },
          },
          {
            text: '<i class="fas fa-plus mr-3"></i> Crear nuevo período',
            className: "btn btn-barymont-red btn-create-goal-period-table",
            action: function () {},
            init: function (api, node) {
              $(node).removeClass("dt-button");
            },
          },
        ],
        pageLength: 15,
        lengthMenu: [
          [15, 30, 50],
          [15, 30, 50],
        ],
        select: false,
        keys: true,
        searchHighlight: true,
        ajax: {
          url: "/herramientas/goals-periods/listprocessing",
          type: "post",
          data: function (d) {
            const startDatePeriodFilter = $("#start-date").val();
            const finishDatePeriodFilter = $("#finish-date").val();
            const checkAllTemporalityFilter = $("#cboxAll").prop("checked");
            $('[data-filter="true"]').each(function () {
              if ($(this).prop("checked")) d["checkCategory"] = $(this).data("category");
            });

            d.startDatePeriodFilter = startDatePeriodFilter != "" ? startDatePeriodFilter : "";
            d.finishDatePeriodFilter = finishDatePeriodFilter != "" ? finishDatePeriodFilter : "";
            d.checkAllTemporalityFilter = checkAllTemporalityFilter === true ? 1 : 0;
          },
          error: function (e) {
            if (e.status === 401) {
              createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
              setTimeout(function () {
                location.reload();
              }, 5000);
            } else {
              createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
            }
          },
        },
        fnDrawCallback: function () {
          $('[data-toggle="tooltip"]').tooltip();
        },
        stateSave: true,
        stateSaveCallback: function (settings, data) {
          let storageKey = `${FILTER_STATE_LOCAL_STORAGE_KEY}${settings.sInstance}_${getCookieValue("user_id")}`;

          data.customFilter = getStateOfCustomFilters();

          localStorage.setItem(storageKey, JSON.stringify(data));
        },
        stateLoadCallback: function (settings) {
          let storageKey = `${FILTER_STATE_LOCAL_STORAGE_KEY}${settings.sInstance}_${getCookieValue("user_id")}`;

          if (localStorage.getItem(storageKey) === null) {
            return false;
          }

          let localStorageData = JSON.parse(localStorage.getItem(storageKey));

          if (localStorageData.time < new Date().getTime() - FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME) {
            return false;
          }

          recreateStateOfCustomFilters(localStorageData);

          return localStorageData;
        },
      });

      document.getElementsByClassName("btn-create-goal-period-table")[0].addEventListener("click", function () {
        openModalGoalPeriod();
      });

      document.getElementById("goal-periods-filters").addEventListener("change", function (event) {
        if (event.target.classList.contains("triggerDatatableUpdateGoalPeriods")) {
          if (event.target.type === "date") {
            checkDateFilters();
            return;
          }
          $("#goal-periods-table").DataTable().ajax.reload();
        }
      });

      document.getElementById("goal-periods-table").addEventListener("click", function (event) {
        if (event.target.matches('[data-edit-goal-period="true"]')) {
          openModalGoalPeriod(event.target.dataset.goalPeriodId);
        }
      });

      document.getElementById("goal-periods-table").addEventListener("click", function (event) {
        if (event.target.matches('[data-delete-goal-period="true"]')) {
          openModalDeleteGoalPeriod(event.target.dataset.goalPeriodId);
        }
      });

      document.getElementById("modal-delete-goal-period-confirm").addEventListener("click", function (event) {
        deleteGoalPeriod(event.target.dataset.goalPeriodId);
      });

      document.getElementById("modal-btn-submit-goal")?.addEventListener("click", function () {
        saveGoalPeriodData();
      });
    });
  }
}
