"use strict";

export async function searchCountries() {
  const response = await fetch("/api/countries");
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}
