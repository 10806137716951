import ApiRequestError from "../../../api/core/domain/apiRequestError";
import getPromoterByDate from "../../../api/leads/application/getPromoterByDate";
import searchUser from "../../../api/users/application/searchUser";
import User from "../../../api/users/domain/user";
import { todayFormattedAsYYYYMMDD } from "../../shared/application/dates/dateTools";

export default async function searchCurrentLeadPromoter(leadId: string): Promise<User[]> {
  let currentLeadPromoter: User[];

  try {
    const promoterInfo = await getPromoterByDate({ leadId, date: todayFormattedAsYYYYMMDD() });
    const promoterUser = await searchUser({ uuid: promoterInfo.promoterId });

    currentLeadPromoter = [promoterUser];
  } catch (error) {
    if (error instanceof ApiRequestError && error.status === 404) {
      currentLeadPromoter = []; // No promoter assigned to the lead on the current date
    } else {
      console.error("Error obtaining current lead promoter:", error);
      throw error;
    }
  }

  return currentLeadPromoter;
}
