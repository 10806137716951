"use strict";

import settledSharedFunctions from "./settledShared.js";

let settlementForListToInvoiceDatatable;
let selectementToInvoiceSelectedsArray = [];
let maxSettlementToInvoice = 0;

async function fetchSettlementsToInvoiced() {
  let requestOptions = {
    method: "POST",
    redirect: "follow",
  };

  return await fetch("/herramientas/liquidaciones/ajax/getSettlementForListToInvoiced", requestOptions).then((response) => response.json());
}

function printContainerLoadingState(containerId) {
  document.getElementById(containerId).innerHTML = `
    <div class="col-12 text-center mt-5">
      <div class="spinner-border text-dark" role="status"></div>
      <div class="mt-3">Cargando liquidaciones disponibles para ser facturadas...</div>
    </div>`;
}

function clearContainer(containerId) {
  document.getElementById(containerId).innerHTML = "";
}

function printAlertMessage(containerId) {
  const container = document.getElementById(containerId);

  const divCol12AlertMessaje = document.createElement("div");
  divCol12AlertMessaje.className = "col-12";

  const alertDiv = document.createElement("div");
  alertDiv.className = "alert alert-primary w-100 mx-1 text-center mb-3";
  alertDiv.setAttribute("role", "alert");
  alertDiv.style.fontSize = "15px";
  alertDiv.style.fontWeight = "bold";
  alertDiv.textContent = "🖊️ Selecciona las liquidaciones pendientes que deseas facturar";

  divCol12AlertMessaje.appendChild(alertDiv);
  container.appendChild(divCol12AlertMessaje);
}

function createDatatableStructure(containerId, tableId) {
  const container = document.getElementById(containerId);

  const divCol12 = document.createElement("div");
  divCol12.className = "col-12";

  const table = document.createElement("table");
  table.id = tableId;
  table.className = "display nowrap compact row-border cell-border stripe hover w-100";

  divCol12.appendChild(table);
  container.appendChild(divCol12);
}

function printSettledForListToInvoicedDatatableByJson(data, tableId) {
  if ($.fn.DataTable.isDataTable("#" + tableId)) {
    $("#" + tableId)
      .DataTable()
      .destroy();
    $("#" + tableId).empty();
  }

  maxSettlementToInvoice = data.length;

  var transformedData = data.map(function (item) {
    return {
      settled_id: item.settledId,
      nif: item.nif.nif,
      name: item.nif.full_name,
      commission_quantity: item.numberOfCommissions,
      tax_base: {
        render: item.grossAmountTotal + " €",
        sort: function () {
          return item.grossAmountTotal.replace(/\s/g, "").replace(/\./g, "");
        },
      },
      iva_percentage: {
        render: item.nif.tax_area / 100 + " %",
        sort: function () {
          return item.nif.tax_area;
        },
      },
      iva_total: {
        render: item.ivaAmountTotal + " €",
        sort: function () {
          return item.ivaAmountTotal.replace(/\s/g, "").replace(/\./g, "");
        },
      },
      irpf_percentage: {
        render: item.nif.tax_personal / 100 + " %",
        sort: function () {
          return item.nif.tax_personal;
        },
      },
      irpf_total: {
        render: item.irpfAmountTotal + " €",
        sort: function () {
          return item.irpfAmountTotal.replace(/\s/g, "").replace(/\./g, "");
        },
      },
      total_liquid: {
        render: item.netAmountTotal + " €",
        sort: function () {
          return item.netAmountTotal.replace(/\s/g, "").replace(/\./g, "");
        },
      },
      dateTo: item.dateTo,
      dateFrom: item.dateFrom,
      service_providers: JSON.stringify(item.serviceProviderEnumCollection),
    };
  });

  settlementForListToInvoiceDatatable = $("#" + tableId).DataTable({
    language: {
      sProcessing: "Procesando datos...",
      sLengthMenu: "Mostrar _MENU_ liquidaciones para facturar",
      sZeroRecords: "No se encontraron liquidaciones para facturar coincidentes con el criterio de búsqueda.",
      sEmptyTable: "No hay ninguna liquidacion para facturar disponible",
      sInfo: "Mostrando liquidaciones para facturar del _START_ al _END_ de un total de _TOTAL_",
      sInfoEmpty: "Mostrando liquidaciones para facturar del 0 al 0 de un total de 0",
      sInfoFiltered: "(Filtrados de un total de _MAX_ liquidaciones para facturar)",
      sSearch: "<div class='input-group-prepend'><div class='input-group-text'><i class='fas fa-search'></i></div></div>",
      sUrl: "",
      sInfoThousands: ",",
      sLoadingRecords: "Cargando Datos...",
      oPaginate: {
        sFirst: "Primera",
        sLast: "Última",
        sNext: "Siguiente",
        sPrevious: "Anterior",
      },
      oAria: {
        sSortAscending: ": Activar para ordenar la columna de manera ascendente",
        sSortDescending: ": Activar para ordenar la columna de manera descendente",
      },
      select: {
        rows: {
          _: "",
        },
      },
    },
    pagingType: "input",
    order: [[0, "desc"]],
    processing: true,
    dom: '<"top"fl>Brt<"bottom"ip><"clear">',
    buttons: [
      {
        text: "Seleccionar todos",
        className: "btn btn-barymont-red my-1 select-all-settlement-to-invoice",
        action: function () {
          selectAllNifForSettled();
        },
        init: function (api, node) {
          $(node).removeClass("dt-button");
        },
      },
      {
        text: "Limpiar seleccion",
        className: "btn btn-barymont-black my-2 my-md-0 deselect-all-settlement-to-invoice",
        action: function () {
          resetSelectedRows();
        },
        init: function (api, node) {
          $(node).removeClass("dt-button");
        },
      },
      {
        text: "Haz click en un fila para seleccionar una liquidación para facturar",
        className: "btn btn-barymont-grey font-weight-bold total-selected not-clickable",
        init: function (api, node) {
          $(node).removeClass("dt-button");
        },
      },
    ],
    data: transformedData,
    columns: [
      {
        data: "settled_id",
        title: "id liquidación",
        visible: false,
      },
      {
        data: "nif",
        title: "NIF",
      },
      { data: "name", title: "Nombre", className: "text-left" },
      { data: "commission_quantity", title: "Nº Com.", className: "text-center" },
      {
        data: "tax_base",
        title: "B. Imponible",
        className: "text-right",
        render: function (data, type) {
          if (type === "sort") return data.sort;
          return data.render;
        },
      },
      {
        data: "iva_percentage",
        title: "% TAX Área",
        className: "text-right",
        render: function (data, type) {
          if (type === "sort") return data.sort;
          return data.render;
        },
      },
      {
        data: "iva_total",
        title: "I. TAX Área",
        className: "text-right",
        render: function (data, type) {
          if (type === "sort") return data.sort;
          return data.render;
        },
      },
      {
        data: "irpf_percentage",
        title: "% TAX Personal",
        className: "text-right",
        render: function (data, type) {
          if (type === "sort") return data.sort;
          return data.render;
        },
      },
      {
        data: "irpf_total",
        title: "I. TAX Personal",
        className: "text-right",
        render: function (data, type) {
          if (type === "sort") return data.sort;
          return data.render;
        },
      },
      {
        data: "total_liquid",
        title: "I. Líquido",
        className: "text-right",
        render: function (data, type) {
          if (type === "sort") return data.sort;
          return data.render;
        },
      },
    ],
    pageLength: 15,
    scrollX: true,
    lengthMenu: [
      [15, 25, 50],
      [15, 25, 50],
    ],
    select: {
      style: "multi",
      selector: "td",
      className: "clickable",
    },
    keys: true,
    searchHighlight: true,
    fnRowCallback: function (nRow) {
      updateSettlementToInvoicedDatatableActionButtons();
      return nRow;
    },
    fnDrawCallback: function () {
      updateSettlementToInvoicedDatatableActionButtons();
    },
  });

  function selectAllNifForSettled() {
    var allData = settlementForListToInvoiceDatatable.rows().data().toArray();

    allData.forEach(function (data) {
      if ($.inArray(data.settled_id, selectementToInvoiceSelectedsArray) === -1) {
        selectementToInvoiceSelectedsArray.push(data.settled_id);
      }
    });

    settlementForListToInvoiceDatatable.rows().nodes().to$().css("background-color", "var(--barymont-green)").select();
    updateSettlementToInvoicedDatatableActionButtons();
  }

  function resetSelectedRows() {
    selectementToInvoiceSelectedsArray = [];
    settlementForListToInvoiceDatatable.rows().deselect();
    settlementForListToInvoiceDatatable.rows().nodes().to$().css("background-color", "white");
    updateSettlementToInvoicedDatatableActionButtons();
  }

  settlementForListToInvoiceDatatable.on("select", function (e, dt, type, indexes) {
    const rowData = settlementForListToInvoiceDatatable.rows(indexes).data().toArray();

    if ($.inArray(rowData[0].settled_id, selectementToInvoiceSelectedsArray) === -1) {
      settlementForListToInvoiceDatatable.rows(indexes).nodes().to$().css("background-color", "var(--barymont-green)");
      selectementToInvoiceSelectedsArray.push(rowData[0].settled_id);
    }
    updateSettlementToInvoicedDatatableActionButtons();
  });

  settlementForListToInvoiceDatatable.on("deselect", function (e, dt, type, indexes) {
    const rowData = settlementForListToInvoiceDatatable.rows(indexes).data().toArray();

    settlementForListToInvoiceDatatable.rows(indexes).nodes().to$().css("background-color", "white");
    selectementToInvoiceSelectedsArray.splice($.inArray(rowData[0].settled_id, selectementToInvoiceSelectedsArray), 1);

    if ($(".select-all-settlement-to-invoice").text() === "Deseleccionar todos") {
      $(".select-all-settlement-to-invoice").text("Seleccionar todos");
      $(".select-all-settlement-to-invoice").removeClass("btn-barymont-green");
      $(".select-all-settlement-to-invoice").addClass("btn-barymont-red");
    }
    updateSettlementToInvoicedDatatableActionButtons();
  });

  $(".select-all-settlement-to-invoice")
    .off("click")
    .on("click", function () {
      if ($(this).text() === "Seleccionar todos") {
        selectAllNifForSettled();
      } else {
        resetSelectedRows();
      }
    });

  resetSelectedRows();
}

function updateSettlementToInvoicedDatatableActionButtons() {
  $(".total-selected").html(selectementToInvoiceSelectedsArray.length + " liquidaci" + (selectementToInvoiceSelectedsArray.length === 1 ? "ón" : "ones") + " seleccionada" + (selectementToInvoiceSelectedsArray.length === 1 ? "" : "s"));

  if (maxSettlementToInvoice === selectementToInvoiceSelectedsArray.length && maxSettlementToInvoice !== 0) {
    $(".select-all-settlement-to-invoice").text("Deselecionar todos");
    $(".select-all-settlement-to-invoice").removeClass("btn-barymont-red");
    $(".select-all-settlement-to-invoice").addClass("btn-barymont-green");
  } else {
    $(".select-all-settlement-to-invoice").text("Seleccionar todos");
    $(".select-all-settlement-to-invoice").removeClass("btn-barymont-green");
    $(".select-all-settlement-to-invoice").addClass("btn-barymont-red");
  }
}

async function fetchMarkAsInvoicedSettlements() {
  const formData = new FormData();

  formData.append("settledIdArray", JSON.stringify(selectementToInvoiceSelectedsArray));

  let requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  return await fetch("/herramientas/liquidaciones/ajax/markSettlementsAsInvoiced", requestOptions).then((response) => response.json());
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname === "/herramientas/liquidaciones") {
      const btnPaidMassiveSettledId = "btn-massive-invoice";

      const modalMassiveSettledId = "modal-massive-invoice";
      const modalMassiveSettledContainerId = "modal-massive-invoice-container";
      const modalDatatableId = "table-with-nifs-for-massive-invoice-table";

      const btnPaidMassiveSettledGenerateId = "btn-massive-invoice-generate";

      const modalMassiveSettledConfirmId = "modal-massive-invoice-confirm";
      const modalMassiveSettledConfirmNumberOfNifsSelectedId = "modal-massive-invoice-number-of-nifs-selected";
      const modalMassiveSettledConfirmGenerateId = "btn-massive-invoice-confirm-generate";

      const modalMassiveSettledResultId = "modal-massive-settled-result";
      const modalMassiveSettledResultTitleId = "modal-massive-settled-result-title";

      document.getElementById(btnPaidMassiveSettledId)?.addEventListener("click", () => {
        printContainerLoadingState(modalMassiveSettledContainerId);
        $("#" + modalMassiveSettledId).modal("show");
      });

      $("#" + modalMassiveSettledId).on("shown.bs.modal", function () {
        fetchSettlementsToInvoiced().then((response) => {
          if (response.status === 1) {
            clearContainer(modalMassiveSettledContainerId);
            printAlertMessage(modalMassiveSettledContainerId);
            createDatatableStructure(modalMassiveSettledContainerId, modalDatatableId);
            printSettledForListToInvoicedDatatableByJson(response.data, modalDatatableId);
          } else {
            createToast("warning", "Ha ocurrido un error", response.error.message, 5000);
          }
        });
      });

      document.getElementById(btnPaidMassiveSettledGenerateId).addEventListener("click", () => {
        if (selectementToInvoiceSelectedsArray.length === 0 || selectementToInvoiceSelectedsArray.length == []) {
          createToast("warning", "Error", "Debes seleccionar al menos una liquidación para facturar", 5000);
        } else {
          document.getElementById(modalMassiveSettledConfirmNumberOfNifsSelectedId).textContent = selectementToInvoiceSelectedsArray.length;
          $("#" + modalMassiveSettledConfirmId).modal("show");
        }
      });

      document.getElementById(modalMassiveSettledConfirmGenerateId).addEventListener("click", () => {
        settledSharedFunctions.showLoadingButton(modalMassiveSettledConfirmGenerateId, "btn-barymont-red");

        fetchMarkAsInvoicedSettlements().then((response) => {
          if (response.status === 1) {
            createToast("success", "Operación realizada", response.data.message, 5000);
            $("#" + modalMassiveSettledConfirmId).modal("hide");
            $("#" + modalMassiveSettledId).modal("hide");

            settledSharedFunctions.printSettledResultContent(modalMassiveSettledResultId, response.data.settledResults, modalMassiveSettledResultTitleId, "INVOICE");

            window.dispatchEvent(new CustomEvent("massiveSettlementInvoided"));
          } else {
            createToast("warning", "Ha ocurrido un error", response.error.message, 5000);
          }
          settledSharedFunctions.updateButtonLabel(modalMassiveSettledConfirmGenerateId, "Facturar", "btn-barymont-red");
        });
      });
    }
  });
}
