import handleApiResponse from "../../core/application/handleApiResponse";

interface Params {
  leadId: string;
  date: string;
}

interface PromoterInfoResponse {
  leadPromoterRegistryId: string;
  leadId: string;
  promoterId: string;
  promoterName: string;
  startAt: string;
  endAt: string;
  createdAt: string;
  createdBy: string;
  closedAt: string | null;
  closedBy: string | null;
}

export default async function getPromoterByDate(params: Params): Promise<PromoterInfoResponse> {
  const response = await fetch(`/api/leads/${params.leadId}/promoter?date=${params.date}`, {
    method: "GET",
    redirect: "follow",
  });

  return handleApiResponse<PromoterInfoResponse>(response);
}
