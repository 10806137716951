"use strict";

import { checkValidPasswords } from "./login_common.js";

async function handleResetPasswordRequest(event) {
  event.preventDefault();

  document.getElementById("resetPasswordForm").style.display = "none";
  document.getElementById("loading").style.display = "block";

  if (!checkValidPasswords()) {
    document.getElementById("resetPasswordForm").style.display = "block";
    document.getElementById("loading").style.display = "none";
    return;
  }

  let form = event.target;
  let formdata = new FormData(form);
  let requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  let response;

  try {
    let result = await fetch("/login/ajax/resetPassword", requestOptions).then((response) => response.text());
    response = JSON.parse(result);
  } catch (error) {
    console.log("error", error);
  } finally {
    document.getElementById("loading").style.display = "none";
  }

  if (response && response.success) {
    document.getElementById("successMessageFeedback").style.display = "block";
    document.getElementById("successMessageFeedbackMessage").innerHTML = response.message;
  } else {
    document.getElementById("errorMessageFeedback").style.display = "block";
    document.getElementById("errorMessageFeedbackMessage").innerHTML = response.message;
  }
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname === "/login/recover/reset") {
      document.querySelector("input[name='password']").addEventListener("change", checkValidPasswords);
      document.querySelector("input[name='password_repeat']").addEventListener("keyup", checkValidPasswords);

      document.querySelector("#resetPasswordFormSelector").addEventListener("submit", handleResetPasswordRequest);
    }
  });
}
