'use strict';

export const checkValidPasswords = () => {
  let password = document.querySelector("input[name='password']");
  let passwordRepeat = document.querySelector("input[name='password_repeat']");
  let passwordFeedback = document.getElementById("password_feedback");
  let passwordRepeatFeedback = document.getElementById("password_repeat_fedback");
  let submitButton = document.querySelector("button[type='submit']");

  if (password.value === "" || passwordRepeat.value === "") {
    passwordFeedback.innerHTML = "";
    passwordRepeatFeedback.innerHTML = "";

    password.classList.remove("is-valid");
    password.classList.remove("is-invalid");

    passwordRepeat.classList.remove("is-valid");
    passwordRepeat.classList.remove("is-invalid");

    submitButton.disabled = true;
    return false;
  }

  if (password.value.length < 5) {
    passwordFeedback.innerHTML = "La contraseña debe tener un mínimo de 5 caracteres.";
    passwordRepeatFeedback.innerHTML = "";

    password.classList.remove("is-valid");
    password.classList.add("is-invalid");

    passwordRepeat.classList.remove("is-valid");
    passwordRepeat.classList.add("is-invalid");

    submitButton.disabled = true;

    return false;
  }

  if (password.value.length > 20) {
    passwordFeedback.innerHTML = "La contraseña debe tener un máximo de 20 caracteres.";
    passwordRepeatFeedback.innerHTML = "";

    password.classList.remove("is-valid");
    password.classList.add("is-invalid");

    passwordRepeat.classList.remove("is-valid");
    passwordRepeat.classList.add("is-invalid");

    submitButton.disabled = true;

    return false;
  }

  if (password.value !== passwordRepeat.value) {
    passwordFeedback.innerHTML = "";
    passwordRepeatFeedback.innerHTML = "Las contraseñas no coinciden, por favor, revisa que sean iguales.";

    password.classList.remove("is-invalid");
    password.classList.add("is-valid");

    passwordRepeat.classList.remove("is-valid");
    passwordRepeat.classList.add("is-invalid");

    submitButton.disabled = true;
    return false;
  }

  passwordFeedback.innerHTML = "";
  passwordRepeatFeedback.innerHTML = "";

  password.classList.remove("is-invalid");
  password.classList.add("is-valid");

  passwordRepeat.classList.remove("is-invalid");
  passwordRepeat.classList.add("is-valid");

  submitButton.disabled = false;

  return true;
};
