import handleApiResponse from "../../core/application/handleApiResponse";
import User from "../domain/user";

interface SearchUserParams {
  nif?: string | null;
  uuid?: string | null;
  externalProviderAccountType?: string | null;
  externalProviderAccountMostImportantValue?: string | null;
}

export default async function searchUser(params: SearchUserParams): Promise<User> {
  const queryParams = new URLSearchParams(params as Record<string, string>).toString();
  const response = await fetch(`/api/user${queryParams ? '?' + queryParams : ''}`);
  return handleApiResponse<User>(response);
}
