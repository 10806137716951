export interface ToastOptions {
  type: "info" | "success" | "warning" | "error";
  title: string;
  subtitle?: string;
  content?: string;
  delay?: number;
}

export default class Toast {
  private static container: HTMLElement | null = null;

  private static getContainer(): HTMLElement {
    if (!this.container) {
      const container = document.createElement("div");
      container.id = "toast-container";
      container.setAttribute("aria-live", "polite");
      container.setAttribute("aria-atomic", "true");

      const wrapper = document.createElement("div");
      wrapper.id = "toast-wrapper";
      container.appendChild(wrapper);

      document.body.prepend(container);
      this.container = wrapper;
    }
    return this.container;
  }

  static show(options: ToastOptions): void {
    const { type, title, subtitle = "", content = "", delay = 3000 } = options;

    const container = this.getContainer();

    const typeClasses = {
      info: { bg: "bg-info", text: "text-white", imageFilter: "grayscale(100%)" },
      success: { bg: "bg-success", text: "text-white", imageFilter: "grayscale(100%)" },
      warning: { bg: "bg-warning", text: "text-barymont-black", imageFilter: "invert(100%)" },
      error: { bg: "bg-danger", text: "text-white", imageFilter: "grayscale(100%)" },
    };

    const { bg, text, imageFilter } = typeClasses[type];

    const toast = document.createElement("div");
    toast.classList.add("toast", "fade", "show");
    toast.setAttribute("role", "alert");
    toast.setAttribute("aria-live", "assertive");
    toast.setAttribute("aria-atomic", "true");

    toast.innerHTML = `
      <div class="toast-header ${bg} ${text}">
        <img src="/imagenes/toast_barymont_icon.png" class="rounded mr-2" alt="Barymont icon" style="filter: ${imageFilter}">
        <strong class="mr-auto">${title}</strong>
        <small class="${text}">${subtitle}</small>
        <button type="button" class="ml-2 mb-1 close" aria-label="Close">
          <span aria-hidden="true" class="${text}">&times;</span>
        </button>
      </div>
      ${content ? `<div class="toast-body">${content}</div>` : ""}
    `;

    const timeoutId = setTimeout(() => {
      this.fadeOutAndRemove(toast);
    }, delay);

    const closeButton = toast.querySelector(".close");
    closeButton?.addEventListener("click", () => {
      clearTimeout(timeoutId);
      this.fadeOutAndRemove(toast);
    });

    container.appendChild(toast);
  }

  private static fadeOutAndRemove(toast: HTMLElement): void {
    toast.classList.remove("show");
    toast.addEventListener("transitionend", () => toast.remove(), { once: true });
  }
}
