import Uuid from "../../shared/domain/uuid";
import Toast, { ToastOptions } from "../../shared/application/toasts/toast";
import useElementLoader from "../../shared/application/loaders/useElementLoader";
import ModalHandler from "../../shared/application/modals/modalHandler";
import initializeSearchableSelectpicker from "../../shared/application/selects/initializeSearchableSelectpicker";

import { SubscriptionPeriodicity } from "../domain/subscriptionPeriodicity";
import { ClientType } from "../domain/clientType";

import requireElementOfTypeById from "../../shared/application/dom/requireElementOfTypeById";
import createUserSelectOptionsHTML from "../../users/application/createUserSelectOptionsHTML";
import initializeSelectPickers from "../../shared/application/selects/initializeSelectpickers";
import validateFormData from "../application/validateFormData";
import searchCurrentLeadPromoter from "../application/searchCurrentLeadPromoter";
import searchPromoters from "../application/searchPromoters";
import searchPlanifiers from "../application/searchPlanifiers";
import createPaymentRequest, { CreationPaymentResult } from "../application/createPaymentRequest";

import planningServiceCreationModalHTML from "./components/planningServiceCreationModalHTML";

export default async function openRequestServiceModal(leadId: string): Promise<void> {
  try {
    const currentLeadPromoters = await searchCurrentLeadPromoter(leadId);

    const randomUuid = Uuid.random().value;
    const modalId = `create-service-request-modal-${randomUuid}`;
    const modalBodyId = `modal-body-${randomUuid}`;
    const promotersSelectId = `promoter-user-id-${randomUuid}`;
    const planifiersSelectId = `planifier-user-id-${randomUuid}`;
    const subscriptionPeriodicitySelectId = `subscription-periodicity-${randomUuid}`;
    const clientTypeSelectId = `client-type-${randomUuid}`;
    const isClientFromCanaryIslandCheckboxId = `client-from-canary-${randomUuid}`;
    const saveButtonId = `save-${randomUuid}`;

    // If there is a current promoter, we only show it as an option in the selectpicker and disable the input
    const currentLeadPromoterId = currentLeadPromoters.length === 1 ? currentLeadPromoters[0]?.userId : undefined;
    const currentLeadPromoterOption = createUserSelectOptionsHTML({ users: currentLeadPromoters, selectedUserId: currentLeadPromoterId });

    const modalHTML = planningServiceCreationModalHTML({
      modalId,
      modalBodyId,
      promotersSelectId,
      planifiersSelectId,
      subscriptionPeriodicitySelectId,
      clientTypeSelectId,
      isClientFromCanaryIslandCheckboxId,
      currentLeadPromoterOption,
      saveButtonId,
    });

    document.body.insertAdjacentHTML("beforeend", modalHTML);

    const modalHandler = ModalHandler.init(modalId).show().onHideRemove();

    initializeSelectPickers(promotersSelectId, planifiersSelectId, subscriptionPeriodicitySelectId, clientTypeSelectId);

    // Handler for client type select change to show/hide Canary Island checkbox
    requireElementOfTypeById(clientTypeSelectId, HTMLSelectElement).addEventListener("change", (e) => {
      if (e.target instanceof HTMLSelectElement) {
        const canaryContainer = requireElementOfTypeById(`container-${isClientFromCanaryIslandCheckboxId}`, HTMLElement);
        const selectedClientType = e.target.value as ClientType;
        canaryContainer.classList.toggle("d-none", selectedClientType !== ClientType.BUSINESS);
      }
    });

    // Handler for promoters search inputbox when there is no current promoter assigned
    if (!currentLeadPromoters.length) {
      initializeSearchableSelectpicker(
        promotersSelectId,
        (query) => searchPromoters(query),
        (results) => createUserSelectOptionsHTML({ users: results })
      );
    }

    // Handler for planifiers search inputbox
    initializeSearchableSelectpicker(
      planifiersSelectId,
      (query) => searchPlanifiers(query),
      (results) => createUserSelectOptionsHTML({ users: results })
    );

    // Handler for save button click
    requireElementOfTypeById(saveButtonId, HTMLButtonElement).addEventListener("click", () => {
      void (async () => {
        const promoterUserId = requireElementOfTypeById(promotersSelectId, HTMLSelectElement).value;
        const planifierUserId = requireElementOfTypeById(planifiersSelectId, HTMLSelectElement).value;
        const subscriptionPeriodicity = requireElementOfTypeById(subscriptionPeriodicitySelectId, HTMLSelectElement).value as SubscriptionPeriodicity;

        const validationErrors = validateFormData(promoterUserId, planifierUserId, subscriptionPeriodicity);
        if (validationErrors.length) {
          Toast.show({
            title: "Error",
            subtitle: "Faltan datos obligatorios",
            content: validationErrors.join("<br>"),
            type: "error",
            delay: 5000,
          });
          return;
        }

        const clientType = requireElementOfTypeById(clientTypeSelectId, HTMLSelectElement).value as ClientType;
        const taxCheckbox = requireElementOfTypeById(isClientFromCanaryIslandCheckboxId, HTMLInputElement);

        const isClientBuyingAsBusiness = clientType === ClientType.BUSINESS;
        const isClientTaxExempt = isClientBuyingAsBusiness && taxCheckbox.checked;

        const loader = useElementLoader({ elementId: saveButtonId, loadingText: "Procesando..." });
        loader.start();

        const creationResult = await createPaymentRequest(leadId, subscriptionPeriodicity, promoterUserId, planifierUserId, isClientBuyingAsBusiness, isClientTaxExempt);

        let toastOptions: ToastOptions;

        switch (creationResult) {
          case CreationPaymentResult.SUCCESS:
            toastOptions = {
              title: "Éxito",
              content: "La solicitud de pago se ha realizado correctamente, se ha enviado un correo al cliente con la información necesaria.",
              type: "success",
              delay: 5000,
            };
            break;

          case CreationPaymentResult.TOO_MANY_REQUESTS:
            toastOptions = {
              title: "Demasiadas solicitudes",
              subtitle: "Operación fallida",
              content: "Se ha alcanzado el límite de solicitudes permitidas, inténtelo de nuevo más tarde.",
              type: "warning",
              delay: 20000,
            };
            break;

          case CreationPaymentResult.ERROR:
            toastOptions = {
              title: "Error",
              subtitle: "Operación fallida",
              content: "Ha ocurrido un error al procesar la solicitud.",
              type: "error",
              delay: 5000,
            };
            break;
        }

        Toast.show(toastOptions);
        modalHandler.hide();
        loader.stop();
      })();
    });
  } catch (error) {
    console.error("Error opening request service modal:", error);
  }
}
