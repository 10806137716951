"use strict";

import { searchUser, searchUsers } from "../../api/users.js";
import { showLoadingButton, updateButtonLabel } from "../shared/shared.js";
import { createLeadInterestDetected } from "../../api/leads.js";
import { updatePromoter, readLeadPromotersHistory, readLeadVisibility, createLeadVisibility, deleteLeadVisibility } from "../../api/leads.js";

const leadPanelUIConfig = {
  updatePromoterModalButtonId: "updatePromoterModalButton",
  promoterHistoryModalButtonId: "promoterHistoryModalButton",
  leadVisibilityModalButtonId: "lead-visibility-modal-button",
  promoterServiceAndProductProposalModalButtonId: "promoter-resource-button-open-modal",
};

function createUpdatePromoterModal() {
  showLoadingButton(leadPanelUIConfig.updatePromoterModalButtonId, "btn-barymont-grey");

  const randomModalId = `modal-${Math.random().toString(36).substring(2, 10)}`;
  const fragment = document.createDocumentFragment();

  const temporalDiv = document.createElement("div");
  temporalDiv.innerHTML = `
    <div class="modal fade" id="${randomModalId}" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">Actualizar Promotor</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body">
                  <div class="form-group">
                      <label for="promoterNIF" class="control-label font-weight-bold">NIF del nuevo promotor</label>
                      <input type="text" class="form-control" id="promoterNIF" name="promoterNIF" autocomplete="off" required>
                      <small class="form-text text-muted">En caso de que ya exista un promotor vinculado al cliente, este será reemplazado siendo la fecha de inicio las 00:00 del día actual.</small>
                  </div>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-barymont-red" id="promoterUpdateBtn">Actualizar</button>
                  <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
              </div>
          </div>
      </div>
    </div>`;

  fragment.appendChild(temporalDiv.firstElementChild);

  document.body.appendChild(fragment);

  const $modalElement = $(`#${randomModalId}`).modal("show");

  $modalElement.on("hidden.bs.modal", function () {
    $modalElement.remove();
  });

  updateButtonLabel(leadPanelUIConfig.updatePromoterModalButtonId, '<i class="fa-solid fa-lg fa-refresh mr-1"></i>Actualizar', "btn-barymont-grey");

  const updateButton = document.getElementById("promoterUpdateBtn");

  updateButton.addEventListener("click", async function () {
    showLoadingButton("promoterUpdateBtn", "btn-barymont-red");
    const promoterNIF = document.getElementById("promoterNIF").value;
    const leadId = document.getElementById("lead-id").value;

    try {
      const user = await searchUser({ nif: promoterNIF });
      await updatePromoter(user.userId, leadId);

      $modalElement.modal("hide");

      createToast("success", "Éxito", "Promotor actualizado correctamente a:<br><b>" + user.name + "</b>.<br>Para obtener la información actualizada, la página se recargará automaticamente en 3 segundos.", 3000);

      setTimeout(() => {
        location.reload();
      }, 3000);
    } catch (error) {
      createToast("error", "Error", error.message, 5000);
    } finally {
      updateButtonLabel("promoterUpdateBtn", "Actualizar", "btn-barymont-red");
    }
  });
}

async function createLeadPromoterHistory() {
  showLoadingButton(leadPanelUIConfig.promoterHistoryModalButtonId, "btn-barymont-grey");

  const randomModalId = `modal-${Math.random().toString(36).substring(2, 10)}`;
  const fragment = document.createDocumentFragment();

  const leadId = document.getElementById("lead-id").value;

  let historyHtml = "";

  try {
    const historyResponse = await readLeadPromotersHistory(leadId);

    if (history.length === 0) {
      createToast("info", "Información", "No hay historico de promotores para este cliente.", 5000);
      return;
    }

    historyResponse.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    historyHtml = historyResponse
      .map((leadPromoter) => {
        let status = new Date(leadPromoter.endAt) > new Date() ? "Activo" : "Inactivo";
        let info = leadPromoter.closedAt ? "Cerrado" : new Date(leadPromoter.endAt) > new Date() ? "Activo" : "Expirado";
        let infoColorClass = info === "Cerrado" ? "badge-danger" : info === "Activo" ? "badge-success" : "badge-secondary";
        let daysAsPromoter = Math.round((new Date(leadPromoter.endAt) - new Date(leadPromoter.startAt)) / (1000 * 60 * 60 * 24));

        let daysAsPromoterString = daysAsPromoter.toString() + (daysAsPromoter === 1 ? " día" : " días");

        let startAtString = leadPromoter.startAt.split(" ")[0];
        let endAtString = leadPromoter.endAt.split(" ")[0];

        if (new Date(leadPromoter.startAt) > new Date(leadPromoter.endAt)) {
          endAtString = "-";
          daysAsPromoterString = "0 días";
        }

        return `
          <tr>
            <td class="align-middle text-nowrap">${leadPromoter.promoterName}</td>
            <td class="align-middle text-nowrap">${startAtString}</td>
            <td class="align-middle text-nowrap">${endAtString}</td>
            <td class="align-middle text-nowrap">${daysAsPromoterString}</td>
            <td class="align-middle text-nowrap">
              <span class="badge ${status === "Activo" ? "badge-success" : "badge-danger"} w-100">
                ${status}
              </span>
            </td>
            <td class="align-middle text-nowrap">
              <span class="badge ${infoColorClass} w-100">
              ${info}
              </span>
            </td>
          </tr>
        `;
      })
      .join("");
  } catch (error) {
    createToast("error", "Error", error.message, 5000);
  }

  const temporalDiv = document.createElement("div");
  temporalDiv.innerHTML = `
    <div class="modal fade" id="${randomModalId}" tabindex="-1" role="dialog">
      <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">Historial de Promotores</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body">
                <div class="table-responsive">
                  <table class="table table-bordered table-condensed table-hover text-center align-middle nowrap table-sm">
                    <thead>
                      <tr>
                        <th class="bg-barymont-grey text-dark text-nowrap border border-barymont-dark-grey px-2">Promotor</th>
                        <th class="bg-barymont-grey text-dark text-nowrap border border-barymont-dark-grey px-2">Desde</th>
                        <th class="bg-barymont-grey text-dark text-nowrap border border-barymont-dark-grey px-2">Hasta</th>
                        <th class="bg-barymont-grey text-dark text-nowrap border border-barymont-dark-grey px-2"><i class="fa-solid fa-calendar"></i></th>
                        <th class="bg-barymont-grey text-dark text-nowrap border border-barymont-dark-grey px-2">Estado</th>
                        <th class="bg-barymont-grey text-dark text-nowrap border border-barymont-dark-grey px-2">Info</th>
                      </tr>
                    </thead>
                    <tbody>
                      ${historyHtml ? historyHtml : "<tr><td colspan='6'>No hay historico de promotores para este cliente.</td></tr>"}
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
              </div>
          </div>
      </div>
    </div>`;

  fragment.appendChild(temporalDiv.firstElementChild);

  document.body.appendChild(fragment);

  const $modalElement = $(`#${randomModalId}`).modal("show");

  updateButtonLabel(leadPanelUIConfig.promoterHistoryModalButtonId, '<i class="fa-solid fa-lg fa-history mr-1"></i>Historial', "btn-barymont-grey");

  $modalElement.on("hidden.bs.modal", function () {
    $modalElement.remove();
  });
}

async function createLeadVisibilityModal() {
  document.getElementById(leadPanelUIConfig.leadVisibilityModalButtonId).classList.remove("fa-eye");
  document.getElementById(leadPanelUIConfig.leadVisibilityModalButtonId).innerHTML = '<span class="spinner-border spinner-border-sm m-1" role="status" aria-hidden="true" style="pointer-events: none;"></span>';

  const randomModalId = `modal-${Math.random().toString(36).substring(2, 10)}`;
  const fragment = document.createDocumentFragment();

  const leadId = document.getElementById("lead-id").value;

  const leadVisibilityManagePermission = AppGbSession.checkUserHasPermission("Leads:LeadVisibilityManageAccessChecker");

  let leadVisibilityHtml = await generateLeadVisibilityTableHtml(leadId, leadVisibilityManagePermission);

  const temporalDiv = document.createElement("div");
  temporalDiv.innerHTML = `
    <div class="modal fade" id="${randomModalId}" tabindex="-1" role="dialog">
      <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">Usuarios con Visibilidad</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body">
                <div class="table-responsive">
                  <table class="table table-bordered table-striped table-condensed table-hover text-center align-middle nowrap table-sm">
                    <thead>
                      <tr>
                        <th class="bg-barymont-grey text-dark text-nowrap border border-barymont-dark-grey px-2">Usuario</th>
                        <th class="bg-barymont-grey text-dark text-nowrap border border-barymont-dark-grey px-2">Perfil</th>
                        ${leadVisibilityManagePermission ? `<th class="bg-barymont-grey text-dark text-nowrap border border-barymont-dark-grey px-1" style="width:50px;">Acciones</th>` : ""}
                      </tr>
                    </thead>
                    <tbody id="lead-visibility-table-body">
                      ${leadVisibilityHtml}
                    </tbody>
                  </table>

                  <small>
                    <i class="fa-regular fa-question-circle text-barymont-red pr-2"></i> La visibilidad se genera automáticamente, teniendo en cuenta diversas configuraciones del cliente, como su <strong>responsable</strong> o <strong>promotor</strong>. Asimismo, se añadirá automáticamente la <strong>estructura de responsables</strong> correspondiente a estas dos figuras.<br>Además, existe un factor adicional: el Plan Comercial del cliente en eBroker, cuya sincronización se realiza de manera periódica.
                  </small>
                </div>
              </div>
              <div class="modal-footer">
                  ${leadVisibilityManagePermission ? `<button type="button" class="btn btn-barymont-red" id="add-visibility"><i class="fa-solid fa-md fa-eye mr-1"></i>Añadir visibilidad</button>` : ""}
                  <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
              </div>
          </div>
      </div>
    </div>`;

  fragment.appendChild(temporalDiv.firstElementChild);

  document.body.appendChild(fragment);

  const $modalElement = $(`#${randomModalId}`).modal("show");

  if (leadVisibilityManagePermission) {
    document.getElementById("add-visibility").addEventListener("click", createAddLeadVisibilityModal);
    await generateRemoveLeadVisibilityEventListeners();
  }

  $(`#${randomModalId} [data-toggle="tooltip"]`).tooltip();

  document.getElementById(leadPanelUIConfig.leadVisibilityModalButtonId).classList.add("fa-eye");
  document.getElementById(leadPanelUIConfig.leadVisibilityModalButtonId).innerHTML = "";

  $modalElement.on("hidden.bs.modal", function () {
    $modalElement.remove();
  });
}

async function generateLeadVisibilityTableHtml(leadId, leadVisibilityManagePermission) {
  const NOT_SHOW_IN_LIST_USER_IDS = [
    "b4bb80fc-bbf1-4a02-ad35-426db7a24835", // Bárymont
    "6e9aa046-4751-4aa6-ba46-890769535a01", // Ana Barros
  ];

  let leadVisibilityUsers = [];
  try {
    leadVisibilityUsers = await readLeadVisibility(leadId);
  } catch (error) {
    createToast("error", "Error", error.message, 5000);
  }

  leadVisibilityUsers.sort((a, b) => {
    return a.userName.localeCompare(b.userName);
  });

  const order = ["Gestor", "Promotor Senior", "Promotor"];

  leadVisibilityUsers.sort((a, b) => {
    let indexA = order.indexOf(a.userProfile.label);
    let indexB = order.indexOf(b.userProfile.label);

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;

    let labelComparison = a.userProfile.label.localeCompare(b.userProfile.label);
    if (labelComparison !== 0) return labelComparison;

    return a.userName.localeCompare(b.userName);
  });

  let leadVisibilityHtml = "";
  let deleteButton = "";

  leadVisibilityHtml = leadVisibilityUsers
    .map((leadVisibilityUser) => {
      if (NOT_SHOW_IN_LIST_USER_IDS.includes(leadVisibilityUser.userId)) {
        return "";
      }

      if (leadVisibilityUser.isEditable === true) {
        deleteButton = `<button class="btn btn-barymont-black btn-sm p-2 w-100" data-action="delete-visibility" data-user-id="${leadVisibilityUser.userId}" data-lead-id="${leadId}" data-toggle="tooltip" data-placement="top" title="Eliminar visibilidad">
            <i class="fa-solid fa-md fa-trash"></i>
          </button>`;
      } else {
        deleteButton = `<button class="btn btn-barymont-grey btn-sm p-2 w-100" data-toggle="tooltip" data-placement="top" title="No puedes eliminar la visibilidad de este usuario" disabled>
            <i class="fa-solid fa-md fa-ban"></i>
          </button>`;
      }

      return `
      <tr>
        <td class="align-middle text-nowrap">${leadVisibilityUser.userName}</td>
        <td class="align-middle text-nowrap"><span class="badge w-100 p-2 bg-${leadVisibilityUser.userProfile.backgroundColor} text-${leadVisibilityUser.userProfile.textColor}">${leadVisibilityUser.userProfile.label}</span></td>
        ${
          leadVisibilityManagePermission
            ? `<td>
              ${deleteButton}
        </td>`
            : ""
        }
      </tr>
    `;
    })
    .join("");

  if (leadVisibilityHtml === "") {
    leadVisibilityHtml = `<tr><td colspan='${leadVisibilityManagePermission ? "3" : "2"}'>Ningún usuario tiene visibilidad de este lead.</td></tr>`;
  }

  return leadVisibilityHtml;
}

async function generateRemoveLeadVisibilityEventListeners() {
  document.querySelectorAll("[data-action='delete-visibility']").forEach((button) => {
    let cloneButton = button.cloneNode(true);
    button.parentNode.replaceChild(cloneButton, button);

    cloneButton.addEventListener("click", async function () {
      let userId = cloneButton.getAttribute("data-user-id");
      await deleteLeadVisibilityForUser(cloneButton.getAttribute("data-user-id"), cloneButton.getAttribute("data-lead-id"));

      if (userId === AppGbSession.getSessionData().userId && !AppGbSession.checkUserHasPermission("Leads:LeadManageAccessChecker")) {
        window.location.href = "/herramientas/leads";
      }

      document.getElementById("lead-visibility-table-body").innerHTML = await generateLeadVisibilityTableHtml(document.getElementById("lead-id").value, AppGbSession.checkUserHasPermission("Leads:LeadVisibilityManageAccessChecker"));
      $(`[data-toggle="tooltip"]`).tooltip();

      generateRemoveLeadVisibilityEventListeners();
    });
  });
}

async function createAddLeadVisibilityModal() {
  showLoadingButton("add-visibility", "btn-barymont-red");

  const randomModalId = `modal-${Math.random().toString(36).substring(2, 10)}`;

  const fragment = document.createDocumentFragment();

  const temporalDiv = document.createElement("div");

  temporalDiv.innerHTML = `
    <div class="modal fade" id="${randomModalId}" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">Añadir Visibilidad</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body text-center">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-light"><i class="fa-solid fa-md fa-user"></i></span>
                    </div>
                    <select
                        id="users-for-visibility"
                        class="form-control selectpicker show-tick input-lg"
                        title="Usuarios"
                        data-live-search="true"
                        data-live-search-placeholder="Introduce 3 caracteres para buscar un usuario"
                        data-none-results-text="No se encontraron usuarios que coincidan con '{0}'">
                    </select>
                </div>
                <small class="form-text text-muted text-left"><i class="fa-regular fa-question-circle text-barymont-red pr-2"></i>Al añadir un usuario, se añadirá visibilidad al usuario seleccionado y toda su estructura de responsables.</small>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-barymont-red" id="confirm-btn-add-visibility">Añadir</button>
                  <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
              </div>
          </div>
      </div>
    </div>`;

  fragment.appendChild(temporalDiv.firstElementChild);

  document.body.appendChild(fragment);

  const $modalElement = $(`#${randomModalId}`).modal("show");

  $("#users-for-visibility").selectpicker("refresh");

  const searchInput = $(`#users-for-visibility`).parent().find(".bs-searchbox input");
  searchInput.on("input", async function (event) {
    if (event.target.value.length < 3) {
      $("#users-for-visibility").empty();
      $("#users-for-visibility").selectpicker("refresh");
      return;
    }

    await paintUsersVisibilitySelector(event.target.value);
  });

  document.getElementById("confirm-btn-add-visibility").addEventListener("click", async function () {
    await addLeadVisibility(document.getElementById("users-for-visibility").value, randomModalId);

    document.getElementById("lead-visibility-table-body").innerHTML = await generateLeadVisibilityTableHtml(document.getElementById("lead-id").value, AppGbSession.checkUserHasPermission("Leads:LeadVisibilityManageAccessChecker"));

    await generateRemoveLeadVisibilityEventListeners();

    $(`[data-toggle="tooltip"]`).tooltip();
  });

  updateButtonLabel("add-visibility", "<i class='fa-solid fa-md fa-eye mr-1'></i>Añadir visibilidad", "btn-barymont-red");

  $modalElement.on("hidden.bs.modal", function () {
    $modalElement.remove();
  });
}

async function paintUsersVisibilitySelector(searchValue) {
  const usersForAddVisibility = await searchUsers([], [], true, searchValue, 10, null);

  $("#users-for-visibility").empty();

  usersForAddVisibility.forEach((user) => {
    $("#users-for-visibility").append(`<option value="${user.userId}" data-content="<span>${user.name}</span> <span class='badge bg-${user.userProfile.backgroundColor} text-${user.userProfile.textColor} ml-2'>${user.userProfile.label}</span>">${user.name}</option>`);
  });

  $("#users-for-visibility").selectpicker("refresh");
}

async function addLeadVisibility(userId, modalId) {
  showLoadingButton("confirm-btn-add-visibility", "btn-barymont-red");

  if (userId === "") {
    createToast("error", "Error", "No se ha seleccionado ningún usuario.", 5000);
    return;
  }

  const leadId = document.getElementById("lead-id").value;

  try {
    await createLeadVisibility(leadId, userId);

    $(`#${modalId}`).modal("hide");
    createToast("success", "Éxito", "Visibilidad añadida correctamente.", 5000);
  } catch (error) {
    createToast("error", "Error", error.message, 5000);
  }

  updateButtonLabel("confirm-btn-add-visibility", "Añadir", "btn-barymont-red");
}

async function deleteLeadVisibilityForUser(userId, leadId) {
  try {
    await deleteLeadVisibility(leadId, userId);

    createToast("success", "Éxito", "Visibilidad eliminada correctamente.", 5000);
  } catch (error) {
    createToast("error", "Error", error.message, 5000);
  }
}

async function createLeadInterestDetectedModal() {
  const randomModalId = `modal-${Math.random().toString(36).substring(2, 10)}`;
  const fragment = document.createDocumentFragment();

  const temporalDiv = document.createElement("div");

  const interestsGroup = {
    Servicios: ["Servicio de educación Financiera (Básico)", "Servicio de educación Financiera (Premium)", "Planificación Financiera (WPT)", "Apoyo Gerencial", "Programa de Bienestar Financiero para Empresas", "Otros Servicios"],
    "Servicios Profesionales Advance": ["Defensa Jurídica (SPAD)", "Ahorro en Facturas (SPAD)", "Asesoramiento Hipotecario (SPAD)", "Otros servicios (SPAD)"],
    Productos: ["Seguros", "Fondos de Inversión", "Otros productos de Inversión"],
  };

  temporalDiv.innerHTML = `
  <div class="modal fade" id="${randomModalId}" tabindex="-1" role="dialog" data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Notificar intereses detectados</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center">

          <div class="form-group text-left">
            <h4 class="mb-2">Intereses detectados</h4>
              <div class="row">
                ${Object.keys(interestsGroup)
                  .map(
                    (group) => `
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="font-weight-bold">${group}</label>
                      ${interestsGroup[group]
                        .map(
                          (item) => `
                        <div class="form-check">
                          <input type="checkbox" id="${item.replace(/\s+/g, "-").toLowerCase()}" name="interes[]" value="${item}">
                          <label class="py-0" for="${item.replace(/\s+/g, "-").toLowerCase()}">${item}</label>
                        </div>
                      `
                        )
                        .join("")}
                    </div>
                  </div>
                `
                  )
                  .join("")}
              </div>
          </div>

          <div class="form-group text-left">
            <label class="control-label font-weight-bold">Modelo de economía</label>
            <select class="form-control" name="modelo-economia" id="modelo-economia" required>
              <option value="" disabled selected>Seleccione un modelo</option>
              <option value="Economía Personal">Personal</option>
              <option value="Economía Familiar">Familiar</option>
              <option value="Autónomo">Autónomo</option>
              <option value="Empresa">Empresa</option>
            </select>
          </div>

          <div class="form-group text-left">
            <label for="promoter-interest-detected-description" class="control-label font-weight-bold">Descripción / Observaciones</label>
            <textarea id="promoter-interest-detected-description" class="form-control input-lg tinymce-editor" rows="3" name="promoter-interest-detected-description" required></textarea>
          </div>

          <div class="form-group text-left">
            <p class="text-muted">
              <span class="badge badge-secondary">Recomendaciones</span> Por favor, seleccione todas las opciones que aplican a su solicitud y brinde una descripción clara y detallada. Esto ayudará a brindar un mejor servicio. Tenga en cuenta que la solicitud será enviada a su responsable y gestor para que puedan valorarla y asignar los expertos correspondientes.
            </p>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-barymont-red" id="confirm-promoter-lead-interest-detected">Notificar</button>
          <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
    `;

  fragment.appendChild(temporalDiv.firstElementChild);

  document.body.appendChild(fragment);

  $("#promoter-interest-detected-type").selectpicker("refresh");

  document.querySelectorAll(".tinymce-editor").forEach((element) => {
    tinymce.remove("#" + element.id);

    tinymce.init({
      selector: "#" + element.id,
      height: 200,
      menubar: false,
      entity_encoding: "raw",
      toolbar: "bold underline italic",
      branding: false,
    });
  });

  $(`#${randomModalId}`).modal("show");

  const $modalElement = $(`#${randomModalId}`);

  $modalElement.on("hidden.bs.modal", function () {
    $modalElement.remove();
  });

  document.getElementById("confirm-promoter-lead-interest-detected").addEventListener("click", async function (e) {
    showLoadingButton(e.target.id, "btn-barymont-red");

    const description = tinymce.get("promoter-interest-detected-description").getContent();
    const interestDetectedTypes = document.querySelectorAll("input[name='interes[]']:checked");
    const economyModel = document.getElementById("modelo-economia").value;

    if (interestDetectedTypes.length === 0) {
      createToast("error", "Error", "Debe seleccionar al menos un interés.", 5000);
      updateButtonLabel(e.target.id, "Notificar", "btn-barymont-red");
      return;
    }

    if (economyModel.length === 0) {
      createToast("error", "Error", "Debe seleccionar el modelo de economía.", 5000);
      updateButtonLabel(e.target.id, "Notificar", "btn-barymont-red");
      return;
    }

    if (description.length === 0) {
      createToast("error", "Error", "La descripción no puede estar vacía.", 5000);
      updateButtonLabel(e.target.id, "Notificar", "btn-barymont-red");
      return;
    }

    if (description.length < 10) {
      createToast("error", "Error", "La descripción debe tener al menos 10 caracteres.", 5000);
      updateButtonLabel(e.target.id, "Notificar", "btn-barymont-red");
      return;
    }

    const interestDetectedValues = Array.from(interestDetectedTypes).map((item) => item.value);

    try {
      await createLeadInterestDetected(document.getElementById("lead-id").value, interestDetectedValues, economyModel, description);

      createToast("success", "Éxito", "Notificación de intereses detectados enviada correctamente.", 5000);
      $modalElement.modal("hide");
    } catch (error) {
      createToast("error", "Error", error.message, 5000);
      updateButtonLabel(e.target.id, "Notificar", "btn-barymont-red");
    }
  });
}

if (window) {
  window.addEventListener("load", function () {
    // Check for /herramientas/leads/{leadId}
    if (/^\/herramientas\/leads\/[a-f0-9-]{36}\/?$/.test(window.location.pathname)) {
      const updatePromoterbutton = document.getElementById(leadPanelUIConfig.updatePromoterModalButtonId);
      if (updatePromoterbutton) updatePromoterbutton.addEventListener("click", createUpdatePromoterModal);

      const leadPromoterHistoryButton = document.getElementById(leadPanelUIConfig.promoterHistoryModalButtonId);
      if (leadPromoterHistoryButton) leadPromoterHistoryButton.addEventListener("click", createLeadPromoterHistory);

      const leadVisibilityButton = document.getElementById(leadPanelUIConfig.leadVisibilityModalButtonId);
      if (leadVisibilityButton) leadVisibilityButton.addEventListener("click", createLeadVisibilityModal);

      const promoterServiceAndProductProposalButton = document.getElementById(leadPanelUIConfig.promoterServiceAndProductProposalModalButtonId);
      if (promoterServiceAndProductProposalButton) promoterServiceAndProductProposalButton.addEventListener("click", createLeadInterestDetectedModal);
    }
  });
}
