"use strict";

const COLORS = [
  {
    backgroundColor: "rgba(54, 162, 235, 1)", // Light Blue
    borderColor: "rgba(54, 162, 235, 1)", // Light Blue
  },
  {
    backgroundColor: "rgba(255, 159, 64, 1)", // Orange
    borderColor: "rgba(255, 159, 64, 1)", // Orange
  },
  {
    backgroundColor: "rgba(255, 99, 132, 1)", // Red
    borderColor: "rgba(255, 99, 132, 1)", // Red
  },
  {
    backgroundColor: "rgba(153, 102, 255, 1)", // Purple
    borderColor: "rgba(153, 102, 255, 1)", // Purple
  },
  {
    backgroundColor: "rgba(54, 162, 235, 1)", // Blue
    borderColor: "rgba(54, 162, 235, 1)", // Blue
  },
  {
    backgroundColor: "rgba(75, 192, 192, 1)", // Teal
    borderColor: "rgba(75, 192, 192, 1)", // Teal
  },
  {
    backgroundColor: "rgba(255, 205, 86, 1)", // Light Yellow
    borderColor: "rgba(255, 205, 86, 1)", // Light Yellow
  },
  {
    backgroundColor: "rgba(255, 159, 64, 1)", // Light Orange
    borderColor: "rgba(255, 159, 64, 1)", // Light Orange
  },
  {
    backgroundColor: "rgba(201, 203, 207, 1)", // Grey
    borderColor: "rgba(201, 203, 207, 1)", // Grey
  },
  {
    backgroundColor: "rgba(153, 102, 255, 1)", // Light Purple
    borderColor: "rgba(153, 102, 255, 1)", // Light Purple
  },
  {
    backgroundColor: "rgba(255, 206, 86, 1)", // Yellow
    borderColor: "rgba(255, 206, 86, 1)", // Yellow
  },
  {
    backgroundColor: "rgba(75, 192, 192, 1)", // Light Teal
    borderColor: "rgba(75, 192, 192, 1)", // Light Teal
  },
  {
    backgroundColor: "rgba(255, 99, 132, 1)", // Light Red
    borderColor: "rgba(255, 99, 132, 1)", // Light Red
  },
  {
    backgroundColor: "rgba(54, 162, 235, 1)", // Sky Blue
    borderColor: "rgba(54, 162, 235, 1)", // Sky Blue
  },
  {
    backgroundColor: "rgba(255, 159, 64, 1)", // Peach
    borderColor: "rgba(255, 159, 64, 1)", // Peach
  },
];

const MONTH_NAMES = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

// Global variable to store the selected dates for the analysis
var datesToAnalyze = [];

function getDatesToAnalyze() {
  return datesToAnalyze;
}

function setDatesToAnalyze(dates) {
  datesToAnalyze = dates;
}

/**
 * Render a button to maximize the chart on the given container
 * @param {HTMLElement} container
 */
function renderMaximizeChartButton(container, chart) {
  const maximizeButton = document.createElement("button");

  maximizeButton.id = container.id + "-maximize-button";
  maximizeButton.classList.add("btn", "btn-barymont-black", "btn-sm", "float-right", "position-absolute");
  maximizeButton.style.right = "140px";
  maximizeButton.style.zIndex = 100;
  maximizeButton.innerHTML = '<i class="fa-solid fa-expand-arrows-alt pr-2"></i> Maximizar gráfico';

  if (document.getElementById(container.id + "-maximize-button")) {
    document.getElementById(container.id + "-maximize-button").remove();
  }
  if (document.getElementById(container.id + "-backdrop")) {
    document.getElementById(container.id + "-backdrop").remove();
  }

  maximizeButton.addEventListener("click", function () {
    const chartContainer = document.getElementById(container.id);

    if (!chartContainer.classList.contains("business-analytics-maximized-chart")) {
      chartContainer.classList.add("business-analytics-maximized-chart");

      const backdrop = document.createElement("div");
      backdrop.classList.add("business-analytics-maximized-chart-backdrop");
      backdrop.id = container.id + "-backdrop";
      document.body.appendChild(backdrop);

      maximizeButton.innerHTML = '<i class="fa-solid fa-compress-arrows-alt pr-2"></i> Restaurar gráfico';

      chart.resize();
    } else {
      chartContainer.classList.remove("business-analytics-maximized-chart");

      const backdrop = document.getElementById(container.id + "-backdrop");
      if (backdrop) {
        backdrop.remove();
      }

      maximizeButton.innerHTML = '<i class="fa-solid fa-expand-arrows-alt pr-2"></i> Maximizar gráfico';

      chart.resize();
    }
  });

  container.insertBefore(maximizeButton, container.firstChild);
}

/**
 * Render a button to export the data to a CSV file on the given container
 * @param {HTMLElement} container
 * @param {Object} chartJsData - Data object used by Chart.js
 * @param {String} fileName - Filename for the exported CSV
 */
function renderExportToCsvButton(container, chartJsData, fileName) {
  const formatNumber = (num) => (typeof num === "number" ? num.toString().replace(".", ",") : num);

  const exportToCsv = (chartJsData) => {
    if (!chartJsData || !chartJsData.labels || !chartJsData.datasets) {
      console.error("Invalid chartJsData structure");
      return;
    }

    const columnsArray = ["", ...chartJsData.datasets.map((dataset) => dataset.label)];
    const rows = chartJsData.labels.map((label, index) => [label, ...chartJsData.datasets.map((dataset) => formatNumber(dataset.data[index]))]);

    const csvContent = ["\uFEFF" + columnsArray.join(";"), ...rows.map((row) => row.join(";"))].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${fileName}_${new Date().toISOString().split("T")[0]}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const existingButton = document.getElementById(container.id + "-export-button");
  if (existingButton) {
    existingButton.remove();
  }

  const exportButton = document.createElement("button");
  exportButton.id = container.id + "-export-button";
  exportButton.classList.add("btn", "btn-barymont-black", "btn-sm", "float-right", "position-absolute", "mr-2");
  exportButton.style.right = "10px";
  exportButton.style.zIndex = 100;
  exportButton.innerHTML = '<i class="fa-solid fa-download pr-2"></i> Exportar datos';
  exportButton.onclick = () => exportToCsv(chartJsData);

  container.insertBefore(exportButton, container.firstChild);
}

/**
 * Render a button to export the data to a CSV file on the given container
 * @param {HTMLElement} button
 * @param {String} loadingStateInnerHtml
 * @param {String} readyStateInnerHtml
 */
function toggleLoadingButton(buttonElement, loadingStateInnerHtml = "Cargando...", readyStateInnerHtml = "Ejecutar") {
  buttonElement.disabled = !buttonElement.disabled;
  buttonElement.innerHTML = buttonElement.disabled ? loadingStateInnerHtml : readyStateInnerHtml;
}

/**
 * Adds a new date to the global datesToAnalyze array and updates the date list container with the new date list array values
 * This function needs the global variable datesToAnalyze to be set in the global scope
 * @param {String} dateInputId
 * @param {String} dateListContainerId
 * @returns
 */
function addBusinessAnalyticsDate(dateInputId, dateListContainerId) {
  const dateInput = document.getElementById(dateInputId);
  const dateValue = dateInput.value;

  if (!verifyDatesToAnalyzeGlobalExistence()) {
    return;
  }

  if (datesToAnalyze.includes(dateValue)) {
    createToast("error", "Fecha erronea", "La fecha . " + dateValue + " ya ha sido seleccionada, por favor, selecciona otra fecha.");
    return;
  }

  if (dateValue) {
    datesToAnalyze.push(dateValue);
    updateBussinesAnalyticsDateList(dateListContainerId);
    dateInput.value = "";
  } else {
    createToast("error", "Fecha erronea", "Por favor, ingresa una fecha válida.");
  }
}

/**
 * Updates the date list container with the current datesToAnalyze array values
 * This function needs the global variable datesToAnalyze to be set in the global scope
 * @param {String} dateListContainerId
 */
function updateBussinesAnalyticsDateList(dateListContainerId) {
  const dateListContainer = document.getElementById(dateListContainerId);
  dateListContainer.innerHTML = "";

  if (!verifyDatesToAnalyzeGlobalExistence()) {
    return;
  }

  // Create element to show when there are no dates when there are no dates
  if (getDatesToAnalyze().length === 0) {
    const noDates = document.createElement("div");
    noDates.id = "no-dates";
    noDates.classList.add("text-center", "alert", "alert-info", "w-100");
    noDates.textContent = "Aún no has seleccionado ninguna fecha.";
    dateListContainer.appendChild(noDates);
    return;
  } else {
    const noDates = document.getElementById("no-dates");
    if (noDates) {
      noDates.remove();
    }
  }

  datesToAnalyze.sort((a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return dateA - dateB;
  });

  function removeDate(index) {
    datesToAnalyze.splice(index, 1);
    updateBussinesAnalyticsDateList(dateListContainerId);
  }

  datesToAnalyze.forEach((date, index) => {
    const listItem = document.createElement("div");
    listItem.classList.add("date-item", "mb-2", "border", "border-barymont-dark-grey", "p-2", "d-flex", "justify-content-between", "font-weight-bold", "rounded");
    listItem.textContent = date;

    const removeButton = document.createElement("button");
    removeButton.innerHTML = '<i class="fas fa-trash"></i>';
    removeButton.classList.add("btn", "btn-barymont-red", "btn-sm", "float-end");
    removeButton.onclick = () => removeDate(index);

    listItem.appendChild(removeButton);
    dateListContainer.appendChild(listItem);
  });
}

/**
 * Generates an array of dates for the last X years every first last day of the month
 * @param {Number} numberOfYears
 * @returns {Array} dates e.g. ["2021-01-31", "2021-02-28", ...]
 */
function generateDatesForLastYears(numberOfYears) {
  const dates = [];
  const currentDate = new Date();
  const yearsAgo = new Date(currentDate.getFullYear() - numberOfYears, currentDate.getMonth(), currentDate.getDate());

  for (let d = yearsAgo; d <= currentDate; d.setDate(d.getDate() + 1)) {
    if (d.getDate() === 1) {
      const formattedDate = d.toISOString().split("T")[0];
      dates.push(formattedDate);
    }
  }

  return dates;
}

/**
 * Generates buttons with fast configurations
 * @param {String} fastConfigContainerId
 * @param {Array} settings e.g. [ { label: "Último año", executeOnClick: () => { ... }, classes: ["btn", "btn-block", "btn-barymont-grey", "my-3"] }, ... ]
 */
function generateFastConfigButtons(fastConfigContainerId, settings) {
  settings.forEach((item, key) => {
    const button = document.createElement("button");
    button.textContent = settings[key].label;
    button.classList.add(...settings[key].classes);
    button.onclick = settings[key].executeOnClick;
    document.getElementById(fastConfigContainerId).appendChild(button);
  });
}

function verifyDatesToAnalyzeGlobalExistence() {
  console.log(datesToAnalyze);
  if (!datesToAnalyze) {
    console.error("The variable datesToAnalyze is not set in the global scope, the client retention dashboard will not work.");
    return false;
  }

  return true;
}

export { getDatesToAnalyze, setDatesToAnalyze, COLORS, MONTH_NAMES, renderExportToCsvButton, renderMaximizeChartButton, toggleLoadingButton, addBusinessAnalyticsDate, updateBussinesAnalyticsDateList, generateDatesForLastYears, generateFastConfigButtons, verifyDatesToAnalyzeGlobalExistence };
