"use strict";

import { getDatesToAnalyze, setDatesToAnalyze, renderMaximizeChartButton, renderExportToCsvButton, generateFastConfigButtons, updateBussinesAnalyticsDateList, generateDatesForLastYears, toggleLoadingButton, addBusinessAnalyticsDate } from "./business_analytics_shared.js";

/**
 * Analyzes the client retention data for the selected dates. Needs the datesToAnalyze global variable to be set with at least 2 dates.
 * This function needs the global variable datesToAnalyze to be set in the global scope
 * @param {String} resultContainerId
 * @param {String} analyzeButtonId
 * @returns {void}
 */
function analyzeActiveClientsData(resultContainerId, analyzeButtonId) {
  if (getDatesToAnalyze().length === 0) {
    createToast("error", "Fechas no seleccionadas", "Por favor, selecciona al menos una fecha para realizar el análisis.");
    return;
  }

  toggleLoadingButton(document.getElementById(analyzeButtonId), '<i class="fas fa-spinner fa-spin"></i> Analizando datos...', '<i class="fas fa-chart-line mr-2"></i> Comenzar análisis');

  fetch("/business-analytics/active-clients/ajax", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      datesToAnalyze: getDatesToAnalyze(),
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      renderResults(resultContainerId, data.data);
      createToast("success", "Análisis completado", "El análisis ha finalizado con éxito. Puedes ver los resultados en la sección de resultados.");
    })
    .catch((error) => {
      console.error("Error:", error);
      createToast("error", "Error en el análisis", "Ha ocurrido un error al realizar el análisis. Por favor, intenta nuevamente.");
    })
    .finally(() => {
      toggleLoadingButton(document.getElementById(analyzeButtonId), '<i class="fas fa-spinner fa-spin"></i> Analizando datos...', '<i class="fas fa-chart-line mr-2"></i> Comenzar análisis');
    });
}

/**
 * Renders the results of the client retention analysis on the given container
 * This function needs the global variable datesToAnalyze to be set in the global scope
 * @param {String} resultsContainerId
 * @param {Array} data Array of objects with the results of the analysis Ex: [{date: "2021-01-01", activeClients: 100, newClients: 10, lostClients: 5, retentionRate: 95}]
 * @returns {void}
 */
function renderResults(resultsContainerId, data) {
  const resultsContainer = document.getElementById(resultsContainerId);

  resultsContainer.innerHTML = "";

  if (getDatesToAnalyze().length === 1) {
    const result = data[0];
    const card = document.createElement("div");
    card.classList.add("card", "mb-4");

    const cardHeader = document.createElement("div");
    cardHeader.classList.add("card-header", "text-dark", "font-weight-bold");
    cardHeader.textContent = "Resultados para la fecha " + result.date;

    const cardBody = document.createElement("div");
    cardBody.classList.add("card-body");

    const resultText = document.createElement("p");
    resultText.innerHTML = "Clientes activos: <strong>" + result.activeClients + "</strong>";

    cardBody.appendChild(resultText);
    card.appendChild(cardHeader);
    card.appendChild(cardBody);

    resultsContainer.appendChild(card);
  } else if (getDatesToAnalyze().length > 1) {
    const canvas = document.createElement("canvas");
    canvas.id = "results-chart";
    resultsContainer.appendChild(canvas);

    const ctx = canvas.getContext("2d");

    const COLOR_UP_HEX = "#4CB140";
    const COLOR_DOWN_HEX = "#C9190B";
    const COLOR_SAME_HEX = COLOR_UP_HEX;

    const COLOR_UP_RGB = "rgba(76, 177, 64, 0.08)";
    const COLOR_DOWN_RGB = "rgba(201, 25, 11, 0.08)";
    const COLOR_SAME_RGB = COLOR_UP_RGB;

    // eslint-disable-next-line no-inner-declarations
    function generateGradient(chartArea, data) {
      if (!chartArea) {
        return;
      }
      const gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
      data.forEach((result, index) => {
        const currentValue = result.activeClients;
        const previousValue = data[index - 1]?.activeClients || 0;
        const percentage = (currentValue - previousValue) / previousValue;
        let color;

        if (percentage > 0) {
          color = COLOR_UP_RGB;
        } else if (percentage < 0) {
          color = COLOR_DOWN_RGB;
        } else {
          color = COLOR_SAME_RGB;
        }

        gradient.addColorStop(index / (data.length - 1), color);
      });

      return gradient;
    }

    const chartData = {
      labels: data.map((result) => result.date),
      datasets: [
        {
          type: "line",
          label: "Clientes activos",
          data: data.map((result) => result.activeClients),
          backgroundColor: function (context) {
            return generateGradient(context.chart.chartArea, data);
          },
          borderColor: function (context) {
            const index = context.dataIndex;
            const currentValue = context.dataset.data[index];
            const previousValue = context.dataset.data[index - 1] || 0;

            if (previousValue > currentValue) {
              return COLOR_DOWN_HEX;
            } else if (previousValue < currentValue) {
              return COLOR_UP_HEX;
            } else {
              return COLOR_SAME_HEX;
            }
          },
          borderWidth: 2,
          pointBackgroundColor: function (context) {
            const index = context.dataIndex;
            const currentValue = context.dataset.data[index];
            const previousValue = context.dataset.data[index - 1] || 0;

            if (previousValue > currentValue) {
              return COLOR_DOWN_HEX;
            } else if (previousValue < currentValue) {
              return COLOR_UP_HEX;
            } else {
              return COLOR_SAME_HEX;
            }
          },
          fill: true,
        },
      ],
    };

    const chart = new Chart(ctx, {
      data: chartData,
      options: {
        animation: {
          duration: 2500,
        },
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false,
            position: "top",
          },
          title: {
            display: true,
            text: "Análisis de clientes activos",
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const index = context.dataIndex;
                const currentValue = context.raw;
                const previousValue = context.dataset.data[index - 1] || 0;
                let percentageChange = "";

                if (previousValue !== 0) {
                  const change = currentValue - previousValue;
                  const percentage = (change / previousValue) * 100;
                  percentageChange = `Variación: (${percentage.toFixed(2)}%)`;
                }

                return [`${context.dataset.label}: ${currentValue}`, percentageChange];
              },
            },
          },
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: "Fecha",
            },
          },
          y: {
            display: true,
            title: {
              display: true,
              text: "Nº Clientes Activos",
            },
            beginAtZero: false,
          },
        },
      },
    });

    renderMaximizeChartButton(resultsContainer, chart);
    renderExportToCsvButton(resultsContainer, chartData, "business_analytics_client_retention");
  }
}

// Constants for the elements IDs in the HTML file for the client retention dashboard
const DateInputId = "date-input";
const DateListContainerId = "date-list-container";
const DateAddButtonId = "date-add-button";
const AnalyzeButtonId = "start-analysis-button";
const ClearDateSelectionButtonId = "clear-date-selection";
const FastConfigContainerId = "fast-config-container";
const ResultContainerId = "result-container";

// Constants for the fast config buttons settings
const FAST_CONFIG_BUTTONS_SETTINGS = [
  {
    label: "Último año",
    executeOnClick: () => {
      setDatesToAnalyze(generateDatesForLastYears(1));
      updateBussinesAnalyticsDateList(DateListContainerId);
    },
    classes: ["btn", "btn-block", "btn-barymont-grey", "my-3"],
  },
  {
    label: "Últimos 3 años",
    executeOnClick: () => {
      setDatesToAnalyze(generateDatesForLastYears(3));
      updateBussinesAnalyticsDateList(DateListContainerId);
    },
    classes: ["btn", "btn-block", "btn-barymont-grey", "my-3"],
  },
  {
    label: "Últimos 5 años",
    executeOnClick: () => {
      setDatesToAnalyze(generateDatesForLastYears(5));
      updateBussinesAnalyticsDateList(DateListContainerId);
    },
    classes: ["btn", "btn-block", "btn-barymont-grey", "my-3"],
  },
  {
    label: "Últimos 10 años",
    executeOnClick: () => {
      setDatesToAnalyze(generateDatesForLastYears(10));
      updateBussinesAnalyticsDateList(DateListContainerId);
    },
    classes: ["btn", "btn-block", "btn-barymont-grey", "my-3"],
  },
];

// Event listeners for the client retention dashboard

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname === "/business-analytics/active-clients") {
      generateFastConfigButtons(FastConfigContainerId, FAST_CONFIG_BUTTONS_SETTINGS);
      updateBussinesAnalyticsDateList(DateListContainerId);

      document.getElementById(AnalyzeButtonId).addEventListener("click", () => {
        analyzeActiveClientsData(ResultContainerId, AnalyzeButtonId);
      });

      document.getElementById(ClearDateSelectionButtonId).addEventListener("click", () => {
        setDatesToAnalyze([]);
        updateBussinesAnalyticsDateList(DateListContainerId);
      });

      document.getElementById(DateInputId).addEventListener("keypress", function (e) {
        if (e.key === "Enter") {
          addBusinessAnalyticsDate(DateInputId, DateListContainerId);
        }
      });

      document.getElementById(DateAddButtonId).addEventListener("click", function () {
        addBusinessAnalyticsDate(DateInputId, DateListContainerId);
      });
    }
  });
}

export {};
