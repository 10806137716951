"use strict";

import { searchProvinces } from "../../api/provinces.js";
import { validateIban } from "../../api/validations.js";
import { showLoadingButton, updateButtonLabel } from "../shared/shared.js";
import { v4 as uuidv4 } from "uuid";

var FILTER_STATE_LOCAL_STORAGE_KEY = "NIF_MANAGEMENT_FILTER_STATE_";
var FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME = 1000 * 60 * 20; // 20 minutes in milliseconds
var CURRENT_NIF_BANK_ACCOUNTS = [];

function getStateOfCustomFilters() {
  return {
    startDateRange: $("#nif-creation-start-date-range-filter").val(),
    finishDateRange: $("#nif-creation-finish-date-range-filter").val(),
  };
}

function recreateStateOfCustomFilters(localStorageData) {
  if (localStorageData.customFilter === undefined) {
    return false;
  }

  $("#nif-creation-start-date-range-filter").val(localStorageData.customFilter.startDateRange);
  $("#nif-creation-finish-date-range-filter").val(localStorageData.customFilter.finishDateRange);
}

function resetearFiltros() {
  $("#nif-creation-start-date-range-filter").val("");
  $("#nif-creation-finish-date-range-filter").val("");

  $("#nifs-table").DataTable().search("").draw();
  $("#nifs-table").DataTable().ajax.reload();
}

function changeInputsWithOtherType(type) {
  if (type === "PERSON") {
    $("#nif-company-rows").addClass("d-none");
    $("#nif-name").attr("required", true);
    $("#nif-last-name").attr("required", true);
    $("#nif-business-name").attr("required", false).val("");
    $("#nif-person-rows").removeClass("d-none");
  }

  if (type === "COMPANY") {
    $("#nif-person-rows").addClass("d-none");
    $("#nif-name").attr("required", false).val("");
    $("#nif-last-name").attr("required", false).val("");
    $("#nif-second-last-name").val("");
    $("#nif-business-name").attr("required", true);
    $("#nif-company-rows").removeClass("d-none");
  }
}

// 16 = CountryId::SPAIN
function getProvinceByCountry(country = 16, selectedProvince = null) {
  if (country === 16) {
    searchProvinces().then((response) => {
      if (response.status != []) {
        $("#nif-select-province").find("option").remove().end();
        $("#nif-select-province").append(`<option value="" disabled selected>Seleccione una provincia...</option>`);

        response.forEach((province) => {
          $("#nif-select-province").append(`<option value="${province.name.toUpperCase().trim()}">${province.name.toUpperCase()}</option>`);
        });

        if (selectedProvince !== null && selectedProvince.trim() !== "") {
          $("#nif-select-province").val(selectedProvince.trim()).trigger("change");
        }

        $("#nif-select-province").selectpicker("destroy");
        $("#nif-select-province").selectpicker();
        $("#nif-select-province").selectpicker("refresh");
      }
    });
  }
}

function validateInputNif() {
  if ($("#nif").val() === "") {
    $("#nif-type").val("");
    changeInputsWithOtherType("PERSON");
    return;
  }

  const nif = ValidateSpanishID($("#nif").val());

  if (nif.valid === false) {
    $("#nif").addClass("is-invalid");
    return;
  }
  $("#nif-type").val(nif.type);

  changeInputsWithOtherType(nif.type.toUpperCase() !== "CIF" ? "PERSON" : "COMPANY");
  $("#nif").removeClass("is-invalid");
  return;
}

function openModalNif(nif) {
  resetValuesModalNif();
  if (nif && nif !== undefined && nif !== "") {
    $.ajax({
      url: "/herramientas/nif/ajax/getInformationNif",
      type: "post",
      data: { nif: nif },
      success: function (data) {
        const response = JSON.parse(data);

        if (response.status === 1) {
          $("#nif-form").attr("action", "/herramientas/nif/ajax/update");

          if (response.data.canBeRemover.status === true) {
            $("#btn-nif-delete").attr("disabled", false);
            $("#btn-nif-delete").attr("data-toggle", "tooltip").attr("data-placement", "top").attr("data-original-title", "Eliminar NIF");
          } else {
            $("#btn-nif-delete").attr("disabled", true);
            $("#btn-nif-delete").attr("data-toggle", "tooltip").attr("data-placement", "top").attr("data-original-title", response.data.canBeRemover.message);
          }

          if (AppGbSession.checkUserHasPermission("Nif:NifDeleteAccessChecker")) {
            $("#btn-nif-delete").removeClass("d-none");
          } else {
            $("#btn-nif-delete").addClass("d-none");
          }

          let splitAddress = response.data.address_1.split(",");
          const nif = ValidateSpanishID(response.data.nif);
          changeInputsWithOtherType(response.data.entity_type);

          const readonly = AppGbSession.checkUserHasPermission("Nif:NifUpdateAccessChecker") ? false : true;

          if (nif.type === "CIF") {
            $("#nif-business-name").val(response.data.first_name);
            $("#nif-business-name").attr("readonly", readonly);
          } else {
            $("#nif-name").val(response.data.first_name);
            $("#nif-name").attr("readonly", readonly);
          }

          $("#nif").val(response.data.nif);
          $("#nif").attr("readonly", true);

          $("#nif-type").val(nif.type);
          $("#nif-last-name").val(response.data.last_name).attr("readonly", readonly);
          $("#nif-second-last-name").val(response.data.second_last_name).attr("readonly", readonly);
          $("#nif-address").val(splitAddress[0].trim()).attr("readonly", readonly);
          $("#nif-address-number").val(splitAddress[1].trim()).attr("readonly", readonly);
          $("#nif-address-2").val(response.data.address_2).attr("readonly", readonly);
          $("#nif-city").val(response.data.city).attr("readonly", readonly);
          $("#nif-postal-code").val(response.data.zip_code.padStart(5, "0")).attr("readonly", readonly);

          if (response.data.country === "ESPAÑA") {
            // 16 = CountryId::SPAIN
            getProvinceByCountry(16, response.data.province);
            $("#nif-input-province").addClass("d-none");
            $("#nif-select-province").attr("disabled", readonly);
            $("#nif-select-province").selectpicker("render");
            $("#nif-select-province").selectpicker("show");
          } else {
            $("#nif-input-province").removeClass("d-none").attr("readonly", readonly);
            $("#nif-select-province").selectpicker("hide").attr("disabled", readonly);
            $("#nif-input-province").removeAttr("disabled");
            $("#nif-input-province").val(response.data.province).attr("readonly", readonly);
          }

          let optionCountry = $("#nif-country option")
            .filter(function () {
              return $(this).text().toUpperCase() === response.data.country;
            })
            .val();

          $("#nif-country").selectpicker("val", optionCountry).attr("disabled", readonly);
          $("#nif-country").selectpicker("render");
          $("#nif-country").selectpicker("show");

          $("#nif-tax-person")
            .val(response.data.tax_personal / 100)
            .attr("readonly", readonly);
          $("#nif-tax-area")
            .val(response.data.tax_area / 100)
            .attr("readonly", readonly);
          $("#nif-tax-area-type").val(response.data.tax_area_type).attr("disabled", readonly);
          $("#nif-tax-area-type").selectpicker("render");

          if (AppGbSession.checkUserHasPermission("Nif:NifUpdateAccessChecker")) {
            $("#btn-nif-action").text("Actualizar");
          } else {
            $("#btn-nif-action").addClass("d-none");
          }

          $("#modal-show-nif")
            .find(".modal-title")
            .text("Actualizar datos del NIF - " + response.data.nif);

          CURRENT_NIF_BANK_ACCOUNTS = response.data.bank_accounts;

          if (CURRENT_NIF_BANK_ACCOUNTS.length > 0) {
            CURRENT_NIF_BANK_ACCOUNTS.forEach((account) => {
              addBankAccountToTable(account);
            });
          } else {
            printDefaultMessageForEmptyBankAccount();
          }
        } else {
          configureModalNifForCreation(nif);
          CURRENT_NIF_BANK_ACCOUNTS = [];
        }
      },
    });
  } else {
    configureModalNifForCreation();
  }

  removeLoadingContainerById("nif-form");
  $("#nif-data").removeClass("d-none");
  $("#nif-actions").removeClass("d-none");
  $("#modal-show-nif").modal("show");
  $('[data-toggle="tooltip"]').tooltip();
}

function printDefaultMessageForEmptyBankAccount() {
  document.getElementById("bank-accounts-body").innerHTML = `
    <tr>
      <td id="nif-without-bank-accounts" colspan="2" class="text-center text-muted">
        <p class="my-3">No hay cuentas bancarias asociadas a este NIF</p>
      </td>
    </tr>`;
}

function addManageBankAccountsEventListenerToSwitchActiveBankAccount(containerId) {
  const tableBody = document.getElementById(containerId);

  tableBody.addEventListener("click", (event) => {
    const switchButton = event.target.closest(".switch-bank-account-btn");
    if (switchButton) {
      const clickedAccountId = switchButton.dataset.id;

      CURRENT_NIF_BANK_ACCOUNTS.forEach((account) => {
        const isSelected = account.id === clickedAccountId;

        account.isActive = isSelected;

        const rowBadge = document.getElementById(`bank-account-status-selected-${account.id}`);
        rowBadge.innerHTML = `<i class="fas ${isSelected ? "fa-check" : "fa-times"} text-white mr-1"></i> ${isSelected ? "ACTIVA" : "INACTIVA"}`;
        rowBadge.className = `badge ${isSelected ? "badge-success" : "badge-secondary"} px-3 py-2`;

        const switchBtn = document.querySelector(`.switch-bank-account-btn[data-id="${account.id}"]`);
        if (switchBtn) {
          switchBtn.style.display = isSelected ? "none" : "inline-block";
        }
      });

      CURRENT_NIF_BANK_ACCOUNTS.forEach((account) => {
        if (!account.isActive) {
          const switchBtn = document.querySelector(`.switch-bank-account-btn[data-id="${account.id}"]`);
          if (switchBtn) {
            switchBtn.style.display = "inline-block";
          }
        }
      });
    }
  });
}

function addManageBankAccountsEventListenerToEditBankAccounts(containerId) {
  const container = document.getElementById(containerId);

  container.addEventListener("click", (event) => {
    const editButton = event.target.closest(".edit-bank-account-btn");
    if (editButton) {
      const accountId = editButton.dataset.id;
      const account = CURRENT_NIF_BANK_ACCOUNTS.find((acc) => acc.id === accountId);

      if (account) {
        editBankAccountModal(account, CURRENT_NIF_BANK_ACCOUNTS);
      }
      return;
    }
  });
}

function addManageBankAccountsEventListenerToDeleteBankAccounts(containerId) {
  const tableBody = document.getElementById(containerId);

  tableBody.addEventListener("click", (event) => {
    if (event.target.closest(".delete-bank-account-btn")) {
      const row = event.target.closest("tr");

      const bankAccountId = row.dataset.id;

      const confirmationModal = createDeleteConfirmationModal(bankAccountId, () => {
        row.classList.add("d-none");
        row.dataset.delete = "true";

        if (CURRENT_NIF_BANK_ACCOUNTS.length === 0) {
          tableBody.innerHTML = `<tr>
              <td id="nif-without-bank-accounts" colspan="2" class="text-center text-muted">
                <p class="my-3">No hay cuentas bancarias asociadas a este NIF</p>
              </td>
            </tr>`;
        }
      });

      confirmationModal.modal("show");
    }
    return;
  });
}

function createDeleteConfirmationModal(bankAccountId, onConfirm) {
  const modalId = `modal-delete-confirmation-${Math.random().toString(36).substring(2, 10)}`;
  const modalHtml = `
    <div class="modal fade" id="${modalId}" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">Confirmar eliminación</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <span class="alert alert-danger d-block text-center">
              ¿Estás seguro de eliminar esta cuenta bancaria?
            </span>
          </div>
          <div class="modal-footer">
          <button type="button" class="btn btn-barymont-red" id="confirm-delete-btn">Eliminar</button>
            <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cancelar</button>
          </div>
        </div>
      </div>
    </div>`;

  document.body.insertAdjacentHTML("beforeend", modalHtml);
  const modalElement = $(`#${modalId}`);

  modalElement.on("click", "#confirm-delete-btn", () => {
    const index = CURRENT_NIF_BANK_ACCOUNTS.findIndex((acc) => acc.id === bankAccountId);
    if (index !== -1) {
      CURRENT_NIF_BANK_ACCOUNTS.splice(index, 1);
    }

    onConfirm();
    modalElement.modal("hide");
    modalElement.on("hidden.bs.modal", () => {
      modalElement.remove();
    });
  });

  return modalElement;
}

function addNewBankAccountModal() {

  const randomId = Math.random().toString(36).substring(2, 10);
  const modalId = `modal-add-bank-account-${randomId}`;
  const accountNumberId = `new-bank-account-number-${randomId}`;
  const accountDescriptionId = `new-bank-account-description-${randomId}`;
  const saveButtonId = `save-bank-account-btn-${randomId}`;

  const modalHtml = `
    <div class="modal fade" id="${modalId}" tabindex="-1" role="dialog" aria-labelledby="addBankAccountLabel">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addBankAccountLabel">Añadir nueva cuenta bancaria</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form id="add-bank-account-form">
              <div class="form-group">
                <label for="${accountNumberId}" class="font-weight-bold">Número de cuenta bancaria (IBAN)</label>
                <input type="text" id="${accountNumberId}" class="form-control" placeholder="Ingrese el número de cuenta" required>
              </div>
              <div class="form-group">
                <label for="${accountDescriptionId}" class="font-weight-bold">Descripción</label>
                <textarea id="${accountDescriptionId}" class="form-control" placeholder="Ingrese una descripción"></textarea>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-barymont-red" id="${saveButtonId}">Añadir</button>
            <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cancelar</button>
          </div>
        </div>
      </div>
    </div>
  `;

  document.body.insertAdjacentHTML("beforeend", modalHtml);
  const modalElement = $(`#${modalId}`);

  modalElement.on("click", "#" + saveButtonId, () => {
    showLoadingButton(saveButtonId, "btn-barymont-red");

    const bankAccountNumber = document.getElementById(accountNumberId).value.trim();
    const bankAccountDescription = document.getElementById(accountDescriptionId).value.trim();

    validateIban(bankAccountNumber).then((response) => {
      if (response) {
        const newAccount = {
          id: uuidv4(),
          bankAccount: bankAccountNumber,
          isActive: CURRENT_NIF_BANK_ACCOUNTS.length === 0,
          description: bankAccountDescription || null,
        };

        CURRENT_NIF_BANK_ACCOUNTS.push(newAccount);

        addBankAccountToTable(newAccount);

        updateButtonLabel(saveButtonId, "Guardar", "btn-barymont-red");
        modalElement.modal("hide");
      } else {
        createToast("error", "Error", "El número de cuenta bancaria no es válido, no se permiten espacios ni minúsculas.", 5000);
        updateButtonLabel(saveButtonId, "Guardar", "btn-barymont-red");
        return;
      }
    });
  });

  modalElement.on("hidden.bs.modal", () => {
    modalElement.remove();
  });

  modalElement.modal("show");
}

function addBankAccountToTable(account) {
  const tableBody = document.getElementById("bank-accounts-body");

  const emptyRow = document.getElementById("nif-without-bank-accounts");
  if (emptyRow) {
    emptyRow.parentElement.remove();
  }

  const newRowHtml = `<tr id="bank-account-row-${account.id}" data-id="${account.id}" class="mb-3">
                        <td colspan="2">
                          <div class="row align-items-center">
                            <div class="col-12 col-md-8">
                              <span class="badge badge-light py-2" style="font-size: 14px" id="bank-account-number-${account.id}">
                                ${account.bankAccount}
                              </span>
                              <span id="bank-account-description-${account.id}" class="text-muted d-inline">${account.description || ""}</span>
                            </div>
                            <div class="col-12 col-md-4 text-right">
                                <span id="bank-account-status-selected-${account.id}"
                                      class="badge ${account.isActive ? "badge-success" : "badge-secondary"} px-3 py-2"
                                      data-id="${account.id}">
                                  <i class="fas ${account.isActive ? "fa-check" : "fa-times"} text-white mr-1"></i> ${account.isActive ? "ACTIVA" : "INACTIVA"}
                                </span>
                                <button type="button" class="btn btn-sm btn-barymont-red switch-bank-account-btn"
                                        title="Establecer como cuenta activa"
                                        data-id="${account.id}" style="${account.isActive ? "display: none;" : ""}">
                                  <i class="fas fa-exchange-alt"></i>
                                </button>
                                <button type="button" class="btn btn-sm btn-barymont-black edit-bank-account-btn" data-id="${account.id}">
                                  <i class="fas fa-edit"></i>
                                </button>
                                <button type="button" class="btn btn-sm btn-barymont-black delete-bank-account-btn">
                                  <i class="fas fa-trash-alt"></i>
                                </button>
                            </div>
                          </div>
                        </td>
                      </tr>`;

  tableBody.insertAdjacentHTML("beforeend", newRowHtml);
}

function editBankAccountModal(account) {
  const randomId = Math.random().toString(36).substring(2, 10);
  const modalId = `modal-edit-bank-account-${randomId}`;
  const modalHtml = `
    <div class="modal fade" id="${modalId}" tabindex="-1" role="dialog" aria-labelledby="editBankAccountLabel">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editBankAccountLabel">Editar cuenta bancaria</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form id="edit-bank-account-form">
              <div class="form-group">
                <label for="edit-bank-account-number-${randomId}" class="font-weight-bold">Número de cuenta bancaria</label>
                <input type="text" id="edit-bank-account-number-${randomId}" class="form-control" value="${account.bankAccount}" required>
              </div>
              <div class="form-group">
                <label for="edit-bank-account-description-${randomId}" class="font-weight-bold">Descripción</label>
                <textarea id="edit-bank-account-description-${randomId}" class="form-control">${account.description || ""}</textarea>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-barymont-red" id="save-edited-bank-account-btn-${randomId}">Guardar</button>
            <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cancelar</button>
          </div>
        </div>
      </div>
    </div>
  `;

  document.body.insertAdjacentHTML("beforeend", modalHtml);
  const modalElement = $(`#${modalId}`);

  modalElement.on("click", `#save-edited-bank-account-btn-${randomId}`, () => {
    const updatedBankAccountNumber = document.getElementById(`edit-bank-account-number-${randomId}`).value.trim();
    const updatedDescription = document.getElementById(`edit-bank-account-description-${randomId}`).value.trim();

    validateIban(updatedBankAccountNumber).then((response) => {
      if (response) {
        account.bankAccount = updatedBankAccountNumber;
        account.description = updatedDescription;

        document.getElementById(`bank-account-number-${account.id}`).textContent = updatedBankAccountNumber;
        document.getElementById(`bank-account-description-${account.id}`).textContent = `${updatedDescription || ""}`;

        updateAccountDataFromGlobalVariable(account);

        modalElement.modal("hide");
        modalElement.on("hidden.bs.modal", () => {
          modalElement.remove();
        });
      } else {
        createToast("error", "Error", "El número de cuenta bancaria no es válido, no se permiten espacios ni minúsculas.", 5000);
      }
    });
  });

  modalElement.modal("show");

  modalElement.on("hidden.bs.modal", function () {
    modalElement.remove();
  });
}

function configureModalNifForCreation(nif) {
  $("#nif-form").attr("action", "/herramientas/nif/ajax/create");
  $("#modal-show-nif").find("input").val("");
  $("#nif").val(nif).attr("readonly", false);
  $("#nif-last-name").attr("readonly", false);
  $("#nif-second-last-name").attr("readonly", false);
  $("#nif-address").attr("readonly", false);
  $("#nif-address-number").attr("readonly", false);
  $("#nif-address-2").attr("readonly", false);
  $("#nif-city").attr("readonly", false);
  $("#nif-postal-code").attr("readonly", false);
  $("#nif-business-name").attr("readonly", false);
  $("#nif-name").attr("readonly", false);
  $("#nif-input-province").attr("readonly", false);
  $("#nif-select-province").attr("disabled", false);
  $("#nif-select-province").selectpicker("render");
  $("#nif-country").attr("disabled", false);
  $("#nif-country").selectpicker("render");
  $("#nif-tax-person").attr("readonly", false);
  $("#nif-tax-area").attr("readonly", false);
  $("#nif-tax-area-type").attr("disabled", false);
  $("#nif-tax-area-type").selectpicker("render");
  $("#btn-nif-action").text("Crear nuevo NIF");
  $("#btn-nif-action").removeClass("d-none");
  $("#modal-show-nif").find(".modal-title").text("Crear nuevo NIF");
  validateInputNif();
  // 16 = CountryId::SPAIN
  getProvinceByCountry(16);
  $("#nif-select-province").selectpicker("show");
  $("#nif-tax-area-type").val("");
  $("#nif-tax-area-type").selectpicker("render");
  printDefaultMessageForEmptyBankAccount();
}

function resetValuesModalNif() {
  $("#nif-data").addClass("d-none");
  $("#nif-actions").addClass("d-none");
  $("#btn-nif-delete").addClass("d-none");

  showLoadingContainerById("nif-form");

  // 16 = CountryId::SPAIN
  $("#nif-country").selectpicker("val", "16");
  $("#nif-input-province").addClass("d-none");
  $("#nif-select-province").selectpicker("val", "");
  $("#nif-select-province").selectpicker("hide");

  $("#nif-form").removeClass("was-validated");
  $("#nif-form input").removeClass("is-invalid");
  $("#btn-nif-action").attr("disabled", false);
  $("#nif").val("").attr("readonly", false);

  document.getElementById("bank-accounts-body").innerHTML = "";
  $("#alert-container").remove();
}

function updateAccountDataFromGlobalVariable(account) {
  const index = CURRENT_NIF_BANK_ACCOUNTS.findIndex((acc) => acc.id === account.id);
  if (index !== -1) {
    CURRENT_NIF_BANK_ACCOUNTS[index] = account;
  }
}

function showLoadingContainerById(containerId) {
  $("#" + containerId).append(`
    <div id="load-${containerId}" class="col-12">
        <div class="d-flex justify-content-center my-3">
            <div class="spinner-border text-barymont-red" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
  `);
}

function removeLoadingContainerById(containerId) {
  $("#load-" + containerId).remove();
}

function validateNifData() {
  const errors = [];

  const nif = ValidateSpanishID($("#nif").val().trim());
  if (!nif.valid) {
    errors.push("Por favor, introduce un número de identificación correcto.");
    $("#nif").addClass("is-invalid");
  } else {
    $("#nif").removeClass("is-invalid");
  }

  document.querySelectorAll("input[pattern]").forEach((input) => {
    if (!input.checkValidity()) {
      errors.push(`El campo ${input.previousElementSibling.textContent.trim()} tiene un formato inválido.`);
      $(input).addClass("is-invalid");
    } else {
      $(input).removeClass("is-invalid");
    }
  });

  const requiredFields = ["#nif", "#nif-address", "#nif-address-number", "#nif-country", "#nif-city", "#nif-postal-code", "#nif-tax-person", "#nif-tax-area", "#nif-tax-area-type"];

  const isCIF = $("#nif-type").val().trim() === "CIF";
  if (!isCIF) {
    requiredFields.push("#nif-name", "#nif-last-name");
  } else {
    requiredFields.push("#nif-business-name");
  }

  if ($("#nif-country").val().trim() === "16") {
    // 16 = CountryId::SPAIN
    requiredFields.push("#nif-select-province");
  } else {
    requiredFields.push("#nif-input-province");
  }

  requiredFields.forEach((field) => {
    if (!$(field).val()?.trim()) {
      errors.push(`El campo ${$(field).siblings("label").text().trim()} es obligatorio.`);
      $(field).addClass("is-invalid");
    } else {
      $(field).removeClass("is-invalid");
    }
  });

  const taxArea = parseFloat($("#nif-tax-area").val());
  const taxPerson = parseFloat($("#nif-tax-person").val());

  if (isNaN(taxArea) || taxArea < 0 || taxArea > 100) {
    errors.push("El campo % Impuesto área debe estar entre 0 y 100.");
    $("#nif-tax-area").addClass("is-invalid");
  } else {
    $("#nif-tax-area").removeClass("is-invalid");
  }

  if (isNaN(taxPerson) || taxPerson < 0 || taxPerson > 100) {
    errors.push("El campo % Impuesto personal debe estar entre 0 y 100.");
    $("#nif-tax-person").addClass("is-invalid");
  } else {
    $("#nif-tax-person").removeClass("is-invalid");
  }

  if (errors.length > 0) {
    return { isValid: false, message: errors.join("<br>") };
  }

  return { isValid: true, message: null };
}

function saveNifData() {
  const formData = $("#nif-form").serializeArray();

  const province = ($("#nif-input-province").val() || $("#nif-select-province option:selected").val()).toUpperCase();
  const address = `${$("#nif-address").val().trim()}, ${$("#nif-address-number").val().trim()}`;
  const name = $("#nif-type").val().trim() === "CIF" ? $("#nif-business-name").val() : $("#nif-name").val();
  const country = $('#nif-country option[value="' + $("#nif-country").val() + '"]')
    .text()
    .toUpperCase();

  formData.push({ name: "nif-province", value: province });
  formData.push({ name: "nif-complete-address", value: address });
  formData.push({ name: "nif-first-name", value: name });
  formData.push({ name: "nif-country", value: country });
  formData.push({ name: "nif-bank-accounts", value: JSON.stringify(CURRENT_NIF_BANK_ACCOUNTS) });

  const toastTitle = $("#nif-form").attr("action").includes("create") ? "NIF creado con éxito" : "NIF actualizado con éxito";

  const toastMessage = $("#nif-form").attr("action").includes("create") ? "El NIF se ha creado correctamente." : "El NIF se ha actualizado correctamente.";

  $("#btn-nif-action").attr("disabled", true);

  $.ajax({
    url: $("#nif-form").attr("action"),
    type: "post",
    data: formData,
    success: function (data) {
      const response = JSON.parse(data);
      if (response.status === 1) {
        createToast("success", toastTitle, toastMessage, 5000);
        $("#modal-show-nif").modal("hide");
      } else {
        createToast("warning", "Ha ocurrido un error", response.error.message, 5000);
      }
    },
    complete: function () {
      $("#btn-nif-action").attr("disabled", false);
      $("#nifs-table").DataTable().ajax.reload();
    },
    error: function () {
      createToast("error", "Ha ocurrido un error", "Por favor, vuelve a intentarlo más tarde.", 5000);
    },
  });
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname.includes("/herramientas/nif")) {
      $(".triggerDatatableUpdateNif").change(function () {
        $("#nifs-table").DataTable().ajax.reload();
      });

      var nifsTable = $("#nifs-table").DataTable({
        language: {
          sProcessing: "Procesando Datos...",
          sLengthMenu: "Mostrar _MENU_ Nifs",
          sZeroRecords: "No se encontraron NIFs coincidentes con el criterio de búsqueda.",
          sEmptyTable: "Ningún NIF coincidente con el criterio de búsqueda.",
          sInfo: "Mostrando NIF del _START_ al _END_ de un total de _TOTAL_ NIFs",
          sInfoEmpty: "Mostrando NIFs del 0 al 0 de un total de 0 NIFs",
          sInfoFiltered: "(Filtrados de un total de _MAX_ NIFs)",
          sInfoPostFix: "",
          sSearch: "<div class='input-group-prepend'><div class='input-group-text'><i class='fas fa-search'></i></div></div>",
          searchPlaceholder: "Ingrese su búsqueda...",
          sUrl: "",
          sInfoThousands: ",",
          sLoadingRecords: "Cargando Datos...",
          oPaginate: {
            sFirst: "Primera",
            sLast: "Última",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
          oAria: {
            sSortAscending: ": Activar para ordenar la columna de manera ascendente",
            sSortDescending: ": Activar para ordenar la columna de manera descendente",
          },
          buttons: {
            copySuccess: {
              1: "Copiado 1 NIF",
              _: "Copiados %d NIFs al Portapapeles",
            },
            copyTitle: "NIFs Copiados al Portapapeles",
          },
          select: {
            rows: "%d Nifs seleccionados",
          },
        },
        pagingType: "input",
        columnDefs: [
          {
            targets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 10],
            searchable: true,
            orderable: true,
          },
          {
            targets: [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            className: "dt-center",
          },
          {
            targets: [9, 11],
            orderable: false,
            searchable: false,
          },
        ],
        order: [[10, "desc"]],
        processing: true,
        serverSide: true,
        scrollX: true,
        dom: '<"top"fl>Brt<"bottom"ip><"clear">',
        buttons: [
          {
            extend: "colvis",
            text: "Ver más columnas",
          },
          {
            extend: "collection",
            text: "Herramientas",
            buttons: [
              {
                extend: "copyHtml5",
                text: "Copiar",
              },
              {
                extend: "print",
                text: "Imprimir",
              },
            ],
            fade: true,
          },
          {
            text: '<i class="fas fa-eraser mr-2"></i> Limpiar filtros',
            className: "btn btn-barymont-black my-2 my-md-0",
            action: function () {
              resetearFiltros();
            },
            init: function (api, node) {
              $(node).removeClass("dt-button");
            },
          },
          {
            text: '<i class="fas fa-download mr-2"></i> Exportar',
            className: "btn btn-barymont-black my-2 my-md-0",
            action: function () {
              window.open("/administracion/exportaciones?exportType=nifs", "_blank");
            },
            init: function (api, node) {
              if (AppGbSession.checkUserHasPermission("Nif:NifExportAccessChecker")) {
                $(node).removeClass("dt-button");
              } else {
                $(node).hide();
              }
            },
          },
          {
            text: "<i class='fas fa-plus mr-2'></i>Crear NIF",
            className: "btn btn-barymont-red",
            action: function () {
              openModalNif();
            },
            init: function (api, node) {
              if (AppGbSession.checkUserHasPermission("Nif:NifCreateAccessChecker")) {
                $(node).removeClass("dt-button");
              } else {
                $(node).hide();
              }
            },
          },
        ],
        pageLength: 15,
        lengthMenu: [
          [15, 30, 50],
          [15, 30, 50],
        ],
        select: false,
        keys: true,
        searchHighlight: true,
        ajax: {
          url: "/herramientas/nif/listprocessing",
          type: "post",
          data: function (d) {
            const checkNifStartDateRangeFilter = $("#nif-creation-start-date-range-filter").val();
            const checkNifFinishDateRangeFilter = $("#nif-creation-finish-date-range-filter").val();

            d.startDateRange = checkNifStartDateRangeFilter != "" ? checkNifStartDateRangeFilter : "";
            d.finishDateRange = checkNifFinishDateRangeFilter != "" ? checkNifFinishDateRangeFilter : "";
          },
          error: function (e) {
            if (e.status === 401) {
              createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
              setTimeout(function () {
                location.reload();
              }, 5000);
            } else {
              createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
            }
          },
        },
        fnDrawCallback: function () {
          document.querySelectorAll("[data-action='open-modal']").forEach((element) => {
            element.addEventListener("click", function () {
              openModalNif(element.getAttribute("data-nif"));
            });
          });

          $('[data-toggle="tooltip"]').tooltip();
        },
        stateSave: true,
        stateSaveCallback: function (settings, data) {
          let storageKey = `${FILTER_STATE_LOCAL_STORAGE_KEY}${settings.sInstance}_${getCookieValue("user_id")}`;

          data.customFilter = getStateOfCustomFilters();

          localStorage.setItem(storageKey, JSON.stringify(data));
        },
        stateLoadCallback: function (settings) {
          let storageKey = `${FILTER_STATE_LOCAL_STORAGE_KEY}${settings.sInstance}_${getCookieValue("user_id")}`;

          if (localStorage.getItem(storageKey) === null) {
            return false;
          }

          let localStorageData = JSON.parse(localStorage.getItem(storageKey));

          recreateStateOfCustomFilters(localStorageData);

          if (localStorageData.time < new Date().getTime() - FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME) {
            return false;
          }

          return localStorageData;
        },
      });

      const params = new URLSearchParams(document.location.search);

      window.history.replaceState({}, document.title, window.location.pathname);

      const actionMode = params.get("mode");
      const newNif = params.get("nif");

      if (actionMode === "create") {
        openModalNif(newNif);
      }

      if (actionMode === "update") {
        openModalNif(newNif);
      }

      const nifTaxAreaTypeId = "nif-tax-area-type";
      const nifTaxArea = "nif-tax-area";

      document.getElementById(nifTaxAreaTypeId).addEventListener("change", function () {
        if (this.value === "IGIC") {
          document.getElementById(nifTaxArea).value = 0;
          createToast("info", "Información", "Al seleccionar IGIC el valor del campo '% Impuesto área' se establecerá a 0", 5000);
        }
      });

      $("#nif-form").submit(function (e) {
        e.preventDefault();

        const validationResult = validateNifData();

        if (validationResult.isValid) {
          saveNifData();
        } else {
          createToast("warning", "Ha ocurrido un error", validationResult.message, 5000);
        }
      });

      document.getElementById("btn-nif-delete-confirm").addEventListener("click", function (e) {
        e.preventDefault();
        const nif = $("#nif").val();
        if (nif === "") {
          createToast("warning", "Ha ocurrido un error", "No se ha podido eliminar el NIF.", 5000);
          return;
        }
        $("#modal-nif-delete").modal("hide");
        $.ajax({
          url: "/herramientas/nif/ajax/delete",
          type: "post",
          data: { nif: nif },
          success: function (data) {
            const response = JSON.parse(data);
            if (response.status === 1) {
              createToast("success", "NIF eliminado con éxito", "El NIF se ha eliminado correctamente.", 5000);
              $("#modal-show-nif").modal("hide");
              return;
            }
            createToast("warning", "Ha ocurrido un error", response["error"]["message"], 5000);
          },
          complete: function () {
            nifsTable.ajax.reload();
          },
          error: function () {
            createToast("error", "Ha ocurrido un error", "Por favor, vuelva a intentarlo más tarde. Si el error persiste contacta con el administrador.", 5000);
          },
        });
      });

      document.getElementById("nif-country").addEventListener("change", function () {
        // 16 = CountryId::SPAIN
        if (parseInt($(this).val()) === 16) {
          $("#nif-input-province").val("").attr("disabled", true).addClass("d-none");
          getProvinceByCountry(parseInt($(this).val()));
          return;
        }
        $("#nif-select-province").selectpicker("hide");
        $("#nif-input-province").val("").attr("disabled", false).removeClass("d-none");
      });

      document.getElementById("btn-nif-delete").addEventListener("click", function (e) {
        e.preventDefault();
        $("#modal-nif-delete").modal("show");
      });

      document.getElementById("nif").addEventListener("change", function () {
        validateInputNif();
      });

      $("#modal-show-nif").on("hidden.bs.modal", function () {
        CURRENT_NIF_BANK_ACCOUNTS = [];
      });

      document.getElementById("add-bank-account-btn").addEventListener("click", function() {
        this.disabled = true;
        addNewBankAccountModal();
        setTimeout(() => {
            this.disabled = false;
        }, 2000);
    });


      addManageBankAccountsEventListenerToEditBankAccounts("bank-accounts-body");
      addManageBankAccountsEventListenerToDeleteBankAccounts("bank-accounts-body");
      addManageBankAccountsEventListenerToSwitchActiveBankAccount("bank-accounts-body");
    }
  });
}
