"use strict";

export async function searchCsvForSepa(limit = 10, offset = 0) {
  const getParams = new URLSearchParams();

  getParams.append("limit", limit);
  getParams.append("offset", offset);

  const response = await fetch(`/api/settled/csv-for-sepa${ getParams.toString() ? `?${getParams.toString()}` : "" }`);
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function downloadCsvForSepaFile(fileName) {
  const response = await fetch("/api/settled/download-csv-for-sepa", {
    method: "POST",
    body: JSON.stringify({ fileName: fileName }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}
