"use strict";

const PersonalValue = "PERSONAL";
const BaseValue = "BASE";

/* Start Loaders */
function showLoadingButton(buttonId, bgClass, onlySpiner = false) {
  const loadingHtml = onlySpiner ? '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="pointer-events: none;"></span>' : '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="pointer-events: none;"></span> Cargando...';
  $("#" + buttonId)
    .removeClass(bgClass)
    .addClass("btn-barymont-grey")
    .attr("disabled", true)
    .html(loadingHtml);
}

function updateButtonLabel(buttonId, newLabel, bgClass) {
  $("#" + buttonId)
    .removeClass("btn-barymont-grey")
    .addClass(bgClass)
    .attr("disabled", false)
    .html(newLabel);
}

function showLoadingContainerById(containerId) {
  $("#" + containerId).append(`
    <div id="load-${containerId}" class="col-12">
        <div class="d-flex justify-content-center my-3">
            <div class="spinner-border text-barymont-red" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
  `);
}

function removeLoadingContainerById(containerId) {
  $("#load-" + containerId).remove();
  $("#" + containerId).removeClass("d-none");
}

/** End Loaders */

document.querySelectorAll(`[data-config-goal="true"]`).forEach((element) => {
  element.addEventListener("click", (event) => {
    let userId = null;
    document.getElementById("user-id-selected").value !== "" ? (userId = document.getElementById("user-id-selected").value) : "";
    let isAdmin = document.getElementById("user-id-selected").dataset.isAdmin == 1 ? true : false;
    let globalConfig = document.getElementById("goals-config-data").dataset.globalConfig == 1 ? true : false;
    showLoadingButton(event.target.id, "btn-barymont-red", true);
    openModalTemporalGoalConfig(event.target.dataset.goalCategory, event.target.id, userId, isAdmin, "config", globalConfig);
  });
});

document.querySelectorAll(`[data-config-goal="false"]`).forEach((element) => {
  element.addEventListener("click", (event) => {
    let userId = null;
    document.getElementById("user-id-selected").value !== "" ? (userId = document.getElementById("user-id-selected").value) : "";
    showLoadingButton(event.target.id, "btn-barymont-red", true);
    openModalGoalView(event.target.dataset.goalCategory, event.target.id, userId);
  });
});

document.getElementById("modal-btn-submit-goal")?.addEventListener("click", (e) => {
  saveTemporalGoalConfigData(e.target.dataset.goalCategory);
});

document.getElementById("modal-btn-remove-goal-temporal")?.addEventListener("click", (e) => {
  removeTemporalGoalConfigData(e.target.dataset.goalCategory);
});

document.getElementById("btn-save-goals-data")?.addEventListener("click", () => {
  $("#modal-goal-config-complete").modal("show");
});

document.getElementById("btn-back-dashboard")?.addEventListener("click", () => {
  $("#modal-goal-config-unsave").modal("show");
});

document.getElementById("modal-goal-config-unsave-confirm")?.addEventListener("click", () => {
  window.location.href = "/dashboard";
});

document.getElementById("modal-goal-config-complete-confirm")?.addEventListener("click", (e) => {
  let userId = null;
  document.getElementById("user-id-selected").value !== "" ? (userId = document.getElementById("user-id-selected").value) : "";

  showLoadingButton(e.target.id, "btn-barymont-red", true);
  saveGoalConfigData(userId);

  setTimeout(() => {
    updateButtonLabel(e.target.id, "Guardar", "btn-barymont-red");
    $("#modal-goal-config-complete").modal("hide");
    window.location.reload();
  }, 3000);
});

document.getElementById("modal-btn-goal-select-user")?.addEventListener("click", () => {
  if (document.getElementById("select-users").value == "") {
    return createToast("error", "Error", "Debes seleccionar un usuario para poder obtener su información", 3000);
  }

  window.location.href = "/herramientas/metas/" + document.getElementById("select-users").value;
});

document.getElementById("btn-select-user")?.addEventListener("click", (e) => {
  showLoadingButton(e.target.id, "btn-barymont-red", true);
  loadActiveUsers(e);
});

document.getElementById("btn-select-user-base")?.addEventListener("click", (e) => {
  showLoadingButton(e.target.id, e.target.classList.contains("btn-barymont-black") ? "btn-barymont-black" : "btn-barymont-red", true);
  document.getElementById("btn-select-user-base").disabled = true;
  loadBaseUsers(e).then(() => {
    document.getElementById("btn-select-user-base").disabled = false;
  });
});

document.querySelectorAll(`[data-action="export"]`).forEach((element) => {
  element.addEventListener("click", () => {
    showLoadingButton(element.id, "btn-barymont-red", true);
    document.querySelectorAll(`[data-action="export"]`).forEach((button) => {
      if (button.id !== element.id) {
        button.style = "pointer-events: none;";
      }
    });

    const fetchUsers = () => {
      if (document.getElementById("user-id-selected").dataset.viewOtherUsers == false) {
        document.getElementById("filter-base-export")?.remove();
        return Promise.resolve();
      } else {
        return fetchBaseUsers().then((response) => {
          document.getElementById("goal-users-id").innerHTML = "";

          if (response.status === 0) {
            createToast("error", "❌ Error", "No se han podido obtener los usuarios disponibles para realizar la exportación", 3000);
            return;
          }

          response.data.forEach((user) => {
            $("#goal-users-id").append(`<option value="${user.userId}">${user.name}</option>`);
          });

          $("#goal-users-id").selectpicker("refresh");
          $("#goal-users-id").selectpicker("val", response.userId);
        });
      }
    };

    document.getElementById("modal-export-goals").dataset.exportExtension = element.dataset.exportExtension;
    const fetchGoals = fetchGoalPeriodsDataForExport(element.dataset.exportExtension).then((response) => {
      document.getElementById("goal-period-id").innerHTML = "";

      if (response.status === 0) {
        createToast("error", "❌ Error", "No se han encontrado periodos disponibles para exportar", 3000);
        return;
      }
      response.data.forEach((goalPeriod) => {
        document.getElementById("goal-period-id").innerHTML += `<option value="${goalPeriod.goalPeriodId}">${goalPeriod.description}</option>`;
      });

      $("#goal-period-id").selectpicker("refresh");
    });

    Promise.all([fetchUsers(), fetchGoals]).then(() => {
      let icon = element.dataset.exportExtension === "pdf" ? "fa-file-pdf" : "fa-file-excel";
      let title = element.dataset.exportExtension === "pdf" ? "PDF" : "CSV";
      updateButtonLabel(element.id, "<i class='fas " + icon + " mr-2'></i>Exportar " + title, "btn-barymont-dark-grey");
      document.querySelectorAll(`[data-action="export"]`).forEach((button) => {
        if (button.id !== element.id) {
          button.style = "pointer-events: auto;";
        }
      });
      $("#modal-export-goals").modal("show");
    });
  });
});

document.getElementById("btn-export-goals")?.addEventListener("click", async function () {
  const exportExtension = document.getElementById("modal-export-goals").dataset.exportExtension;

  const goalPeriodOptions = document.getElementById("goal-period-id").options;
  const goalPeriodSelected = [];
  for (let i = 0; i < goalPeriodOptions.length; i++) {
    if (goalPeriodOptions[i].selected) {
      goalPeriodSelected.push(goalPeriodOptions[i].value);
    }
  }

  const goalUserIdOptions = document.getElementById("goal-users-id")?.options;
  const goalUserIdsForExport = [];
  if (goalUserIdOptions) {
    for (let i = 0; i < goalUserIdOptions.length; i++) {
      if (goalUserIdOptions[i].selected) {
        goalUserIdsForExport.push(goalUserIdOptions[i].value);
      }
    }
  } else {
    goalUserIdsForExport.push(AppGbSession.getSessionData().userId);
  }

  showLoadingButton("btn-export-goals", "btn-barymont-red");
  const goalExportData = await fetchGoalExportData(goalPeriodSelected, goalUserIdsForExport, exportExtension);

  if (goalExportData !== undefined) {
    if (exportExtension === "pdf") {
      let blob = new Blob([goalExportData], { type: "application/pdf; charset=utf-8;" });
      let link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "metas_" + new Date().toISOString().slice(0, -5) + "." + exportExtension;
      link.click();
      link.remove();
    }

    if (exportExtension === "csv" && goalExportData.status === 1) {
      let blob = new Blob([goalExportData.exportData], { type: "text/csv; charset=utf-8;" });
      let link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "metas_" + new Date().toISOString().slice(0, -5) + "." + exportExtension;
      link.click();
      link.remove();
    }
  }
  updateButtonLabel("btn-export-goals", "<i class='fas fa-file-export mr-2'></i>Exportar", "btn-barymont-red");
});

document.getElementById("btn-edit-goal")?.addEventListener("click", (e) => {
  showLoadingButton(e.target.id, e.target.classList.contains("btn-barymont-black") ? "btn-barymont-black" : "btn-barymont-red", true);
  saveEditGoalData(e.target.dataset.goalId, e.target.dataset.goalType, e.target.dataset.currentValue);
});

async function openModalGoalView(goalCategory, buttonId, userId, isAdmin = false, mode = "view") {
  let tableAvailableBodyId = "modal-goal-periods-data-table-body";
  let tableExpiredBodyId = "modal-expired-goal-periods-data-table-body";
  let saveButtonId = "modal-btn-submit-goal";
  let removeButtonId = "modal-btn-remove-goal-temporal";
  let globalConfig = false;

  await loadGoalData(goalCategory, tableAvailableBodyId, mode, isAdmin, globalConfig, userId);
  await loadExpiredGoalData(goalCategory, tableExpiredBodyId, userId);

  initalizeGoalPercents();
  eventListenerEdit();
  document.getElementById(saveButtonId)?.remove();
  document.getElementById(removeButtonId)?.remove();
  $("#modal-goal-config").modal("show");
  updateButtonLabel(buttonId, "Visualizar", "btn-barymont-red");
}

document.querySelectorAll(`[data-favorite]`).forEach((element) => {
  element.addEventListener("click", (event) => {
    saveFavoriteGoal(event.target.dataset.favorite, event.target.dataset.goalCategory);
  });
});

async function saveGoalConfigData(userId) {
  let goalInputData = JSON.parse(localStorage.getItem("goalInputData"));
  let formData = new FormData();
  formData.append("goalInputData", JSON.stringify(goalInputData));

  if (userId !== null) {
    formData.append("userId", userId);
  }

  let requestData = {
    method: "POST",
    body: formData,
  };

  let response = await fetch("/herramientas/goals/ajax/saveGoalConfigData", requestData).then((response) => response.json());

  if (response.status) {
    localStorage.removeItem("goalInputData");
    createToast("success", "Datos guardados", "Los datos se guardaron correctamente", 3000);
  } else {
    createToast("error", "Error al guardar", "Ocurrió un error al guardar los datos, intentalo de nuevo más tarde", 3000);
  }
}

/** General Load GoalData Functions */

async function loadGoalData(goalCategory, tableAvailableBodyId, mode, isAdmin, globalConfig, userId = null) {
  let response = await fetchGoalData(goalCategory, userId);
  await printModalGoalData(response, tableAvailableBodyId, isAdmin, mode, globalConfig);
}

async function loadGoalPeriodsData(goalCategory, tableAvailableBodyId, userId = null) {
  let response = await fetchGoalPeriodsData(goalCategory, userId);
  await printModalGoalPeriodsData(response, tableAvailableBodyId);
}

async function loadExpiredGoalData(goalCategory, tableExpiredBodyId, userId = null) {
  let response = await fetchExpiredGoalData(goalCategory, userId);
  await printExpiredGoalData(response, tableExpiredBodyId);
}

async function fetchGoalData(goalCategory, userId) {
  let formData = new FormData();

  formData.append("goalCategory", goalCategory);

  if (userId !== null) {
    formData.append("userId", userId);
  }

  let requestData = {
    method: "POST",
    body: formData,
  };

  return await fetch("/herramientas/goals/ajax/getGoalDataByCategory", requestData).then((response) => response.json());
}

async function printModalGoalData(responseData, tableBodyId, isAdmin, mode, globalConfig) {
  let tableBody = document.getElementById(tableBodyId);
  tableBody.innerHTML = "";

  if (responseData.data.length == 0) {
    if (mode == "view") {
      tableBody.innerHTML = '<td colspan="8" class="text-center w-100">No hay períodos disponibles</td>';
    }
    return;
  }

  responseData.data.forEach((goal) => {
    let htmlCurrentValuePersonal = `<td class="align-middle"><span>-</span></td>`;
    let htmlCurrentValueBase = `<td class="align-middle"><span>-</span></td>`;
    let htmlObjectiveValuePersonal = `<td class="align-middle"><input id="personal-objective-${goal.goalPeriodId}" data-goal-period-id="${goal.goalPeriodId}" data-goal-id="${goal.goalId}" data-goal-period-description="${goal.goalPeriodDescription}" type="number" class="form-control text-center" value="${goal.objectiveValuePersonal}" min="0"></td>`;
    let htmlObjectiveValueBase = `<td class="align-middle"><input id="base-objective-value" data-goal-period-id="${goal.goalPeriodId}" data-goal-id="${goal.goalId}" type="number" class="form-control text-center" value="${goal.objectiveValueBase}" min="0"></td>`;
    let htmlDeleteButton = `<td class="align-middle"><button data-goal-action="delete" data-goal-category="${goal.categoryValue}" data-goal-id="${goal.goalId}" class="btn btn-barymont-red"><i class="fas fa-trash" style="pointer-events: none;"></i></button></td>`;
    let htmlEditButtonPersonal = "";
    let htmlEditButtonBase = "";
    document.getElementById("modal-goal-title").innerHTML = "Configuración Meta de " + goal.category;

    if (mode == "view" || (!globalConfig && isAdmin == false)) {
      if (goal.percentValuePersonal >= 10000 && window.location.pathname === "/herramientas/metas") {
        htmlEditButtonPersonal = `
          <div class="row">
            <div class="col-12">
              <button data-goal-action="edit" data-goal-id="${goal.goalId}" data-goal-type="${PersonalValue}" data-current-value="${goal.currentValuePersonal}" class="btn btn-xs btn-barymont-red py-2">Aumentar</button>
            </div>
          </div>`;
      }

      if (goal.percentValueBase >= 10000 && window.location.pathname === "/herramientas/metas") {
        htmlEditButtonBase = `
          <div class="row">
            <div class="col-12">
              <button data-goal-action="edit" data-goal-id="${goal.goalId}" data-goal-type="${BaseValue}" data-current-value="${goal.currentValueBase}" class="btn btn-xs btn-barymont-red py-2">Aumentar</button>
            </div>
          </div>`;
      }

      htmlObjectiveValuePersonal = `
      <td class="align-middle">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <span>${goal.objectiveValuePersonal}</span>
            </div>
          </div>
          ${htmlEditButtonPersonal}
        </div>
      </td>`;

      htmlObjectiveValueBase = `
      <td class="align-middle">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <span>${Number(goal.objectiveValueBase) !== 0 ? goal.objectiveValueBase : "-"}</span>
            </div>
          </div>
          ${htmlEditButtonBase}
        </div>
      </td>`;
    }

    if (Number(goal.currentValuePersonal) !== 0) {
      htmlCurrentValuePersonal = `<td class="align-middle font-weight-bold"><span>${goal.currentValuePersonal}</span></td>`;
    }

    if (Number(goal.currentValueBase) !== 0) {
      htmlCurrentValueBase = `<td class="align-middle font-weight-bold"><span>${goal.currentValueBase}</span></td>`;
    }

    tableBody.innerHTML += `
          <tr class="text-center border">
            <td class="align-middle bg-barymont-light-green"><strong>${goal.goalPeriodDescription}</strong></td>
            ${htmlObjectiveValuePersonal}
            ${htmlCurrentValuePersonal}
            <td class="align-middle"><div class="my-0 mx-auto gauge-size-sm" id="personal-${goal.goalPeriodId}" data-percent-value="${goal.percentValuePersonal}"></div></td>
            ${htmlObjectiveValueBase}
            ${htmlCurrentValueBase}
            <td class="align-middle"><div class="my-0 mx-auto gauge-size-sm" id="base-${goal.goalPeriodId}" data-percent-value="${goal.percentValueBase}"></div></td>
            ${isAdmin ? htmlDeleteButton : ""}
          </tr>`;
  });
  checkDeleteActionColumn();
}

async function fetchGoalPeriodsData(goalCategory, userId) {
  let formData = new FormData();

  formData.append("goalCategory", goalCategory);
  if (userId !== null) {
    formData.append("userId", userId);
  }

  let requestData = {
    method: "POST",
    body: formData,
  };

  return await fetch("/herramientas/goals/ajax/getGoalPeriodsData", requestData).then((response) => response.json());
}

async function printModalGoalPeriodsData(responseData, tableBodyId) {
  if (responseData.data.length == 0) {
    return;
  }

  let tableBody = document.getElementById(tableBodyId);
  document.getElementById("modal-goal-title").innerHTML = "Configuración Meta de " + responseData.goalCategory;

  responseData.data.forEach((goalPeriod) => {
    if (document.querySelector(`[data-goal-period-id="${goalPeriod.goalPeriodId}"]`) !== null) return;
    tableBody.innerHTML += `
          <tr class="text-center">
            <td class="align-middle bg-barymont-light-blue"><strong>${goalPeriod.description}</strong></td>
            <td class="align-middle"><input id="personal-objective-value" data-goal-period-id="${goalPeriod.goalPeriodId}" data-goal-period-description="${goalPeriod.description}" data-goal-id="null" type="number" class="form-control text-center" value="0" min="0"></td>
            <td class="align-middle">-</td>
            <td class="align-middle">-</td><td><input id="base-objective-value" data-goal-period-id="${goalPeriod.goalPeriodId}" data-goal-id="null" type="number" class="form-control text-center" value="0" min="0"></td>
            <td class="align-middle">-</td>
            <td class="align-middle">-</td>
          </tr>`;
  });
}

async function fetchExpiredGoalData(goalCategory, userId) {
  let formData = new FormData();

  formData.append("goalCategory", goalCategory);
  if (userId !== null) {
    formData.append("userId", userId);
  }

  let requestData = {
    method: "POST",
    body: formData,
  };

  return await fetch("/herramientas/goals/ajax/getExpiredGoalData", requestData).then((response) => response.json());
}

async function printExpiredGoalData(responseData, tableBodyId) {
  let tableBody = document.getElementById(tableBodyId);
  tableBody.innerHTML = "";

  if (responseData.data.length == 0) {
    tableBody.innerHTML = '<td colspan="8" class="text-center w-100">No hay períodos disponibles</td>';
    return;
  }

  responseData.data.forEach((goal) => {
    tableBody.innerHTML += `
          <tr class="text-center">
            <td class="align-middle font-weight-bold">${goal.goalPeriodDescription}</td>
            <td class="align-middle">${Number(goal.objectiveValuePersonal) !== 0 ? goal.objectiveValuePersonal : "-"}</td>
            <td class="align-middle font-weight-bold">${Number(goal.currentValuePersonal) !== 0 ? goal.currentValuePersonal : "-"}</td>
            <td class="align-middle"><div class="my-0 mx-auto gauge-size-sm" id="personal-${goal.goalPeriodId}" data-percent-value="${goal.percentValuePersonal}"></div></td>
            <td class="align-middle">${Number(goal.objectiveValueBase) !== 0 ? goal.objectiveValueBase : "-"}</td>
            <td class="align-middle font-weight-bold">${Number(goal.currentValueBase) !== 0 ? goal.currentValueBase : "-"}</td>
            <td class="align-middle"><div class="my-0 mx-auto gauge-size-sm" id="base-${goal.goalPeriodId}" data-percent-value="${goal.percentValueBase}"></div></td>
          </tr>`;
  });
}

function initalizeGoalPercents() {
  document.querySelectorAll(`[data-percent-value]`).forEach((element) => {
    new JustGage({
      id: element.id,
      customSectors: {
        percents: true,
        ranges: [
          {
            color: "#FF0000",
            lo: 0,
            hi: 49,
          },
          {
            color: "#F9C802",
            lo: 50,
            hi: 74,
          },
          {
            color: "#28A745",
            lo: 75,
            hi: 100,
          },
        ],
      },
      value: element.dataset.percentValue / 100,
      min: 0,
      max: 100,
      hideMinMax: true,
      decimals: 2,
      gaugeColor: "#86868a",
      gaugeWidthScale: 0.8,
      counter: true,
      symbol: "%",
      width: 120,
      height: 70,
      humanFriendly: true,
      humanFriendlyDecimal: 0,
      formatNumber: true,
      donut: false,
    });
  });
}

/** End General Load Goal Data Functions */

/** Temporal Goal Config Functions */

async function openModalTemporalGoalConfig(goalCategory, buttonId, userId, isAdmin = false, mode = "config", globalConfig = false) {
  let tableAvailableBodyId = "modal-goal-periods-data-table-body";
  let tableExpiredBodyId = "modal-expired-goal-periods-data-table-body";
  let saveButtonId = "modal-btn-submit-goal";
  let removeButtonId = "modal-btn-remove-goal-temporal";
  let actionsButtonsColumn = "actions-column";

  let tableAvailableBody = document.getElementById(tableAvailableBodyId);
  tableAvailableBody.innerHTML = "";

  document.getElementById(actionsButtonsColumn).classList.add("d-none");

  await loadGoalData(goalCategory, tableAvailableBodyId, mode, isAdmin, globalConfig, userId);
  readTemporalGoalPeriodsConfigData(goalCategory, tableAvailableBodyId);
  await loadGoalPeriodsData(goalCategory, tableAvailableBodyId, userId);
  await loadExpiredGoalData(goalCategory, tableExpiredBodyId, userId, isAdmin);

  initalizeGoalPercents();
  eventListenerDelete();
  $("#modal-goal-config").modal("show");
  if (buttonId !== null) updateButtonLabel(buttonId, "Configurar", "btn-barymont-red");
  document.getElementById(saveButtonId).dataset.goalCategory = goalCategory;
  document.getElementById(removeButtonId).dataset.goalCategory = goalCategory;
}

function saveTemporalGoalConfigData(goalCategory) {
  let goalInputData = getGoalInputData(goalCategory);
  if (goalInputData instanceof Error) return;

  let counterData = document.getElementById("counter-data-" + goalCategory);
  let categoryIndex = goalInputData.findIndex((element) => element.goalCategory == goalCategory);
  let remainingGoals = counterData.dataset.goalPeriodValue != 0 ? counterData.dataset.goalPeriodValue - goalInputData[categoryIndex].data.length : 0;

  counterData.innerHTML = "Disponibles: " + (remainingGoals < 0 ? 0 : remainingGoals);
  counterData.dataset.goalPeriodValue = remainingGoals < 0 ? 0 : remainingGoals;

  if (remainingGoals <= 0) {
    document.getElementById("card-data-" + goalCategory).classList.add("bg-barymont-light-green", "border-barymont-green");
  }

  localStorage.setItem("goalInputData", JSON.stringify(goalInputData));

  $("#modal-goal-config").modal("hide");
  createToast("info", "Información", "Recuerda que para guardar los datos es necesario clicar sobre el botón <strong>Guardar configuración</strong>", 5000);
}

function getGoalInputData(goalCategory) {
  let goalInputCollection = JSON.parse(localStorage.getItem("goalInputData")) || [];
  let categoryIndex = goalInputCollection.findIndex((element) => element.goalCategory == goalCategory);
  let errorValueZero = false;

  if (categoryIndex == -1) {
    categoryIndex = goalInputCollection.length;
    goalInputCollection.push({
      goalCategory: goalCategory,
      data: [],
    });
  } else {
    goalInputCollection[categoryIndex].data = [];
  }

  document.querySelectorAll(`[id^="personal-objective-"][data-goal-period-id]`).forEach((element) => {
    if (Number(element.value) < 0.01) {
      errorValueZero = true;
      return;
    }

    goalInputCollection[categoryIndex].data.push({
      goalPeriodId: element.dataset.goalPeriodId,
      goalPeriodDescription: element.dataset.goalPeriodDescription,
      goalId: element.dataset.goalId === "null" ? null : element.dataset.goalId,
      objectiveValuePersonal: element.value === "0" ? null : element.value,
    });
  });

  if (errorValueZero) {
    createToast("error", "Valor inválido", "No se puede guardar un valor menor de 0.01 en la configuración de una meta personal", 3000);
    return new Error();
  }

  document.querySelectorAll(`[id^="base-objective-"][data-goal-period-id]`).forEach((input) => {
    let goalInputDataIndex = goalInputCollection[categoryIndex].data.findIndex((element) => element.goalPeriodId == input.dataset.goalPeriodId);
    if (Number(input.value) < 0) {
      errorValueZero = true;
      return;
    }
    goalInputCollection[categoryIndex].data[goalInputDataIndex].objectiveValueBase = input.value === "0" ? null : input.value;
  });

  if (errorValueZero) {
    createToast("error", "Valor inválido", "No se puede guardar un valor menor que 0 en la configuración de una meta base", 3000);
    return new Error();
  }

  return goalInputCollection;
}

function readTemporalGoalConfigData() {
  if (localStorage.getItem("goalInputData") !== null) {
    let goalInputData = JSON.parse(localStorage.getItem("goalInputData"));
    goalInputData.forEach((element) => {
      let counterData = document.getElementById("counter-data-" + element.goalCategory);
      let remainingGoals = counterData.dataset.goalPeriodValue != 0 ? counterData.dataset.goalPeriodValue - element.data.length : 0;

      counterData.innerHTML = "Disponibles: " + (remainingGoals < 0 ? 0 : remainingGoals);
      counterData.dataset.goalPeriodValue = remainingGoals < 0 ? 0 : remainingGoals;

      if (remainingGoals <= 0) {
        document.getElementById("card-data-" + element.goalCategory).classList.add("bg-barymont-light-green", "border-barymont-green");
      }
    });
  }
}

function readTemporalGoalPeriodsConfigData(goalCategory, tableBodyId) {
  if (localStorage.getItem("goalInputData") !== null) {
    let goalInputData = JSON.parse(localStorage.getItem("goalInputData"));
    let goalCategoryIndex = goalInputData.findIndex((element) => element.goalCategory == goalCategory);

    if (goalCategoryIndex == -1) return;

    goalInputData[goalCategoryIndex].data.forEach((element) => {
      printTemporalGoalPeriodsData(element.goalPeriodId, element.goalPeriodDescription, element.objectiveValuePersonal, element.objectiveValueBase, element.goalId, tableBodyId);
    });
  }
}

function printTemporalGoalPeriodsData(goalPeriodId, goalPeriodDescription, objectiveValuePersonal, objectiveValueBase, goalId, tableBodyId) {
  let tableBody = document.getElementById(tableBodyId);

  let element = document.querySelector(`[data-goal-period-id="${goalPeriodId}"]`);
  if (element !== null) {
    element.closest("tr").remove();
  }

  tableBody.innerHTML += `<tr class="text-center border">
                            <td class="align-middle bg-barymont-light-green"><strong>${goalPeriodDescription}</strong></td>
                            <td class="align-middle"><input id="personal-objective-value" data-goal-period-id="${goalPeriodId}" data-goal-period-description="${goalPeriodDescription}" data-goal-id="${goalId}" type="number" class="form-control text-center" value="${objectiveValuePersonal}" min="0"></td>
                            <td class="align-middle"><span>-</span></td>
                            <td class="align-middle"><span>-</span></td>
                            <td class="align-middle"><input id="base-objective-value" data-goal-period-id="${goalPeriodId}" type="number" class="form-control text-center" value="${objectiveValueBase ?? 0}" min="0"></td>
                            <td class="align-middle"><span>-</span></td>
                            <td class="align-middle"><span>-</span></td>
                          </tr>`;
}

async function removeTemporalGoalConfigData(goalCategory, globalConfig = false) {
  let goalInputData = JSON.parse(localStorage.getItem("goalInputData"));
  if (goalInputData == null) {
    createToast("error", "Error", "No se ha encontrado una configuración temporal para esta meta", 3000);
    return;
  }
  let categoryIndex = goalInputData.findIndex((element) => element.goalCategory == goalCategory);
  let counterDataGoalIdNull = 0;

  if (categoryIndex == -1) return;

  goalInputData[categoryIndex].data.forEach((element) => {
    if (element.goalId == null) {
      counterDataGoalIdNull++;
    }
  });

  goalInputData[categoryIndex].data = [];
  localStorage.setItem("goalInputData", JSON.stringify(goalInputData));

  let counterData = document.getElementById("counter-data-" + goalCategory);
  let remainingGoals = counterDataGoalIdNull != 0 ? counterDataGoalIdNull : 0;

  counterData.innerHTML = "Disponibles: " + (remainingGoals < 0 ? 0 : remainingGoals);
  counterData.dataset.goalPeriodValue = remainingGoals < 0 ? 0 : remainingGoals;

  if (remainingGoals > 0) {
    document.getElementById("card-data-" + goalCategory).classList.remove("bg-barymont-light-green", "border-barymont-green");
  }

  showLoadingContainerById("modal-goal-periods-data-container");
  createToast("info", "Información", "Se ha eliminado la configuración temporal, se volverá a cargar el listado de configuración.", 5000);
  let userId = null;
  document.getElementById("user-id-selected").value !== "" ? (userId = document.getElementById("user-id-selected").value) : "";
  let isAdmin = document.getElementById("user-id-selected").dataset.isAdmin == 1 ? true : false;
  await openModalTemporalGoalConfig(goalCategory, null, userId, isAdmin, "config", globalConfig);
  removeLoadingContainerById("modal-goal-periods-data-container");
}

/** End Temporal Goal Config Functions */

async function loadActiveUsers(eventTarget) {
  let response = await fetchActiveUsers();
  printSelectUsers(response);
  updateButtonLabel(eventTarget?.target.id, '<i class="fas fa-people-arrows pr-3"></i> Seleccionar otro usuario', "btn-barymont-black");
}

async function fetchActiveUsers() {
  return await fetch("/herramientas/goals/ajax/getActiveUsers").then((response) => response.json());
}

async function loadBaseUsers(eventTarget) {
  let btnClass = eventTarget.target.classList.contains("btn-barymont-black") ? "btn-barymont-black" : "btn-barymont-red";
  let response = await fetchBaseUsers();
  printSelectUsers(response);
  updateButtonLabel(eventTarget.target.id, '<i class="fas fa-people-arrows pr-3"></i> Seleccionar otro usuario', btnClass);
}

async function fetchBaseUsers() {
  return await fetch("/herramientas/goals/ajax/getBaseUsers").then((response) => response.json());
}

function printSelectUsers(responseData) {
  let selectUsers = document.getElementById("select-users");

  selectUsers.innerHTML = "";
  if (responseData.data === null) {
    createToast("error", "Error", "Ha ocurrido un error al buscar usuarios disponibles para cargar el listado", 3000);
    return;
  }

  responseData.data.forEach((user) => {
    selectUsers.innerHTML += `<option value="${user.userId}">${user.name}</option>`;
  });

  $("#select-users").selectpicker("refresh");
  $("#modal-goal-select-user").modal("show");
}

function eventListenerDelete() {
  document.querySelectorAll(`[data-goal-action="delete"]`).forEach((element) => {
    element.addEventListener("click", (e) => {
      let userId = document.getElementById("user-id-selected").value;
      deleteGoal(e.target.dataset.goalId, e.target.dataset.goalCategory, userId);
    });
  });
}

async function deleteGoal(goalId, goalCategory, userId) {
  let formData = new FormData();
  formData.append("goalId", goalId);
  formData.append("userId", userId);

  let requestData = {
    method: "POST",
    body: formData,
  };

  fetch("/herramientas/goals/ajax/deleteGoal", requestData)
    .then((response) => response.json())
    .then((response) => {
      if (response.status) {
        let counterData = document.getElementById("counter-data-" + goalCategory);
        let remainingGoals = Number(counterData.dataset.goalPeriodValue) + 1;

        counterData.innerHTML = "Disponibles: " + remainingGoals;
        counterData.dataset.goalPeriodValue = remainingGoals;

        document.getElementById("card-data-" + goalCategory).classList.remove("bg-barymont-light-green", "border-barymont-green");

        createToast("success", "Meta eliminada", "La meta se eliminó correctamente", 3000);
        openModalTemporalGoalConfig(goalCategory, null, userId, true);
      } else {
        createToast("error", "Error al eliminar", "Ocurrió un error al eliminar la meta, intentalo de nuevo más tarde", 3000);
      }
    });
}

async function fetchGoalExportData(goalPeriodSelected, goalUserIdsForExport, exportExtension) {
  let formData = new FormData();
  let endpoint = "";

  formData.append("goalPeriodSelected", goalPeriodSelected);
  formData.append("goalUserIdsForExport", goalUserIdsForExport);
  formData.append("exportExtension", exportExtension);

  let requestData = {
    method: "POST",
    body: formData,
  };

  exportExtension === "csv" ? (endpoint = "exportGoalsCSV") : (endpoint = "exportGoalsPDF");

  const response = await fetch("/herramientas/goals/ajax/" + endpoint, requestData);
  if (!response.ok) {
    createToast("error", "❌ Error", "Se ha producido un error al realizar el proceso de exportación...", 5000);
    return;
  }

  if (exportExtension === "pdf") {
    return await response.blob();
  } else {
    return await response.json();
  }
}

function checkDeleteActionColumn() {
  let theadLength = document.querySelectorAll("[id='modal-goal-periods-data-table'] thead th").length;
  let tbody = document.querySelectorAll("[id='modal-goal-periods-data-table'] tbody tr");
  let exitsData = false;

  tbody.forEach((tr) => {
    let element = tr.querySelectorAll("td")[theadLength - 1]?.querySelectorAll("button");
    if (element === undefined) return;

    if (element.length > 0) {
      exitsData = true;
      return;
    }
    tr.querySelectorAll("td")[theadLength - 1].remove();
  });

  if (exitsData) {
    document.getElementById("actions-column").classList.remove("d-none");
    return;
  }

  document.getElementById("actions-column").classList.add("d-none");
}

function eventListenerEdit() {
  document.querySelectorAll(`[data-goal-action="edit"]`).forEach((element) => {
    element.addEventListener("click", (e) => {
      loadEditModal(e.target.dataset.goalId, e.target.dataset.goalType);
    });
  });
}

async function loadEditModal(goalId, goalType) {
  const response = await fetchGoalDataByGoalId(goalId);

  if (response.status == 0) {
    createToast("error", "Error", "Ocurrió un error al obtener la información de la meta, intentalo de nuevo más tarde", 3000);
    return;
  }

  printEditModal(response.data, goalType);
}

async function fetchGoalDataByGoalId(goalId) {
  let formData = new FormData();
  formData.append("goalId", goalId);

  let requestData = {
    method: "POST",
    body: formData,
  };

  return await fetch("/herramientas/goals/ajax/getGoalByGoalId", requestData).then((response) => response.json());
}

function printEditModal(goalData, goalType) {
  document.getElementById("modal-title").innerHTML = `Aumentar Objetivo ${goalData.goalPeriodDescription}`;

  let htmlGoalData = `
    <div class="card-body text-center p-0">
      <div class="form-group">
        <div class="form-row">`;

  if (goalType === PersonalValue) {
    htmlGoalData += `
      <div class="col-md-6 col-12 form-group">
        <label class="font-weight-bold">Objetivo Actual</label>
        <span class="font-weight-bold h4 d-block" id="past-value-personal-edit">${goalData.objectiveValuePersonal}</span>
      </div>
      <div class="col-md-6 col-12 form-group">
        <label class="font-weight-bold">Valor Actual</label>
        <span class="font-weight-bold h4 d-block">${goalData.currentValuePersonal}</span>
      </div>
      <div class="col-12 form-group">
        <label class="font-weight-bold">Nuevo Objetivo</label>
        <input id="value-personal-edit" type="number" class="form-control text-center" value="" min="${goalData.objectiveValuePersonal}">
      </div>`;
  } else {
    htmlGoalData += `
      <div class="col-md-6 col-12 form-group">
        <label class="font-weight-bold">Objetivo Actual</label>
        <span class="font-weight-bold h4 d-block" id="past-value-base-edit">${goalData.objectiveValueBase}</span>
      </div>
      <div class="col-md-6 col-12 form-group">
        <label class="font-weight-bold">Valor Actual Base</label>
        <span class="font-weight-bold h4 d-block">${goalData.currentValueBase}</span>
      </div>
      <div class="col-12 form-group">
        <label class="font-weight-bold">Nuevo Objetivo</label>
        <input id="value-base-edit" type="number" class="form-control text-center" value="" min="${goalData.objectiveValueBase}">
      </div>`;
  }

  htmlGoalData += `
      </div>
    </div>
  </div>`;

  document.getElementById("modal-body").innerHTML = htmlGoalData;
  document.getElementById("btn-edit-goal").dataset.goalId = goalData.goalId;
  document.getElementById("btn-edit-goal").dataset.goalType = goalType;
  document.getElementById("btn-edit-goal").dataset.currentValue = goalType === PersonalValue ? goalData.currentValuePersonal : goalData.currentValueBase;
  $("#modal-edit-goal").modal("show");
}

async function saveEditGoalData(goalId, goalType, currentValue) {
  let value = 0;
  if (goalType === PersonalValue) {
    value = document.getElementById("value-personal-edit").value;
  } else {
    value = document.getElementById("value-base-edit").value;
  }

  if (!checkValidValues(value, goalType, currentValue)) {
    updateButtonLabel("btn-edit-goal", '<i class="fa-solid fa-plus mr-2"></i> Aumentar', "btn-barymont-red");
    return;
  }

  const response = await fetchGoalEditedData(goalId, value, goalType);
  updateButtonLabel("btn-edit-goal", '<i class="fa-solid fa-plus mr-2"></i> Aumentar', "btn-barymont-red");
  if (response.status == 0) {
    createToast("error", "Error", "Ocurrió un error al guardar los cambios, intentalo de nuevo más tarde", 3000);
    return;
  }

  createToast("success", "Cambios guardados", "Los cambios se guardaron correctamente", 3000);
  await loadGoalData(response.data.goalCategory, "modal-goal-periods-data-table-body", "view", false, false, response.data.userId);
  await loadExpiredGoalData(response.data.goalCategory, "modal-expired-goal-periods-data-table-body", response.data.userId);
  initalizeGoalPercents();
  eventListenerEdit();
  $("#modal-edit-goal").modal("hide");
}

async function fetchGoalEditedData(goalId, value, goalType) {
  let formData = new FormData();
  formData.append("goalId", goalId);
  formData.append("value", value);
  formData.append("goalType", goalType);

  let requestData = {
    method: "POST",
    body: formData,
  };

  return await fetch("/herramientas/goals/ajax/editGoal", requestData).then((response) => response.json());
}

function checkValidValues(value, goalType, currentValue) {
  let pastValue = document.getElementById("past-value-" + goalType.toLowerCase() + "-edit").innerHTML;

  if (value == "") {
    createToast("error", "Error", "Debes ingresar un valor para poder guardar los cambios", 3000);
    return false;
  }

  if (Number(value) <= Number(pastValue)) {
    createToast("error", "Error", "Los valores introducidos no superan los valores anteriores.", 3000);
    return false;
  }

  if (Number(value) <= Number(currentValue)) {
    createToast("error", "Error", "El valor introducido no supera el valor actual obtenido en la meta.", 3000);
    return false;
  }

  return true;
}

async function saveFavoriteGoal(favorite, goalCategory) {
  let starElement = document.querySelector(`[data-favorite="${favorite}"][data-goal-category="${goalCategory}"]`);
  let formData = new FormData();
  formData.append("favorite", !favorite);
  formData.append("goalCategory", goalCategory);

  starElement.dataset.favorite = !favorite;
  starElement.firstChild.classList.contains("fa-regular") ? starElement.firstChild.classList.replace("fa-regular", "fa-solid") : starElement.firstChild.classList.replace("fa-solid", "fa-regular");

  let requestData = {
    method: "POST",
    body: formData,
  };

  let response = await fetch("/herramientas/goals/ajax/updateFavoriteGoal", requestData).then((response) => response.json());

  if (!response.status) {
    createToast("error", "Error al guardar", "Ocurrió un error al guardar los cambios, intentalo de nuevo más tarde", 3000);
    starElement.dataset.favorite = !favorite;
    starElement.firstChild.classList.contains("fa-regular") ? starElement.firstChild.classList.replace("fa-regular", "fa-solid") : starElement.firstChild.classList.replace("fa-solid", "fa-regular");
  }

  !favorite ? starElement.setAttribute("data-original-title", "Eliminar de favoritos") : starElement.setAttribute("data-original-title", "Añadir a favoritos");
  $('[data-toggle="tooltip"]').tooltip();
}

async function fetchFavoriteGoalsData(userId) {
  let formData = new FormData();
  formData.append("userId", userId);

  let requestData = {
    method: "POST",
    body: formData,
  };

  let response = await fetch("/herramientas/goals/ajax/getGoalCategoriesFavorites", requestData).then((response) => response.json());

  if (response.status == 0) {
    createToast("error", "Error", "Ocurrió un error al obtener la información de las metas favoritas, intentalo de nuevo más tarde", 3000);
    return;
  }

  return response.data;
}

function printGoalsList(goals, containerId) {
  if (goals.length === 0) {
    document.getElementById(containerId).innerHTML = `
    <div class="col-12">
      <div class="alert alert-light text-center" role="alert">
        <p>Aún no has marcado ninguna meta como favorita<br>Para ver tus metas favoritas, haz clic en el botón de estrella que aparece en cada meta</p>
        <img src="https://content.barymont.com/files/e23d8325-422e-4aec-bd6a-1dab75c3ed6f.png" class="img-fluid" alt="No hay metas favoritas">
        <a href="/herramientas/metas" class="btn btn-block btn-barymont-red mt-3">Ir a metas</a>
      </div>
    </div>`;
    return;
  }

  const categories = new Set();

  $("#" + containerId).append(`<div class="row no-gutters"><div id="goals-list" class="card-columns"></div></div>`);

  goals.map((goal) => {
    categories.add(goal.category);
  });

  categories.forEach((category) => {
    $("#goals-list").append(`
        <div id="${category}" class="col-12 pt-2 pb-0 px-2">
            <a href="/herramientas/metas" class="text-barymont-black">
                <div class="card clickable-box" style="overflow: hidden;">

                    <div class="card-header p-0">
                        <div class="container-fluid px-0">
                            <div class="row no-gutters">

                                <div class="col-12 text-left">
                                    <p class="font-weight-bold py-1 px-2 mb-0" style="font-size: 15px; text-wrap:balance">⭐ ${category}</p>
                                </div>

                                <div class="col-md-12 border-top bg-white" style="font-size: 13px; min-height:92.5px">
                                    <div class="container-fluid">
                                        ${printGoalPeriodsForList(category, goals)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </a>
        </div>
      `);
  });

  initalizeGoalPercents();
}

function printGoalPeriodsForList(category, goals) {
  let goalPeriods = goals.filter((goal) => goal.category === category);
  let html = "";

  html += `<div class="row my-2">`;

  goalPeriods.map((goal) => {
    html += `
      <div class="col-lg-6 col-md-12 col-6 border-right">
          <div class="d-flex flex-column">
            <span class="font-weight-bold text-center">Personal</span>
            <div class="my-0 mx-auto gauge-size-sm" id="personal-${goal.goalPeriodId}-${goal.categoryValue}" data-percent-value="${goal.percentValuePersonal}"></div>
            <span class="font-weight-bold text-center">${goal.currentValuePersonal} de ${goal.objectiveValuePersonal}</span>
            <span class="font-weight-bold text-center">${goal.goalPeriodDescription}</span>
          </div>
      </div>`;

    if (goal.objectiveValueBase != 0) {
      html += `<div class="col-lg-6 col-md-12 col-6 border-right">
                <div class="d-flex flex-column">
                  <span class="font-weight-bold text-center">Base</span>
                  <div class="my-0 mx-auto gauge-size-sm" id="base-${goal.goalPeriodId}-${goal.categoryValue}" data-percent-value="${goal.percentValueBase}"></div>
                  <span class="font-weight-bold text-center">${goal.currentValueBase} de ${goal.objectiveValueBase}</span>
                  <span class="font-weight-bold text-center">${goal.goalPeriodDescription}</span>
                </div>
              </div>`;
    }
  });

  html += `</div>`;

  return html;
}

async function fetchGoalPeriodsDataForExport(exportExtension) {
  let formData = new FormData();

  formData.append("exportExtension", exportExtension);

  let requestData = {
    method: "POST",
    body: formData,
  };

  return await fetch("/herramientas/goals/ajax/getGoalPeriodsDataForExport", requestData).then((response) => response.json());
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname.includes("/herramientas/metas")) {
      localStorage.removeItem("goalInputData");

      let userIdSelected = document.getElementById("user-id-selected");

      if (userIdSelected.dataset.isAdmin == 1 && userIdSelected.value == "") {
        loadActiveUsers();

        let modalSelectUser = document.getElementById("modal-goal-select-user");

        modalSelectUser.dataset.backdrop = "static";

        let closeButtons = modalSelectUser.querySelectorAll('[data-dismiss="modal"]');

        closeButtons.forEach((element) => {
          element.remove();
        });

        let buttonBack = document.createElement("button");
        buttonBack.classList.add("btn", "btn-barymont-black");
        buttonBack.innerHTML = '<i class="fas fa-arrow-circle-left pr-3"></i>Volver al dashboard';
        buttonBack.addEventListener("click", () => {
          window.location.href = "/";
        });

        modalSelectUser.querySelector(".modal-footer").appendChild(buttonBack);
        return;
      }

      if (document.querySelector(`[data-goals-count = "0"]`) !== null) {
        removeLoadingContainerById("goals-config-data");
        $("#modal-goal-zero-config").modal("show");
        return;
      }

      if (document.getElementById("goals-config-data") !== null) {
        readTemporalGoalConfigData();
        removeLoadingContainerById("goals-config-data");
      }

      if (document.getElementById("goals-view-data") !== null) {
        removeLoadingContainerById("goals-view-data");
      }
    }
  });
}

export default {
  fetchFavoriteGoalsData,
  printGoalsList,
};
