export const enum ClientType {
  INDIVIDUAL = "INDIVIDUAL",
  BUSINESS = "BUSINESS",
}

interface ClientTypeSelectOption {
  label: string;
  enabled: boolean;
  isDefault: boolean;
}

export const clientTypeSelectOptions: Record<ClientType, ClientTypeSelectOption> = {
  [ClientType.INDIVIDUAL]: {
    label: "Particular",
    enabled: true,
    isDefault: true,
  },
  [ClientType.BUSINESS]: {
    label: "Autónomo / Empresa",
    enabled: true,
    isDefault: false,
  },
};
