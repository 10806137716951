"use strict";

export async function readChecklistTemplates() {
  const response = await fetch("/api/checklist-templates");
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function readChecklistTemplateCompletionChecks(checklistTemplateId) {
  const response = await fetch(`/api/checklist-template/${checklistTemplateId}`);
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function deleteChecklistTemplate(checklistTemplateId) {
  const response = await fetch(`/api/checklist-template/${checklistTemplateId}`, {
    method: "DELETE",
  });
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function updateChecklistTemplate(checklistTemplateId, checklistTemplateTitle) {
  const response = await fetch(`/api/checklist-template/${checklistTemplateId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ title: checklistTemplateTitle }),
  });
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function updateChecklistTemplateCompletionCheck(checklistTemplateId, completionCheckId, completionCheckDescription) {
  const response = await fetch(`/api/checklist-template/${checklistTemplateId}/completion-check/${completionCheckId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ description: completionCheckDescription }),
  });
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function deleteChecklistTemplateCompletionCheck(checklistTemplateId, completionCheckId) {
  const response = await fetch(`/api/checklist-template/${checklistTemplateId}/completion-check/${completionCheckId}`, {
    method: "DELETE",
  });
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function createChecklistTemplate(checklistTemplateTitle) {
  const response = await fetch("/api/checklist-template", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ title: checklistTemplateTitle }),
  });
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function createChecklistTemplateCompletionCheck(checklistTemplateId, completionCheckDescription) {
  const response = await fetch(`/api/checklist-template/${checklistTemplateId}/completion-check`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ description: completionCheckDescription }),
  });
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function updateChecklistTemplateCompletionCheckOrder(checklistTemplateId, completionChecksOrders) {
  if (!Array.isArray(completionChecksOrders)) {
    throw new Error("Error en el formato de completionChecksOrders recibido.");
  }

  completionChecksOrders.forEach((check, index) => {
    if (typeof check !== "object" || check === null) {
      throw new Error(`El elemento en el índice ${index} debe ser un objeto.`);
    }
    if (!("checklistTemplateCompletionCheckId" in check)) {
      throw new Error(`Falta la propiedad "checklistTemplateCompletionCheckId" en el elemento del índice ${index}.`);
    }
    if (!("order" in check)) {
      throw new Error(`Falta la propiedad "order" en el elemento del índice ${index}.`);
    }
    if (typeof check.checklistTemplateCompletionCheckId !== "string") {
      throw new Error(`La propiedad "checklistTemplateCompletionCheckId" en el índice ${index} debe ser un string.`);
    }
    if (typeof check.order !== "number") {
      throw new Error(`La propiedad "order" en el índice ${index} debe ser un número.`);
    }
  });

  const response = await fetch(`/api/checklist-template/${checklistTemplateId}/reorder`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ checksOrder: completionChecksOrders }),
  });
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}
