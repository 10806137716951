"use strict";

import { getDatesToAnalyze, setDatesToAnalyze, renderMaximizeChartButton, renderExportToCsvButton, generateFastConfigButtons, updateBussinesAnalyticsDateList, generateDatesForLastYears, toggleLoadingButton, addBusinessAnalyticsDate } from "./business_analytics_shared.js";

/**
 * Analyzes the client retention data for the selected dates. Needs the datesToAnalyze global variable to be set with at least 2 dates.
 * This function needs the global variable datesToAnalyze to be set in the global scope
 * @param {String} resultContainerId
 * @param {String} analyzeButtonId
 * @returns {void}
 */
function analyzeClientRetentionData(resultContainerId, analyzeButtonId) {
  if (getDatesToAnalyze().length < 2) {
    createToast("error", "Fechas no seleccionadas", "Por favor, selecciona al menos 2 fechas para poder realizar el análisis.");
    return;
  }

  toggleLoadingButton(document.getElementById(analyzeButtonId), '<i class="fas fa-spinner fa-spin"></i> Analizando datos...', '<i class="fas fa-chart-line mr-2"></i> Comenzar análisis');

  fetch("/business-analytics/client-retention/ajax", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      datesToAnalyze: getDatesToAnalyze(),
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      renderResults(resultContainerId, data.data);
      createToast("success", "Análisis completado", "El análisis ha finalizado con éxito. Puedes ver los resultados en la sección de resultados.");
    })
    .catch((error) => {
      console.error(error);
      createToast("error", "Error en el análisis", "Ha ocurrido un error al realizar el análisis. Por favor, intenta nuevamente.");
    })
    .finally(() => {
      toggleLoadingButton(document.getElementById(analyzeButtonId), '<i class="fas fa-spinner fa-spin"></i> Analizando datos...', '<i class="fas fa-chart-line mr-2"></i> Comenzar análisis');
    });
}

/**
 * Renders the results of the client retention analysis on the given container
 * This function needs the global variable datesToAnalyze to be set in the global scope
 * @param {String} resultsContainerId
 * @param {Array} data Array of objects with the results of the analysis Ex: [{date: "2021-01-01", activeClients: 100, newClients: 10, lostClients: 5, retentionRate: 95}]
 * @returns {void}
 */
function renderResults(resultsContainerId, data) {
  const resultsContainer = document.getElementById(resultsContainerId);

  resultsContainer.innerHTML = "";

  if (getDatesToAnalyze().length > 1) {
    const canvas = document.createElement("canvas");
    canvas.id = "results-chart";
    resultsContainer.appendChild(canvas);

    const ctx = canvas.getContext("2d");

    const COLOR_NEW_CLIENTS = "#2ca02c";
    const COLOR_LOST_CLIENTS = "#d62728";
    const COLOR_ACTIVE_CLIENTS = "#1f77b4";
    const COLOR_RETENTION_RATE = "#000000";

    const chartData = {
      labels: data.map((result) => result.date),
      datasets: [
        {
          type: "line",
          label: "Clientes Activos",
          data: data.map((result) => result.activeClients),
          borderColor: COLOR_ACTIVE_CLIENTS,
          borderWidth: 1,
          pointBackgroundColor: COLOR_ACTIVE_CLIENTS,
          fill: false,
          yAxisID: "y",
        },
        {
          type: "line",
          label: "Tasa de Retención",
          data: data.map((result) => result.retentionRate),
          borderColor: COLOR_RETENTION_RATE,
          borderWidth: 2,
          pointBackgroundColor: COLOR_RETENTION_RATE,
          fill: false,
          yAxisID: "y2",
        },
        {
          type: "bar",
          label: "Clientes Nuevos",
          data: data.map((result) => result.newClients),
          backgroundColor: COLOR_NEW_CLIENTS,
          borderColor: COLOR_NEW_CLIENTS,
          borderWidth: 1,
          yAxisID: "y1",
        },
        {
          type: "bar",
          label: "Clientes Perdidos",
          data: data.map((result) => result.lostClients),
          backgroundColor: COLOR_LOST_CLIENTS,
          borderColor: COLOR_LOST_CLIENTS,
          borderWidth: 1,
          yAxisID: "y1",
        },
      ],
    };

    const chart = new Chart(ctx, {
      data: chartData,
      options: {
        animation: {
          duration: 500,
        },
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: true,
            position: "top",
          },
          title: {
            display: true,
            text: "Análisis de clientes activos y tasa de retención",
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const index = context.dataIndex;
                const currentValue = context.raw;
                const previousValue = context.dataset.data[index - 1] || 0;
                let change = "";
                let percentageChange = "";
                let retentionPercentage = "";
                let label = `${context.dataset.label}: ${currentValue}`;

                if (previousValue !== 0 && context.dataset.label !== "Tasa de Retención") {
                  const diff = currentValue - previousValue;
                  const percentage = (diff / previousValue) * 100;
                  change = `Diferencia: ${diff}`;
                  percentageChange = `Variación clientes activos: (${percentage.toFixed(2)}%)`;
                }

                if (context.dataset.label === "Tasa de Retención") {
                  retentionPercentage = `Retención: ${currentValue.toFixed(3)}%`;
                }

                return [label, percentageChange, change, retentionPercentage].filter((item) => item !== "");
              },
            },
          },
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: "Fecha",
            },
          },
          y: {
            display: true,
            title: {
              display: true,
              text: "Nº Clientes Activos",
            },
            beginAtZero: false,
          },
          y1: {
            display: true,
            title: {
              display: true,
              text: "Clientes Nuevos y Perdidos",
            },
            position: "right",
            beginAtZero: true,
            grid: {
              drawOnChartArea: false,
            },
          },
          y2: {
            display: true,
            title: {
              display: true,
              text: "Tasa de Retención (%)",
            },
            position: "left",
            beginAtZero: false,
            grid: {
              drawOnChartArea: true,
            },
          },
        },
      },
    });

    renderMaximizeChartButton(resultsContainer, chart);
    renderExportToCsvButton(resultsContainer, chartData, "business_analytics_client_retention");
  } else {
    const noData = document.createElement("div");
    noData.classList.add("alert", "alert-info", "w-100");
    noData.textContent = "Por favor, selecciona al menos 2 fechas para poder realizar el análisis.";
    resultsContainer.appendChild(noData);
  }
}

// Constants for the elements IDs in the HTML file for the client retention dashboard
const DateInputId = "date-input";
const DateListContainerId = "date-list-container";
const DateAddButtonId = "date-add-button";
const AnalyzeButtonId = "start-analysis-button";
const ClearDateSelectionButtonId = "clear-date-selection";
const FastConfigContainerId = "fast-config-container";
const ResultContainerId = "result-container";

// Constants for the fast config buttons settings
const FAST_CONFIG_BUTTONS_SETTINGS = [
  {
    label: "Último año",
    executeOnClick: () => {
      setDatesToAnalyze(generateDatesForLastYears(1));
      updateBussinesAnalyticsDateList(DateListContainerId);
    },
    classes: ["btn", "btn-block", "btn-barymont-grey", "my-3"],
  },
  {
    label: "Últimos 3 años",
    executeOnClick: () => {
      setDatesToAnalyze(generateDatesForLastYears(3));
      updateBussinesAnalyticsDateList(DateListContainerId);
    },
    classes: ["btn", "btn-block", "btn-barymont-grey", "my-3"],
  },
  {
    label: "Últimos 5 años",
    executeOnClick: () => {
      setDatesToAnalyze(generateDatesForLastYears(5));
      updateBussinesAnalyticsDateList(DateListContainerId);
    },
    classes: ["btn", "btn-block", "btn-barymont-grey", "my-3"],
  },
  {
    label: "Últimos 10 años",
    executeOnClick: () => {
      setDatesToAnalyze(generateDatesForLastYears(10));
      updateBussinesAnalyticsDateList(DateListContainerId);
    },
    classes: ["btn", "btn-block", "btn-barymont-grey", "my-3"],
  },
];

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname === "/business-analytics/client-retention") {
      generateFastConfigButtons(FastConfigContainerId, FAST_CONFIG_BUTTONS_SETTINGS);
      updateBussinesAnalyticsDateList(DateListContainerId);

      document.getElementById(AnalyzeButtonId).addEventListener("click", () => {
        analyzeClientRetentionData(ResultContainerId, AnalyzeButtonId);
      });

      document.getElementById(ClearDateSelectionButtonId).addEventListener("click", () => {
        setDatesToAnalyze([]);
        updateBussinesAnalyticsDateList(DateListContainerId);
      });

      document.getElementById(DateInputId).addEventListener("keypress", function (e) {
        if (e.key === "Enter") {
          addBusinessAnalyticsDate(DateInputId, DateListContainerId);
        }
      });

      document.getElementById(DateAddButtonId).addEventListener("click", function () {
        addBusinessAnalyticsDate(DateInputId, DateListContainerId);
      });
    }
  });
}
export {};
