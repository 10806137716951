"use strict";

import { showLoadingButton, updateButtonLabel } from "../shared/shared.js";

import { createServiceVisibility, deleteServiceVisibility, readServiceVisibility } from "../../api/service.js";
import { searchUsers } from "../../api/users.js";
import { checkPermissionByServiceType } from "./services.js";

async function deleteServiceVisibilityForUser(userId, serviceId) {
  try {
    await deleteServiceVisibility(serviceId, userId);

    createToast("success", "Éxito", "Visibilidad eliminada correctamente.", 5000);
  } catch (error) {
    createToast("error", "Error", error.message, 5000);
  }
}

async function addServiceVisibility(serviceId, userId, modalId) {
  showLoadingButton("confirm-btn-add-visibility", "btn-barymont-red");

  if (userId === "") {
    createToast("error", "Error", "No se ha seleccionado ningún usuario.", 5000);
    return;
  }

  try {
    await createServiceVisibility(serviceId, userId);

    $(`#${modalId}`).modal("hide");
    createToast("success", "Éxito", "Visibilidad añadida correctamente.", 5000);
  } catch (error) {
    createToast("error", "Error", error.message, 5000);
  }

  updateButtonLabel("confirm-btn-add-visibility", "Añadir", "btn-barymont-red");
}

async function paintUsersVisibilitySelector(searchValue, serviceVisibilityUsersSelectorId) {
  const usersForAddVisibility = await searchUsers([], [], true, searchValue, 10, null);

  $("#" + serviceVisibilityUsersSelectorId).empty();

  usersForAddVisibility.forEach((user) => {
    $("#" + serviceVisibilityUsersSelectorId).append(`<option value="${user.userId}" data-content="<span>${user.name}</span> <span class='badge bg-${user.userProfile.backgroundColor} text-${user.userProfile.textColor} ml-2'>${user.userProfile.label}</span>">${user.name}</option>`);
  });

  $("#" + serviceVisibilityUsersSelectorId).selectpicker("refresh");
}

async function createAddServiceVisibilityModal(serviceId, serviceTypeId) {
  showLoadingButton("add-visibility", "btn-barymont-red");

  const randomModalId = `modal-${Math.random().toString(36).substring(2, 10)}`;
  const serviceVisibilityUsersSelectorId = "service-visibility-user-list";

  const fragment = document.createDocumentFragment();

  const temporalDiv = document.createElement("div");

  temporalDiv.innerHTML = `
    <div class="modal fade" id="${randomModalId}" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">Añadir Visibilidad</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body text-center">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-light"><i class="fa-solid fa-md fa-user"></i></span>
                    </div>
                    <select
                        id="${serviceVisibilityUsersSelectorId}"
                        class="form-control selectpicker show-tick input-lg"
                        title="Usuarios"
                        data-live-search="true"
                        data-live-search-placeholder="Introduce 3 caracteres para buscar un usuario"
                        data-none-results-text="No se encontraron usuarios que coincidan con '{0}'">
                    </select>
                </div>
                <small class="form-text text-muted text-left"><i class="fa-regular fa-question-circle text-barymont-red pr-2"></i>Al añadir un usuario, se añadirá visibilidad al usuario seleccionado y toda su estructura de responsables.</small>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-barymont-red" id="confirm-btn-add-visibility">Añadir</button>
                  <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
              </div>
          </div>
      </div>
    </div>`;

  fragment.appendChild(temporalDiv.firstElementChild);

  document.body.appendChild(fragment);

  const $modalElement = $(`#${randomModalId}`).modal("show");

  $("#" + serviceVisibilityUsersSelectorId).selectpicker("refresh");

  const searchInput = $(`#${serviceVisibilityUsersSelectorId}`).parent().find(".bs-searchbox input");
  searchInput.on("input", async function (event) {
    if (event.target.value.length < 3) {
      $("#" + serviceVisibilityUsersSelectorId).empty();
      $("#" + serviceVisibilityUsersSelectorId).selectpicker("refresh");
      return;
    }

    await paintUsersVisibilitySelector(event.target.value, serviceVisibilityUsersSelectorId);
  });

  document.getElementById("confirm-btn-add-visibility").addEventListener("click", async function () {
    await addServiceVisibility(serviceId, document.getElementById(serviceVisibilityUsersSelectorId).value, randomModalId);

    document.getElementById("service-visibility-table-body").innerHTML = await generateServiceVisibilityTableHtml(serviceId, AppGbSession.checkUserHasPermission("Services:ServiceVisibilityManageAccessChecker"));

    await generateRemoveServiceVisibilityEventListeners(serviceId, serviceTypeId);

    $(`[data-toggle="tooltip"]`).tooltip();
  });

  updateButtonLabel("add-visibility", "<i class='fa-solid fa-md fa-eye mr-1'></i>Añadir visibilidad", "btn-barymont-red");

  $modalElement.on("hidden.bs.modal", function () {
    $modalElement.remove();
  });
}

async function createServiceVisibilityModal(serviceId, serviceTypeId) {
  const randomModalId = `modal-${Math.random().toString(36).substring(2, 10)}`;
  const fragment = document.createDocumentFragment();

  const serviceVisibilityManagePermission = AppGbSession.checkUserHasPermission("Services:ServiceVisibilityManageAccessChecker");

  let serviceVisibilityHtml = await generateServiceVisibilityTableHtml(serviceId, serviceVisibilityManagePermission);

  const temporalDiv = document.createElement("div");
  temporalDiv.innerHTML = `
    <div class="modal fade" id="${randomModalId}" tabindex="-1" role="dialog">
      <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">Usuarios con Visibilidad</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body">
                <div class="table-responsive">
                  <table class="table table-bordered table-striped table-condensed table-hover text-center align-middle nowrap table-sm">
                    <thead>
                      <tr>
                        <th class="bg-barymont-grey text-dark text-nowrap border border-barymont-dark-grey px-2">Usuario</th>
                        <th class="bg-barymont-grey text-dark text-nowrap border border-barymont-dark-grey px-2">Perfil</th>
                        ${serviceVisibilityManagePermission ? `<th class="bg-barymont-grey text-dark text-nowrap border border-barymont-dark-grey px-1" style="width:50px;">Acciones</th>` : ""}
                      </tr>
                    </thead>
                    <tbody id="service-visibility-table-body">
                      ${serviceVisibilityHtml}
                    </tbody>
                  </table>

                  <small>
                    <i class="fa-regular fa-question-circle text-barymont-red pr-2"></i> La visibilidad se añadirá automáticamente para la <strong>estructura de responsables</strong> correspondiente a los usuarios que se les proporcione visibilidad.
                  </small>
                </div>
              </div>
              <div class="modal-footer">
                  ${serviceVisibilityManagePermission ? `<button type="button" class="btn btn-barymont-red" id="add-visibility"><i class="fa-solid fa-md fa-eye mr-1"></i>Añadir visibilidad</button>` : ""}
                  <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
              </div>
          </div>
      </div>
    </div>`;

  fragment.appendChild(temporalDiv.firstElementChild);

  document.body.appendChild(fragment);

  const $modalElement = $(`#${randomModalId}`).modal("show");

  if (serviceVisibilityManagePermission) {
    document.getElementById("add-visibility").addEventListener("click", async function () {
      await createAddServiceVisibilityModal(serviceId, serviceTypeId);
    });
    await generateRemoveServiceVisibilityEventListeners(serviceId, serviceTypeId);
  }

  $(`#${randomModalId} [data-toggle="tooltip"]`).tooltip();

  $modalElement.on("hidden.bs.modal", function () {
    $modalElement.remove();
  });
}

async function generateRemoveServiceVisibilityEventListeners(serviceId, serviceTypeId) {
  document.querySelectorAll("[data-action='delete-visibility']").forEach((button) => {
    let cloneButton = button.cloneNode(true);
    button.parentNode.replaceChild(cloneButton, button);

    cloneButton.addEventListener("click", async function () {
      let userId = cloneButton.getAttribute("data-user-id");
      await deleteServiceVisibilityForUser(cloneButton.getAttribute("data-user-id"), cloneButton.getAttribute("data-service-id"));

      const hasManagePermission = await checkPermissionByServiceType(serviceTypeId, {
        financialEducationServiceAccessChecker: "FinancialEducationService:FinancialEducationServiceReadAccessChecker",
        spadServiceAccessChecker: "SpadService:SpadServiceManageAccessChecker",
        pymeServiceAccessChecker: "PymeService:PymeServiceManageAccessChecker",
        cpServiceAccessChecker: "CpService:CpServiceManageAccessChecker",
        financialPlanningServiceAccessChecker: "FinancialPlanningService:FinancialPlanningServiceManageAccessChecker",
      });

      if (userId === AppGbSession.getSessionData().userId && !hasManagePermission) {
        window.location.href = document.getElementById("service-breadcrumb").getAttribute("href");
      }

      document.getElementById("service-visibility-table-body").innerHTML = await generateServiceVisibilityTableHtml(serviceId, AppGbSession.checkUserHasPermission("Services:ServiceVisibilityManageAccessChecker"));
      $(`[data-toggle="tooltip"]`).tooltip();

      generateRemoveServiceVisibilityEventListeners(serviceId, serviceTypeId);
    });
  });
}

async function generateServiceVisibilityTableHtml(serviceId, serviceVisibilityPermission) {
  const NOT_SHOW_IN_LIST_USER_IDS = [
    "b4bb80fc-bbf1-4a02-ad35-426db7a24835", // Bárymont
    "6e9aa046-4751-4aa6-ba46-890769535a01", // Ana Barros
  ];

  let serviceVisibilityUsers = [];
  try {
    serviceVisibilityUsers = await readServiceVisibility(serviceId);
  } catch (error) {
    createToast("error", "Error", error.message, 5000);
  }

  serviceVisibilityUsers.sort((a, b) => {
    return a.userName.localeCompare(b.userName);
  });

  const order = ["Gestor", "Promotor Senior", "Promotor"];

  serviceVisibilityUsers.sort((a, b) => {
    let indexA = order.indexOf(a.userProfile.label);
    let indexB = order.indexOf(b.userProfile.label);

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;

    let labelComparison = a.userProfile.label.localeCompare(b.userProfile.label);
    if (labelComparison !== 0) return labelComparison;

    return a.userName.localeCompare(b.userName);
  });

  let serviceVisibilityHtml = "";
  let deleteButton = "";

  serviceVisibilityHtml = serviceVisibilityUsers
    .map((serviceVisibilityUser) => {
      if (NOT_SHOW_IN_LIST_USER_IDS.includes(serviceVisibilityUser.userId)) {
        return "";
      }

      if (serviceVisibilityUser.isEditable === true) {
        deleteButton = `<button class="btn btn-barymont-black btn-sm p-2 w-100" data-action="delete-visibility" data-user-id="${serviceVisibilityUser.userId}" data-service-id="${serviceId}" data-toggle="tooltip" data-placement="top" title="Eliminar visibilidad">
              <i class="fa-solid fa-md fa-trash"></i>
            </button>`;
      } else {
        deleteButton = `<button class="btn btn-barymont-grey btn-sm p-2 w-100" data-toggle="tooltip" data-placement="top" title="No puedes eliminar la visibilidad de este usuario" disabled>
              <i class="fa-solid fa-md fa-ban"></i>
            </button>`;
      }

      return `
        <tr>
          <td class="align-middle text-nowrap">${serviceVisibilityUser.userName}</td>
          <td class="align-middle text-nowrap"><span class="badge w-100 p-2 bg-${serviceVisibilityUser.userProfile.backgroundColor} text-${serviceVisibilityUser.userProfile.textColor}">${serviceVisibilityUser.userProfile.label}</span></td>
          ${
            serviceVisibilityPermission
              ? `<td>
                ${deleteButton}
          </td>`
              : ""
          }
        </tr>
      `;
    })
    .join("");

  if (serviceVisibilityHtml === "") {
    serviceVisibilityHtml = `<tr><td colspan='${serviceVisibilityPermission ? "3" : "2"}'>Ningún usuario tiene visibilidad de este servicio.</td></tr>`;
  }

  return serviceVisibilityHtml;
}

function addServiceVisibilityEventListener() {
  document.body.addEventListener("click", async function (event) {
    if (event.target.getAttribute("showServiceVisibilityModal") !== null) {
      event.target.classList.remove("fa-eye");
      event.target.innerHTML = '<span class="spinner-border spinner-border-sm m-1" role="status" aria-hidden="true" style="pointer-events: none;"></span>';

      const serviceId = event.target.dataset.serviceId;
      const serviceTypeId = event.target.dataset.serviceTypeId;
      await createServiceVisibilityModal(serviceId, serviceTypeId);

      event.target.classList.add("fa-eye");
      event.target.innerHTML = "";
    }
  });
}

export { addServiceVisibilityEventListener };
