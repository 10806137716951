"use strict";

import { COLORS, MONTH_NAMES, renderMaximizeChartButton, renderExportToCsvButton } from "./business_analytics_shared.js";

const appendixYearFromInputId = "-year-from-select";
const appendixDataTypeInputId = "-data-type-select";
const appendixServiceTypeInputId = "-service-type-select";
const appendixYearToInputId = "-year-to-select";

const filtersAnnualComparasionOfSales = "filters-annual-comparasion-of-sales";
const filtersMonthlySales = "filters-monthly-sales";
const filtersMonthlySalesByServiceType = "filters-monthly-sales-by-service-type";
const filtersMonthlyQuantity = "filters-monthly-quantity";

const validContainersIds = [filtersAnnualComparasionOfSales, filtersMonthlySales, filtersMonthlySalesByServiceType, filtersMonthlyQuantity];

async function fetchFiltersData() {
  let requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  };

  return await fetch("/business-analytics/services/ajax/getFiltersData", requestOptions).then((response) => response.json());
}

async function fetchAnnualComparisonOfSalesOfServices(yearFrom, yearTo, services, dataType) {
  let formData = new FormData();
  formData.append("yearFrom", yearFrom);
  formData.append("yearTo", yearTo);
  formData.append("servicesTypes", JSON.stringify(services));
  formData.append("dataType", dataType);

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    body: formData,
  };

  return await fetch("/business-analytics/services/ajax/getAnnualComparisonOfSalesOfServices", requestOptions).then((response) => response.json());
}

async function fetchMonthlySalesOfServices(yearFrom, yearTo) {
  let formData = new FormData();
  formData.append("yearFrom", yearFrom);
  formData.append("yearTo", yearTo);

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    body: formData,
  };

  return await fetch("/business-analytics/services/ajax/getTotalMonthlySalesOfServices", requestOptions).then((response) => response.json());
}

async function fetchMonthlySalesByServiceType(yearFrom, yearTo, services) {
  let formData = new FormData();
  formData.append("yearFrom", yearFrom);
  formData.append("yearTo", yearTo);
  formData.append("servicesTypes", JSON.stringify(services));

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    body: formData,
  };

  return await fetch("/business-analytics/services/ajax/getMonthlySalesByServices", requestOptions).then((response) => response.json());
}

async function fetchMonthlyQuantityOfServices(yearFrom, yearTo, services) {
  let formData = new FormData();
  formData.append("yearFrom", yearFrom);
  formData.append("yearTo", yearTo);
  formData.append("servicesTypes", JSON.stringify(services));

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    body: formData,
  };

  return await fetch("/business-analytics/services/ajax/getMonthlyQuantityOfServices", requestOptions).then((response) => response.json());
}

function createDateSelectInput(values, selectedValues, inputId, containerId) {
  const div = document.createElement("div");
  div.setAttribute("class", "col-sm-12 col-md-2 px-3 mb-2");
  div.style.width = "100%";

  const select = document.createElement("select");
  select.setAttribute("id", inputId);
  select.setAttribute("name", inputId);
  select.setAttribute("class", "form-control selectpicker custom-select");

  values.forEach((value) => {
    const option = document.createElement("option");
    option.value = value;
    option.text = value;

    if (selectedValues.includes(value)) {
      option.selected = true;
    }

    select.appendChild(option);
  });

  select.addEventListener("change", () => {
    dispachCustomEventToUpdateDashboardGraphics(containerId);
  });

  div.appendChild(select);

  return div;
}

function createServiceSelectInput(values, inputId, containerId) {
  const div = document.createElement("div");
  div.setAttribute("class", "col-sm-12 col-md-6 mb-2");

  const select = document.createElement("select");
  select.setAttribute("id", inputId);
  select.setAttribute("name", inputId);
  select.setAttribute("multiple", "multiple");
  select.setAttribute("class", "form-control selectpicker custom-select ");
  select.setAttribute("title", "Selecciona un tipo de servicio ...");
  select.setAttribute("data-live-search", "true");

  values.forEach((service) => {
    const option = document.createElement("option");

    option.value = service.value;
    option.text = service.label;
    option.selected = true;

    select.appendChild(option);
  });

  select.addEventListener("change", () => {
    dispachCustomEventToUpdateDashboardGraphics(containerId);
  });

  div.appendChild(select);

  return div;
}

function createDataTypeSelectInput(values, inputId, containerId) {
  const div = document.createElement("div");
  div.setAttribute("class", "col-sm-12 col-md-2 mb-2");

  const select = document.createElement("select");
  select.setAttribute("id", inputId);
  select.setAttribute("name", inputId);
  select.setAttribute("class", "form-control selectpicker custom-select ");

  values.forEach((dataType) => {
    const option = document.createElement("option");

    option.value = dataType.value;
    option.text = dataType.label;

    select.appendChild(option);
  });

  select.selectedIndex = 0;

  select.addEventListener("change", () => {
    dispachCustomEventToUpdateDashboardGraphics(containerId);
  });

  div.appendChild(select);

  return div;
}

async function printFilters(containerName) {
  const filtersContainer = document.querySelectorAll(`[name="${containerName}"]`);

  const filtersData = await fetchFiltersData();

  filtersContainer.forEach((container) => {
    const yearFromInputId = container.id + appendixYearFromInputId;
    const dataTypeInputId = container.id + appendixDataTypeInputId;
    const serviceTypeInputId = container.id + appendixServiceTypeInputId;
    const yearToInputId = container.id + appendixYearToInputId;

    const yearFromSelectElement = createDateSelectInput(filtersData.data.years, [filtersData.data.yearsSelected[0]], yearFromInputId, container.id);

    let serviceDataTypeSelectElement;
    if (document.getElementById(container.id).getAttribute("data-type") === "true") {
      serviceDataTypeSelectElement = createDataTypeSelectInput(filtersData.data.dataTypes, dataTypeInputId, container.id);
    } else {
      serviceDataTypeSelectElement = document.createElement("div");
    }

    let serviceTypeSelectElement;
    if (document.getElementById(container.id).getAttribute("data-service-type") !== "false") {
      serviceTypeSelectElement = createServiceSelectInput(filtersData.data.servicesTypes, serviceTypeInputId, container.id);
    } else {
      serviceTypeSelectElement = document.createElement("div");
    }

    const yearToSelectElement = createDateSelectInput(filtersData.data.years, [filtersData.data.yearsSelected[1]], yearToInputId, container.id);

    container.appendChild(yearFromSelectElement);
    container.appendChild(serviceDataTypeSelectElement);
    container.appendChild(serviceTypeSelectElement);
    container.appendChild(yearToSelectElement);

    $(".selectpicker").selectpicker();
  });
}

function dispachCustomEventToUpdateDashboardGraphics(containerId) {
  const yearFromSelectElement = document.getElementById(containerId + appendixYearFromInputId).value;
  const yearToSelectElement = document.getElementById(containerId + appendixYearToInputId).value;

  const dataTypeSelectElement = document.getElementById(containerId + appendixDataTypeInputId)?.value;

  let selectedServices = null;
  if (document.getElementById(containerId + appendixServiceTypeInputId)) {
    selectedServices = Array.from(document.getElementById(containerId + appendixServiceTypeInputId).selectedOptions).map((option) => option.value);
  }

  updateGrapichs(containerId, yearFromSelectElement, yearToSelectElement, selectedServices, dataTypeSelectElement);
}

function createLoadingSpinner() {
  const div = document.createElement("div");
  div.setAttribute("class", "d-flex flex-column justify-content-center align-items-center");
  div.style.width = "100%";
  div.style.height = "100%";

  const spinner = document.createElement("div");
  spinner.setAttribute("class", "spinner-border text-dark");
  spinner.setAttribute("role", "status");

  const span = document.createElement("span");
  span.setAttribute("class", "sr-only");
  span.innerHTML = "Loading...";

  spinner.appendChild(span);
  div.appendChild(spinner);

  const text = document.createElement("p");
  text.innerHTML = "Cargando gráfico...";
  text.setAttribute("class", "mt-2");

  div.appendChild(text);

  return div;
}

function showLoadingContainer(containerId) {
  const graphtcDivId = containerId + "-chart-container";

  if (document.getElementById(graphtcDivId)) {
    document.getElementById(graphtcDivId).innerHTML = "";
    document.getElementById(graphtcDivId).appendChild(createLoadingSpinner());
  }
}

async function updateGrapichs(containerId, yearFrom, yearTo, services, dataType) {
  if (!validContainersIds.includes(containerId)) {
    createToast("warning", "Error al actualizar el gráfico", "El contenedor seleccionado no es válido.", 5000);
    return;
  }

  if (services !== null && services.length == 0) {
    createToast("warning", "Error al actualizar el gráfico", "Selecciona al menos un servicio para actualizar los datos del gráfico.", 5000);
    return;
  }

  if (yearFrom > yearTo) {
    createToast("warning", "Error al actualizar el gráfico", "El año de inicio no puede ser mayor al año final.", 5000);
    return;
  }

  let functionToUpdateGraphics = null;
  let functionPrintGraphics = null;
  let csvFileName;
  let chartTitle;
  let dataTypeEuro = false;

  switch (containerId) {
    case filtersAnnualComparasionOfSales:
      functionToUpdateGraphics = fetchAnnualComparisonOfSalesOfServices;
      functionPrintGraphics = printBarGraphic;
      chartTitle = "Comparación anual de ventas de servicios";
      csvFileName = "business_analytics_services_annual_comparison_of_sales";
      dataTypeEuro = dataType === "INCOME";
      break;
    case filtersMonthlySales:
      functionToUpdateGraphics = fetchMonthlySalesOfServices;
      functionPrintGraphics = printLineGraphic;
      chartTitle = "Comparativa mensual de ingresos por servicios";
      csvFileName = "business_analytics_services_monthly_sales";
      dataTypeEuro = true;
      break;
    case filtersMonthlySalesByServiceType:
      functionToUpdateGraphics = fetchMonthlySalesByServiceType;
      functionPrintGraphics = printLineGraphic;
      chartTitle = "Ingresos mensuales por tipo de servicio";
      csvFileName = "business_analytics_services_monthly_sales_by_service_type";
      dataTypeEuro = true;
      break;
    case filtersMonthlyQuantity:
      functionToUpdateGraphics = fetchMonthlyQuantityOfServices;
      functionPrintGraphics = printLineGraphic;
      chartTitle = "Unidades mensuales por tipo de servicio";
      csvFileName = "business_analytics_services_monthly_quantity";
      break;
  }

  functionToUpdateGraphics(yearFrom, yearTo, services, dataType).then((data) => {
    functionPrintGraphics(containerId, data, dataTypeEuro, chartTitle, csvFileName);
  });
}

function createDatasetsForChartJsFromAnnualServiceData(data) {
  if (data === null || data === undefined) {
    return { labels: [], datasets: [] };
  }

  const years = [];
  const labels = [];

  for (const category of Object.values(data)) {
    for (const subcatData of Object.values(category)) {
      subcatData.years.forEach((year) => {
        if (!years.includes(year)) {
          years.push(year);
        }
      });
      if (!labels.includes(subcatData.label)) {
        labels.push(subcatData.label);
      }
    }
  }

  const datasets = Array.from(years).map((year, index) => {
    const { backgroundColor, borderColor } = COLORS[index % COLORS.length];
    const yearData = {
      label: year,
      backgroundColor,
      borderColor,
      borderWidth: 1,
      data: [],
    };

    for (const category of Object.values(data)) {
      for (const subcatData of Object.values(category)) {
        const entry = subcatData.data.find((item) => item.date.startsWith(year));
        const quantity = entry ? parseFloat(entry.quantity.toString().replace(/\./g, "").replace(",", ".")) : 0;
        yearData.data.push(quantity);
      }
    }

    return yearData;
  });

  return {
    labels,
    datasets,
  };
}

function printBarGraphic(containerId, data, dataTypeEuro, chartTitle, csvFileName) {
  showLoadingContainer(containerId);

  const mainContainer = document.getElementById(containerId + "-chart-container");
  mainContainer.innerHTML = "";

  const graphtcDiv = document.createElement("div");
  graphtcDiv.id = containerId + "-chart";
  graphtcDiv.setAttribute("class", "col-12");

  const canvas = document.createElement("canvas");
  canvas.id = containerId + "-chart";
  graphtcDiv.appendChild(canvas);
  mainContainer.appendChild(graphtcDiv);

  const ctx = canvas.getContext("2d");

  const chartJsData = createDatasetsForChartJsFromAnnualServiceData(data.data);

  const chart = new Chart(ctx, {
    type: "bar",
    data: chartJsData,
    options: {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          display: true,
          position: "top",
        },
        title: {
          display: true,
          text: chartTitle,
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || "";
              if (label) {
                label += ": ";
              }
              if (context.parsed.y !== null) {
                label += context.parsed.y;
                if (dataTypeEuro) {
                  label += "€";
                }
              }
              return label;
            },
          },
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: dataTypeEuro === true ? "Ingresos" : "Unidades",
          },
        },
      },
    },
  });

  renderMaximizeChartButton(mainContainer, chart);
  renderExportToCsvButton(mainContainer, chartJsData, csvFileName);
}

function createDatasetsForChartJsFromMonthlyServiceData(data) {
  if (data === null || data === undefined) {
    return { labels: [], datasets: [] };
  }

  const datasets = [];
  const serviceYears = {};

  Object.entries(data).forEach(([serviceKey, serviceValue]) => {
    const { label } = serviceValue;
    serviceValue.data.forEach((item) => {
      const date = new Date(item.date);
      const year = date.getFullYear();
      const month = date.getMonth();
      const quantity = parseFloat(item.quantity.toString().replace(/\./g, "").replace(",", "."));
      const type = item.type || serviceKey;

      if (!serviceYears[type]) serviceYears[type] = {};
      if (!serviceYears[type][year]) serviceYears[type][year] = { label, data: Array(12).fill(null) };

      serviceYears[type][year].data[month] = quantity;
    });
  });

  let colorIndex = 0;
  Object.entries(serviceYears).forEach(([, years]) => {
    Object.entries(years).forEach(([year, yearData]) => {
      const { backgroundColor, borderColor } = COLORS[colorIndex % COLORS.length];
      const filteredData = yearData.data.map((value, index) => (value !== null ? { month: index, value } : null)).filter((item) => item !== null);
      const dataWithIndexes = Array(12)
        .fill(null)
        .map((_, i) => {
          const found = filteredData.find((item) => item.month === i);
          return found ? found.value : null;
        });

      datasets.push({
        label: `${yearData.label} ${year}`,
        backgroundColor,
        borderColor,
        borderWidth: 1,
        fill: false,
        data: dataWithIndexes,
      });
      colorIndex++;
    });
  });

  return {
    labels: MONTH_NAMES,
    datasets: datasets,
  };
}

function printLineGraphic(containerId, data, dataTypeEuro, chartTitle, csvFileName) {
  showLoadingContainer(containerId);

  const mainContainer = document.getElementById(containerId + "-chart-container");
  mainContainer.innerHTML = "";

  const graphtcDiv = document.createElement("div");
  graphtcDiv.id = containerId + "-chart";
  graphtcDiv.setAttribute("class", "col-12");

  const canvas = document.createElement("canvas");
  canvas.id = containerId + "-chart";

  graphtcDiv.appendChild(canvas);
  mainContainer.appendChild(graphtcDiv);

  const ctx = canvas.getContext("2d");

  const chartJsData = createDatasetsForChartJsFromMonthlyServiceData(data.data);

  const chart = new Chart(ctx, {
    type: "line",
    data: chartJsData,
    options: {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          display: true,
          position: "top",
        },
        title: {
          display: true,
          text: chartTitle,
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || "";
              if (label) {
                label += ": ";
              }
              if (context.parsed.y !== null) {
                label += context.parsed.y;
                if (dataTypeEuro) {
                  label += "€";
                }
              }
              return label;
            },
          },
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: dataTypeEuro === true ? "Ingresos" : "Unidades",
          },
        },
      },
    },
  });

  renderMaximizeChartButton(mainContainer, chart);
  renderExportToCsvButton(mainContainer, chartJsData, csvFileName);
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname === "/business-analytics/services") {
      const filtersContainerName = "filters-container";
      printFilters(filtersContainerName).then(() => {
        validContainersIds.forEach((containerId) => {
          dispachCustomEventToUpdateDashboardGraphics(containerId);
        });
      });
    }
  });
}

export {};
