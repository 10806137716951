"use strict";

export async function readCurrentUserNotifications(includeNotified = false) {
  let includeNotifiedParam = includeNotified ? "?includeNotified=true" : "";

  const response = await fetch(`/api/current-user/notifications${includeNotifiedParam}`, { method: "GET" });
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function updateNotificationState(notificationId, state) {
  let requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ isNotified: state }),
  };

  const response = await fetch(`/api/current-user/notifications/${notificationId}`, requestOptions);

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function createNewPromoterRequest(contractType, name, firstName, secondName, nif, email, phone, corporateEmailSuggestion, refererName, refererNif, responsibleName, responsibleNif, nifFile, cifFile, deedFile) {
  const formData = new FormData();

  formData.append("contractType", contractType);
  formData.append("name", name);
  formData.append("firstName", firstName);
  formData.append("secondName", secondName);
  formData.append("nif", nif);
  formData.append("email", email);
  formData.append("phone", phone);
  formData.append("corporateEmailSuggestion", corporateEmailSuggestion);
  formData.append("refererName", refererName);
  formData.append("refererNif", refererNif);
  formData.append("responsibleName", responsibleName);
  formData.append("responsibleNif", responsibleNif);

  if (nifFile) {
    formData.append("nifFile", nifFile);
  }
  if (cifFile) {
    formData.append("cifFile", cifFile);
  }
  if (deedFile) {
    formData.append("deedFile", deedFile);
  }

  let requestOptions = {
    method: "POST",
    body: formData,
  };

  const response = await fetch("/api/current-user/new-promoter/request", requestOptions);
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function searchCurrentUserStructure(mode) {
  const validModes = ["Full", "Subordinates", "Responsibles"];

  if (!validModes.includes(mode)) {
    throw new Error("Invalid mode, must be one of" + validModes.join(", "));
  }

  const response = await fetch(`/api/current-user/structure?mode=${mode}`);
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}
