"use strict";

import { showLoadingButton, updateButtonLabel, isValidUuid } from "../shared/shared.js";
import { downloadInscriptionTicket } from "./corporate_event_inscription.js";

var FILTER_STATE_LOCAL_STORAGE_KEY = "CORPORATE_EVENT_INSCRIPTIONS_MANAGEMENT_FILTER_STATE_";
var FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME = 1000 * 60 * 20;

const datatableInscriptionId = "corporate-event-inscriptions-management-table";
const modalInscriptionData = "modal-corporate-event-inscription-data";
const btnDownloadInscription = "download-inscription";
const btnSendInscription = "send-inscription";
const btnRemoveInscription = "remove-inscription";
const btnSaveInscription = "btn-save-inscription";
const modalInscriptionUrl = "modal-generate-inscription-url";
const modalConfirmRegisterAccess = "modal-confirm-register-access";

function getStateOfCustomFilters() {
  return {
    startDate: $("#corporate-event-inscriptions-management-start-date").val(),
    finishDate: $("#corporate-event-inscriptions-management-finish-date").val(),
    phaseType: $("#corporate-event-inscriptions-management-phase-type").val(),
    level: $("#corporate-event-inscriptions-management-level").val(),
  };
}

function recreateStateOfCustomFilters(localStorageData) {
  if (localStorageData.customFilter === undefined) {
    return false;
  }

  $("#corporate-event-inscriptions-management-start-date").val(localStorageData.customFilter.startDate);
  $("#corporate-event-inscriptions-management-finish-date").val(localStorageData.customFilter.finishDate);
  $("#corporate-event-inscriptions-management-phase-type").val(localStorageData.customFilter.phaseType);
  $("#corporate-event-inscriptions-management-level").val(localStorageData.customFilter.level);
}

function resetearFiltros() {
  $("#corporate-event-inscriptions-management-start-date").val("");
  $("#corporate-event-inscriptions-management-finish-date").val("");
  $("#corporate-event-inscriptions-management-phase-type").val("");
  $("#corporate-event-inscriptions-management-level").val("");

  $("#corporate-event-inscriptions-management-phase-type").selectpicker("refresh");
  $("#corporate-event-inscriptions-management-level").selectpicker("refresh");
  $("#" + datatableInscriptionId)
    .DataTable()
    .search("")
    .draw();
  $("#" + datatableInscriptionId)
    .DataTable()
    .ajax.reload();
}

document.querySelectorAll(".triggerDatatableUpdateCorporateEventInscriptionsManagement").forEach((item) => {
  item.addEventListener("change", function () {
    $("#" + datatableInscriptionId)
      .DataTable()
      .ajax.reload();
  });
});

async function loadInscriptionData(inscriptionId) {
  const responseInscription = await fetchInscriptionData(inscriptionId);

  if (responseInscription.success === false) {
    createToast("error", "Error ❌", "Ocurrió un error al obtener la información de la inscripción, intentelo de nuevo más tarde.");
    return;
  }

  const responseAccess = await fetchAccessInscriptionData(inscriptionId);

  if (responseAccess.success === false) {
    createToast("error", "Error ❌", "Ocurrió un error al obtener la información de accesos de la inscripción, intentelo de nuevo más tarde.");
    return;
  }

  const responseInscriptionLevels = await fetchInscriptionLevels();

  if (responseInscriptionLevels.success === false) {
    createToast("error", "Error ❌", "Ocurrió un error al obtener la información de los niveles de inscripción, intentelo de nuevo más tarde.");
    return;
  }

  let accompanyingData = [];
  if (responseInscription.corporateEventInscription.showAccompanyingData === true) {
    const responseAccompanying = await fetchAccompanyingInscriptionData(inscriptionId);
    accompanyingData = responseAccompanying.accompanyingData !== undefined ? responseAccompanying.accompanyingData : [];
  }

  if (responseInscription.corporateEventInscription.isAccompanying === true) {
    let corporateEventId = document.getElementById("corporate-event-id").value;
    const responseAccompanyingOfUser = await fetchInscriptionDataByInscriptionCode(responseInscription.corporateEventInscription.inscriptionCode, corporateEventId);
    if (responseAccompanyingOfUser.corporateEventInscription !== null) {
      accompanyingData.push(responseAccompanyingOfUser.corporateEventInscription);
    }
  }

  printInscriptionData(responseInscription, responseAccess.corporateEventAccessCollection, accompanyingData, responseInscription.corporateEventInscription.isAccompanying, responseInscriptionLevels.levels);

  $("#" + modalInscriptionData).modal("show");
}

function printInscriptionData(responseInscriptionData, accessData, accompanyingData, isAccompanying, inscriptionLevels) {
  let divUserInscriptionData = document.getElementById(modalInscriptionData + "-user-div");

  const inscriptionData = responseInscriptionData.corporateEventInscription;

  let readonly = AppGbSession.checkUserHasPermission("CorporateEvent:CorporateEventInscriptionUpdateAccessChecker") ? "" : "readonly";

  if (readonly !== "") {
    document.getElementById(btnSaveInscription).classList.add("d-none");
  }

  divUserInscriptionData.innerHTML = "";

  divUserInscriptionData.insertAdjacentHTML(
    "beforeend",
    ` <div class="container-fluid">
        <div class="row">
        <div class="col-4">
            <div class="input-group mb-2 mr-sm-2">
              <div class="input-group-prepend">
                <div class="input-group-text bg-light" data-toggle="tooltip" title="Nombre">Nombre</div>
              </div>
            <input id="inscription-user-name" type="text" class="form-control" value="${inscriptionData.name}" ${readonly}>
          </div>
        </div>
        <div class="col-4">
            <div class="input-group mb-2 mr-sm-2">
              <div class="input-group-prepend">
                <div class="input-group-text bg-light" data-toggle="tooltip" title="Primer Apellido">Primer Apellido</div>
              </div>
            <input id="inscription-user-first-surname" type="text" class="form-control" value="${inscriptionData.firstSurname}" ${readonly}>
          </div>
        </div>
        <div class="col-4">
            <div class="input-group mb-2 mr-sm-2">
              <div class="input-group-prepend">
                <div class="input-group-text bg-light" data-toggle="tooltip" title="Segundo Apellido">Segundo Apellido</div>
              </div>
            <input id="inscription-user-second-surname" type="text" class="form-control" value="${inscriptionData.secondSurname}" ${readonly}>
          </div>
        </div>
        <div class="col-6">
            <div class="input-group mb-2 mr-sm-2">
              <div class="input-group-prepend">
                <div class="input-group-text bg-light" data-toggle="tooltip" title="Email">Email</div>
              </div>
            <input id="inscription-user-email" type="text" class="form-control" value="${inscriptionData.email}" ${readonly}>
          </div>
        </div>
        <div class="col-6">
            <div class="input-group mb-2 mr-sm-2">
              <div class="input-group-prepend">
                <div class="input-group-text bg-light" data-toggle="tooltip" title="Teléfono">Teléfono</div>
              </div>
            <input id="inscription-user-phone" type="text" class="form-control" value="${inscriptionData.phone}" ${readonly}>
          </div>
        </div>`
  );

  let divInscriptionData = document.getElementById(modalInscriptionData + "-div");

  const accessCodeImage = responseInscriptionData.accessCodeImage;

  let downloadBtnHtml = AppGbSession.checkUserHasPermission("CorporateEvent:CorporateEventInscriptionDownloadAccessChecker") ? `<div class="col-4"><button id="download-inscription" type="button" class="btn btn-barymont-black btn-block" data-toggle="tooltip" title="Descargar Ticket"><i class="fas fa-download mr-2"></i> Descargar</button></div>` : "";
  let sendBtnHtml = AppGbSession.checkUserHasPermission("CorporateEvent:CorporateEventInscriptionSendAccessChecker") ? `<div class="col-4"><button id="send-inscription" type="button" class="btn btn-barymont-red btn-block" data-toggle="tooltip" title="Enviar Email"><i class="fa-solid fa-paper-plane mr-2"></i> Enviar Email</button></div>` : "";
  let deleteBtnHtml = AppGbSession.checkUserHasPermission("CorporateEvent:CorporateEventInscriptionDeleteAccessChecker") ? `<div class="col-4"><button id="remove-inscription" type="button" class="btn btn-barymont-black btn-block" data-toggle="tooltip" title="Eliminar Inscripción"><i class="fas fa-trash mr-2"></i> Eliminar</button></div>` : "";

  divInscriptionData.innerHTML = "";

  divInscriptionData.insertAdjacentHTML(
    "beforeend",
    ` <div class="container-fluid">
        <div class="row">
        <div class="col-6">
          <div class="row">
            <div class="col-12">
                <div class="input-group mb-2 mr-sm-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text bg-light" data-toggle="tooltip" title="Código de Accesso">Código Acceso</div>
                  </div>
                <input type="text" class="form-control" value="${inscriptionData.accessCode}" readonly>
              </div>
            </div>
            <div class="col-12">
                <div class="input-group mb-2 mr-sm-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text bg-light" data-toggle="tooltip" title="Código Inscripción">Código Inscripción</div>
                  </div>
                <input id="inscription-code" type="text" class="form-control" value="${inscriptionData.inscriptionCode}" readonly>
              </div>
            </div>
            <div class="col-12">
                <div class="input-group mb-2 mr-sm-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text bg-light" data-toggle="tooltip" title="Código Referido">Código Referido</div>
                  </div>
                <input type="text" class="form-control" value="${inscriptionData.referencedCode}" readonly>
              </div>
            </div>
            <div class="col-12">
                <div class="input-group mb-2 mr-sm-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text bg-light" data-toggle="tooltip" title="Tipo Fase">Tipo Fase</div>
                  </div>
                <input type="text" class="form-control" value="${inscriptionData.corporateEventPhaseLabel}" readonly>
              </div>
            </div>
            <div class="col-12">
              <div class="input-group mb-2 mr-sm-2">
                <div class="input-group-prepend">
                  <div class="input-group-text bg-light" data-toggle="tooltip" title="Nivel">Nivel</div>
                </div>
                <select id="inscription-level" class="form-control selectpicker">
                  <option value="" ${inscriptionData.corporateEventInscriptionLevel === null ? "selected" : ""}>Seleccione un nivel</option>
                  ${inscriptionLevels.map((item) => `<option value="${item.value}" data-content="<span class='badge w-100 py-2' style='background-color: ${item.backgroundColor}; color: ${item.color};'>${item.label}</span>" ${item.value === inscriptionData.corporateEventInscriptionLevel ? "selected" : ""}>${item.label}</option>`)}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-12">
              <img class="w-25" src="${accessCodeImage}"/>
              <p>${inscriptionData.accessCode}</p>
            </div>
            ${downloadBtnHtml}
            ${sendBtnHtml}
            ${deleteBtnHtml}
          </div>
        </div>`
  );

  let divAccompanyingSection = document.getElementById(modalInscriptionData + "-accompanying-section");
  divAccompanyingSection.classList.add("d-none");

  let divAccompanyingData = document.getElementById(modalInscriptionData + "-accompanying-div");

  if (isAccompanying) {
    document.getElementById(modalInscriptionData + "-accompanying-title").innerHTML = "Invitado por:";
  } else {
    document.getElementById(modalInscriptionData + "-accompanying-title").innerHTML = "Acompañantes:";
  }

  if (accompanyingData.length > 0) {
    divAccompanyingData.innerHTML = "";

    divAccompanyingData.insertAdjacentHTML(
      "beforeend",
      `
      <div class="row bg-barymont-dark-grey">
        <div class="col-3 font-weight-bolder">
          Código de Acceso
        </div>
        <div class="col-3 font-weight-bolder">
          Fase
        </div>
        <div class="col-3 font-weight-bolder">
          Nombre
        </div>
        <div class="col-3 font-weight-bolder">
          Acciones
        </div>
      </div>
      `
    );

    accompanyingData.forEach((item) => {
      divAccompanyingData.insertAdjacentHTML(
        "beforeend",
        `
        <div class="row my-2">
          <div class="col-3">
            <img class="w-25" src="${item.base64AccessCode}"/>
            <p class="mb-0">${item.accessCode}</p>
          </div>
          <div class="col-3">
            ${item.corporateEventPhaseLabel}
          </div>
          <div class="col-3">
            ${item.name + " " + item.firstSurname + " " + item.secondSurname}
          </div>
          <div class="col-3">
            <button type="button" class="btn btn-barymont-red" data-action="open-accompany-inscription" data-inscription-id="${item.corporateEventInscriptionId}" data-toggle="tooltip" title="Ver Inscripción"><i class="fa-solid fa-eye mr-2"></i> Ver</button>
          </div>
        </div>`
      );
    });

    divAccompanyingSection.classList.remove("d-none");
  }

  let divAccessData = document.getElementById(modalInscriptionData + "-access-div");

  divAccessData.innerHTML = "";

  divAccessData.insertAdjacentHTML(
    "beforeend",
    `
      <div class="row bg-barymont-dark-grey">
        <div class="col-2">
          #
        </div>
        <div class="col-4 font-weight-bolder">
          Fecha
        </div>
        <div class="col-6 font-weight-bolder">
          Usuario
        </div>
      </div>
    `
  );

  if (accessData.length === 0) {
    divAccessData.insertAdjacentHTML(
      "beforeend",
      `
      <div class="row">
        <div class="col-12 bg-barymont-grey">
          <p class="text-center">No se han registrado accesos para esta inscripción.</p>
        </div>
      </div>
    `
    );
  }

  accessData.forEach((item, index) => {
    divAccessData.insertAdjacentHTML(
      "beforeend",
      `
      <div class="row">
        <div class="col-2">
          ${accessData.length + 1 - (index + 1)}
        </div>
        <div class="col-4">
          ${item.createdAt}
        </div>
        <div class="col-6">
          ${item.userName}
        </div>
      </div>
    `
    );
  });

  function _removeAllEventListeners(element) {
    const newElement = element.cloneNode(true);
    element.replaceWith(newElement);
    return newElement;
  }

  let downloadBtn = document.getElementById(btnDownloadInscription);

  if (downloadBtn) {
    downloadBtn = _removeAllEventListeners(downloadBtn);
    downloadBtn.addEventListener("click", async function () {
      showLoadingButton(this.id);
      await downloadInscriptionTicket(inscriptionData.corporateEventInscriptionId);
      updateButtonLabel(this.id, "<i class='fas fa-download mr-2'></i> Descargar", "btn-barymont-black");
    });
  }

  let sendBtn = document.getElementById(btnSendInscription);

  if (sendBtn) {
    sendBtn = _removeAllEventListeners(sendBtn);
    sendBtn.addEventListener("click", async function () {
      showLoadingButton(this.id);
      await sendInscriptionEmail(inscriptionData.corporateEventInscriptionId);
      updateButtonLabel(this.id, "<i class='fa-solid fa-paper-plane mr-2'></i> Enviar Email", "btn-barymont-red");
    });
  }

  let removeBtn = document.getElementById(btnRemoveInscription);

  if (removeBtn) {
    removeBtn = _removeAllEventListeners(removeBtn);
    removeBtn.addEventListener("click", async function () {
      showLoadingButton(this.id);
      await removeInscription(inscriptionData.corporateEventInscriptionId);
      updateButtonLabel(this.id, "<i class='fas fa-trash mr-2'></i> Eliminar", "btn-barymont-black");
    });
  }

  let saveBtn = document.getElementById(btnSaveInscription);

  if (saveBtn) {
    saveBtn = _removeAllEventListeners(saveBtn);
    saveBtn.addEventListener("click", async function () {
      showLoadingButton(this.id);
      await saveInscriptionData(inscriptionData.corporateEventInscriptionId);
      updateButtonLabel(this.id, "<i class='fas fa-floppy-disk mr-2'></i> Guardar", "btn-barymont-red");
    });
  }

  document.querySelectorAll("[data-action='open-accompany-inscription']").forEach((item) => {
    item = _removeAllEventListeners(item);

    item.addEventListener("click", async function () {
      let containerData = document.getElementById(modalInscriptionData + "-body");
      containerData.classList.add("d-none");

      let containerLoader = document.getElementById("load-" + modalInscriptionData);
      containerLoader.classList.remove("d-none");

      await loadInscriptionData(this.getAttribute("data-inscription-id"));

      containerLoader.classList.add("d-none");
      containerData.classList.remove("d-none");
    });
  });

  $("#inscription-level").selectpicker("refresh");
}

async function sendInscriptionEmail(inscriptionId) {
  let formData = new FormData();
  formData.append("inscriptionId", inscriptionId);

  await fetch("/herramientas/corporate-event-inscriptions-management/ajax/sendInscriptionEmail", {
    method: "POST",
    body: formData,
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.success === false) {
        createToast("error", "Error ❌", "Ocurrió un error al enviar el email, intentelo de nuevo más tarde.");
        return;
      }

      createToast("success", "Éxito ✅", "Email enviado correctamente.");
    });
}

async function removeInscription(inscriptionId) {
  let formData = new FormData();
  formData.append("inscriptionId", inscriptionId);

  await fetch("/herramientas/corporate-event-inscriptions-management/ajax/removeInscription", {
    method: "POST",
    body: formData,
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.success === false) {
        createToast("error", "Error ❌", "Ocurrió un error al eliminar la inscripción, intentelo de nuevo más tarde.");
        return;
      }

      createToast("success", "Éxito ✅", "Inscripción eliminada correctamente.");
      $("#" + datatableInscriptionId)
        .DataTable()
        .ajax.reload();
      $("#" + modalInscriptionData).modal("hide");
    });
}

async function saveInscriptionData(inscriptionId) {
  let userName = document.getElementById("inscription-user-name").value;
  let userFirstName = document.getElementById("inscription-user-first-surname").value;
  let userSecondName = document.getElementById("inscription-user-second-surname").value;
  let email = document.getElementById("inscription-user-email").value;
  let phone = document.getElementById("inscription-user-phone").value;
  let level = document.getElementById("inscription-level").value;

  const response = await fetchSaveInscriptionData(inscriptionId, userName, userFirstName, userSecondName, email, phone, level);

  if (response.success === false) {
    createToast("error", "Error ❌", "Ocurrió un error al guardar la información de la inscripción, intentelo de nuevo más tarde.");
    return;
  }

  createToast("success", "Éxito ✅", "Información de la inscripción guardada correctamente.");
  $("#" + datatableInscriptionId)
    .DataTable()
    .ajax.reload();
  $("#" + modalInscriptionData).modal("hide");
}

async function loadInscriptionUrlModal() {
  let corporateEventId = document.getElementById("corporate-event-id").value;

  const responsePhases = await fetchPhasesData(corporateEventId);

  if (responsePhases.success === false) {
    createToast("error", "Error ❌", "Ocurrió un error al obtener la información de las fases, intentelo de nuevo más tarde.");
    return;
  }

  const responseLanguages = await fetchLanguagesData();

  if (responseLanguages.success === false) {
    createToast("error", "Error ❌", "Ocurrió un error al obtener la información de los idiomas, intentelo de nuevo más tarde.");
    return;
  }

  printInscriptionUrlModal(responsePhases.corporateEventPhases, responseLanguages.languages);

  $("#" + modalInscriptionUrl).modal("show");
}

function printInscriptionUrlModal(corporateEventPhases, corporateInscriptionLanguages) {
  let divInscriptionUrl = document.getElementById(modalInscriptionUrl + "-body");

  divInscriptionUrl.innerHTML = "";

  divInscriptionUrl.insertAdjacentHTML(
    "beforeend",
    ` <div class="container-fluid">
        <div class="row d-flex align-items-center">
          <div class="col-4">
            <label class="d-flex justify-content-center align-items-center font-weight-bold text-barymont-red mx-3"><i class="fas fa-list  mr-2"></i> Fase</label>
            <select id="inscription-url-phase" class="form-control selectpicker" data-action="generator-url" data-live-search="true">
              <option value="">Seleccione una fase</option>
              ${corporateEventPhases.map((item) => `<option value="${item.corporateEventPhaseId}">${item.corporateEventPhaseTypeLabel}</option>`)}
            </select>
          </div>
          <div class="col-4">
            <label class="d-flex justify-content-center align-items-center font-weight-bold text-barymont-red mx-3"><i class="fas fa-language  mr-2"></i> Idioma</label>
            <select id="inscription-url-language" class="form-control selectpicker" data-action="generator-url">
              <option value="">Seleccione un idioma</option>
              ${corporateInscriptionLanguages.map((item) => `<option value="${item.value}">${item.label}</option>`)}
            </select>
          </div>
          <div class="col-4">
            <label class="d-flex justify-content-center align-items-center font-weight-bold text-barymont-red mx-3"><i class="fas fa-user-check  mr-2"></i> Acceso</label>
            <div class="text-center w-100">
              <input type="checkbox" id="inscription-super-access" data-action="generator-url" data-toggle="toggle" data-on="Siempre" data-off="Restringido" data-onstyle="success" data-offstyle="secondary" data-width="90%" data-height="32">
            </div>
          </div>
          <div class="col-12 my-4">
            <div class="input-group">
              <input type="url" id="inscription-url" class="form-control" value="" readonly>
              <div class="input-group-append">
                <button id="btn-copy-url" class="btn btn-barymont-red" data-toggle="tooltip" data-original-title="Copiar enlace al portapapeles">Copiar</button>
              </div>
            </div>
          </div>
        </div>
      </div>`
  );

  $("#inscription-url-phase").selectpicker("refresh");
  $("#inscription-url-language").selectpicker("refresh");
  $('[data-toggle="toggle"]').bootstrapToggle();
  $('[data-toggle="tooltip"]').tooltip();

  $("[data-action='generator-url']").change(function () {
    generateInscriptionUrl();
  });

  document.getElementById("btn-copy-url").addEventListener("click", function () {
    let phase = document.getElementById("inscription-url-phase").value;
    let language = document.getElementById("inscription-url-language").value;

    if (phase === "" || language === "") {
      createToast("error", "Error ❌", "Debe seleccionar una fase y un idioma para poder generar una url y copiarla");
      return;
    }

    let url = document.getElementById("inscription-url");
    url.select();
    navigator.clipboard.writeText(url.value);

    updateButtonLabel(this.id, "<i class='fa-solid fa-check mr-2'></i>Copiado", "btn-barymont-green");
  });
}

function generateInscriptionUrl() {
  let phase = document.getElementById("inscription-url-phase").value;
  let language = document.getElementById("inscription-url-language").value;
  let superAccess = document.getElementById("inscription-super-access").checked;
  let corporateEventId = document.getElementById("corporate-event-id").value;

  if (phase === "" || language === "") {
    document.getElementById("inscription-url").value = "";
    return;
  }

  document.getElementById("inscription-url").value = `${window.location.origin}/herramientas/corporate-event-inscription/${corporateEventId}/${phase}/${language}${superAccess ? "/permanent" : ""}`;
}

async function registerAccess(inscriptionId) {
  let formData = new FormData();
  formData.append("inscriptionId", inscriptionId);

  const response = await fetch("/herramientas/corporate-event-inscriptions-management/ajax/registerAccess", {
    method: "POST",
    body: formData,
  }).then((response) => response.json());

  if (response.success === false) {
    createToast("error", "Error ❌", response.message);
    return;
  }

  createToast("success", "Éxito ✅", response.message);

  $("#" + modalConfirmRegisterAccess).modal("hide");
}

async function fetchInscriptionData(inscriptionId) {
  let formData = new FormData();
  formData.append("inscriptionId", inscriptionId);

  return await fetch("/herramientas/corporate-event-inscriptions-management/ajax/getInscriptionData", {
    method: "POST",
    body: formData,
  }).then((response) => response.json());
}

async function fetchAccessInscriptionData(inscriptionId) {
  let formData = new FormData();
  formData.append("inscriptionId", inscriptionId);

  return await fetch("/herramientas/corporate-event-inscriptions-management/ajax/getAccessDataByInscriptionId", {
    method: "POST",
    body: formData,
  }).then((response) => response.json());
}

async function fetchSaveInscriptionData(inscriptionId, userName, userFirstName, userSecondName, email, phone, level) {
  let formData = new FormData();
  formData.append("inscriptionId", inscriptionId);
  formData.append("userName", userName);
  formData.append("userFirstName", userFirstName);
  formData.append("userSecondName", userSecondName);
  formData.append("email", email);
  formData.append("phone", phone);
  formData.append("level", level);

  return await fetch("/herramientas/corporate-event-inscriptions-management/ajax/saveInscriptionData", {
    method: "POST",
    body: formData,
  }).then((response) => response.json());
}

async function fetchPhasesData(corporateEventId) {
  let formData = new FormData();
  formData.append("corporateEventId", corporateEventId);

  return await fetch("/herramientas/corporate-event-inscriptions-management/ajax/getPhasesData", {
    method: "POST",
    body: formData,
  }).then((response) => response.json());
}

async function fetchLanguagesData() {
  return await fetch("/herramientas/corporate-event-inscriptions-management/ajax/getLanguagesData", {
    method: "POST",
  }).then((response) => response.json());
}

async function fetchAccompanyingInscriptionData(inscriptionId) {
  let formData = new FormData();
  formData.append("inscriptionId", inscriptionId);

  return await fetch("/herramientas/corporate-event-inscriptions-management/ajax/getAccompanyingDataByInscriptionId", {
    method: "POST",
    body: formData,
  }).then((response) => response.json());
}

async function fetchInscriptionDataByInscriptionCode(inscriptionCode, eventId) {
  let formData = new FormData();
  formData.append("inscriptionCode", inscriptionCode);
  formData.append("eventId", eventId);

  return await fetch("/herramientas/corporate-event-inscriptions-management/ajax/getInscriptionDataByInscriptionCode", {
    method: "POST",
    body: formData,
  }).then((response) => response.json());
}

async function fetchInscriptionLevels() {
  return await fetch("/herramientas/corporate-event-inscriptions-management/ajax/getInscriptionLevels", {
    method: "POST",
  }).then((response) => response.json());
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname.includes("/herramientas/corporate-event-inscriptions-management")) {
      $("#" + datatableInscriptionId).DataTable({
        language: {
          sProcessing: "Procesando Datos...",
          sLengthMenu: "Mostrar _MENU_ inscripciones",
          sZeroRecords: "No se encontraron inscripciones coincidentes con el criterio de búsqueda.",
          sEmptyTable: "Ninguna inscripción coincidente con el criterio de búsqueda.",
          sInfo: "Mostrando inscripciones del _START_ al _END_ de un total de _TOTAL_ inscripciones",
          sInfoEmpty: "Mostrando Inscripciones del 0 al 0 de un total de 0 Inscripciones",
          sInfoFiltered: "(Filtrados de un total de _MAX_ Inscripciones)",
          sInfoPostFix: "",
          sSearch: "<div class='input-group-prepend'><div class='input-group-text'><i class='fas fa-search'></i></div></div>",
          searchPlaceholder: "Ingrese su búsqueda...",
          sUrl: "",
          sInfoThousands: ",",
          sLoadingRecords: "Cargando Datos...",
          oPaginate: {
            sFirst: "Primera",
            sLast: "Última",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
          oAria: {
            sSortAscending: ": Activar para ordenar la columna de manera ascendente",
            sSortDescending: ": Activar para ordenar la columna de manera descendente",
          },
          buttons: {
            copySuccess: {
              1: "Copiado 1 Inscripciones",
              _: "Copiados %d Inscripciones al Portapapeles",
            },
            copyTitle: "Inscripciones Copiados al Portapapeles",
          },
          select: {
            rows: "%d Inscripciones seleccionados",
          },
        },
        pagingType: "input",
        columnDefs: [
          {
            targets: [1, 2, 3, 6, 7],
            searchable: true,
            orderable: true,
          },
          {
            targets: [0, 4, 5, 8],
            searchable: true,
            orderable: false,
          },
          {
            targets: [9],
            searchable: false,
            orderable: true,
          },
          {
            targets: [10],
            searchable: false,
            orderable: false,
          },
          {
            targets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            className: "dt-center",
          },
        ],
        order: [[9, "desc"]],
        processing: true,
        serverSide: true,
        scrollX: true,
        dom: '<"top"fl>Brt<"bottom"ip><"clear">',
        buttons: [
          {
            extend: "colvis",
            text: "Ver más columnas",
          },
          {
            extend: "collection",
            text: "Herramientas",
            buttons: [
              {
                extend: "copyHtml5",
                text: "Copiar",
              },
              {
                extend: "print",
                text: "Imprimir",
              },
            ],
            fade: true,
          },
          {
            text: '<i class="fas fa-eraser mr-2"></i> Limpiar filtros',
            className: "btn btn-barymont-black my-2 my-md-0",
            action: function () {
              resetearFiltros();
            },
            init: function (api, node) {
              $(node).removeClass("dt-button");
            },
          },
          {
            text: '<i class="fas fa-download mr-2"></i> Exportar',
            className: "btn btn-barymont-black my-2 my-md-0",
            action: function () {
              window.open("/administracion/exportaciones?exportType=corporateEvent", "_blank");
            },
            init: function (api, node) {
              if (AppGbSession.checkUserHasPermission("CorporateEvent:CorporateEventInscriptionExportAccessChecker")) {
                $(node).removeClass("dt-button");
              } else {
                $(node).hide();
              }
            },
          },
          {
            text: '<i class="fa-solid fa-user-clock mr-2"></i> Control de acceso',
            className: "btn btn-barymont-red my-2 my-md-0",
            action: function () {
              window.location.href = "/herramientas/corporate-event-access-management/" + $("#corporate-event-id").val();
            },
            init: function (api, node) {
              if (AppGbSession.checkUserHasPermission("CorporateEvent:CorporateEventAccessControlAccessChecker")) {
                $(node).removeClass("dt-button");
              } else {
                $(node).hide();
              }
            },
          },
          {
            text: '<i class="fa-solid fa-clock-rotate-left mr-2"></i> Histórico',
            className: "btn btn-barymont-black my-2 my-md-0",
            action: function () {
              window.location.href = "/herramientas/corporate-event-access-history/" + $("#corporate-event-id").val();
            },
            init: function (api, node) {
              if (AppGbSession.checkUserHasPermission("CorporateEvent:CorporateEventHistoryReadAccessChecker")) {
                $(node).removeClass("dt-button");
              } else {
                $(node).hide();
              }
            },
          },
          {
            text: '<i class="fa-solid fa-link mr-2"></i> Enlace Inscripción',
            className: "btn btn-barymont-red my-2 my-md-0",
            action: async function () {
              await loadInscriptionUrlModal();
            },
            init: function (api, node) {
              if (AppGbSession.checkUserHasPermission("CorporateEvent:CorporateEventInscriptionLinkCreateAccessChecker")) {
                $(node).removeClass("dt-button");
              } else {
                $(node).hide();
              }
            },
          },
        ],
        pageLength: 15,
        lengthMenu: [
          [15, 30, 50, 100],
          [15, 30, 50, 100],
        ],
        select: false,
        keys: true,
        searchHighlight: true,
        ajax: {
          url: "/herramientas/corporate-event-inscriptions-management/listprocessing",
          type: "post",
          data: function (d) {
            const checkCorporateInscriptionStartDateFilter = $("#corporate-event-inscriptions-management-start-date").val();
            const checkCorporateInscriptionFinishDateFilter = $("#corporate-event-inscriptions-management-finish-date").val();
            const checkCorporateInscriptionPhaseTypeFilter = $("#corporate-event-inscriptions-management-phase-type").val();
            const checkCorporateInscriptionLevelFilter = $("#corporate-event-inscriptions-management-level").val();
            const corporateEventId = $("#corporate-event-id").val();

            if (!isValidUuid(corporateEventId)) {
              return false;
            }

            d.startDate = checkCorporateInscriptionStartDateFilter != "" ? checkCorporateInscriptionStartDateFilter : "";
            d.finishDate = checkCorporateInscriptionFinishDateFilter != "" ? checkCorporateInscriptionFinishDateFilter : "";
            d.phaseType = checkCorporateInscriptionPhaseTypeFilter != "" ? checkCorporateInscriptionPhaseTypeFilter : "";
            d.level = checkCorporateInscriptionLevelFilter != "" ? checkCorporateInscriptionLevelFilter : "";
            d.corporateEventId = corporateEventId;
          },
          error: function (e) {
            if (e.status === 401) {
              createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
              setTimeout(function () {
                location.reload();
              }, 5000);
            } else {
              createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
            }
          },
        },
        fnDrawCallback: function () {
          document.querySelectorAll("[data-action='register-access']").forEach((element) => {
            element.addEventListener("click", async () => {
              $("#" + modalConfirmRegisterAccess).modal("show");
              document.getElementById(modalConfirmRegisterAccess + "-confirm").addEventListener("click", async () => {
                showLoadingButton(modalConfirmRegisterAccess + "-confirm", "btn-barymont-red", true);
                await registerAccess(element.getAttribute("data-inscription-id"));
                updateButtonLabel(modalConfirmRegisterAccess + "-confirm", "<i class='fas fa-user-check mr-2'></i> Registrar", "btn-barymont-red");
              });
            });
          });
          document.querySelectorAll("[data-action='show-inscription']").forEach((item) => {
            item.addEventListener("click", async function () {
              await loadInscriptionData(this.getAttribute("data-inscription-id"));
            });
          });

          $('[data-toggle="tooltip"]').tooltip();
        },
        stateSave: true,
        stateSaveCallback: function (settings, data) {
          let storageKey = `${FILTER_STATE_LOCAL_STORAGE_KEY}${settings.sInstance}_${getCookieValue("user_id")}`;

          data.customFilter = getStateOfCustomFilters();

          localStorage.setItem(storageKey, JSON.stringify(data));
        },
        stateLoadCallback: function (settings) {
          let storageKey = `${FILTER_STATE_LOCAL_STORAGE_KEY}${settings.sInstance}_${getCookieValue("user_id")}`;

          if (localStorage.getItem(storageKey) === null) {
            return false;
          }

          let localStorageData = JSON.parse(localStorage.getItem(storageKey));

          if (localStorageData.time < new Date().getTime() - FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME) {
            return false;
          }

          recreateStateOfCustomFilters(localStorageData);

          return localStorageData;
        },
      });

      document.querySelectorAll(".triggerDatatableUpdateCorporateEventInscriptionsManagement").forEach((item) => {
        item.addEventListener("change", function () {
          $("#" + datatableInscriptionId)
            .DataTable()
            .ajax.reload();
        });
      });
    }
  });
}
