"use strict";

import { showLoadingButton, updateButtonLabel } from "../shared/shared.js";
import { addCommonServiceModalsEventListeners } from "./services.js";

var FILTER_STATE_LOCAL_STORAGE_KEY = "FES_FILTER_STATE_";
var FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME = 1000 * 60 * 20; // 20 minutes in milliseconds

function getStateOfCustomFilters() {
  return {
    checkTodos: $("#cbox5").prop("checked"),
    checkSoloUtilizados: $("#cbox1").prop("checked"),
    checkSoloPendientes: $("#cbox2").prop("checked"),
    onlyWithoutExpiredState: $("#cbox6").prop("checked"),
    checkPeriodicidad: $("#periodicidad-servicio").val(),
    checkPlan: $("#service-plan").val(),
    checkObtainMethod: $("#obtain-method").val(),
    subordinateUserSelector: document.getElementById("subordinates-selector").value,
    subordinateFilterMode: document.getElementById("subordinates-filter-mode").value,
  };
}

function recreateStateOfCustomFilters(localStorageData) {
  if (localStorageData.customFilter === undefined) {
    return false;
  }

  $("#cbox5").prop("checked", localStorageData.customFilter.checkTodos);
  $("#cbox1").prop("checked", localStorageData.customFilter.checkSoloUtilizados);
  $("#cbox2").prop("checked", localStorageData.customFilter.checkSoloPendientes);
  $("#cbox6").prop("checked", localStorageData.customFilter.onlyWithoutExpiredState);
  setBootstrapToggle("#cbox6", localStorageData.customFilter.onlyWithoutExpiredState);
  $("#periodicidad-servicio").val(localStorageData.customFilter.checkPeriodicidad);
  $("#service-plan").val(localStorageData.customFilter.checkPlan);
  $("#obtain-method").val(localStorageData.customFilter.checkObtainMethod);

  $("#periodicidad-servicio").selectpicker("refresh");
  $("#service-plan").selectpicker("refresh");
  $("#obtain-method").selectpicker("refresh");

  document.getElementById("subordinates-selector").value = localStorageData.customFilter.subordinateUserSelector;
  $("#subordinates-selector").selectpicker("refresh");

  document.getElementById("subordinates-filter-mode").value = localStorageData.customFilter.subordinateFilterMode;
  $("#subordinates-filter-mode").selectpicker("refresh");
}

function resetearFiltros() {
  $("#cbox5").prop("checked", true);
  $("#cbox1").prop("checked", false);
  $("#cbox2").prop("checked", false);
  setBootstrapToggle("#cbox6", false);
  $("#periodicidad-servicio").val("").selectpicker("refresh");
  $("#subordinates-selector").val("").selectpicker("refresh");
  $("#subordinates-filter-mode").val("is_promoter").selectpicker("refresh");
  $("#service-plan").val("").selectpicker("refresh");
  $("#obtain-method").val("").selectpicker("refresh");

  $("#financial_education_service_tabla").DataTable().search("").draw();
  $("#financial_education_service_tabla").DataTable().ajax.reload();
}

function addFinancialEducationService() {
  let codigoCanjeo = document.getElementById("codigo_canjeo");
  let idUsuario = document.getElementById("id_usuario");
  let grossAmount = document.getElementById("gross_amount");
  let netAmount = document.getElementById("net_amount");
  let taxPercent = document.getElementById("tax_percentaje");
  let obtainPlan = document.getElementById("obtain_plan");
  let obtainPeriodicity = document.getElementById("obtain_periodicity");
  let obtainMethod = document.getElementById("obtain_method");
  let dateExpiration = document.getElementById("date_expiration");

  showLoadingButton("add-financial-education-service-button", "btn-barymont-red", true);

  if (codigoCanjeo.value != "" && idUsuario.value != "" && grossAmount.value != "" && netAmount.value != "" && taxPercent.value != "" && obtainPlan.value != "" && obtainPeriodicity.value != "" && obtainMethod.value != "") {
    let formData = new FormData();

    setTimeout(() => {
      formData.append("codigo_canjeo", codigoCanjeo.value ? codigoCanjeo.value : "");
      formData.append("id_usuario", idUsuario.value ? idUsuario.value : "");
      formData.append("gross_amount", grossAmount.value ? grossAmount.value : "");
      formData.append("net_amount", netAmount.value ? netAmount.value : "");
      formData.append("tax_percent", taxPercent.value ? taxPercent.value : "");
      formData.append("obtain_plan", obtainPlan.value ? obtainPlan.value : "");
      formData.append("obtain_periodicity", obtainPeriodicity.value ? obtainPeriodicity.value : "");
      formData.append("obtain_method", obtainMethod.value ? obtainMethod.value : "");
      formData.append("expired_at", dateExpiration.value ? dateExpiration.value : "");

      $.ajax({
        url: "/herramientas/financialeducationservice/ajax/addNewFinancialEducationService",
        type: "POST",
        dataType: "html",
        data: formData,
        processData: false,
        contentType: false,
        async: false,
      })
        .done(function (data) {
          const response = JSON.parse(data);

          updateButtonLabel("add-financial-education-service-button", "Crear Servicio de Educación Financiera", "btn-barymont-red");

          if (response["status"] === 1) {
            window.location = window.location.href.split("?")[0];
            return true;
          } else {
            if (response["error"]["message"] == "El importe no puede ser 0") {
              grossAmount.classList.add("is-invalid");
              $("#financialeducationservice").removeClass("was-validated");
            } else {
              grossAmount.classList.remove("is-invalid");
            }

            createToast("warning", "Ha ocurrido un error", response["error"]["message"], 5000);
            return false;
          }
        })
        .fail(function (e) {
          if (e.status === 401) {
            createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
            setTimeout(function () {
              location.reload();
            }, 5000);
          } else {
            createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
          }
        });
    }, 100);
  } else {
    updateButtonLabel("add-financial-education-service-button", "Crear Servicio de Educación Financiera", "btn-barymont-red");
    return false;
  }
}

function setBootstrapToggle(id, value) {
  if (!$(id).hasClass("updatetriggerfinancialeeducationservice")) {
    return;
  }

  if (value) {
    $(id).data("bs.toggle").on(true);
    return;
  }

  $(id).data("bs.toggle").off(true);
}

function loadAssignEducator(codeFinancialEducationService) {
  document.getElementById("codigo_canjeo_assign_educator").value = codeFinancialEducationService;
  $("#ModalAssignEducator").modal("show");
}

function assignEducator() {
  let codigoCanjeo = document.getElementById("codigo_canjeo_assign_educator");
  let idEducator = document.getElementById("id_educator");
  let educatorMode = document.getElementById("educator_mode");

  if (codigoCanjeo.value != "" && idEducator.value != "" && educatorMode.value != "") {
    let formData = new FormData();

    showLoadingButton("assign-educator-button", "btn-barymont-red", true);

    setTimeout(() => {
      formData.append("codigo_canjeo", codigoCanjeo.value ? codigoCanjeo.value : "");
      formData.append("id_educator", idEducator.value ? idEducator.value : "");
      formData.append("educator_mode", educatorMode.value ? educatorMode.value : "");

      $.ajax({
        url: "/herramientas/financialeducationservice/ajax/assignToEducator",
        type: "POST",
        dataType: "html",
        data: formData,
        processData: false,
        contentType: false,
        async: false,
      })
        .done(function (data) {
          const response = JSON.parse(data);

          updateButtonLabel("assign-educator-button", "Asignar Educador", "btn-barymont-red");

          if (response["status"] === 1) {
            window.location = window.location.href.split("?")[0];
            return true;
          } else {
            createToast("warning", "Ha ocurrido un error", response["error"]["message"], 5000);
            return false;
          }
        })
        .fail(function (e) {
          if (e.status === 401) {
            createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
            setTimeout(function () {
              location.reload();
            }, 5000);
          } else {
            createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
          }
        });
    }, 100);
  } else {
    return false;
  }
}

function deleteFinancialEducationService() {
  let codigoCanjeo = document.getElementById("codigo_canjeo_delete");

  if (codigoCanjeo.val()) {
    let formData = new FormData();

    showLoadingButton("delete-financial-education-service-button", "btn-barymont-black", true);

    setTimeout(() => {
      formData.append("codigo_canjeo", codigoCanjeo.value ? codigoCanjeo.value : "");

      $.ajax({
        url: "/herramientas/financialeducationservice/ajax/deleteFinancialEducationService",
        type: "POST",
        dataType: "html",
        data: formData,
        processData: false,
        contentType: false,
        async: false,
      })
        .done(function (data) {
          const response = JSON.parse(data);

          updateButtonLabel("delete-financial-education-service-button", "Eliminar", "btn-barymont-black");

          if (response["status"] === 1) {
            window.location = window.location.href.split("?")[0];
            return true;
          } else {
            createToast("warning", "Ha ocurrido un error", response["error"]["message"], 5000);
            return false;
          }
        })
        .fail(function (e) {
          if (e.status === 401) {
            createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
            setTimeout(function () {
              location.reload();
            }, 5000);
          } else {
            createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
          }
        });
    }, 100);
  } else {
    return false;
  }
}

function calculateTaxPercentage() {
  let importePago = document.getElementById("gross_amount");
  let tasaPorcentaje = document.getElementById("tax_percentaje");
  let valorNetoPreview = document.getElementById("net_amount_preview");
  let valorNeto = document.getElementById("net_amount");

  tasaPorcentaje.value > 100 ? (tasaPorcentaje.value = 100) : null;
  tasaPorcentaje.value < 0 ? (tasaPorcentaje.value = 0) : null;

  if (tasaPorcentaje.value >= 0 && tasaPorcentaje.value != "" && importePago.value > 0) {
    let valorNetoCalculado = (parseFloat(importePago.value) * (1 + parseFloat(tasaPorcentaje.value) / 100)).toFixed(2);

    valorNetoPreview.innerHTML = valorNetoCalculado;
    valorNeto.value = valorNetoCalculado;
  } else {
    valorNetoPreview.innerHTML = "0.0";
    valorNeto.value = 0.0;
  }
}

function generateRandomCode() {
  let codigoCanjeo = document.getElementById("codigo_canjeo");

  $.ajax({
    url: "/herramientas/financialeducationservice/ajax/generateRandomCode",
    type: "POST",
    dataType: "html",
    processData: false,
    contentType: false,
    async: false,
  })
    .done(function (data) {
      const response = JSON.parse(data);

      if (response["status"] === 1) {
        codigoCanjeo.value = response["data"]["codigoCanjeo"];
        createToast("success", "Código generado", "El código ha sido generado correctamente", 2000);
        return true;
      } else {
        createToast("warning", "Ha ocurrido un error", response["error"]["message"], 5000);
        return false;
      }
    })
    .fail(function (e) {
      if (e.status === 401) {
        createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
        setTimeout(function () {
          location.reload();
        }, 5000);
      } else {
        createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
      }
    });
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname.includes("/herramientas/financialeducationservice")) {
      $("#financial_education_service_tabla").DataTable({
        language: {
          sProcessing: "Procesando Datos...",
          sLengthMenu: "Mostrar _MENU_ Servicios de Educación Financiera",
          sZeroRecords: "No se encontraron Servicios de Educación Financiera coincidentes con el criterio de búsqueda.",
          sEmptyTable: "Ningún Servicios de Educación Financiera coincidente con el criterio de búsqueda.",
          sInfo: "Mostrando Servicios de Educación Financiera del _START_ al _END_ de un total de _TOTAL_ Servicios",
          sInfoEmpty: "Mostrando Servicios de Educación Financiera del 0 al 0 de un total de 0 Servicios",
          sInfoFiltered: "(Filtrados de un total de _MAX_ Servicios)",
          sInfoPostFix: "",
          sSearch: "<div class='input-group-prepend'><div class='input-group-text'><i class='fas fa-search'></i></div></div>",
          searchPlaceholder: "Datos de los Servicios de Educación Financiera",
          sUrl: "",
          sInfoThousands: ",",
          sLoadingRecords: "Cargando Datos...",
          oPaginate: {
            sFirst: "Primera",
            sLast: "Última",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
          oAria: {
            sSortAscending: ": Activar para ordenar la columna de manera ascendente",
            sSortDescending: ": Activar para ordenar la columna de manera descendente",
          },
          buttons: {
            copySuccess: {
              1: "Copiado 1 Servicio de Educación Financiera al Portapapeles",
              _: "Copiados %d Servicios de Educación Financiera al Portapapeles",
            },
            copyTitle: "Servicios de Educación Financiera Copiados al Portapapeles",
          },
          select: {
            rows: "%d Servicios de Educación Financiera seleccionados",
          },
        },
        pagingType: "input",
        columnDefs: [
          {
            targets: [0, 2, 6],
            searchable: true,
          },
          {
            targets: [0, 3],
            visible: false,
          },
          {
            targets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
            className: "dt-center",
          },
          {
            targets: [6, 9, 10, 14, 15],
            orderable: false,
          },
          {
            visible: AppGbSession.checkUserHasPermission("FinancialEducationService:FinancialEducationServiceTransactionManageAccessChecker"),
            targets: [0],
          },
          {
            visible: AppGbSession.checkUserHasPermission("FinancialEducationService:FinancialEducationServiceDocumentsManageAccessChecker"),
            targets: [10],
          },
        ],
        order: [[12, "desc"]],
        processing: true,
        serverSide: true,
        scrollX: true,
        dom: '<"top"fl>Brt<"bottom"ip><"clear">',
        buttons: [
          {
            extend: "colvis",
            text: "Ver más columnas",
          },
          {
            extend: "collection",
            text: "Herramientas",
            buttons: [
              {
                extend: "copyHtml5",
                text: "Copiar",
              },
              {
                extend: "print",
                text: "Imprimir",
              },
              {
                extend: "excelHtml5",
                title: "Servicios de Educación Financiera Gestibarymont",
                text: "Excel",
              },
            ],
            fade: true,
          },
          {
            text: '<i class="fas fa-eraser mr-2"></i> Limpiar filtros',
            className: "btn btn-barymont-black my-2 my-md-0",
            action: function () {
              resetearFiltros();
            },
            init: function (api, node) {
              $(node).removeClass("dt-button");
            },
          },
          {
            text: '<i class="fas fa-download mr-2"></i> Exportar Servicios',
            className: "btn btn-barymont-black my-2 my-md-0",
            action: function () {
              window.open("/administracion/exportaciones?exportType=educacion-financiera", "_blank");
            },
            init: function (api, node) {
              if (AppGbSession.checkUserHasPermission("FinancialEducationService:FinancialEducationServiceExportAccessChecker")) {
                $(node).removeClass("dt-button");
              } else {
                $(node).hide();
              }
            },
          },
          {
            text: "<i class='fas fa-plus mr-2'></i>Crear Servicio de Educación Financiera",
            className: "btn btn-barymont-red my-2 my-md-0",
            action: function () {
              $("#ModalAddFinancialEducationService").modal("show");
            },
            init: function (api, node) {
              if (AppGbSession.checkUserHasPermission("FinancialEducationService:FinancialEducationServiceCreateAccessChecker")) {
                $(node).removeClass("dt-button");
              } else {
                $(node).hide();
              }
            },
          },
        ],
        pageLength: 10,
        lengthMenu: [
          [10, 25, 50, 100, 500],
          [10, 25, 50, 100, 500],
        ],
        select: false,
        keys: true,
        searchHighlight: true,
        ajax: {
          url: "/herramientas/financialeducationservice/listaprocessing",
          type: "post",
          data: function (d) {
            const checkSoloUtilizados = $("#cbox1").prop("checked");
            const checkSoloPendientes = $("#cbox2").prop("checked");
            const checkOnlyWithoutExpiredState = document.getElementById("cbox6")?.checked;

            const checkPeriodicidad = $("#periodicidad-servicio").val();
            const checkPlan = $("#service-plan").val();
            const checkObtainMethod = $("#obtain-method").val();

            if (checkSoloUtilizados == true) {
              d.soloUtilizados = 1;
            }

            if (checkSoloPendientes == true) {
              d.soloPendientes = 1;
            }

            if (checkOnlyWithoutExpiredState == true) {
              d.onlyWithoutExpiredState = 1;
            }

            d.periodicidad = checkPeriodicidad != "" ? checkPeriodicidad : "";
            d.plan = checkPlan != "" ? checkPlan : "";
            d.obtainMethod = checkObtainMethod != "" ? checkObtainMethod : "";

            d.subordinateUserSelector = document.getElementById("subordinates-selector") !== null ? document.getElementById("subordinates-selector").value : "";
            d.subordinateFilterMode = document.getElementById("subordinates-filter-mode")?.value;
          },
          error: function (e) {
            if (e.status === 401) {
              createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
              setTimeout(function () {
                location.reload();
              }, 5000);
            } else {
              createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
            }
          },
        },
        fnDrawCallback: function () {
          document.querySelectorAll("[data-action='assign-educator']").forEach((element) => {
            element.addEventListener("click", () => {
              loadAssignEducator(element.getAttribute("data-code"));
            });
          });

          $('[data-toggle="tooltip"]').tooltip();
        },
        stateSave: true,
        stateSaveCallback: function (settings, data) {
          let storageKey = `${FILTER_STATE_LOCAL_STORAGE_KEY}${settings.sInstance}_${getCookieValue("user_id")}`;

          data.customFilter = getStateOfCustomFilters();

          localStorage.setItem(storageKey, JSON.stringify(data));
        },
        stateLoadCallback: function (settings) {
          let storageKey = `${FILTER_STATE_LOCAL_STORAGE_KEY}${settings.sInstance}_${getCookieValue("user_id")}`;

          if (localStorage.getItem(storageKey) === null) {
            return false;
          }

          let localStorageData = JSON.parse(localStorage.getItem(storageKey));

          if (localStorageData.time < new Date().getTime() - FILTER_STATE_LOCAL_STORAGE_EXPIRATION_TIME) {
            return false;
          }

          recreateStateOfCustomFilters(localStorageData);

          return localStorageData;
        },
      });

      $(".updatetriggerfinancialeeducationservice").change(function () {
        $("#financial_education_service_tabla").DataTable().ajax.reload();
      });

      document.getElementById("periodicidad-servicio").addEventListener("change", function () {
        $("#financial_education_service_tabla").DataTable().ajax.reload();
      });

      document.getElementById("obtain-method").addEventListener("change", function () {
        $("#financial_education_service_tabla").DataTable().ajax.reload();
      });

      document.getElementById("service-plan").addEventListener("change", function () {
        $("#financial_education_service_tabla").DataTable().ajax.reload();
      });

      document.getElementById("subordinates-selector").addEventListener("change", function () {
        $("#financial_education_service_tabla").DataTable().ajax.reload();
      });

      document.getElementById("subordinates-filter-mode").addEventListener("change", function () {
        $("#financial_education_service_tabla").DataTable().ajax.reload();
      });

      document.querySelector("#ModalAddFinancialEducationService #financialeducationservice")?.addEventListener("submit", (e) => {
        e.preventDefault();
        addFinancialEducationService();
      });

      document.querySelector("#ModalDeleteFinancialEducationService #deleteFinancialEducationService")?.addEventListener("submit", (e) => {
        e.preventDefault();
        deleteFinancialEducationService();
      });

      document.querySelector("#ModalAssignEducator #financialeducationserviceassigneducator")?.addEventListener("submit", (e) => {
        e.preventDefault();
        assignEducator();
      });

      document.getElementById("button_codigo_canjeo")?.addEventListener("click", () => {
        generateRandomCode();
      });

      document.getElementById("gross_amount")?.addEventListener("keyup", () => {
        calculateTaxPercentage();
      });

      document.getElementById("tax_percentaje")?.addEventListener("keyup", () => {
        calculateTaxPercentage();
      });

      addCommonServiceModalsEventListeners();
    }
  });
}
