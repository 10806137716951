"use strict";

import { removeLoadingContainerById } from "../shared/shared.js";

const countersHistoryData = "counters-history-access-data-container";
const historyDataTable = "history-access-table";

if (window) {
  window.addEventListener("load", async () => {
    if (window.location.pathname.includes("/herramientas/corporate-event-access-history/")) {
      $("#" + historyDataTable).DataTable({
        language: {
          sProcessing: "Procesando Datos...",
          sLengthMenu: "Mostrar _MENU_ accesos",
          sZeroRecords: "No se encontraron accesos coincidentes con el criterio de búsqueda.",
          sEmptyTable: "Ningún Acceso coincidente con el criterio de búsqueda.",
          sInfo: "Mostrando accesos del _START_ al _END_ de un total de _TOTAL_ accesos",
          sInfoEmpty: "Mostrando Accesos del 0 al 0 de un total de 0 Accesos",
          sInfoFiltered: "(Filtrados de un total de _MAX_ Accesos)",
          sInfoPostFix: "",
          sSearch: "<div class='input-group-prepend'><div class='input-group-text'><i class='fas fa-search'></i></div></div>",
          searchPlaceholder: "Ingrese su búsqueda...",
          sUrl: "",
          sInfoThousands: ",",
          sLoadingRecords: "Cargando Datos...",
          oPaginate: {
            sFirst: "Primera",
            sLast: "Última",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
          oAria: {
            sSortAscending: ": Activar para ordenar la columna de manera ascendente",
            sSortDescending: ": Activar para ordenar la columna de manera descendente",
          },
          buttons: {
            copySuccess: {
              1: "Copiado 1 Accesos",
              _: "Copiados %d Accesos al Portapapeles",
            },
            copyTitle: "Accesos Copiados al Portapapeles",
          },
          select: {
            rows: "%d Accesos seleccionados",
          },
        },
        pagingType: "input",
        columnDefs: [
          {
            targets: [1, 2, 3],
            searchable: true,
            orderable: true,
          },
          {
            targets: [0],
            searchable: false,
            orderable: true,
          },
          {
            targets: [0, 1, 2, 3],
            className: "dt-center",
          },
        ],
        order: [[0, "desc"]],
        processing: true,
        serverSide: true,
        scrollX: true,
        dom: '<"top"fl>Brt<"bottom"ip><"clear">',
        buttons: [
          {
            extend: "colvis",
            text: "Ver más columnas",
          },
          {
            extend: "collection",
            text: "Herramientas",
            buttons: [
              {
                extend: "copyHtml5",
                text: "Copiar",
              },
              {
                extend: "print",
                text: "Imprimir",
              },
            ],
            fade: true,
          },
        ],
        pageLength: 15,
        lengthMenu: [
          [15, 30, 50, 100],
          [15, 30, 50, 100],
        ],
        select: false,
        keys: true,
        searchHighlight: true,
        ajax: {
          url: "/herramientas/corporate-event-access-history/listprocessing",
          type: "post",
          data: function (d) {
            d.corporateEventId = document.getElementById("corporate-event-id").value;
          },
          error: function (e) {
            if (e.status === 401) {
              createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
              setTimeout(function () {
                location.reload();
              }, 5000);
            } else {
              createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
            }
          },
        },
        fnDrawCallback: function () {
          $('[data-toggle="tooltip"]').tooltip();
        },
      });

      await loadCountersData();
      removeLoadingContainerById(countersHistoryData);
      document.getElementById(countersHistoryData).classList.remove("d-none");
    }
  });
}

async function loadCountersData() {
  const corporateEventId = document.getElementById("corporate-event-id").value;

  const response = await fetchCountersData(corporateEventId);

  if (response.success === false) {
    createToast("error", "Error ❌", "Ocurrió un error al obtener la información de los contadores de acceso.");
    return;
  }

  document.getElementById("total-access").textContent = response.counterAllAccess;
  document.getElementById("total-access-last-24-hours").textContent = response.counterLast24HoursAccess;
  document.getElementById("total-access-last-4-hours").textContent = response.counterLast4HoursAccess;
}

async function fetchCountersData(corporateEventId) {
  let formData = new FormData();
  formData.append("corporateEventId", corporateEventId);

  return await fetch("/herramientas/corporate-event-access-management/ajax/getAccessCountersData", {
    method: "POST",
    body: formData,
  }).then((response) => response.json());
}
