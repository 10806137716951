import { subscriptionPeriodicitySelectOptions } from "../../domain/subscriptionPeriodicity";
import { clientTypeSelectOptions } from "../../domain/clientType";

export default function planningServiceCreationModalHTML({ modalId, modalBodyId, promotersSelectId, planifiersSelectId, subscriptionPeriodicitySelectId, clientTypeSelectId, isClientFromCanaryIslandCheckboxId, currentLeadPromoterOption, saveButtonId }: { modalId: string; modalBodyId: string; promotersSelectId: string; planifiersSelectId: string; subscriptionPeriodicitySelectId: string; clientTypeSelectId: string; isClientFromCanaryIslandCheckboxId: string; currentLeadPromoterOption: string; saveButtonId: string }): string {
  return `
    <div id="${modalId}" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">

          <div class="modal-header">
            <h1 class="modal-title">Nuevo servicio de planificación financiera</h1>
          </div>

          <div class="modal-body" id="${modalBodyId}">
            <div class="container-fluid">
              <div class="row justify-content-center">

                <div class="col-12">
                  <div class="form-group">
                    <label for="${promotersSelectId}" class="control-label font-weight-bold">
                      Promotor <i class="fas fa-asterisk text-barymont-red"></i>
                    </label>
                    <select id="${promotersSelectId}"
                      class="form-control input-lg selectpicker"
                      title="Seleccionar promotor"
                      data-live-search="true"
                      data-live-search-placeholder="Introduce al menos 3 caracteres para buscar un promotor"
                      data-none-results-text="No se encontraron promotores que coincidan con '{0}'"
                      required
                      ${currentLeadPromoterOption ? "disabled" : ""}
                      >
                      ${currentLeadPromoterOption}
                    </select>
                    ${currentLeadPromoterOption ? `<small class="form-text text-muted text-center">El lead tiene un promotor asignado, este no se puede modificar.</small>` : ""}
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label for="${planifiersSelectId}" class="control-label font-weight-bold">
                      Planificador <i class="fas fa-asterisk text-barymont-red"></i>
                    </label>
                    <select id="${planifiersSelectId}"
                      class="form-control input-lg selectpicker"
                      title="Seleccionar planificador"
                      data-live-search="true"
                      data-live-search-placeholder="Introduce al menos 3 caracteres para buscar un planificador"
                      data-none-results-text="No se encontraron planificadores que coincidan con '{0}'"
                      required
                      >
                    </select>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label for="${subscriptionPeriodicitySelectId}" class="control-label font-weight-bold">
                      Períodicidad de la suscripción <i class="fas fa-asterisk text-barymont-red"></i>
                    </label>
                    <select id="${subscriptionPeriodicitySelectId}" class="form-control input-lg selectpicker" title="Seleccionar la periódicidad de la suscripción" required>
                      ${Object.entries(subscriptionPeriodicitySelectOptions)
                        .map(([value, { label, enabled, isDefault }]) => `<option value="${value}" ${isDefault ? "selected" : ""} ${enabled ? "" : "disabled"}>${label}</option>`)
                        .join("")}
                    </select>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label for="${clientTypeSelectId}" class="control-label font-weight-bold">
                      Tipo de cliente <i class="fas fa-asterisk text-barymont-red"></i>
                    </label>
                    <select id="${clientTypeSelectId}" class="form-control input-lg selectpicker" title="Indicar si es particular o autónomo/empresa" required>
                      ${Object.entries(clientTypeSelectOptions)
                        .map(([value, { label, enabled, isDefault }]) => `<option value="${value}" ${isDefault ? "selected" : ""} ${enabled ? "" : "disabled"}>${label}</option>`)
                        .join("")}
                    </select>
                  </div>
                </div>

                <div class="col-12 d-none" id="container-${isClientFromCanaryIslandCheckboxId}">
                  <div class="form-group">
                    <div class="form-check form-check-inline">
                      <input id="${isClientFromCanaryIslandCheckboxId}" class="form-check-input" type="checkbox">
                      <label for="${isClientFromCanaryIslandCheckboxId}" class="form-check-label">
                        Indicar en caso de que el cliente sea <strong>residente en las Islas Canarias</strong>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="col-12 mt-4">
                  <p class="alert alert-info">
                    Al hacer clic en <strong>"Enviar solicitud de pago"</strong>, se enviará un correo al cliente con toda la información necesaria
                    <strong>para realizar el pago</strong>. La oferta tiene una validez de <strong>24 horas</strong>, pasado ese tiempo, el enlace de pago
                    dejará de estar disponible.
                  </p>
                  <p class="alert alert-info">
                    Una vez se realice el pago, se enviará una confirmación por correo al <strong>Promotor</strong> y al <strong>Planificador</strong>.
                  </p>
                </div>

              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button id="${saveButtonId}" type="button" class="btn btn-barymont-red">Enviar solicitud de pago</button>
            <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
          </div>

        </div>
      </div>
    </div>`;
}
