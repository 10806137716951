"use strict";

import { addServiceCustomer, readServiceCustomer, removeServiceCustomer } from "../../api/service";
import { showLoadingButton, updateButtonLabel } from "../shared/shared";

async function loadAndOpenRegisterCustomerModal(serviceId) {
  const randomModalId = Math.random().toString(36).substring(7);

  const btnSearchCustomerId = "btn-search-customer" + randomModalId;
  const btnAddCustomerId = "btn-add-customer" + randomModalId;
  const containerCustomerSearchResultId = "customer-search-result" + randomModalId;

  const customerIdentificationNumberInputId = "customer_identification_number" + randomModalId;

  let modal = document.createElement("div");
  modal.id = randomModalId;
  modal.classList.add("modal", "fade");
  modal.setAttribute("tabindex", "-1");
  modal.setAttribute("role", "dialog");
  modal.setAttribute("aria-labelledby", "financial-education-service-add-customer-modal");
  modal.setAttribute("aria-hidden", "true");

  modal.insertAdjacentHTML(
    "beforeend",
    `<div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <form action="javascript:void(0);" class="needs-validation" novalidate>
                <div class="modal-header">
                    <h1 class="modal-title">Vincular cliente</h1>
                </div>
                <div class="modal-body">
                  <div class="container-fluid">
                      <div class="row">
                          <div class="col-12">
                              <label class="font-weight-bold" for="${customerIdentificationNumberInputId}">NIF del cliente</label>
                              <input type="text" class="form-control" id="${customerIdentificationNumberInputId}" name="${customerIdentificationNumberInputId}" required>
                              <button type="button" class="btn btn-block btn-barymont-red mt-2" id="${btnSearchCustomerId}">Buscar cliente</button>
                              <div id="${containerCustomerSearchResultId}"></div>
                          </div>
                      </div>
                  </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
                </div>
            </form>
        </div>
    </div>`
  );

  document.body.appendChild(modal);
  $("#" + randomModalId).modal("show");

  try {
    document.getElementById(btnSearchCustomerId).addEventListener("click", async () => {
      showLoadingButton(btnSearchCustomerId, "btn-barymont-red", true);

      let customerIdentificationNumber = document.getElementById(customerIdentificationNumberInputId).value;

      if (customerIdentificationNumber !== "") {
        let formData = new FormData();
        formData.append("identification_number", customerIdentificationNumber);

        let response = await fetch("/herramientas/financialeducationservice/ajax/searchCustomerByIdentificationNumber", {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          let data = await response.json();

          if (data.status === 1) {
            let customerData = data.data;
            let customerSearchResult = document.getElementById(containerCustomerSearchResultId);
            customerSearchResult.innerHTML = "";

            const customerTypeInputName = "customer-type";

            customerSearchResult.insertAdjacentHTML(
              "beforeend",
              `<div class="card mt-3">
                  <div class="card-body">
                      <h5 class="card-title font-weight-bold">Datos del cliente</h5>
                      <p class="my-1">
                        <span class="font-weight-bold">Nombre:</span> ${customerData.fullName}
                      </p>
                      <p class="my-1">
                        <span class="font-weight-bold">NIF:</span> ${customerData.identificationNumber}
                      </p>
                      <p class="my-1">
                        <input type="radio" id="customer-main" name="${customerTypeInputName}" value="main" checked>
                        <label for="customer-main" class="font-weight-bold">Cliente Principal</label>
                        <input type="radio" id="customer-secondary" name="${customerTypeInputName}" value="secondary">
                        <label for="customer-secondary" class="font-weight-bold">Cliente Secundario</label>
                      </p>
                      <button type="button" class="btn btn-block btn-barymont-red" id="${btnAddCustomerId}">Vincular cliente</button>
                  </div>
              </div>`
            );

            document.getElementById(btnAddCustomerId).addEventListener("click", async () => {
              showLoadingButton(btnAddCustomerId, "btn-barymont-red", true);
              try {
                const isMain = document.querySelector('input[name="' + customerTypeInputName + '"]:checked').value === "main" ? true : false;

                const currentCustomersFromService = await readServiceCustomer(serviceId);

                if (currentCustomersFromService.length == 0 && isMain === false) {
                  createToast("warning", "Error de asignación", "Es necesario asignar primero un cliente principal del servicio", 5000);
                  updateButtonLabel(btnAddCustomerId, "Vincular cliente", "btn-barymont-red");
                  return false;
                }

                const duplicateCustomer = currentCustomersFromService.find(
                  (customer) => customer.leadId === customerData.leadId
                );

                if (duplicateCustomer) {
                  createToast("warning", "Cliente duplicado", "El cliente ya está vinculado al servicio", 5000);
                  updateButtonLabel(btnAddCustomerId, "Vincular cliente", "btn-barymont-red");
                  return false;
                }

                if (currentCustomersFromService.length > 0 && isMain === true) {
                  createToast("warning", "Error de asignación", "Ya existe un cliente principal asignado al servicio", 5000);
                  updateButtonLabel(btnAddCustomerId, "Vincular cliente", "btn-barymont-red");
                  return false;
                }

                await addServiceCustomer(serviceId, customerData.leadId, { isMain: isMain });

                createToast("success", "Cliente añadido", "El cliente ha sido añadido correctamente", 2000);
                document.dispatchEvent(new CustomEvent("serviceUpdated"));

                $("#" + randomModalId).modal("hide");

                document.dispatchEvent(new CustomEvent("ServiceCustomerUpdated"));

                return true;
              } catch (error) {
                createToast("error", "Error ❌", "Ha ocurrido un error al intentar añadir el cliente. Inténtelo de nuevo más tarde.");
                updateButtonLabel(btnAddCustomerId, "Vincular cliente", "btn-barymont-red");

                return false;
              }
            });
          } else {
            let customerSearchResult = document.getElementById(containerCustomerSearchResultId);
            customerSearchResult.innerHTML = "";

            createToast("warning", "Cliente no encontrado", "No se ha encontrado ningún cliente con el NIF introducido", 5000);
            updateButtonLabel(btnSearchCustomerId, "Buscar cliente", "btn-barymont-red");

            return false;
          }
        } else {
          let customerSearchResult = document.getElementById(containerCustomerSearchResultId);
          customerSearchResult.innerHTML = "";

          createToast("warning", "Cliente no encontrado", "No se ha encontrado ningún cliente con el NIF introducido", 5000);

          return false;
        }
      } else {
        createToast("warning", "Campo vacío", "Por favor, introduce un NIF para buscar un cliente", 5000);
        updateButtonLabel(btnSearchCustomerId, "Buscar cliente", "btn-barymont-red");

        return false;
      }

      updateButtonLabel(btnSearchCustomerId, "Buscar cliente", "btn-barymont-red");
    });

    document.getElementById(customerIdentificationNumberInputId).addEventListener("keyup", (event) => {
      if (event.key === "Enter") {
        document.getElementById(btnSearchCustomerId).click();
      }
    });

    $("#" + randomModalId).on("hidden.bs.modal", function () {
      document.getElementById(randomModalId).remove();
    });
  } catch (error) {
    console.error(error);
    createToast("error", "Error ❌", "Ha ocurrido un error al intentar obtener los datos del servicio. Inténtelo de nuevo más tarde.");
    return;
  }
}

async function loadAndOpenUnregisterCustomerModal(serviceId, leadId) {
  const randomModalId = Math.random().toString(36).substring(7);
  const btnRemoveCustomerId = "btn-remove-customer-" + randomModalId;

  let modal = document.createElement("div");
  modal.id = randomModalId;
  modal.classList.add("modal", "fade");
  modal.setAttribute("tabindex", "-1");
  modal.setAttribute("role", "dialog");
  modal.setAttribute("aria-labelledby", "financial-education-service-remove-customer-modal");
  modal.setAttribute("aria-hidden", "true");

  modal.insertAdjacentHTML(
    "beforeend",
    `<div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <form method="POST" action="javascript:void(0);" class="needs-validation" novalidate>
                <div class="modal-header">
                    <h1 class="modal-title">Desvincular cliente</h1>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12 text-center">
                                <p class="my-2">¿Estás seguro de que desea desvincular el cliente del servicio?</p>
                                <button type="button" class="btn btn-block btn-barymont-red" id="${btnRemoveCustomerId}">Desvincular cliente</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
                </div>
            </form>
        </div>
    </div>`
  );

  document.body.appendChild(modal);

  $("#" + randomModalId).modal("show");

  document.getElementById(btnRemoveCustomerId).addEventListener("click", async () => {
    showLoadingButton(btnRemoveCustomerId, "btn-barymont-red", true);

    try {
      await removeServiceCustomer(serviceId, leadId);

      createToast("success", "Cliente desvinculado", "El cliente ha sido desvinculado correctamente", 2000);
      document.dispatchEvent(new CustomEvent("serviceUpdated"));
      $("#" + randomModalId).modal("hide");

      document.dispatchEvent(new CustomEvent("ServiceCustomerUpdated"));

      return true;
    } catch (error) {
      createToast("error", "Error ❌", "Ha ocurrido un error al intentar eliminar el cliente. Inténtelo de nuevo más tarde.");
      updateButtonLabel(btnRemoveCustomerId, "Desvincular cliente", "btn-barymont-red");

      return false;
    }
  });

  $("#" + randomModalId).on("hidden.bs.modal", function () {
    document.getElementById(randomModalId).remove();
  });
}

export { loadAndOpenRegisterCustomerModal, loadAndOpenUnregisterCustomerModal };
