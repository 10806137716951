import useElementLoader from "../../shared/application/loaders/useElementLoader";
import openRequestServiceModal from "./openRequestServiceModal";

const DOM_SELECTORS = {
  openRequestServiceModalButtonId: "financial-planning-service-button-open-modal",
};

function initLeadPanelEventListeners(): void {
  if (!/^\/herramientas\/leads\/[a-f0-9-]{36}\/?$/.test(window.location.pathname)) {
    return;
  }

  const openRequestServiceModalButton = document.getElementById(DOM_SELECTORS.openRequestServiceModalButtonId);

  if (!openRequestServiceModalButton) {
    console.error("Open modal button not found.");
    return;
  }

  openRequestServiceModalButton.addEventListener("click", () => {
    const loader = useElementLoader({ elementId: DOM_SELECTORS.openRequestServiceModalButtonId });

    loader.start();

    const pathParts = window.location.pathname.split("/");
    const leadId = pathParts[3];

    if (!leadId) {
      console.error("Lead ID not found in URL.");
      loader.stop();
      return;
    }

    void (async () => {
      await openRequestServiceModal(leadId);
      loader.stop();
    })();
  });
}

window.addEventListener("load", initLeadPanelEventListeners);
