"use strict";

async function handleRecoverPasswordRequest(event) {
  event.preventDefault();

  document.getElementById("recoverPasswordForm").style.display = "none";
  document.getElementById("loading").style.display = "block";

  let form = event.target;

  let formdata = new FormData(form);
  let requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  let response;

  try {
    let result = await fetch("/login/ajax/initiateLoginRecovery", requestOptions).then((response) => response.text());
    response = JSON.parse(result);
  } catch (error) {
    console.log("error", error);
  } finally {
    document.getElementById("loading").style.display = "none";
  }

  if (response && response.success) {
    document.getElementById("successMessageFeedback").style.display = "block";
  } else {
    document.getElementById("errorMessageFeedback").style.display = "block";
  }
}

if (window) {
  window.addEventListener("load", () => {
    if (window.location.pathname === "/login/recover") {
      document.querySelector("#recoverPasswordFormSelector").addEventListener("submit", handleRecoverPasswordRequest);
    }
  });
}
