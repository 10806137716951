"use strict";

export async function readServiceVisibility(serviceId) {
  const response = await fetch(`/api/service/${serviceId}/visibility`);
  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function createServiceVisibility(serviceId, userId) {
  const response = await fetch(`/api/service/${serviceId}/visibility/${userId}`, {
    method: "POST",
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function deleteServiceVisibility(serviceId, userId) {
  const response = await fetch(`/api/service/${serviceId}/visibility/${userId}`, {
    method: "DELETE",
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function readServiceCustomer(serviceId) {
  const response = await fetch(`/api/service/${serviceId}/customer`, {
    method: "GET",
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function addServiceCustomer(serviceId, leadId, data) {
  const response = await fetch(`/api/service/${serviceId}/customer/${leadId}`, {
    method: "POST",
    body: JSON.stringify(data),
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function removeServiceCustomer(serviceId, leadId) {
  const response = await fetch(`/api/service/${serviceId}/customer/${leadId}`, {
    method: "DELETE",
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function readServiceExpert(serviceId) {
  const response = await fetch(`/api/service/${serviceId}/expert`, {
    method: "GET",
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function createServiceExpert(serviceId, userId, role) {
  const response = await fetch(`/api/service/${serviceId}/expert/${userId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ role: role }),
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function deleteServiceExpert(serviceId, userId) {
  const response = await fetch(`/api/service/${serviceId}/expert/${userId}`, {
    method: "DELETE",
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function readServiceTypeChecklistTemplates({ serviceTypeId, actionKey, additionalData = {} }) {
  let queryParams = `?actionKey=${encodeURIComponent(actionKey)}`;
  if (additionalData && Object.keys(additionalData).length > 0) {
    const additionalDataString = Object.keys(additionalData)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(additionalData[key])}`)
      .join("&");
    queryParams += `&${additionalDataString}`;
  }

  const response = await fetch(`/api/service-type/${serviceTypeId}/checklist-templates${queryParams}`, {
    method: "GET",
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function readServiceTypeChecklistTemplateActions() {
  const response = await fetch(`/api/service-type/checklist-templates/actions`, {
    method: "GET",
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function updateServiceTypeChecklistTemplates({ serviceTypeId, actionKey, additionalData = {}, data }) {
  let queryParams = `?actionKey=${encodeURIComponent(actionKey)}`;

  if(data.length === 0 && Object.keys(additionalData).length === 0) {
    throw new Error("At least one checklist template or additional data must be provided.");
  }

  if (additionalData && Object.keys(additionalData).length > 0) {
    const additionalDataString = Object.keys(additionalData)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(additionalData[key])}`)
      .join("&");
    queryParams += `&${additionalDataString}`;
  }

  const response = await fetch(`/api/service-type/${serviceTypeId}/checklist-templates${queryParams}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ serviceChecklistTemplates: data }),
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function readServiceTypeSchema(serviceTypeId) {
  const response = await fetch(`/api/service-type/${serviceTypeId}/schema`, {
    method: "GET",
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}

export async function readPymeServiceTypes() {
  const response = await fetch(`/api/pyme-service-types`, {
    method: "GET",
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData.message);
  }

  return responseData;
}
