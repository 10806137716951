import handleApiResponse from "../../core/application/handleApiResponse";

interface PaymentRequestParams {
  leadId: string;
  subscriptionPeriodicity: 'MONTHLY' | 'YEARLY';
  promoterUserId: string;
  planifierUserId: string;
  isClientBuyingAsBusiness: boolean;
  isClientTaxExempt: boolean;
}

interface PaymentRequestResponse {
  paymentUrl?: string;
}

export default async function paymentRequest(params: PaymentRequestParams): Promise<PaymentRequestResponse> {
  const response = await fetch(`/api/financial-planning-service/payment-request`, {
    method: "POST",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  });

  return handleApiResponse<PaymentRequestResponse>(response);
}
